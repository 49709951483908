import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class MasterColourService {
  constructor(private http: HttpUtilService) {}

  getAll(): Observable<any> {
    return this.http.get("/ColourMasterService.asmx/GetAll");
  }

  Delete(items: any[]): Observable<any> {
    return this.http.post("ColourMasterService.asmx/Delete", {
      items,
    });
  }

  GetModelGroup(): Observable<any> {
    return this.http.get("InteriorColourService.asmx/GetModelGroup");
  }

  GetColourCode(modelGroup: string): Observable<any> {
    const encodedModelGroup = encodeURIComponent(`"${modelGroup}"`);
    const url = `InteriorColourService.asmx/GetColourCode?modelGroup=${encodedModelGroup}`;
    return this.http.get(url);
  }

  getDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`);
    const url = `/ColourMasterService.asmx/GetDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  saveDetails(data: any): Observable<any> {
    return this.http.post("/ColourMasterService.asmx/SaveDetails", {
      data,
    });
  }
}
