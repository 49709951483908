<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Customer Search</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Buyer's Details</h2>
      <app-data-form
        [dataFields]="buyersDetails"
        [dataName]="'buyersDetails'"
      />
    </section>
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn save-btn" (click)="onUpdate()">
        <fa-icon [icon]="faEdit" class="mr-1"></fa-icon>Update
      </button>
      <button class="shorter-btn print-btn" (click)="onPrint()">
        <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print Sales
        Contract
      </button>
    </div>
  </div>
</section>
