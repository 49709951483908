import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { UserTypeManagementService } from "src/app/services/administration/use-type-management.service";
import {getEmptyGuid} from "../../../../services/util.service";

@Component({
  selector: "app-user-type-management-list",
  templateUrl: "./user-type-management-list.component.html",
  styleUrls: ["./user-type-management-list.component.css"],
})
export class UserTypeManagementListComponent {
  faPlus = faPlus;
  roles_info: any[] = [];
  filteredRoles: any[] = [];

  constructor(
    private UserTypeManagementService: UserTypeManagementService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.UserTypeManagementService.getAllRoles().subscribe(
      (response) => {
        this.roles_info = response.body.map((user: any) => {
          const container = {
            "innerId": "",
            "name": "",
            "description": "",
            "status": "",
            "action": "",
          };
          container["name"] = user.Name;
          container["description"] = user.Description;
          container["status"] = user.Status;
          container["action"] = "";
          container["innerId"] = user.RoleId;
          return container;
        });
        this.filteredRoles = this.roles_info;
      },
      (error) => {
        console.error("Error fetching role management data:", error);
      },
    );
  }

  onSearch(searchValue: string): void {
    this.filteredRoles = this.roles_info.filter(
      (item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.description
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
    );
  }

  tableHeaders = [
    {
      title: "NAME",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "DESCRIPTION",
      type: "data",
    },
    {
      title: "STATUS",
      type: "data",
    },
    {
      title: "ACTION",
      Hclasses: "text-center",
      type: "edit",
    },
  ];

  editEntry(value: any) {
    this.router.navigate(
      ["../user-type-management", value.innerId],
      { relativeTo: this.route },
    );
  }

  protected readonly getEmptyGuid = getEmptyGuid;
}
