<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      Update Detail
    </h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faPencil" class="mr-1" />Update
    </button>
    <button class="shorter-btn red-btn">
      Delete
    </button>
    <button class="shorter-btn gray-btn">
      <fa-icon [icon]="faBan" class="mr-1" />Cancel
    </button>
  </div>
  <div class="content-container">
    <app-data-form [dataFields]="formFields" />
  </div>
</div>
