import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class WeeklyFocusDashboardService {
  constructor(private http: HttpUtilService) {}

  /**
   * Fetches all sales teams based on permission.
   * @returns An Observable of the sales teams data.
   */
  getAllSalesTeamBasedOnPermission(): Observable<any> {
    return this.http.get('/KapsUserService.asmx/GetAllSalesTeamBasedOnPermission');
  }

  /**
   * Fetches all salesmen based on team.
   * @param team The team name to fetch salesmen for.
   * @returns An Observable of the salesmen data.
   */
  getAllSalesmanBasedOnTeam(team: string = 'All'): Observable<any> {
    return this.http.get(`/KapsUserService.asmx/GetAllSalesmanBasedOnTeam?team=${team}`);
  }

  /**
   * Fetches all weekly focus data.
   * @param salesman The salesman identifier.
   * @param team The team name.
   * @returns An Observable of the weekly focus data.
   */
  getAllWeeklyFocus(salesman: string = 'All', team: string = 'All'): Observable<any> {
    return this.http.get(`/WeeklyFocusService.asmx/GetAllWeeklyFocus?salesman=${salesman}&team=${team}`);
  }

  /**
   * Fetches weekly focus detail by ID.
   * @param id The weekly focus ID.
   * @returns An Observable of the weekly focus detail data.
   */
  getWeeklyFocusDetail(id: string): Observable<any> {
    return this.http.get(`/WeeklyFocusService.asmx/GetWeeklyFocusDetail?id=%22${id}%22`);
  }

  /**
   * Saves remarks for a weekly focus entry.
   * @param data The data containing ID, weeklyId, and remarks.
   * @returns An Observable of the save operation result.
   */
  saveRemarksWeeklyFocus(data: { id: string; weeklyId: string; remarks: string | null }): Observable<any> {
    return this.http.post('/WeeklyFocusService.asmx/SaveRemarksWeeklyFocus', data);
  }
}
