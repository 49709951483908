<div
  class="input-container"
  [ngClass]="{
    'missing':
      required && (dataInput == undefined || dataInput === ''),
    'disabled': lock
  }"
>
  <input
    [type]="dataType"
    class="text-container"
    [disabled]="lock"
    [(ngModel)]="dataInput"
    [placeholder]="placeholder"
    (input)="changeInput(dataInput)"
  />
  <fa-icon
    *ngIf="required && (dataInput == undefined || dataInput === '')"
    [icon]="faTriangleExclamation"
    class="warning-btn"
  />
</div>
