import { Component } from '@angular/core';
import { faSave, faPlus, faPrint, faTrash, faLink } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-giro-payment',
  templateUrl: './giro-payment.component.html',
  styleUrls: ['./giro-payment.component.css']
})
export class GiroPaymentComponent {

  faSave = faSave;
  faPlus = faPlus;
  faPrint = faPrint;
  faTrash = faTrash;
  faLink = faLink;

  contractDetail: FormField[] = [
    {
      type: "text",
      id: "contractNumber",
      label: "Contract Number",
    },
    {
      type: "text",
      id: "contractDate",
      label: "Contract Date",
      lock: true,
    },
    {
      type: "text",
      id: "invoiceNumber",
      label: "Invoice Number",
      lock: true,
    },
    {
      type: "text",
      id: "model",
      label: "Model",
      lock: true,
    },
    {
      type: "text",
      id: "registerNumber",
      label: "Register Number",
      lock: true,
    },
    {
      type: "text",
      id: "registerDate",
      label: "Register Date",
      lock: true,
    },
    {
      type: "text",
      id: "vinNumber",
      label: "Vin Number",
      lock: true,
    },
    {
      type: "text",
      id: "buyerName",
      label: "Buyer Name",
      lock: true,
    },
    {
      type: "text",
      id: "salesConsultantName",
      label: "Sales Consultant Name",
      lock: true,
    },
    {
      type: "text",
      id: "salesConsultantCode",
      label: "Sales Consultant Code",
      lock: true,
    },
  ];

  giroDetail: FormField[] = [
    {
      type: "radio",
      id: "detail",
      options: [
        {
          display: "Buyer",
          value: "buyer",
        },
        {
          display: "Sales Consultant",
          value: "salesConsultant",
        },
        {
          display: "Others",
          value: "others",
        },
      ],
    },
    {
      type: "text",
      id: "bank",
      label: "Bank",
      lock: true,
    },
    {
      type: "date",
      id: "giroDate",
      label: "GIRO Date (dd/mm/yyyy)",
    },
    {
      type: "text",
      id: "giroNumber",
      label: "GIRO Number",
    },
    {
      type: "select",
      id: "paidAccountName",
      label: "Paid Account Name",
      options: [
        {
          display: "AIG",
          value: "AIG",
        },
      ],
    },
    {
      type: "text",
      id: "bankAccountNumber",
      label: "Bank Account Number",
    },
    {
      type: "text",
      id: "payTo",
      label: "Pay To",
    },
    {
      type: "text",
      id: "amount",
      label: "Amount",
    },
    {
      type: "select",
      id: "purpose",
      label: "Purpose",
      options: [
        {
          display: "ACCOUNTS - OVERTIME",
          value: "accountsOvertime",
        },
      ],
    },
    {
      type: "text",
      id: "accountCode",
      label: "Account Code",
      lock: true,
    },
  ]

}
