import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpResponse, HttpUtilService } from "../http-util.service";
import {
  model,
  finance,
  payment,
  user,
  updateForm,
} from "src/app/models/vehicle/booking";

const SERVICE_NAME = "BookingService.asmx";

@Injectable({
  providedIn: "root",
})
export class BookingService {
  constructor(private http: HttpUtilService) {}

  GetTradeInVehicleDealers(): Observable<
    HttpResponse<
      {
        id: string;
        name: string;
      }[]
    >
  > {
    return this.http.get(
      `LookUpService.asmx/GetTradeInVehicleDealers`,
    );
  }

  GetBlockStatus(): Observable<HttpResponse<{}>> {
    return this.http.get(`${SERVICE_NAME}/GetBlockStatus`);
  }

  GetModelListForBooking(): Observable<HttpResponse<model[]>> {
    return this.http.get(
      `ModelSpecificationService.asmx/GetModelListForBooking`,
    );
  }

  GetExpectedDeliveryList(): Observable<HttpResponse<string[]>> {
    return this.http.get(`${SERVICE_NAME}/GetExpectedDeliveryList`);
  }

  GetFinanceList(): Observable<HttpResponse<finance[]>> {
    return this.http.get(`${SERVICE_NAME}/GetFinanceList`);
  }

  GetAllActiveList(): Observable<HttpResponse<payment[]>> {
    return this.http.get(`TerminalService.asmx/GetAllActiveList`);
  }

  GetActiveDrpList(): Observable<HttpResponse<finance[]>> {
    return this.http.get(`InsuranceService.asmx/GetActiveDrpList`);
  }

  GetRoyaltyDiscount(): Observable<HttpResponse<number[]>> {
    return this.http.get(`${SERVICE_NAME}/GetRoyaltyDiscount`);
  }

  GetUserDrpList(): Observable<HttpResponse<user[]>> {
    return this.http.get(`AdminService.asmx/GetUserDrpList`);
  }

  GetAllEmailBlackList(): Observable<
    HttpResponse<{ "Email": string; "ID": string }[]>
  > {
    return this.http.get(
      `EmailBlackListService.asmx/GetAllEmailBlackList`,
    );
  }

  GetById(
    byContract: boolean,
    id: string,
    isReservation: boolean,
  ): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_NAME}/GetById`, {
      params: {
        byContract,
        id,
        isReservation,
      },
    });
  }

  ConvertQuotationToBooking(
    quotationNumber: string,
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      `QuotationService.asmx/ConvertQuotationToBooking`,
      {
        params: {
          quotationNumber,
        },
      },
    );
  }

  GetVehicleStockAvailability(
    modelDesc: string,
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      `ModelSpecificationService.asmx/GetVehicleStockAvailability`,
      {
        params: {
          modelDesc,
        },
      },
    );
  }

  GetDataOnModelChange(
    ModelMasterId: string,
  ): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_NAME}/GetDataOnModelChange`, {
      params: {
        ModelMasterId,
      },
    });
  }

  GetPurchaseHistorybyNric(
    category: string,
    nric: string,
  ): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_NAME}/GetPurchaseHistorybyNric`, {
      params: {
        category,
        nric,
      },
    });
  }

  GetIntColourCodeList(
    extColour: string,
    model: string,
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      `ColourMasterService.asmx/GetIntColourCodeList`,
      {
        params: {
          extColour,
          model,
        },
      },
    );
  }

  GetVehiclePrice(
    carType: string,
    ModelShortDesc: string,
    SpecialType: string = "",
  ): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_NAME}/GetVehiclePrice`, {
      params: {
        carType,
        ModelShortDesc,
        SpecialType,
      },
    });
  }

  GetMonthlyInstallment(
    loanAmount: number,
    loanPeriod: number,
    rate: number,
  ): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_NAME}/GetMonthlyInstallment`, {
      params: {
        loanAmount,
        loanPeriod,
        rate,
      },
    });
  }

  GetProspectByIcNo(IcNo: string): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_NAME}/GetProspectByIcNo`, {
      params: {
        IcNo,
      },
    });
  }

  PrintWithoutSellingPrice(
    id: string,
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      `${SERVICE_NAME}/PrintWithoutSellingPrice`,
      {
        id: id,
      },
    );
  }

  GetBuyerOrderFormForBuyOnline(
    id: string,
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      `${SERVICE_NAME}/GetBuyerOrderFormForBuyOnline`,
      {
        id: id,
      },
    );
  }

  Update(
    addContractNo: boolean,
    isRequestFromContract: boolean,
    model: updateForm,
  ): Observable<HttpResponse<any>> {
    return this.http.post(`${SERVICE_NAME}/Update`, {
      addContractNo: addContractNo,
      isRequestFromContract: isRequestFromContract,
      model: model,
    });
  }

  Add(model: any): Observable<HttpResponse<any>> {
    return this.http.post(`${SERVICE_NAME}/Add`, {
      model: model,
    });
  }

  CancelBooking(
    Name: string,
    Value: string,
  ): Observable<HttpResponse<any>> {
    return this.http.post(`${SERVICE_NAME}/CancelBooking`, {
      model: { Name: Name, Value: Value },
    });
  }
}
