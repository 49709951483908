import { Component, OnInit } from '@angular/core';
import {
  faPlus,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { InteriorColourService } from 'src/app/services/vehicle/interior-colour.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: 'app-interior-colour-list',
  templateUrl: './interior-colour-list.component.html',
  styleUrls: ['./interior-colour-list.component.css']
})
export class InteriorColourListComponent implements OnInit {

  searchInput: string = "";
  allData: any[] = [];
  filteredData: any[] = [];

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
      id: "checked"
    },
    {
      title: "MODEL GROUP",
      type: "link",
      id: "modelGroup"
    },
    {
      title: "INTERIOR COLOUR CODE",
      type: "data",
      id: "interiorColourCode",
      classes: "max-w-64"
    },
    {
      title: "DESCRIPTION",
      type: "data",
      id: "description"
    },
    {
      title: "ID",
      type: "hidden",
      id: "interiorColourId"
    }
  ];

  constructor(
    private interiorColourService: InteriorColourService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAllList();
  }

  private getAllList(): void {
    this.interiorColourService.getAll().subscribe(
      (response) => {
        this.allData = response.body.map((interior_colour: any) => {
          return {
            "checked": false,
            "modelGroup": interior_colour.ModelGroup || "",
            "interiorColourCode": interior_colour.InteriorColourCode || "",
            "description": interior_colour.Description || "",
            "interiorColourId": interior_colour.InteriorColourId || "",
          };
        });
        this.filteredData = [...this.allData]; 
      },
      (error) => {
        console.error("Error fetching interior colour data:", error);
      }
    );
  }

  onSearch(searchValue: string): void {
    this.filteredData = this.allData.filter(item =>
      item.modelGroup.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.interiorColourCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.description.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  openEntry(value: any) {
    this.router.navigate(["../interior-colour", value.interiorColourId], {
      relativeTo: this.route,
    });
  }
  
  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../interior-colour", emptyGuid], {
      relativeTo: this.route,
    });
  }

  deleteSelectedEntries(): void {

    const selectedItems = this.filteredData.filter(item => item.checked);

    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one item to delete.');
      return;
    }

    this.interiorColourService.deleteInteriorColours(selectedItems).subscribe({
      next: () => {
        this.notifier.notify('success', 'Selected items deleted successfully');

        this.filteredData = this.filteredData.filter(item => !item.checked);
      },
      error: (error) => {
        console.error("Error deleting interior colours:", error);
        this.notifier.notify('error', 'Error deleting selected items.');
      }
    });
  }
}

