<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Cheque enquiry</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <app-data-form
      [dataFields]="chequeEnquiry"
      [dataName]="'chequeEnquiry'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
</div>
