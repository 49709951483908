<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Special Price Detail</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button
      *ngIf="mode === 'Add'"
      class="shorter-btn add-btn"
      [disabled]="lockUpdate"
      (click)="handlePriceList('Add')"
    >
      <fa-icon [icon]="faPlus" class="mr-1" />Add
    </button>
    <button
      *ngIf="mode === 'Edit'"
      class="shorter-btn save-btn"
      [disabled]="lockUpdate"
      (click)="handlePriceList('Update')"
    >
      <fa-icon [icon]="faPencil" class="mr-1" />Update
    </button>
    <button class="shorter-btn clear-btn" (click)="onCancel()">
      <fa-icon [icon]="faCancel" class="mr-1" />Cancel
    </button>
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="formFields"
      (updateFields_child)="updateFields($event)"
    />
  </div>
</div>
