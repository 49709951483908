import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import {
  model,
  activeModel,
  remark,
} from "src/app/models/sales/price-list-master";

@Injectable({
  providedIn: "root",
})
export class PriceListMasterService {
  constructor(private http: HttpUtilService) {}

  GetAll(): Observable<HttpResponse<model[]>> {
    return this.http.get("PriceListService.asmx/GetAll");
  }

  GetSalesPriceList(): Observable<HttpResponse<model[]>> {
    return this.http.get("PriceListService.asmx/GetSalesPriceList");
  }

  GetAllRemarks(): Observable<HttpResponse<remark[]>> {
    return this.http.get("PriceListService.asmx/GetAllRemarks");
  }
  Delete(items: model[]): Observable<any> {
    return this.http.post("PriceListService.asmx/Delete", {
      items,
    });
  }

  Print(): Observable<any> {
    return this.http.get("PriceListService.asmx/PriceListMaster");
  }

  GetBasicActiveModelList(): Observable<HttpResponse<activeModel[]>> {
    return this.http.get(
      "ModelSpecificationService.asmx/GetBasicActiveModelList",
    );
  }

  GetDetails(id: string): Observable<HttpResponse<model>> {
    return this.http.get("PriceListService.asmx/GetDetails", {
      params: { id },
    });
  }

  Update(
    updateBoth: string,
    model: model,
  ): Observable<HttpResponse<model>> {
    return this.http.post("PriceListService.asmx/Update", {
      updateBoth,
      model,
    });
  }

  UpdateRemarks(model: remark): Observable<HttpResponse<any>> {
    return this.http.post("PriceListService.asmx/UpdateRemarks", {
      model,
    });
  }

  Add(model: any): Observable<HttpResponse<any>> {
    return this.http.post("PriceListService.asmx/Add", {
      model,
    });
  }
}
