import { Component } from '@angular/core';
import { faPrint, faEraser } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-accessory-report',
  templateUrl: './accessory-report.component.html',
  styleUrls: ['./accessory-report.component.css']
})
export class AccessoryReportComponent {

// ICONS VARIABLES
faPrint = faPrint;
faEraser = faEraser;

reportFields: FormField[] = [
  {
    type: "radio",
    id: "searchBy",
    required: true,
    options: [
      {
        display: "Buyer",
        value: "buyer",
      },
      {
        display: "Sales Consultant",
        value: "salesConsultant",
      },
    ],
  },
  {
    type: "date",
    id: "fromDate",
    label: "From Date (dd/mm/yyyy)",
    required: true,
  },
  {
    type: "select",
    id: "selectFormat",
    label: "Select Format",
    options: [
      {
        display: "PDF",
        value: "Pdf",
      },
      {
        display: "EXCEL",
        value: "Excel",
      },
      {
        display: "BOTH",
        value: "Both",
      },
    ],
  },
  {
    type: "date",
    id: "toDate",
    label: "To Date (dd/mm/yyyy)",
    required: true,
  },
]

}
