import { Component, OnInit } from "@angular/core";
import { faEye, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
  InventoryVehicle,
  Response,
} from "src/app/models/sales/inventory-vehicle";
import { InventoryVehicleService } from "src/app/services/sales/inventory-vehicle.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";
import { filter } from "rxjs";

@Component({
  selector: "app-inventory-vehicle",
  templateUrl: "./inventory-vehicle.component.html",
  styleUrls: ["./inventory-vehicle.component.css"],
})
export class InventoryVehicleComponent implements OnInit {
  faEye = faEye;
  faUpload = faUpload;
  showUpload: boolean = false;
  response: Response | undefined = undefined;

  tableHeaders = [
    {
      title: "CHASIS NUMBER",
      type: "data",
      id: "ChasisNumber",
    },
    {
      title: "ENGINE NUMBER",
      type: "data",
      id: "EngineNumber",
    },
    {
      title: "CASE SERIES",
      type: "data",
      id: "CaseSeries",
    },
    {
      title: "MODEL TYPE",
      type: "data",
      id: "ModelType",
    },
    {
      title: "MODEL SHORT",
      type: "data",
      id: "ModelShort",
    },
    {
      title: "ICOLOUR",
      type: "data",
      id: "IColour",
    },
    {
      title: "FRANCHISE CODE",
      type: "data",
      id: "FranchiseCode",
    },
    {
      title: "MODEL CODE",
      type: "data",
      id: "ModelCode",
    },
    {
      title: "COLOUR CODE",
      type: "data",
      id: "ColourCode",
    },
    {
      title: "KEY NUMBER",
      type: "data",
      id: "KeyNumber",
    },
    {
      title: "CASE NUMBER",
      type: "data",
      id: "CaseNumber",
    },
    {
      title: "PURCHASE INVOICE NUMBER",
      type: "data",
      id: "PurchaseInvNumber",
    },
    {
      title: "SHIPMENT DATE",
      type: "data",
      id: "ShipmentDate",
    },
    {
      title: "MODEL SHORT DESC",
      type: "data",
      id: "ModelShortDesc",
    },
    {
      title: "VESSEL NAME",
      type: "data",
      id: "VesselName",
    },
    {
      title: "ORDER MONTH",
      type: "data",
      id: "OrderMonth",
    },
    {
      title: "EXTERIOR COLOUR",
      type: "data",
      id: "ExteriorColour",
    },
    {
      title: "OCC NUMBER",
      type: "data",
      id: "OccNo",
    },
  ];
  InventoryVehicles: InventoryVehicle[] = [];

  constructor(
    private notifier: NotifierService,
    private inventoryVehicleService: InventoryVehicleService,
  ) {}

  ngOnInit() {}

  onFileChange(event: any): void {
    const target: DataTransfer = <DataTransfer>event.target;

    if (target.files.length !== 1) {
      console.error("Please, upload only one file");
      return;
    }

    const file = target.files[0];
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const arrayBuffer: ArrayBuffer = e.target.result;

      this.ShowFile(arrayBuffer);
    };

    reader.readAsArrayBuffer(file);
  }

  ShowFile(file: ArrayBuffer): void {
    this.inventoryVehicleService.UploadFiles(file).subscribe({
      next: (response) => {
        if (response.body.Code === 0) {
          this.response = response.body.Data;
          this.InventoryVehicles =
            response.body.Data.InventoryVehicles;
        } else {
          console.log(this.response);
          this.notifier.notify("error", response.body.message);
        }
      },
      error: console.error,
    });
  }

  ShowData(): void {
    if (this.response) {
      this.showUpload = true;
    } else {
      this.showUpload = false;
    }
  }

  AddVehicles(): void {
    this.inventoryVehicleService
      .AddVehicles(this.InventoryVehicles)
      .subscribe({
        next: () =>
          this.notifier.notify(
            "success",
            "Vehicles uploaded successfully",
          ),
        error: (error) => {
          this.notifier.notify("error", "Error uploading vehicles");
          console.log(error);
        },
      });
  }

  filterEntries(entries: InventoryVehicle[]): any[] {
    const allowedKeys = this.tableHeaders.map((header) => header.id);
    return entries.map((obj: any) => {
      const filteredObject: any = {};
      allowedKeys.forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          filteredObject[key] = obj[key];
        }
      });
      return filteredObject;
    });
  }
}
