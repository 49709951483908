// src/app/services/session-storage.service.ts
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() {
  }

  /**
   * Retrieves an item from session storage.
   * @param key The key of the item to retrieve.
   * @returns The value associated with the key, or null if not found.
   */
  getItem(key: string): any {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  /**
   * Sets an item in session storage.
   * @param key The key of the item to set.
   * @param value The value to associate with the key.
   */
  setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  /*
  * Clears all items in the specified namespace.
  * @param namespace The namespace to clear.
  */
  clearNamespace(namespace: string): void {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith(`${namespace}.`)) {
        sessionStorage.removeItem(key);
      }
    });
  }

  /**
   * Clears all items in session storage.
   */
  clear(): void {
    sessionStorage.clear();
  }
}
