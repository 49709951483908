<div class="input-container">
  <input
    type="text"
    class="search-container"
    [ngClass]="{
      'missing': required && !dataInput,
      'brightness-95': lock,
      'is-table': isTable
    }"
    (input)="updateFormControl($event)"
    [formControl]="searchControl"
    [placeholder]="isTable ? placeHolder : ''"
  />
  <button
    class="search-btn"
    [ngClass]="{
      'disabled': !dataInput && isTable,
      'is-table': isTable
    }"
    (click)="search($event)"
  >
    <fa-icon [icon]="faSearch" />
  </button>
  <div
    *ngIf="showFiltered && !isTable"
    class="text-options"
    (click)="showFiltered = false"
    (click)="$event.stopPropagation()"
  >
    <p
      *ngFor="let textOption of filteredOptions"
      (click)="selectItem(textOption.value)"
      class="cursor-pointer px-2 py-1 hover:bg-gray-100"
    >
      {{ textOption.display }}
    </p>
  </div>
</div>
