import { Component, OnInit } from '@angular/core';
import {
  faPlus,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { PriceListTemplateService } from 'src/app/services/sales/price-list-template.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: 'app-price-list-template-list',
  templateUrl: './price-list-template-list.component.html',
  styleUrls: ['./price-list-template-list.component.css']
})
export class PriceListTemplateListComponent implements OnInit {

  searchInput: string = "";
  allData: any[] = [];
  filteredData: any[] = [];

  // Icon imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
      id: "checked"
    },
    {
      title: "MODEL",
      type: "link",
      id: "model"
    },
    {
      title: "ENGINE CAP",
      type: "data",
      id: "engineCap"
    },
    {
      title: "SEQ",
      type: "data",
      id: "seq"
    },
    {
      title: "SUB SEQ",
      type: "data",
      id: "subSeq"
    },
    {
      title: "CATEGORY",
      type: "data",
      id: "category"
    },
    {
      title: "COE OFFSET",
      type: "data",
      id: "coe"
    },
    {
      title: "6 MTHS ROAD TAX",
      type: "data",
      id: "roadTax"
    },
    {
      title: "PRICE",
      type: "data",
      id: "price"
    },
    {
      title: "DESC",
      type: "data",
      id: "desc"
    },
    {
      title: "DISPLAY",
      type: "data",
      id: "display"
    },
    {
      title: "ID",
      type: "hidden",
      id: "id"
    }
  ];

  constructor(
    private priceListTemplateService: PriceListTemplateService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAllTemplates();
  }

  private getAllTemplates(): void {
    this.priceListTemplateService.getAllPriceListTemplates().subscribe(
      (response) => {
        this.allData = response.body.map((template: any) => {
          return {
            "checked": false,
            "model": template.MODEL || "",
            "engineCap": template.ENGINECAP || "",
            "seq": template.SEQ || "",
            "subSeq": template.SUBSEQ || "",
            "category": template.CATEGORY || "",
            "coe": template.COE || 0,
            "roadTax": template.ROADTAX || 0,
            "price": template.Price || 0,
            "desc": template.StringDesc || "",
            "display": template.Display || "",
            "id": template.ID || ""
          };
        });
        this.filteredData = [...this.allData];
      },
      (error) => {
        console.error("Error fetching price list templates:", error);
        this.notifier.notify('error', 'Error fetching price list templates.');
      }
    );
  }

  onSearch(searchValue: string): void {
    this.filteredData = this.allData.filter(item =>
      item.model.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.desc.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.category.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  openEntry(value: any) {
    this.router.navigate(["../price-list-template", value.id], {
      relativeTo: this.route,
    });
  }

  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../price-list-template", emptyGuid], {
      relativeTo: this.route,
    });
  }

  deleteSelectedEntries(): void {
    const selectedItems = this.filteredData.filter(item => item.checked);
  
    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one item to delete.');
      return;
    }
  
    // Map selected items to the expected format
    const payload = {
      ids: selectedItems.map(item => ({
        ID: item.id,
        MODEL: item.model,
        ROADTAX: item.roadTax,
        Price: item.price,
        COE: item.coe,
        ENGINECAP: item.engineCap,
        CATEGORY: item.category,
        SEQ: item.seq,
        SUBSEQ: item.subSeq,
        DisplayPassenger: item.display === "Passenger" ? true : null,
        DisplayCommercial: item.display === "Commercial" ? true : null,
        StringDesc: item.desc,
        Display: item.display,
        IsChecked: true
      }))
    };
  
    this.priceListTemplateService.deletePriceListTemplates(payload).subscribe({
      next: () => {
        this.notifier.notify('success', 'Selected items deleted successfully.');
        this.filteredData = this.filteredData.filter(item => !item.checked);
      },
      error: (error) => {
        console.error("Error deleting price list templates:", error);
        this.notifier.notify('error', 'Error deleting selected items.');
      }
    });
  }
  
}
