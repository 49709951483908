<div *ngIf="isCompanyLoading$ | async" class="loading-page-container">
  <div class="grid gap-4 text-center">
    <img
      src="assets/images/logos/komoco-center.png"
      alt="Komoco Logo"
      class="mb-10"
    />
    <p class="loading-text text-2xl">
      Welcome, <b>{{ username }}!</b>
    </p>
    <h3 class="loading-text text-xl">
      Setting up your account, please wait.
    </h3>
    <div class="mt-10 flex justify-center">
      <div class="loader"></div>
    </div>
  </div>
</div>
<div
  *ngIf="isRequestLoading$ | async"
  class="loading-modal-container"
>
  <div class="loader"></div>
</div>
