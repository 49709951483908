import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {STORAGE_KEYS} from "../constants";
import {SessionStorageService} from "./session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private userInfoSubject = new BehaviorSubject<{ [key: string]: any }>({});
  userInfo$ = this.userInfoSubject.asObservable();

  constructor(private sessionStorageService: SessionStorageService) {
    this.updateUserInfo();
  }

  updateUserInfo(): void {
    const userInfo: { [key: string]: any } = {};
    Object.keys(STORAGE_KEYS.USER_INFO).forEach(key => {
      const sessionKey = STORAGE_KEYS.USER_INFO[key].sessionKey;
      userInfo[sessionKey] = this.sessionStorageService.getItem(sessionKey);
    });
    this.userInfoSubject.next(userInfo);
  }

  setUserInfo(key: string, value: any): void {
    this.sessionStorageService.setItem(key, value);
    this.updateUserInfo();
  }
}
