<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Manage Credit Memo</h1>
      <app-navpath></app-navpath>
    </div>
    <div class="content-container">
      <app-data-form
        [dataFields]="formFields"
        (clickSearch)="fetchContractDetails()"
      ></app-data-form>
      <div class="mt-4 p-4 rounded-md shadow-md" style="background-color: #00c0ef; color: white; font-size: 0.875rem;">
        <p class="mb-2">
          * For cases after Registration, please print and fill in the Request Form for instruction to pay the 1st Installment/Insurance Premium.
        </p>
        <p>
          * The Request Form must be submitted to Invoicing Section.
        </p>
      </div>
    </div>
    <div class="mb-2 mt-4 flex w-fit gap-1">
        <button class="shorter-btn misc-btn" (click)="updateAndPrintMemo()">
            <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Update & Print
        </button>
        <button class="shorter-btn misc-btn" (click)="printMemo()">
            <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print
        </button>
        <button class="shorter-btn misc-btn" (click)="printCreditMemoRequestForm()">
            <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print Request Form
        </button>
    </div>
</section>
  