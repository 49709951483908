import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faBan,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { UserManagementService } from "src/app/services/administration/user-management.service";
import { NotifierService } from "angular-notifier";
import { of, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { isEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: "app-user-management-detail",
  templateUrl: "./user-management-detail.component.html",
  styleUrls: ["./user-management-detail.component.css"],
})
export class UserManagementDetailComponent implements OnInit {
  // ICONS
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;

  mode = "Add";
  userId: string = "";
  activeRoles: any[] = [];
  activeTeams: any[] = [];
  user!: FormField[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userManagementService: UserManagementService,
    private notifier: NotifierService,
  ) {}

  ngOnInit(): void {
    this.setModeAndUserId();
    this.fetchActiveRolesAndTeams().subscribe({
      next: () => this.setUpFormFields(),
      error: (error) =>
        this.handleError("Error initializing data", error),
    });
  }

  updateFields(event: any): void {
    this.user = event.fields;
  }

  updateUser(): void {
    const updatedUser = this.populateUserRequestBody();
    this.userManagementService.updateUser(updatedUser).subscribe({
      next: () =>
        this.handleSuccess("User updated", "../../user-management"),
      error: (error) =>
        this.handleError("Error updating user", error),
    });
  }

  addUser(): void {
    const newUser = this.populateUserRequestBody();
    this.userManagementService.addUser(newUser).subscribe({
      next: () =>
        this.handleSuccess("User added", "../../user-management"),
      error: (error) => this.handleError("Error adding user", error),
    });
  }

  private setModeAndUserId(): void {
    this.activatedRoute.params
      .pipe(
        tap((data) => {
          this.userId = data["id"];
          if (!isEmptyGuid(this.userId)) {
            this.mode = "Detail";
          }
        }),
      )
      .subscribe();
  }

  private fetchActiveRolesAndTeams() {
    return of(null).pipe(
      switchMap(() =>
        this.userManagementService.getAllActiveRoles().pipe(
          tap((response) => this.processActiveRoles(response.body)),
          switchMap(() =>
            this.userManagementService.getAllSalesTeam(),
          ),
          tap((response) => this.processActiveTeams(response.body)),
        ),
      ),
    );
  }

  private processActiveRoles(responseBody: any[]): void {
    this.activeRoles = responseBody
      .filter((role) => role.Status)
      .map((role) => ({
        display: role.Name,
        value: role.Name,
        id: role.Value,
      }));
  }

  private processActiveTeams(responseBody: any[]): void {
    this.activeTeams = responseBody.map((team) => ({
      display: team.TeamName,
      value: team.TeamName,
    }));
  }

  private setUpFormFields(): void {
    if (this.mode === "Add") {
      this.user = this.getAddUserFormFields();
    } else if (this.mode === "Detail") {
      this.userManagementService
        .getUserDetail(this.userId)
        .subscribe({
          next: (response) =>
            (this.user = this.getDetailUserFormFields(response.body)),
          error: (error) =>
            this.handleError("Error getting user data", error),
        });
    }
  }

  private getAddUserFormFields(): FormField[] {
    return [
      {
        type: "text",
        id: "userId",
        label: "User Id",
        required: true,
      },
      {
        type: "doubleText",
        id: "fullName",
        label: "Full Name",
        required: true,
        value: ["", ""],
      },
      {
        type: "password",
        id: "password",
        label: "Password",
        required: true,
      },
      {
        type: "password",
        id: "confirmPassword",
        label: "Confirm password",
        required: true,
      },
      {
        type: "select",
        id: "userType",
        label: "User Type",
        required: true,
        options: this.activeRoles,
      },
      {
        type: "select",
        id: "team",
        label: "Team",
        options: this.activeTeams,
      },
      {
        type: "select",
        id: "rightsLevel",
        label: "Rights Level",
        required: true,
        options: this.getRightsLevelOptions(),
      },
      { type: "text", id: "agent", label: "Agent" },
      { type: "text", id: "dealersStafff", label: "Dealers Stafff" },
      { type: "text", id: "address", label: "Address" },
      {
        type: "text",
        id: "telephoneNumber",
        label: "Telephone Number",
      },
      { type: "text", id: "mobileNumber", label: "Mobile Number" },
      {
        type: "text",
        id: "emailAddress",
        label: "Email Address",
        required: true,
      },
      { type: "text", id: "faxNumber", label: "Fax Number" },
      { type: "text", id: "serialNumber", label: "Serial Number" },
      {
        type: "select",
        id: "status",
        label: "Status",
        required: true,
        value: false,
        options: this.getStatusOptions(),
      },
      { type: "text", id: "HMGICSID", label: "HMGICS ID" },
    ];
  }

  private getDetailUserFormFields(responseBody: any): FormField[] {
    return [
      {
        type: "text",
        id: "userId",
        label: "User Id",
        lock: true,
        value: responseBody.LoginId,
      },
      {
        type: "doubleText",
        id: "fullName",
        label: "Full Name",
        required: true,
        value: [responseBody.FirstName, responseBody.LastName],
      },
      {
        type: "select",
        id: "userType",
        label: "User Type",
        required: true,
        options: this.activeRoles,
        value: responseBody.RoleName,
      },
      {
        type: "select",
        id: "team",
        label: "Team",
        options: this.activeTeams,
        value: responseBody.Team,
      },
      {
        type: "select",
        id: "rightsLevel",
        label: "Rights Level",
        required: true,
        value: responseBody.RightsLevel,
        options: this.getRightsLevelOptions(),
      },
      {
        type: "text",
        id: "agent",
        label: "Agent",
        value: responseBody.Agent,
      },
      {
        type: "text",
        id: "dealersStafff",
        label: "Dealers Stafff",
        value: responseBody.DealersStaff,
      },
      {
        type: "text",
        id: "address",
        label: "Address",
        value: responseBody.Address,
      },
      {
        type: "text",
        id: "telephoneNumber",
        label: "Telephone Number",
        value: responseBody.TelephoneNumber,
      },
      {
        type: "text",
        id: "mobileNumber",
        label: "Mobile Number",
        value: responseBody.MobileNumber,
      },
      {
        type: "text",
        id: "emailAddress",
        label: "Email Address",
        value: responseBody.EmailId,
        required: true,
      },
      {
        type: "text",
        id: "faxNumber",
        label: "Fax Number",
        value: responseBody.FaxNumber,
      },
      {
        type: "text",
        id: "serialNumber",
        label: "Serial Number",
        value: responseBody.SerialNumber,
      },
      {
        type: "select",
        id: "status",
        label: "Status",
        required: true,
        value: responseBody.Status,
        options: this.getStatusOptions(),
      },
      {
        type: "text",
        id: "HMGICSID",
        label: "HMGICS ID",
        value: responseBody.HmgicsId,
      },
    ];
  }

  private populateUserRequestBody(): any {
    return {
      UserId: this.userId,
      Address: this.getFieldValue("address"),
      Agent: this.getFieldValue("agent"),
      ConfirmPassword: this.getFieldValue("confirmPassword"),
      DealersStaff: this.getFieldValue("dealersStafff"),
      EmailId: this.getFieldValue("emailAddress"),
      FaxNumber: this.getFieldValue("faxNumber"),
      FirstName: this.getFieldValue("fullName", 0),
      HmgicsId: this.getFieldValue("HMGICSID"),
      LastName: this.getFieldValue("fullName", 1),
      LoginId: this.getFieldValue("userId"),
      MobileNumber: this.getFieldValue("mobileNumber"),
      Password: this.getFieldValue("password"),
      RightsLevel: this.getFieldValue("rightsLevel"),
      RoleId: this.getSelectedRoleId(),
      RoleName: this.getFieldValue("userType"),
      SerialNumber: this.getFieldValue("serialNumber"),
      Status: this.getFieldValue("status"),
      Team: this.getFieldValue("team"),
      TelephoneNumber: this.getFieldValue("telephoneNumber"),
      __type: "KAPS.Model.UserModel.CrmUser",
    };
  }

  private getFieldValue(id: string, index?: number): any {
    const field = this.user.find((f) => f.id === id);
    return index !== undefined ? field?.value[index] : field?.value;
  }

  private getSelectedRoleId(): string | undefined {
    const selectedRole = this.activeRoles.find(
      (role) => role.display === this.getFieldValue("userType"),
    );
    return selectedRole?.id;
  }

  private getStatusOptions(): any[] {
    return [
      { display: "Inactive", value: false },
      { display: "Active", value: true },
    ];
  }

  private getRightsLevelOptions(): any[] {
    return [
      { display: "", value: 0 },
      { display: "1", value: 1 },
      { display: "2", value: 2 },
      { display: "3", value: 3 },
      { display: "4", value: 4 },
    ];
  }

  private handleSuccess(message: string, redirectPath: string): void {
    this.notifier.notify("success", message);
    this.router.navigate([redirectPath], {
      relativeTo: this.activatedRoute,
    });
  }

  private handleError(message: string, error: any): void {
    console.error(message, error);
    this.notifier.notify("error", message);
  }
}
