<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Amend cheque purpose</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button class="shorter-btn green-btn">
      <fa-icon [icon]="faPencil" class="mr-1" />Update
    </button>
    <button class="shorter-btn gray-btn">
      <fa-icon [icon]="faBroom" class="mr-1" />Clear
    </button>
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="amendCheque"
      [dataName]="'amendCheque'"
      (updateFields_child)="updateFields($event)"
    />
  </div>
</div>
