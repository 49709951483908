<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Vehicle stock availability</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <label for="" class="block border-b pb-4">
      <app-data-form
        [dataFields]="upperFormFields"
        [dataName]="'upperFormFields'"
        (updateFields_child)="handleUpdate($event)"
      ></app-data-form>
    </label>
    <app-data-table
      *ngIf="tableVisible"
      [tableHeaders]="tableHeaders_newTable"
      [tableEntries]="filteredData_newTable"
      [pagination]="false"
      (open)="openEntry($event)"
      class="my-4 block border-b pb-4"
    ></app-data-table>

    <p class="text-red-500 mt-8">
      <strong>Disclaimer</strong>: The expected shipment arrival dates
      in months depicted <strong>are estimates only</strong>. While we
      try our best to keep the stock details up to date, they do not
      necessarily reflect the latest information available from the
      HMC due to factory glitches, union strikes, vessel delays,
      homologations and other events which are out of our control.
      <br />
      <br />
      You are reminded to advise your customers that the expected
      shipment arrival dates
      <strong>may be subjected to delays</strong>.
    </p>
  </section>
</section>
