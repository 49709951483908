<section class="page-container">
  <div class="flex items-center justify-between">
      <h1 class="main-heading">Price List Template Report</h1>
      <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form [dataFields]="formFields" />
  </div>
  <div class="mb-2 mt-4 flex w-fit gap-1">
      <button class="shorter-btn misc-btn" (click)="printPassengerPriceList()">
          <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print Passenger Price List
      </button>
      <button class="shorter-btn delete-btn" (click)="printCommercialPriceList()">
          <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print Commercial Price List
      </button>
      <button class="shorter-btn delete-btn" (click)="printSTAREXFlyerEng()">
        <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print STAREX-Flyer Eng
      </button>
  </div>
</section>