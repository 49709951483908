import { Component, Output, EventEmitter } from '@angular/core';
import { faPrint,} from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-print-input',
  templateUrl: './print-input.component.html',
  styleUrls: ['./print-input.component.css']
})
export class PrintInputComponent {
  @Output() dataOutput = new EventEmitter<string>();

  faPrint = faPrint

}
