<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">COE bid staff</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="searchCriteria"
      [dataName]="'searchCriteria'"
      (updateFields_child)="updateFields($event)"
    />
    <div class="mt-2 flex w-fit gap-1">
      <button class="shorter-btn lighter-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1" />New
      </button>
      <button class="shorter-btn green-btn">
        <fa-icon [icon]="faSave" class="mr-1" />Update
      </button>
      <button class="shorter-btn red-btn">
        <fa-icon [icon]="faTrash" class="mr-1" />Delete
      </button>
      <button class="shorter-btn alt-red-btn">
        <fa-icon [icon]="faPrint" class="mr-1" />Print
      </button>
    </div>
  </div>
</div>
