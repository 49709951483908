<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">
        <fa-icon [icon]="faCar" class="mr-1" />Payment Status
      </h1>
      <app-navpath />
    </div>
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1" />Genrate Buyer's Order
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faEraser" class="mr-1" />Clear
      </button>
    </div>
    <div class="content-container">
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Search</h2>
        <app-data-form
          [dataFields]="searchBooking"
          [dataName]="'searchBooking'"
          (updateFields_child)="updateFields($event)"
        />
      </section>

      <ul class="tabs-container">
        <li
          (click)="selectedTab = 'Balance'"
          [ngClass]="
            selectedTab == 'Balance' ? 'tab-item_selected' : 'tab-item'
          "
        >
          Balance of Payment
        </li>
        <li
          (click)="selectedTab = 'Buyer'"
          [ngClass]="
            selectedTab == 'Buyer' ? 'tab-item_selected' : 'tab-item'
          "
        >
          Buyer's Account
        </li>
        <li
          (click)="selectedTab = 'Sales'"
          [ngClass]="
            selectedTab == 'Sales' ? 'tab-item_selected' : 'tab-item'
          "
        >
          Sales Consultan's Acount
        </li>
        <li
          (click)="selectedTab = 'komoco'"
          [ngClass]="
            selectedTab == 'komoco' ? 'tab-item_selected' : 'tab-item'
          "
        >
          Komoco's Acount
        </li>
        <li
          (click)="selectedTab = 'Others'"
          [ngClass]="
            selectedTab == 'Others' ? 'tab-item_selected' : 'tab-item'
          "
        >
          Others
        </li>
      </ul>

      <section class="content-container__tabs">
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">
            Details of Contract
          </h2>
          <app-data-form
            [dataFields]="buyersDetails"
            [dataName]="'buyersDetails'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">
            Due Amount from Buyer
          </h2>
          <app-data-form
            [dataFields]="detailsCashDiscount"
            [dataName]="'detailsCashDiscount'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">
            Adjustment Amounts
          </h2>
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">
            Rebates from Komoco
          </h2>
          <app-data-form
            [dataFields]="detailsOtherFees"
            [dataName]="'detailsOtherFees'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">
            Rebates from Trade-In Vehicle
          </h2>
          <app-data-form
            [dataFields]="detailsInsurance"
            [dataName]="'detailsInsurance'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">Total Rebates</h2>
          <app-data-form
            [dataFields]="detailsHireApplication"
            [dataName]="'detailsHireApplication'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">
            Details of Hire Purchase Application
          </h2>
          <app-data-form
            [dataFields]="detailsTradeInVehicle"
            [dataName]="'detailsTradeInVehicle'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Balance' || selectedTab == '')"
        >
          <h2 class="sub-heading w-full border-b">Payment Status</h2>
          <app-data-form
            [dataFields]="additionalRemarks"
            [dataName]="'additionalRemarks'"
            (updateFields_child)="updateFields($event)"
          />
        </div>

        <app-data-form
          [hidden]="!(selectedTab == 'Buyer')"
          [dataFields]="detailsVehicle"
          [dataName]="'detailsVehicle'"
          (updateFields_child)="updateFields($event)"
        />
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Sales')"
        >
          <app-data-form
            [dataFields]="buyersDetails"
            [dataName]="'buyersDetails'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Sales')"
        >
          <app-data-form
            [dataFields]="buyersDetails2"
            [dataName]="'buyersDetails2'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'komoco')"
        >
          <app-data-form
            [dataFields]="detailsVehicle4"
            [dataName]="'detailsVehicle4'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Others')"
        >
          <h2 class="sub-heading w-full border-b">Short Payment</h2>
          <app-data-form
            [dataFields]="detailsVehicle2"
            [dataName]="'detailsVehicle2'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Others')"
        >
          <h2 class="sub-heading w-full border-b">
            Hire Purchase Settlement
          </h2>
          <app-data-form
            [dataFields]="detailsVehicle3"
            [dataName]="'detailsVehicle3'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
        <div
          class="content-container"
          [hidden]="!(selectedTab == 'Others')"
        >
          <h2 class="sub-heading w-full border-b">
            Cheques Issued
          </h2>
        </div>
      </section>

      <div class="mt-4 flex w-fit gap-1">
        <button class="shorter-btn gray-btn">
          <fa-icon [icon]="faEraser" class="mr-1" />Clear
        </button>
      </div>
    </div>
  </section>
