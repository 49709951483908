export interface Team {
  Id: number;
  TeamName: string;
  Description: string;
  IsDeleted: boolean;
}

export interface Role {
  __type: string;
  Value: string;
  Name: string;
  Description: string;
  Status: boolean;
}

export class CommunicationPreferences {
  IsAgreeDirectMail: boolean = false;
  IsAgreeEMail: boolean = false;
  IsAgreeSMS: boolean = false;
  IsAgreePhone: boolean = false;
  All: boolean = false;
  DoNotReceive: boolean = false;
}

export interface User {
  __type: string;
  SystemLoginId: string;
  UserId: string;
  LoginId: string;
  Password: string;
  ConfirmPassword: string;
  RoleId: string;
  RoleName: string;
  FirstName: string;
  LastName: string;
  Team: string;
  RightsLevel: number;
  Agent: string;
  DealersStaff: string;
  Address: string;
  EmailId: string;
  TelephoneNumber: string;
  MobileNumber: string;
  FaxNumber: string;
  SerialNumber: string;
  Status: boolean;
  HmgicsId: string;
}

export interface Customer {
  AddressBlock: string;
  AddressRoad: string;
  AddressUnit: string;
  Category: string;
  CreatedBy: string;
  CreatedDate: string;
  CurrentCarModel: string;
  CurrentRegDate: string;
  CurrentRegNo: string;
  CustomerId: string;
  DoNotReceive: boolean;
  Dob: string;
  Email: string;
  FamilyName: string;
  GivenName: string;
  Hp: string;
  IcNo: string;
  IsAddedFromTestDrive: boolean;
  IsAgreeDirectMail: boolean;
  IsAgreeEMail: boolean;
  IsAgreePhone: boolean;
  IsAgreeSMS: boolean;
  IsBought: boolean;
  IsDeleted: boolean;
  LastModifiedBy: string;
  LastModifiedDate: string;
  LicenseNo: string;
  ModeContact: string;
  ModelInterested: string;
  Name: string;
  Occupation: string | null;
  OfficeNo: string | null;
  PostalCode: string;
  Remarks: string;
  ResidenceNo: string;
  Source: string;
  Status: string | null;
  Title: string;
  UserOwner: string;
}

export interface Prospect {
  AddedFromTestDrive: string;
  AddressBlock: string;
  AddressRoad: string;
  AddressUnit: string;
  Bought: string;
  Category: string;
  CreatedBy: string;
  CreatedDate: string;
  CurrentCarModel: string;
  CurrentRegDate: string;
  CurrentRegNo: string;
  CustomerId: number;
  DoNotReceive: boolean;
  Dob: string;
  Email: string;
  FamilyName: string;
  Gender: string;
  GivenName: string;
  Hp: string;
  IcNo: string;
  IsAgreeDirectMail: string;
  IsAgreeEMail: boolean;
  IsAgreePhone: boolean;
  IsAgreeSMS: boolean;
  IsBought: boolean;
  IsDeleted: boolean;
  IsIsAgreeDirectMail: boolean;
  IsIsAgreePhone: boolean;
  IsIsAgreeSMS: boolean;
  LastModifiedBy: string;
  LastModifiedDate: string;
  LicenseNo: string;
  ModeContact: string;
  ModelInterested: string;
  Name: string;
  Occupation: string;
  OfficeNo: string;
  PostalCode: string;
  Remarks: string;
  ResidenceNo: string;
  Source: string;
  Status: string;
  Title: string;
  UserOwner: string;
  modelinterestedothers: string;
}
