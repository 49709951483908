import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";
@Injectable({
  providedIn: "root",
})
export class CustomerSearchService {
  private contractData: any; // Stores the selected contract data

  constructor(private http: HttpUtilService) {}

  /**
   * Stores the selected contract data to be used between components.
   * @param data The contract data to be stored.
   */
  setContractData(data: any): void {
    this.contractData = data;
  }

  /**
   * Retrieves the stored contract data.
   * @returns The stored contract data.
   */
  getContractData(): any {
    return this.contractData;
  }

  /**
   * Clears the stored contract data.
   */
  clearContractData(): void {
    this.contractData = null;
  }

  /**
   * Fetches the models.
   * @returns An Observable of the settings data.
   */
  getModelDrpList(): Observable<any> {
    return this.http.get('/SalesReportService.asmx/GetModelDrpList');
  }

  /**
   * Fetches all active users.
   * @returns An Observable of the settings data.
   */
  getAllActiveUsers(): Observable<any> {
    return this.http.get('/LookUpService.asmx/GetAllActiveUsers');
  }

  /**
   * Fetches contracts based on search criteria.
   * @param searchText The text to search for in contracts.
   * @param searchType The type of search (e.g., "postalcode").
   * @returns An Observable of the search results.
   */
  findContract(searchText: string, searchType: string): Observable<any> {
    const encodedSearchText = encodeURIComponent(`"${searchText}"`);
    const encodedSearchType = encodeURIComponent(`"${searchType}"`);
    const url = `/ContractService.asmx/FindContract?searchText=${encodedSearchText}&searchType=${encodedSearchType}`;
    return this.http.get(url);
  }

  /**
 * Updates the sales contract with the provided data.
 * @param contractData The updated contract data.
 * @returns An Observable of the update result.
 */
updateSalesContract(contractData: any): Observable<any> {
  const url = '/CustomerService.asmx/UpdateSalesContract';
  return this.http.post(url, { data: contractData });
}

/**
   * Prints contracts based on search contractNo.
   * @param contractNo The contractNo to be search.
   * @returns An Observable of the search results.
   */
print(contractNo: string): Observable<any> {
  const encodedContractNo = encodeURIComponent(`"${contractNo}"`);
  const url = `BookingService.asmx/Print?contractNo=${encodedContractNo}`;
  return this.http.get(url);
}

/**
   * export customer's info based on selection.
   * @param id Id of the selected model.
   * @returns An Observable of the search results.
   */
exportCustomer(id: string): Observable<any> {
  const url = `CustomerService.asmx/ExportCustomer?id=${id}`;
  return this.http.get(url);
}
}
