<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Weekly Focus Report</h1>
    </div> 
    <section class="content-container">
        <div class="mb-2 flex w-fit gap-1">
        <button class="shorter-btn misc-btn" (click)="addEntry()">
          <fa-icon [icon]="['fas', 'plus']" class="mr-1"></fa-icon>Add
        </button>
      </div>
      <app-data-table
        [tableHeaders]="tableHeaders"
        [tableEntries]="tableEntries"
        (open)="openEntry($event)"   
        (remove)="deleteEntry($event)"
      ></app-data-table>  
    </section>
  </section>
  