import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-inventory-vehicle',
  templateUrl: './inventory-vehicle.component.html',
  styleUrls: ['./inventory-vehicle.component.css']
})
export class InventoryVehicleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
