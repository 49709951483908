import { Component, OnInit } from '@angular/core';
import { FormField } from 'src/app/interfaces/form-field.model';

@Component({
  selector: 'app-cheque-enquiry',
  templateUrl: './cheque-enquiry.component.html',
  styleUrls: ['./cheque-enquiry.component.css']
})
export class ChequeEnquiryComponent implements OnInit {

  chequeEnquiry!: FormField[];

  updateFields(event: any):void {
    this.chequeEnquiry = event.fields;
  }

  constructor() { }

  ngOnInit() {
    this.chequeEnquiry = [
      {
        type: "search",
        id: "chequeNumber",
        label: "Cheque number",
      },
      {
        type: "text",
        id: "buyerName",
        label: "Buyer name",
        lock: true
      },
      {
        type: "text",
        id: "contractNumber",
        label: "Contract number",
        lock: true
      },
      {
        type: "text",
        id: "payTo",
        label: "Pay to",
        lock: true
      },
      {
        type: "text",
        id: "chequeDate",
        label: "Cheque date",
        lock: true
      },
      {
        type: "text",
        id: "bank",
        label: "Bank",
        lock: true
      },
      {
        type: "text",
        id: "amount",
        label: "Amount",
        lock: true
      },
      {
        type: "text",
        id: "purpose",
        label: "Purpose",
        lock: true
      },
    ]
  }

}
