import { Component, OnInit } from "@angular/core";
import {
  faPrint,
  faMagnifyingGlass,
  faBroom,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-duty-pullout",
  templateUrl: "./duty-pullout.component.html",
  styleUrls: ["./duty-pullout.component.css"],
})
export class DutyPulloutComponent implements OnInit {
  faPrint = faPrint;
  faMagnifyingGlass = faMagnifyingGlass;
  faBroom = faBroom;
  faSave = faSave;
  searchCriteria!: FormField[];
  tableHeaders = [
    {
      title: "DUTY PAID?",
      type: "data",
    },
    {
      title: "MODEL",
      type: "data",
    },
    {
      title: "COLOUR",
      type: "data",
    },
    {
      title: "VIN NUMBER",
      type: "data",
    },
    {
      title: "ETA DATE",
      type: "data",
    },
    {
      title: "CAR TYPE",
      type: "data",
    },
    {
      title: "BUYER'S NAME",
      type: "data",
    },
    {
      title: "CONTRACT NUMBER",
      type: "data",
    },
    {
      title: "SALES CONSULTANT",
      type: "data",
    },
    {
      title: "STOCK STATUS",
      type: "data",
    },
    {
      title: "DUTY",
      type: "checkbox",
    },
  ];
  tableEntries = [
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "A2B",
      vinNumber: "ZDAE005",
      etaDate: "09/05/2024",
      carType: "",
      buyersName: "CAR TIMES AUTO-RENT PTE LTD",
      contractNumber: "24040036",
      salesConsultant: "KRT",
      stockStatus: "A",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "A2B",
      vinNumber: "ZDAE098",
      etaDate: "09/05/2024",
      carType: "",
      buyersName: "CAR TIMES AUTO-RENT PTE LTD",
      contractNumber: "24040038",
      salesConsultant: "KRT",
      stockStatus: "A",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "R2P",
      vinNumber: "ZDAE018",
      etaDate: "09/05/2024",
      carType: "",
      buyersName: "Alexandra Showroom 14 June 24",
      contractNumber: "",
      salesConsultant: "",
      stockStatus: "R",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S" (PHV)',
      colour: "A2B",
      vinNumber: "ZDAB007",
      etaDate: "09/05/2024",
      carType: "",
      buyersName: "display at GRAB",
      contractNumber: "",
      salesConsultant: "",
      stockStatus: "K",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "A2B",
      vinNumber: "ZDAE005",
      etaDate: "09/05/2024",
      carType: "09/05/2024",
      buyersName: "CAR TIMES AUTO-RENT PTE LTD",
      contractNumber: "24040036",
      salesConsultant: "KRT",
      stockStatus: "A",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "A2B",
      vinNumber: "ZDAE005",
      etaDate: "09/05/2024",
      carType: "09/05/2024",
      buyersName: "CAR TIMES AUTO-RENT PTE LTD",
      contractNumber: "24040036",
      salesConsultant: "KRT",
      stockStatus: "A",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "A2B",
      vinNumber: "ZDAE005",
      etaDate: "09/05/2024",
      carType: "09/05/2024",
      buyersName: "CAR TIMES AUTO-RENT PTE LTD",
      contractNumber: "24040036",
      salesConsultant: "KRT",
      stockStatus: "A",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "A2B",
      vinNumber: "ZDAE005",
      etaDate: "09/05/2024",
      carType: "09/05/2024",
      buyersName: "CAR TIMES AUTO-RENT PTE LTD",
      contractNumber: "24040036",
      salesConsultant: "KRT",
      stockStatus: "A",
      checked: true,
    },
    {
      dutyPaid: "Y",
      model: 'AVANTE 1.6 DCT HYBRID "S"',
      colour: "A2B",
      vinNumber: "ZDAE005",
      etaDate: "09/05/2024",
      carType: "09/05/2024",
      buyersName: "CAR TIMES AUTO-RENT PTE LTD",
      contractNumber: "24040036",
      salesConsultant: "KRT",
      stockStatus: "A",
      checked: true,
    },
  ];
  constructor() {}

  ngOnInit() {
    this.searchCriteria = [
      {
        type: "date",
        id: "etaDate",
        label: "To ETA date (dd/mm/yyyy)",
      },
      {
        type: "date",
        id: "pulloutListDate",
        label: "Pullout list date (dd/mm/yyyy)",
      },
      {
        type: "select",
        id: "selectFormat",
        label: "Select format",
        value: "PDF",
        options: [
          {
            display: "PDF",
            value: "PDF",
          },
          {
            display: "EXCEL",
            value: "EXCEL",
          },
          {
            display: "BOTH",
            value: "BOTH",
          },
        ],
      },
    ];
  }
}
