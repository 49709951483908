import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-control-parameters',
  templateUrl: './control-parameters.component.html',
  styleUrls: ['./control-parameters.component.css']
})
export class ControlParametersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
