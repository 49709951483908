import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { faTag, faUndo } from "@fortawesome/free-solid-svg-icons";
import { ClickDriveCustomerService } from "src/app/services/customer/click-drive-customer.service";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "src/app/services/util.service";
import { model } from "src/app/models/customer/click-drive-customer";

@Component({
  selector: "app-generate-voucher",
  templateUrl: "./generate-voucher.component.html",
  styleUrls: ["./generate-voucher.component.css"],
})
export class GenerateVoucherComponent implements OnInit {
  faTag = faTag;
  faUndo = faUndo;
  email = "";

  items: {
    "Id": string;
    "Name": string;
    "Allowed": boolean;
    "Selected": boolean;
  }[] = [];

  constructor(
    private ClickDriveCustomerService: ClickDriveCustomerService,
    private notifier: NotifierService,
    private dialog: MatDialog,
  ) {}

  GenerateVoucher(): void {
    const model = {
      Email: this.email,
      Id: getEmptyGuid(),
      PromotionItem: this.items.filter((item) => item.Selected),
    };
    const dialogRef = this.dialog.open(DialogContent, {
      height: "300px",
      enterAnimationDuration: "200ms",
      exitAnimationDuration: "200ms",
      data: model,
    });
  }

  ngOnInit() {
    this.ClickDriveCustomerService.GetAllActive().subscribe({
      next: (response) => {
        this.items = response.body;
        for (let index = 0; index < this.items.length; index++) {
          this.items[index].Selected = false;
        }
      },
      error: (error) => {
        console.log(error);
        this.notifier.notify("error", "Error getting items");
      },
    });
  }

  public isValidEmail(email: string) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
  }
}

@Component({
  selector: "dialog-content",
  template: `
    <div class="flex h-full flex-col justify-around">
      <h1 mat-dialog-title>Confirmation</h1>
      <div mat-dialog-content>
        <p class="mb-5">
          Are you sure you want to generate voucher for the following
          items?
        </p>
        <ul class="max-h-24 list-disc overflow-y-auto px-4 text-sm">
          <li *ngFor="let item of data.PromotionItem">
            {{ item.Name }}
          </li>
        </ul>
      </div>
      <div mat-dialog-actions class="flex w-full justify-end gap-x-3">
        <button
          mat-button
          (click)="closeDialog()"
          class="shorter-btn red-btn"
        >
          No
        </button>
        <button
          mat-button
          (click)="closeDialog(data)"
          class="shorter-btn green-btn"
        >
          Yes
        </button>
      </div>
    </div>
  `,
})
export class DialogContent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogContent>,
    private ClickDriveCustomerService: ClickDriveCustomerService,
    private notifier: NotifierService,
  ) {}

  closeDialog(model?: model): void {
    if (model) {
      this.ClickDriveCustomerService.GenerateVoucher(model).subscribe(
        {
          next: () => {
            this.notifier.notify("success", "Voucher generated");
          },
          error: (error) => {
            console.log(error);
            this.notifier.notify("error", "Error adding voucher");
          },
        },
      );
      console.log(model);
    }
    this.dialogRef.close(true);
  }
}
