import { Component } from '@angular/core';
import { BuyersOrderReportService } from 'src/app/services/sales-reports/buyers-order-report.service';
import { faPrint, faEye } from '@fortawesome/free-solid-svg-icons';
import { FormField } from 'src/app/interfaces/form-field.model';

@Component({
  selector: 'app-buyers-order-report',
  templateUrl: './buyers-order-report.component.html',
  styleUrls: ['./buyers-order-report.component.css']
})
export class BuyersOrderReportComponent {
  faEye = faEye;
  faPrint = faPrint;

  showTable = false; 
  tableEntries: any[] = [];

  formFields: FormField[] = [
    { type: 'date', id: 'FromDate', label: "From Buyer's Order Date (dd/mm/yyyy) *", value: '', required: true },
    { type: 'date', id: 'ToDate', label: "To Buyer's Order Date (dd/mm/yyyy) *", value: '', required: true },
    {
      type: 'select', id: 'Show', label: 'Show', options: [
        { display: 'My buyers order', value: 'BuyerOrder' },
        { display: "My team's buyers order", value: 'TeamBuyerOrder' },
        { display: 'All buyers order', value: 'All' }
      ], value: 'All'
    },
    {
      type: 'select', id: 'GroupBy', label: 'Group By', options: [
        { display: '', value: '' },
        { display: 'Model Group', value: 'ModelGroup' },
        { display: 'Customer NRIC', value: 'CustomerNRIC' },
        { display: 'Customer Name', value: 'CustomerName' },
        { display: 'Sales Consultant', value: 'Salesconsultant' },
        { display: 'Sales Team', value: 'Salesteam' },
        { display: 'Registration Date', value: 'RegistrationDate' }
      ], value: ''
    },
    {
      type: 'select', id: 'SortBy', label: 'Sort By', options: [
        { display: '', value: '' },
        { display: 'Model Group', value: 'ModelGroup' },
        { display: 'Sales Consultant', value: 'Salesconsultant' },
        { display: 'Sales Team', value: 'Salesteam' },
        { display: 'Customer Name', value: 'CustomerName' },
        { display: 'Contract No', value: 'ContractNo' },
        { display: 'Contract Date', value: 'ContractDate' },
        { display: 'Buyer Order No', value: 'BuyerOrderNo' },
        { display: 'Buyer Order Date', value: 'BuyerOrderDate' },
        { display: 'Registration Date', value: 'RegistrationDate' },
        { display: 'Registration No', value: 'RegistrationNo' }
      ], value: ''
    },
    {
      type: 'select',
      id: 'Format',
      label: 'Select format',
      options: [
        { display: 'PDF', value: 'pdf' },
        { display: 'EXCEL', value: 'excel' },
        { display: 'BOTH', value: 'both' }
      ],
      value: 'pdf'
    }
  ];

  tableHeaders = [
    { title: "BUYER'S ORDER NUMBER", type: 'data', id: 'BuyerOrderNumber' },
    { title: 'BUYER ORDER DATE', type: 'data', id: 'BuyerOrderDate' },
    { title: 'CONTRACT NO.', type: 'data', id: 'ContractNo' },
    { title: 'CONTRACT DATE (AUTOPAC)', type: 'data', id: 'ContractDate' },
    { title: 'CUSTOMER NAME', type: 'data', id: 'CustomerName' },
    { title: 'SMAN CODE', type: 'data', id: 'SalesmanCode' },
    { title: 'VEHICLE MODEL', type: 'data', id: 'VehicleModel' },
    { title: 'MODEL COLOUR', type: 'data', id: 'ModelColour' },
    { title: 'COE TYPE', type: 'data', id: 'COEType' },
    { title: 'COE OFFSET (REBATE LEVEL)', type: 'data', id: 'COEOffset' },
    { title: 'LOAN AMOUNT', type: 'data', id: 'LoanAmount' },
    { title: 'LOAN RATE', type: 'data', id: 'LoanRate' },
    { title: 'LOAN PERIOD', type: 'data', id: 'LoanPeriod' },
    { title: 'LOAN REMARK', type: 'data', id: 'LoanRemark' },
    { title: 'FINANCE STATUS', type: 'data', id: 'FinanceStatus' },
    { title: 'CASE NO.', type: 'data', id: 'CaseNumber' },
    { title: 'PRICE (INCLUSIVE OF FREE OPTIONS & GST)', type: 'data', id: 'Price' },
    { title: 'DEPOSIT (REPORT)', type: 'data', id: 'Deposit' },
    { title: 'REGISTER DATE', type: 'data', id: 'RegisterDate' }
  ];

  constructor(private buyersOrderReportService: BuyersOrderReportService) {}

  preview(): void {
    const fromDate = this.getField('FromDate').value;
    const toDate = this.getField('ToDate').value;
    const resultBy = this.getField('Show').value;
    const sortBy = this.getField('SortBy').value || ''; 
    const groupBy = this.getField('GroupBy').value || '';
  
    const formattedFromDate = this.formatDate(fromDate);
    const formattedToDate = this.formatDate(toDate);
  
    const payload = {
      model: {
        FromDate: formattedFromDate,
        ToDate: formattedToDate,
        ResultBy: resultBy,
        SortBy: sortBy,
        GroupBy: groupBy
      }
    };
    
    this.buyersOrderReportService.previewBuyerOrder(payload).subscribe({
      next: (response) => {
        
        const data = response.body || [];
        
        this.tableEntries = data.map((group: any) => ({
          ModelGroup: group.ModelGroup || "",
          Entries: group.CrmBookingReport.map((entry: any) => ({
            BuyerOrderNumber: entry.RefNo,
            BuyerOrderDate: entry.RefDate,
            ContractNo: entry.ContactNo,
            ContractDate: entry.ContractDate,
            CustomerName: entry.CustomerName,
            SalesmanCode: entry.SALESCONSULTANT,
            VehicleModel: entry.Model,
            ModelColour: entry.Color,
            COEType: entry.COEType,
            COEOffset: entry.COERebateLevel,
            LoanAmount: entry.LoanAmount,
            LoanRate: entry.LoanRate,
            LoanPeriod: entry.loanPeriod,
            LoanRemark: entry.LoanRemark,
            FinanceStatus: entry.FinStatus,
            CaseNumber: entry.VINNumber,
            Price: entry.FinalSellingPrice,
            Deposit: entry.Deposit,
            RegisterDate: entry.RegisterDate
          })),
          TotalRecords: group.CrmBookingReport.length
        }));
  
        this.showTable = true;
      },
      error: (error) => {
        console.error('Error fetvhing data:', error);
      }
    });
  }
  
  getField(id: string): FormField {
    return this.formFields.find(f => f.id === id) || { id: '', type: 'text', label: '', value: null };
  }

 
  private formatDate(dateString: string): string {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }

  export(): void {
    const fromDate = this.getField('FromDate').value;
    const toDate = this.getField('ToDate').value;
    const resultBy = this.getField('Show').value;
    const sortBy = this.getField('SortBy').value || ''; 
    const groupBy = this.getField('GroupBy').value || '';
    const format = this.getField('Format').value;
  
    if (!fromDate || !toDate) {
      console.error('Error: Missing required dates.');
      return;
    }
  
    const formattedFromDate = this.formatDate(fromDate);
    const formattedToDate = this.formatDate(toDate);
  
    const payload = {
      model: {
        FromDate: formattedFromDate,
        ToDate: formattedToDate,
        ResultBy: resultBy,
        SortBy: sortBy,
        GroupBy: groupBy
      }
    };
  
    console.log('Exporting with payload:', payload);
  
    this.buyersOrderReportService.bookingReport(payload).subscribe({
      next: (response) => {
        console.log('Booking Report API Response:', response);
        this.handleBookingReportResponse(response, format);
      },
      error: (error) => {
        console.error('Error generating Booking Report:', error);
      }
    });
  }

  private handleBookingReportResponse(response: any, format: string): void {
    switch (format) {
      case "pdf":
        if (response.body?.Name) {
          window.open(response.body.Name, "_blank");
        } else {
          console.error("PDF file is not available.");
        }
        break;
      case "excel":
        if (response.body?.Value) {
          window.open(response.body.Value, "_blank");
        } else {
          console.error("Excel file is not available.");
        }
        break;
      case "both":
        if (response.body?.Name) {
          window.open(response.body.Name, "_blank");
        } else {
          console.error("PDF file is not available.");
        }
        if (response.body?.Value) {
          window.open(response.body.Value, "_blank");
        } else {
          console.error("Excel file is not available.");
        }
        break;
      default:
        console.error("Invalid format selected.");
    }
  }
  
}
