import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

/*
Interceptor to intercept requests intended from V2 to KAPS backend while maintaining
cookies/configuration required from V1
*/
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Domains where requested are intercepted
    const interceptedDomains = [
      "localhost",
      "syion.net",
      "komoco.com.sg",
    ];

    if (
      interceptedDomains.some((domain) => req.url.includes(domain))
    ) {
      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      const authReq = req.clone({
        headers: req.headers.set("Content-Type", "application/json; charset=utf-8"),
        withCredentials: true,
      });
      // Send cloned request with header to the next handler.
      return next.handle(authReq);
    }

    // If not match, return original request
    return next.handle(req);
  }
}
