import { Component } from '@angular/core';
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-reservation-dashboard',
  templateUrl: './reservation-dashboard.component.html',
  styleUrls: ['./reservation-dashboard.component.css']
})
export class ReservationDashboardComponent {

  constructor() {}

  ngOnInit() {}
  
  reservationForm: FormField[] = [
    {
      type: "date",
      id: "fromReservationDate",
      label: "From Reservation Date",
    },
    {
      type: "date",
      id: "toReservationDate",
      label: "To Reservation Date",
    },
    {
      type: "select",
      id: "show",
      label: "Show",
      options: [
        { display: "All reservations", value: "All reservations" },
        { display: "My reservations", value: "My reservations" },
        { display: "My team's reservations", value: "My team's reservations" },
      ],
    },
  ];

  
  reservationReportHeaders = [
    {
      title: "RESERVATION NO.",
      type: "link",
    },
    {
      title: "DATE & TIME",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BUYER'S NAME",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "SC",
      type: "data",
    },
    {
      title: "MODEL",
      type: "data",
    },
    {
      title: "COLOUR",
      type: "data",
    },
    {
      title: "COE TYPE",
      type: "data",
    },
    {
      title: "COE OFFSET",
      type: "data",
    },
    {
      title: "REMARKS",
      type: "data",
    },
    {
      title: "LOANSTATUSREMARK",
      type: "data",
    },
  ];

  reservationListHeaders = [
    {
      title: "RESERVATION NO.",
      type: "link",
    },
    {
      title: "RESERVATION DATE",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BUYER'S NAME",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "SC",
      type: "data",
    },
    {
      title: "MODEL",
      type: "data",
    },
    {
      title: "COLOUR",
      type: "data",
    },
    {
      title: "COE OFFSET",
      type: "data",
    },
    {
      title: "REMARKS",
      type: "data",
    },
    {
      title: "LOANSTATUSREMARK",
      type: "data",
    },
  ];

  reservationsEmpty = [
  ]

  reservations = [
      {
        "reservationNumber": "208550",
        "reservationDate": "23/07/2023 05:13:24 PM",
        "buyerName": "TAN SHAO WEI, BIONDI",
        "salesConsultant": "RYN",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "YYY",
        "coeOffset": "$90,000.00",
        "remarks": "",
        "loanStatusRemark": "NF"
      },
      {
        "reservationNumber": "208540",
        "reservationDate": "21/07/2023 05:34:34 PM",
        "buyerName": "WONG SOO CHERN SHIRLEY",
        "salesConsultant": "TAY",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "SAW",
        "coeOffset": "$90,000.00",
        "remarks": "",
        "loanStatusRemark": ""
      },
      {
        "reservationNumber": "208533",
        "reservationDate": "19/07/2023 09:49:18 AM",
        "buyerName": "ALISON TAN BENG NEO",
        "salesConsultant": "REK",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "PM2",
        "coeOffset": "$90,000.00",
        "remarks": "",
        "loanStatusRemark": "NF"
      },
      {
        "reservationNumber": "208530",
        "reservationDate": "16/07/2023 06:16:05 PM",
        "buyerName": "JESSIY AANANDHA MOHAN",
        "salesConsultant": "REK",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "YYY",
        "coeOffset": "$90,000.00",
        "remarks": "",
        "loanStatusRemark": "NF"
      },
      {
        "reservationNumber": "208524",
        "reservationDate": "15/07/2023 06:59:12 PM",
        "buyerName": "PREMKUMAR S/O KULASEKHARAM",
        "salesConsultant": "PCY",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "RRR",
        "coeOffset": "$90,000.00",
        "remarks": "-8 years/160,000km mileage whichever comes first on high voltage battery warranty -7 years or 140,000km free servicing whichever comes first",
        "loanStatusRemark": ""
      },
      {
        "reservationNumber": "208513",
        "reservationDate": "09/07/2023 03:15:37 PM",
        "buyerName": "ROHAIYATI BINTE ALLADAD KHAN",
        "salesConsultant": "REK",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "PM2",
        "coeOffset": "$90,000.00",
        "remarks": "",
        "loanStatusRemark": "NF"
      },
      {
        "reservationNumber": "208511",
        "reservationDate": "09/07/2023 12:11:56 PM",
        "buyerName": "CHIA PEH CHOO",
        "salesConsultant": "REK",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "PM2",
        "coeOffset": "$90,000.00",
        "remarks": "waive 6 years service for 3000 discount",
        "loanStatusRemark": "NF"
      },
      {
        "reservationNumber": "208506",
        "reservationDate": "04/07/2023 06:30:01 PM",
        "buyerName": "HO SHU YEE",
        "salesConsultant": "JNG",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "A2B",
        "coeOffset": "$90,000.00",
        "remarks": "",
        "loanStatusRemark": ""
      },
      {
        "reservationNumber": "208488",
        "reservationDate": "01/07/2023 01:12:00 PM",
        "buyerName": "MUHAMMAD SAIFUDIN BIN TUMANI",
        "salesConsultant": "REK",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "R2P",
        "coeOffset": "$90,000.00",
        "remarks": "",
        "loanStatusRemark": "NF"
      },
      {
        "reservationNumber": "208420",
        "reservationDate": "05/06/2023 06:10:36 PM",
        "buyerName": "LIN HANCHENG",
        "salesConsultant": "JRN",
        "model": "KONA 1.6 DCT HYBRID ELITE (S/R)",
        "colour": "C5G",
        "coeOffset": "$84,000.00",
        "remarks": "",
        "loanStatusRemark": "NF"
      }
    ]

}
