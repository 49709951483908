import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import { vehicle } from "src/app/models/sales/vehicle-stock-summary-report";

@Injectable({
  providedIn: "root",
})
export class VehicleStockSummaryReportService {
  constructor(private http: HttpUtilService) {}

  GetVehicleStockSummary(): Observable<HttpResponse<vehicle[]>> {
    return this.http.get(
      "VehicleStockSummaryReportService.asmx/GetVehicleStockSummary",
    );
  }

  Delete(items: { Id: string; Category: string }[]): Observable<any> {
    return this.http.post(
      "VehicleStockSummaryReportService.asmx/Delete",
      {
        items,
      },
    );
  }

  GetModelShortDescList(): Observable<
    HttpResponse<
      { "ModelCode": string; "ModelShortDescription": string }[]
    >
  > {
    return this.http.get(
      "VehicleStockSummaryReportService.asmx/GetModelShortDescList",
    );
  }

  GetActiveModels(
    category: string,
  ): Observable<
    HttpResponse<{ ModelShortDesc: string; OrderNo: string }[]>
  > {
    return this.http.get(
      "VehicleStockSummaryReportService.asmx/GetActiveModels",
      {
        params: { category },
      },
    );
  }

  GetDetails(
    id: string,
    category: string,
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      "VehicleStockSummaryReportService.asmx/GetDetail",
      {
        id,
        category,
      },
    );
  }
}
