import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-select-multiple-input",
  templateUrl: "./select-multiple-input.component.html",
  styleUrls: ["./select-multiple-input.component.css"],
})
export class SelectMultipleInputComponent implements OnInit {
  @Input() options: { value: string; display: string }[] = [];
  @Input() selectedOptions: { value: string; display: string }[] = [];
  @Output() dataOutput = new EventEmitter<
    { value: string; display: string }[]
  >();
  searchText: string = "";

  constructor() {}

  ngOnInit() {}

  onSelectionChange(event: any) {
    this.dataOutput.emit(this.selectedOptions);
  }

  compareFn(
    option1: { value: string; display: string },
    option2: { value: string; display: string },
  ): boolean {
    return option1 && option2
      ? option1.value === option2.value
      : option1 === option2;
  }
}
