<table class="overflow-x-auto w-full max-w-full text-sm">
  <colgroup>
    <!-- RECOMENDED TO USE PERCENTAGE WIDTH -->
    <col
      [width]="header.width"
      *ngFor="let header of filteredHeaders"
    />
  </colgroup>
  <tbody>
    <tr class="table-header">
      <th *ngFor="let header of filteredHeaders; let index = index">
        <p [class]="header.Hclasses">
          {{ header.title }}
          <fa-icon
            class="ml-1 cursor-pointer hover:text-primary-lighter"
            *ngIf="
              sorting &&
              header.title &&
              sortingDirection[header.id]
            "
            [icon]="faAngleDown"
            (click)="toggleSort(header.id)"
          />
          <fa-icon
            class="ml-1 cursor-pointer hover:text-primary-lighter"
            *ngIf="
              sorting &&
              header.title &&
              !sortingDirection[header.id]
            "
            [icon]="faAngleUp"
            (click)="toggleSort(header.id)"
          />
        </p>
      </th>
    </tr>
    <tr
      *ngFor="
        let entry of sortEntries(tableEntries, sortingHeader).slice(
          pageSize * pageIndex,
          pageTop
        );
        let index = index
      "
    >
      <td
        *ngFor="
          let data of filterEntry(entry) | keyvalue: returnZero;
          let subIndex = index
        "
      >
        <!-- DATA TYPE, JUST A PLAIN AND SIMPLE STRING -->
        <p
          [class]="tableHeaders[subIndex].classes"
          *ngIf="tableHeaders[subIndex].type === 'data'"
        >
          {{ data.value }}
        </p>
        <!-- LINK TYPE, SPECIFY IN YOUR PARENT COMPONENT THE FUNCTION YOU WISH TO EXECUTE, FROM THIS COMPONENT ITS ONLY RETURNED THE ENTRY DATA AS A OBJECT -->
        <a
          [class]="tableHeaders[subIndex].classes"
          (click)="openFunction(entry)"
          *ngIf="tableHeaders[subIndex].type === 'link'"
          >{{ data.value }}</a
        >
        <!-- BOOLEAN TYPE, SPECIFY THE TWO VALUES YOU WISH TO REPLACE FOR TRUE/FALSE -->
        <p
          [class]="tableHeaders[subIndex].classes"
          class="text-center"
          *ngIf="tableHeaders[subIndex].type === 'boolean'"
        >
          {{
            data.value
              ? tableHeaders[subIndex].messages?.[0]
              : tableHeaders[subIndex].messages?.[1]
          }}
        </p>
        <!-- EDIT/TRASH TYPE, THIS WILL EXECUTE A FUNCTION SPECIFIED IN YOUR PARENT COMPONENT DEPENDING OF THE BUTTON YOU CLICK ON -->
        <div
          [class]="tableHeaders[subIndex].classes"
          class="flex justify-center gap-x-4 text-primary-darker"
          *ngIf="tableHeaders[subIndex].type == 'editTrash'"
        >
          <fa-icon
            class="cursor-pointer hover:text-primary-lighter"
            [icon]="faPencil"
            (click)="editFunction(entry)"
          />
          <fa-icon
            class="cursor-pointer hover:text-primary-lighter"
            [icon]="faTrash"
            (click)="removeFunction(entry)"
          />
        </div>
        <!-- TRASH TYPE, THIS WILL EXECUTE A FUNCTION SPECIFIED IN YOUR PARENT COMPONENT -->
        <div
          [class]="tableHeaders[subIndex].classes"
          class="flex justify-center text-primary-darker"
          *ngIf="tableHeaders[subIndex].type == 'trash'"
        >
          <fa-icon
            class="cursor-pointer hover:text-primary-lighter"
            [icon]="faTrash"
            (click)="removeFunction(entry)"
          />
        </div>
        <!-- EDIT TYPE, SAME AS ABOVE BUT ONLY THE EDIT FUNCTION -->
        <div
          [class]="tableHeaders[subIndex].classes"
          class="flex justify-center text-primary-darker"
          *ngIf="tableHeaders[subIndex].type == 'edit'"
        >
          <fa-icon
            [icon]="faPencil"
            (click)="editFunction(entry)"
            class="cursor-pointer hover:text-primary-lighter"
          />
        </div>
        <!-- EDIT/PRINT TYPE, THIS WILL EXECUTE A FUNCTION SPECIFIED IN YOUR PARENT COMPONENT DEPENDING OF THE BUTTON YOU CLICK ON -->
        <div
          [class]="tableHeaders[subIndex].classes"
          class="flex justify-center gap-x-4 text-primary-darker"
          *ngIf="tableHeaders[subIndex].type == 'editPrint'"
        >
          <fa-icon
            class="cursor-pointer hover:text-primary-lighter"
            [icon]="faPencil"
            (click)="editFunction(entry)"
          />
          <fa-icon
            class="cursor-pointer hover:text-primary-lighter"
            [icon]="faPrint"
            (click)="printFunction(entry)"
          />
        </div>
        <!-- READ TYPE, THIS SHOULD OPEN THE ENTRY, LIKE THE LINK OPTION -->
        <div
          [class]="tableHeaders[subIndex].classes"
          class="flex justify-center text-primary-darker"
          *ngIf="tableHeaders[subIndex].type == 'read'"
        >
          <fa-icon
            [icon]="faInfoCircle"
            (click)="openFunction(entry)"
            class="cursor-pointer hover:text-primary-lighter"
          />
        </div>
        <!-- CHECKBOX TYPE, MARKED WHEN VALUE IS TRUE, SIMILAR TO BOOLEAN TYPE -->
        <input
          type="checkbox"
          class="checkbox-table"
          [class]="tableHeaders[subIndex].classes"
          *ngIf="tableHeaders[subIndex].type == 'checkbox'"
          [checked]="
            isObj(data.value) ? data.value['value'] : data.value
          "
          [disabled]="disabledCheckbox(index, data.key)"
          (click)="checkFunction(index, data.key)"
        />
      </td>
    </tr>
  </tbody>
</table>
<mat-paginator
  #paginator
  class="mt-4"
  *ngIf="pagination"
  (page)="handlePageEvent($event)"
  [length]="tableEntries.length"
  [pageSize]="pageSize"
  [showFirstLastButtons]="true"
  [pageSizeOptions]="pageSizeOptions"
></mat-paginator>
