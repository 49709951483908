<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Weekly Focus Dashboard</h1>
    </div>
    <section class="content-container">
      <!-- Form -->
      <app-data-form
        [dataFields]="upperFormFields"
        [dataName]="'upperFormFields'"
        [columns]="0"
        (updateFields_child)="handleUpdate($event)"
      ></app-data-form>
      <!-- Table -->
      <app-data-table
        [tableHeaders]="tableHeaders"
        [tableEntries]="tableEntries"
        (open)="openEntry($event)"
      ></app-data-table>
    </section>
  </section>
  