import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";
import { model } from "src/app/models/customer/click-drive-customer";

@Injectable({
  providedIn: "root",
})
export class ClickDriveCustomerService {
  /**
   * Fetches the free items.
   * @returns An Observable of the items data.
   */
  GetAllActive(): Observable<any> {
    return this.http.get(
      "/PromotionFreeItemService.asmx/GetAllActive",
    );
  }

  GetChatCustomerDraftOrder(): Observable<any> {
    return this.http.get(
      "/ClickDriveService.asmx/GetChatCustomerDraftOrder",
    );
  }

  /**
   * Generate a voucher.
   * @returns Nada.
   */
  GenerateVoucher(model: model): Observable<any> {
    return this.http.post(
      "/PromotionFreeItemService.asmx/GenerateVoucher",
      { model },
    );
  }

  constructor(private http: HttpUtilService) {}
}
