<div
  class="side-container"
  [ngClass]="showSideMenu ? 'w-60' : 'w-14'"
>
  <div
    class="fixed h-12 bg-white transition-all duration-300"
    [ngClass]="showSideMenu ? 'w-60' : 'w-14'"
  >
    <img
      src="assets/images/logos/komoco-logo.png"
      alt="komoco-logo"
      class="mx-auto h-12 w-9/10"
      *ngIf="showSideMenu"
    />
    <img
      src="assets/images/logos/komoco-small.png"
      alt="komoco-logo"
      class="mx-auto h-12 w-4/5"
      *ngIf="!showSideMenu"
    />
  </div>
  <div class="mt-12 flex gap-4 p-3">
    <img
      [src]="profilePic"
      alt="user-profile-img"
      class="my-auto rounded-full shadow-md"
      [ngClass]="showSideMenu ? 'h-12' : 'w-8'"
    />
    <div class="my-auto text-center">
      <p
        title="Username"
        class="my-auto text-sm font-medium uppercase text-white"
        *ngIf="showSideMenu"
      >
        {{ userInfo[STORAGE_KEYS.USER_INFO["FULL_NAME"].sessionKey] }}
      </p>
      <p
        title="Job Description"
        class="my-auto text-sm uppercase text-white"
      >
        {{
          userInfo[
            STORAGE_KEYS.USER_INFO["JOB_DESCRIPTION"].sessionKey
          ]
        }}
      </p>
    </div>
  </div>
  <div
    class="grid gap-1 px-1 py-2"
    [ngClass]="showSideMenu ? 'grid-cols-3' : 'grid-rows-3'"
  >
    <button
      class="block"
      [ngClass]="btn.color"
      *ngFor="let btn of departmentsBtns"
      (click)="
        btn.menu_id ? switchMenu(btn.menu_id) : switchMenu('KAPS')
      "
    >
      <fa-icon class="inline" [icon]="btn.icon"></fa-icon>
      <span *ngIf="showSideMenu">
        {{ btn.text }}
      </span>
    </button>
  </div>
  <div class="mt-2">
    <button
      id="dropdownButton"
      data-dropdown-toggle="dropdownMenu"
      type="button"
      class="flex gap-3 rounded-sm bg-gray-200"
      [ngClass]="showSideMenu ? 'mx-auto px-5 py-2' : 'mx-1 p-2'"
    >
      <img
        alt="komoco-logo"
        [src]="
          showSideMenu
            ? selectedCompany?.logo
            : selectedCompany?.small_logo
        "
        [ngClass]="showSideMenu ? 'h-5' : ''"
      />
      <fa-icon
        class="text-sm"
        [icon]="faAngleDown"
        *ngIf="showSideMenu"
      ></fa-icon>
    </button>
    <div
      id="dropdownMenu"
      class="z-40 hidden w-52 rounded-md bg-white"
    >
      <ul class="py-2" aria-labelledby="dropdownButton">
        <li
          class="cursor-pointer hover:bg-gray-200"
          *ngFor="let company of companyList"
        >
          <img
            class="mx-auto block px-4 py-1"
            [src]="company.logo"
            alt="brand-image"
            (click)="onSelectCompany(company)"
          />
        </li>
      </ul>
    </div>
  </div>
  <div class="py-3 text-center">
    <button class="regular-btn care-btn" *ngIf="showSideMenu">
      Smart Sales Care
    </button>
  </div>
  <div class="mt- text-white">
    <div *ngFor="let module of modules">
      <div
        class="category-container"
        [ngClass]="{
          'border-l-4 text-primary-lighter':
            displayedModule == (module.module_name | lowercase)
        }"
        (click)="toggleModuleDropdown(module.module_name)"
      >
        <fa-icon class="w-5" [icon]="module.icon"></fa-icon>
        <p *ngIf="showSideMenu">
          {{ module.module_name }}
        </p>
        <fa-icon
          class="absolute right-4"
          [icon]="
            displayedModule == (module.module_name | lowercase)
              ? faAngleUp
              : faAngleDown
          "
          *ngIf="showSideMenu"
        ></fa-icon>
      </div>
      <ul
        class="submodule-container-class"
        [ngStyle]="{
          'max-height':
            displayedModule == (module.module_name | lowercase) &&
            showSideMenu
              ? module.submodules.length * 4 + 'rem'
              : 0
        }"
      >
        <li
          class="cursor-pointer border-l py-2 hover:text-primary-lighter"
          *ngFor="let submodule of module.submodules"
          [ngClass]="{
            'text-primary-lighter':
              displayedSubModule.trim() ==
              (submodule.submodule_name.trim() | lowercase)
          }"
        >
          <ng-container>
            <a
              *ngIf="submodule.submodule_path; else errorTemplate"
              [href]="submodule.submodule_path"
              [title]="submodule.submodule_name"
            >
              {{ submodule.submodule_name }}
            </a>
            <ng-template #errorTemplate>
              <span
                (click)="showError()"
                [title]="submodule.submodule_name"
              >
                {{ submodule.submodule_name }}
              </span>
            </ng-template>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>
