<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading"> Manage Locations</h1>
      <app-navpath />
    </div>
    <div class="mb-2 flex w-fit gap-1">
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
      </button>
      <button class="shorter-btn red-btn">
        <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Delete
      </button>
    </div>
    <div class="content-container">
      <app-data-table
        [tableHeaders]="tableHeaders"
        [tableEntries]="tableEntries"
      ></app-data-table>
    </div>
  </section>
