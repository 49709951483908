import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { AccessoryService } from "src/app/services/vehicle/accessory.service";
import { ActivatedRoute, Router } from "@angular/router"; 
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: "app-accessory-list",
  templateUrl: "./accessory-list.component.html",
  styleUrls: ["./accessory-list.component.css"],
})
export class AccessoryListComponent implements OnInit {

  searchInput: string = "";
  filteredAccessories: any[] = [];
  accessories: any[] = []; 

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "ACCESSORY CODE",
      type: "link",
      id: "accessoryCode"
    },
    {
      title: "DESCRIPTION",
      type: "data",
      id: "description",
      classes: "max-w-64",
    },
    {
      title: "COST PRICE",
      type: "data",
      id: "costPrice",
    },
    {
      title: "NETT PRICE",
      type: "data",
      id: "nettPrice",
    },
    {
      title: "RETAIL PRICE",
      type: "data",
      id: "retailPrice",
    },
    {
      title: "SALESMAN PRICE",
      type: "data",
      id: "salesmanPrice",
    },
    {
      title: "CLASSIFICATION",
      type: "data",
      id: "classification",
    },
    {
      title: "FITTED",
      type: "data",
      id: "fitted",
    },
    {
      title: "STATUS",
      type: "boolean",
      id: "status",
      messages: ["Active", "Inactive"],
    },
    {
      title: "ID",
      type: "hidden",
      id: "accessoryId"
    },
  ];

  constructor(
    private accessoryService: AccessoryService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService 
  ) {}

  ngOnInit() {
    this.accessoryService.getAll().subscribe(
      (response) => {
        if (response && response.body) {
          this.accessories = response.body.map((accessory: any) => ({
            checked: false,
            accessoryCode: accessory.AccessoryCode || "",
            description: accessory.Description || "",
            costPrice: accessory.CostPrice || 0,
            nettPrice: accessory.NettPrice || 0,
            retailPrice: accessory.RetailPrice || 0,
            salesmanPrice: accessory.SalesmanPrice || 0,
            classification: accessory.Classification || "",
            fitted: accessory.Fitted || "",
            status: accessory.Status || false,
            accessoryId: accessory.AccessoryId || ""
          }));
          this.filteredAccessories = [...this.accessories]; 
        } else {
          console.error("Error: La estructura de la respuesta no contiene los datos esperados.");
        }
      },
      (error) => {
        console.error("Error al obtener los accesorios", error);
      }
    );
  }

  openEntry(value: any): void {
    this.router.navigate(["../accessory", value.accessoryId], {
      relativeTo: this.route,  
    });
  }

  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../accessory", emptyGuid], {
      relativeTo: this.route, 
    });
  }

  deleteSelectedEntries(): void {
    const selectedItems = this.filteredAccessories.filter(item => item.checked);

    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one item to delete.');
      return;
    }

    this.accessoryService.delete(selectedItems).subscribe({
      next: () => {
        this.notifier.notify('success', 'Selected items deleted successfully');
        this.filteredAccessories = this.filteredAccessories.filter(item => !item.checked);
      },
      error: (error) => {
        console.error("Error deleting accessories:", error);
        this.notifier.notify('error', 'Error deleting selected items.');
      }
    });
  }

  onSearch(searchValue: string): void {
    this.filteredAccessories = this.accessories.filter(item =>
      item.accessoryCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.description.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.costPrice.toString().includes(searchValue) || 
      item.retailPrice.toString().includes(searchValue) 
    );
  }
}
