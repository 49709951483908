import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faPlus,
  faCancel,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { NotifierService } from "angular-notifier";
import { SpecialPriceListMasterService } from "src/app/services/sales/special-price-list-master.service";
import { formatDate } from "@angular/common";
import { getEmptyGuid } from "src/app/services/util.service";

@Component({
  selector: "app-special-price-list-master-detail",
  templateUrl: "./special-price-list-master-detail.component.html",
  styleUrls: ["./special-price-list-master-detail.component.css"],
})
export class SpecialPriceListMasterDetailComponent implements OnInit {
  faPlus = faPlus;
  faCancel = faCancel;
  faPencil = faPencil;

  mode: string = "Add";
  lockUpdate: boolean = true;

  __type: string = "";
  Id: string = "0";
  MODELCODE: string = "";

  availableModels: any[] = [];

  formFields: FormField[] = [
    {
      type: "select",
      id: "MODELSHORTDESC",
      label: "Model Short Desc",
      required: true,
      options: [],
    },
    {
      type: "text",
      id: "MODELGROUP",
      label: "Model Group",
      required: true,
    },
    {
      type: "number",
      id: "ENGINECAP",
      label: "Eng. Cap",
    },
    {
      type: "number",
      id: "LUCKYDRAW",
      label: "Lucky Draw",
      required: true,
    },
    {
      type: "number",
      id: "DIPLOMAT",
      label: "Diplomat",
      required: true,
    },
    {
      type: "number",
      id: "USARMY",
      label: "US Army",
      required: true,
    },
    {
      type: "number",
      id: "HANDICAP",
      label: "Handicap",
      required: true,
    },
    {
      type: "number",
      id: "PPLATE",
      label: "Handicap (Starex Minibus - P plate)",
    },
    {
      type: "number",
      id: "SPLATE",
      label: "Handicap (Starex Minibus - S plate/ P plate VWO)",
    },
    {
      type: "number",
      id: "OWNCOE",
      label: "Own COE",
    },
    {
      type: "date",
      id: "EFFECTIVEDATE",
      label: "Effective Date",
      required: true,
    },
  ];

  constructor(
    private specialPriceListMasterService: SpecialPriceListMasterService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadActiveModelList();
    this.route.params.subscribe((params) => {
      this.Id = params["id"];
      if (this.Id !== "0") {
        this.mode = "Edit";
        this.lockUpdate = false;
        this.loadModelDetails();
      }
    });
  }

  private loadModelDetails(): void {
    this.specialPriceListMasterService.GetDetails(this.Id).subscribe({
      next: (response) => {
        const { __type, Id, MODELCODE, ...data } = response.body;
        this.__type = __type;
        this.MODELCODE = MODELCODE;
        Object.entries(data).forEach(([key, value]) => {
          if (key == "EFFECTIVEDATE") {
            this.getField(key).value = this.dateToField(
              value as string,
            );
            return;
          }
          this.getField(key).value = value;
        });
      },
      error: (error: any) => {
        console.error("Error loading model details:", error);
      },
    });
  }

  loadActiveModelList(): void {
    this.specialPriceListMasterService
      .GetBasicActiveModelList()
      .subscribe({
        next: (response) => {
          this.availableModels = response.body;
          this.getField("MODELSHORTDESC").options = response.body.map(
            (item) => ({
              display: item.ModelShortDesc,
              value: item.ModelShortDesc,
            }),
          );
        },
        error: console.error,
      });
  }

  updateFields(event: any) {
    if (event.updatedValue == 0) {
      const newModel = this.availableModels.find(
        (item) =>
          item.ModelShortDesc ==
          this.getField("MODELSHORTDESC").value,
      );
      this.getField("MODELGROUP").value = newModel.ModelShort;
      this.getField("MODELGROUP").value = newModel.ModelShort;
    }
    this.checkCanUpdate();
  }

  checkCanUpdate(): void {
    this.lockUpdate = !this.formFields
      .filter((item) => item.required == true)
      .every(
        (item) => item.value !== undefined && item.value !== null,
      );
  }

  onCancel(): void {
    this.router.navigate(["../../special-price-list-master"], {
      relativeTo: this.route,
    });
  }

  handlePriceList(action: "Add" | "Update"): void {
    const model = this.formFields.reduce((acc: any, field) => {
      acc[field.id] =
        field.id === "EFFECTIVEDATE"
          ? formatDate(field.value, "dd/MM/yyyy", "en-US")
          : field.value ?? null;
      return acc;
    }, {});

    if (action === "Add") {
      model["Id"] = getEmptyGuid();
      this.specialPriceListMasterService.Add(model).subscribe({
        next: (response) => {
          console.log(response.body);
          if (response.body.Code) {
            this.notifier.notify("error", response.body.Message);
          } else {
            this.notifier.notify(
              "success",
              "Special Price List has been added successfully",
            );
            this.onCancel();
          }
        },
        error: console.error,
      });
    } else if (action === "Update") {
      model["__type"] = this.__type;
      model["Id"] = this.Id;
      model["MODELCODE"] = this.MODELCODE;
      this.specialPriceListMasterService.Update(model).subscribe({
        next: () => {
          this.notifier.notify(
            "success",
            "Special Price List has been updated successfully",
          );
          this.onCancel();
        },
        error: console.error,
      });
    }
  }

  dateToField(date: string): string {
    const [day, month, year] = date.split("/");
    if (!day || !month || !year) {
      throw new Error("Date has no valid format");
    }
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  getField(id: string): any {
    const field = this.formFields.find((f) => f.id === id);
    return field;
  }
}
