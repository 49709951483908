import { Component, OnInit } from "@angular/core";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { SpecialPriceListMasterService } from "src/app/services/sales/special-price-list-master.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-special-price-list-master-list",
  templateUrl: "./special-price-list-master-list.component.html",
  styleUrls: ["./special-price-list-master-list.component.css"],
})
export class SpecialPriceListMasterListComponent implements OnInit {
  constructor(
    private specialPriceListMasterService: SpecialPriceListMasterService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
  ) {}

  ngOnInit() {
    this.getAll();
  }

  faPlus = faPlus;
  faTrash = faTrash;

  showModal: boolean = false;
  searchInput: string = "";
  rawModels: any[] = [];
  allModels: any[] = [];
  filteredModels: any[] = [];

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
      id: "IsChecked",
    },
    {
      title: "MODEL SHORT DESC",
      type: "link",
      id: "MODELSHORTDESC",
    },
    {
      title: "MODEL GROUP",
      type: "data",
      id: "MODELGROUP",
    },
    {
      title: "ENG. CAP",
      type: "data",
      id: "ENGINECAP",
    },
    {
      title: "EFFECTIVE DATE",
      type: "data",
      id: "EFFECTIVEDATE",
    },
    {
      title: "LUCKY",
      type: "data",
      id: "LUCKYDRAW",
    },
    {
      title: "DIPLOMAT",
      type: "data",
      id: "DIPLOMAT",
    },
    {
      title: "US ARMY",
      type: "data",
      id: "USARMY",
      Hclasses: 'min-w-16'
    },
    {
      title: "HANDICAP",
      type: "data",
      id: "HANDICAP",
    },
    {
      title: "P PLATE",
      type: "data",
      id: "PPLATE",
      Hclasses: 'min-w-14'
    },
    {
      title: "S PLATE",
      type: "data",
      id: "SPLATE",
      Hclasses: 'min-w-14'
    },
    {
      title: "OWN COE",
      type: "data",
      id: "OWNCOE",
    },
    {
      title: "",
      type: "hidden",
      id: "Id",
    },
  ];

  private getAll(): void {
    this.specialPriceListMasterService.GetAll().subscribe(
      (response) => {
        const allowedKeys = this.tableHeaders.map(
          (header) => header.id,
        );
        this.rawModels = response.body;
        this.allModels = response.body.map((obj: any) => {
          const filteredObj: any = {};
          filteredObj["IsChecked"] = false;
          allowedKeys.forEach((key) => {
            if (obj.hasOwnProperty(key)) {
              filteredObj[key] = obj[key];
            }
          });
          return filteredObj;
        });
        this.filteredModels = [...this.allModels];
      },
      (error) => {
        console.error("Error fetching data:", error);
      },
    );
  }

  openEntry(value?: any) {
    const id = value?.Id || "0";
    this.router.navigate(["../special-price-list-master", id], {
      relativeTo: this.route,
    });
  }

  openModal(modal: boolean): void {
    const entries = this.filteredModels.filter(
      (item) => item.IsChecked == true,
    );
    if (entries.length == 0 && modal) {
      this.notifier.notify(
        "error",
        "Please, select at least one record to delete",
      );
      return;
    } else {
      this.showModal = modal;
      document.body.style.overflow = modal ? "hidden" : "auto";
    }
  }

  removeEntry(): void {
    const selectedItems = this.rawModels
      .filter((item) => {
        return this.filteredModels
          .filter((innerItem) => innerItem.IsChecked)
          .map((innerItem) => innerItem.Id)
          .includes(item.Id);
      })
      .map((item) => ({
        ...item,
        IsChecked: true,
      }));
    this.specialPriceListMasterService
      .Delete(selectedItems)
      .subscribe({
        next: () => {
          this.notifier.notify(
            "success",
            "Items deleted successfully",
          );
          this.filteredModels = this.filteredModels.filter(
            (item) => !item.IsChecked,
          );
          this.openModal(false);
        },
        error: (error) => {
          console.error("Error deleting records:", error);
          this.openModal(false);
        },
      });
  }

  private filterItems(
    items: any[],
    searchValue: string,
    keys: string[],
  ): any[] {
    const lowerSearchValue = searchValue.toLowerCase();
    return items.filter((item) =>
      keys.some((key) =>
        String(item[key]).toLowerCase().includes(lowerSearchValue),
      ),
    );
  }

  onSearch(searchValue: string): void {
    const commonKeys = [
      "MODELSHORTDESC",
      "ENGINECAP",
      "LUCKYDRAW",
      "DIPLOMAT",
      "USARMY",
      "HANDICAP",
      "OWNCOE",
      "EFFECTIVEDATE",
      "MODELGROUP",
      "PPLATE",
      "SPLATE",
    ];

    this.filteredModels = this.filterItems(
      this.allModels,
      searchValue,
      commonKeys,
    );
  }
}
