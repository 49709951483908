<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Click & Drive Customer</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <p class="border-b p-3">Kindly select your option.</p>
    <div class="btns-container">
      <button class="voucher-btn" [routerLink]="'generate-voucher'">
        <fa-icon [icon]="faUserTag" class="icon"></fa-icon>
        <p>GENERATE VOUCHER</p>
      </button>
      <button class="order-btn" [routerLink]="'update-order'">
        <fa-icon [icon]="faUserEdit" class="icon"></fa-icon>UPDATE
        ORDER
      </button>
    </div>
  </section>
</div>
