import { Component, OnInit } from '@angular/core';
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { DiscountService } from 'src/app/services/vehicle/discount.service';
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.css']
})
export class DiscountComponent implements OnInit {
  faPencil = faPencil;

  tableHeaders = [
    { title: "MODEL", type: "data", id: "MODEL" },
    { title: "CATEGORY", type: "data", id: "CATEGORY", classes: "max-w-64" },
    { title: "DISCOUNT", type: "data", id: "DISCOUNT" },
    { title: "SELECT", type: "checkbox", id: "SELECT" },
    { title: "ID", type: "hidden", id: "ModelMasterId" }
  ];

  coe_bidding: any[] = [];
  immediate: any[] = [];

  updateDiscounts: FormField[] = [
    {
      type: "update",
      id: "discountAmount1",
      label: "Discount Amount",
      required: true,
      searchType: "own"
    },
    {
      type: "update",
      id: "discountAmount2",
      label: "Discount Amount",
      required: true,
      searchType: "immediate"
    },
  ];

  constructor(private discountService: DiscountService) {}

  ngOnInit(): void {
    this.loadDiscountData();
    this.loadImmediateDiscountData();
  }

  private loadDiscountData(): void {
    this.discountService.getModelDiscount().subscribe(
      (response) => {
        const data = response.body || [];
        
        this.coe_bidding = data.map((item: any) => {
          return {
            MODEL: item.ModelShortDesc,
            CATEGORY: item.CoeCategory,
            DISCOUNT: item.Discount,
            SELECT: false,
            ModelMasterId: item.ModelMasterId
          };
        });
  
       },
      (error) => {
        console.error("Error getting data:", error);
      }
    );
  }
  
  private loadImmediateDiscountData(): void {
    this.discountService.getModelImmediateDiscount().subscribe(
      (response) => {
        const data = response.body || [];
        this.immediate = data.map((item: any) => {
          return {
            MODEL: item.ModelShortDesc,
            CATEGORY: item.CoeCategory,
            DISCOUNT: item.Discount,
            SELECT: false,
            ModelMasterId: item.ModelMasterId
          };
        });
        },
      (error) => {
        console.error("Error getting data:", error);
      }
    );
  }
  

  onUpdateEvent(event: { fieldId: string }): void {
    
    const searchType = this.updateDiscounts.find(field => field.id === event.fieldId)?.searchType;
  
    if (searchType === "own") {
      const payload = {
        model: this.coe_bidding.map(item => ({
          ModelMasterId: item.ModelMasterId,
          CoeCategory: item.CATEGORY,
          ModelShortDesc: item.MODEL,
          Discount: item.SELECT ? this.updateDiscounts.find(field => field.id === event.fieldId)?.value || item.DISCOUNT : item.DISCOUNT,
          Allowed: item.SELECT
        }))
      };
      
      this.discountService.updateModelDiscount(payload).subscribe(
        response => {
          this.loadDiscountData(); 
          this.clearUpdateFields(); 
        },
        error => {
          console.error("Error updating discount:", error);
        }
      );
    } else if (searchType === "immediate") {
      const selectedItem = this.immediate.find(item => item.SELECT);
  
      if (selectedItem) {
        const updatedDiscountValue = this.updateDiscounts.find(field => field.id === event.fieldId)?.value || selectedItem.DISCOUNT;
  
        const payload = {
          model: [{
            ModelMasterId: selectedItem.ModelMasterId,
            CoeCategory: selectedItem.CATEGORY,
            ModelShortDesc: selectedItem.MODEL,
            Discount: updatedDiscountValue,
            Allowed: selectedItem.SELECT
          }]
        };
       
        this.discountService.updateModelImmediateDiscount(payload).subscribe(
          response => {
            this.loadImmediateDiscountData();
            this.clearUpdateFields();
          },
          error => {
            console.error("Error updating discount:", error);
          }
        );
      } else {
        console.warn("Please select something to update.");
      }
    }
  }
  
  private clearUpdateFields(): void {
    this.updateDiscounts.forEach(field => {
      field.value = "";
    });
  }
  
}
