<div class="input-container">
  <input
    type="number"
    (input)="updateFormControl($event)"
    [formControl]="addControl"
  />
  <button
    class="add-btn"
    (click)="add($event)"
    [disabled]="!dataInput && !admitsUndefined"
    [ngClass]="{
      'disabled': !dataInput && !admitsUndefined
    }"
  >
    <fa-icon [icon]="faPlus" />
    Add
  </button>
</div>
