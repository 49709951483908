<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Contract management</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faUndo" class="mr-1" />Cancel contract
    </button>
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faSave" class="mr-1" />Supercede contract
    </button>
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faSave" class="mr-1" />Generate contract
    </button>
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faPrint" class="mr-1" />Print
    </button>
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faBroom" class="mr-1" />Clear
    </button>
  </div>
  <div class="content-container">
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Booking contract</h2>
      <app-data-form
        [dataFields]="bookingContract"
        [dataName]="'bookingContract'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Buyer's details</h2>
      <app-data-form
        [dataFields]="buyersDetails"
        [dataName]="'buyersDetails'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details of vehicle</h2>
      <app-data-form
        [dataFields]="detailsVehicle"
        [dataName]="'detailsVehicle'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Model stock availability
      </h2>
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of cash discount
      </h2>
      <app-data-form
        [dataFields]="detailsCashDiscount"
        [dataName]="'detailsCashDiscount'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details of deposit</h2>
      <app-data-form
        [dataFields]="detailsDeposit"
        [dataName]="'detailsDeposit'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of other fees
      </h2>
      <app-data-form
        [dataFields]="detailsOtherFees"
        [dataName]="'detailsOtherFees'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of insurance
      </h2>
      <app-data-form
        [dataFields]="detailsInsurance"
        [dataName]="'detailsInsurance'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of hire purchase application
      </h2>
      <app-data-form
        [dataFields]="detailsHireApplication"
        [dataName]="'detailsHireApplication'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of trade-in vehicle
      </h2>
      <app-data-form
        [dataFields]="detailsTradeInVehicle"
        [dataName]="'detailsTradeInVehicle'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details of accounts</h2>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Komoco's account</h2>
        <div class="mt-4">
          <h3 class="subsub-heading">Promotion</h3>
          <app-data-form
            [dataFields]="komocosAccount_Promotion"
            [dataName]="'komocosAccount_Promotion'"
            (updateFields_child)="updateFields($event)"
          />
          <h3 class="subsub-heading">Accessories</h3>
          <app-data-form
            [dataFields]="komocosAccount_Accessories"
            [dataName]="'komocosAccount_Accessories'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
      </section>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Buyer's account</h2>
        <div class="mt-4">
          <h3 class="subsub-heading">Non accessories</h3>
          <app-data-form
            [dataFields]="buyersAccount_NonAccessories"
            [dataName]="'buyersAccount_NonAccessories'"
            (updateFields_child)="updateFields($event)"
          />
          <h3 class="subsub-heading">Accessories</h3>
          <app-data-form
            [dataFields]="buyersAccount_Accessories"
            [dataName]="'buyersAccount_Accessories'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
      </section>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">
          Sales consultant's account
        </h2>
        <div class="mt-4">
          <h3 class="subsub-heading">Non accessories</h3>
          <app-data-form
            [dataFields]="consultantAccount_NonAccessories"
            [dataName]="'consultantAccount_NonAccessories'"
            (updateFields_child)="updateFields($event)"
          />
          <h3 class="subsub-heading">Accessories</h3>
          <app-data-form
            [dataFields]="consultantAccount_Accessories"
            [dataName]="'consultantAccount_Accessories'"
            (updateFields_child)="updateFields($event)"
          />
        </div>
      </section>
    </section>
    <section class="content-container mb-4">
      <h2 class="sub-heading w-full border-b">Additional remarks</h2>
      <app-data-form
        [dataFields]="additionalRemarks"
        [dataName]="'additionalRemarks'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faPrint" class="mr-1" />Print thank you letter
    </button>
  </div>
</section>
