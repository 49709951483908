import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class BuyersOrderReportDynamicService {
  private readonly apiUrl: string = "SalesReportService.asmx/AllInvoicesReport";

  constructor(private http: HttpUtilService) {}

 
}
