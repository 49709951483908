import { Component, OnInit } from "@angular/core";
import { FormField } from "src/app/interfaces/form-field.model";
import {
  faPencil,
  faTrash,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-account-code-creation",
  templateUrl: "./account-code-creation.component.html",
  styleUrls: ["./account-code-creation.component.css"],
})
export class AccountCodeCreationComponent implements OnInit {
  faPencil = faPencil;
  faTrash = faTrash;
  faBroom = faBroom;
  codeCreation!: FormField[];
  lockButtons = true;

  updateFields(event: any): void {
    this.codeCreation = event.fields;
    console.log(this.codeCreation[0]);
    console.log(this.codeCreation[1]);
    if (this.codeCreation[0].value && this.codeCreation[1].value) {
      this.lockButtons = false;
    } else {
      this.lockButtons = true;
    }
  }

  clearForm(): void {
    this.codeCreation[0].value = undefined;
    this.codeCreation[1].value = undefined;
  }

  constructor() {}
  ngOnInit() {
    this.codeCreation = [
      {
        type: "text",
        id: "accountCode",
        label: "Account code",
      },
      {
        type: "text",
        id: "purpose",
        label: "Purpose",
      },
    ];
  }
}
