import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPencil, faBan } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';

@Component({
  selector: 'app-reservation-price-list-master-detail',
  templateUrl: './reservation-price-list-master-detail.component.html',
  styleUrls: ['./reservation-price-list-master-detail.component.css']
})
export class ReservationPriceListMasterDetailComponent {

    //Icons imports
    faPencil = faPencil;
    faBan = faBan;
  
    menuItems: string[] = [];

    formFields: FormField[] = [
      { type: 'select', id: 'modelShortDescription', label: 'Model Short Description', options: [{value: 'SANTA FE 1.6 TURBO HYBRID (S/R)7-SEATER', display: 'SANTA FE 1.6 TURBO HYBRID (S/R)7-SEATER'}, {value: 'SANTA FE 1.6 TURBO HYBRID 7-SEATER', display: 'SANTA FE 1.6 TURBO HYBRID (S/R)7-SEATER'}] },
      { type: 'text', id: 'modelGroup', label: 'Model Group'},
      { type: 'text', id: 'modelCode', label: 'Model Code'},
      { type: 'number', id: 'engCap', label: 'Eng. Cap' },
      { type: 'text', id: 'sequence', label: 'Sequence' },
      { type: 'number', id: 'modelSubSeq', label: 'Model Sub Seq' },
      { type: 'text', id: 'category', label: 'Category' },
      { type: 'date', id: 'effectiveDate', label: 'Effective Date' },
      { type: 'number', id: 'price', label: 'Price' },
      { type: 'number', id: 'regFee', label: 'Reg Fee' },
      { type: 'number', id: 'coeOffSet', label: 'COE Off Set' },
      { type: 'number', id: 'sixMthsRoadTax', label: '6 Months Road Tax' },
      { type: 'number', id: 'arfNet', label: 'ARF Net', lock: true },
      { type: 'number', id: 'cngPrice', label: 'CNG Price' },
      { type: 'number', id: 'tradeInDiscount', label: 'Trade-In Discount' },
      { type: 'number', id: 'inHouseFinanceRebate', label: 'In-House Finance Rebate' },
      { type: 'number', id: 'inHouseInsRebate', label: 'In-House Ins. Rebate' },
      { type: 'number', id: 'retailPrice', label: 'Retail Price', lock: true },
      ];
  
    constructor(private route: ActivatedRoute) {
      this.route.url.subscribe(urlSegments => {
        if (urlSegments && urlSegments.length >= 2) {
          const menuItem1 = this.capitalizeFirstLetter(urlSegments[0].path.replace(/-/g, ' '));
          const menuItem2 = this.capitalizeFirstLetter(urlSegments[1].path.replace(/-/g, ' '));
          this.menuItems = [menuItem1, menuItem2];
        }
      });
    }
  
    private capitalizeFirstLetter(str: string): string {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }
}
