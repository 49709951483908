import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { CreditMemoService } from "src/app/services/sales/credit-memo.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-credit-memo",
  templateUrl: "./credit-memo.component.html",
  styleUrls: ["./credit-memo.component.css"],
})
export class CreditMemoComponent implements OnInit {
  faPrint = faPrint;
  contractDetails: any = {};

  formFields: FormField[] = [
    { type: "search", id: "contractNo", label: "Enter Contract Number to fetch details", required: true, searchEvent: true },
    { type: "text", id: "registerNumber", label: "Registration Number" },
    { type: "text", id: "firstName", label: "Buyer's Name" },
    { type: "date", id: "registerDate", label: "Registration Date" },
    { type: "select", id: "finCompCode", label: "Finance Company", options: [] },
    { type: "text", id: "modelShortDesc", label: "Model" },
    { type: "select", id: "insCompCode", label: "Insurance Company", options: [] },
    { type: "number", id: "install", label: "First Installment ($)" },
    { type: "number", id: "aig", label: "Insurance Premium ($)" },
  ];
  

  constructor(
    private creditMemoService: CreditMemoService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.loadFinanceCompanyDropdown();
    this.loadInsuranceCompanyDropdown();
  }

  private loadFinanceCompanyDropdown(): void {
    this.creditMemoService.getFinanceCompanyList().subscribe({
      next: (response) => {
        const financeCompanyField = this.formFields.find((f) => f.id === "finCompCode");
        if (financeCompanyField) {
          financeCompanyField.options = response?.body?.map((item: any) => ({
            display: item.Name,
            value: item.Value,
          })) || []; 
          console.log("Mapped Finance Company Options:", financeCompanyField.options);
        } else {
          console.warn("Finance Company field not found.");
        }
      },
      error: (error) => {
        console.error("Error fetching finance companies:", error);
      },
    });
  }
  
  private loadInsuranceCompanyDropdown(): void {
    this.creditMemoService.getInsuranceCompanyList().subscribe({
      next: (response) => {
        const insuranceCompanyField = this.formFields.find((f) => f.id === "insCompCode");
        if (insuranceCompanyField) {
          insuranceCompanyField.options = response?.body?.map((item: any) => ({
            display: item.Name,
            value: item.Value,
          })) || []; 
          console.log("Mapped Insurance Company Options:", insuranceCompanyField.options);
        } else {
          console.warn("Insurance Company field not found.");
        }
      },
      error: (error) => {
        console.error("Error fetching insurance companies:", error);
      },
    });
  }
  
  fetchContractDetails(): void {
    const contractNo = this.formFields.find((f) => f.id === "contractNo")?.value || "";
    if (!contractNo) {
      this.notifier.notify("error", "Please enter a contract number.");
      return;
    }
  
    this.creditMemoService.getContractDetails(contractNo).subscribe({
      next: (response) => {
        console.log("Contract Details Response:", response);
        const data = response?.body;
  
        if (data) {
          this.setFormFieldValue("firstName", data.FirstName || "");
          this.setFormFieldValue("registerNumber", data.RegisterNumber || "");
  
          if (data.RegisterDate) {
            const [day, month, year] = data.RegisterDate.split("/");
            const formattedDate = `${year}-${month}-${day}`;
            this.setFormFieldValue("registerDate", formattedDate);
          } else {
            this.setFormFieldValue("registerDate", "");
          }
  
          this.setFormFieldValue("modelShortDesc", data.ModelShortDesc || "");
          this.setFormFieldValue("aig", data.Aig || "");
          this.setFormFieldValue("install", data.Install || "");
  
         const financeCompanyField = this.formFields.find((f) => f.id === "finCompCode");
          if (financeCompanyField?.options) {
            const matchedFinance = financeCompanyField.options.find(
              (option) => option.value === data.FinCompCode
            );
            this.setFormFieldValue("finCompCode", matchedFinance?.value || "");
          } else {
            console.warn("Finance Company dropdown options not initialized.");
          }
          
          const insuranceCompanyField = this.formFields.find((f) => f.id === "insCompCode");
          if (insuranceCompanyField?.options) {
            const matchedInsurance = insuranceCompanyField.options.find(
              (option) => option.value === data.InsCompCode
            );
            this.setFormFieldValue("insCompCode", matchedInsurance?.value || "");
          } else {
            console.warn("Insurance Company dropdown options not initialized.");
          }
        } else {
          this.notifier.notify("warning", "No contract details found.");
        }
      },
      error: (error) => {
        console.error("Error fetching contract details:", error);
        this.notifier.notify("error", "Failed to fetch contract details.");
      },
    });
  }
  
  private setFormFieldValue(fieldId: string, value: any): void {
    const field = this.formFields.find((f) => f.id === fieldId);
    if (field) {
      field.value = value || "";
    }
  }
  
  updateAndPrintMemo(): void {
    const registerDateField = this.formFields.find((f) => f.id === "registerDate")?.value || "";
    const formattedRegisterDate = registerDateField
      ? (() => {
          const [year, month, day] = registerDateField.split("-");
          return `${day}/${month}/${year}`;
        })()
      : "";
  
    const updateData = {
      __type: "KAPS.Model.Sales.CrmCreditMemo",
      ContractNo: this.formFields.find((f) => f.id === "contractNo")?.value || "",
      RegisterDate: formattedRegisterDate,
      RegisterNumber: this.formFields.find((f) => f.id === "registerNumber")?.value || null,
      ModelShortDesc: this.formFields.find((f) => f.id === "modelShortDesc")?.value || "",
      FirstName: this.formFields.find((f) => f.id === "firstName")?.value || "",
      Aig: Number(this.formFields.find((f) => f.id === "aig")?.value) || null,
      Install: Number(this.formFields.find((f) => f.id === "install")?.value) || null,
      FinCompCode: this.formFields.find((f) => f.id === "finCompCode")?.value || "",
      InsCompCode: this.formFields.find((f) => f.id === "insCompCode")?.value || "",
    };
  
    this.creditMemoService.updateCreditMemo(updateData).subscribe({
      next: () => {
        //this.notifier.notify("success", "Credit Memo updated successfully.");
      },
      error: (error) => {
        console.error("Error updating credit memo:", error);
        this.notifier.notify("error", "Failed to update Credit Memo.");
      },
    });
  }
  
  printMemo(): void {
    const contractNo = this.formFields.find((f) => f.id === "contractNo")?.value || "";
    this.creditMemoService.printMemo(contractNo).subscribe({
      //next: () => this.notifier.notify("success", "Memo printed successfully."),
      error: (error) => {
        console.error("Error printing memo:", error);
        this.notifier.notify("error", "Failed to print memo.");
      },
    });
  }

  printCreditMemoRequestForm(): void {
    const contractNo = this.formFields.find((f) => f.id === "contractNo")?.value || "";
    this.creditMemoService.printCreditMemoRequestForm(contractNo).subscribe({
      //next: () => this.notifier.notify("success", "Request form printed successfully."),
      error: (error) => {
        console.error("Error printing request form:", error);
        this.notifier.notify("error", "Failed to print request form.");
      },
    });
  }
}
