import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WeeklyFocusReportService } from 'src/app/services/sales/weekly-focus-report.service';
import { getEmptyGuid } from 'src/app/services/util.service';

@Component({
  selector: 'app-weekly-focus-report-list',
  templateUrl: './weekly-focus-report-list.component.html',
  styleUrls: ['./weekly-focus-report-list.component.css']
})
export class WeeklyFocusReportListComponent implements OnInit {

  tableHeaders = [
    { title: "TITLE", type: "link", id: "WeeklyFocusTitle" },
    { title: "SALES CONSULTANT", type: "data", id: "LoginId" },
    { title: "CREATED DATE", type: "data", id: "CreatedDate" },
    { title: "SUBMITTED DATE", type: "data", id: "SubmittedDate" },
    { title: "", type: "trash" }, 
    { title: "INNERID", type: "hidden", id: "innerId" },
  ];
  

  tableEntries: any[] = [];

  constructor(
    private weeklyFocusReportService: WeeklyFocusReportService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadWeeklyFocus();
  }

  loadWeeklyFocus(): void {
    this.weeklyFocusReportService.getAllWeeklyFocus().subscribe(
      (response) => {
        if (response?.body) {
          this.tableEntries = response.body.map((item: any) => ({
            WeeklyFocusTitle: item.WeeklyFocusTitle,
            LoginId: item.LoginId,
            CreatedDate: item.CreatedDate,
            SubmittedDate: item.SubmittedDate,
            innerId: item.WeeklyFocusId,
            editTrash: "",
          }));
        } else {
          this.tableEntries = [];
        }
      },
      (error) => {
        console.error('Error retrieving weekly focus data:', error);
        this.tableEntries = [];
      }
    );    
  }

  addEntry(): void {
    const emptyId = getEmptyGuid();
    console.log('Add button clicked. Navigating with empty GUID:', emptyId);

    this.router.navigate(['/sales/weekly-focus-report', emptyId])
      .then(success => {
        console.log('Navigation successful:', success);
      })
      .catch(error => {
        console.error('Error during navigation:', error);
      });
  }
  openEntry(entry: any): void {
    console.log('Open entry:', entry);
    this.router.navigate(['/sales/weekly-focus-report', entry.innerId])
      .then(success => {
        console.log('Navigation successful:', success);
      })
      .catch(error => {
        console.error('Error during navigation:', error);
      });
  }
  
  deleteEntry(entry: any): void {
    if (!entry?.innerId) { 
      console.warn('deleteEntry blocked - missing innerId:', entry); 
      return; 
    }
  
    const confirmDelete = confirm('Are you sure you want to delete this entry?');
    if (!confirmDelete) return;
  
    this.weeklyFocusReportService.deleteWeeklyFocus(entry.innerId).subscribe(
      (res) => {
        console.log('Delete response:', res);
        this.loadWeeklyFocus();
      },
      (error) => {
        console.error('Error deleting WeeklyFocus entry:', error);
      }
    );
  }
  
}
