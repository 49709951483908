import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import { InventoryVehicle } from "src/app/models/sales/inventory-vehicle";

@Injectable({
  providedIn: "root",
})
export class InventoryVehicleService {
  constructor(private http: HttpUtilService) {}

  UploadFiles(file: ArrayBuffer): Observable<any> {
    const formData = new FormData();

    const blob = new Blob([file], {
      type: "application/octet-stream",
    });
    formData.append("file", blob, "uploadedFile.xlsx");
    formData.append("docType", "InventoryVehicle");

    return this.http.post("UploadFiles.ashx", formData);
  }

  AddVehicles(modelList: InventoryVehicle[]): Observable<any> {
    return this.http.post("VehicleService.asmx/AddVehicles", {
      modelList,
    });
  }
}
