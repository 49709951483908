import { Component } from '@angular/core';
import { faPencil, faPlus, faBan } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-model-and-specification-detail',
  templateUrl: './model-and-specification-detail.component.html',
  styleUrls: ['./model-and-specification-detail.component.css']
})
export class ModelAndSpecificationDetailComponent {
  constructor() {}

  // ICONS VARIABLES

  faPencil = faPencil;
  faPlus = faPlus;
  faBan = faBan;

  detailForm: FormField[] = [
    {
      type: "text",
      id: "modelShortDescription",
      label: "Model Short Description",
      required: true
    },
    {
      type: "text",
      id: "modelCode",
      label: "Model Code",
      required: true
    },
    {
      type: "text",
      id: "modelGroup",
      label: "Model Group",
      required: true
    },
    {
      type: "text",
      id: "modelLongDescription",
      label: "Model Long Description",
      required: true
    },
    {
      type: "select",
      id: "manufacturerCode",
      label: "Manufacturer Code",
      required: true,
      options: [
        { display: "A22AB", value: "A22AB" },
        { display: "A22XB", value: "A22XB" },
        { display: "A30XA", value: "A30XA" },
        { display: "13809", value: "13809" },
      ],
    },
    {
      type: "text",
      id: "chasisPrefix",
      label: "ChasisPrefix",
    },
    {
      type: "select",
      id: "coeCategory",
      label: "COE Category",
      required: true,
      options: [
        { display: "A", value: "A" },
        { display: "B", value: "B" },
        { display: "C", value: "C" },
      ],
    },
    {
      type: "select",
      id: "Classification",
      label: "Classification",
      options: [
        { display: "Normal", value: "Normal" },
        { display: "Taxi", value: "Taxi" },
      ],
    },
    {
      type: "select",
      id: "franchiseCode",
      label: "Franchise Code",
      required: true,
      options: [
        { display: "HY", value: "HY" },
      ],
    },
    {
      type: "text",
      id: "occNo",
      label: "OCC No",
    },
    {
      type: "number",
      id: "Additional6MonthsRoadTaxStamdard",
      label: "Aditional 6 Months Road Tax (Standard)",
    },
    {
      type: "select",
      id: "modelType",
      label: "Model Type",
      required: true,
      options: [
        { display: "A", value: "A" },
        { display: "M", value: "M" },
      ],
    },
    {
      type: "number",
      id: "Additional6MonthsRoadTaxCompressed",
      label: "Aditional 6 Months Road Tax (Compressed Natural Gas)",
    },
    {
      type: "number",
      id: "Additional6MonthsRadioLicenseStandard",
      label: "Aditional 6 Months Radio License (Standard)",
    },
    {
      type: "number",
      id: "vesSurchargeRebate",
      label: "VES Surcharge/Rebate",
    },
    {
      type: "number",
      id: "Additional6MonthsRadioLicenseCompressedNaturalGas",
      label: "Aditional 6 Months Radio License (Compressed Natural Gas)",
    },
    {
      type: "number",
      id: "estimatedOmvPrice",
      label: "Estimate OMV Price",
    },
    {
      type: "select",
      id: "ModifyModelWithin24hrs",
      label: "Modify model & colout within 24 hrs of Buyer's Order",
      required: true,
      options: [
        { display: "Enable", value: "Enable" },
        { display: "Disable", value: "Disable" },
      ],
    },
    {
      type: "checkbox",
      id: "display",
      label: "",
      options: [
        {
          display: "Display for Sales",
          value: "displayForSales",
        },
        {
          display: "Display in Price List Master",
          value: "displayInPriceListMaster",
        },
        {
          display: "Display in Web Price List",
          value: "displayInWebPriceList",
        },
        {
          display: "Display in Reservation",
          value: "displayInReservation",
        },
      ],
    },
  ];

  standard_specification: FormField[] = [
    {
      type: "text",
      id: "engineType",
      label: "Engine Type",
    },
    {
      type: "number",
      id: "displacement",
      label: "Displacement (cc)",
    },
    {
      type: "text",
      id: "boreXStroke",
      label: "Bore x Stroke (mm)",
    },
    {
      type: "number",
      id: "compressionRatio",
      label: "Compression Ratio",
    },
    {
      type: "text",
      id: "maxPower",
      label: "Max. Power (kW/rpm)",
    },
    {
      type: "text",
      id: "maxTorque",
      label: "Max. Torque (N.m/rpm)",
    },
    {
      type: "number",
      id: "lubricantCapacity",
      label: "Lubricant Capacity (litre)",
    },
  ];

  brakes: FormField[] = [
    {
      type: "text",
      id: "general",
      label: "General",
    },
    {
      type: "text",
      id: "frontBrakeType",
      label: "Front Brake Type",
    },
    {
      type: "text",
      id: "rearBrakeType",
      label: "Rear Brake Type",
    },
    {
      type: "text",
      id: "boosterSizeType",
      label: "Booster Size / Type",
    },
    {
      type: "number",
      id: "cylinderDiameter",
      label: "Cylinder Diameter",
    },
  ];

  steering_wheel: FormField[] = [
    {
      type: "text",
      id: "type",
      label: "Type",
    },
    {
      type: "number",
      id: "overallSteeringGearRatio",
      label: "Overall Steering Gear Ratio",
    },
    {
      type: "number",
      id: "numberOfSteeringWheelTurn",
      label: "Number of Steering Wheel Turn",
    },
    {
      type: "number",
      id: "turningRadiusAtWheel",
      label: "Turning Radius at Wheel (m)",
    },
  ];

  suspension: FormField[] = [
    {
      type: "text",
      id: "front",
      label: "Front",
    },
    {
      type: "text",
      id: "rear",
      label: "Rear",
    },
    {
      type: "text",
      id: "shockAbsorber",
      label: "Shock Absorber",
    },
  ];

  dimension: FormField[] = [
    {
      type: "number",
      id: "length",
      label: "Length",
    },
    {
      type: "number",
      id: "width",
      label: "Width",
    },
    {
      type: "number",
      id: "height",
      label: "Height",
    },
    {
      type: "number",
      id: "wheelBase",
      label: "WheelBase",
    },
    {
      type: "text",
      id: "cargoArea",
      label: "Cargo Area",
    },
  ]

}
