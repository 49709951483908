import { Component, OnInit } from "@angular/core";
import { faPencil, faBroom } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-amend-cheque-purpose",
  templateUrl: "./amend-cheque-purpose.component.html",
  styleUrls: ["./amend-cheque-purpose.component.css"],
})
export class AmendChequePurposeComponent implements OnInit {
  faPencil = faPencil;
  faBroom = faBroom;

  amendCheque!: FormField[];

  updateFields(event: any): void {
    this.amendCheque = event.filds;
  }

  ngOnInit() {
    this.amendCheque = [
      {
        type: "text",
        id: "chequeNumber",
        label: "Cheque number",
        required: true,
      },
      {
        type: "text",
        id: "oldPurpose",
        label: "Old purpose",
        lock: true,
      },
      {
        type: "select",
        id: "newPurpose",
        label: "New purpose",
        required: true,
      },
    ];
  }
}
