import { Component } from '@angular/core';
import { faPencil, faBan } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-location-master-detail',
  templateUrl: './location-master-detail.component.html',
  styleUrls: ['./location-master-detail.component.css']
})
export class LocationMasterDetailComponent {
  
  faPencil = faPencil;
  faBan = faBan;

  menuItems: string[] = [];

  formFields: FormField[] = [
    { type: 'text', id: 'location', label: 'Location', required: true },
    { type: 'text', id: 'chequeFrom', label: 'Cheque From', required: true },
    { type: 'text', id: 'chequeTo', label: 'Cheque To', required: true },
    { type: 'text', id: 'currentCheque', label: 'Current Cheque', required: true },
  ];

  constructor(private route: ActivatedRoute) {
    this.route.url.subscribe(urlSegments => {
      if (urlSegments && urlSegments.length >= 2) {
        const menuItem1 = this.capitalizeFirstLetter(urlSegments[0].path.replace(/-/g, ' '));
        const menuItem2 = this.capitalizeFirstLetter(urlSegments[1].path.replace(/-/g, ' '));
        this.menuItems = [menuItem1, menuItem2];
      }
    });
  }

  private capitalizeFirstLetter(str: string): string {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
