import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<{
    [key: string]: boolean;
  }>({
    company: false,
    request: false,
  });
  private requestCount = 0;
  loading$ = this.loadingSubject.asObservable();

  constructor() {}

  private setLoading(type: string, loading: boolean): void {
    const currentLoadingState = this.loadingSubject.getValue();
    this.loadingSubject.next({
      ...currentLoadingState,
      [type]: loading,
    });
  }

  startLoading(type: string): void {
    this.requestCount++;
    this.setLoading(type, true);
  }

  stopLoading(type: string): void {
    this.requestCount--;
    if (this.requestCount <= 0) {
      this.setLoading(type, false);
      this.requestCount = 0;
    }
  }

  isLoading(type: string): boolean {
    return this.loadingSubject.getValue()[type];
  }
}
