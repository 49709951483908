<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading"> General Enquiry</h1>
      <app-navpath />
    </div>
    <div class="content-container">
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Search Criteria</h2>
        <app-data-form
          [dataFields]="searchCriteria"
          [dataName]="'searchCriteria'"
        />
      </section>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Search Result</h2>
        <app-data-form
          [dataFields]="searchResult"
          [dataName]="'searchResult'"
        />
      </section>
    </div>
    <div class="content-container">
        <app-data-table
            [tableHeaders]="general_enquiry_headers"
            [tableEntries]="general_enquiry_data"
        />
    </div>  
</section>