<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Factory Standard Equipment & Items</h1>
      <app-navpath></app-navpath>
    </div>
  
    <div class="mb-2 mt-4 flex w-fit gap-1">
      <button class="shorter-btn misc-btn" (click)="updateDeliveryCer()">
        <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Update
      </button>
    </div>
  
    <div class="content-container">
      <!-- Category selection -->
      <app-data-form
        [dataFields]="categoryField"
        [dataName]="'categoryField'"
        [columns]="1"
        (updateFields_child)="onCategoryChange($event.fields[0].value)"
      ></app-data-form>
  
      <!-- Delivery items form -->
      <app-data-form
        [dataFields]="itemsFields"
        [dataName]="'itemsFields'"
        [columns]="2"
      ></app-data-form>
    </div>
  </section>
  