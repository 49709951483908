import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faPlus,
  faCancel,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { NotifierService } from "angular-notifier";
import { formatDate } from "@angular/common";
import { getEmptyGuid } from "src/app/services/util.service";
import { VehicleStockSummaryReportService } from "src/app/services/sales/vehicle-stock-summary-report.service";

@Component({
  selector: "app-vehicle-stock-summary-report-detail",
  templateUrl: "./vehicle-stock-summary-report-detail.component.html",
  styleUrls: ["./vehicle-stock-summary-report-detail.component.css"],
})
export class VehicleStockSummaryReportDetailComponent
  implements OnInit
{
  faPlus = faPlus;
  faCancel = faCancel;
  faPencil = faPencil;

  mode: string = "Add";
  lockUpdate: boolean = true;
  masterId: string = getEmptyGuid();
  masterCategory: string = "A";

  availableModels: any[] = [];
  availablePositions: any[] = [];

  constructor(
    private vehicleStockSummaryReportService: VehicleStockSummaryReportService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadModelShortDesc();
    this.loadActiveModels();
    this.route.params.subscribe((params) => {
      this.masterId = params["id"];
      this.masterCategory = params["category"];
      if (this.masterId !== getEmptyGuid()) {
        this.mode = "Edit";
        this.lockUpdate = false;
        this.getField("Position").show = false;
        this.getField("IsGrab").show = true;
        this.loadReportDetails();
      }
    });
  }

  formFields: FormField[] = [
    {
      type: "text",
      id: "ModelCode",
      label: "Model Code",
      required: true,
    },
    {
      type: "switch",
      id: "ShowInReport",
      label: "Show in Report",
    },
    {
      type: "select",
      id: "ModelShortDesc",
      label: "Model Short Desc",
      required: true,
    },
    {
      type: "switch",
      id: "AddCalcInYTD",
      label: "Add Calc In YTD",
    },
    {
      type: "select",
      id: "Category",
      label: "Category",
    },
    {
      type: "select",
      id: "Position",
      label: "Position",
    },
    {
      type: "switch",
      id: "IsGrab",
      label: "Is Taxi",
      show: false,
    },
    {
      type: "radio",
      id: "si",
      label: "",
      options: [
        {
          value: "Above",
          display: "Above",
        },
        {
          value: "Below",
          display: "Below",
        },
      ],
    },
  ];

  loadModelShortDesc(): void {
    this.vehicleStockSummaryReportService
      .GetModelShortDescList()
      .subscribe({
        next: (response) => {
          console.log(response);
          /* this.availableModels = response.body;
          this.getField("ModelShortDesc").options = response.body.map(
            (item) => ({
              display: item.ModelShortDescription,
              value: item.ModelShortDescription,
            }),
          ); */
        },
        error: console.error,
      });
  }

  loadActiveModels(): void {
    this.route.params.subscribe((params) => {
      this.vehicleStockSummaryReportService
        .GetActiveModels(params["category"])
        .subscribe({
          next: (response) => {
            console.log(response);
            /* this.availableModels = response.body;
          this.getField("ModelShortDesc").options = response.body.map(
            (item) => ({
              display: item.ModelShortDescription,
              value: item.ModelShortDescription,
            }),
          ); */
          },
          error: console.error,
        });
    });
  }

  private loadReportDetails(): void {
    this.vehicleStockSummaryReportService
      .GetDetails(this.masterId, this.masterCategory)
      .subscribe({
        next: (response) => {
          const { Id, OrderNo, ...data } = response.body;
          Object.entries(data).forEach(([key, value]) => {
            this.getField(key).value = value;
          });
        },
        error: (error: any) => {
          console.error("Error loading model details:", error);
        },
      });
  }

  onCancel(): void {
    this.router.navigate(["../../../vehicle-stock-summary-report"], {
      relativeTo: this.route,
    });
  }

  getField(id: string): any {
    const field = this.formFields.find((f) => f.id === id);
    return field;
  }
}
