import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class CreditMemoService {
  constructor(private http: HttpUtilService) {}

  /**
   * Fetches the finance company dropdown list.
   * @returns An Observable of the finance company list.
   */
  getFinanceCompanyList(): Observable<any> {
    const url = "FinanceManagerService.asmx/GetDrpList";
    return this.http.get(url);
  }

  /**
   * Fetches the insurance company dropdown list.
   * @returns An Observable of the insurance company list.
   */
  getInsuranceCompanyList(): Observable<any> {
    const url = "InsuranceService.asmx/GetActiveDrpList";
    return this.http.get(url);
  }

  /**
   * Fetches contract details by contract number.
   * @param contractNo - The contract number.
   * @returns An Observable of the contract details.
   */
  getContractDetails(contractNo: string): Observable<any> {
    const encodedContractNo = encodeURIComponent(`"${contractNo}"`);
    const url = `CreditMemoService.asmx/Get?contractNo=${encodedContractNo}`;
    return this.http.get(url);
  }

  /**
 * Updates the credit memo with the provided data.
 * @param updateData - The data of the credit memo to be updated.
 * @returns An Observable of the update response.
 */
  updateCreditMemo(updateData: any): Observable<any> {
    const url = "CreditMemoService.asmx/Update";
    const payload = { model: updateData }; // Encapsula los datos en la clave "model"
    return this.http.post(url, payload);
  }


  /**
   * Prints the memo for a given contract number.
   * @param contractNo - The contract number.
   * @returns An Observable of the print response.
   */
  printMemo(contractNo: string): Observable<any> {
    const url = "CreditMemoService.asmx/PrintMemo";
    return this.http.post(url, { id: contractNo });
  }

  /**
   * Prints the credit memo request form for a given contract number.
   * @param contractNo - The contract number.
   * @returns An Observable of the print response.
   */
  printCreditMemoRequestForm(contractNo: string): Observable<any> {
    const url = "CreditMemoService.asmx/PrintCreditMemoRequestForm";
    return this.http.post(url, { id: contractNo });
  }
}
