<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">COE allocation</h1>
    <app-navpath />
  </div>
  <ul class="tabs-container">
    <li
      (click)="selectedTab = 'single'"
      [ngClass]="
        selectedTab == 'single' ? 'tab-item_selected' : 'tab-item'
      "
    >
      Single allocation
    </li>
    <li
      (click)="selectedTab = 'all'"
      [ngClass]="
        selectedTab == 'all' ? 'tab-item_selected' : 'tab-item'
      "
    >
      Allocate all
    </li>
  </ul>
  <div
    [hidden]="!(selectedTab == 'single')"
    class="content-container__tabs"
  >
    <form class="flex text-sm">
      <label for="customerBid">
        <input id="customerBid" type="radio" value="customer" checked />
        Customer's bid
      </label>
      <label for="komocoBid">
        <input id="komocoBid" type="radio" value="komoco" />
        Komoco's bid
      </label>
      <label for="otherBid">
        <input id="otherBid" type="radio" value="others" />
        Others
      </label>
    </form>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details of contract</h2>
      <app-data-form
        [dataFields]="detailsContract"
        [dataName]="'detailsContract'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details of COE</h2>
      <app-data-form
        [dataFields]="detailsCOE"
        [dataName]="'detailsCOE'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn lighter-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1" />Add
      </button>
      <button class="shorter-btn green-btn">
        <fa-icon [icon]="faEdit" class="mr-1" />Update
      </button>
      <button class="shorter-btn red-btn">
        <fa-icon [icon]="faTrash" class="mr-1" />Delete
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </div>
  <div
    [hidden]="!(selectedTab == 'all')"
    class="content-container__tabs"
  >
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Upload COE certificates
      </h2>
      <app-data-form
        [dataFields]="uploadCOECertificates"
        [dataName]="'uploadCOECertificates'"
        (updateFields_child)="updateFields($event)"
      />
      <div class="mt-4 flex w-fit gap-1">
        <button class="shorter-btn yellow-btn">
          <fa-icon [icon]="faEye" class="mr-1" />Show list
        </button>
        <button class="shorter-btn gray-btn">
          <fa-icon [icon]="faBroom" class="mr-1" />Clear
        </button>
      </div>
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Legend</h2>
      <div class="mt-4 flex gap-2 text-sm font-bold">
        <p
          *ngFor="let legend of filterLegends(legends)"
          class="rounded-full px-2"
          [ngClass]="legend.color"
        >
          {{ legend.label }}
        </p>
      </div>
    </section>
    <section class="content-container">
      <h2 class="sub-heading mb-4 w-full border-b">
        COE certificates
      </h2>
      <button class="shorter-btn darker-primary-btn locked-btn" disabled>
        <fa-icon [icon]="faUpload" class="mr-1" /> Allocate COE
        certificates to contracts
      </button>
    </section>
  </div>
</section>
