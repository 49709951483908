import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPencil, faBan, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { PremiumCareMemberService } from "src/app/services/customer/premium-care-member.service";
import { NotifierService } from "angular-notifier";
import { isEmptyGuid } from "../../../../services/util.service";  // Importación de utilidad para GUID vacío

@Component({
  selector: 'app-premium-care-member-detail',
  templateUrl: './premium-care-member-detail.component.html',
  styleUrls: ['./premium-care-member-detail.component.css']
})
export class PremiumCareMemberDetailComponent implements OnInit {

  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faMinus = faMinus;

  membershipId: string = "";
  mode: string = 'Add';
  types_select = [];

  isEmptyGuid: boolean = false;

  membershipDetails: any; // For storing membership details
  membershipTypes: any[] = []; // For storing membership types
  vehicleGroups: any[] = []; // For storing vehicle groups
  modelSpecifications: any[] = []; // For storing model specifications
  premiumBenefits: any[] = []; // For storing premium benefits
  memberBenefits: any[] = []; // For storing member benefits usage
  memberUsage: any[] = []; // For storing benefits usage data


  membershipInfoExpanded: boolean = true;
  benefitsEntitlementExpanded: boolean = true;
  benefitsUsageExpanded: boolean = true;

  toggleSection(section: string) {
    switch (section) {
      case 'membershipInfoExpanded':
        this.membershipInfoExpanded = !this.membershipInfoExpanded;
        break;
      case 'benefitsEntitlementExpanded':
        this.benefitsEntitlementExpanded = !this.benefitsEntitlementExpanded;
        break;
      case 'benefitsUsageExpanded':
        this.benefitsUsageExpanded = !this.benefitsUsageExpanded;
        break;
    }
  }


  mainFormField: FormField[] = [
    { type: "search", id: "vehicleNo", label: "Vehicle Number", required: true, searchEvent: true, searchType: "vehicle" },
    { type: "select", id: "vehicleGroup", label: "Vehicle Group", options: [] },
    { type: "text", id: "chassisNumber", label: "Chassis Number", required: true },
    { type: "select", id: "modelShortDesc", label: "Model Short Desc", options: [], lock: false },
    { type: "search", id: "icNo", label: "NRIC No/RCB No/Passport No", required: true, searchEvent: true, searchType: "ic" },
    {
      type: "select",
      id: "title",
      label: "Title",
      options: [
        { display: "MR", value: "MR" },
        { display: "MRS", value: "MRS" },
        { display: "MISS", value: "MISS" },
        { display: "M/S", value: "M/S" },
        { display: "MDM", value: "MDM" },
        { display: "DR", value: "DR" },
        { display: "MS", value: "MS" },
      ],
    },
    {
      type: "text",
      id: "nameNric",
      label: "Name as in NRIC/Passport",
      required: true,
    },
    {
      type: "text",
      id: "blockHouse",
      label: "Block/House",
    },
    {
      type: "text",
      id: "streetName",
      label: "Street Name",
    },
    {
      type: "text",
      id: "unit",
      label: "Unit",
    },
    {
      type: "search",
      id: "postalCode",
      label: "Postal Code",
      required: true,
      options: [],
      searchEvent: true,
      searchType: "postalCode"
    },
    {
      type: "text",
      id: "mobileNumber",
      label: "Mobile number",
      required: true,
    },
    {
      type: "text",
      id: "email",
      label: "Email",
    },
    {
      type: "date",
      id: "dateBirth",
      label: "Date of birth (dd/mm/yyyy)",
    },
    {
      type: "select",
      id: "gender",
      label: "Gender",
      options: [
        { display: "Female", value: "Female" },
        { display: "Male", value: "Male" },
      ],
    },    
    {
      type: "date",
      id: "consentDate",
      label: "Consent Date",
      required: true,
    },
    {
      type: "checkbox",
      id: "modeConsent",
      label: "Mode of consent",
      options: [
        {
          display: "Direct mailer",
          value: "directMailer",
        },
        {
          display: "Mail",
          value: "mail",
        },
        {
          display: "SMS/MMS",
          value: "smsMms",
        },
        {
          display: "Phone call",
          value: "phoneCall",
        },
      ],
    }, 
    {
      type: "formCheckbox",
      id: "declarationCommunication",
      label:
        "I do not wish to receive any communication from Komoco Motors Pte Ltd and/or its partners.",
    },
  ];

  membershipInformationFormField: FormField[] = [
    { 
      type: "select", 
      id: "membershipType", 
      label: "Membership Type", 
      options: [], 
      required: true, 
    },
    {
      type: "text",
      id: "remarks",
      label: "Remarks",
    },   
    {
      type: "date",
      id: "effectiveDate",
      label: "Effective Date (dd/mm/yyyy)",
      required: true,
    },   
    {
      type: "date",
      id: "expiryDate",
      label: "Expiry Date (dd/mm/yyyy)",
      required: true,
    },
    {
      type: "select",
      id: "wecStatus",
      label: "WEC Status",
      options: [
        { display: "Valid", value: "Valid" },
        { display: "Invalid", value: "Invalid" },
        { display: "-NIL-", value: "NIL" },
      ],
      required: true,
    },
    {
      type: "text",
      id: "wecSerialNumber",
      label: "WEC Serial Number",
      required: true,
    },   
  ]

  membershipBenefitsEntitlementFormField: FormField[] = [
    {
      type: "select", 
      id: "description", 
      label: "Description", 
      options: [], 
      required: true, 
    },
    {
      type: "number",
      id: "quantity",
      label: "Quantity",
      required: true
    }
  ]

  benefitsUsageFormField: FormField[] = [
    { //GetAllPremiumBenefit
      type: "select", 
      id: "description", 
      label: "Description", 
      options: [], 
      required: true, 
    },
    {
      type: "number",
      id: "quantity",
      label: "Quantity",
      required: true
    },   
    {
      type: "date",
      id: "dateUsed",
      label: "Date Used (dd/mm/yyyy)",
      required: true,
    },   
  ]

  membershipBenefitsTableHeaders = [
    { title: "ENTITLEMENT QUANTITY", type: "data" },
    { title: "BENEFIT DESCRIPTION", type: "data", classes: "max-w-64" },
    { title: "QTY USED", type: "data" },
    { title: "ENTITLEMENT BALANCE", type: "data" },
    { title: "COMPLETED?", type: "data" },
    { title: "ACTION", type: "trash" },
    { title: "MEMBERSHIPID", type: "hidden", id: "id"},
    { title: "PREMIUMBENEFITID", type: "hidden", id: "id"}
  ];

  membershipUsageTableHeaders = [
    { title: "DATE USED", type: "data" },
    { title: "QTY USED", type: "data" },
    { title: "BENEFIT DESCRIPTION", type: "data" },
    { title: "ACTION", type: "trash" },
    { title: "BENEFITUSAGEID", type: "hidden", id: "id"}
  ];

  constructor(
    private premiumCareMemberService: PremiumCareMemberService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifier: NotifierService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
        this.membershipId = params['id'];

        if (this.membershipId && this.membershipId !== "00000000-0000-0000-0000-000000000000") {
            this.mode = 'Detail';
            this.isEmptyGuid = false;
            this.getDetailInfoFromId();
            this.getMemberBenefits();
            this.getMemberUsage();
        } else {
            this.mode = 'Add';
            this.isEmptyGuid = true;
            this.setDefaultDatesForNewMember();
        }

        this.getAllMembershipType();
        this.getAllVehicleGroup();
        this.getAllModelSpecification();
        this.getAllPremiumBenefit();
    });
}


  private setDefaultDatesForNewMember(): void {
    const today = new Date();
    const consentDate = this.convertDateToYYYYMMDDString(today);
    const expiryDate = this.convertDateToYYYYMMDDString(new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()));

    this.setFormFieldValue('consentDate', consentDate);
    this.setFormFieldValue('effectiveDate', consentDate);
    this.setFormFieldValue('expiryDate', expiryDate);
}

// Conversión de Date a String (yyyy-mm-dd)
private convertDateToYYYYMMDDString(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}
  
addPremiumCareMember(): void {

  const transformedData: any = {
      AddressBlock: this.mainFormField.find(f => f.id === 'blockHouse')?.value || '',
      AddressRoad: this.mainFormField.find(f => f.id === 'streetName')?.value || '',
      AddressUnit: this.mainFormField.find(f => f.id === 'unit')?.value || '',
      AgreeEmail: false, 
      AgreeMailer: false,
      AgreePhone: false, 
      AgreeSMS: false, 
      ChassisNumber: this.mainFormField.find(f => f.id === 'chassisNumber')?.value || 'AAA',
      ConsentDate: this.formatDate(this.mainFormField.find(f => f.id === 'consentDate')?.value || '29/09/2024'),
      DoNotAgree: true, 
      Email: this.mainFormField.find(f => f.id === 'email')?.value || 'aaa@gmail.com',
      EntitleQty: 2,
      ExpiryDate: this.formatDate(this.membershipInformationFormField.find(f => f.id === 'expiryDate')?.value || '29/09/2026'),
      Gender: this.mainFormField.find(f => f.id === 'gender')?.value === 'Male' ? 'M' : 'F',
      Hp: this.mainFormField.find(f => f.id === 'mobileNumber')?.value || 'aaa',
      IcNo: this.mainFormField.find(f => f.id === 'icNo')?.value || 'AAA',
      JoinedDate: this.formatDate(this.membershipInformationFormField.find(f => f.id === 'effectiveDate')?.value || '29/09/2024'),
      MemberType: this.membershipInformationFormField.find(f => f.id === 'membershipType')?.value || 'GOLD',
      Name: this.mainFormField.find(f => f.id === 'nameNric')?.value || 'AAA',
      PostalCode: this.mainFormField.find(f => f.id === 'postalCode')?.value || 'aaa',
      VehicleNo: this.mainFormField.find(f => f.id === 'vehicleNo')?.value || 'aaa',
      WarrantySerial: this.membershipInformationFormField.find(f => f.id === 'wecSerialNumber')?.value || 'AAA',
      WarrantyStatus: this.membershipInformationFormField.find(f => f.id === 'wecStatus')?.value || 'Valid',
  };

  const membershipTypeField = this.membershipInformationFormField.find(f => f.id === 'membershipType');
  if (membershipTypeField && membershipTypeField.value) {
      const selectedMembership = this.membershipTypes.find(type => type.Name === membershipTypeField.value);
      if (selectedMembership) {
          transformedData.MemberType = selectedMembership.Value;
      }
  }

  console.log('Datos enviados al servicio:', transformedData);

  this.premiumCareMemberService.addMember(transformedData).subscribe(
      (response) => {
          try {
              let newMemberId = response?.d?.Data;

              if (!newMemberId && response?.body) {
                  newMemberId = response.body;
              }

              if (newMemberId) {
                  this.notifier.notify('success', 'Premium Care Member added successfully.');
                  this.router.navigate(['/customer/premium-care-member', newMemberId]);
              } else {
                  this.notifier.notify('error', 'Failed to get new Member ID.');
                  console.error('Response structure does not contain the expected GUID:', response);
              }
          } catch (error) {
              console.error('Error parsing the response:', error);
              this.notifier.notify('error', 'Failed to process the response from the server.');
          }
      },
      (error) => {
          console.error('Error adding Premium Care Member:', error);
          this.notifier.notify('error', 'Failed to add Premium Care Member. Please try again.');
      }
  );
}



  private getDetailInfoFromId(): void {
    this.premiumCareMemberService.getDetailInfoFromId(this.membershipId).subscribe(
      (response) => {
        //console.log('Full response for GetDetailInfoFromId:', response);  
        if (response?.body) {
          this.membershipDetails = response.body;
          //console.log('Membership Details:', this.membershipDetails);
  
          const vehicleNoField = this.mainFormField.find(f => f.id === 'vehicleNo');
          if (vehicleNoField) vehicleNoField.value = this.membershipDetails.VehicleNo;
  
          const chassisNumberField = this.mainFormField.find(f => f.id === 'chassisNumber');
          if (chassisNumberField) chassisNumberField.value = this.membershipDetails.ChassisNumber;
  
          const icNoField = this.mainFormField.find(f => f.id === 'icNo');
          if (icNoField) icNoField.value = this.membershipDetails.IcNo;

          const modelShortDescField = this.mainFormField.find(f => f.id === 'modelShortDesc');
        if (modelShortDescField) {
          modelShortDescField.value = this.membershipDetails.ModelShortDesc;

          if (this.mode === 'Detail' && this.membershipDetails.ModelShortDesc) {
            modelShortDescField.lock = true;
          } else {
            modelShortDescField.lock = false;
          }
        }
  
          const titleField = this.mainFormField.find(f => f.id === 'title');
          if (titleField) titleField.value = this.membershipDetails.Title;
  
          const nameField = this.mainFormField.find(f => f.id === 'nameNric');
          if (nameField) nameField.value = this.membershipDetails.Name;
  
          const blockHouseField = this.mainFormField.find(f => f.id === 'blockHouse');
          if (blockHouseField) blockHouseField.value = this.membershipDetails.AddressBlock;
  
          const streetNameField = this.mainFormField.find(f => f.id === 'streetName');
          if (streetNameField) streetNameField.value = this.membershipDetails.AddressRoad;
  
          const unitField = this.mainFormField.find(f => f.id === 'unit');
          if (unitField) unitField.value = this.membershipDetails.AddressUnit;
  
          const postalCodeField = this.mainFormField.find(f => f.id === 'postalCode');
          if (postalCodeField) postalCodeField.value = this.membershipDetails.PostalCode;
  
          const mobileNumberField = this.mainFormField.find(f => f.id === 'mobileNumber');
          if (mobileNumberField) mobileNumberField.value = this.membershipDetails.Hp;
  
          const emailField = this.mainFormField.find(f => f.id === 'email');
          if (emailField) emailField.value = this.membershipDetails.Email;

          const genderField = this.mainFormField.find(f => f.id === 'gender');
          if (genderField) {
            genderField.value = this.membershipDetails.Gender === 'M' ? 'Male' : 'Female';
          }

          this.setFormFieldValue('dateBirth', this.convertDateToYYYYMMDD(this.membershipDetails.Dob));
          this.setFormFieldValue('consentDate', this.convertDateToYYYYMMDD(this.membershipDetails.ConsentDate));
          this.setFormFieldValue('effectiveDate', this.convertDateToYYYYMMDD(this.membershipDetails.JoinedDate));
          this.setFormFieldValue('expiryDate', this.convertDateToYYYYMMDD(this.membershipDetails.ExpiryDate));

          const vehicleGroupField = this.mainFormField.find(f => f.id === 'vehicleGroup');
          if (vehicleGroupField) {
            const selectedVehicleGroup = this.vehicleGroups.find(
              (group: any) => group.Value === this.membershipDetails.VehicleGroup
            );
            if (selectedVehicleGroup) {
              vehicleGroupField.value = `${selectedVehicleGroup.Value} - ${selectedVehicleGroup.Name}`;
            }
          }
  
          this.setFormFieldValue('modelShortDesc', this.membershipDetails.ModelShortDesc);
  
          this.setFormFieldValue('membershipType', this.membershipDetails.MemberTypeDesc);
          this.setFormFieldValue('wecStatus', this.membershipDetails.WarrantyStatus);
          this.setFormFieldValue('wecSerialNumber', this.membershipDetails.WarrantySerial);
  
          const consentField = this.mainFormField.find(f => f.id === 'modeConsent');
          if (consentField && consentField.options) {
            this.updateCheckboxValue(consentField, 'directMailer', this.membershipDetails.AgreeMailer);
            this.updateCheckboxValue(consentField, 'smsMms', this.membershipDetails.AgreeSMS);
            this.updateCheckboxValue(consentField, 'phoneCall', this.membershipDetails.AgreePhone);
          }
  
        } else {
          console.warn('No Membership Details found in response.');
        }
      },
      (error) => {
        console.error('Error fetching membership details:', error);
      }
    );
  }

  private updateCheckboxValue(consentField: any, optionValue: string, newValue: boolean) {
    const option = consentField.options.find((o: any) => o.value === optionValue);
    if (option) {
      option.marked = newValue;
    }
  }

  private convertDateToYYYYMMDD(dateString: string): string {
    if (!dateString) return ''; 
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  private setFormFieldValue(fieldId: string, value: string | null) {
    const formField = this.mainFormField.find(f => f.id === fieldId) ||
                      this.membershipInformationFormField.find(f => f.id === fieldId);
    if (formField) {
      if (formField.type === 'date' && value) {
        formField.value = this.convertDateToYYYYMMDD(value);
      } else {
        formField.value = value || '';
      }
    }
  }
  

  
  
  
  
  private getAllMembershipType(): void {
    this.premiumCareMemberService.getAllMembershipType().subscribe(
      (response) => {
        //('Full response for GetAllMembershipType:', response);  
        if (response?.body) {
          this.membershipTypes = response.body;  
          const membershipField = this.membershipInformationFormField.find(f => f.id === 'membershipType');
          if (membershipField) {
            membershipField.options = this.membershipTypes.map((type: any) => ({
              display: type.Name,
              value: type.Name,
            }));
          }
          //console.log('Membership Types:', this.membershipTypes);
        } else {
          console.warn('No Membership Types found in response.');
        }
      },
      (error) => {
        console.error('Error fetching membership types:', error);
      }
    );
  }
  
  private getAllVehicleGroup(): void {
    this.premiumCareMemberService.getAllVehicleGroup().subscribe(
      (response) => {
        //console.log('Full response for GetAllVehicleGroup:', response);  
        if (response?.body) {
          this.vehicleGroups = response.body;  
          const vehicleGroupField = this.mainFormField.find(f => f.id === 'vehicleGroup');
          if (vehicleGroupField) {
            vehicleGroupField.options = this.vehicleGroups.map((group: any) => ({
              display: `${group.Value} - ${group.Name}`,
              value: `${group.Value} - ${group.Name}`,
            }));
          }
          //console.log('Vehicle Groups:', this.vehicleGroups);
        } else {
          console.warn('No Vehicle Groups found in response.');
        }
      },
      (error) => {
        console.error('Error fetching vehicle groups:', error);
      }
    );
  }
  
  private getAllModelSpecification(): void {
    this.premiumCareMemberService.getAllModelSpecification().subscribe(
      (response) => {
        //console.log('Full response for GetAllModelSpecification:', response);  
        if (response?.body) {
          this.modelSpecifications = response.body;  
          const modelShortDescField = this.mainFormField.find(f => f.id === 'modelShortDesc');
          if (modelShortDescField) {
            modelShortDescField.options = this.modelSpecifications.map((spec: any) => ({
              display: spec.ModelShortDesc,
              value: spec.ModelShortDesc,
            }));
          }
          //console.log('Model Specifications:', this.modelSpecifications);
        } else {
          console.warn('No Model Specifications found in response.');
        }
      },
      (error) => {
        console.error('Error fetching model specifications:', error);
      }
    );
  }
  
  private getAllPremiumBenefit(): void {
    this.premiumCareMemberService.getAllPremiumBenefit().subscribe(
      (response) => {
        //console.log('Full response for GetAllPremiumBenefit:', response);  
        if (response?.body) {
          this.premiumBenefits = response.body;  
          const benefitOptions = this.premiumBenefits.map((benefit: any) => ({
            display: benefit.Name,
            value: benefit.Name,
          }));
          
          const membershipBenefitsField = this.membershipBenefitsEntitlementFormField.find(f => f.id === 'description');
          if (membershipBenefitsField) {
            membershipBenefitsField.options = benefitOptions;
          }
          
          const benefitsUsageField = this.benefitsUsageFormField.find(f => f.id === 'description');
          if (benefitsUsageField) {
            benefitsUsageField.options = benefitOptions;
          }

          //console.log('Premium Benefits:', this.premiumBenefits);
        } else {
          console.warn('No Premium Benefits found in response.');
        }
      },
      (error) => {
        console.error('Error fetching premium benefits:', error);
      }
    );
  }

  
  private getMemberBenefits(): void {
    this.premiumCareMemberService.getMemberBenefits(this.membershipId).subscribe(
      (response) => {
        console.log('Full response for GetMemberBenefits:', response); 
        if (response?.body) {
          this.memberBenefits = response.body.map((benefit: any) => {
            const container = {
              entitlementQuantity: "", 
              benefitDescription: "", 
              quantityUsed: "", 
              entitlementBalance: "", 
              completed: "", 
              trash: "",
              membershipId: "", 
              premiumBenefitId: "",
            };
  
            container["entitlementQuantity"] = benefit.EntitleQty; 
            container["benefitDescription"] = benefit.BenefitDesc; 
            container["quantityUsed"] = benefit.EntitleUsed; 
            container["entitlementBalance"] = benefit.EntitleBalance; 
            container["completed"] = benefit.Completed ? '✔️' : ''; 
            container["trash"] = "";
            container["membershipId"] = benefit.MembershipId;
            container["premiumBenefitId"] = benefit.PremiumBenefitId;
  
            return container;
          });
          console.log('Processed Member Benefits for table:', this.memberBenefits);
        } else {
          console.warn('No Member Benefits found in response.');
        }
      },
      (error) => {
        console.error('Error fetching member benefits:', error);
      }
    );
  }

  
  private getMemberUsage(): void {
    this.premiumCareMemberService.getMemberUsage(this.membershipId).subscribe(
      (response) => {
        //console.log('Full response for GetMemberUsage:', response); 
        if (response?.body) {
          this.memberUsage = response.body.map((usage: any) => {
            const container = {
              dateUsed: "", 
              quantityUsed: "", 
              benefitDescription: "", 
              trash: "",
              benefitUsageId: "",
            };
  
            container["dateUsed"] = usage.DateUsed; 
            container["quantityUsed"] = usage.EntitleQtyUsed; 
            container["benefitDescription"] = usage.BenefitDesc; 
            container["trash"] = ""; 
            container["benefitUsageId"] = usage.BenefitUsageId;
  
            return container;
          });
          //console.log('Processed Member Usage for table:', this.memberUsage);
        } else {
          console.warn('No Member Usage found in response.');
        }
      },
      (error) => {
        console.error('Error fetching member usage:', error);
      }
    );
  }
  
  formatDate(date: string): string {
    if (date.includes('/')) {
        return date; 
    }

    if (!date || date.includes('undefined')) return ''; 
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
}

updatePremiumCareMember(): void {
  const transformedData = {
      MembershipId: this.membershipId,
      MemberId: this.membershipDetails.MemberId || null,
      VehicleNo: this.mainFormField.find(f => f.id === 'vehicleNo')?.value || '',
      ChassisNumber: this.mainFormField.find(f => f.id === 'chassisNumber')?.value || '',
      ModelShortDesc: this.mainFormField.find(f => f.id === 'modelShortDesc')?.value || '',
      IcNo: this.mainFormField.find(f => f.id === 'icNo')?.value || '',
      MemberType: this.membershipDetails.MemberType || '', 
      MemberTypeDesc: this.membershipDetails.MemberTypeDesc || '', 
      CaseNumber: this.membershipDetails.CaseNumber || '',
      JoinedDate: this.formatDate(this.membershipInformationFormField.find(f => f.id === 'effectiveDate')?.value || ''),
      ExpiryDate: this.formatDate(this.membershipInformationFormField.find(f => f.id === 'expiryDate')?.value || ''),
      WarrantySerial: this.membershipInformationFormField.find(f => f.id === 'wecSerialNumber')?.value || null,
      WarrantyStatus: this.membershipInformationFormField.find(f => f.id === 'wecStatus')?.value || null,
      VehicleGroup: this.mainFormField.find(f => f.id === 'vehicleGroup')?.value || null,
      AgreeMailer: this.membershipDetails.AgreeMailer || false,
      AgreeSMS: this.membershipDetails.AgreeSMS || false,
      AgreePhone: this.membershipDetails.AgreePhone || false,
      AgreeEmail: this.membershipDetails.AgreeEmail || false,
      DoNotAgree: this.membershipDetails.DoNotAgree || false,
      ConsentDate: this.formatDate(this.membershipDetails.ConsentDate), 
      Remarks: this.membershipInformationFormField.find(f => f.id === 'remarks')?.value || null,
      Dob: this.formatDate(this.mainFormField.find(f => f.id === 'dateBirth')?.value || ''),
      Hp: this.mainFormField.find(f => f.id === 'mobileNumber')?.value || '',
      Title: this.mainFormField.find(f => f.id === 'title')?.value || '',
      Name: this.mainFormField.find(f => f.id === 'nameNric')?.value || '',
      AddressBlock: this.mainFormField.find(f => f.id === 'blockHouse')?.value || '',
      AddressRoad: this.mainFormField.find(f => f.id === 'streetName')?.value || '',
      AddressUnit: this.mainFormField.find(f => f.id === 'unit')?.value || '',
      PostalCode: this.mainFormField.find(f => f.id === 'postalCode')?.value || '',
      Email: this.mainFormField.find(f => f.id === 'email')?.value || '',
      Gender: this.mainFormField.find(f => f.id === 'gender')?.value === 'Male' ? 'M' : 'F',
      EntitleQty: 2
  };

  this.premiumCareMemberService.editMember(transformedData).subscribe(
      (response) => {
          //console.log('Member updated successfully:', response);
      },
      (error) => {
          console.error('Error updating member:', error);
      }
  );
}

  private collectUpdatedData(): any {
    const updatedData: any = {};
  
    this.mainFormField.forEach(field => {
      if (field.value !== this.membershipDetails[field.id]) {
        updatedData[field.id] = field.value;
      }
    });
  
    this.membershipInformationFormField.forEach(field => {
      if (field.value !== this.membershipDetails[field.id]) {
        updatedData[field.id] = field.value;
      }
    });
  
    return updatedData;
  }

  addUsage(): void {
    const descriptionField = this.benefitsUsageFormField.find(f => f.id === 'description');
    if (!descriptionField || !descriptionField.value) {
      this.notifier.notify('error', 'Please select a benefit description.');
      return;
    }
  
    const selectedDescription = descriptionField.value.trim().toLowerCase().replace(/\s+/g, '');
  
    const dateUsedRaw = this.benefitsUsageFormField.find(f => f.id === 'dateUsed')?.value || '';
    const quantityRaw = this.benefitsUsageFormField.find(f => f.id === 'quantity')?.value || 0;
  
    if (!dateUsedRaw || !quantityRaw) {
      this.notifier.notify('error', 'Please fill in all required fields.');
      return;
    }
  
    const [year, month, day] = dateUsedRaw.split('-');
    if (!year || !month || !day) {
      this.notifier.notify('error', 'Invalid date format. Please select a valid date.');
      return;
    }
    const dateUsed = `${day}/${month}/${year}`;
    const quantity = parseInt(quantityRaw, 10);
  
    console.log('Selected Description:', selectedDescription);
    console.log('Member Benefits:', this.memberBenefits);
  
    const selectedBenefit = this.memberBenefits.find(benefit => {
      const benefitDesc = benefit.benefitDescription?.trim().toLowerCase().replace(/\s+/g, '');
      console.log(`Comparing "${benefitDesc}" with "${selectedDescription}"`);
      return benefitDesc === selectedDescription;
    });
  
    if (!selectedBenefit || !selectedBenefit.premiumBenefitId) {
      console.error('Selected benefit not found or invalid:', selectedDescription);
      this.notifier.notify('error', 'Please select a valid benefit.');
      return;
    }
  
    const premiumBenefitId = selectedBenefit.premiumBenefitId;
  
    const usageData = {
      DateUsed: dateUsed,
      EntitleQtyUsed: quantity,
      PremiumBenefitId: premiumBenefitId,
    };
  
    this.premiumCareMemberService.addMemberUsage(usageData).subscribe(
      (response) => {
        this.notifier.notify('success', 'Usage added successfully.');
        this.getMemberUsage();
        this.getMemberBenefits();
      },
      (error) => {
        console.error('Error adding usage:', error);
        this.notifier.notify('error', 'Failed to add usage. Please try again.');
      }
    );
  }
  
  addBenefit(): void {
    const descriptionField = this.membershipBenefitsEntitlementFormField.find(f => f.id === 'description');
    if (!descriptionField || !descriptionField.value) {
      this.notifier.notify('error', 'Please select a benefit description.');
      return;
    }
  
    const selectedDescription = descriptionField.value.trim().toLowerCase().replace(/\s+/g, '');
  
    const quantityRaw = this.membershipBenefitsEntitlementFormField.find(f => f.id === 'quantity')?.value || 0;
  
    if (!quantityRaw) {
      this.notifier.notify('error', 'Please fill in all required fields.');
      return;
    }
  
    const quantity = parseInt(quantityRaw, 10);
  
    console.log('Selected Description for Benefit:', selectedDescription);
    console.log('Member Benefits:', this.premiumBenefits);
  
    const selectedBenefit = this.premiumBenefits.find(benefit => {
      const benefitDesc = benefit.Name?.trim().toLowerCase().replace(/\s+/g, '');
      console.log(`Comparing "${benefitDesc}" with "${selectedDescription}"`);
      return benefitDesc === selectedDescription;
    });
  
    if (!selectedBenefit || !selectedBenefit.Value) {
      console.error('Selected benefit code not found or invalid:', selectedDescription);
      this.notifier.notify('error', 'Please select a valid benefit.');
      return;
    }
  
    const benefitCode = selectedBenefit.Value;
    const membershipId = this.membershipId;
  
    const benefitData = {
      BenefitCode: benefitCode,
      EntitleQty: quantity,
      MembershipId: membershipId,
    };
  
    this.premiumCareMemberService.addMemberBenefit(benefitData).subscribe(
      (response) => {
        this.notifier.notify('success', 'Benefit added successfully.');
        this.getMemberBenefits();
        this.getMemberUsage();
      },
      (error) => {
        console.error('Error adding benefit:', error);
        this.notifier.notify('error', 'Failed to add benefit. Please try again.');
      }
    );
  }
  
  removeEntry(value: any): void {
    if (!value.benefitUsageId) {
      this.notifier.notify('error', 'Invalid usage entry. No benefit usage ID found.');
      return;
    }
  
    const benefitUsageId = value.benefitUsageId;
  
    this.premiumCareMemberService.deleteMemberUsage(benefitUsageId).subscribe(
      (response) => {
        this.notifier.notify('success', 'Usage entry deleted successfully.');
       this.getMemberUsage();
        this.getMemberBenefits();
      },
      (error) => {
        console.error('Error deleting usage entry:', error);
        this.notifier.notify('error', 'Failed to delete usage entry. Please try again.');
      }
    );
  }

  removeBenefit(value: any): void {
    if (!value.premiumBenefitId) {
      this.notifier.notify('error', 'Invalid benefit entry. No premium benefit ID found.');
      return;
    }
  
    const premiumBenefitId = value.premiumBenefitId;
  
    this.premiumCareMemberService.deleteMemberBenefit(premiumBenefitId).subscribe(
      (response) => {
        this.notifier.notify('success', 'Benefit entry deleted successfully.');
        this.getMemberBenefits();
        this.getMemberUsage();
      },
      (error) => {
        console.error('Error deleting benefit entry:', error);
        this.notifier.notify('error', 'Failed to delete benefit entry. Please try again.');
      }
    );
  } 

  fillByVehicleNo(event: any): void {
    console.log('fillByVehicleNo called with event:', event);
    const vehicleNoField = this.mainFormField.find(field => field.id === 'vehicleNo');
    if (vehicleNoField && vehicleNoField.value) {
        const vehicleNo = vehicleNoField.value;

        this.premiumCareMemberService.getInfoFromVehicleNo(vehicleNo).subscribe(
            (response: any) => {
                console.log('Response from VehicleNo:', response);
                
                let data = response?.body?.d?.Data;

                if (!data && response?.body) {
                    data = response.body;
                }

                if (data) {
                    this.setFormFieldValue('vehicleNo', data.VehicleNo);
                    this.setFormFieldValue('chassisNumber', data.ChassisNumber);
                    this.setFormFieldValue('icNo', data.IcNo);
                    this.setFormFieldValue('title', data.Title);
                    this.setFormFieldValue('nameNric', data.Name);
                    this.setFormFieldValue('blockHouse', data.AddressBlock);
                    this.setFormFieldValue('streetName', data.AddressRoad);
                    this.setFormFieldValue('unit', data.AddressUnit);
                    this.setFormFieldValue('postalCode', data.PostalCode);
                    this.setFormFieldValue('mobileNumber', data.Hp);
                    this.setFormFieldValue('email', data.Email);
                    this.setFormFieldValue('gender', data.Gender === 'M' ? 'Male' : 'Female');
                    this.setFormFieldValue('dateBirth', this.convertDateFromAPIFormat(data.Dob));
                    this.setFormFieldValue('registerDate', this.convertDateFromAPIFormat(data.RegisterDate));
                } else {
                    console.warn('No data found in response:', response);
                }
            },
            (error: any) => {
                console.error('Error fetching data from vehicle number:', error);
            }
        );
    }
}


private convertDateFromAPIFormat(apiDate: string): string {
    if (!apiDate) return '';
    const timestamp = parseInt(apiDate.replace(/\/Date\((\d+)\)\//, '$1'), 10);
    const date = new Date(timestamp);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

  
  fillByIcNo(event: any): void {
    const icNoField = this.mainFormField.find(field => field.id === 'icNo');
    if (icNoField && icNoField.value) {
      const icNo = icNoField.value;
  
      this.premiumCareMemberService.getProspectByIcNo(icNo).subscribe(
        (response: any) => {
          console.log('Response from ICNo:', response);
          if (response?.body) {
            const data = response.body;
  
            this.setFormFieldValue('icNo', data.IcNo);
            this.setFormFieldValue('title', data.Title);
            this.setFormFieldValue('nameNric', data.CustomerName);
            this.setFormFieldValue('blockHouse', data.AddressBlock);
            this.setFormFieldValue('streetName', data.AddressRoad);
            this.setFormFieldValue('unit', data.AddressUnit);
            this.setFormFieldValue('postalCode', data.PostalCode); 
            this.setFormFieldValue('mobileNumber', data.ContactNo);
            this.setFormFieldValue('email', data.Email);
            this.setFormFieldValue('gender', data.Gender === 'M' ? 'Male' : 'Female');
            this.setFormFieldValue('dateBirth', this.convertDateToYYYYMMDD(data.Dob));
          } else {
            console.warn('No data found in response:', response);
          }
        },
        (error) => {
          console.error('Error fetching data from IC number:', error);
        }
      );
    }
  }

  fillByPostalCode(event: any): void {
    const postalCodeField = this.mainFormField.find(field => field.id === 'postalCode');
    if (postalCodeField && postalCodeField.value) {
      const postalCode = postalCodeField.value;
  
      const apiUrl = `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=N&getAddrDetails=Y&pageNum=1`;
  
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          console.log('Response from PostalCode:', data);
  
          if (data?.results && data.results.length > 0) {
            const result = data.results[0];
            
            this.setFormFieldValue('blockHouse', result.BLK_NO || '');
            this.setFormFieldValue('streetName', result.ROAD_NAME || '');
            this.setFormFieldValue('unit', result.BUILDING || '');
            this.setFormFieldValue('postalCode', result.POSTAL || postalCode);
          } else {
            console.warn('No data found in response for PostalCode:', data);
            this.notifier.notify('warning', 'No address found for the given postal code.');
          }
        })
        .catch(error => {
          console.error('Error fetching data from PostalCode:', error);
          this.notifier.notify('error', 'Failed to fetch address information for the postal code. Please try again.');
        });
    }
  }
  
  
  handleSearch(event: any): void {
    console.log('handleSearch called with event:', event);
  
    const searchField = this.mainFormField.find(field => field.id === event.fieldId);
    console.log('Identified searchField:', searchField);
  
    if (searchField?.searchType === "vehicle") {
      this.fillByVehicleNo(event);
    } else if (searchField?.searchType === "ic") {
      this.fillByIcNo(event);
    } else if (searchField?.searchType === "postalCode") {
      this.fillByPostalCode(event);
    }
  }
  
  
  
}
