import { Component, OnInit } from "@angular/core";
import {
  faUserTag,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-click-drive-customer",
  templateUrl: "./click-drive-customer.component.html",
  styleUrls: ["./click-drive-customer.component.css"],
})
export class ClickDriveCustomerComponent implements OnInit {
  faUserTag = faUserTag;
  faUserEdit = faUserEdit;

  constructor() {}

  ngOnInit() {}
}
