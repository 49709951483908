import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencil, faBan, faUsers, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';
import { AccessoryService } from 'src/app/services/vehicle/accessory.service';
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid, isEmptyGuid } from '../../../../services/util.service';

@Component({
  selector: 'app-accessory-detail',
  templateUrl: './accessory-detail.component.html',
  styleUrls: ['./accessory-detail.component.css']
})
export class AccessoryDetailComponent implements OnInit {
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faUsers = faUsers;

  mode: string = "Add";
  accessoryId: string = getEmptyGuid();

  formFields: FormField[] = [
    { type: 'text', id: 'accessoryCode', label: 'Accessory Code', value: '' },
    { type: 'number', id: 'costPrice', label: 'Cost Price', defaultValue: 0, min: 0, step: 0.01 },
    { type: 'text', id: 'retailPrice', label: 'Retail Price', value: '' },
    { type: 'number', id: 'salesmanPrice', label: 'Salesman Price', defaultValue: 0, min: 0, step: 0.01 },
    { type: 'text', id: 'netPrice', label: 'Net Price', defaultValue: 0, min: 0, step: 0.01 },
    { type: 'select', id: 'fitted', label: 'Fitted', options: [{ value: 'Local', display: 'Local' }, { value: 'Factory', display: 'Factory' }] },
    { type: 'text', id: 'description', label: 'Description', value: '' },
    { type: 'select', id: 'classification', label: 'Classification', options: [{ value: 'I', display: 'I' }, { value: 'E', display: 'E' }] },
    { type: 'text', id: 'vendorName', label: 'Vendor Name', value: '' },
    { type: 'select', id: 'status', label: 'Status', options: [{ value: 'Active', display: 'Active' }, { value: 'Inactive', display: 'Inactive' }] }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accessoryService: AccessoryService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.accessoryId = params['id'];

      if (!isEmptyGuid(this.accessoryId)) {
        this.mode = 'Edit';
        this.loadAccessoryDetail();
      }
    });
  }

  private loadAccessoryDetail(): void {
    this.accessoryService.getDetail(this.accessoryId).subscribe({
      next: (response) => {
        const data = response.body;
        this.formFields = [
          { type: 'text', id: 'accessoryCode', label: 'Accessory Code', value: data.AccessoryCode },
          { type: 'number', id: 'costPrice', label: 'Cost Price', value: data.CostPrice, min: 0, step: 0.01 },
          { type: 'text', id: 'retailPrice', label: 'Retail Price', value: data.RetailPrice },
          { type: 'number', id: 'salesmanPrice', label: 'Salesman Price', value: data.SalesmanPrice, min: 0, step: 0.01 },
          { type: 'number', id: 'netPrice', label: 'Net Price', value: data.NettPrice, min: 0, step: 0.01 },
          { 
            type: 'select', 
            id: 'fitted', 
            label: 'Fitted', 
            value: data.Fitted === 'L' ? 'Local' : 'Factory',
            options: [{ value: 'Local', display: 'Local' }, { value: 'Factory', display: 'Factory' }] 
          },
          { type: 'text', id: 'description', label: 'Description', value: data.Description },
          { 
            type: 'select', 
            id: 'classification', 
            label: 'Classification', 
            value: data.Classification, 
            options: [{ value: 'I', display: 'I' }, { value: 'E', display: 'E' }] 
          },
          { type: 'text', id: 'vendorName', label: 'Vendor Name', value: data.Vendor },
          { 
            type: 'select', 
            id: 'status', 
            label: 'Status', 
            value: data.Status === '1' ? 'Active' : 'Inactive',
            options: [{ value: 'Active', display: 'Active' }, { value: 'Inactive', display: 'Inactive' }] 
          }
        ];
      },
      error: (error) => {
        console.error("Error loading accessory detail:", error);
        this.notifier.notify('error', 'Error loading accessory detail');
      }
    });
  }

  onSave(): void {
    if (this.mode === 'Add') {
      this.addAccessory();
    } else if (this.mode === 'Edit') {
      this.updateAccessory();
    }
  }

  private addAccessory(): void {
    const newAccessoryData = {
      AccessoryId: getEmptyGuid(),
      AccessoryCode: this.getFieldValue('accessoryCode'),
      CostPrice: this.getFieldValue('costPrice'),
      RetailPrice: this.getFieldValue('retailPrice'),
      SalesmanPrice: this.getFieldValue('salesmanPrice'),
      NettPrice: this.getFieldValue('netPrice'),
      Fitted: this.getFieldValue('fitted') === 'Local' ? 'L' : 'F',
      Description: this.getFieldValue('description'),
      Classification: this.getFieldValue('classification'),
      Vendor: this.getFieldValue('vendorName'),
      Status: this.getFieldValue('status') === 'Active' ? '1' : '0'
    };

    this.accessoryService.saveDetails(newAccessoryData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Accessory added successfully');
        this.router.navigate(['../../accessory'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error adding accessory:", error);
        this.notifier.notify('error', 'Error adding accessory');
      }
    });
  }

  private updateAccessory(): void {
    const updatedAccessoryData = {
      AccessoryId: this.accessoryId,
      AccessoryCode: this.getFieldValue('accessoryCode'),
      CostPrice: this.getFieldValue('costPrice'),
      RetailPrice: this.getFieldValue('retailPrice'),
      SalesmanPrice: this.getFieldValue('salesmanPrice'),
      NettPrice: this.getFieldValue('netPrice'),
      Fitted: this.getFieldValue('fitted') === 'Local' ? 'L' : 'F',
      Description: this.getFieldValue('description'),
      Classification: this.getFieldValue('classification'),
      Vendor: this.getFieldValue('vendorName'),
      Status: this.getFieldValue('status') === 'Active' ? '1' : '0'
    };

    this.accessoryService.saveDetails(updatedAccessoryData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Accessory updated successfully');
        this.router.navigate(['../../accessory'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error updating accessory:", error);
        this.notifier.notify('error', 'Error updating accessory');
      }
    });
  }

  private getFieldValue(id: string): any {
    const field = this.formFields.find(f => f.id === id);
    return field?.value || '';
  }

  onCancel(): void {
    this.router.navigate(['../../accessory'], { relativeTo: this.route });
  }
}
