import { Component, OnInit } from "@angular/core";
import {
  faSave,
  faBroom,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-car-registration",
  templateUrl: "./car-registration.component.html",
  styleUrls: ["./car-registration.component.css"],
})
export class CarRegistrationComponent implements OnInit {
  faSave = faSave;
  faBroom = faBroom;
  faPrint = faPrint;

  carRegistration!: FormField[];
  vinSeriesChecking!: FormField[];
  equipmentItems: string[] = Array(12).fill("");
  constructor() {}

  updateFields(event: any) {
    switch (event.name) {
      case "carRegistration":
        this.carRegistration = event.fields;
        break;
      case "vinSeriesChecking":
        this.vinSeriesChecking = event.fields;
        break;
    }
  }

  trackByIndex(index: number, item: string): number {
    return index;
  }

  ngOnInit() {
    this.carRegistration = [
      {
        type: "text",
        id: "contractNumber",
        label: "Contract number",
        required: true,
      },
      {
        type: "text",
        id: "COENumber",
        label: "COE number",
      },
      {
        type: "text",
        id: "invoiceDate",
        label: "Invoice date",
        lock: true,
      },
      {
        type: "formCheckbox",
        id: "numberRetention",
        label: "Tick if have number retention",
      },
      {
        type: "text",
        id: "registrationNumber",
        label: "Registration number",
        required: true,
      },
      {
        type: "text",
        id: "UINumber",
        label: "UI number",
        required: true,
      },
      {
        type: "date",
        id: "registrationDate",
        label: "Registration date (dd/mm/yyyy)",
      },
      {
        type: "text",
        id: "vinNumber",
        label: "Vin number",
        lock: true,
      },
      {
        type: "text",
        id: "model",
        label: "Model",
        lock: true,
      },
      {
        type: "text",
        id: "chasisNumber",
        label: "Chasis number",
      },
      {
        type: "text",
        id: "engineNumber",
        label: "Engine number",
      },
      {
        type: "text",
        id: "buyersNRIC",
        label: "Buyer's NRIC",
        lock: true,
      },
      {
        type: "text",
        id: "salesConsultantName",
        label: "Sales consultant's name",
        lock: true,
      },
      {
        type: "text",
        id: "buyersName",
        label: "Buyer's name",
      },
      {
        type: "text",
        id: "financeCompany",
        label: "Finance company",
        lock: true,
      },
    ];
    this.vinSeriesChecking = [
      {
        type: "search",
        id: "vinSeries",
        label: "Vin series",
      },
    ];
  }
}
