import { Component, OnInit } from '@angular/core';
import {
  faPlus,
  faTrash,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import { ColoursByModelService } from 'src/app/services/vehicle/colours-by-model.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: 'app-colours-by-model-list',
  templateUrl: './colours-by-model-list.component.html',
  styleUrls: ['./colours-by-model-list.component.css']
})
export class ColoursByModelListComponent implements OnInit {

  searchInput: string = "";
  filteredColoursByModel: any[] = [];
  coloursByModel: any[] = [];

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "MODEL",
      type: "link",
    },
    {
      title: "MODEL CODE",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "OCC NUMBER",
      type: "data",
    },
    {
      title: "MODEL GROUP",
      type: "data",
    },
    {
      title: "EXTERIOR COLOUR CODE",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "EXTERIOR COLOUR DESCRIPTION",
      type: "data",
    },
    {
      title: "EXTERIOR TYPE",
      type: "data",
    },
    {
      title: "INTERIOR COLOUR CODE",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "INTERIOR COLOUR DESCRIPTION",
      type: "data",
    },
    {
      title: "STATUS",
      type: "data",
    },
    {
      title: "ID",
      type: "hidden",
      id: "colourByModelMasterId"
    }
  ];

  constructor(
    private coloursByModelService: ColoursByModelService,
    private router: Router,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getAllColoursByModel();
  }

  getAllColoursByModel(): void {
    this.coloursByModelService.getAllColoursByModel().subscribe(
      (response) => {
        console.log('API Response:', response);

        if (response && response.body) {
          this.coloursByModel = response.body.map((item: any) => ({
            checked: false,
            model: item.ModelShortDesc || '',
            modelCode: item.ModelCode || '',
            occNo: item.OccNo || '',
            modelGroup: item.ModelGroup || '',
            extColourCode: item.ExtColourCode || '',
            extColourDescription: item.ExtColourDescription || '',
            extType: item.ExtType || '',
            intColourCode: item.IntColourCode || '',
            intColourDescription: item.IntColourDescription || '',
            status: item.Status === "True" ? "Active" : "Inactive",
            colourByModelMasterId: item.ColourByModelMasterId || ''
          }));
          this.filteredColoursByModel = [...this.coloursByModel];
        } else {
          console.error("Error: La estructura de la respuesta no contiene los datos esperados.");
        }
      },
      (error) => {
        console.error("Error al obtener los colores por modelo", error);
        this.notifier.notify('error', 'Error al obtener los colores por modelo');
      }
    );
  }

  onSearch(searchValue: string): void {
    this.filteredColoursByModel = this.coloursByModel.filter(item =>
      item.model.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.modelCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.occNo.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.modelGroup.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.extColourCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.extColourDescription.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.extType.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.intColourCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.intColourDescription.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../colours-by-model", emptyGuid], {
      relativeTo: this.route,
    });
  }

  deleteSelectedEntries(): void {
    const selectedItems = this.filteredColoursByModel
      .filter(item => item.checked)
      .map(item => ({
        ColourByModelMasterId: item.colourByModelMasterId || '',
        ModelCode: item.modelCode || '',
        ModelShortDesc: item.model || '',
        ModelGroup: item.modelGroup || '',
        ExtColourCode: item.extColourCode || '',
        ExtColourDescription: item.extColourDescription || '',
        ExtType: item.extType || '',
        IntColourCode: item.intColourCode || '',
        IntColourDescription: item.intColourDescription || '',
        OccNo: item.occNo || '',
        Status: item.status === "Active" ? "True" : "False",
        IsChecked: item.checked
      }));
  
    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one item to delete.');
      return;
    }
  
    this.coloursByModelService.delete(selectedItems).subscribe({
      next: () => {
        this.notifier.notify('success', 'Selected items deleted successfully');
        this.filteredColoursByModel = this.filteredColoursByModel.filter(item => !item.checked);
      },
      error: (error) => {
        console.error("Error deleting colours by model:", error);
        this.notifier.notify('error', 'Error deleting selected items.');
      }
    });
  }
  

  openEntry(value: any): void {
    if (value && value.colourByModelMasterId) {
      this.router.navigate(["../colours-by-model", value.colourByModelMasterId], {
        relativeTo: this.route,
      });
    } else {
      console.error("Error: Id not found");
    }
  }
}
