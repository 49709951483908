<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      Kaps Settings
    </h1>
    <app-navpath></app-navpath>
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="settingsInformation"
      dataName="formFields"
      (updateFields_child)="updateFields($event.fields)"
    ></app-data-form>
    <div class="w-full border-t pt-4">
      <button class="shorter-btn save-btn" (click)="uploadForm()">
        <fa-icon [icon]="faEdit" class="mr-1" />Update
      </button>
    </div>
  </div>
</section>
