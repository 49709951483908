import { Component } from "@angular/core";
import {
  faPlus,
  faMinus,
  faCircle,
  faPencil,
  faTrashCan,
  faTrash,
  faFloppyDisk,
  faCancel,
  faMagnifyingGlass,
  faSearch,
  faTriangleExclamation,
  faEraser,
  faPrint,
  faDownload,
  faCar,
} from "@fortawesome/free-solid-svg-icons";

import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-finance-vehicle-receipt",
  templateUrl: "./finance-vehicle-receipt.component.html",
  styleUrls: ["./finance-vehicle-receipt.component.css"],
})
export class FinanceVehicleReceiptComponent {
  titleSelect: string | undefined;
  coeType: string | undefined;
  modeContac: string | undefined;
  carType: string | undefined;
  discount: string | undefined;
  paymentType: string | undefined;
  loanPeriod: string | undefined;
  dealrName: string | undefined;
  insuranceCompany: string | undefined;
  titles: string[] = ["MR", "MRS", "MISS", "M/S", "MMDM", "DR", "MS"];
  coes: string[] = ["Bidding", "Own COE", "Immediate E"];
  cartypes: string[] = [
    "Standard",
    "Off Peak Car(OPC)",
    "Compressed Natural Gas (CNG)",
    "CNG OPC",
    "OPC",
  ];
  modscontac: string[] = [
    "REFERRAL",
    "REPEAT",
    "WALK-IN",
    "POSTALE",
    "ONLINE SALES",
  ];
  discounts: string[] = ["500", "700", "1000"];
  paymenttypes: string[] = [
    "AIG OTHERS",
    "AIG IN HOUSE",
    "AXA INSURANCE OTHERS",
    "AXA INSURANCE IN-HOUSE",
    "LIBERTY INSURANCE IN-HOUSE",
    "MSIG INSURANCE IN-HOUSE",
    "NTUC INCOME OTHERS",
    "NTUC INCOME IN-HOUSE",
    "OWN INSURANCE",
  ];
  loanperiods: string[] = [
    "12",
    "24",
    "36",
    "48",
    "60",
    "72",
    "84",
    "96",
    "108",
    "116",
    "118",
    "120",
  ];
  dealernames: string[] = [
    "No Trade In",
    "BOEKI AUTO & MARINE PTE LTD",
    "KMPL USED CAR DIVISION",
  ];

  faPlus = faPlus;
  faMinus = faMinus;
  faCircle = faCircle;
  faPencil = faPencil;
  faTrashCan = faTrashCan;
  faFloppyDisk = faFloppyDisk;
  faCancel = faCancel;
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faSearch = faSearch;
  faTriangleExclamation = faTriangleExclamation;
  faEraser = faEraser;
  faPrint = faPrint;
  faDownload = faDownload;
  faCar = faCar;

  textInput: string = "";
  required: boolean = true;
  searchText = "";
  searchControl = new FormControl("");
  items = ["Element1", "Element2", "Element3", "Element4"];
  filteredItems: string[] = [];
  showList = false;

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe((value) => {
        this.filterItems(value);
        if (value) {
          this.showList = true;
        }
      });
  }

  filterItems(searchText: string | null): void {
    if (!searchText) {
      this.filteredItems = [];
      this.showList = false;
    } else {
      this.filteredItems = this.items.filter((item) =>
        item.toLowerCase().includes(searchText.toLowerCase()),
      );
      this.showList = this.filteredItems.length > 0;
    }
  }

  selectItem(item: string): void {
    this.searchControl.setValue(item);
    this.filteredItems = [];
    this.showList = false;
  }

  searchBooking: FormField[] = [
    {
      type: "select",
      id: "FinanceCompany",
      label: "Finance Company",
      options: [
        {
          display: "No Finance",
          value: "No Finance",
        },
        {
          display: "CITIBANK SINGAPORE LTD",
          value: "CB_MA",
        },
        {
          display: "CITIBANK SINGAPORE LTD",
          value: "CB_OC",
        },
        {
          display: "DBS BANK LTD",
          value: "DBS_OC",
        },
        {
          display: "DBS BANK LTD",
          value: "DBSCA",
        },
        {
          display: "DBS BANK LTD",
          value: "DBS_CA2",
        },
        {
          display: "DBS BANK LTD",
          value: "DBSMA",
        },
        {
          display: "DBS BANK LTD",
          value: "DBS_CA1",
        },
        {
          display: "GENIE FINANCIAL SERVICES PTE LTD",
          value: "GFSPL",
        },
        {
          display: "GOLDBELL FINANCIAL SERVICES PTE LTD",
          value: "GFS",
        },
        {
          display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
          value: "HICA_NF",
        },
        {
          display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
          value: "HICA",
        },
        {
          display: "HL BANK",
          value: "HLB_OC",
        },
        {
          display: "HONG LEONG FINANCE LTD",
          value: "HL",
        },
        {
          display: "HONG LEONG FINANCE LTD",
          value: "HL_OC",
        },
      ],
    },
    {
      type: "search",
      id: "searchInvoiceNumber",
      label: "Invoice Number",
    },
    {
      type: "search",
      id: "searchContractNumber",
      label: "Contract Number",
    },
    {
      type: "search",
      id: "searchRegisterNumber",
      label: "Registration Number",
    },
    {
      type: "search",
      id: "searchBuyerName",
      label: "Buyer Name",
    },
    {
      type: "print",
      id: "searchReceiptNumber",
      label: "Receipt Number",
    },
  ];

  buyersDetails!: FormField[];
  detailsVehicle!: FormField[];
  detailsCashDiscount!: FormField[];
  detailsDeposit!: FormField[];
  detailsOtherFees!: FormField[];
  detailsInsurance!: FormField[];
  detailsHireApplication!: FormField[];
  detailsTradeInVehicle!: FormField[];
  komocosAccount_Promotion!: FormField[];
  komocosAccount_Accessories!: FormField[];
  buyersAccount_NonAccessories!: FormField[];
  buyersAccount_Accessories!: FormField[];
  consultantAccount_NonAccessories!: FormField[];
  consultantAccount_Accessories!: FormField[];
  additionalRemarks!: FormField[];

  updateFields(event: any): void {
    switch (event.name) {
      case "searchBooking":
        this.searchBooking = event.fields;
        break;
      case "buyersDetails":
        this.buyersDetails = event.fields;
        break;
      case "detailsVehicle":
        this.detailsVehicle = event.fields;
        break;
      case "detailsCashDiscount":
        this.detailsCashDiscount = event.fields;
        break;
      case "detailsDeposit":
        this.detailsDeposit = event.fields;
        let local_PaymentType = this.detailsDeposit.find(
          (detail) => detail.id == "paymentType",
        )?.value;
        for (let i = 3; i <= 8; i++) {
          this.detailsDeposit[i].show = false;
        }
        switch (local_PaymentType) {
          case "CHEQUE":
            this.detailsDeposit[3].show = true;
            this.detailsDeposit[4].show = true;
            break;
          case "NETS":
          case "VISA/MASTER":
          case "AMEX":
            this.detailsDeposit[5].show = true;
            this.detailsDeposit[6].show = true;
            this.detailsDeposit[7].show = true;
            break;
          case "OTHERS":
            this.detailsDeposit[8].show = true;
            break;
        }
        break;
      case "detailsOtherFees":
        this.detailsOtherFees = event.fields;
        break;
      case "detailsInsurance":
        this.detailsInsurance = event.fields;
        break;
      case "detailsHireApplication":
        this.detailsHireApplication = event.fields;
        break;
      case "detailsTradeInVehicle":
        this.detailsTradeInVehicle = event.fields;
        let local_DetailsTradeInVehicle =
          this.detailsTradeInVehicle.find(
            (detail) => detail.id == "dealerName",
          )?.value;
        for (let i = 1; i <= 6; i++) {
          this.detailsTradeInVehicle[i].show = false;
        }
        switch (local_DetailsTradeInVehicle) {
          case "BOEKI AUTO & MARINE PTE LTD":
          case "KMPL USED CAR DIVISION":
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = true;
            }
            break;
          default:
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = false;
            }
        }
        break;
      case "komocosAccount_Promotion":
        this.komocosAccount_Promotion = event.fields;
        break;
      case "komocosAccount_Accessories":
        this.komocosAccount_Accessories = event.fields;
        break;
      case "buyersAccount_NonAccessories":
        this.buyersAccount_NonAccessories = event.fields;
        break;
      case "buyersAccount_Accessories":
        this.buyersAccount_Accessories = event.fields;
        break;
      case "consultantAccount_NonAccessories":
        this.consultantAccount_NonAccessories = event.fields;
        break;
      case "consultantAccount_Accessories":
        this.consultantAccount_Accessories = event.fields;
        break;
      case "additionalRemarks":
        this.additionalRemarks = event.fields;
        break;
    }
  }

  ngOnInit(): void {
    this.buyersDetails = [
      {
        type: "text",
        id: "previousFinanceReceiptNumber",
        label: "Previous Finance Receipt Number",
      },
      {
        type: "date",
        id: "receiptDate",
        label: "Receipt Date (dd/mm/yyyy)",
      },
      {
        type: "text",
        id: "amountReceivedfromFinance",
        label: "Amount received from finance",
      },
      {
        type: "text",
        id: "words",
        label: "Words",
      },
      { type: "text", id: "paymentMode", label: "Payment Mode" },
      {
        type: "text",
        id: "chequeNumber",
        label: "Cheque Number",
      },
      {
        type: "text",
        id: "totalLoanAmountPayable",
        label: "Total Loan Amount Payable",
      },
      {
        type: "text",
        id: "balance",
        label: "Balance",
      },
    ];
  }
}
