import {Component, EventEmitter, HostListener, OnInit, Output} from "@angular/core";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {faBell} from "@fortawesome/free-regular-svg-icons"
import {GENERIC_PROFILE_PIC, STORAGE_KEYS} from "../../constants";
import {UserInfoService} from "../../services/user-info.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {

  constructor(private userInfoService: UserInfoService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.userInfoService.userInfo$.subscribe(info => {
      this.userInfo = info;
      const profilePic = this.userInfo[STORAGE_KEYS.USER_INFO['PROFILE_PIC'].sessionKey] ?? GENERIC_PROFILE_PIC;
      this.profilePic = this.sanitizer.bypassSecurityTrustUrl(profilePic);
    });
  }

  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.showNotifications = false;
    this.showUserOptions = false;
  }

  @Output() clickMenu = new EventEmitter();

  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faBell = faBell;

  showNotifications: boolean = false;
  showUserOptions: boolean = false;
  showMenu: boolean = false;

  userInfo: { [key: string]: string | null } = {};

  profilePic: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(GENERIC_PROFILE_PIC);

  switchMenu() {
    this.showMenu = !this.showMenu;
    this.clickMenu.emit('data');
  }

  switchMid() {
    let midBar = document.getElementById("midBar");
    if (midBar?.classList.contains("on-menu-icon")) {
      midBar.classList.remove("on-menu-icon");
    } else {
      midBar?.classList.add("on-menu-icon");
    }
  }

  protected readonly STORAGE_KEYS = STORAGE_KEYS;
}
