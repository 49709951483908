<div class="input-container">
  <input
    type="text"
    class="search-input"
    placeholder="Search..."
    [ngModel]="searchValue"
    (ngModelChange)="childSearchInput($event)"
  />
  <div class="search-btn">
    <fa-icon [icon]="faSearch" class="flex-grow" />
  </div>
</div>
