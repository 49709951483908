import { Component, OnInit } from "@angular/core";
import { faUpload, faBroom } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-coe-bidding-results",
  templateUrl: "./coe-bidding-results.component.html",
  styleUrls: ["./coe-bidding-results.component.css"],
})
export class CoeBiddingResultsComponent implements OnInit {
  faUpload = faUpload;
  faBroom = faBroom;

  uploadCOE!: FormField[];
  selectCOE!: FormField[];

  updateFields(event: any) {
    switch (event.name) {
      case "uploadCOE":
        this.uploadCOE = event.fields;
        break;
      case "selectCOE":
        this.selectCOE = event.fields;
        break;
    }
  }

  ngOnInit() {
    this.uploadCOE = [
      {
        type: "file",
        id: "fileLocation",
        label: "File location",
      },
    ];
    this.selectCOE = [
      {
        type: "select",
        id: "selectType",
        label: "Select type",
      },
    ];
  }
}
