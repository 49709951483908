import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { formatDate } from "@angular/common";
import { NotifierService } from "angular-notifier";
import { ProspectReportService } from "src/app/services/sales-reports/prospect-report.service";

@Component({
  selector: "app-prospect-report",
  templateUrl: "./prospect-report.component.html",
  styleUrls: ["./prospect-report.component.css"],
})
export class ProspectReportComponent implements OnInit {
  faPrint = faPrint;

  FormFields: FormField[] = [
    {
      type: "date",
      id: "FromDate",
      label: "From Register Date (dd/mm/yyyy)",
      required: true,
    },
    {
      type: "date",
      id: "ToDate",
      label: "To Register Date (dd/mm/yyyy)",
      required: true,
    },
    {
      type: "select",
      id: "Format",
      label: "Select format",
      options: [
        { display: "PDF", value: "pdf" },
        { display: "EXCEL", value: "excel" },
        { display: "BOTH", value: "both" },
      ],
      value: "pdf",
    },
  ];

  constructor(
    private notifier: NotifierService,
    private prospectReportService: ProspectReportService,
  ) {}

  ngOnInit() {
    const now = new Date();

    this.getField("FromDate").value = formatDate(
      now,
      "yyyy-MM-dd",
      "en-US",
    );
    this.getField("ToDate").value = formatDate(
      now,
      "yyyy-MM-dd",
      "en-US",
    );
    this.update();
  }

  update(): void {}

  print(): void {
    const FromDate = this.getField("FromDate").value;
    const ToDate = this.getField("ToDate").value;
    const Format = this.getField("Format").value;

    if (!FromDate || !ToDate) {
      this.notifier.notify(
        "error",
        "Please provide valid date values",
      );
      return;
    }

    const formattedFromDate = formatDate(
      FromDate,
      "dd/MM/yyyy",
      "en-US",
    );
    const formattedToDate = formatDate(ToDate, "dd/MM/yyyy", "en-US");

    this.prospectReportService
      .ProspectReport(formattedFromDate, formattedToDate)
      .subscribe({
        next: ({ body }) => {
          if (["pdf", "both"].includes(Format)) {
            window.open(body.Name, "_blank");
          }
          if (["excel", "both"].includes(Format)) {
            window.open(body.Value, "_blank");
          }
        },
        error: console.error,
      });
  }

  getField(id: string): FormField {
    return (
      this.FormFields.find((f) => f.id === id) || {
        id: "",
        type: "text",
        label: "",
        value: null,
      }
    );
  }
}
