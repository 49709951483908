<div class="input-container">
  <input
    type="number"
    (input)="updateFormControl($event)"
    [formControl]="updateControl"
  />
  <button
    class="update-btn"
    (click)="update($event)"
    [disabled]="!dataInput && !admitsUndefined"
    [ngClass]="{
      'disabled': !dataInput && !admitsUndefined
    }"
  >
    <fa-icon [icon]="faPencil" />
    Update
  </button>
</div>
