<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Price List Master</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex justify-between">
    <div class="flex gap-1">
      <button class="shorter-btn add-btn" (click)="addEntry()">
        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
      </button>
      <button
        class="shorter-btn delete-btn"
        (click)="openModal('remove', true)"
      >
        <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Delete
      </button>
      <button class="shorter-btn print-btn" (click)="print()">
        <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Print
      </button>
      <app-search-input
        (dataOutput)="onSearch($event)"
        [isTable]="true"
      ></app-search-input>
    </div>
    <div class="flex gap-1">
      <button
        class="shorter-btn misc-btn"
        (click)="openModal('passenger', true)"
      >
        <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Passenger
        Remarks
      </button>
      <button
        class="shorter-btn misc-btn"
        (click)="openModal('commercial', true)"
      >
        <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Commercial
        Remarks
      </button>
    </div>
  </div>
  <div class="content-container">
    <h2 class="sub-heading w-full border-b">List Prices</h2>
    <app-data-table
      [tableHeaders]="listPricesHeaders"
      [tableEntries]="filteredListPrices"
      (open)="openEntry($event)"
      [pagination]="false"
    ></app-data-table>
  </div>
  <div class="content-container">
    <h2 class="sub-heading w-full border-b">Sales Prices</h2>
    <app-data-table
      [tableHeaders]="salesPricesHeaders"
      [tableEntries]="filteredSalesPrices"
      [pagination]="false"
    ></app-data-table>
  </div>
</section>
<div class="bg-modal" *ngIf="showModal">
  <div class="container-confirmation">
    <div class="flex flex-col justify-between">
      <div for="" class="mb-8 flex flex-col gap-4">
        <h2 class="text-lg">Confirm Action</h2>
        <p>Are you sure you want to delete record(s)?</p>
      </div>
      <div class="ml-auto mr-0 mt-4 flex w-fit gap-4">
        <button class="shorter-btn save-btn" (click)="removeEntry()">
          Yes
        </button>
        <button
          class="shorter-btn delete-btn"
          (click)="openModal('remove', false)"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="bg-modal" *ngIf="showPassenger">
  <div class="container-remarks">
    <h2 class="sub-heading w-full border-b">
      Update Passenger Remarks
    </h2>
    <app-data-form [dataFields]="passengerRemarksForm" />
    <div class="flex w-full justify-end gap-2 border-t pt-4">
      <button
        class="shorter-btn save-btn"
        (click)="saveRemarks('passenger')"
      >
        <fa-icon [icon]="faEdit" class="mr-1"></fa-icon>Update
      </button>
      <button
        class="shorter-btn clear-btn"
        (click)="openModal('passenger', false)"
      >
        <fa-icon [icon]="faCancel" class="mr-1"></fa-icon>Cancel
      </button>
    </div>
  </div>
</div>
<div class="bg-modal" *ngIf="showCommercial">
  <div class="container-remarks">
    <h2 class="sub-heading w-full border-b">
      Update Commercial Remarks
    </h2>
    <app-data-form [dataFields]="commercialRemarksForm" />
    <div class="flex w-full justify-end gap-2 border-t pt-4">
      <button
        class="shorter-btn save-btn"
        (click)="saveRemarks('commercial')"
      >
        <fa-icon [icon]="faEdit" class="mr-1"></fa-icon>Update
      </button>
      <button
        class="shorter-btn clear-btn"
        (click)="openModal('commercial', false)"
      >
        <fa-icon [icon]="faCancel" class="mr-1"></fa-icon>Cancel
      </button>
    </div>
  </div>
</div>
