import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencil, faBan, faPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';
import { HirePurchaseService } from 'src/app/services/sales/hire-purchase.service';
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid, isEmptyGuid } from '../../../../services/util.service';

@Component({
  selector: 'app-hire-purchase-detail',
  templateUrl: './hire-purchase-detail.component.html',
  styleUrls: ['./hire-purchase-detail.component.css']
})
export class HirePurchaseDetailComponent implements OnInit {
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faUsers = faUsers;

  mode: string = "Add";
  invAccountId: string = getEmptyGuid();

  formFields: FormField[] = [
    { type: 'text', id: 'AcCode', label: 'Finance Company', value: '', lock: false },
    { type: 'text', id: 'Address1', label: 'Address1', value: '' },
    { type: 'text', id: 'Address2', label: 'Address2', value: '' },
    { type: 'text', id: 'Address3', label: 'Address3', value: '' },
    { type: 'text', id: 'PostalCode', label: 'Postal Code', value: '' },
    { type: 'text', id: 'ContactNumber', label: 'Contact Number 1', value: '' },
    { type: 'text', id: 'Telephone2', label: 'Contact Number 2', value: '' },
    { type: 'text', id: 'Telephone3', label: 'Contact Number 3', value: '' },
    { type: 'text', id: 'Acname', label: 'Description', value: '' },
    { type: 'text', id: 'Country', label: 'Country', value: '' },
    { type: 'text', id: 'ContactPerson', label: 'Contact Person', value: '' },
    { type: 'switch', id: 'DisplayStatusInBooking', label: 'Display in Booking Screen', value: false },
    { type: 'switch', id: 'DisplayStatus', label: 'Display', value: false }
  ];
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private hirePurchaseService: HirePurchaseService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.invAccountId = params['id'];
    
      if (!isEmptyGuid(this.invAccountId)) {
        this.mode = 'Edit';
        this.loadFinanceDetail();
      }
    });
  }
  
  private setFinanceCompanyLock(isLocked: boolean): void {
    const financeCompanyField = this.formFields.find(field => field.id === 'AcCode');
    if (financeCompanyField) {
      financeCompanyField.lock = isLocked;
    }
  }
  
  private loadFinanceDetail(): void {
    this.hirePurchaseService.getDetail(this.invAccountId).subscribe({
      next: (response) => {
        const data = response?.body;
        if (data) {
          this.formFields = [
            { type: 'text', id: 'AcCode', label: 'Finance Company', value: data.AcCode, lock: true }, 
            { type: 'text', id: 'Acname', label: 'Description', value: data.Acname },
            { type: 'text', id: 'Address1', label: 'Address1', value: data.Address1 },
            { type: 'text', id: 'Address2', label: 'Address2', value: data.Address2 },
            { type: 'text', id: 'Address3', label: 'Address3', value: data.Address3 },
            { type: 'text', id: 'Country', label: 'Country', value: data.Country },
            { type: 'text', id: 'PostalCode', label: 'Postal Code', value: data.PostalCode },
            { type: 'text', id: 'ContactPerson', label: 'Contact Person', value: data.ContactPerson },
            { type: 'text', id: 'ContactNumber', label: 'Contact Number 1', value: data.ContactNumber },
            { type: 'text', id: 'Telephone2', label: 'Contact Number 2', value: data.Telephone2 },
            { type: 'text', id: 'Telephone3', label: 'Contact Number 3', value: data.Telephone3 },
            { type: 'switch', id: 'DisplayStatus', label: 'Display', value: data.DisplayStatus },
            { type: 'switch', id: 'DisplayStatusInBooking', label: 'Display in Booking Screen', value: data.DisplayStatusInBooking },
          ];
        } else {
          console.error("Error: No data found in response.");
          this.notifier.notify('error', 'No data found in response');
        }
      },
      error: (error) => {
        console.error("Error loading finance detail:", error);
        this.notifier.notify('error', 'Error loading finance detail');
      }
    });
  }
  
  onSave(): void {
    const formData = this.getFormValues();
    
    formData["AttentionTitle"] = formData["AttentionTitle"] || ""; 
    formData["LoanRate"] = formData["LoanRate"] || ""; 
    
    if (this.mode === 'Add') {
      formData["InvAccountId"] = getEmptyGuid(); 
      formData["IsDeleted"] = false; 
      this.addFinance(formData);
    } else if (this.mode === 'Edit') {
      formData["InvAccountId"] = this.invAccountId; 
      this.updateFinance(formData);
    }
  }
  
  private addFinance(data: any): void {
    this.hirePurchaseService.saveDetails({ data }).subscribe({
      next: () => {
        this.notifier.notify('success', 'Finance company added successfully');
        this.router.navigate(['../../hire-purchase'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error adding finance company:", error);
        this.notifier.notify('error', 'Error adding finance company');
      }
    });
  }
  
 private updateFinance(data: any): void {
    data["IsDeleted"] = false;
    this.hirePurchaseService.saveDetails({ data }).subscribe({
      next: () => {
        this.notifier.notify('success', 'Finance company updated successfully');
        this.router.navigate(['../../hire-purchase'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error updating finance company:", error);
        this.notifier.notify('error', 'Error updating finance company');
      }
    });
  }
  

  private getFormValues(): { [key: string]: any } {
    return this.formFields.reduce((formValues: { [key: string]: any }, field: FormField) => {
      formValues[field.id] = field.value;
      return formValues;
    }, {});
  }
  

  onCancel(): void {
    this.router.navigate(['../../hire-purchase'], { relativeTo: this.route });
  }
}
