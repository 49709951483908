<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      <fa-icon [icon]="faCar" class="mr-1" />PARF Transfer Date
    </h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">PARF Transfer Date</h2>
      <app-data-form
        [dataFields]="searchBooking"
        [dataName]="'searchBooking'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
        <h2 class="sub-heading w-full border-b">For Reference</h2>
        <app-data-form
          [dataFields]="buyersDetails"
          [dataName]="'buyersDetails'"
          (updateFields_child)="updateFields($event)"
        />
      </section>
      <div class="mt-4 flex w-fit gap-1">
        <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faFloppyDisk" class="mr-1" />Update
          </button>
        <button class="shorter-btn gray-btn">
          <fa-icon [icon]="faEraser" class="mr-1" />Clear
        </button>
      </div>
  </div>
</section>
