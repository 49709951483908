import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faEdit, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { CustomerSearchService } from 'src/app/services/customer/customer-search.service';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search-detail.component.html',
  styleUrls: ['./customer-search-detail.component.css']
})
export class CustomerSearchDetailComponent implements OnInit {

  // ICONS VARIABLES
  faEdit = faEdit;
  faPrint = faPrint;

  // OTHER VARIABLES
  activeTeams: any[] = [];
  activeUsers: any[] = [];
  fullModelsData: any[] = [];
  allUsersData: any[] = [];
  buyersDetails: FormField[] = [];
  contractData: any;

  constructor(
    private customerSearchService: CustomerSearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifier: NotifierService,
  ) {}

  ngOnInit(): void {
    // Retrieve the contract data from the service
    this.contractData = this.customerSearchService.getContractData();

    if (this.contractData) {
      console.log("Contract data received:", this.contractData);
      this.setUpFormFields(); // Call the function to initialize the form fields
    } else {
      console.log("No contract data received.");
    }

    this.setModelList();
    this.getActiveUsers();
  }

  private setModelList(): void {
    this.customerSearchService.getModelDrpList().subscribe(
      (response) => {
        const models = response?.body;
        if (!models || !Array.isArray(models)) {
          console.error("No data found in set model list response:", response);
          return;
        }
        this.fullModelsData = models.map((model: any) => ({
          Type: model.__type,
          Value: model.Value,
          Name: model.Name,
          Description: model.Description,
          Status: model.Status
        }));
        this.setUpFormFields();
      },
      (error) => {
        console.error("Error fetching set model list data:", error);
      }
    );
  }

  private getActiveUsers(): void {
    this.customerSearchService.getAllActiveUsers().subscribe(
      (response) => {
        const users = response?.body;
        if (!users || !Array.isArray(users)) {
          console.error("No data found in get active users response:", response);
          return;
        }
        this.allUsersData = users.map((user: any) => ({
          UserId: user.user_id,
          UserName: user.user_name,
          DisplayName: user.display_name
        }));
      },
      (error) => {
        console.error("Error fetching get active users data:", error);
      }
    );
  }

  onPrint(): void {
    const contractNo = this.contractData.contractNo;

    if (!contractNo) {
      this.notifier.notify('error', 'No contract number found.');
      return;
    }

    this.customerSearchService.print(contractNo).subscribe(
      (response) => {
        console.log('Print response:', response);
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);

        this.notifier.notify('success', `Print initiated for contract: ${contractNo}`);
      },
      (error) => {
        console.error(`Error printing contract ${contractNo}:`, error);
        this.notifier.notify('error', `Failed to print contract ${contractNo}`);
      }
    );
  }

  // Convert dd/mm/yyyy to yyyy-mm-dd
  private convertDateToYYYYMMDD(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  private setUpFormFields(): void {
    if (!this.contractData) {
      return;
    }

    this.buyersDetails = [
      {
        type: "text",
        id: "nricNo",
        label: "NRIC No/RCB No/Passport No",
        required: true,
        value: this.contractData.icNo
      },
      {
        type: "text",
        id: "name",
        label: "Name as in NRIC/Passport",
        required: true,
        value: this.contractData.name
      },
      {
        type: "select",
        id: "title",
        label: "Title",
        required: true,
        value: this.contractData.title,
        options: [
          { display: "MR", value: "MR" },
          { display: "MRS", value: "MRS" },
          { display: "MISS", value: "MISS" },
          { display: "M/S", value: "M/S" },
          { display: "MDM", value: "MDM" },
          { display: "DR", value: "DR" },
          { display: "MS", value: "MS" },
        ],
      },
      {
        type: "text",
        id: "block",
        label: "Block",
        required: true,
        value: this.contractData.addressBlock
      },
      {
        type: "text",
        id: "road",
        label: "Road",
        required: true,
        value: this.contractData.addressRoad
      },
      { 
        type: "text", 
        id: "unit", 
        label: "Unit",
        value: this.contractData.addressUnit
      },
      {
        type: "text",
        id: "postalCode",
        label: "Postal Code",
        required: true,
        value: this.contractData.postalCode
      },
      {
        type: "date",
        id: "dateBirth",
        label: "Date of birth (dd/mm/yyyy)",
        required: true,
        value: this.convertDateToYYYYMMDD(this.contractData.dob) // Convert to yyyy-mm-dd
      },
      {
        type: "text",
        id: "mobileNumber",
        label: "Mobile Number",
        required: true,
        value: this.contractData.hp
      },
      { 
        type: "text", 
        id: "telNumber", 
        label: "Home tel number",
        value: this.contractData.residendeNo
      },
      {
        type: "text",
        id: "officeNumber",
        label: "Office Tel Number",
        value: this.contractData.officeNo
      },
      {
        type: "text",
        id: "occupation",
        label: "Occupation",
        required: true,
        value: this.contractData.occupation
      },
      {
        type: "text",
        id: "email",
        label: "Email",
        required: true,
        value: this.contractData.email
      },
      {
        type: "text",
        id: "model",
        label: "Model",
        value: this.contractData.model
      },
      {
        type: "text",
        id: "carRegistrationNumber",
        label: "Car Registration Number",
        value: this.contractData.registerNumber
      },
      {
        type: "date",
        id: "carRegistrationDate",
        label: "Car Registration Date (dd/mm/yyyy)",
        required: true,
        value: this.convertDateToYYYYMMDD(this.contractData.registrationDate) // Convert to yyyy-mm-dd
      },
      {
        type: "select",
        id: "salesConsultant",
        label: "Sales Consultant",
        value: this.contractData.salemansID,
        options: this.allUsersData.map(user => ({
          display: user.DisplayName,
          value: user.UserId
        }))
      }
    ];
  }

  onUpdate(): void {

    const updatedContractData = {
      AddressBlock: this.buyersDetails.find(field => field.id === 'block')?.value || '',
      AddressRoad: this.buyersDetails.find(field => field.id === 'road')?.value || '',
      AddressUnit: this.buyersDetails.find(field => field.id === 'unit')?.value || '',
      ContractNo: this.contractData.contractNo,  // Aquí usamos el contrato original
      CurrentCarModel: this.contractData.currentCarModel || null,
      CurrentRegDate: this.contractData.currentRegDate || '',
      Dob: this.convertDateToDDMMYYYY(this.buyersDetails.find(field => field.id === 'dateBirth')?.value || ''),
      Email: this.buyersDetails.find(field => field.id === 'email')?.value || '',
      Hp: this.buyersDetails.find(field => field.id === 'mobileNumber')?.value || '',
      IcNo: this.buyersDetails.find(field => field.id === 'nricNo')?.value || '',
      Model: this.buyersDetails.find(field => field.id === 'model')?.value || '',
      ModelInterested: this.contractData.modelInterested || '', // Puedes ajustar esto según sea necesario
      ModelInterestedOthers: this.contractData.modelInterestedOthers || '',
      ModelShortDesc: this.contractData.modelShortDesc || '',
      Name: this.buyersDetails.find(field => field.id === 'name')?.value || '',
      Occupation: this.buyersDetails.find(field => field.id === 'occupation')?.value || '',
      OfficeNo: this.buyersDetails.find(field => field.id === 'officeNumber')?.value || '',
      PostalCode: this.buyersDetails.find(field => field.id === 'postalCode')?.value || '',
      REGISTERNO: this.buyersDetails.find(field => field.id === 'carRegistrationNumber')?.value || '',
      REGISTRATIONDATE: this.convertDateToDDMMYYYY(this.buyersDetails.find(field => field.id === 'carRegistrationDate')?.value || ''),
      RegisterNumber: this.buyersDetails.find(field => field.id === 'carRegistrationNumber')?.value || '',
      ResidenceNo: this.buyersDetails.find(field => field.id === 'telNumber')?.value || null,
      SalemansCode: this.contractData.salemansCode || '',
      SalemansID: this.buyersDetails.find(field => field.id === 'salesConsultant')?.value || '',
      Title: this.buyersDetails.find(field => field.id === 'title')?.value || '',
      UserOwner: this.contractData.userOwner || ''
    };
  
    // Call the service with updated values
    this.customerSearchService.updateSalesContract(updatedContractData).subscribe(
      (response) => {
        this.notifier.notify('success', 'Contract updated successfully!');
        console.log('Update response:', response);
      },
      (error) => {
        console.error("Error during contract update:", error);
        this.notifier.notify('error', 'Error updating contract');
      }
    );
  }
  
  // Covert date to dd/mm/yyyy
  private convertDateToDDMMYYYY(dateString: string): string {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }

}
