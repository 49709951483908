<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">La debuggeadora</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <div
      *ngFor="let item of exampleArray_Customer"
      class="grid grid-cols-2 bg-orange-100"
    >
      <p>{{ item.id }}</p>
      <p>{{ item.value }}</p>
    </div>
  </div>
  <ul class="tabs-container">
    <li
      (click)="selectedTab = 'customer'"
      [ngClass]="
        selectedTab == 'customer' ? 'tab-item_selected' : 'tab-item'
      "
    >
      Customer
    </li>
    <li
      (click)="selectedTab = 'client'"
      [ngClass]="
        selectedTab == 'client' ? 'tab-item_selected' : 'tab-item'
      "
    >
      Client
    </li>
  </ul>
  <section class="content-container__tabs">
    <app-data-form
      [hidden]="!(selectedTab == 'customer')"
      [dataFields]="exampleArray_Customer"
      [dataName]="'exampleArray_Customer'"
      (updateFields_child)="updateFields($event)"
    />
    <app-data-form
      [hidden]="!(selectedTab == 'client')"
      [dataFields]="exampleArray_Client"
      [dataName]="'exampleArray_Client'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
  <div class="content-container">
    <app-data-table
      [tableHeaders]="tableHeaders"
      [tableEntries]="accessories"
    ></app-data-table>
  </div>
</section>
