import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-navpath",
  templateUrl: "./navpath.component.html",
  styleUrls: ["./navpath.component.css"],
})
export class NavpathComponent {

  menuItems: string[] = [];

  constructor(private route: ActivatedRoute) {
    this.route.url.subscribe((urlSegments) => {
      if (urlSegments && urlSegments.length >= 2) {
        const menuItem1 = this.capitalizeFirstLetter(
          urlSegments[0].path.replace(/-/g, " "),
        );
        const menuItem2 = this.capitalizeFirstLetter(
          urlSegments[1].path.replace(/-/g, " "),
        );
        this.menuItems = [menuItem1, menuItem2];
      }
    });
  }

  private capitalizeFirstLetter(str: string): string {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
