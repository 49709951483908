<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Cheque issue</h1>
    <app-navpath />
  </div>
  <ul class="tabs-container">
    <li
      (click)="selectedTab = 'with'"
      [ngClass]="
        selectedTab == 'with' ? 'tab-item_selected' : 'tab-item'
      "
    >
      DBS and Maybank cheque
    </li>
    <li
      (click)="selectedTab = 'without'"
      [ngClass]="
        selectedTab == 'without' ? 'tab-item_selected' : 'tab-item'
      "
    >
      Maybank check without contract
    </li>
  </ul>
  <section
    class="content-container__tabs"
    [hidden]="!(selectedTab == 'with')"
  >
    <h2 class="sub-heading w-full border-b">Contract detail</h2>
    <app-data-form
      [dataFields]="contractDetail"
      [dataName]="'contractDetail'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
  <section
    class="content-container"
    [hidden]="!(selectedTab == 'with')"
  >
    <h2 class="sub-heading w-full border-b">Contract detail</h2>
    <app-data-form
      [dataFields]="chequeDetail"
      [dataName]="'chequeDetail'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
  <section
    class="content-container"
    [hidden]="!(selectedTab == 'with')"
  >
    <h2 class="sub-heading w-full border-b">
      FINAL (EXCESS) / SHORT AFTER CHEQUE ISSUED:
    </h2>
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn lighter-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1" />Add new cheque
      </button>
      <button class="shorter-btn green-btn">
        <fa-icon [icon]="faSave" class="mr-1" />Update cheque
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faTrash" class="mr-1" />Cancel cheque
      </button>
      <button class="shorter-btn alt-red-btn">
        <fa-icon [icon]="faPrint" class="mr-1" />Print cheque
      </button>
      <button class="shorter-btn alt-red-btn">
        <fa-icon [icon]="faPrint" class="mr-1" />Print payment
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </section>
  <section
    class="content-container__tabs"
    [hidden]="!(selectedTab == 'without')"
  >
    <h2 class="sub-heading w-full border-b">Cheque detail</h2>
    <app-data-form
      [dataFields]="chequeDetail__without"
      [dataName]="'chequeDetail__without'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
  <section
    class="content-container"
    [hidden]="!(selectedTab == 'without')"
  >
    <h2 class="sub-heading w-full border-b">Others</h2>
    <app-data-form
      [dataFields]="others"
      [dataName]="'others'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
  <section
    class="content-container"
    [hidden]="!(selectedTab == 'without')"
  >
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn lighter-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1" />Add new cheque
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faTrash" class="mr-1" />Cancel cheque
      </button>
      <button class="shorter-btn alt-red-btn">
        <fa-icon [icon]="faPrint" class="mr-1" />Print cheque
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </section>
</div>
