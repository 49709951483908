<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Weekly Focus Dashboard - Report Details</h1>
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button class="shorter-btn misc-btn" (click)="onBack()">
      <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Back to List
    </button>
  </div>
  <div class="content-container">
    <h2 class="sub-heading w-full border-b">A. SHOWROOM DUTY</h2>
    <app-data-form [dataFields]="showroomDutyFormField"></app-data-form>

    <div class="content-container mt-3">
      <app-data-table [tableHeaders]="leadsTableHeaders" [tableEntries]="leadsTableEntries"></app-data-table>
    </div>

    <app-data-form [dataFields]="closingRatioFormField"></app-data-form>

    <h2 class="sub-heading w-full border-b mt-4">B. STANDBY DUTY</h2>
    <h4>PROSPECTING</h4>
    <app-data-form [dataFields]="standbyProspectingFormField"></app-data-form>

    <h4 class="mt-4">RESEARCH</h4>
    <app-data-form [dataFields]="standbyResearchFormField"></app-data-form>

    <h2 class="sub-heading w-full border-b mt-4">C. SPOTTERS & YOUR NETWORK</h2>

    <h3 class="mt-2">NEW SPOTTER'S NAMES</h3>
    <div class="content-container mt-3">
      <app-data-table [tableHeaders]="spotters1TableHeaders" [tableEntries]="spotters1TableEntries"></app-data-table>
    </div>

    <h3 class="mt-4">REVISITED SPOTTER'S NAMES</h3>
    <div class="content-container mt-3">
      <app-data-table [tableHeaders]="spotters2TableHeaders" [tableEntries]="spotters2TableEntries"></app-data-table>
    </div>

    <h3 class="mt-4">WHY NO BUSINESS FROM SPOTTER?</h3>
      <app-data-form
        [dataFields]="noBusinessSpotterForm"
        [dataName]="'noBusinessSpotterForm'"
    ></app-data-form>
    
    <h2 class="sub-heading w-full border-b mt-4">D. COMMERCIAL HOUSES</h2>
    <app-data-form [dataFields]="commercialHousesFormField"></app-data-form>

    <h2 class="sub-heading w-full border-b mt-4">E. MARKET ASSESSMENT</h2>
    <app-data-form [dataFields]="marketAssessmentFormField"></app-data-form>

    <h2 class="sub-heading w-full border-b mt-4">F. WHY NO CAT B CARS SALES</h2>
    <app-data-form [dataFields]="whyNoCatBFormField"></app-data-form>

    <h2 class="sub-heading w-full border-b mt-4">G. ANY OTHER COMMENTS OR SUGGESTIONS</h2>
    <app-data-form [dataFields]="commentsSuggestionsFormField"></app-data-form>

    <h2 class="mt-4">COMMENTS</h2>
      <app-data-form
        [dataFields]="remarksForm"
        [dataName]="'remarksForm'"
      ></app-data-form>

      <button class="shorter-btn save-btn" (click)="onSaveRemark()">
        <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Save Comment
      </button>

  </div>
  <div class="mt-2 flex w-fit gap-1">
    <button class="shorter-btn misc-btn" (click)="onBack()">
      <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Back to List
    </button>
  </div>
</section>
