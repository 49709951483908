import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ProspectService } from "src/app/services/customer/prospect.service";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: "app-user-management-list",
  templateUrl: "./prospect-list.component.html",
  styleUrls: ["./prospect-list.component.css"],
})
export class ProspectListComponent implements OnInit {
  private readonly notifier: NotifierService;
  faPlus = faPlus;
  users_info: any[] = [];
  filteredUsers: any[] = [];

  icNoSearchValue: string = "";
  nameSearchValue: string = "";

  constructor(
    private ProspectService: ProspectService,
    private router: Router,
    private route: ActivatedRoute,
    notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.ProspectService.GetAllProspectCustomerPaged().subscribe(
      (response) => {
        this.users_info = response.body.map((user: any) => {
          const container = {
            "IcNo": "",
            "Name": "",
            "Hp": "",
            "ProspectDate": "",
            "Email": "",
            "ModelInterested": "",
            "SalesmanCode": "",
            "editTrash": "",
            "innerId": "",
          };
          container["IcNo"] = user.IcNo;
          container["Name"] = user.Name;
          container["Hp"] = user.Hp;
          container["ProspectDate"] = user.ProspectDate;
          container["Email"] = user.Email;
          container["ModelInterested"] = user.ModelInterested;
          container["SalesmanCode"] = user.SalesmanCode;
          container["editTrash"] = "";
          container["innerId"] = user.IcNo ? user.IcNo : "";
          return container;
        });
        this.filteredUsers = this.users_info;
      },
      (error) => {
        console.error("Error fetching user management data:", error);
      },
    );
  }

  onSearch(searchValue: string, type: string): void {
    if (type === "IcNo") {
      this.icNoSearchValue = searchValue;
    } else if (type === "Name") {
      this.nameSearchValue = searchValue;
    }

    this.filteredUsers = this.users_info.filter(
      (item) =>
        item.IcNo.toLowerCase().includes(
          this.icNoSearchValue.toLowerCase(),
        ) &&
        item.Name.toLowerCase().includes(
          this.nameSearchValue.toLowerCase(),
        ),
    );
  }

  tableHeaders = [
    { title: "ICNO", type: "data", id: "IcNo" },
    {
      title: "NAME",
      type: "data",
      id: "Name",
    },
    { title: "MOBILE", type: "data", id: "Hp" },
    { title: "PROSPECT DATE", type: "data", id: "ProspectDate" },
    { title: "EMAIL ADDRESS", type: "data", id: "Email" },
    {
      title: "MODEL INTERESTED",
      type: "data",
      id: "ModelInterested",
    },
    { title: "SC", type: "data", id: "SalesmanCode" },
    { title: "", type: "editTrash" },
    { title: "INNERID", type: "hidden" },
  ];

  editEntry(value: any) {
    this.router.navigate(["../prospect", value.innerId], {
      relativeTo: this.route,
    });
  }

  removeEntry(value: any) {
    this.ProspectService.deleteProspectPatient(value.innerId).subscribe({
      next: () => {
        this.notifier.notify(
          "success",
          "User " + value.IcNo + " deleted",
        );
        this.filteredUsers = this.filteredUsers.filter(
          (item) => item.innerId !== value.innerId,
        );
      },
      error: (error) => {
        this.notifier.notify("error", "Error deleting user");
        console.error("Error removing user:", error);
      },
    });
  }

  protected readonly getEmptyGuid = getEmptyGuid;
}
