<section class="page-container">
    <div class="flex items-center justify-between">
        <h1 class="main-heading">Premium Care Member Detail</h1>
        <app-navpath></app-navpath>
    </div>
    <div class="mb-2 flex w-fit gap-2">
        <button class="shorter-btn misc-btn" *ngIf="mode == 'Detail'" (click)="updatePremiumCareMember()">
            <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Update Premium Care Member Type
        </button>
        <button class="shorter-btn misc-btn" *ngIf="mode == 'Add'" (click)="addPremiumCareMember()">
            <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add Premium Care Member
        </button>
        <button class="shorter-btn clear-btn" [routerLink]="['/customer/premium-care-member']">
            <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Cancel
        </button>
    </div>

    <!-- Main Form  -->
    <div class="content-container">
        <section class="content-container">
            <app-data-form 
                [dataFields]="mainFormField" 
                [dataName]="'mainFormField'"
                (clickSearch)="handleSearch($event)"
            >
            </app-data-form>
        </section>
    </div>

    <!-- Membership Information Section -->
    <section class="content-container">
        <div class="flex items-center justify-between" (click)="toggleSection('membershipInfoExpanded')">
            <h2 class="sub-heading w-full border-b">Membership Information</h2>
            <button class="toggle-icon">
                <fa-icon [icon]="membershipInfoExpanded ? faMinus : faPlus" class="hover-icon"></fa-icon>
            </button>
        </div>
        <div *ngIf="membershipInfoExpanded">
            <app-data-form
                [dataFields]="membershipInformationFormField"
                [dataName]="'membershipInformationFormField'"
            ></app-data-form>
        </div>
    </section>

    <!-- Membership Benefits Entitlement Section -->
    <section class="content-container" *ngIf="!isEmptyGuid">
        <div class="flex items-center justify-between" (click)="toggleSection('benefitsEntitlementExpanded')">
            <h2 class="sub-heading w-full border-b">Membership Benefits Entitlement</h2>
            <button class="toggle-icon">
                <fa-icon [icon]="benefitsEntitlementExpanded ? faMinus : faPlus" class="hover-icon"></fa-icon>
            </button>
        </div>
        <div *ngIf="benefitsEntitlementExpanded">
            <app-data-form
                [dataFields]="membershipBenefitsEntitlementFormField"
                [dataName]="'membershipBenefitsEntitlementFormField'"
            ></app-data-form>
            <button class="shorter-btn clear-btn ml-8" (click)="addBenefit()">
                <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add Benefit
            </button>
            <div class="content-container">
                <app-data-table
                    [tableHeaders]="membershipBenefitsTableHeaders"
                    [tableEntries]="memberBenefits"
                    (remove)="removeBenefit($event)"
                ></app-data-table>
            </div>
        </div>
    </section>

    <!-- Benefits Usage Section -->
    <section class="content-container" *ngIf="!isEmptyGuid">
        <div class="flex items-center justify-between" (click)="toggleSection('benefitsUsageExpanded')">
            <h2 class="sub-heading w-full border-b">Benefits Usage</h2>
            <button class="toggle-icon">
                <fa-icon [icon]="benefitsUsageExpanded ? faMinus : faPlus" class="hover-icon"></fa-icon>
            </button>
        </div>
        <div *ngIf="benefitsUsageExpanded">
            <app-data-form
                [dataFields]="benefitsUsageFormField"
                [dataName]="'benefitsUsageFormField'"
            ></app-data-form>
            <button class="shorter-btn clear-btn ml-8" (click)="addUsage()">
                <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add Usage
            </button>
            <div class="content-container">
                <app-data-table
                    [tableHeaders]="membershipUsageTableHeaders"
                    [tableEntries]="memberUsage"
                    (remove)="removeEntry($event)"
                ></app-data-table>
            </div>
        </div>
    </section>

    <!-- Bottom Buttons -->
    <div class="mb-2 flex w-fit gap-2 mt-2">
        <button class="shorter-btn misc-btn" *ngIf="mode == 'Detail'" (click)="updatePremiumCareMember()">
            <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Update Premium Care Member Type
        </button>
        <button class="shorter-btn misc-btn" *ngIf="mode == 'Add'" (click)="addPremiumCareMember()">
            <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add Premium Care Member
        </button>
        <button class="shorter-btn clear-btn" [routerLink]="['/customer/premium-care-member']">
            <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Cancel
        </button>
    </div>
</section>
