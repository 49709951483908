import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class SalesTeamShuffleService {
  constructor(private http: HttpUtilService) {}

  /**
   * Fetches all sales teams.
   * @returns An Observable of the sales teams data.
   */
  getAllSalesTeam(): Observable<any> {
    return this.http.get('/KapsUserService.asmx/GetAllSalesTeam');
  }

  /**
   * Fetches all salesmen.
   * @returns An Observable of the salesmen data.
   */
  getAllSalesman(): Observable<any> {
    return this.http.get('/KapsUserService.asmx/GetAllSalesman');
  }

  /**
   * Saves a sales team.
   * @param data The sales team data to save.
   * @returns An Observable of the save operation result.
   */
  saveSalesTeam(data: object): Observable<any> {
    return this.http.post('/KapsUserService.asmx/SaveSalesTeam', data);
  }

  /**
   * Deletes selected sales teams.
   * @param items The sales team items to delete.
   * @returns An Observable of the delete operation result.
   */
  deleteSalesTeam(items: object[]): Observable<any> {
    return this.http.post('/KapsUserService.asmx/DeleteSalesTeam', { items });
  }

  /**
   * Updates the salesmen teams.
   * @param data The updated salesmen data.
   * @returns An Observable of the update operation result.
   */
  updateSalesmanTeam(data: object): Observable<any> {
    return this.http.post('/KapsUserService.asmx/UpdateSalesmanTeam', data);
  }
}
