import { Component, OnInit } from "@angular/core";
import {
  faPencil,
  faTrash,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-car-allocation",
  templateUrl: "./car-allocation.component.html",
  styleUrls: ["./car-allocation.component.css"],
})
export class CarAllocationComponent implements OnInit {
  faPencil = faPencil;
  faTrash = faTrash;
  faBroom = faBroom;

  selectedTab = "single";

  searchVehicles!: FormField[];
  detailsVehicle!: FormField[];
  detailsContract!: FormField[];

  tableSingleAllocation__headers = [
    {
      title: "VIN NUMBER",
      type: "data",
    },
    {
      title: "MODEL",
      type: "data",
    },
    {
      title: "COLOUR",
      type: "data",
    },
    {
      title: "INT COLOUR",
      type: "data",
    },
    {
      title: "ETA DATE",
      type: "data",
    },
    {
      title: "CONTRACT NUMBER",
      type: "data",
    },
    {
      title: "",
      type: "link",
      classes: "underline",
    },
  ];

  tableSingleAllocation__entries = [
    {
      vinNumber: "GEAA107",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA110",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA114",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA118",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA120",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA121",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA122",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA123",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA125",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
    {
      vinNumber: "GEAA129",
      model: "SONATA 2.0 CRDI A",
      colour: "QE",
      intColour: "CH-",
      etaPrice: "31/05/2007",
      contractNumber: "",
      link: "select",
    },
  ];

  tableAllAllocation__headers = [
    {
      title: "CONTRACT DATE",
      type: "data",
    },
    {
      title: "CONTRACT NUMBER",
      type: "data",
    },
    {
      title: "BUYER'S NAME",
      type: "data",
    },
    {
      title: "MODEL",
      type: "data",
    },
    {
      title: "COLOUR",
      type: "data",
    },
    {
      title: "EXCLUDE FROM ALLOCATION",
      type: "checkbox",
      Hclasses: "text-center"
    },
  ];

  tableAllAllocation__entries = [
    {
      contractDate: "09/11/2005",
      contractNumber: "05110411",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "04030473",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "08070351",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "08120375",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "24040058",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "04090504",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "04040949",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "23120015",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "23110017",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "24010060",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "24010281",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "23120004",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "23110028",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "23120012",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
    {
      contractDate: "09/11/2005",
      contractNumber: "23060057",
      buyersName: "NG LAY HUA",
      model: "ACCENT 1.3 M",
      colour: "CS",
      checked: false,
    },
  ];

  updateFields(event: any): void {
    switch (event.name) {
      case "searchVehicles":
        this.searchVehicles = event.fields;
        break;
      case "detailsVehicle":
        this.detailsVehicle = event.fields;
        break;
      case "detailsContract":
        this.detailsContract = event.fields;
        break;
    }
  }

  ngOnInit() {
    this.searchVehicles = [
      {
        type: "select",
        id: "searchVehicles__model",
        label: "Model",
      },
      {
        type: "select",
        id: "searchVehicles__colour",
        label: "Colour",
      },
      {
        type: "select",
        id: "searchVehicles__interiorColour",
        label: "Interior colour",
      },
    ];
    this.detailsVehicle = [
      {
        type: "search",
        id: "detailsVehicle__vinNumber",
        label: "VIN number",
      },
      {
        type: "text",
        id: "detailsVehicle__colour",
        label: "Colour",
        lock: true,
      },
      {
        type: "text",
        id: "detailsVehicle__model",
        label: "Model",
        lock: true,
      },
      {
        type: "text",
        id: "detailsVehicle__interiorColour",
        label: "Interior colour",
        lock: true,
      },
    ];
    this.detailsContract = [
      {
        type: "search",
        id: "detailsContract__contractNumber",
        label: "Contract number",
      },
      {
        type: "text",
        id: "detailsContract__contractDate",
        label: "Contract date",
      },
      {
        type: "text",
        id: "detailsContract__buyersNric",
        label: "Buyer's NRIC",
      },
      {
        type: "text",
        id: "detailsContract__buyersName",
        label: "Buyer's name",
      },
      {
        type: "text",
        id: "detailsContract__buyersContact",
        label: "Buyer's contact",
      },
      {
        type: "text",
        id: "detailsContract__buyersAddress",
        label: "Buyer's address",
      },
      {
        type: "select",
        id: "detailsContract__salesConsultant",
        label: "Sales consultant",
      },
      {
        type: "text",
        id: "detailsContract__financeCompany",
        label: "Finance company",
      },
      {
        type: "date",
        id: "detailsContract__actualTakenDate",
        label: "COE actual taken date",
      },
      {
        type: "textarea",
        id: "detailsContract__orderRemarks",
        label: "Order remarks",
      },
    ];
  }
}
