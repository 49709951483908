<main class="login-container">
  <div class="color-container">
    <div
      class="flex w-96 flex-col justify-center rounded-lg bg-white p-10"
    >
      <img
        src="/src/assets/images/logos/komoco-logo.png"
        alt="komoco-logo"
        class="max-w-60"
      />
      <form [formGroup]="loginForm"  (ngSubmit)="login()" action="" class="flex w-fit flex-col gap-2">
        <label for="email" class="block">
          <fa-icon [icon]="faUser" class="mr-1" />
          <input id="email" formControlName="email" type="email" placeholder="Company E-Mail Address" />
        </label>
        <label for="password" class="block">
          <fa-icon [icon]="faLock" class="mr-1" />
          <input id="password" formControlName="password" type="password" placeholder="Password"/>
        </label>
        <label for="showPassword" class="block text-right">
          Show password
          <input id="showPassword" type="checkbox" />
        </label>
        <button type="submit">Log in</button>
      </form>
      <button>Forgot your password?</button>
      <p>Powered by <strong>Syion Private Limited</strong></p>
    </div>
  </div>
</main>
