<div
  class="input-container"
  [ngClass]="{ 'missing': required && !dataInput, 'disabled': lock }"
>
  <input
    [type]="dataType"
    class="text-container"
    [disabled]="lock"
    [(ngModel)]="dataInput"
    [placeholder]="placeholder"
    (input)="dataOutput.emit(dataInput)"
  />
  <fa-icon
    *ngIf="required && !dataInput"
    [icon]="faTriangleExclamation"
    class="warning-btn"
  />
</div>
