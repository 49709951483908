import { Component } from '@angular/core';

@Component({
  selector: 'app-buyers-order-report-dynamic',
  templateUrl: './buyers-order-report-dynamic.component.html',
  styleUrls: ['./buyers-order-report-dynamic.component.css']
})
export class BuyersOrderReportDynamicComponent {

}
