import { Component } from '@angular/core';
import { faSave, faPrint, faLink } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-cash-deposit-listing',
  templateUrl: './cash-deposit-listing.component.html',
  styleUrls: ['./cash-deposit-listing.component.css']
})
export class CashDepositListingComponent {
  constructor() {}

  ngOnInit() {}
  
  // ICONS VARIABLES

  faSave = faSave;
  faPrint = faPrint;
  faLink = faLink;

  showReceiptTable: boolean = false;

  cashForm: FormField[] = [
    {
      type: "date",
      id: "receiptDate",
      label: "Receipt Date (dd/mm/yyyy)",
    },
    {
      type: "text",
      id: "depositsName",
      label: "Deposit's Name",
    },
    {
      type: "text",
      id: "depositsAccountNumber",
      label: "Deposit's Account Number",
    },
    {
      type: "const",
      id: "totalReceiptAmount",
      label: "Total Receipt Amount",
      value: "",
    },
  ];

  listing_headers = [
    {
      title: "CUSTOMER NAME",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "FINANCE COMPANY",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "OR NUMBER",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "CONTRACT NUMBER",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "SALES CONSULTANT",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "RECEIPT AMOUNT",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BANK IN",
      type: "checkbox",
    },
  ];

  listing = [
    {
      "customerName": "ZHANG RUITING MARTIN",
      "financeCompany": "CASH",
      "orNumber": "13465",
      "contractNumber": "22020004****",
      "salesConsultant": "WNS",
      "receiptAmount": 2000.00,
      "bankIn": false
    },
    {
      "customerName": "SHAMSHUDEEN SHEIK HUSSAINUDEEN",
      "financeCompany": "CASH",
      "orNumber": "314700",
      "contractNumber": "22020085****",
      "salesConsultant": "SUP",
      "receiptAmount": 10000.00,
      "bankIn": false
    },
    {
      "customerName": "SHAMSHUDEEN SHEIK HUSSAINUDEEN",
      "financeCompany": "CASH",
      "orNumber": "314701",
      "contractNumber": "22020085****",
      "salesConsultant": "SUP",
      "receiptAmount": 2050.00,
      "bankIn": false
    },
    {
      "customerName": "SHAMSHUDEEN SHEIK HUSSAINUDEEN",
      "financeCompany": "CASH",
      "orNumber": "314702",
      "contractNumber": "22020085****",
      "salesConsultant": "SUP",
      "receiptAmount": 2950.00,
      "bankIn": false
    },
    {
      "customerName": "SHAMSHUDEEN SHEIK HUSSAINUDEEN",
      "financeCompany": "CASH",
      "orNumber": "314703",
      "contractNumber": "22020085****",
      "salesConsultant": "SUP",
      "receiptAmount": 5000.00,
      "bankIn": false
    },
    {
      "customerName": "HAMEEDA BEEVI D/O ABDULLAH",
      "financeCompany": "CASH",
      "orNumber": "315175",
      "contractNumber": "22020098****",
      "salesConsultant": "REK",
      "receiptAmount": 10000.00,
      "bankIn": false
    },
    {
      "customerName": "ZHANG RUISHENG LEWIS",
      "financeCompany": "CASH",
      "orNumber": "315417",
      "contractNumber": "22020005****",
      "salesConsultant": "WNS",
      "receiptAmount": 2000.00,
      "bankIn": false
    },
    {
      "customerName": "KOH CHENG KWEE BARTHOLOMEW",
      "financeCompany": "CASH",
      "orNumber": "315428",
      "contractNumber": "22020061****",
      "salesConsultant": "CBH",
      "receiptAmount": 600.00,
      "bankIn": false
    },
    {
      "customerName": "MOHAMMAD AMIN BIN ATAN",
      "financeCompany": "CASH",
      "orNumber": "316263",
      "contractNumber": "22060015****",
      "salesConsultant": "FRD",
      "receiptAmount": 11494.00,
      "bankIn": false
    },
    {
      "customerName": "MOHAMED SAID BIN KAMIN",
      "financeCompany": "CASH",
      "orNumber": "317387",
      "contractNumber": "22080101****",
      "salesConsultant": "CYN",
      "receiptAmount": 8000.00,
      "bankIn": false
    },
    {
      "customerName": "LEE HWEE MIN",
      "financeCompany": "CASH",
      "orNumber": "317455",
      "contractNumber": "22080093****",
      "salesConsultant": "ACC",
      "receiptAmount": 1.00,
      "bankIn": false
    },
    {
      "customerName": "SPEDCO KALTIM PTE LTD",
      "financeCompany": "CASH",
      "orNumber": "317636",
      "contractNumber": "22090056****",
      "salesConsultant": "RYN",
      "receiptAmount": 32500.00,
      "bankIn": false
    },
    {
      "customerName": "MOHAMAD RAZI BIN ISMAIL",
      "financeCompany": "CASH",
      "orNumber": "317688",
      "contractNumber": "22100004****",
      "salesConsultant": "SUP",
      "receiptAmount": 13500.00,
      "bankIn": false
    },
    {
      "customerName": "YEAH WEIQIANG",
      "financeCompany": "CASH",
      "orNumber": "317728",
      "contractNumber": "22100019****",
      "salesConsultant": "SUP",
      "receiptAmount": 7750.00,
      "bankIn": false
    },
    {
      "customerName": "SUKHBANS SINGH S/O PRITAM SINGH",
      "financeCompany": "CASH",
      "orNumber": "317896",
      "contractNumber": "22100038****",
      "salesConsultant": "REK",
      "receiptAmount": 28300.00,
      "bankIn": false
    },
    {
      "customerName": "4G INVESTMENT PTE LTD",
      "financeCompany": "CASH",
      "orNumber": "318363",
      "contractNumber": "22110077****",
      "salesConsultant": "JRN",
      "receiptAmount": 10000.00,
      "bankIn": false
    },
    {
      "customerName": "CHEAH YEOK THENG (XIE YUTING)",
      "financeCompany": "CASH",
      "orNumber": "319241",
      "contractNumber": "23040088****",
      "salesConsultant": "REK",
      "receiptAmount": 66600.00,
      "bankIn": false
    },
    {
      "customerName": "SAM MEI POH",
      "financeCompany": "CASH",
      "orNumber": "319332",
      "contractNumber": "23050014****",
      "salesConsultant": "MKC",
      "receiptAmount": 175000.00,
      "bankIn": false
    },
    {
      "customerName": "SIANG KOK ENG",
      "financeCompany": "CASH",
      "orNumber": "319400",
      "contractNumber": "23070150****",
      "salesConsultant": "REK",
      "receiptAmount": 61313.00,
      "bankIn": false
    },
    {
      "customerName": "MUHAMMAD SAIFUDIN BIN TUMANI",
      "financeCompany": "CASH",
      "orNumber": "319480",
      "contractNumber": "23080016****",
      "salesConsultant": "REK",
      "receiptAmount": 50600.00,
      "bankIn": false
    },
    {
      "customerName": "LIN RUIFENG",
      "financeCompany": "CASH",
      "orNumber": "319682",
      "contractNumber": "23080107****",
      "salesConsultant": "REK",
      "receiptAmount": 52250.00,
      "bankIn": false
    },
    {
      "customerName": "LEOW BENG HENG",
      "financeCompany": "CASH",
      "orNumber": "321294",
      "contractNumber": "24010119****",
      "salesConsultant": "AUD",
      "receiptAmount": 30000.00,
      "bankIn": false
    },
    {
      "customerName": "LAU CHIN JUI",
      "financeCompany": "CASH",
      "orNumber": "321778",
      "contractNumber": "24040106****",
      "salesConsultant": "REK",
      "receiptAmount": 17220.00,
      "bankIn": false
    },
    {
      "customerName": "GOH SWEE GUAN",
      "financeCompany": "CASH",
      "orNumber": "321779",
      "contractNumber": "24010120****",
      "salesConsultant": "REK",
      "receiptAmount": 55000.00,
      "bankIn": false
    },
    {
      "customerName": "TAN KHENG HONG",
      "financeCompany": "CASH",
      "orNumber": "321787",
      "contractNumber": "24050008****",
      "salesConsultant": "AUD",
      "receiptAmount": 18000.00,
      "bankIn": false
    },
    {
      "customerName": "YONG CHING FANG",
      "financeCompany": "CASH",
      "orNumber": "322303",
      "contractNumber": "24050071****",
      "salesConsultant": "KIT",
      "receiptAmount": 9655.00,
      "bankIn": false
    }
  ];

  showReceiptAndUpdateTotal() {
    this.showReceiptTable = true;
    const totalAmountField = this.cashForm.find(field => field.id === 'totalReceiptAmount');
    if (totalAmountField) {
      totalAmountField.value = "6";
    }
  }
}
