import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService, HttpResponse } from "../http-util.service";
import { model } from "src/app/models/sales/control-parameters";

@Injectable({
  providedIn: "root",
})
export class ControlParametersService {
  constructor(private http: HttpUtilService) {}

  GetAll(): Observable<any> {
    return this.http.get("ControlParameterService.asmx/GetAll");
  }

  GetCategory(): Observable<any> {
    return this.http.get("ControlParameterService.asmx/GetCategory");
  }

  GetRoyaltyDiscount(): Observable<any> {
    return this.http.get(
      "ControlParameterService.asmx/GetRoyaltyDiscount",
    );
  }

  AddRoyaltyDiscount(
    Discount: number,
    Id: string,
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      `ControlParameterService.asmx/AddRoyaltyDiscount`,
      {
        model: { Discount: Discount, Id: Id },
      },
    );
  }

  DeleteRoyaltyDiscount(id: string): Observable<HttpResponse<any>> {
    return this.http.post(
      `ControlParameterService.asmx/DeleteRoyaltyDiscount`,
      {
        id: id,
      },
    );
  }

  Update(model: model): Observable<any> {
    return this.http.post("ControlParameterService.asmx/Update", {
      model,
    });
  }

  PrintStockSummary(monthAdjust: {
    Value: number;
    Name: string;
  }): Observable<any> {
    return this.http.post(
      "ControlParameterService.asmx/PrintStockSummary",
      {
        monthAdjust,
      },
    );
  }

  TestDriveReport(model: {
    FromDate: string;
    ToDate: string;
  }): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/TestDriveReport",
      {
        model,
      },
    );
  }

  TradeNetIntegration(): Observable<any> {
    return this.http.post(
      "ControlParameterService.asmx/TradeNetIntegration",
      {},
    );
  }
}
