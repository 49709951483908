import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-select-input",
  templateUrl: "./select-input.component.html",
  styleUrls: ["./select-input.component.css"],
})
export class SelectInputComponent {
  @Input("selectOptions") selectOptions: {
    display: string;
    value: string;
  }[] = [];
  @Input("dataInput") dataInput: string | undefined;
  @Input("required") required: boolean = false;
  @Input("lock") lock: boolean = false;
  @Input("id") id: string = "";

  @Output() dataOutput = new EventEmitter<string>();

  faTriangleExclamation = faTriangleExclamation;

  selectItem(): void {
    this.dataOutput.emit(this.dataInput);
  }
}
