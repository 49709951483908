import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilService } from '../http-util.service';

@Injectable({
  providedIn: 'root',
})
export class WeeklyFocusReportService {

  constructor(private http: HttpUtilService) { }

  /**
   * Retrieves all WeeklyFocus records.
   * @returns An Observable containing all WeeklyFocus data.
   */
  getAllWeeklyFocus(): Observable<any> {
    return this.http.get('/WeeklyFocusService.asmx/GetWeeklyFocus');
  }

  /**
   * Retrieves the details of a WeeklyFocus record by its ID.
   * @param id The ID of the WeeklyFocus.
   * @returns An Observable containing the WeeklyFocus details.
   */
  getWeeklyFocusDetail(id: string): Observable<any> {
    // Notice the payload requires id=%22...%22, so we include the quotes in the URL
    return this.http.get(`/WeeklyFocusService.asmx/GetWeeklyFocusDetail?id=%22${id}%22`);
  }

  /**
   * Adds or saves a new WeeklyFocus record in the system.
   * @param model The data object containing all necessary fields to create the WeeklyFocus.
   * @returns An Observable containing the server response.
   */
  addWeeklyFocus(model: any): Observable<any> {
    // The endpoint expects a payload of the form: { "model": { ... } }
    return this.http.post('/WeeklyFocusService.asmx/AddWeeklyFocus', { model });
  }

  /**
   * Deletes a WeeklyFocus record by its ID.
   * @param id The ID of the WeeklyFocus record to delete.
   * @returns An Observable containing the server response.
   */
  deleteWeeklyFocus(id: string): Observable<any> {
    // The endpoint expects a payload of the form: { "id": "some-uuid-value" }
    return this.http.post('/WeeklyFocusService.asmx/DeleteWeeklyFocus', { id });
  }

  /**
   * Edits a WeeklyFocus record by its ID.
   * @param model The data of the WeeklyFocus record to update.
   */
  editWeeklyFocus(model: any): Observable<any> {
   return this.http.post('/WeeklyFocusService.asmx/EditWeeklyFocus', {
      data: model
    });
  }

   /**
   * Submits a WeeklyFocus record.
   * @param id The ID of the WeeklyFocus record to submit.
   * @returns An Observable containing the server response.
   */
   submitWeeklyFocus(id: string): Observable<any> {
    return this.http.post('/WeeklyFocusService.asmx/SubmitWeeklyFocus', { id });
  }

  
}

