import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class HirePurchaseService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets all finance companies
   * @returns An Observable of the finance company list
   */
  getAll(): Observable<any> {
    return this.http.get("/FinanceManagerService.asmx/GetAll");
  }

  /**
   * Gets finance company detail by ID.
   * @param id - The ID of the finance company.
   * @returns An Observable of the finance company details.
   */
  getDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`);
    const url = `/FinanceManagerService.asmx/GetDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  /**
  * Saves finance company details (for both Add and Update).
  * @param data - The finance company data to save.
  * @returns An Observable for the result of the save operation.
  */
  saveDetails(data: any): Observable<any> {
    return this.http.post("/FinanceManagerService.asmx/SaveDetails", data);
  }


  /**
   * Deletes the selected finance companies.
   * @param items - The list of finance companies to delete.
   * @returns An Observable for the delete operation.
   */
  delete(items: any[]): Observable<any> {
    return this.http.post("/FinanceManagerService.asmx/Delete", { items });
  }
}
