import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencil, faBan, faUsers, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';
import { InteriorColourService } from 'src/app/services/vehicle/interior-colour.service';
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid, isEmptyGuid } from '../../../../services/util.service';

@Component({
  selector: 'app-interior-colour-detail',
  templateUrl: './interior-colour-detail.component.html',
  styleUrls: ['./interior-colour-detail.component.css']
})
export class InteriorColourDetailComponent implements OnInit {
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faUsers = faUsers;

  mode: string = "Add"; 
  interiorColourId: string = getEmptyGuid(); 

  formFields: FormField[] = [
    { type: 'text', id: 'modelGroup', label: 'Model Group', value: '' },
    { type: 'text', id: 'interiorColourCode', label: 'Interior Colour Code', value: '' },
    { type: 'text', id: 'description', label: 'Description', value: '' },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private interiorColourService: InteriorColourService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.interiorColourId = params['id'];

      if (!isEmptyGuid(this.interiorColourId)) {
        this.mode = 'Edit';
        this.loadInteriorColourDetail();
      }
    });
  }

  private loadInteriorColourDetail(): void {
    this.interiorColourService.getDetail(this.interiorColourId).subscribe({
      next: (response) => {
        const data = response.body;
        this.formFields = [
          { type: 'text', id: 'modelGroup', label: 'Model Group', value: data.ModelGroup },
          { type: 'text', id: 'interiorColourCode', label: 'Interior Colour Code', value: data.InteriorColourCode },
          { type: 'text', id: 'description', label: 'Description', value: data.Description },
        ];
      },
      error: (error: any) => {
        console.error("Error loading interior colour detail:", error);
        this.notifier.notify('error', 'Error loading interior colour detail');
      }
    });
  }

  onSave(): void {
    if (this.mode === 'Add') {
      this.addInteriorColour();
    } else if (this.mode === 'Edit') {
      this.updateInteriorColour();
    }
  }

  private addInteriorColour(): void {
    const newColourData = {
      InteriorColourId: 0, 
      ModelGroup: this.getFieldValue('modelGroup'),
      InteriorColourCode: this.getFieldValue('interiorColourCode'),
      Description: this.getFieldValue('description')
    };

    this.interiorColourService.saveDetails(newColourData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Interior Colour added successfully');
        this.router.navigate(['../../interior-colour'], { relativeTo: this.route });
      },
      error: (error: any) => {
        console.error("Error adding interior colour:", error);
        this.notifier.notify('error', 'Error adding interior colour');
      }
    });
  }

  private updateInteriorColour(): void {
    const updatedColourData = {
      InteriorColourId: this.interiorColourId, 
      ModelGroup: this.getFieldValue('modelGroup'),
      InteriorColourCode: this.getFieldValue('interiorColourCode'),
      Description: this.getFieldValue('description')
    };

    this.interiorColourService.saveDetails(updatedColourData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Interior Colour updated successfully');
        this.router.navigate(['../../interior-colour'], { relativeTo: this.route });
      },
      error: (error: any) => {
        console.error("Error updating interior colour:", error);
        this.notifier.notify('error', 'Error updating interior colour');
      }
    });
  }

  private getFieldValue(id: string): string {
    const field = this.formFields.find(f => f.id === id);
    return field?.value || '';
  }

  onCancel(): void {
    this.router.navigate(['../../interior-colour'], { relativeTo: this.route });
  }
}
