import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class PriceListTemplateService {
  constructor(private http: HttpUtilService) {}

  private baseUrl = '/PriceListService.asmx';
/**
   * Fetches all price list templates.
   * Sends a GET request to retrieve all available templates.
   * @returns An Observable with the API response containing the list of templates.
   */
getAllPriceListTemplates(): Observable<any> {
  return this.http.get(`${this.baseUrl}/GetAllPriceListTemplate`);
}

/**
 * Fetches the details of a specific price list template.
 * Sends a GET request with the provided template ID.
 * @param id The GUID of the price list template to fetch details for.
 * @returns An Observable with the API response containing the template details.
 */
getAllPriceListTemplateDetails(id: string): Observable<any> {
  const encodedId = encodeURIComponent(id);
  return this.http.get(`${this.baseUrl}/GetAllPriceListTemplateDetails?id=%22${encodedId}%22`);
}

/**
 * Updates an existing price list template.
 * Sends a POST request with the provided payload containing the updated template data.
 * @param payload The payload containing the template data to be updated.
 * @returns An Observable with the API response indicating the result of the update operation.
 */
updatePriceListTemplate(payload: { model: any }): Observable<any> {
  return this.http.post(`${this.baseUrl}/UpdatePriceListTemplate`, payload);
}

/**
 * Adds a new price list template.
 * Sends a POST request with the provided payload containing the new template data.
 * @param payload The payload containing the new template data to be added.
 * @returns An Observable with the API response indicating the result of the add operation.
 */
addPriceListTemplate(payload: { model: any }): Observable<any> {
  return this.http.post(`${this.baseUrl}/AddPriceListTemplate`, payload);
}

/**
 * Deletes one or more price list templates.
 * Sends a POST request with the provided payload containing the IDs of templates to delete.
 * @param payload The payload containing the list of template IDs to be deleted.
 * @returns An Observable with the API response indicating the result of the delete operation.
 */
deletePriceListTemplates(payload: { ids: any[] }): Observable<any> {
  return this.http.post(`${this.baseUrl}/DeletePriceListTemplates`, payload);
}
}