import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpResponse, HttpUtilService} from '../http-util.service';

const SERVICE_NAME = 'HomeService.asmx';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpUtilService) {}

  /**
   * Fetches the login user information.
   * @returns An Observable of the login user information.
   */
  getLoginUser(): Observable<HttpResponse<any>> {
    return this.http.post(`${SERVICE_NAME}/GetLoginUser`, {})
  }

}
