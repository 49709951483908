import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class ColoursByModelService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets all colours by model elements
   * @returns An Observable of the colours by model list
   */
  getAllColoursByModel(): Observable<any> {
    return this.http.get('/ColoursByModelService.asmx/GetAll');
  }

  /**
   * Gets the detail of a colour by model based on the ID.
   * @param id - The ID of the colour by model.
   * @returns An Observable of the colour by model details.
   */
  getDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`);
    const url = `/ColoursByModelService.asmx/GetDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  /**
 * Saves colour by model details (for both Add and Update).
 * @param data - The colour by model data to save.
 * @returns An Observable for the result of the save operation.
 */
saveDetails(data: any): Observable<any> {
  return this.http.post('/ColoursByModelService.asmx/SaveDetails', data);
}


  /**
   * Deletes the selected colours by model.
   * @param items - The list of colours by model to delete.
   * @returns An Observable for the delete operation.
   */
  delete(items: any[]): Observable<any> {
    return this.http.post('/ColoursByModelService.asmx/Delete', { items });
  }

  /**
   * Gets all model specifications.
   * @returns An Observable of the model specifications list.
   */
  getAllModelSpecifications(): Observable<any> {
    return this.http.get('/ModelSpecificationService.asmx/GetAll');
  }

  /**
   * Gets the list of model groups.
   * @returns An Observable of the model group list.
   */
  getModelGroupList(): Observable<any> {
    return this.http.get('/ColourMasterService.asmx/GetModelGroup');
  }

  /**
   * Gets the colour code list by model group.
   * @param modelGroup - The model group for which to fetch the colour codes.
   * @returns An Observable of the colour code list.
   */
  getColourCodeListByModelGroup(modelGroup: string): Observable<any> {
    const encodedModelGroup = encodeURIComponent(`"${modelGroup}"`);
    const url = `/ColourMasterService.asmx/GetColourCodeList?modelGroup=${encodedModelGroup}`;
    return this.http.get(url);
  }

  /**
 * Gets the interior colour code by model code.
 * @param model - An object containing the model group and exterior colour.
 * @returns An Observable of the interior colour code list.
 */
  getInteriorColourCodeByModelCode(model: any): Observable<any> {
    return this.http.post('/InteriorColourService.asmx/GetColourCodeByModelCode', model);
  }

}
