<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Registration Report</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="FormFields"
      (updateFields_child)="update()"
    />
    <button
      class="shorter-btn print-btn"
      (click)="print()"
      [disabled]="lockPrint"
    >
      <fa-icon [icon]="faPrint" class="mr-1" />Export
    </button>
  </div>
</div>
