<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Click & Drive Customer</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <app-text-input
      dataType="email"
      [placeholder]="'Enter customers email...'"
      (dataOutput)="email = $event"
    ></app-text-input>
    <button
      class="shorter-btn save-btn"
      [disabled]="isValidEmail(email)"
      (click)="GenerateVoucher()"
    >
      <fa-icon [icon]="faTag" class="mr-1"></fa-icon>Generate voucher
    </button>
    <button class="shorter-btn clear-btn" [routerLink]="'../'">
      <fa-icon [icon]="faUndo" class="mr-1"></fa-icon>Back to option
    </button>
  </div>
  <section class="content-container">
    <div class="flex flex-col gap-y-2">
      <label
        *ngFor="let item of items; let index = index"
        class="block cursor-pointer border-b p-2"
      >
        <input
          type="checkbox"
          class="checkbox-single mr-2"
          [(ngModel)]="items[index].Selected"
        />
        {{ item.Name }}
      </label>
    </div>
  </section>
</div>
