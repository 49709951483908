import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faTrash,
  faSearch,
  faPencil,
  faPrint,
  faEdit,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { PriceListMasterService } from "src/app/services/sales/price-list-master.service";
import { NotifierService } from "angular-notifier";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-price-list-master-list",
  templateUrl: "./price-list-master-list.component.html",
  styleUrls: ["./price-list-master-list.component.css"],
})
export class PriceListMasterListComponent implements OnInit {
  constructor(
    private priceListMasterService: PriceListMasterService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  searchInput: string = "";
  showModal: boolean = false;
  showPassenger: boolean = false;
  showCommercial: boolean = false;
  rawListPrices: any[] = [];
  rawSalesPrices: any[] = [];
  allListPrices: any[] = [];
  allSalesPrices: any[] = [];
  filteredListPrices: any[] = [];
  filteredSalesPrices: any[] = [];

  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;
  faPencil = faPencil;
  faPrint = faPrint;
  faEdit = faEdit;
  faCancel = faCancel;

  listPricesHeaders = [
    {
      title: "",
      type: "checkbox",
      id: "CHECKED",
    },
    {
      title: "MODEL SHORT DESC",
      type: "link",
      id: "MODELSHORTDEC",
    },
    {
      title: "MODEL GROUP",
      type: "data",
      id: "MODELGROUP",
    },
    {
      title: "MODEL LONG DESC",
      type: "data",
      id: "MODEL",
    },
    {
      title: "ENG. CAP",
      type: "data",
      id: "ENGINECAP",
    },
    {
      title: "SEQ",
      type: "data",
      id: "SEQ",
    },
    {
      title: "SUB SEQ",
      type: "data",
      id: "SUBSEQ",
    },
    {
      title: "CATEGORY",
      type: "data",
      id: "CATEGORY",
    },
    {
      title: "EFFECTIVE DATE",
      type: "data",
      id: "EFFECTIVEDATE",
    },
    {
      title: "ARF NET",
      type: "data",
      id: "ARFNET",
    },
    {
      title: "REG FEE",
      type: "data",
      id: "REGFEE",
    },
    {
      title: "COE OFF SET",
      type: "data",
      id: "COE",
    },
    {
      title: "6 MTHS ROAD TAX",
      type: "data",
      id: "ROADTAX",
    },
    {
      title: "PRICE",
      type: "data",
      id: "PRICE",
    },
    {
      title: "CNG PRICE",
      type: "data",
      id: "CNG",
    },
    {
      title: "",
      type: "hidden",
      id: "Id",
    },
  ];

  salesPricesHeaders = [
    {
      title: "MODEL SHORT DESCRIPTION",
      type: "data",
      id: "MODELSHORTDEC",
    },
    {
      title: "MODEL GROUP",
      type: "data",
      id: "MODELGROUP",
    },
    {
      title: "MODEL LONG DESC",
      type: "data",
      id: "MODEL",
    },
    {
      title: "ENG. CAP",
      type: "data",
      id: "ENGINECAP",
    },
    {
      title: "MODEL SUB SEQ",
      type: "data",
      id: "SUBSEQ",
    },
    {
      title: "CATEGORY",
      type: "data",
      id: "CATEGORY",
    },
    {
      title: "EFFECTIVE DATE",
      type: "data",
      id: "EFFECTIVEDATE",
    },
    {
      title: "ARF NET",
      type: "data",
      id: "ARFNET",
    },
    {
      title: "REG FEE",
      type: "data",
      id: "REGFEE",
    },
    {
      title: "COE OFF SET",
      type: "data",
      id: "COE",
    },
    {
      title: "6 MTHS ROAD TAX",
      type: "data",
      id: "ROADTAX",
    },
    {
      title: "PRICE",
      type: "data",
      id: "PRICE",
    },
    {
      title: "CNG PRICE",
      type: "data",
      id: "CNG",
    },
    {
      title: "",
      type: "hidden",
      id: "Id",
    },
  ];

  passengerRemarksForm: FormField[] = [
    {
      type: "text",
      id: "Remarks1",
      label: "Remarks 1",
    },
    {
      type: "text",
      id: "Remarks2",
      label: "Remarks 2",
    },
    {
      type: "text",
      id: "Remarks3",
      label: "Remarks 3",
    },
    {
      type: "text",
      id: "Remarks4",
      label: "Remarks 4",
    },
    {
      type: "text",
      id: "Remarks5",
      label: "Remarks 5",
    },
    {
      type: "text",
      id: "Remarks6",
      label: "Remarks 6",
    },
    {
      type: "text",
      id: "Remarks7",
      label: "Remarks 7",
    },
    {
      type: "text",
      id: "Remarks8",
      label: "Remarks 8",
    },
    {
      type: "text",
      id: "Remarks9",
      label: "Remarks 9",
    },
    {
      type: "text",
      id: "Remarks10",
      label: "Remarks 10",
    },
    {
      type: "text",
      id: "__type",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "Id",
      label: "",
      show: false,
    },
    {
      type: "switch",
      id: "IsCommRemark",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "CreatedBy",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "CreatedDate",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "LastModifiedBy",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "LastModifiedDate",
      label: "",
      show: false,
    },
  ];

  commercialRemarksForm: FormField[] = [
    {
      type: "text",
      id: "Remarks1",
      label: "Remarks 1",
    },
    {
      type: "text",
      id: "Remarks2",
      label: "Remarks 2",
    },
    {
      type: "text",
      id: "Remarks3",
      label: "Remarks 3",
    },
    {
      type: "text",
      id: "Remarks4",
      label: "Remarks 4",
    },
    {
      type: "text",
      id: "Remarks5",
      label: "Remarks 5",
    },
    {
      type: "text",
      id: "Remarks6",
      label: "Remarks 6",
    },
    {
      type: "text",
      id: "Remarks7",
      label: "Remarks 7",
    },
    {
      type: "text",
      id: "Remarks8",
      label: "Remarks 8",
    },
    {
      type: "text",
      id: "Remarks9",
      label: "Remarks 9",
    },
    {
      type: "text",
      id: "Remarks10",
      label: "Remarks 10",
    },
    {
      type: "text",
      id: "__type",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "Id",
      label: "",
      show: false,
    },
    {
      type: "switch",
      id: "IsCommRemark",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "CreatedBy",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "CreatedDate",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "LastModifiedBy",
      label: "",
      show: false,
    },
    {
      type: "text",
      id: "LastModifiedDate",
      label: "",
      show: false,
    },
  ];
  ngOnInit() {
    this.getAllList();
    this.getSalesPriceList();
  }

  private getAllList(): void {
    this.priceListMasterService.GetAll().subscribe(
      (response) => {
        const allowedKeys = this.listPricesHeaders.map(
          (header) => header.id,
        );
        this.rawListPrices = response.body;
        this.allListPrices = response.body.map((obj: any) => {
          const filteredObj: any = {};
          filteredObj["CHECKED"] = false;
          allowedKeys.forEach((key) => {
            if (obj.hasOwnProperty(key)) {
              filteredObj[key] = obj[key];
            }
          });
          return filteredObj;
        });
        this.filteredListPrices = [...this.allListPrices];
      },
      (error) => {
        console.error("Error fetching data:", error);
      },
    );
  }

  private getSalesPriceList(): void {
    this.priceListMasterService.GetSalesPriceList().subscribe(
      (response) => {
        const allowedKeys = this.salesPricesHeaders.map(
          (header) => header.id,
        );
        this.rawSalesPrices = response.body;
        this.allSalesPrices = response.body.map((obj: any) => {
          const filteredObj: any = {};
          allowedKeys.forEach((key) => {
            if (obj.hasOwnProperty(key)) {
              filteredObj[key] = obj[key];
            }
          });
          return filteredObj;
        });
        this.filteredSalesPrices = [...this.allSalesPrices];
      },
      (error) => {
        console.error("Error fetching data:", error);
      },
    );
  }

  openModal(mode: string, modal: boolean): void {
    switch (mode) {
      case "remove":
        const entries = this.filteredListPrices.filter(
          (item) => item.CHECKED == true,
        );
        if (entries.length == 0 && modal) {
          this.notifier.notify(
            "error",
            "Please, select at least one record to delete",
          );
          return;
        } else {
          this.showModal = modal;
          document.body.style.overflow = modal ? "hidden" : "auto";
        }
        break;
      case "passenger":
        this.getPassengerRemarks(modal);
        break;
      case "commercial":
        this.getCommercialRemarks(modal);
        break;
    }
  }

  getPassengerRemarks(modal: boolean): void {
    this.priceListMasterService.GetAllRemarks().subscribe({
      next: (response) => {
        Object.entries(response.body[0]).forEach(([key, value]) => {
          this.getField("passenger", key).value = value;
        });
        this.showPassenger = modal;
        document.body.style.overflow = modal ? "hidden" : "auto";
      },
      error: console.error,
    });
  }

  getCommercialRemarks(modal: boolean): void {
    this.priceListMasterService.GetAllRemarks().subscribe({
      next: (response) => {
        Object.entries(response.body[1]).forEach(([key, value]) => {
          this.getField("commercial", key).value = value;
        });
        this.showCommercial = modal;
        document.body.style.overflow = modal ? "hidden" : "auto";
      },
      error: console.error,
    });
  }

  saveRemarks(mode: string): void {
    let model: any = undefined;
    if (mode == "passenger") {
      model = this.passengerRemarksForm.reduce((acc: any, field) => {
        acc[field.id] = field.value;
        return acc;
      }, {});
    } else {
      model = this.commercialRemarksForm.reduce((acc: any, field) => {
        acc[field.id] = field.value;
        return acc;
      }, {});
    }
    this.priceListMasterService.UpdateRemarks(model).subscribe({
      next: () => {
        this.notifier.notify(
          "success",
          "Remarks has been updated successfully",
        );
        this.openModal("passenger", false);
        this.openModal("commercial", false);
      },
      error: console.error,
    });
  }

  removeEntry(): void {
    const selectedItems = this.rawListPrices.filter((item) => {
      return this.filteredListPrices
        .filter((innerItem) => innerItem.CHECKED)
        .map((innerItem) => innerItem.Id)
        .includes(item.Id);
    });
    this.priceListMasterService.Delete(selectedItems).subscribe({
      next: () => {
        this.notifier.notify(
          "success",
          "Selected items deleted successfully",
        );
        this.filteredListPrices = this.filteredListPrices.filter(
          (item) => !item.CHECKED,
        );
        this.openModal("remove", false);
      },
      error: (error) => {
        console.error("Error deleting records:", error);
        this.openModal("remove", false);
      },
    });
  }

  addEntry(): void {
    this.router.navigate(["../price-list-master", "0"], {
      relativeTo: this.route,
    });
  }

  openEntry(value: any) {
    this.router.navigate(["../price-list-master", value.Id], {
      relativeTo: this.route,
    });
  }

  print(): void {
    this.priceListMasterService.Print().subscribe({
      next: (response) => window.open(response.body.Name, "_blank"),
      error: console.error,
    });
  }

  private filterItems(
    items: any[],
    searchValue: string,
    keys: string[],
  ): any[] {
    const lowerSearchValue = searchValue.toLowerCase();
    return items.filter((item) =>
      keys.some((key) =>
        String(item[key]).toLowerCase().includes(lowerSearchValue),
      ),
    );
  }

  onSearch(searchValue: string): void {
    const commonKeys = [
      "MODELSHORTDEC",
      "MODELGROUP",
      "MODEL",
      "ENGINECAP",
      "SEQ",
      "SUBSEQ",
      "CATEGORY",
      "EFFECTIVEDATE",
      "ARFNET",
      "REGFEE",
      "COE",
      "ROADTAX",
      "PRICE",
      "CNG",
    ];

    this.filteredListPrices = this.filterItems(
      this.allListPrices,
      searchValue,
      commonKeys,
    );

    this.filteredSalesPrices = this.filterItems(
      this.allSalesPrices,
      searchValue,
      commonKeys,
    );
  }

  getField(form: string, id: string): any {
    if (form == "passenger") {
      const field = this.passengerRemarksForm.find(
        (f) => f.id === id,
      );
      return field;
    } else if (form == "commercial") {
      const field = this.commercialRemarksForm.find(
        (f) => f.id === id,
      );
      return field;
    }
  }
}
