import { Component, OnInit, Input } from "@angular/core";
import { formatDate } from "@angular/common";
import {
  faBroom,
  faPlus,
  faEdit,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { BookingService } from "src/app/services/vehicle/booking.service";
import { NotifierService } from "angular-notifier";
import { from, of, switchMap, tap } from "rxjs";
import {
  finance,
  user,
  model,
  payment,
  Container,
  MonthName,
  accessory,
  promotion,
  option,
  colour,
  active,
  deposit,
  updateForm,
} from "src/app/models/vehicle/booking";

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.css"],
})
export class BookingComponent implements OnInit {
  // ICONS
  faPlus = faPlus;
  faBroom = faBroom;
  faEdit = faEdit;
  faFilePdf = faFilePdf;

  hasData: boolean = false;
  hasContract: boolean = false;
  showCancel: boolean = false;
  CancelRemarks: string = "";
  showAcceptance: boolean = false;
  enableAcceptance: boolean = false;
  canUpload: boolean = false;
  IsReservation: boolean = false;

  //Variable used for print requests, is RefNo property of GetById response
  globalId: string = "";

  constructor(
    private BookingService: BookingService,
    private notifier: NotifierService,
  ) {
    this.Forms = {
      SearchBooking: [],
      BuyerDetails: [],
      VehicleDetails: [],
      DetailsCashDiscount: [],
      DetailsDeposit: [],
      DetailsOtherFees: [],
      DetailsInsurance: [],
      DetailsHireApplication: [],
      DetailsTradeInVehicle: [],
      KomocosAccount_Promotion: [],
      KomocosAccount_Accessories: [],
      BuyersAccount_NonAccessories: [],
      BuyersAccount_Accessories: [],
      SalesmanAccount_NonAccessories: [],
      SalesmanAccount_Accessories: [],
      AdditionalRemarks: [],
    };
  }

  @Input() Forms!: {
    [key: string]: FormField[];
  };

  TradeInVehicleDealers: any[] = [];
  ExpectedDeliveryList: any[] = [];
  RoyaltyDiscount: any[] = [];
  UserDrpList: any[] = [];
  FinanceList: any[] = [];

  // These have valuable data from API's, they are NOT displayed directly in the inputs
  BuyerId: number = 0;
  SalesmanId: string = "";
  SalesmanCode: string | null = null;
  QuotationCustomerId: string = "";
  ModelListForBooking: model[] = [];

  // These two arrays contain the available items in promotions and accessories inputs
  AccessoryList: accessory[] = [];
  PromotionList: promotion[] = [];

  ColoursInStock: colour[] = [];
  AllActiveList: active[] = [];
  ActiveDrpList: any[] = [];
  PurchaseHistory: any[] = [];

  //These is data that will be displayed in the tables of promotions and accessories
  AvailabilityHeaders = [
    {
      title: "EXTERIOR COLOUR",
      type: "data",
      classes: "underline",
    },
    {
      title: "TYPE",
      type: "data",
    },
    {
      title: "INT COLOUR",
      type: "data",
    },
    {
      title: "IN STOCK",
      type: "data",
      classes: "text-center",
    },
    {
      title: "ETA (MID OCT)",
      type: "data",
      classes: "text-center",
    },
    {
      title: "ETA (MID NOV)",
      type: "data",
      classes: "text-center",
    },
    {
      title: "ETA (MID DIC)",
      type: "data",
      classes: "text-center",
    },
    {
      title: "ETA (MID JAN)",
      type: "data",
      classes: "text-center",
    },
    {
      title: "ETA (MID FEB)",
      type: "data",
      classes: "text-center",
    },
  ];
  AvailabilityEntries: any[] = [];
  HistoryHeaders = [
    {
      title: "CONTRACT NO",
      type: "data",
    },
    {
      title: "NAME AS IN NRIC/PASSPORT",
      type: "data",
    },
    {
      title: "NRIC NO/RCB NO/PASSPORT NO",
      type: "data",
    },
    {
      title: "REGISTER DATE",
      type: "data",
    },
    {
      title: "REGISTER NUMBER",
      type: "data",
    },
    {
      title: "MODEL",
      type: "data",
    },
  ];
  HistoryEntries: any[] = [];
  DepositHeaders = [
    {
      title: "PAYMENT TYPE",
      type: "data",
    },
    {
      title: "LISTED BANK",
      type: "data",
    },
    {
      title: "OTHER BANK",
      type: "data",
    },
    {
      title: "AMOUNT",
      type: "data",
    },
    {
      title: "CHEQUE NO",
      type: "data",
    },
    {
      title: "TERMINAL NO",
      type: "data",
    },
    {
      title: "BATCH NO",
      type: "data",
    },
    {
      title: "INVOICE NO",
      type: "data",
    },
    {
      title: "RECEIPT NO",
      type: "data",
    },
    {
      title: "DEPOSIT DATE",
      type: "data",
    },
    {
      title: "ACTION",
      type: "trash",
    },
    {
      title: "",
      type: "hidden",
    },
  ];
  DepositEntries: any[] = [];
  PromotionsHeaders = [
    {
      title: "PROMOTION",
      type: "data",
    },
    {
      title: "AMOUNT",
      type: "data",
      Hclasses: "text-center",
      classes: "text-center",
    },
    {
      title: "ACTION",
      type: "trash",
      Hclasses: "text-center",
      classes: "text-center",
    },
    {
      title: "",
      type: "hidden",
    },
  ];
  PromotionsEntries: any[] = [];
  AccessoriesHeaders = [
    {
      title: "ACCESSORY",
      type: "data",
    },
    {
      title: "AMOUNT",
      type: "data",
      Hclasses: "text-center",
      classes: "text-center",
    },
    {
      title: "ACTION",
      type: "trash",
      Hclasses: "text-center",
      classes: "text-center",
    },
    {
      title: "",
      type: "hidden",
    },
  ];
  AccessoriesKomocoEntries: any = [];
  ItemsHeaders = [
    {
      title: "ITEM",
      type: "data",
    },
    {
      title: "AMOUNT",
      type: "data",
      Hclasses: "text-center",
      classes: "text-center",
    },
    {
      title: "ACTION",
      type: "trash",
      Hclasses: "text-center",
      classes: "text-center",
    },
    {
      title: "",
      type: "hidden",
    },
  ];
  ItemsBuyerEntries: any = [];
  ItemsSalesmanEntries: any = [];

  //These are arrays that will be filled when an order is updated, deposits, proms, accessories/items
  KomocoItems: any[] = []; // AccessoriesKomocoEntries
  BuyerItems: any[] = []; // ItemsBuyerEntries
  SalesConsultantItems: any[] = []; // ItemsSalesmanEntries

  ngOnInit(): void {
    this.fetchFormsData().subscribe({
      next: () => this.setUpFormFields(),
      error: (error) => {
        console.log(error);
        this.notifier.notify("error", "Error initializing data");
      },
    });
    if (this.showAcceptance) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  //Fills some select inputs when page is loaded
  private fetchFormsData() {
    return of(null).pipe(
      switchMap(() =>
        this.BookingService.GetTradeInVehicleDealers().pipe(
          tap((response) =>
            this.processTradeInVehicleDealers(response.body),
          ),
          switchMap(() =>
            this.BookingService.GetModelListForBooking(),
          ),
          tap((response) =>
            this.processModelListForBooking(response.body),
          ),
          switchMap(() =>
            this.BookingService.GetExpectedDeliveryList(),
          ),
          tap((response) =>
            this.processExpectedDeliveryList(response.body),
          ),
          switchMap(() => this.BookingService.GetFinanceList()),
          tap((response) => this.processFinanceList(response.body)),
          switchMap(() => this.BookingService.GetAllActiveList()),
          tap((response) => this.processAllActiveList(response.body)),
          switchMap(() => this.BookingService.GetActiveDrpList()),
          tap((response) => this.processActiveDrpList(response.body)),
          switchMap(() => this.BookingService.GetRoyaltyDiscount()),
          tap((response) =>
            this.processRoyaltyDiscount(response.body),
          ),
          switchMap(() => this.BookingService.GetUserDrpList()),
          tap((response) => this.processUserDrpList(response.body)),
        ),
      ),
    );
  }

  //#region Process Responses

  private processTradeInVehicleDealers(
    responseBody: { id: string; name: string }[],
  ): void {
    this.TradeInVehicleDealers = responseBody.map((model) => ({
      display: model.name,
      value: model.id,
      id: model.id,
    }));
  }

  private processModelListForBooking(responseBody: model[]): void {
    this.ModelListForBooking = responseBody.filter(
      (model) => model.DisplaySales == true,
    );
  }

  private processExpectedDeliveryList(responseBody: string[]): void {
    this.ExpectedDeliveryList = responseBody.map((model) => ({
      display: model,
      value: model,
    }));
  }

  private processFinanceList(responseBody: finance[]): void {
    this.FinanceList = responseBody.map((model) => ({
      display: `${model.Name}(${model.Value})`,
      value: model.Value,
    }));
  }

  private processAllActiveList(responseBody: payment[]): void {
    this.AllActiveList = responseBody;
  }

  private processActiveDrpList(responseBody: finance[]): void {
    this.ActiveDrpList = responseBody;
  }

  private processRoyaltyDiscount(responseBody: number[]): void {
    this.RoyaltyDiscount = responseBody.map((model) => ({
      display: model,
      value: model,
    }));
  }

  private processUserDrpList(responseBody: user[]): void {
    this.UserDrpList = responseBody.map((model: user) => ({
      display: model.Name,
      value: model.Value,
      id: model.Value,
    }));
  }

  private processDataForSearch(responseBody: any): void {
    this.setFormFieldValue(
      "RefDate",
      "SearchBooking",
      responseBody.RefDate,
    );
    this.setFormFieldValue(
      "RefNo",
      "SearchBooking",
      responseBody.RefNo,
    );
    this.setFormFieldValue(
      "ContractNo",
      "SearchBooking",
      responseBody.ContractNo,
    );
    this.setFormFieldValue(
      "BookingUserType",
      "SearchBooking",
      responseBody.Item.BookingUserType,
    );
  }

  private processDataForBuyer(responseBody: any): void {
    const fieldsToSet = [
      "AddressBlock",
      "AddressRoad",
      "AddressUnit",
      "ContactNo",
      "CustomerName",
      "DoNotReceive",
      "Dob",
      "Email",
      "IcNo",
      "ModeOfContact",
      "Occupation",
      "OfficeNo",
      "PostalCode",
      "ResidenceNo",
      "Title",
    ];

    fieldsToSet.forEach((field) => {
      this.setFormFieldValue(
        field,
        "BuyerDetails",
        field === "Dob"
          ? this.formatDateToField(responseBody.BuyerDetails[field])
          : responseBody.BuyerDetails[field],
      );
    });

    const consentOptions = [
      "AgreeDirectMail",
      "AgreeMail",
      "AgreePhone",
      "AgreeSms",
    ];

    consentOptions.forEach((option) => {
      this.getField("ModeOfConsent", "BuyerDetails").options.find(
        (item: any) => item.value === option,
      ).marked = responseBody.BuyerDetails[option];
    });
  }

  async processDataForVehicle(responseBody: any) {
    const selectedModel = this.ModelListForBooking.find(
      (model) =>
        model.ModelMasterId == responseBody.VehicleDetails.ModelName,
    );

    this.setFormFieldValue(
      "ModelName",
      "VehicleDetails",
      selectedModel?.ModelShortDesc ?? "",
    );

    await Promise.all([
      this.GetStockAvailability(),
      this.SetModelCategoryAndCoe(),
      this.GetDataOnModelChange(),
    ]);

    const COEType = responseBody.VehicleDetails.COEType;
    const newCOEType = this.getField(
      "COEType",
      "VehicleDetails",
    ).options.find((item: any) => item.display === COEType)?.value;
    /* console.log(
      COEType,
      typeof(COEType),
      this.getField("COEType", "VehicleDetails").options,
    ); */

    this.setFormFieldValue("COEType", "VehicleDetails", newCOEType);

    this.getField(
      "ExpectedDeliveryDate",
      "VehicleDetails",
    ).options.push({
      display: responseBody.VehicleDetails.ExpectedDeliveryDate,
      value: responseBody.VehicleDetails.ExpectedDeliveryDate,
    });

    const fieldsToSet = [
      "IntColour",
      "CarType",
      "Colour",
      "SpecialType",
      "Category",
      "InvoicePrice",
      "VehiclePrice",
      "ExpectedDeliveryDate",
    ];

    fieldsToSet.forEach((field) => {
      this.setFormFieldValue(
        field,
        "VehicleDetails",
        responseBody.VehicleDetails[field],
      );
    });
  }

  private processDataForCashDiscount(responseBody: any) {
    this.setFormFieldValue(
      "DiscountAmount",
      "DetailsCashDiscount",
      responseBody.Item.DiscountAmount,
    );
    this.setFormFieldValue(
      "RoyaltyDiscount",
      "DetailsCashDiscount",
      responseBody.Item.RoyaltyDiscount,
    );
  }

  private processDataForDeposit(responseBody: any) {
    this.DepositEntries = responseBody.DepositList.map(
      ({
        PaymentType,
        Bank = null,
        OtherBank = null,
        DepositAmount,
        SlipNo = null,
        TerminalNo = null,
        BatchNo = null,
        InvoiceNo = null,
        ReceiptNo = "",
        DepositDate,
        Id = null,
      }: deposit) => ({
        PaymentType,
        Bank,
        OtherBank,
        DepositAmount,
        SlipNo,
        TerminalNo,
        BatchNo,
        InvoiceNo,
        ReceiptNo,
        DepositDate,
        Trash: "",
        Id,
      }),
    );
  }

  private processDataForOtherFees(responseBody: any) {
    const fields = [
      "lessCoeRebate",
      "COEOffSet",
      "COETopUp",
      "SurchargeOrRebate",
      "SpotterCommision",
      "IntroAddress",
      "IntroContractNo",
      "IntroNRICNo",
      "IntroName",
    ];

    fields.forEach((field) => {
      this.setFormFieldValue(
        field,
        "DetailsOtherFees",
        responseBody.OtherFees[field],
      );
    });

    const showFields = fields
      .slice(-4)
      .some((field) => responseBody.OtherFees[field]);

    fields.slice(-4).forEach((field) => {
      this.getField(field, "DetailsOtherFees").show = showFields;
    });
  }

  private processDataForInsurance(responseBody: any) {
    const company = responseBody.Item.InsuranceCompany;
    this.setFormFieldValue(
      "InsuranceCompany",
      "DetailsInsurance",
      company,
    );
  }

  private processDataForHireApplication(responseBody: any) {
    const financeCompanies = [
      "FinanceCompany1",
      "FinanceCompany2",
      "FinanceCompany3",
      "FinanceCompany4",
    ];

    financeCompanies.forEach((company, index) => {
      const value = responseBody.HirePurchaseDetails[company];
      if (value !== null) {
        this.setFormFieldValue(
          company,
          "DetailsHireApplication",
          value.split(",").pop(),
        );
        if (index === 0) {
          this.filterFinanceCompanies();
        }
      }
    });

    const details = [
      "FP",
      "LoanAmount",
      "LoanPeriod",
      "LoanRate",
      "MonthlyInstallment",
      "LoanStatus",
    ];

    details.forEach((field) => {
      this.setFormFieldValue(
        field,
        "DetailsHireApplication",
        responseBody.HirePurchaseDetails[field],
      );
    });

    this.setFormFieldValue(
      "LoanStatusDate",
      "DetailsHireApplication",
      this.formatDateToField(
        responseBody.HirePurchaseDetails.LoanStatusDate,
      ),
    );
    this.setFormFieldValue(
      "LoanSubmittedDate",
      "DetailsHireApplication",
      this.formatDateToField(
        responseBody.HirePurchaseDetails.LoanSubmittedDate,
      ),
    );
  }

  private processDataTradeInVehicle(responseBody: any) {
    const dealerName = Number(
      responseBody.TradeInVehicleDetails.DealerName,
    );
    this.setFormFieldValue(
      "DealerName",
      "DetailsTradeInVehicle",
      dealerName,
    );

    const fieldsToShow = [
      "Make",
      "YOM",
      "OldVehicleNumber",
      "Model",
      "Overtrade",
      "PurchasePriceUsedVehicle",
    ];

    fieldsToShow.forEach((field) => {
      const show = dealerName !== 1;
      this.getField(field, "DetailsTradeInVehicle").show = show;
      this.setFormFieldValue(
        field,
        "DetailsTradeInVehicle",
        responseBody.TradeInVehicleDetails[field],
      );
    });
  }

  private async processDataDetailsAccount(
    responseBody: any,
  ): Promise<void> {
    const filterAndMapItems = (
      items: any[],
      filterFn: (item: any) => boolean,
      mapFn: (item: any) => any,
    ) => {
      return items.filter(filterFn).map(mapFn);
    };

    if (responseBody.KomocoItems.length > 0) {
      this.PromotionsEntries = filterAndMapItems(
        responseBody.KomocoItems,
        (promItem) => promItem.Value == 0,
        (promItem) => ({
          promotion: promItem.Name,
          amount: promItem.Value,
          trash: "",
          id: promItem.Id,
        }),
      );

      this.KomocoItems = responseBody.KomocoItems.filter(
        (komocoItem: any) => komocoItem.Value != 0,
      ).map((item: any) => ({
        Allowed: true,
        Id: item.Id,
        Name: item.Name,
        Value: item.Value,
      }));
      this.AccessoriesKomocoEntries = filterAndMapItems(
        responseBody.KomocoItems,
        (komocoItem) => komocoItem.Value != 0,
        (komocoItem) => ({
          promotion: komocoItem.Name,
          amount: komocoItem.Value,
          trash: "",
          id: komocoItem.Id,
        }),
      );

      this.CalculateKomocoPrice();
    }

    if (responseBody.BuyerItems.length > 0) {
      this.BuyerItems = responseBody.BuyerItems;
      this.ItemsBuyerEntries = filterAndMapItems(
        responseBody.BuyerItems,
        () => true,
        (item) => ({
          item: item.Name,
          amount: item.Value,
          trash: "",
          id: item.Id,
        }),
      );

      this.CalculateBuyerPrice();
    }

    if (responseBody.SalesConsultantItems.length > 0) {
      this.ItemsSalesmanEntries = responseBody.SalesConsultantItems;
      this.ItemsSalesmanEntries = filterAndMapItems(
        responseBody.SalesConsultantItems,
        () => true,
        (item) => ({
          item: item.Name,
          amount: item.Value,
          trash: "",
          id: item.Id,
        }),
      );

      this.CalculateSalesmanPrice();
    }
  }

  private processDataAdditionalRemarks(responseBody: any) {
    this.setFormFieldValue(
      "Remarks",
      "AdditionalRemarks",
      responseBody.Remarks,
    );
    const SalesMan = this.UserDrpList.find(
      (active) => active.id == responseBody.SalesMan,
    );
    if (SalesMan) {
      this.setFormFieldValue(
        "SalesMan",
        "AdditionalRemarks",
        SalesMan.value,
      );
    }
  }

  private processGetProspectByIcno(responseBody: any) {
    this.globalId = responseBody.Id;
    this.QuotationCustomerId = responseBody.QuotationCustomerId;
    this.processPurchaseHistory(responseBody.PurchaseHistory);

    const fieldsToSet = [
      "AddressBlock",
      "AddressRoad",
      "AddressUnit",
      "ContactNo",
      "CustomerName",
      "DoNotReceive",
      "Dob",
      "Email",
      "IcNo",
      "ModeOfContact",
      "Occupation",
      "OfficeNo",
      "PostalCode",
      "ResidenceNo",
      "Title",
    ];

    fieldsToSet.forEach((field) =>
      this.setFormFieldValue(
        field,
        "BuyerDetails",
        field === "Dob"
          ? this.formatDateToField(responseBody[field])
          : responseBody[field],
      ),
    );

    [
      "AgreeDirectMail",
      "AgreeMail",
      "AgreePhone",
      "AgreeSms",
    ].forEach((option) => {
      const consentField = this.getField(
        "ModeOfConsent",
        "BuyerDetails",
      ).options.find((item: any) => item.value === option);
      if (consentField) consentField.marked = responseBody[option];
    });

    this.setFormFieldValue(
      "RoyaltyDiscount",
      "DetailsCashDiscount",
      responseBody.RoyaltyDiscount,
    );
    this.setFormFieldValue(
      "SalesMan",
      "AdditionalRemarks",
      responseBody.SalesmanCode,
    );
  }

  processPurchaseHistory(PurchaseHistory: any[]) {
    this.PurchaseHistory = PurchaseHistory;
    this.HistoryEntries = PurchaseHistory.map((item) => ({
      ContractNo: item.ContractNo,
      CustomerName: item.CustomerName,
      IcNo: item.IcNo,
      RegisterDate: item.RegisterDate,
      RegisterNumber: item.RegisterNumber,
      ModelShortDesc: item.ModelShortDesc,
    }));
  }

  //#endregion

  //#region Setup FormFields

  private setUpFormFields(): void {
    this.Forms["SearchBooking"] = [
      {
        type: "search",
        id: "RefNo",
        label: "Buyer's order number",
        searchEvent: true,
      },
      {
        type: "text",
        id: "RefDate",
        label: "Buyer's Order Date",
        lock: true,
      },
      {
        type: "search",
        id: "ContractNo",
        label: "Contract Number",
        searchEvent: true,
      },
      {
        type: "search",
        id: "QuotationNumber",
        label: "Quotation Number",
        searchEvent: true,
      },
      {
        type: "radio",
        id: "BookingUserType",
        label: "Customer Type",
        options: [
          {
            display: "Local",
            value: 1,
          },
          {
            display: "Company",
            value: 2,
          },
          {
            display: "Foraigner",
            value: 3,
          },
        ],
        value: 1,
      },
    ];
    this.Forms["BuyerDetails"] = [
      {
        type: "search",
        id: "IcNo",
        label: "NRIC No/RCB No/Passport No",
        required: true,
        searchEvent: true,
      },
      {
        type: "select",
        id: "Title",
        label: "Title",
        options: [
          { display: "MR", value: "MR" },
          { display: "MRS", value: "MRS" },
          { display: "MISS", value: "MISS" },
          { display: "M/S", value: "M/S" },
          { display: "MDM", value: "MDM" },
          { display: "DR", value: "DR" },
          { display: "MS", value: "MS" },
        ],
      },
      {
        type: "text",
        id: "CustomerName",
        label: "Name as in NRIC/Passport",
        required: true,
      },
      {
        type: "text",
        id: "ContactNo",
        label: "Mobile Number",
        required: true,
      },
      { type: "text", id: "ResidenceNo", label: "Home tel number" },
      {
        type: "text",
        id: "OfficeNo",
        label: "Office Tel Tumber",
      },
      {
        type: "text",
        id: "AddressBlock",
        label: "Block/House",
        required: true,
      },
      {
        type: "text",
        id: "AddressRoad",
        label: "Road",
        required: true,
      },
      { type: "text", id: "AddressUnit", label: "Unit" },
      {
        type: "search",
        id: "PostalCode",
        label: "Postal Code",
        required: true,
        searchEvent: true,
      },
      {
        type: "text",
        id: "Email",
        label: "Email",
        required: true,
      },
      {
        type: "date",
        id: "Dob",
        label: "Date of Birth (dd/mm/yyyy)",
        required: true,
      },
      {
        type: "text",
        id: "Occupation",
        label: "Occupation",
        required: true,
      },
      {
        type: "select",
        id: "ModeOfContact",
        label: "Mode of Contact",
        required: true,
        options: [
          {
            display: "Referral",
            value: "REF",
          },
          {
            display: "Repeat",
            value: "REPEAT",
          },
          {
            display: "Walk-in",
            value: "WI",
          },
          {
            display: "Postale",
            value: "POSTALE",
          },
        ],
      },
      {
        type: "checkbox",
        id: "ModeOfConsent",
        label: "Mode of consent",
        options: [
          {
            display: "Direct Mailer",
            value: "AgreeDirectMail",
            marked: false,
          },
          {
            display: "Mail",
            value: "AgreeMail",
            marked: false,
          },
          {
            display: "SMS/MMS",
            value: "AgreeSms",
            marked: false,
          },
          {
            display: "Phone Call",
            value: "AgreePhone",
            marked: false,
          },
        ],
      },
      {
        type: "formCheckbox",
        id: "DoNotReceive",
        label:
          "I do not wish to receive any communication from Komoco Motors Pte Ltd and/or its partners.",
        value: true,
      },
    ];
    this.Forms["VehicleDetails"] = [
      {
        type: "select",
        id: "ModelName",
        label: "Model",
        required: true,
        options: this.ModelListForBooking.map((item: model) => ({
          display: item.ModelShortDesc,
          value: item.ModelShortDesc,
          id: item.ModelMasterId,
        })),
      },
      {
        type: "select",
        id: "Colour",
        label: "Colour",
        required: true,
        options: [],
      },
      {
        type: "select",
        id: "IntColour",
        label: "Interior colour",
        required: true,
        options: [],
      },
      {
        type: "select",
        id: "CarType",
        label: "Car type",
        required: true,
        options: [
          { display: "Stantard", value: "NC" },
          {
            display: "Off Peak Car (OPC)",
            value: "OPC",
          },
          {
            display: "Compressed Natural Gas (CNG)",
            value: "CNG",
          },
          {
            display: "CNG OPC",
            value: "CNG OPC",
          },
          {
            display: "Special",
            value: "Special",
          },
        ],
      },
      {
        type: "select",
        id: "COEType",
        label: "COE type",
        required: true,
        options: [],
      },
      {
        type: "select",
        id: "SpecialType",
        label: "Special Type",
        required: true,
        show: false,
        options: [
          { display: "LUCKY DRAW", value: "LUCKYDRAW" },
          { display: "DIPLOMAT", value: "DIPLOMAT" },
          { display: "US ARMY", value: "USARMY" },
          { display: "HANDICAP", value: "HANDICAP" },
          { display: "HANDICAP P PLATE", value: "HANDICAP P PLATE" },
          {
            display: "HANDICAP S/P PLATE VWO",
            value: "HANDICAP S/P PLATE VWO",
          },
          {
            display: "OWN COE",
            value: "OWNCOE",
          },
        ],
      },
      {
        type: "text",
        id: "Category",
        label: "Category",
        lock: true,
      },
      {
        type: "number",
        id: "InvoicePrice",
        label: "Invoice price ($)",
        required: true,
        value: 0,
      },
      {
        type: "number",
        id: "VehiclePrice",
        label: "Nett Selling Price ($)",
        required: true,
        value: 0,
      },
      {
        type: "select",
        id: "ExpectedDeliveryDate",
        label: "Expected delivery date",
        required: true,
        options: this.ExpectedDeliveryList,
      },
      {
        type: "const",
        id: "RoadTaxPeriod",
        label: "Road tax period",
        value: 6,
      },
    ];
    this.Forms["DetailsCashDiscount"] = [
      {
        type: "number",
        id: "DiscountAmount",
        label: "Cash discount $",
      },
      {
        type: "select",
        id: "RoyaltyDiscount",
        label: "Loyalty discount $",
        options: this.RoyaltyDiscount,
      },
    ];
    this.Forms["DetailsDeposit"] = [
      {
        type: "select",
        id: "PaymentType",
        label: "Payment Type",
        options: [
          {
            display: "No deposit",
            value: "NO DEPOSIT",
          },
          {
            display: "CASH",
            value: "CASH",
          },
          {
            display: "CHEQUE",
            value: "CHEQUE",
          },
          {
            display: "NETS",
            value: "NETS",
          },
          {
            display: "VISA/MASTER",
            value: "VISA/MASTER",
          },
          {
            display: "AMEX",
            value: "AMEX",
          },
          {
            display: "OTHERS",
            value: "OTHERS",
          },
        ],
      },
      {
        type: "number",
        id: "Amount",
        label: "Amount $",
        show: true,
      },
      {
        type: "select",
        id: "Bank",
        label: "Bank",
        show: false,
        required: true,
        options: [
          {
            display: "DBS/POSB",
            value: "DBS/POSB",
          },
          {
            display: "OCBC",
            value: "OCBC",
          },
          {
            display: "UOB",
            value: "UOB",
          },
          {
            display: "MAYBANK",
            value: "MAYBANK",
          },
          {
            display: "STANDARD CHARTERED BANK",
            value: "STANDARD CHARTERED BANK",
          },
          {
            display: "CITIBANK",
            value: "CITIBANK",
          },
          {
            display: "OTHERS",
            value: "OTHERS",
          },
        ],
      },
      {
        type: "text",
        id: "OtherBank",
        label: "Please Specify",
        show: false,
        required: true,
      },
      {
        type: "number",
        id: "ChequeNumber",
        label: "Cheque number",
        show: false,
        required: true,
      },
      {
        type: "select",
        id: "TerminalNumber",
        label: "Terminal number",
        show: false,
        options: [],
      },
      {
        type: "number",
        id: "BatchNumber",
        label: "Batch number",
        show: false,
      },
      {
        type: "number",
        id: "InvoiceNumber",
        label: "Invoice number",
        show: false,
      },
      {
        type: "text",
        id: "ReceiptNumber",
        label: "Receipt number",
        show: false,
        required: true,
      },
      {
        type: "date",
        id: "DepositDate",
        label: "Deposit Date",
        show: true,
      },
    ];
    this.Forms["DetailsOtherFees"] = [
      {
        type: "number",
        id: "COEOffSet",
        label: "COE Offset (rebate level)",
      },
      {
        type: "number",
        id: "COETopUp",
        label: "COE top up",
      },
      {
        type: "number",
        id: "SurchargeOrRebate",
        label: "VES surcharge/rebate",
      },
      {
        type: "number",
        id: "SpotterCommision",
        label: "Spotter's commision",
      },
      {
        type: "text",
        id: "IntroName",
        label: "Spotter's Name",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "IntroNRICNo",
        label: "Spotter's NRIC",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "IntroAddress",
        label: "Spotter's Address",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "IntroContractNo",
        label: "Spotter's Contact No",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "lessCoeRebate",
        label: "",
        show: false,
      },
    ];
    this.Forms["DetailsInsurance"] = [
      {
        type: "select",
        id: "InsuranceCompany",
        label: "Insurance Company",
        required: true,
        options: this.ActiveDrpList.map((model) => ({
          display: model.Name,
          value: model.Value,
        })),
      },
    ];
    this.Forms["DetailsHireApplication"] = [
      {
        type: "select",
        id: "FinanceCompany1",
        label: "Finance Company 1",
        options: [
          {
            display: "No Finance (NF)",
            value: "NF",
          },
          ...this.FinanceList,
        ],
        value: "NF",
      },
      {
        type: "formCheckbox",
        id: "FP",
        label: "FP",
      },
      {
        type: "number",
        id: "LoanAmount",
        label: "Loan Amount ($)",
      },
      {
        type: "select",
        id: "LoanPeriod",
        label: "Loan Period (months)",
        options: [
          {
            display: "0",
            value: 0,
          },
          {
            display: "12",
            value: 12,
          },
          {
            display: "24",
            value: 24,
          },
          {
            display: "48",
            value: 48,
          },
          {
            display: "60",
            value: 60,
          },
          {
            display: "72",
            value: 72,
          },
          {
            display: "84",
            value: 84,
          },
          {
            display: "96",
            value: 96,
          },
          {
            display: "108",
            value: 108,
          },
          {
            display: "116",
            value: 116,
          },
          {
            display: "118",
            value: 118,
          },
          {
            display: "120",
            value: 120,
          },
        ],
      },
      {
        type: "number",
        id: "LoanRate",
        label: "Loan Rate",
      },
      {
        type: "text",
        id: "MonthlyInstallment",
        label: "Monthly Installment ($)",
        lock: true,
      },
      {
        type: "select",
        id: "FinanceCompany2",
        label: "Finance Company 2",
        lock: true,
        options: this.FinanceList,
      },
      {
        type: "select",
        id: "FinanceCompany3",
        label: "Finance Company 3",
        lock: true,
        options: this.FinanceList,
      },
      {
        type: "select",
        id: "FinanceCompany4",
        label: "Finance Company 4",
        lock: true,
        options: this.FinanceList,
      },
      {
        type: "text",
        id: "LoanStatus",
        label: "Loan Status",
      },
      {
        type: "date",
        id: "LoanStatusDate",
        label: "Loan Status Date",
      },
      {
        type: "date",
        id: "LoanSubmittedDate",
        label: "Loan Submitted Date",
      },
    ];
    this.Forms["DetailsTradeInVehicle"] = [
      {
        type: "select",
        id: "DealerName",
        label: "Dealer Name",
        options: this.TradeInVehicleDealers,
        value: 1,
      },
      {
        type: "text",
        id: "Make",
        label: "Make",
        required: true,
        show: false,
      },
      {
        type: "number",
        id: "YOM",
        label: "Manufacture year",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "OldVehicleNumber",
        label: "Vehicle number",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "Model",
        label: "Model",
        required: true,
        show: false,
      },
      {
        type: "number",
        id: "Overtrade",
        label: "Komoco overtrade subsidy ($)",
        required: true,
        show: false,
      },
      {
        type: "number",
        id: "PurchasePriceUsedVehicle",
        label: "Total purchase price of used vehicle",
        required: true,
        show: false,
      },
    ];
    this.Forms["KomocosAccount_Promotion"] = [
      {
        type: "add",
        id: "InsurancePremium",
        label: "Insurance Premium ($)",
      },
      {
        type: "formCheckbox",
        id: "RoadTax",
        label: "Additional 6 Months Tax Road",
        /* lock: true, */
        value: false,
      },
      {
        type: "select",
        id: "Promotion",
        label: "Promotion (free items)",
        options: [],
      },
      {
        type: "add",
        id: "Price",
        label: "Price ($)",
        admitsUndefined: true,
      },
    ];
    this.Forms["KomocosAccount_Accessories"] = [
      {
        type: "select",
        id: "Accessory",
        label: "Accessory",
        options: [],
      },
      {
        type: "add",
        id: "Price",
        label: "Price ($)",
        admitsUndefined: true,
      },
      {
        type: "const",
        id: "Total",
        label: "Total Amount in Komoco's Account ($)",
        value: 0,
      },
    ];
    this.Forms["BuyersAccount_NonAccessories"] = [
      {
        type: "add",
        id: "InsurancePremium",
        label: "Insurance premium ($)",
      },
      {
        type: "add",
        id: "AdminFee",
        label: "Admin fee for Overtrade ($)",
      },
      {
        type: "add",
        id: "FirstInstallment",
        label: "First Installment ($)",
      },
      {
        type: "formCheckbox",
        id: "RoadTax",
        label: "Additional 6 Months Road Tax",
        /* lock: true, */
        value: false,
      },
    ];
    this.Forms["BuyersAccount_Accessories"] = [
      {
        type: "select",
        id: "Accessory",
        label: "Accessory",
        options: [],
      },
      {
        type: "add",
        id: "Price",
        label: "Price ($)",
        admitsUndefined: true,
      },
      {
        type: "const",
        id: "Total",
        label: "Total Amount In Buyer's Account ($)",
        value: 0,
      },
    ];
    this.Forms["SalesmanAccount_NonAccessories"] = [
      {
        type: "add",
        id: "InsurancePremium",
        label: "Insurance Premium ($)",
      },
      {
        type: "formCheckbox",
        id: "RoadTax",
        label: "Additional 6 Months Road Tax",
        /* lock: true, */
        value: false,
      },
      {
        type: "add",
        id: "AdminFee",
        label: "Admin fee for overtrade ($)",
      },
      {
        type: "add",
        id: "COETopUp",
        label: "COE Top Up ($)",
      },
    ];
    this.Forms["SalesmanAccount_Accessories"] = [
      {
        type: "select",
        id: "Accessory",
        label: "Accessory",
        options: [],
      },
      {
        type: "add",
        id: "Price",
        label: "Price ($)",
        admitsUndefined: true,
      },
      {
        type: "const",
        id: "Total",
        label: "Total amount in salesman's account ($)",
        value: 0,
      },
    ];
    this.Forms["AdditionalRemarks"] = [
      {
        type: "textarea",
        id: "Remarks",
        label: "Remarks",
      },
      {
        type: "select",
        id: "SalesMan",
        label: "Sales consultant",
        options: this.UserDrpList,
      },
    ];
  }

  //#endregion

  //#region Update Fields

  updateFields(event: any): void {
    this.evaluateUpload();
    switch (event.name) {
      case "SearchBooking":
        this.Forms["SearchBooking"] = event.fields;
        break;
      case "BuyerDetails":
        const ModeOfConsentInput = this.getField(
          "ModeOfConsent",
          "BuyerDetails",
        );
        const DoNotReceiveInput = this.getField(
          "DoNotReceive",
          "BuyerDetails",
        );
        if (
          ModeOfConsentInput.options.some(
            (item: any) => item.marked == true,
          )
        ) {
          this.Forms["BuyerDetails"][15].value = false;
        } else if (
          ModeOfConsentInput.options.every(
            (item: any) => item.marked == false,
          )
        ) {
          this.Forms["BuyerDetails"][15].value = true;
        }
        if (DoNotReceiveInput.marked == true) {
          for (
            let index = 0;
            index < ModeOfConsentInput.options.length;
            index++
          ) {
            this.Forms["BuyerDetails"][14].options![index].marked =
              false;
          }
        }
        this.Forms["BuyerDetails"] = event.fields;
        break;
      case "VehicleDetails":
        this.Forms["VehicleDetails"] = event.fields;
        switch (event.updatedValue) {
          // Vehicle model selected
          case 0: {
            if (this.getField("CarType", "VehicleDetails").value) {
              this.GetVehiclePrice();
            }
            this.GetStockAvailability();
            this.GetDataOnModelChange();
            this.SetModelCategoryAndCoe();
            break;
          }
          // Vehicle extColour selected
          case 1: {
            this.GetIntColourWithExtColour();
            break;
          }
          //Vehicle intColour selected
          case 2: {
            this.CheckStock();
            break;
          }
          //Vehicle car type selected
          case 3: {
            this.GetVehiclePrice();
            const CarType: "Special" | "OPC" = this.getField(
              "CarType",
              "VehicleDetails",
            ).value;

            const config = {
              Special: { roadTaxPeriod: 6, showSpecialType: true },
              OPC: { roadTaxPeriod: 12, showSpecialType: false },
            };

            const { roadTaxPeriod = 6, showSpecialType = false } =
              config[CarType] || {};

            this.getField("RoadTaxPeriod", "VehicleDetails").value =
              roadTaxPeriod;
            this.getField("SpecialType", "VehicleDetails").show =
              showSpecialType;
            break;
          }
          case 5: {
            this.GetVehiclePrice();
            break;
          }
        }
        break;
      case "DetailsCashDiscount":
        this.Forms["DetailsCashDiscount"] = event.fields;
        this.CalculateVehiclePrice();
        break;
      case "DetailsDeposit":
        this.Forms["DetailsDeposit"] = event.fields;
        const local_PaymentType = this.getField(
          "PaymentType",
          "DetailsDeposit",
        ).value;

        for (let i = 2; i <= 8; i++) {
          this.Forms["DetailsDeposit"][i].show = false;
        }

        const isDepositRequired = local_PaymentType !== "NO DEPOSIT";
        this.getField("Amount", "DetailsDeposit").required =
          isDepositRequired;
        this.getField("DepositDate", "DetailsDeposit").required =
          isDepositRequired;

        switch (local_PaymentType) {
          case "CASH":
          case "CHEQUE":
            if (local_PaymentType === "CHEQUE") {
              this.getField("Bank", "DetailsDeposit").show = true;
              this.getField("ChequeNumber", "DetailsDeposit").show =
                true;
              if (
                this.getField("Bank", "DetailsDeposit").value ===
                "OTHERS"
              ) {
                this.getField("OtherBank", "DetailsDeposit").show =
                  true;
              }
            }
            break;
          case "NETS":
          case "VISA/MASTER":
          case "AMEX":
            {
              (this.getField(
                "TerminalNumber",
                "DetailsDeposit",
              ).options = this.AllActiveList.filter(
                (item) => item.PaymentType == local_PaymentType,
              ).map((model) => ({
                display: `${model.TerminalNo}(${model.Branch})`,
                value: model.TerminalNo,
              }))),
                [
                  "BatchNumber",
                  "TerminalNumber",
                  "InvoiceNumber",
                ].forEach((field) => {
                  this.getField(field, "DetailsDeposit").show = true;
                });
            }
            break;
          case "OTHERS":
            this.getField("ReceiptNumber", "DetailsDeposit").show =
              true;
            this.getField("OtherBank", "DetailsDeposit").show = true;
            break;
        }

        break;
      case "DetailsOtherFees":
        this.Forms["DetailsOtherFees"] = event.fields;
        const showSpotters =
          this.getField("SpotterCommision", "DetailsOtherFees")
            .value > 0;

        [
          "IntroName",
          "IntroNRICNo",
          "IntroAddress",
          "IntroContractNo",
        ].forEach((field) => {
          this.getField(field, "DetailsOtherFees").show =
            showSpotters;
        });
        this.CalculateVehiclePrice();
        break;
      case "DetailsInsurance":
        this.Forms["DetailsInsurance"] = event.fields;
        break;
      case "DetailsHireApplication":
        this.Forms["DetailsHireApplication"] = event.fields;
        this.GetMonthlyInstallment();
        this.filterFinanceCompanies();
        break;
      case "DetailsTradeInVehicle":
        this.Forms["DetailsTradeInVehicle"] = event.fields;
        const DealerName = this.getField(
          "DealerName",
          "DetailsTradeInVehicle",
        );
        const shouldShow = DealerName.value != 1;

        const fieldsToShow = [
          "Make",
          "YOM",
          "OldVehicleNumber",
          "Model",
          "Overtrade",
          "PurchasePriceUsedVehicle",
        ];

        fieldsToShow.forEach((fieldName) => {
          this.getField(fieldName, "DetailsTradeInVehicle").show =
            shouldShow;
        });

        break;
      case "KomocosAccount_Promotion":
        this.Forms["KomocosAccount_Promotion"] = event.fields;
        if (event.updatedValue == 1) {
          this.toggleRoadTax("PromotionEntries");
        }
        break;
      case "KomocosAccount_Accessories":
        this.Forms["KomocosAccount_Accessories"] = event.fields;
        if (event.updatedValue == 0) {
          const selectedAcc = this.getField(
            "Accessory",
            "KomocosAccount_Accessories",
          ).value;
          const newPrice = this.AccessoryList.find(
            (acc) => acc.Description == selectedAcc,
          )?.CostPrice;
          this.setFormFieldValue(
            "Price",
            "KomocosAccount_Accessories",
            newPrice ?? null,
          );
        }
        break;
      case "BuyersAccount_NonAccessories":
        this.Forms["BuyersAccount_NonAccessories"] = event.fields;
        if (event.updatedValue == 3) {
          this.toggleRoadTax("ItemsBuyerEntries");
        }
        break;
      case "BuyersAccount_Accessories":
        this.Forms["BuyersAccount_Accessories"] = event.fields;
        if (event.updatedValue == 0) {
          const selectedAcc = this.getField(
            "Accessory",
            "BuyersAccount_Accessories",
          ).value;
          const newPrice = this.AccessoryList.find(
            (acc) => acc.Description == selectedAcc,
          )?.CostPrice;
          this.setFormFieldValue(
            "Price",
            "BuyersAccount_Accessories",
            newPrice ?? null,
          );
        }
        break;
      case "SalesmanAccount_NonAccessories":
        this.Forms["SalesmanAccount_NonAccessories"] = event.fields;
        if (event.updatedValue == 1) {
          this.toggleRoadTax("ItemsSalesmanEntries");
        }
        break;
      case "SalesmanAccount_Accessories":
        this.Forms["SalesmanAccount_Accessories"] = event.fields;
        if (event.updatedValue == 0) {
          const selectedAcc = this.getField(
            "Accessory",
            "SalesmanAccount_Accessories",
          ).value;
          const newPrice = this.AccessoryList.find(
            (acc) => acc.Description == selectedAcc,
          )?.CostPrice;
          this.setFormFieldValue(
            "Price",
            "SalesmanAccount_Accessories",
            newPrice ?? null,
          );
        }
        break;
      case "AdditionalRemarks":
        this.Forms["AdditionalRemarks"] = event.fields;
        break;
    }
  }

  //#endregion

  //Fills Block/House and Road inputs in Buyer's Details section when a valid postal code is added, or fills all Buyer's Details section when NRIC is defined
  searchPostalCodeOrIcNo(event: any): void {
    const fieldId = event.fieldId;

    const handleError = (message: string) =>
      this.notifier.notify("error", message);
    const handleWarning = (message: string) =>
      this.notifier.notify("warning", message);

    if (fieldId === "IcNo") {
      const IcNo = this.getField("IcNo", "BuyerDetails").value;
      if (IcNo) {
        this.BookingService.GetProspectByIcNo(IcNo).subscribe({
          next: (response) => {
            response.statusCode === 500
              ? handleError("No data found")
              : this.processGetProspectByIcno(response.body);
          },
          error: console.log,
        });
      } else {
        handleWarning("Please add a valid value");
      }
    } else if (fieldId === "PostalCode") {
      const PostalCode = this.getField(
        "PostalCode",
        "BuyerDetails",
      )?.value;
      if (PostalCode) {
        const apiUrl = `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${PostalCode}&returnGeom=N&getAddrDetails=Y&pageNum=1`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const result = data?.results?.[0];
            if (result) {
              this.setFormFieldValue(
                "AddressBlock",
                "BuyerDetails",
                result.BLK_NO,
              );
              this.setFormFieldValue(
                "AddressRoad",
                "BuyerDetails",
                result.ROAD_NAME,
              );
            } else {
              handleWarning(
                "No address found for the given postal code",
              );
            }
          })
          .catch((error) => {
            console.error(
              "Error fetching data from PostalCode:",
              error,
            );
            handleError(
              "Failed to fetch address information for the postal code. Please try again.",
            );
          });
      }
    }
  }

  //Get available stock for a certain model, available extColours and intColours, also display this stock in the Model Stock Availability table (also this DELETES previous extColours, intColours available and its selected values)
  private GetStockAvailability(): Promise<void> {
    return new Promise((resolve, reject) => {
      const model = this.getField("ModelName", "VehicleDetails");

      this.BookingService.GetVehicleStockAvailability(
        model.value,
      ).subscribe({
        next: (response) => {
          this.ColoursInStock = response.body.GridData;

          let ExtColourField = this.getField(
            "Colour",
            "VehicleDetails",
          );
          let IntColourField = this.getField(
            "IntColour",
            "VehicleDetails",
          );
          ExtColourField.value = undefined;
          ExtColourField.options = [];
          IntColourField.value = undefined;
          IntColourField.options = [];

          response.body.GridData.forEach((item: any) => {
            const ExtColour = {
              value: item.ExtColourCode,
              display: `${item.ExtColourCode} - ${item.ExtColourDescription}`,
              id: `${item.ExtColourCode} - ${item.ExtColourDescription}`,
            };
            const IntColour = {
              value: item.IntColourCode,
              display: `${item.IntColourCode} - ${item.IntColourDescription}`,
              id: `${item.IntColourCode} - ${item.IntColourDescription}`,
            };
            const extIndex = ExtColourField.options.findIndex(
              (x: option) => x.value == ExtColour.value,
            );
            const intIndex = IntColourField.options.findIndex(
              (y: option) => y.value == IntColour.value,
            );
            if (extIndex === -1) {
              ExtColourField.options.push(ExtColour);
            }
            if (intIndex === -1) {
              IntColourField.options.push(IntColour);
            }
          });

          this.AvailabilityEntries = response.body.GridData.map(
            (model: any) => {
              const container: Container = {
                ExtColourDescription: model.ExtColourDescription,
                Type: model.Type,
                IntColourDescription: model.IntColourDescription,
                InStock: model.InStock,
                Oct: 0,
                Nov: 0,
                Dec: 0,
                Jan: 0,
                Feb: 0,
              };
              response.body.ExtraData.forEach((month: any) => {
                month.ItemList.forEach((item: any) => {
                  if (
                    `${model.ExtColourCode} - ${model.ExtColourDescription}` ===
                    item.ExtColourCode
                  ) {
                    const monthName: MonthName = month.MonthName;
                    if (container.hasOwnProperty(month.MonthName)) {
                      container[monthName] += item.Quantity;
                    }
                  }
                });
              });
              return container;
            },
          );

          resolve();
        },
        error: (error) => {
          console.log(error);
          this.notifier.notify(
            "error",
            "Error getting available models",
          );
          reject(error);
        },
      });
    });
  }

  //Set the category and COE type in Details of Vehicle and it adds Surcharge/Rebate value in Details of Other Fees section
  private SetModelCategoryAndCoe(): Promise<void> {
    return new Promise((resolve) => {
      const model = this.ModelListForBooking.find(
        (item) =>
          this.getField("ModelName", "VehicleDetails").value ===
          item.ModelShortDesc,
      );

      if (model) {
        this.setFormFieldValue(
          "Category",
          "VehicleDetails",
          model.CoeCategory,
        );
        this.setFormFieldValue(
          "SurchargeOrRebate",
          "DetailsOtherFees",
          model.SurchargeOrRebate,
        );

        const newOptions = [
          { display: "Bidding", value: "Bidding", id: "1" },
          { display: "Own COE", value: "Own COE", id: "2" },
          ...(model.CoeCategory === "A" || model.CoeCategory === "B"
            ? [
                {
                  display: "Immediate E",
                  value: "Immediate E",
                  id: "4",
                },
              ]
            : [
                {
                  display: "Immediate C",
                  value: "Immediate C",
                  id: "3",
                },
                {
                  display: "Immediate E",
                  value: "Immediate E",
                  id: "4",
                },
              ]),
        ];

        this.getField("COEType", "VehicleDetails").options =
          newOptions;
      }

      resolve();
    });
  }

  // Display available accessories and promotions when a model is selected
  private GetDataOnModelChange(): Promise<void> {
    return new Promise((resolve, reject) => {
      const foundModel = this.ModelListForBooking.find(
        (item) =>
          item.ModelShortDesc ===
          this.getField("ModelName", "VehicleDetails").value,
      );

      if (foundModel) {
        const ModelMasterId = foundModel.ModelMasterId;
        this.BookingService.GetDataOnModelChange(
          ModelMasterId,
        ).subscribe({
          next: (response) => {
            this.AccessoryList = response.body.AccessoryList;
            this.PromotionList = response.body.KomocoPromotionList;

            let newAccessories = response.body.AccessoryList.map(
              (accessory: accessory) => ({
                display: accessory.Description,
                value: accessory.Description,
                id: accessory.AccessoryId,
              }),
            );

            let newPromotions = response.body.KomocoPromotionList.map(
              (promotion: promotion) => ({
                display: promotion.Name,
                value: promotion.Value,
                id: promotion.Value,
              }),
            );

            this.getField(
              "Accessory",
              "KomocosAccount_Accessories",
            ).options = newAccessories;
            this.getField(
              "Accessory",
              "BuyersAccount_Accessories",
            ).options = newAccessories;
            this.getField(
              "Accessory",
              "SalesmanAccount_Accessories",
            ).options = newAccessories;
            this.getField(
              "Promotion",
              "KomocosAccount_Promotion",
            ).options = newPromotions;
            if (this.PromotionsEntries.length == 0) {
              const defaultProm = this.PromotionList.find(
                (prom) => prom.Value == "Six(6) Bids Non-Guaranteed",
              );
              this.PromotionsEntries = [
                ...this.PromotionsEntries,
                {
                  promotion: defaultProm?.Name,
                  amount: 0,
                  trash: "",
                },
              ];
            }
            resolve();
          },
          error: (error) => {
            console.log(error);
            reject(error);
          },
        });
      } else {
        resolve();
      }
    });
  }

  //Change available intColours when an extColour is selected
  private GetIntColourWithExtColour(): void {
    const ExtColourCode = this.getField(
      "Colour",
      "VehicleDetails",
    ).value;
    const model = this.getField("ModelName", "VehicleDetails").value;
    this.BookingService.GetIntColourCodeList(
      ExtColourCode,
      model,
    ).subscribe({
      next: (response) => {
        this.getField("IntColour", "VehicleDetails").value =
          undefined;
        this.getField("IntColour", "VehicleDetails").options = [];
        const NewColours = response.body.map((item: any) => ({
          value: item.Value,
          display: `${item.Value} - ${item.Name}`,
          id: `${item.Value} - ${item.Name}`,
        }));
        this.getField("IntColour", "VehicleDetails").options =
          NewColours;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  //Check stock according to selected colours and model
  private CheckStock(): void {
    const ExtColour = this.getField("Colour", "VehicleDetails").value;
    const IntColour = this.getField(
      "IntColour",
      "VehicleDetails",
    ).value;

    const hasStock = this.ColoursInStock.some(
      (item) =>
        item.ExtColourCode == ExtColour &&
        item.IntColourCode == IntColour &&
        item.InStock > 0,
    );
    if (!hasStock) {
      this.notifier.notify(
        "warning",
        "Please take note that stock is not available",
      );
    }
  }

  //Fills monthly installement input if values are defined in Details of Hire Application
  private GetMonthlyInstallment(): void {
    const LoanAmount = this.getField(
      "LoanAmount",
      "DetailsHireApplication",
    ).value;
    const LoanPeriod = this.getField(
      "LoanPeriod",
      "DetailsHireApplication",
    ).value;
    const LoanRate = this.getField(
      "LoanRate",
      "DetailsHireApplication",
    ).value;

    if (LoanAmount && LoanPeriod && LoanRate) {
      this.BookingService.GetMonthlyInstallment(
        LoanAmount,
        LoanPeriod,
        LoanRate,
      ).subscribe({
        next: (response) => {
          this.setFormFieldValue(
            "MonthlyInstallment",
            "DetailsHireApplication",
            Math.round(response.body),
          );
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.setFormFieldValue(
        "MonthlyInstallment",
        "DetailsHireApplication",
        null,
      );
    }
  }

  //Get price when a model, car type and special type are selected
  private GetVehiclePrice(): void {
    const carType = this.getField("CarType", "VehicleDetails").value;
    const ModelShortDesc = this.getField(
      "ModelName",
      "VehicleDetails",
    ).value;
    const SpecialType = this.getField(
      "SpecialType",
      "VehicleDetails",
    ).value;
    this.BookingService.GetVehiclePrice(
      carType,
      ModelShortDesc,
      SpecialType,
    ).subscribe({
      next: (response) => {
        this.setFormFieldValue(
          "InvoicePrice",
          "VehicleDetails",
          response.body,
        );
        this.CalculateVehiclePrice();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  //Add new payment with conditionals
  AddPayment(): void {
    const hasMissingValue = this.Forms["DetailsDeposit"].some(
      (field) => {
        return (
          (field.required ?? false) &&
          (field.show ?? false) &&
          !field.value
        );
      },
    );

    if (hasMissingValue) {
      this.notifier.notify(
        "warning",
        "Please fill all required data",
      );
    } else {
      const newPayment = this.createPaymentObject();
      this.resetFieldValues();
      this.DepositEntries = [...this.DepositEntries, newPayment];
    }
  }

  //Part of AddPayment
  private createPaymentObject() {
    const paymentObject = {
      PaymentType: this.getField("PaymentType", "DetailsDeposit")
        .value,
      Bank: this.getField("Bank", "DetailsDeposit").value,
      OtherBank: this.getField("OtherBank", "DetailsDeposit").value,
      DepositAmount: this.getField("Amount", "DetailsDeposit").value,
      SlipNo: this.getField("ChequeNumber", "DetailsDeposit").value,
      TerminalNo: this.getField("TerminalNumber", "DetailsDeposit")
        .value,
      BatchNo: this.getField("BatchNumber", "DetailsDeposit").value,
      InvoiceNo: this.getField("InvoiceNumber", "DetailsDeposit")
        .value,
      ReceiptNo: this.getField("ReceiptNumber", "DetailsDeposit")
        .value,
      DepositDate: this.formatDateField(
        this.getField("DepositDate", "DetailsDeposit").value,
      ),
      Trash: "",
    };

    // Filtrar propiedades con valor null
    return Object.fromEntries(
      Object.entries(paymentObject).filter(
        ([_, value]) => value !== null,
      ),
    );
  }

  //Part of AddPayment
  private resetFieldValues() {
    this.Forms["DetailsDeposit"].forEach((item) => {
      if (item.id !== "PaymentType") {
        item.value = undefined;
      }
    });
  }

  //Remove a payment entry in Details of deposit table
  RemovePayment(event: any): void {
    this.DepositEntries = this.DepositEntries.filter(
      (entry) => entry !== event,
    );
  }

  //Logic to handle finance companies inputs options
  private filterFinanceCompanies(): void {
    const financeCompanies = [
      "FinanceCompany1",
      "FinanceCompany2",
      "FinanceCompany3",
      "FinanceCompany4",
    ];

    const financeCompany1Field = this.getField(
      "FinanceCompany1",
      "DetailsHireApplication",
    );
    const isLocked = financeCompany1Field.value === "NF";

    financeCompanies.forEach((company) => {
      const field = this.getField(company, "DetailsHireApplication");

      if (company !== "FinanceCompany1") {
        field.lock = isLocked;
      }

      if (isLocked && company !== "FinanceCompany1") {
        field.value = undefined;
      }
    });

    const selectedValues = financeCompanies
      .map(
        (company) =>
          this.getField(company, "DetailsHireApplication").value,
      )
      .filter((value) => value !== undefined);

    financeCompanies.forEach((company) => {
      const field = this.getField(company, "DetailsHireApplication");
      if (company === "FinanceCompany1") {
        field.options = [
          { display: "No Finance (NF)", value: "NF" },
          ...this.FinanceList.filter(
            (option) =>
              !selectedValues.includes(option.value) ||
              option.value === field.value,
          ),
        ];
      } else {
        field.options = this.FinanceList.filter(
          (option) =>
            !selectedValues.includes(option.value) ||
            option.value === field.value,
        );
      }
    });
  }

  //Adds an entry in promotion table in Komoco's Account section
  addPromotion(event: any): void {
    const { fieldId } = event;
    const promotionMapping: any = {
      InsurancePremium: {
        promotion: "Insurance Premium",
        amount: this.getField(
          "InsurancePremium",
          "KomocosAccount_Promotion",
        ).value,
      },
      Price: {
        promotion: this.getField(
          "Promotion",
          "KomocosAccount_Promotion",
        ).value,
        amount: this.getField("Price", "KomocosAccount_Promotion")
          .value,
      },
    };

    if (promotionMapping[fieldId]) {
      const newProm = {
        ...promotionMapping[fieldId],
        trash: "",
      };

      const exists = this.PromotionsEntries.some(
        (entry) => entry.promotion === newProm.promotion,
      );

      if (!exists) {
        this.PromotionsEntries = [...this.PromotionsEntries, newProm];
      }

      this.CalculateKomocoPrice();
    }
  }

  //Adds an entry in accessory table in Komoco's Account section
  addAccessoryKomoco(event: any): void {
    const newAcc = {
      accessory: this.getField(
        "Accessory",
        "KomocosAccount_Accessories",
      ).value,
      amount: this.getField("Price", "KomocosAccount_Accessories")
        .value,
      trash: "",
    };

    const exists = this.AccessoriesKomocoEntries.some(
      (entry: any) => entry.accessory === newAcc.accessory,
    );

    if (!exists) {
      this.KomocoItems = [
        ...this.KomocoItems,
        {
          Allowed: true,
          Name: newAcc.accessory,
          Value: newAcc.amount,
        },
      ];
      this.AccessoriesKomocoEntries = [
        ...this.AccessoriesKomocoEntries,
        newAcc,
      ];
      this.CalculateKomocoPrice();
    }
  }

  //Adds an entry in accessory table in Buyer's Account section
  addItemBuyer(event: any): void {
    const { fieldId } = event;
    const itemMapping: any = {
      InsurancePremium: {
        item: "Insurance Premium",
        amount: this.getField(
          "InsurancePremium",
          "BuyersAccount_NonAccessories",
        ).value,
      },
      AdminFee: {
        item: "Admin Fee for Overtrade",
        amount: this.getField(
          "AdminFee",
          "BuyersAccount_NonAccessories",
        ).value,
      },
      FirstInstallment: {
        item: "First Installment",
        amount: this.getField(
          "FirstInstallment",
          "BuyersAccount_NonAccessories",
        ).value,
      },
      Price: {
        item: this.getField("Accessory", "BuyersAccount_Accessories")
          .value,
        amount: this.getField("Price", "BuyersAccount_Accessories")
          .value,
      },
    };

    if (itemMapping[fieldId]) {
      const newItem = {
        ...itemMapping[fieldId],
        trash: "",
      };

      const exists = this.ItemsBuyerEntries.some(
        (entry: any) => entry.item === newItem.item,
      );

      if (!exists) {
        this.BuyerItems = [
          ...this.BuyerItems,
          { Name: newItem.item, Value: newItem.amount },
        ];
        this.ItemsBuyerEntries = [...this.ItemsBuyerEntries, newItem];
      }

      this.CalculateBuyerPrice();
    }
  }

  //Adds an entry in accessory table in Sales Consultant's Account section
  addItemSalesman(event: any): void {
    const { fieldId } = event;
    const itemMapping: any = {
      InsurancePremium: {
        item: "Insurance Premium",
        amount: this.getField(
          "InsurancePremium",
          "SalesmanAccount_NonAccessories",
        ).value,
      },
      AdminFee: {
        item: "Admin Fee for Overtrade",
        amount: this.getField(
          "AdminFee",
          "SalesmanAccount_NonAccessories",
        ).value,
      },
      COETopUp: {
        item: "COE Top Up",
        amount: this.getField(
          "COETopUp",
          "SalesmanAccount_NonAccessories",
        ).value,
      },
      Price: {
        item: this.getField(
          "Accessory",
          "SalesmanAccount_Accessories",
        ).value,
        amount: this.getField("Price", "SalesmanAccount_Accessories")
          .value,
      },
    };

    if (itemMapping[fieldId]) {
      const newItem = {
        ...itemMapping[fieldId],
        trash: "",
      };

      const exists = this.ItemsSalesmanEntries.some(
        (entry: any) => entry.item === newItem.item,
      );

      if (!exists) {
        this.SalesConsultantItems = [
          ...this.SalesConsultantItems,
          { Name: newItem.item, Value: newItem.amount },
        ];
        this.ItemsSalesmanEntries = [
          ...this.ItemsSalesmanEntries,
          newItem,
        ];
      }

      this.CalculateSalesmanPrice();
    }
  }

  //Remove an entry in promotion list
  RemovePromotion(event: any): void {
    this.PromotionsEntries = this.PromotionsEntries.filter(
      (entry) => entry !== event,
    );
    this.CalculateKomocoPrice();
  }

  //Remove an entry in a specified table
  RemoveItem(event: any, table: string): void {
    if (table == "AccessoryKomoco") {
      this.AccessoriesKomocoEntries =
        this.AccessoriesKomocoEntries.filter(
          (entry: any) => entry !== event,
        );
      this.KomocoItems = this.KomocoItems.filter(
        (entry: any) => entry.Name !== event.item,
      );
      this.CalculateKomocoPrice();
    } else if (table == "ItemBuyer") {
      this.ItemsBuyerEntries = this.ItemsBuyerEntries.filter(
        (entry: any) => entry !== event,
      );
      this.BuyerItems = this.BuyerItems.filter(
        (entry: any) => entry.Name !== event.item,
      );
      this.CalculateBuyerPrice();
    } else if (table == "ItemSalesman") {
      this.ItemsSalesmanEntries = this.ItemsSalesmanEntries.filter(
        (entry: any) => entry !== event,
      );
      this.SalesConsultantItems = this.SalesConsultantItems.filter(
        (entry: any) => entry.Name !== event.item,
      );
      this.CalculateSalesmanPrice();
    }
  }

  //Fills all forms using an input from search booking section
  async searchBooking(event: any): Promise<void> {
    const id = this.getField(event.fieldId, "SearchBooking").value;
    const byContract = event.fieldId === "ContractNo";

    try {
      const response =
        event.fieldId === "QuotationNumber"
          ? await this.BookingService.ConvertQuotationToBooking(
              id,
            ).toPromise()
          : await this.BookingService.GetById(
              byContract,
              id,
              false,
            ).toPromise();

      if (!response?.body) {
        this.notifier.notify(
          "warning",
          "Response is undefined or empty.",
        );
        return;
      }

      const { Code, BuyerDetails, Message } = response.body;
      const messages: any = {
        [-1]: Message,
        [-2]: Message,
        [-3]: Message,
      };

      if (messages[Code]) {
        this.notifier.notify(
          event.fieldId === "QuotationNumber" ? "error" : "warning",
          messages[Code],
        );
      } else {
        if (response.body.ContractNo != null) {
          this.hasContract = true;
        } else {
          this.hasContract = false;
        }
        this.hasData = true;
        this.canUpload = true;
        this.IsReservation = response.body.IsReservation;
        this.globalId = response.body.RefNo;
        this.BuyerId = BuyerDetails.Id;
        this.SalesmanId = BuyerDetails.SalesmanId;
        this.SalesmanCode = BuyerDetails.SalesmanCode;
        this.QuotationCustomerId = BuyerDetails.QuotationCustomerId;

        // Procesar datos
        const processDataMethods = [
          this.processDataForSearch,
          this.processDataForBuyer,
          this.processDataForCashDiscount,
          this.processDataForDeposit,
          this.processDataForOtherFees,
          this.processDataForInsurance,
          this.processDataForHireApplication,
          this.processDataTradeInVehicle,
          this.processDataAdditionalRemarks,
          this.processDataForVehicle,
          this.processDataDetailsAccount,
        ];

        for (const method of processDataMethods) {
          if (
            method === this.processDataForVehicle ||
            method === this.processDataDetailsAccount
          ) {
            await method.call(this, response.body);
          } else {
            method.call(this, response.body);
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.notifier.notify(
        "error",
        "An error occurred while fetching the booking.",
      );
    }
  }

  //Calculates price using some values from the page
  private CalculateVehiclePrice(): any {
    const InvoicePrice = Number(
      this.getField("InvoicePrice", "VehicleDetails").value ?? 0,
    );
    const LoyaltyDiscount = Number(
      this.getField("RoyaltyDiscount", "DetailsCashDiscount").value ??
        0,
    );
    const DiscountAmount = Number(
      this.getField("DiscountAmount", "DetailsCashDiscount").value ??
        0,
    );
    const COETopUp = Number(
      this.getField("COETopUp", "DetailsOtherFees").value ?? 0,
    );
    const SurchargeOrRebate = Number(
      this.getField("SurchargeOrRebate", "DetailsOtherFees").value ??
        0,
    );
    let newNetPrice =
      InvoicePrice +
      COETopUp +
      SurchargeOrRebate -
      LoyaltyDiscount -
      DiscountAmount;
    this.setFormFieldValue(
      "VehiclePrice",
      "VehicleDetails",
      newNetPrice,
    );
  }

  //Calculates total amount price in Komoco's Account section using the values of its tables
  private CalculateKomocoPrice(): any {
    const totalProm = this.PromotionsEntries.reduce(
      (sum, item) => sum + parseFloat(item.amount ?? 0),
      0,
    );

    const totalAcc = this.AccessoriesKomocoEntries.reduce(
      (sum: any, item: any) => sum + parseFloat(item.amount ?? 0),
      0,
    );

    this.setFormFieldValue(
      "Total",
      "KomocosAccount_Accessories",
      totalAcc + totalProm,
    );
  }

  //Calculates total amount price in Buyer's Account section using the values of its tables
  private CalculateBuyerPrice(): any {
    const totalItem = this.ItemsBuyerEntries.reduce(
      (sum: any, item: any) => sum + parseFloat(item.amount ?? 0),
      0,
    );

    this.setFormFieldValue(
      "Total",
      "BuyersAccount_Accessories",
      totalItem,
    );
  }

  //Calculates total amount price in Sales Consultant's Account section using the values of its tables
  private CalculateSalesmanPrice(): any {
    const totalItem = this.ItemsSalesmanEntries.reduce(
      (sum: any, item: any) => sum + parseFloat(item.amount ?? 0),
      0,
    );

    this.setFormFieldValue(
      "Total",
      "SalesmanAccount_Accessories",
      totalItem,
    );
  }

  //Switch Additional 6 Months Road Tax entry in the tables of Details of Account
  private toggleRoadTax(table: string) {
    if (table == "PromotionEntries") {
      const hasRoadTax = this.getField(
        "RoadTax",
        "KomocosAccount_Promotion",
      ).value;
      if (hasRoadTax == true) {
        this.PromotionsEntries = [
          ...this.PromotionsEntries,
          {
            promotion: "Additional 6 Months Road Tax",
            amount: 371,
            trash: "",
          },
        ];
      } else {
        this.PromotionsEntries = this.PromotionsEntries.filter(
          (entry) =>
            entry.promotion != "Additional 6 Months Road Tax",
        );
      }
      this.CalculateKomocoPrice();
    } else if (table == "ItemsBuyerEntries") {
      const hasRoadTax = this.getField(
        "RoadTax",
        "BuyersAccount_NonAccessories",
      ).value;
      if (hasRoadTax == true) {
        this.ItemsBuyerEntries = [
          ...this.ItemsBuyerEntries,
          {
            item: "Additional 6 Months Road Tax",
            amount: 371,
            trash: "",
          },
        ];
      } else {
        this.ItemsBuyerEntries = this.ItemsBuyerEntries.filter(
          (entry: any) =>
            entry.item != "Additional 6 Months Road Tax",
        );
      }
      this.CalculateBuyerPrice();
    } else if (table == "ItemsSalesmanEntries") {
      const hasRoadTax = this.getField(
        "RoadTax",
        "SalesmanAccount_NonAccessories",
      ).value;
      if (hasRoadTax == true) {
        this.ItemsSalesmanEntries = [
          ...this.ItemsSalesmanEntries,
          {
            item: "Additional 6 Months Road Tax",
            amount: 371,
            trash: "",
          },
        ];
      } else {
        this.ItemsSalesmanEntries = this.ItemsSalesmanEntries.filter(
          (entry: any) =>
            entry.item != "Additional 6 Months Road Tax",
        );
      }
      this.CalculateSalesmanPrice();
    }
  }

  //This is only enabled when search booking function worked
  PrintWithoutSellingPrice(): void {
    this.BookingService.PrintWithoutSellingPrice(
      this.globalId,
    ).subscribe({
      next: (response) => {
        window.open(response.body, "_blank")?.focus();
      },
      error: console.log,
    });
    this.showModal(false);
    this.enableAcceptance = false;
  }

  //Prints when Print Buyer's Order is clicked
  GetBuyerOrderFormForBuyOnline(): void {
    this.BookingService.GetBuyerOrderFormForBuyOnline(
      this.globalId,
    ).subscribe({
      next: (response) => {
        window.open(response.message, "_blank")?.focus();
      },
      error: console.log,
    });
  }

  //The Update
  Update(): void {
    const updateForm = this.populateForm(true);
    this.BookingService.Update(false, false, updateForm).subscribe({
      next: (response) => {
        const { Code, Message } = response.body;

        const messages: any = {
          [-1]: Message,
          [-2]: Message,
          [-3]: Message,
          [-4]: Message,
        };
        if (!Code) {
          this.notifier.notify(
            "success",
            "Order updated successfully!",
          );
        } else if (messages[Code]) {
          this.notifier.notify("warning", messages[Code]);
        } else {
          this.notifier.notify("warning", Message);
        }
      },
      error: (error) => console.log(error),
    });
  }

  //The Add
  Add(): void {
    const addForm = this.populateForm(false);
    console.log(addForm);
    this.BookingService.Add(addForm).subscribe({
      next: (response) => {
        this.notifier.notify(
          "success",
          "Booking has been created successfully with Buyer Order: " +
            response.body,
        );
        this.setFormFieldValue(
          "RefNo",
          "SearchBooking",
          response.body,
        );
        const event = { fieldId: "RefNo" };
        this.searchBooking(event).then(() => {
          this.GetBuyerOrderFormForBuyOnline();
        });
      },
      error: (error) => console.log(error),
    });
  }

  private populateForm(isUpdate: boolean): any {
    const Form = {
      AddedPromotionList: this.PromotionsEntries.map((prom) => {
        const { promotion: Name, amount: Value, id: Id } = prom;
        const promotionEntry: { Name: any; Value: any; Id?: any } = {
          Name,
          Value: Value ?? 0,
        };
        if (Id !== undefined) {
          promotionEntry.Id = Id;
        }
        return promotionEntry;
      }),
      BuyerDetails: {
        AddressBlock: this.getField("AddressBlock", "BuyerDetails")
          .value,
        AddressRoad: this.getField("AddressRoad", "BuyerDetails")
          .value,
        AddressUnit:
          this.getField("AddressUnit", "BuyerDetails").value ?? null,
        AgreeDirectMail:
          this.getField("ModeOfConsent", "BuyerDetails").options[0]
            .marked ?? false,
        AgreeMail:
          this.getField("ModeOfConsent", "BuyerDetails").options[1]
            .marked ?? false,
        AgreePhone:
          this.getField("ModeOfConsent", "BuyerDetails").options[3]
            .marked ?? false,
        AgreeSms:
          this.getField("ModeOfConsent", "BuyerDetails").options[2]
            .marked ?? false,
        ContactNo: this.getField("ContactNo", "BuyerDetails").value,
        CustomerName: this.getField("CustomerName", "BuyerDetails")
          .value,
        DoNotReceive: this.getField("DoNotReceive", "BuyerDetails")
          .value,
        Dob: this.formatDateField(
          this.getField("Dob", "BuyerDetails").value,
        ),
        Email: this.getField("Email", "BuyerDetails").value,
        ...(isUpdate ? { Gender: null } : {}),
        IcNo: this.getField("IcNo", "BuyerDetails").value,
        Id: this.BuyerId,
        ModeOfContact: this.getField("ModeOfContact", "BuyerDetails")
          .value,
        Occupation: this.getField("Occupation", "BuyerDetails").value,
        OfficeNo:
          this.getField("OfficeNo", "BuyerDetails").value ?? null,
        PostalCode: this.getField("PostalCode", "BuyerDetails").value,
        ...(isUpdate
          ? {
              PurchaseHistory:
                this.PurchaseHistory.length > 0
                  ? this.PurchaseHistory
                  : null,
            }
          : {}),
        ...(isUpdate
          ? { QuotationCustomerId: this.QuotationCustomerId }
          : {}),
        ResidenceNo:
          this.getField("ResidenceNo", "BuyerDetails").value ?? null,
        ...(isUpdate
          ? {
              RoyaltyDiscount: this.getField(
                "RoyaltyDiscount",
                "DetailsCashDiscount",
              ).value,
            }
          : {}),
        ...(isUpdate
          ? {
              SalesmanCode: this.SalesmanCode,
            }
          : {}),
        ...(isUpdate
          ? {
              SalesmanId: this.SalesmanId,
            }
          : {}),
        Title: this.getField("Title", "BuyerDetails").value ?? null,
      },
      BuyerItems: this.BuyerItems.map((item) => {
        const { trash, ...rest } = item;
        return rest;
      }),
      DepositList: this.DepositEntries.map((entry) => {
        const filteredEntry = Object.fromEntries(
          Object.entries(entry).filter(
            ([key, value]) => value !== undefined && key !== "Trash",
          ),
        );
        return filteredEntry;
      }),
      HirePurchaseDetails: {
        FP: this.getField("FP", "DetailsHireApplication").value,
        FinanceCompany1: this.getField(
          "FinanceCompany1",
          "DetailsHireApplication",
        ).value,
        ...(this.getField("FinanceCompany2", "DetailsHireApplication")
          .value
          ? {
              FinanceCompany2: this.getField(
                "FinanceCompany2",
                "DetailsHireApplication",
              ).value,
            }
          : {}),
        ...(this.getField("FinanceCompany3", "DetailsHireApplication")
          .value
          ? {
              FinanceCompany3: this.getField(
                "FinanceCompany3",
                "DetailsHireApplication",
              ).value,
            }
          : {}),
        ...(this.getField("FinanceCompany4", "DetailsHireApplication")
          .value
          ? {
              FinanceCompany4: this.getField(
                "FinanceCompany4",
                "DetailsHireApplication",
              ).value,
            }
          : {}),
        LoanAmount:
          this.getField("LoanAmount", "DetailsHireApplication")
            .value ?? null,
        LoanPeriod:
          this.getField("LoanPeriod", "DetailsHireApplication")
            .value ?? 0,
        LoanRate:
          this.getField("LoanRate", "DetailsHireApplication").value ??
          null,
        LoanStatus:
          this.getField("LoanStatus", "DetailsHireApplication")
            .value ?? null,
        LoanStatusDate:
          this.formatDateField(this.getField("LoanStatusDate", "DetailsHireApplication")
            .value) ?? null,
        LoanSubmittedDate:
          this.formatDateField(this.getField("LoanSubmittedDate", "DetailsHireApplication")
            .value) ?? null,
        MonthlyInstallment:
          this.getField(
            "MonthlyInstallment",
            "DetailsHireApplication",
          ).value ?? null,
      },
      IsReservation: this.IsReservation,
      Item: {
        BookingUserType: this.getField(
          "BookingUserType",
          "SearchBooking",
        ).value,
        DiscountAmount:
          Number(
            this.getField("DiscountAmount", "DetailsCashDiscount")
              .value,
          ) ?? null,
        InsuranceCompany: this.getField(
          "InsuranceCompany",
          "DetailsInsurance",
        ).value,
        RoyaltyDiscount:
          this.getField("RoyaltyDiscount", "DetailsCashDiscount")
            .value ?? 0,
      },
      KomocoItems: this.KomocoItems.map((item) => {
        const { trash, ...rest } = item;
        return rest;
      }),
      OtherFees: {
        COEOffSet:
          Number(
            this.getField("COEOffSet", "DetailsOtherFees").value,
          ) ?? null,
        COETopUp:
          Number(
            this.getField("COETopUp", "DetailsOtherFees").value,
          ) ?? null,
        IntroAddress:
          this.getField("IntroAddress", "DetailsOtherFees").value ??
          null,
        IntroContractNo:
          this.getField("IntroContractNo", "DetailsOtherFees")
            .value ?? null,
        IntroNRICNo:
          this.getField("IntroNRICNo", "DetailsOtherFees").value ??
          null,
        IntroName:
          this.getField("IntroName", "DetailsOtherFees").value ??
          null,
        SpotterCommision:
          Number(
            this.getField("SpotterCommision", "DetailsOtherFees")
              .value,
          ) ?? null,
        SurchargeOrRebate:
          this.getField("SurchargeOrRebate", "DetailsOtherFees")
            .value ?? null,
        ...(isUpdate
          ? {
              lessCoeRebate:
                this.getField("lessCoeRebate", "DetailsOtherFees")
                  .value ?? null,
            }
          : { Surcharge: 0 }),
      },
      RefDate: this.getField("RefDate", "SearchBooking").value ?? "",
      RefNo: isUpdate
        ? this.getField("RefNo", "SearchBooking").value
        : "",
      Remarks:
        this.getField("Remarks", "AdditionalRemarks").value ?? null,
      SalesConsultantItems: this.SalesConsultantItems.map((item) => {
        const { trash, ...rest } = item;
        return rest;
      }),
      ...(this.getField("SalesMan", "AdditionalRemarks").value
        ? {
            SalesMan: this.getField("SalesMan", "AdditionalRemarks")
              .value,
          }
        : {}),
      TotalInBuyer: this.getField(
        "Total",
        "BuyersAccount_Accessories",
      ).value,
      TotalInKomoco: this.getField(
        "Total",
        "KomocosAccount_Accessories",
      ).value,
      TotalInSalesman: this.getField(
        "Total",
        "SalesmanAccount_Accessories",
      ).value,
      TradeInVehicleDetails: {
        DealerName:
          this.getField(
            "DealerName",
            "DetailsTradeInVehicle",
          ).value?.toString() ?? null,
        ...(this.getField("Make", "DetailsTradeInVehicle").value
          ? {
              Make: this.getField("Make", "DetailsTradeInVehicle")
                .value,
            }
          : {}),
        ...(this.getField("Model", "DetailsTradeInVehicle").value
          ? {
              Model: this.getField("Model", "DetailsTradeInVehicle")
                .value,
            }
          : {}),
        ...(this.getField("OldVehicleNumber", "DetailsTradeInVehicle")
          .value
          ? {
              OldVehicleNumber: this.getField(
                "OldVehicleNumber",
                "DetailsTradeInVehicle",
              ).value,
            }
          : {}),
        ...(this.getField("Overtrade", "DetailsTradeInVehicle").value
          ? {
              Overtrade: this.getField(
                "Overtrade",
                "DetailsTradeInVehicle",
              ).value,
            }
          : {}),
        ...(this.getField(
          "PurchasePriceUsedVehicle",
          "DetailsTradeInVehicle",
        ).value
          ? {
              PurchasePriceUsedVehicle: this.getField(
                "PurchasePriceUsedVehicle",
                "DetailsTradeInVehicle",
              ).value,
            }
          : {}),
        ...(this.getField("YOM", "DetailsTradeInVehicle").value
          ? {
              YOM: this.getField("YOM", "DetailsTradeInVehicle")
                .value,
            }
          : {}),
      },
      VehicleDetails: {
        COEType:
          this.getField("COEType", "VehicleDetails").value ?? null,
        CarType:
          this.getField("CarType", "VehicleDetails").value ?? null,
        Category:
          this.getField("Category", "VehicleDetails").value ?? null,
        Colour:
          this.getField("Colour", "VehicleDetails").value ?? null,
        ...(isUpdate
          ? {
              Description:
                this.ColoursInStock.find(
                  (colour) =>
                    colour.ExtColourCode ==
                    this.getField("Colour", "VehicleDetails").value,
                )?.ExtColourDescription ?? null,
            }
          : {}),
        ExpectedDeliveryDate:
          this.getField("ExpectedDeliveryDate", "VehicleDetails")
            .value ?? null,
        IntColour:
          this.getField("IntColour", "VehicleDetails").value ?? null,
        InvoicePrice:
          this.getField("InvoicePrice", "VehicleDetails").value ??
          null,
        ModelName:
          this.ModelListForBooking.find(
            (model) =>
              model.ModelShortDesc ==
              this.getField("ModelName", "VehicleDetails").value,
          )?.ModelMasterId ?? null,
        ...(isUpdate
          ? {
              ModelNameDisplay:
                this.getField("ModelName", "VehicleDetails").value ??
                null,
            }
          : {}),
        SpecialType:
          this.getField("SpecialType", "VehicleDetails").value ??
          null,
        VehiclePrice:
          this.getField("VehiclePrice", "VehicleDetails").value ??
          null,
      },
    };
    return Form;
  }

  CancelContract(): void {
    const RefId = this.getField("RefNo", "SearchBooking").value;
    if (this.CancelRemarks == "") {
      this.notifier.notify("error", "Please Input Cancel Remarks");
    } else {
      this.BookingService.CancelBooking(
        RefId,
        this.CancelRemarks,
      ).subscribe({
        next: () =>
          this.notifier.notify(
            "success",
            "Booking has been cancelled successfully.",
          ),
        error: (error) => console.log(error),
      });
      this.showModalCancel(false);
      this.clearForms();
      this.CancelRemarks = "";
    }
  }

  //Formate dates to use v1 format
  private formatDateField(date?: Date): string {
    if (date) {
      const formatedDate = formatDate(date, "dd/MM/yyyy", "en-US");
      return formatedDate;
    } else {
      return "";
    }
  }

  //Formatea la fecha de entrada de la api al formato del date input
  private formatDateToField(dateStr: string | null): string | null {
    if (dateStr !== null) {
      const [day, month, year] = dateStr.split("/");
      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  //Self explained
  getField(id: string, form: string): any {
    const field = this.Forms[form as keyof typeof this.Forms].find(
      (f) => f.id === id,
    );
    return field;
  }

  //Self explained
  private setFormFieldValue(
    fieldId: string,
    formId: string,
    value: string | number | null,
  ) {
    const formField = this.Forms[formId].find(
      (f) => f.id === fieldId,
    );
    if (formField) {
      formField.value = value;
    }
  }

  //Self explained
  private resetFieldValue(fieldId: string, formId: string) {
    const formField = this.Forms[formId].find(
      (f) => f.id === fieldId,
    );
    if (formField) {
      formField.value = undefined;
    }
  }

  //Show modal popup when print without selling price is clicked
  showModal(modal: boolean) {
    this.showAcceptance = modal;
    document.body.style.overflow = modal ? "hidden" : "auto";
  }

  // Lo mismo que la de arriba pero para el Cancel Contract
  showModalCancel(modal: boolean) {
    this.showCancel = modal;
    document.body.style.overflow = modal ? "hidden" : "auto";
  }

  //Clear all values from all forms and all tables entries
  clearForms(): void {
    Object.values(this.Forms).forEach((formArray) =>
      formArray.forEach((item) => {
        item.value = item.type === "search" ? "" : undefined;
      }),
    );

    this.hasData = false;
    this.globalId = "";
    this.canUpload = false;
    this.BuyerId = 0;
    this.IsReservation = false;

    this.AvailabilityEntries = [];
    this.PromotionsEntries = [];
    this.AccessoriesKomocoEntries = [];
    this.ItemsBuyerEntries = [];
    this.ItemsSalesmanEntries = [];
    this.DepositEntries = [];
    this.PurchaseHistory = [];
    this.HistoryEntries = [];
  }

  //Check if all datafields with required and show true have values
  evaluateUpload(): void {
    this.canUpload = Object.values(this.Forms).every((formArray) =>
      formArray.every(
        (item) =>
          !(item.required && item.show !== false) ||
          (item.value !== undefined && item.value !== ""),
      ),
    );
  }
}
