import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class InsuranceService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets all insurance companies
   * @returns An Observable of the insurance company list
   */
  getAll(): Observable<any> {
    return this.http.get("/InsuranceService.asmx/GetAll");
  }

  /**
   * Gets insurance company detail by ID.
   * @param id - The ID of the insurance company.
   * @returns An Observable of the insurance company details.
   */
  getDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`);
    const url = `/InsuranceService.asmx/GetDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  /**
   * Saves insurance company details (for both Add and Update).
   * @param data - The insurance company data to save.
   * @returns An Observable for the result of the save operation.
   */
  saveDetails(data: any): Observable<any> {
    return this.http.post("/InsuranceService.asmx/SaveDetails", data); // Envolver en objeto `data`
  }

  /**
   * Deletes the selected insurance companies.
   * @param items - The list of insurance companies to delete.
   * @returns An Observable for the delete operation.
   */
  delete(items: any[]): Observable<any> {
    return this.http.post("/InsuranceService.asmx/Delete", { items });
  }
}
