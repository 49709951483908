import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  HttpResponse,
  HttpUtilService,
} from "../http-util.service";
import {
  Customer,
  Prospect,
} from "../../models/administration/prospect/prospect.model";

const SERVICE_NAME = "CustomerService.asmx";

@Injectable({
  providedIn: "root",
})
export class ProspectService {
  constructor(private http: HttpUtilService) {}

  GetAllProspectCustomerPaged(): Observable<HttpResponse<any>> {
    return this.http.get(
      `${SERVICE_NAME}/GetAllProspectCustomerPaged`,
      {
        params: {
          icNo: "",
          name: "",
          orderBy: "",
          orderByReverse: false,
          pageNumber: 1,
          pageSize: 1000, //QUE TRUCASO NO?
        },
      },
    );
  }

  deleteProspectPatient(
    customerId: string,
  ): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVICE_NAME}/DeleteProspectPatient`, {
      customerId,
    });
  }

  getModelActiveInSalesDrpList(): Observable<
    HttpResponse<{ id: string; name: string }[]>
  > {
    return this.http.get(
      `LookUpService.asmx/GetModelActiveInSalesDrpList`,
    );
  }

  getAllActiveUsers(): Observable<
    HttpResponse<
      { user_id: string; user_name: string; display_name: string }[]
    >
  > {
    return this.http.get(`LookUpService.asmx/GetAllActiveUsers`);
  }

  GetCustomerDetail(id: string): Observable<HttpResponse<Customer>> {
    return this.http.get(`${SERVICE_NAME}/GetCustomerDetail`, {
      params: { id },
    });
  }

  SaveProspectCustomer(
    customer: Prospect,
  ): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVICE_NAME}/SaveProspectCustomer`, {
      customerId: 0,
      data: customer,
    });
  }

  UpdateProspectCustomer(
    customerId: string,
    customer: Prospect,
  ): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVICE_NAME}/SaveProspectCustomer`, {
      customerId: customerId,
      data: customer,
    });
  }
}
