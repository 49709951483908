import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class ShipmentMasterService {
  constructor(private http: HttpUtilService) {}

  /**
   * Gets shipment entry dates.
   * This API fetches all available shipment entry dates for initialization.
   * @returns An Observable containing the list of shipment entry dates.
   */
  getShipmentEntryDates(): Observable<any> {
    return this.http.get("/ShipmentMasterService.asmx/GetShipmentEntryDates");
  }

  /**
   * Gets the basic list of active models.
   * This API fetches the active models available in the system.
   * @returns An Observable containing the list of active models.
   */
  getBasicActiveModelList(): Observable<any> {
    return this.http.get("/ModelSpecificationService.asmx/GetBasicActiveModelList");
  }

/**
 * Corrects the encoding to meet the specific API requirements.
 * @param value - The string to encode.
 * @returns The encoded string.
 */
  private correctEncoding(value: string): string {
  let encoded = encodeURIComponent(value);

  encoded = encoded
    .replace(/%20/g, '+') 
    .replace(/%2B/g, '+') 
    .replace(/%22/g, '%5C%22')
    .replace(/%5C%5C%22/g, '%5C%22') 
    .replace(/%2522/g, '%5C%22') 
    .replace(/^%5C%22/, '%22') 
    .replace(/%5C%22$/, '%22');

  return encoded;
}


/**
 * Gets the exterior color codes for a specific model and model group.
 * @param modelCode - The code of the model (e.g., "PALISADE 3.5 GLS AUTO (S/R) 7-SEATER").
 * @param modelGroup - The group of the model (e.g., "PALISADE").
 * @returns An Observable containing the exterior color codes.
 */
getColourCodeByModel(modelCode: string, modelGroup: string): Observable<any> {
  const correctedModelCode = this.correctEncoding(`"${modelCode}"`);
  const correctedModelGroup = this.correctEncoding(`"${modelGroup}"`);
  const url = `/ColourMasterService.asmx/GetColourCodeByModel?modelCode=${correctedModelCode}&modelGroup=${correctedModelGroup}`;
  return this.http.get(url);
}

  /**
 * Gets the interior color codes for a specific exterior color and model.
 * @param extColour - The exterior color code (e.g., "P7V").
 * @param model - The model name (e.g., "PALISADE 3.5 GLS AUTO (S/R) 7-SEATER").
 * @returns An Observable containing the interior color codes.
 */
  getIntColourCodeList(extColour: string, model: string): Observable<any> {
  const correctedExtColour = this.correctEncoding(`"${extColour}"`);
  const correctedModel = this.correctEncoding(`"${model}"`);

  const url = `/ColourMasterService.asmx/GetIntColourCodeList?extColour=${correctedExtColour}&model=${correctedModel}`;
  return this.http.get(url);
}

/**
 * Adds a new shipment entry.
 * This API sends the shipment data to the server to create a new shipment entry.
 * @param payload An object containing the shipment data in the format required by the API.
 * @returns An Observable with the response from the server.
 */
addShipment(payload: any): Observable<any> {
  return this.http.post("/ShipmentMasterService.asmx/Add", payload);
}

/**
 * Fetches all shipments by a specific date.
 * @param date The date in the format `dd-mm-yyyy` to filter shipments.
 * @returns An Observable containing the shipments for the specified date.
 */
getAllShipmentByDate(date: string): Observable<any> {
  const url = `/ShipmentMasterService.asmx/GetAllShipmentByDate?date=${date}`;
  return this.http.get(url);
}

/**
 * Fetches all details of a givern shipment
 * @param shipmentCode The code to search.
 * @returns An Observable containing the details of the shipment.
 */
getShipmentDetails(shipmentCode: string): Observable<any> {
  return this.http.get(
    `/ShipmentMasterService.asmx/GetDetails?shipmentCode=%22${shipmentCode}%22`
  );
}

/**
 * Updates remarks for selected shipments.
 * Sends an API request to update remarks based on the provided OrderNoList.
 * @param payload The payload containing the list of OrderNos and the remark value.
 * @returns An Observable with the API response.
 */
updateRemarks(payload: { model: { OrderNoList: number[]; Value: string } }): Observable<any> {
  return this.http.post('/ShipmentMasterService.asmx/UpdateRemarks', payload);
}

/**
 * Updates the arrival date for selected shipments.
 * Sends an API request to update the arrival date based on the provided OrderNoList.
 * @param payload The payload containing the list of OrderNos and the new arrival date value.
 * @returns An Observable with the API response.
 */
updateArrivalDate(payload: { model: { OrderNoList: number[]; Value: string } }): Observable<any> {
  return this.http.post('/ShipmentMasterService.asmx/UpdateArrivalDate', payload);
}

/**
 * Deletes a shipment entry by its ID.
 * Sends an API request to delete a specific shipment entry.
 * @param id The ID of the shipment to delete.
 * @returns An Observable indicating the result of the operation.
 */
deleteShipment(id: number): Observable<any> {
  const payload = { id };
  return this.http.post('/ShipmentMasterService.asmx/Delete', payload);
}

/**
 * Updates a shipment with the provided payload.
 * Sends an API request to update the shipment details.
 * @param payload The payload containing the updated shipment data.
 * @returns An Observable with the API response.
 */
updateShipment(payload: { model: any }): Observable<any> {
  return this.http.post('/ShipmentMasterService.asmx/Update', payload);
}
}
