import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-add-input",
  templateUrl: "./add-input.component.html",
  styleUrls: ["./add-input.component.css"],
})
export class AddInputComponent implements OnInit, OnChanges {
  @Input() dataInput: string | undefined;
  @Output() dataOutput = new EventEmitter<string>();
  @Output() clickAdd = new EventEmitter<Event>();
  @Input() admitsUndefined: boolean = false;

  addControl = new FormControl("");
  faPlus = faPlus;

  ngOnInit(): void {
    this.addControl.enable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataInput != undefined) {
      this.addControl.setValue(this.dataInput);
    }
    if (changes["lock"]) {
      this.addControl.enable();
    }
  }

  updateFormControl(event: any): void {
    const value = event.target.value;
    this.addControl.setValue(value);
    this.dataOutput.emit(value);
  }
  add(event: any): void {
    this.clickAdd.emit(event);
  }
}
