import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencil, faBan, faPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';
import { InsuranceService } from 'src/app/services/sales/insurance.service';
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid, isEmptyGuid } from '../../../../services/util.service';

@Component({
  selector: 'app-insurance-detail',
  templateUrl: './insurance-detail.component.html',
  styleUrls: ['./insurance-detail.component.css']
})
export class InsuranceDetailComponent implements OnInit {
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faUsers = faUsers;

  mode: string = "Add";
  insAccountId: string = getEmptyGuid();

  formFields: FormField[] = [
    { type: 'text', id: 'AcCode', label: 'Insurance Company', value: '', lock: false },
    { type: 'text', id: 'Address1', label: 'Address1', value: '' },
    { type: 'text', id: 'Address2', label: 'Address2', value: '' },
    { type: 'text', id: 'Address3', label: 'Address3', value: '' },
    { type: 'text', id: 'ContactNumber', label: 'Contact Number 1', value: '' },
    { type: 'text', id: 'Acname', label: 'Description', value: '' },
    { type: 'text', id: 'ContactPerson', label: 'Contact Person', value: '' },
    { type: 'switch', id: 'DisplayStatus', label: 'Display', value: false }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private insuranceService: InsuranceService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.insAccountId = params['id'];

      if (!isEmptyGuid(this.insAccountId)) {
        this.mode = 'Edit';
        this.loadInsuranceDetail();
      }
    });
  }

  private setInsuranceCompanyLock(isLocked: boolean): void {
    const insuranceCompanyField = this.formFields.find(field => field.id === 'AcCode');
    if (insuranceCompanyField) {
      insuranceCompanyField.lock = isLocked;
    }
  }

  private loadInsuranceDetail(): void {
    this.insuranceService.getDetail(this.insAccountId).subscribe({
      next: (response) => {
        const data = response?.body;
        if (data) {
          this.formFields = [
            { type: 'text', id: 'AcCode', label: 'Insurance Company', value: data.AcCode, lock: true },
            { type: 'text', id: 'Acname', label: 'Description', value: data.Acname },
            { type: 'text', id: 'Address1', label: 'Address1', value: data.Address1 },
            { type: 'text', id: 'Address2', label: 'Address2', value: data.Address2 },
            { type: 'text', id: 'Address3', label: 'Address3', value: data.Address3 },
            { type: 'text', id: 'ContactNumber', label: 'Contact Number 1', value: data.ContactNumber || '' },
            { type: 'text', id: 'ContactPerson', label: 'Contact Person', value: data.ContactPerson || '' },
            { type: 'switch', id: 'DisplayStatus', label: 'Display', value: data.DisplayStatus }
          ];
        } else {
          console.error("Error: No data found in response.");
          this.notifier.notify('error', 'No data found in response');
        }
      },
      error: (error) => {
        console.error("Error loading insurance detail:", error);
        this.notifier.notify('error', 'Error loading insurance detail');
      }
    });
  }

  onSave(): void {
    const formData = this.getFormValues();
    
    if (this.mode === 'Add') {
      formData["InsAccountId"] = getEmptyGuid();
      formData["IsDeleted"] = "";
      formData["AttentionTitle"] = ""; 
      this.addInsurance({ data: formData });
    } else if (this.mode === 'Edit') {
      formData["InsAccountId"] = this.insAccountId;
      formData["IsDeleted"] = false;
      this.updateInsurance({ data: formData });
    }
  }

  private addInsurance(data: any): void {
    this.insuranceService.saveDetails(data).subscribe({
      next: () => {
        this.notifier.notify('success', 'Insurance company added successfully');
        this.router.navigate(['../../insurance'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error adding insurance company:", error);
        this.notifier.notify('error', 'Error adding insurance company');
      }
    });
  }

  private updateInsurance(data: any): void {
    this.insuranceService.saveDetails(data).subscribe({
      next: () => {
        this.notifier.notify('success', 'Insurance company updated successfully');
        this.router.navigate(['../../insurance'], { relativeTo: this.route });
      },
      error: (error) => {
        console.error("Error updating insurance company:", error);
        this.notifier.notify('error', 'Error updating insurance company');
      }
    });
  }

  private getFormValues(): { [key: string]: any } {
    return this.formFields.reduce((formValues: { [key: string]: any }, field: FormField) => {
      formValues[field.id] = field.value;
      return formValues;
    }, {});
  }

  onCancel(): void {
    this.router.navigate(['../../insurance'], { relativeTo: this.route });
  }
}
