import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilService } from '../http-util.service'; // Adjust the path based on your project

@Injectable({
  providedIn: 'root',
})
export class FactoryStandardEquipmentAndItemsService {

  constructor(private http: HttpUtilService) {}

  /**
   * Fetches all available categories.
   * Calls: FactoryStandardEquipmentService.asmx/GetAllCategory
   */
  getAllCategory(): Observable<any> {
    const url = `/FactoryStandardEquipmentService.asmx/GetAllCategory`;
    return this.http.get(url);
  }

  /**
   * Retrieves DeliveryCer data by category.
   * Calls: FactoryStandardEquipmentService.asmx/GetDeliveryCerByCategory
   * The 'category' parameter must be enclosed in double quotes and URL-encoded.
   * @param category Example: 'A'
   */
  getDeliveryCerByCategory(category: string): Observable<any> {
    // Format the category parameter as required: category=%22A%22
    const encodedCategory = encodeURIComponent(`"${category}"`);
    const url = `/FactoryStandardEquipmentService.asmx/GetDeliveryCerByCategory?category=${encodedCategory}`;
    return this.http.get(url);
  }

  /**
   * Updates DeliveryCer data for a specific category.
   * @param model Object containing category and item descriptions.
   */
  updateDeliveryCerByCategory(model: any): Observable<any> {
    const url = `/FactoryStandardEquipmentService.asmx/UpdateDeliveryCerByCategory`;
    return this.http.post(url, { model });
  }
}
