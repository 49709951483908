<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">FOB Invoice Price</h1>
      <app-navpath />
    </div>
    <div class="content-container">
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">FOB Invoice Price</h2>
        <app-data-form
          [dataFields]="fobTable"
          [dataName]="'fobTable'"
        />
        <div class="mt-4 flex w-fit gap-1">
            <button class="shorter-btn darker-primary-btn">
              <fa-icon [icon]="faSave" class="mr-1" />Update
            </button>
            <button class="shorter-btn gray-btn">
              <fa-icon [icon]="faTrash" class="mr-1" />Clear
            </button>
        </div>
      </section>
    </div>
    <div class="content-container">
        <app-data-table
            [tableHeaders]="fob_headers"
            [tableEntries]="fob_entries"
        />
    </div>
</section>
