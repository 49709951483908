import { Component, OnInit } from '@angular/core';
import { faPlus, faTrash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { PromotionsService } from 'src/app/services/vehicle/promotions.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from 'src/app/services/util.service'; // Asegúrate de que esta ruta es correcta

@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.css']
})
export class PromotionListComponent implements OnInit {

  searchInput: string = "";
  promotions: any[] = [];
  filteredPromotions: any[] = [];

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    { title: "", type: "checkbox", id: "IsChecked" },
    { title: "PROMOTION CODE", type: "link", id: "PromotionCode" },
    { title: "DESCRIPTION", type: "data", id: "Description" },
    { title: "STATUS", type: "data", id: "Status" },
    { title: "ID", type: "hidden", id: "PromotionFreeItemId" }
  ];

  constructor(
    private promotionsService: PromotionsService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAllPromotions();
  }

  private getAllPromotions(): void {
    this.promotionsService.getAll().subscribe(
      (response) => {
        this.promotions = response.body.map((promotion: any) => {
          return {
            "IsChecked": false,
            "PromotionCode": promotion.PromotionCode || "",
            "Description": promotion.Description || "",
            "Status": promotion.Status ? '✔️' : '',
            "PromotionFreeItemId": promotion.PromotionFreeItemId || ""
          };
        });
        this.filteredPromotions = [...this.promotions];
      },
      (error: any) => {
        console.error("Error fetching promotion data:", error);
      }
    );
  }

  onSearch(searchValue: string): void {
    const lowerCaseSearch = searchValue.toLowerCase();
    this.filteredPromotions = this.promotions.filter(item =>
      item.PromotionCode.toLowerCase().includes(lowerCaseSearch) ||
      item.Description.toLowerCase().includes(lowerCaseSearch)
    );
  }

  deleteSelectedPromotions(): void {
    const selectedItems = this.filteredPromotions
      .filter(item => item.IsChecked)
      .map(item => ({
        ...item,
        Status: item.Status === '✔️' ? true : false 
      }));

    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one promotion to delete.');
      return;
    }

    this.promotionsService.deletePromotionFreeItems(selectedItems).subscribe({
      next: () => {
        this.notifier.notify('success', 'Selected promotions deleted successfully');
        this.filteredPromotions = this.filteredPromotions.filter(item => !item.IsChecked);
      },
      error: (error: any) => {
        console.error("Error deleting promotions:", error);
        this.notifier.notify('error', 'Error deleting selected promotions.');
      }
    });
  }

  openEntry(value: any): void {
    const promotionId = value.PromotionFreeItemId; 
    this.router.navigate([`../promotion-free-item`, promotionId], {
      relativeTo: this.route,
    });
  }

  addEntry(): void {
    const emptyGuid = getEmptyGuid(); 
    this.router.navigate([`../promotion-free-item`, emptyGuid], {
      relativeTo: this.route,
    });
  }
}
