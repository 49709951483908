<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      <fa-icon [icon]="faCalculator" class="mr-1" />GST Calculation
      Receipt
    </h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Search</h2>
      <app-data-form
        [dataFields]="searchBooking"
        [dataName]="'searchBooking'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Total amounts of contract</h2>
      <app-data-form
        [dataFields]="totalAmountsofcontract"
        [dataName]="'totalAmountsofcontract'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Less amounts not taxable</h2>
      <app-data-form
        [dataFields]="lessAmountsNotTaxable"
        [dataName]="'lessAmountsNotTaxable'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Others (Non-GST Vehicle Only)</h2>
      <app-data-form
        [dataFields]="othersNonGSTVehicleOnly"
        [dataName]="'othersNonGSTVehicleOnly'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Rebates not included in GST calculation</h2>
      <app-data-form
        [dataFields]="rebatesNotIncludedGSTcalculation"
        [dataName]="'rebatesNotIncludedGSTcalculation'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Others</h2>
      <app-data-form
        [dataFields]="others"
        [dataName]="'others'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
  </div>
  <div class="mt-4 flex w-fit gap-1">
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faFloppyDisk" class="mr-1" />Save
    </button>
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faPrint" class="mr-1" />Print
    </button>
    <button class="shorter-btn darker-primary-btn">
      <fa-icon [icon]="faLink" class="mr-1" />To Invoice
    </button>
  </div>
</section>
