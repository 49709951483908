import { Component } from '@angular/core';
import {
  faPlus,
  faMinus,
  faCircle,
  faPencil,
  faTrashCan,
  faTrash,
  faFloppyDisk,
  faCancel,
  faMagnifyingGlass,
  faSearch,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-open-category-coe-depository',
  templateUrl: './open-category-coe-depository.component.html',
  styleUrls: ['./open-category-coe-depository.component.css']
})
export class OpenCategoryCoeDepositoryComponent {
  titleSelect: string | undefined;
  coeType: string | undefined;
  modeContac: string | undefined;
  carType: string | undefined;
  discount: string | undefined;
  paymentType: string | undefined;
  loanPeriod: string | undefined;
  dealrName: string | undefined;
  insuranceCompany: string | undefined;
  titles: string[] = ["MR", "MRS", "MISS", "M/S", "MMDM", "DR", "MS"];
  coes: string[] = ["Bidding", "Own COE", "Immediate E"];
  cartypes: string[] = [
    "Standard",
    "Off Peak Car(OPC)",
    "Compressed Natural Gas (CNG)",
    "CNG OPC",
    "OPC",
  ];
  modscontac: string[] = [
    "REFERRAL",
    "REPEAT",
    "WALK-IN",
    "POSTALE",
    "ONLINE SALES",
  ];
  discounts: string[] = ["500", "700", "1000"];
  paymenttypes: string[] = [
    "AIG OTHERS",
    "AIG IN HOUSE",
    "AXA INSURANCE OTHERS",
    "AXA INSURANCE IN-HOUSE",
    "LIBERTY INSURANCE IN-HOUSE",
    "MSIG INSURANCE IN-HOUSE",
    "NTUC INCOME OTHERS",
    "NTUC INCOME IN-HOUSE",
    "OWN INSURANCE",
  ];
  loanperiods: string[] = [
    "12",
    "24",
    "36",
    "48",
    "60",
    "72",
    "84",
    "96",
    "108",
    "116",
    "118",
    "120",
  ];
  dealernames: string[] = [
    "No Trade In",
    "BOEKI AUTO & MARINE PTE LTD",
    "KMPL USED CAR DIVISION",
  ];

  faPlus = faPlus;
  faMinus = faMinus;
  faCircle = faCircle;
  faPencil = faPencil;
  faTrashCan = faTrashCan;
  faFloppyDisk = faFloppyDisk;
  faCancel = faCancel;
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faSearch = faSearch
  faTriangleExclamation = faTriangleExclamation

  textInput: string = "";
  required: boolean = true;
  searchText = "";
  searchControl = new FormControl("");
  items = ["Element1", "Element2", "Element3", "Element4"];
  filteredItems: string[] = [];
  showList = false;

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe((value) => {
        this.filterItems(value);
        if(value){
          this.showList = true;
        }
      });
  }

  filterItems(searchText: string | null): void {
    if (!searchText) {
      this.filteredItems = [];
      this.showList = false;
    } else {
      this.filteredItems = this.items.filter((item) =>
        item.toLowerCase().includes(searchText.toLowerCase()),

      );
       this.showList = this.filteredItems.length > 0
    }
  }

  selectItem(item: string): void {
    this.searchControl.setValue(item);
    this.filteredItems = [];
    this.showList = false;
  }
}
