import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpResponse, HttpUtilService} from "../http-util.service";
import {CrmRoleRight, RoleRequest, RoleRight} from "../../models/administration/user-type-management/role-right.model";

const SERVICE_NAME = 'KapsUserService.asmx';

@Injectable({
  providedIn: "root",
})
export class UserTypeManagementService {
  constructor(private http: HttpUtilService) {
  }

  getAllRoles(): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_NAME}/GetAllRoles`);
  }

  getRoleRights(): Observable<HttpResponse<RoleRight[]>> {
    return this.http.get(`${SERVICE_NAME}/GetRoleRights`);
  }

  getRoleRightsById(roleId: string): Observable<HttpResponse<CrmRoleRight>> {
    return this.http.get(`${SERVICE_NAME}/GetRoleRightsById`, {params: {roleId}},);
  }

  addRole(role: RoleRequest): Observable<HttpResponse<{}>> {
    //CHECKBOX INPUTS ARE NOT BEING SAVED, THIS IS BUG FROM BACKEND
    return this.http.post(`${SERVICE_NAME}/AddRole`, {model: role},);
  }

  updateRole(role: RoleRequest): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVICE_NAME}/UpdateRole`, {model: role},);
  }
}
