import { Component } from '@angular/core';
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-reservation-price-list-master-list',
  templateUrl: './reservation-price-list-master-list.component.html',
  styleUrls: ['./reservation-price-list-master-list.component.css']
})
export class ReservationPriceListMasterListComponent {
  constructor() {}

  ngOnInit() {}

  searchInput: string = "";

  //Icons imports
  faPlus = faPlus;
  faTrash = faTrash;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "MODEL SHORT DESC",
      type: "link",
    },
    {
      title: "MODEL GROUP",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "MODEL LONG DESC",
      type: "data",
    },
    {
      title: "ENG. CAP",
      type: "data",
    },
    {
      title: "SEQ",
      type: "data",
    },
    {
      title: "SUB SEQ",
      type: "data",
    },
    {
      title: "CATEGORY",
      type: "data",
    },
    {
      title: "EFFECTIVE DATE",
      type: "data",
    },
    {
      title: "ARF NET",
      type: "data",
    },
    {
      title: "REG FEE",
      type: "data",
    },
    {
      title: "COE OFF SET",
      type: "data",
    },
    {
      title: "6 MTHS ROAD TAX",
      type: "data",
    },
    {
      title: "PRICE",
      type: "data",
    },
    {
      title: "CNG PRICE",
      type: "data",
    }
  ];

  reservations = [
    {
      "checked": false,
      "modelShortDesc": "SANTA FE 1.6 TURBO HYBRID (S/R) 7-SEATER",
      "modelGroup": "SANTA FE",
      "modelLongDesc": "HKW7D5G1FEV1",
      "engCap": 1598,
      "seq": 1,
      "subSeq": 2,
      "category": "B",
      "effectiveDate": "17/05/2024",
      "arfNet": 253278,
      "regFee": 350,
      "coeOffSet": 84000,
      "sixMthsRoadTax": 371,
      "price": 337999
    },
    {
      "checked": false,
      "modelShortDesc": "SANTA FE 1.6 TURBO HYBRID 7-SEATER",
      "modelGroup": "SANTA FE",
      "modelLongDesc": "HKW7D5G1FEV1",
      "engCap": 1598,
      "seq": 1,
      "subSeq": 1,
      "category": "B",
      "effectiveDate": "17/05/2024",
      "arfNet": 233278,
      "regFee": 350,
      "coeOffSet": 84000,
      "sixMthsRoadTax": 371,
      "price": 337999
    },
  ]
}
