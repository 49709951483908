import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class DiscountService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets all model discounts.
   * @returns An Observable of the model discounts list.
   */
  getModelDiscount(): Observable<any> {
    return this.http.get('/ModelSpecificationService.asmx/GetModelDiscount');
  }

  /**
   * Gets all immediate discounts for models.
   * @returns An Observable of the model immediate discounts list.
   */
  getModelImmediateDiscount(): Observable<any> {
    return this.http.get('/ModelSpecificationService.asmx/GetModelImmediateDiscount');
  }

  /**
  * Updates model discount details.
  * @param model - The model data to update.
  * @returns An Observable for the result of the update operation.
  */
  updateModelDiscount(model: any): Observable<any> {
  return this.http.post('/ModelSpecificationService.asmx/UpdateModelDiscount', model);
  }


  /**
 * Updates model immediate discount details.
 * @param model - The model data to update for immediate discount.
 * @returns An Observable for the result of the update operation.
 */
  updateModelImmediateDiscount(model: any): Observable<any> {
  return this.http.post('/ModelSpecificationService.asmx/UpdateModelImmediateDiscount', model);
  }
}
