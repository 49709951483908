<section class="page-container">
    <div class="flex items-center justify-between">
        <h1 class="main-heading">
            Cards Deposit Listing
        </h1>
        <app-navpath />
    </div>
    <div class="content-container">
        <app-data-form [dataFields]="cardsForm" />
        <div class="mb-2 flex w-fit gap-1">
            <button class="shorter-btn darker-primary-btn" (click)="showReceiptAndUpdateTotal()">
                <fa-icon [icon]="faSave" class="mr-1" />Show Receipt
            </button>
            <button class="shorter-btn darker-primary-btn">
              <fa-icon [icon]="faPrint" class="mr-1" />Print
            </button>
            <button class="shorter-btn gray-btn">
                <fa-icon [icon]="faLink" class="mr-1" />Clear
            </button>
            <button class="shorter-btn darker-primary-btn">
                <fa-icon [icon]="faSave" class="mr-1" />Export to Excel
            </button>
        </div>
    </div>
    <div class="content-container" *ngIf="showReceiptTable">
        <h2 class="sub-heading w-full border-b">Cards Deposit Listing</h2>
        <app-data-table
            [tableHeaders]="listing_headers"
            [tableEntries]="listing"
        />
    </div>
</section>
