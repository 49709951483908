import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpResponse, HttpUtilService} from "../http-util.service";

const SERVICE_NAME = 'SecuritySvc.asmx';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private http: HttpUtilService) {
  }

  /**
   * Switches the current company context to the specified company.
   *
   * @param {string} companyCode - The code of the company to switch to.
   * @returns {Observable<HttpResponse<any>>} An Observable of the HTTP response.
   */
  switchCompany(companyCode: string): Observable<HttpResponse<any>> {
    return this.http.post(`${SERVICE_NAME}/SwitchCompany`, {comCode: companyCode})
  }
}
