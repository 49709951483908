import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

export enum MessageType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export interface Message {
  text: string;
  type: MessageType;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private messageSubject = new BehaviorSubject<Message | null>(null);
  $message = this.messageSubject.asObservable();

  showMessage(text: string, type: MessageType): void {
    this.messageSubject.next({text, type});
    setTimeout(() => {
      this.clearMessage();
    }, 3000);
  }

  clearMessage(): void {
    this.messageSubject.next(null);
  }
}
