<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">COE Bidding Preparation</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="coeBiddingPreparation"
      [dataName]="'coeBiddingPreparation'"
      (updateFields_child)="updateFields($event)"
    />
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn green-btn">
        <fa-icon [icon]="faPencil" class="mr-1" />Export
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </div>
</section>
