import { Component } from '@angular/core';
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-general-enquiry',
  templateUrl: './general-enquiry.component.html',
  styleUrls: ['./general-enquiry.component.css']
})
export class GeneralEnquiryComponent {
  faPencil = faPencil

  searchCriteria: FormField[] = [
    {
      type: "radio",
      id: "searchBy",
      options: [
        {
          display: "Contract No.",
          value: "contractNumber",
        },
        {
          display: "Buyer Order No.",
          value: "buyerOrderNumber",
        },
        {
          display: "Buyer Name",
          value: "buyerName",
        },
        {
          display: "Vehicle No.",
          value: "vehicleNumber",
        },
      ],
    },
    {
      type: "search",
      id: "desciption",
      label: "Description",
    },
  ]

  searchResult: FormField[] = [
    {
      type: "text",
      id: "contractNumber",
      label: "Contract Number",
      lock: true,
    },
    {
      type: "text",
      id: "buyerOrderNumber",
      label: "Buyer Order Number",
      lock: true,
    },
    {
      type: "text",
      id: "buyerName",
      label: "Buyer Name",
      lock: true,
    },
    {
      type: "text",
      id: "chequeDate",
      label: "Cheque Date",
      lock: true,
    },
    {
      type: "text",
      id: "payTo",
      label: "Pay To",
      lock: true,
    },
    {
      type: "text",
      id: "amount",
      label: "Amount",
      lock: true,
    },
    {
      type: "text",
      id: "purpose",
      label: "Purpose",
      lock: true,
    },
    {
      type: "text",
      id: "bank",
      label: "Bank",
      lock: true,
    },
    {
      type: "text",
      id: "chequeNumber",
      label: "Cheque Number",
      lock: true,
    },  
  ];

  general_enquiry_headers = [
    {
      title: "PAY TO",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BUYER NAME",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "CONTRACT NO",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "CHEQUE DATE",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "CHEQUE NO",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "AMOUNT",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "PURPOSE",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BANK",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BUYER ORDER NO",
      type: "data",
    },
    {
      title: "CANCEL CHEQUE",
      type: "data",
    },
    {
      title: "SELECT",
      type: "data",
    },
  ];

  general_enquiry_data = [
    {
      "payTo": "ORIENT CREDIT PTE LTD",
      "buyerName": "RAVINDRAN S/O DORAISAMY",
      "contractNumber": "03090174",
      "chequeDate": "22/09/2003",
      "chequeNumber": "810755",
      "amount": 26612.00,
      "purpose": "OVERPAYMENT REFUND",
      "bank": "DBS BANK",
      "buyerOrderNumber": "",
      "cancelCheque": "",
      "select": ""
    },
    {
      "payTo": "ORIENT CREDIT PTE LTD",
      "buyerName": "SURANDRAN S/O KUNASINGAM",
      "contractNumber": "03090549",
      "chequeDate": "30/09/2003",
      "chequeNumber": "811000",
      "amount": 3000.00,
      "purpose": "UNSUCCESSFUL COE CANCELLATION",
      "bank": "DBS BANK",
      "buyerOrderNumber": "",
      "cancelCheque": "",
      "select": ""
    },
    {
      "payTo": "RAMACHANDRAN S/O KOCHADAI MUTHIAH",
      "buyerName": "RAMACHANDRAN S/O KOCHADAI MUTHIAH",
      "contractNumber": "03090585",
      "chequeDate": "06/10/2003",
      "chequeNumber": "811097",
      "amount": 3092.93,
      "purpose": "OVERPAYMENT REFUND",
      "bank": "DBS BANK",
      "buyerOrderNumber": "",
      "cancelCheque": "",
      "select": ""
    },
    {
      "payTo": "Toh Meng Ping",
      "buyerName": "RAMACHANDRAN S/O KOCHADAI MUTHIAH",
      "contractNumber": "03090585",
      "chequeDate": "06/10/2003",
      "chequeNumber": "811098",
      "amount": 1108.88,
      "purpose": "OVERPAYMENT REFUND",
      "bank": "DBS BANK",
      "buyerOrderNumber": "",
      "cancelCheque": "",
      "select": ""
    },
    {
      "payTo": "SONATA AUTO PTE LTD",
      "buyerName": "FRANCIS GOH CHIN YUEN",
      "contractNumber": "03100445",
      "chequeDate": "15/10/2003",
      "chequeNumber": "811181",
      "amount": 2000.00,
      "purpose": "UNSUCCESSFUL COE CANCELLATION",
      "bank": "DBS BANK",
      "buyerOrderNumber": "",
      "cancelCheque": "",
      "select": ""
    },
    {
      "payTo": "ORIENT CREDIT PTE LTD",
      "buyerName": "MANOKARAN S/O SINNAIYA PILLAI",
      "contractNumber": "03100367",
      "chequeDate": "20/10/2003",
      "chequeNumber": "811266",
      "amount": 29136.00,
      "purpose": "OVERPAYMENT REFUND",
      "bank": "DBS BANK",
      "buyerOrderNumber": "",
      "cancelCheque": "",
      "select": ""
    },
    {
      "payTo": "Choo Boon Heong",
      "buyerName": "RAMALINGAM BASKARAN PUGAZH",
      "contractNumber": "03100303",
      "chequeDate": "20/10/2003",
      "chequeNumber": "811270",
      "amount": 2000.00,
      "purpose": "UNSUCCESSFUL COE CANCELLATION",
      "bank": "DBS BANK",
      "buyerOrderNumber": "",
      "cancelCheque": "",
      "select": ""
    }
  ]
}
