import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  CommonModule,
  NgComponentOutlet,
  NgOptimizedImage,
} from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./http-interceptor/auth-interceptor"; // Adjust the path as necessary
import { NotifierModule } from "angular-notifier";

// Modules
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDialogModule } from "@angular/material/dialog";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Components
import { NavbarComponent } from "./components/navbar/navbar.component";
import { SidenavbarComponent } from "./components/sidenavbar/sidenavbar.component";
import { HttpClientModule } from "@angular/common/http";
import { NavpathComponent } from "./components/navpath/navpath.component";
import { ChatWindowComponent } from "./components/chat-window/chat-window.component";

// Services
import { UserManagementService } from "./services/administration/user-management.service";
import { KapsSettingsService } from "./services/administration/kaps-settings.service";
import { AuthService } from "./services/auth.service";
import { AuditMasterService } from "./services/administration/audit-master.service";
import { UserTypeManagementService } from "./services/administration/use-type-management.service";
import { UserRightsManagementService } from "./services/administration/user-rights-management.service";
import { SalesTeamShuffleService } from "./services/administration/sales-team-shuffle.service";
import { CustomerSearchService } from "./services/customer/customer-search.service";
import { ClickDriveCustomerService } from "./services/customer/click-drive-customer.service";
import { ProspectService } from "./services/customer/prospect.service";
import { PremiumCareMemberService } from "./services/customer/premium-care-member.service";
import { PremiumCareMemberStatusService } from "./services/customer-reports/premium-care-member-status.service";
import { PremiumCareMemberEntitlementService } from "./services/customer-reports/premium-care-member-entitlement.service";
import { BookingService } from "./services/vehicle/booking.service";
import { InteriorColourService } from "./services/vehicle/interior-colour.service";
import { VehicleSelectionService } from "./services/vehicle/vehice-selection.service";
import { VehicleStockAvailabilityService } from "./services/vehicle/vehicle-stock-availability.service";
import { PromotionsService } from "./services/vehicle/promotions.service";
import { AccessoryService } from "./services/vehicle/accessory.service";
import { ColoursByModelService } from "./services/vehicle/colours-by-model.service";
import { DiscountService } from "./services/vehicle/discount.service";
import { HirePurchaseService } from "./services/sales/hire-purchase.service";
import { InsuranceService } from "./services/sales/insurance.service";
import { ControlParametersService } from "./services/sales/control-parameters.service";
import { InventoryVehicleService } from "./services/sales/inventory-vehicle.service";
import { LoanStatusReportService } from "./services/sales/loan-status-report.service";
import { PriceListTemplateService } from "./services/sales/price-list-template.service";
import { PriceListTemplateReportService } from "./services/sales/price-list-template-report.service";
import { CreditMemoService } from "./services/sales/credit-memo.service";
import { PriceListMasterService } from "./services/sales/price-list-master.service";
import { LoanStatusService } from "./services/sales/loan-status.service";
import { SpecialPriceListMasterService } from "./services/sales/special-price-list-master.service";
import { EmailBlackListService } from "./services/sales/email-black-list.service";
import { AllInvoicesService } from "./services/sales-reports/all-invoices.service";
import { VehicleStockReportService } from "./services/sales-reports/vehicle-stock-report.service";
import { WeeklyFocusDashboardService } from "./services/sales/weekly-focus-dashboard.service";
import { BuyersOrderReportService } from "./services/sales/buyers-order-report.service";
import { WeeklyFocusReportService } from "./services/sales/weekly-focus-report.service";
import { FactoryStandardEquipmentAndItemsService } from "./services/sales/factory-standard-equipment-and-items.service";
import { ChequeIssuedService } from "./services/sales-reports/cheque-issued";
import { CancellationReportService } from "./services/sales-reports/cancellation-report.service";
import { VehicleStockSummaryReportService } from "./services/sales/vehicle-stock-summary-report.service";
import { OutstandingDeliveryService } from "./services/sales-reports/outstanding-delivery";
import { FinalShortPaymentService } from "./services/sales-reports/final-short-payment";
import { OutstandingCoeBiddingService } from "./services/sales-reports/outstanding-coe-bidding.service";
import { MailingLabelService } from "./services/sales-reports/mailing-label.service";
import { OutstandingCarAllocationService } from "./services/sales-reports/outstanding-car-allocation.service";
import { BuyersOrderReportDynamicService } from "./services/sales-reports/buyers-order-report-dynamic.service";
import { RegistrationReportService } from "./services/sales-reports/registration-report.service";
import { UnpaidInvoicesReportService } from "./services/sales-reports/unpaid-invoices-report.service";
import { VehicleOverbookReportService } from "./services/sales-reports/vehicle-overbook-report.service";
import { ProspectReportService } from "./services/sales-reports/prospect-report.service";

// Common components
import { TableSearchInputComponent } from "./components/common/inputs/table-search-input/table-search-input.component";
import { DataTableComponent } from "./components/common/data-table/data-table.component";
import { SearchInputComponent } from "./components/common/inputs/search-input/search-input.component";
import { TextInputComponent } from "./components/common/inputs/text-input/text-input.component";
import { DataFormComponent } from "./components/common/data-form/data-form.component";
import { SelectInputComponent } from "./components/common/inputs/select-input/select-input.component";
import { SelectMultipleInputComponent } from "./components/common/inputs/select-multiple-input/select-multiple-input.component";
import { AddInputComponent } from "./components/common/inputs/add-input/add-input.component";
import { PrintInputComponent } from "./components/common/inputs/print-input/print-input.component";
import { UpdateInputComponent } from "./components/common/inputs/update-input/update-input.component";

// Pages
import { AccessoryDetailComponent } from "./pages/vehicle/accessory/accessory-detail/accessory-detail.component";
import { AccessoryListComponent } from "./pages/vehicle/accessory/accessory-list/accessory-list.component";
import { AccountAdjustmentComponent } from "./pages/autopac/account-adjustment/account-adjustment.component";
import { AccountCodeCreationComponent } from "./pages/autopac/account-code-creation/account-code-creation.component";
import { AmendChequePurposeComponent } from "./pages/autopac/amend-cheque-purpose/amend-cheque-purpose.component";
import { AuditMasterComponent } from "./pages/administration/audit-master/audit-master.component";
import { BookingComponent } from "./pages/vehicle/booking/booking.component";
import { CarAllocationComponent } from "./pages/autopac/car-allocation/car-allocation.component";
import { CardsDepositListingComponent } from "./pages/autopac/cards-deposit-listing/cards-deposit-listing.component";
import { CarRegistrationComponent } from "./pages/autopac/car-registration/car-registration.component";
import { CashDepositListingComponent } from "./pages/autopac/cash-deposit-listing/cash-deposit-listing.component";
import { ChequeDepositListingComponent } from "./pages/autopac/cheque-deposit-listing/cheque-deposit-listing.component";
import { ChequeEnquiryComponent } from "./pages/autopac/cheque-enquiry/cheque-enquiry.component";
import { ChequeIssueComponent } from "./pages/autopac/cheque-issue/cheque-issue.component";
import { CoeAllocationComponent } from "./pages/autopac/coe-allocation/coe-allocation.component";
import { CoeBiddingPreparationComponent } from "./pages/autopac/coe-bidding-preparation/coe-bidding-preparation.component";
import { CoeBiddingResultsComponent } from "./pages/autopac/coe-bidding-results/coe-bidding-results.component";
import { CoeBidStaffComponent } from "./pages/autopac/coe-bid-staff/coe-bid-staff.component";
import { ColoursByModelDetailComponent } from "./pages/vehicle/colours-by-model/colours-by-model-detail/colours-by-model-detail.component";
import { ColoursByModelListComponent } from "./pages/vehicle/colours-by-model/colours-by-model-list/colours-by-model-list.component";
import { ContractManagementComponent } from "./pages/autopac/contract-management/contract-management.component";
import { CustomerEnquiryComponent } from "./pages/autopac/customer-enquiry/customer-enquiry.component";
import { DiscountComponent } from "./pages/vehicle/discount/discount.component";
import { ExportToHMCComponent } from "./pages/autopac/export-to-hmc/export-to-hmc.component";
import { ExportToHmcNewComponent } from "./pages/autopac/export-to-hmc-new/export-to-hmc-new.component";
import { FinanceVehicleReceiptComponent } from "./pages/autopac/finance-vehicle-receipt/finance-vehicle-receipt.component";
import { GeneralEnquiryComponent } from "./pages/autopac/general-enquiry/general-enquiry.component";
import { GiroPaymentComponent } from "./pages/autopac/giro-payment/giro-payment.component";
import { GstCalculationComponent } from "./pages/autopac/gst-calculation/gst-calculation.component";
import { InteriorColourDetailComponent } from "./pages/vehicle/interior-colour/interior-colour-detail/interior-colour-detail.component";
import { InteriorColourListComponent } from "./pages/vehicle/interior-colour/interior-colour-list/interior-colour-list.component";
import { InvoiceComponent } from "./pages/autopac/invoice/invoice.component";
import { LaDebuggeadoraComponent } from "./pages/la-debuggeadora/la-debuggeadora.component";
import { MasterColourDetailComponent } from "./pages/vehicle/master-colour/master-colour-detail/master-colour-detail.component";
import { MasterColourListComponent } from "./pages/vehicle/master-colour/master-colour-list/master-colour-list.component";
import { ModelAndSpecificationDetailComponent } from "./pages/vehicle/model-and-specification/model-and-specification-detail/model-and-specification-detail.component";
import { ModelAndSpecificationListComponent } from "./pages/vehicle/model-and-specification/model-and-specification-list/model-and-specification-list.component";
import { MonthlyCommissionComponent } from "./pages/autopac/monthly-commission/monthly-commission.component";
import { OpenCategoryCoeDepositoryComponent } from "./pages/autopac/open-category-coe-depository/open-category-coe-depository.component";
import { ParfTransferDateComponent } from "./pages/autopac/parf-transfer-date/parf-transfer-date.component";
import { PaymentStatusReadOnlyComponent } from "./pages/autopac/payment-status-read-only/payment-status-read-only.component";
import { PromotionDetailComponent } from "./pages/vehicle/promotion/promotion-detail/promotion-detail.component";
import { PromotionListComponent } from "./pages/vehicle/promotion/promotion-list/promotion-list.component";
import { ReinstateBookingComponent } from "./pages/vehicle/reinstate-booking/reinstate-booking.component";
import { ReservationComponent } from "./pages/reservation/reservation/reservation.component";
import { ReservationDashboardComponent } from "./pages/reservation/reservation-dashboard/reservation-dashboard.component";
import { ReservationPriceListMasterDetailComponent } from "./pages/reservation/reservation-price-list-master/reservation-price-list-master-detail/reservation-price-list-master-detail.component";
import { ReservationPriceListMasterListComponent } from "./pages/reservation/reservation-price-list-master/reservation-price-list-master-list/reservation-price-list-master-list.component";
import { TaxiRegistrationComponent } from "./pages/autopac/taxi-registration/taxi-registration.component";
import { UpdateVehicleRateComponent } from "./pages/autopac/update-vehicle-rate/update-vehicle-rate.component";
import { VehicleSelectionComponent } from "./pages/vehicle/vehicle-selection/vehicle-selection.component";
import { VehicleStockAvailabilityComponent } from "./pages/vehicle/vehicle-stock-availability/vehicle-stock-availability.component";
import { EtaDateUpdationComponent } from "./pages/autopac/eta-date-updation/eta-date-updation.component";
import { OpenCategoryCoeTransferComponent } from "./pages/autopac/open-category-coe-transfer/open-category-coe-transfer.component";
import { LocationMasterListComponent } from "./pages/autopac/location-master/location-master-list/location-master-list.component";
import { LocationMasterDetailComponent } from "./pages/autopac/location-master/location-master-detail/location-master-detail.component";
import { PaymentListComponent } from "./pages/autopac/payment-list/payment-list.component";
import { PaymentStatusComponent } from "./pages/autopac/payment-status/payment-status.component";
import { PremiumCareMemberStatusComponent } from "./pages/customer-reports/premium-care-member-status/premium-care-member-status.component";
import { FobInvoicePriceComponent } from "./pages/autopac/fob-invoice-price/fob-invoice-price.component";
import { SalesmanReceiptComponent } from "./pages/autopac/salesman-receipt/salesman-receipt.component";
import { DutyPulloutComponent } from "./pages/autopac/duty-pullout/duty-pullout.component";
import { DutyPulloutTaxiComponent } from "./pages/autopac/duty-pullout-taxi/duty-pullout-taxi.component";
import { ServiceEnquiryComponent } from "./pages/autopac/service-enquiry/service-enquiry.component";
import { AccessoryReportComponent } from "./pages/autopac-reports/accessory-report/accessory-report.component";
import { CoeBidDetailsByDateReportComponent } from "./pages/autopac-reports/coe-bid-details-by-date-report/coe-bid-details-by-date-report.component";
import { CoeBidsDetailReportComponent } from "./pages/autopac-reports/coe-bids-detail-report/coe-bids-detail-report.component";
import { UserManagementListComponent } from "./pages/administration/user-management/user-management-list/user-management-list.component";
import { UserManagementDetailComponent } from "./pages/administration/user-management/user-management-detail/user-management-detail.component";
import { KapsSettingsComponent } from "./pages/administration/kaps-settings/kaps-settings.component";
import { UserTypeManagementListComponent } from "./pages/administration/user-type-management/user-type-management-list/user-type-management-list.component";
import { UserTypeManagementDetailComponent } from "./pages/administration/user-type-management/user-type-management-detail/user-type-management-detail.component";
import { SalesTeamShuffleComponent } from "./pages/administration/sales-team-shuffle/sales-team-shuffle.component";
import { UserRightsManagementComponent } from "./pages/administration/user-rights-management/user-rights-management.component";
import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home.component";
import { CustomerSearchListComponent } from "./pages/customer/customer-search/customer-search-list/customer-search-list.component";
import { CustomerSearchDetailComponent } from "./pages/customer/customer-search/customer-search-detail/customer-search-detail.component";
import { LoadingPageComponent } from "./components/loading-page/loading-page.component";
import { AlertComponent } from "./components/alert/alert.component";
import { ProspectDetailComponent } from "./pages/customer/prospect/prospect-detail/prospect-detail.component";
import { ProspectListComponent } from "./pages/customer/prospect/prospect-list/prospect-list.component";
import { ClickDriveCustomerComponent } from "./pages/customer/click-drive-customer/click-drive-customer.component";
import {
  GenerateVoucherComponent,
  DialogContent,
} from "./pages/customer/click-drive-customer/generate-voucher/generate-voucher.component";
import { UpdateOrderComponent } from "./pages/customer/click-drive-customer/update-order/update-order.component";
import { PremiumCareMemberListComponent } from "./pages/customer/premium-care-member/premium-care-member-list/premium-care-member-list.component";
import { PremiumCareMemberDetailComponent } from "./pages/customer/premium-care-member/premium-care-member-detail/premium-care-member-detail.component";
import { PremiumCareMemberEntitlementComponent } from "./pages/customer-reports/premium-care-member-entitlement/premium-care-member-entitlement.component";
import { HirePurchaseListComponent } from "./pages/sales/hire-purchase/hire-purchase-list/hire-purchase-list.component";
import { InsuranceListComponent } from './pages/sales/insurance/insurance-list/insurance-list.component';
import { InsuranceDetailComponent } from './pages/sales/insurance/insurance-detail/insurance-detail.component';
import { InventoryVehicleComponent } from './pages/sales/inventory-vehicle/inventory-vehicle.component';
import { ShipmentMasterComponent } from './pages/sales/shipment-master/shipment-master/shipment-master.component';
import { HirePurchaseDetailComponent } from './pages/sales/hire-purchase/hire-purchase-detail/hire-purchase-detail.component';
import { ControlParametersComponent } from "./pages/sales/control-parameters/control-parameters.component";
import { LoanStatusReportComponent } from "./pages/sales/loan-status-report/loan-status-report.component";
import { PriceListTemplateListComponent } from './pages/sales/price-list-template/price-list-template-list/price-list-template-list.component';
import { PriceListTemplateDetailComponent } from './pages/sales/price-list-template/price-list-template-detail/price-list-template-detail.component';
import { PriceListTemplateReportComponent } from './pages/sales/price-list-template-report/price-list-template-report.component';
import { PriceListMasterDetailComponent } from "./pages/sales/price-list-master/price-list-master-detail/price-list-master-detail.component";
import { PriceListMasterListComponent } from "./pages/sales/price-list-master/price-list-master-list/price-list-master-list.component";
import { CreditMemoComponent } from './pages/sales/credit-memo/credit-memo.component';
import { LoanStatusComponent } from './pages/sales/loan-status/loan-status.component';
import { SpecialPriceListMasterDetailComponent } from "./pages/sales/special-price-list-master/special-price-list-master-detail/special-price-list-master-detail.component";
import { SpecialPriceListMasterListComponent } from "./pages/sales/special-price-list-master/special-price-list-master-list/special-price-list-master-list.component";
import { ManageFilesComponent } from "./pages/sales/special-price-list-master/manage-files/manage-files.component";
import { EmailBlackListListComponent } from './pages/sales/email-black-list/email-black-list-list/email-black-list-list.component';
import { EmailBlackListDetailComponent } from './pages/sales/email-black-list/email-black-list-detail/email-black-list-detail.component';
import { AllInvoicesComponent } from './pages/sales-reports/all-invoices/all-invoices.component';
import { VehicleStockReportComponent } from './pages/sales-reports/vehicle-stock-report/vehicle-stock-report.component';
import { WeeklyFocusDashboardListComponent } from './pages/sales/weekly-focus-dashboard/weekly-focus-dashboard-list/weekly-focus-dashboard-list.component';
import { WeeklyFocusDashboardDetailComponent } from './pages/sales/weekly-focus-dashboard/weekly-focus-dashboard-detail/weekly-focus-dashboard-detail.component';
import { WeeklyFocusReportListComponent } from './pages/sales/weekly-focus-report/weekly-focus-report-list/weekly-focus-report-list.component';
import { WeeklyFocusReportDetailComponent } from './pages/sales/weekly-focus-report/weekly-focus-report-detail/weekly-focus-report-detail.component';
import { FactoryStandardEquipmentAndItemsComponent } from './pages/sales/factory-standard-equipment-and-items/factory-standard-equipment-and-items.component';
import { DocumentDownloadComponent } from "./pages/sales-reports/document-download/document-download.component";
import { ChequeIssuedComponent } from "./pages/sales-reports/cheque-issued/cheque-issued.component";
import { CancellationReportComponent } from "./pages/sales-reports/cancellation-report/cancellation-report.component";
import { VehicleStockSummaryReportDetailComponent } from "./pages/sales/vehicle-stock-summary-report/vehicle-stock-summary-report-detail/vehicle-stock-summary-report-detail.component";
import { VehicleStockSummaryReportListComponent } from "./pages/sales/vehicle-stock-summary-report/vehicle-stock-summary-report-list/vehicle-stock-summary-report-list.component";
import { OutstandingDeliveryComponent } from "./pages/sales-reports/outstanding-delivery/outstanding-delivery.component";
import { FinalShortPaymentComponent } from "./pages/sales-reports/final-short-payment/final-short-payment.component";
import { OutstandingCoeBiddingComponent } from "./pages/sales-reports/outstanding-coe-bidding/outstanding-coe-bidding.component";
import { MailingLabelComponent } from "./pages/sales-reports/mailing-label/mailing-label.component";
import { OutstandingCarAllocationComponent } from "./pages/sales-reports/outstanding-car-allocation/outstanding-car-allocation.component";
import { BuyersOrderReportComponent } from './pages/sales-reports/buyers-order-report/buyers-order-report.component';
import { BuyersOrderReportDynamicComponent } from './pages/sales-reports/buyers-order-report-dynamic/buyers-order-report-dynamic.component';
import { RegistrationReportComponent } from "./pages/sales-reports/registration-report/registration-report.component";
import { UnpaidInvoicesReportComponent } from "./pages/sales-reports/unpaid-invoices-report/unpaid-invoices-report.component";
import { VehicleOverbookReportComponent } from "./pages/sales-reports/vehicle-overbook-report/vehicle-overbook-report.component";
import { ProspectReportComponent } from "./pages/sales-reports/prospect-report/prospect-report.component";

@NgModule({
  declarations: [
    AccessoryDetailComponent,
    AccessoryListComponent,
    AccountAdjustmentComponent,
    AccountCodeCreationComponent,
    AddInputComponent,
    AmendChequePurposeComponent,
    AppComponent,
    BookingComponent,
    CarAllocationComponent,
    CardsDepositListingComponent,
    CarRegistrationComponent,
    CashDepositListingComponent,
    ChatWindowComponent,
    ChequeDepositListingComponent,
    ChequeEnquiryComponent,
    ChequeIssueComponent,
    CoeAllocationComponent,
    CoeBiddingPreparationComponent,
    CoeBiddingResultsComponent,
    CoeBidStaffComponent,
    ColoursByModelDetailComponent,
    ColoursByModelListComponent,
    ContractManagementComponent,
    CustomerEnquiryComponent,
    DataFormComponent,
    DataTableComponent,
    DiscountComponent,
    ExportToHMCComponent,
    ExportToHmcNewComponent,
    FinanceVehicleReceiptComponent,
    GeneralEnquiryComponent,
    GiroPaymentComponent,
    GstCalculationComponent,
    HomeComponent,
    InteriorColourDetailComponent,
    InteriorColourListComponent,
    InvoiceComponent,
    InvoiceComponent,
    LaDebuggeadoraComponent,
    MasterColourDetailComponent,
    MasterColourListComponent,
    ModelAndSpecificationDetailComponent,
    ModelAndSpecificationListComponent,
    MonthlyCommissionComponent,
    NavbarComponent,
    NavpathComponent,
    OpenCategoryCoeDepositoryComponent,
    ParfTransferDateComponent,
    PaymentStatusReadOnlyComponent,
    PrintInputComponent,
    PromotionDetailComponent,
    PromotionListComponent,
    PremiumCareMemberStatusComponent,
    ReinstateBookingComponent,
    ReservationComponent,
    ReservationDashboardComponent,
    ReservationPriceListMasterDetailComponent,
    ReservationPriceListMasterListComponent,
    SearchInputComponent,
    SelectInputComponent,
    SidenavbarComponent,
    TableSearchInputComponent,
    TaxiRegistrationComponent,
    TextInputComponent,
    UpdateInputComponent,
    UpdateVehicleRateComponent,
    VehicleSelectionComponent,
    VehicleStockAvailabilityComponent,
    EtaDateUpdationComponent,
    OpenCategoryCoeTransferComponent,
    LocationMasterListComponent,
    LocationMasterDetailComponent,
    PaymentListComponent,
    PaymentStatusComponent,
    FobInvoicePriceComponent,
    SalesmanReceiptComponent,
    DutyPulloutComponent,
    DutyPulloutTaxiComponent,
    ServiceEnquiryComponent,
    AccessoryReportComponent,
    CoeBidDetailsByDateReportComponent,
    CoeBidsDetailReportComponent,
    AuditMasterComponent,
    UserManagementListComponent,
    UserManagementDetailComponent,
    KapsSettingsComponent,
    UserTypeManagementListComponent,
    UserTypeManagementDetailComponent,
    LoginComponent,
    SalesTeamShuffleComponent,
    UserRightsManagementComponent,
    LoginComponent,
    HomeComponent,
    CustomerSearchListComponent,
    CustomerSearchDetailComponent,
    LoadingPageComponent,
    AlertComponent,
    ProspectDetailComponent,
    ProspectListComponent,
    ClickDriveCustomerComponent,
    GenerateVoucherComponent,
    DialogContent,
    UpdateOrderComponent,
    PremiumCareMemberListComponent,
    PremiumCareMemberDetailComponent,
    PremiumCareMemberEntitlementComponent,
    HirePurchaseListComponent,
    InventoryVehicleComponent,
    ShipmentMasterComponent,
    HirePurchaseDetailComponent,
    InsuranceListComponent,
    InsuranceDetailComponent,
    SelectMultipleInputComponent,
    ControlParametersComponent,
    LoanStatusReportComponent,
    PriceListTemplateListComponent,
    PriceListTemplateDetailComponent,
    PriceListTemplateReportComponent,
    PriceListMasterDetailComponent,
    PriceListMasterListComponent,
    CreditMemoComponent,
    LoanStatusComponent,
    SpecialPriceListMasterDetailComponent,
    SpecialPriceListMasterListComponent,
    ManageFilesComponent,
    EmailBlackListListComponent,
    EmailBlackListDetailComponent,
    AllInvoicesComponent,
    VehicleStockReportComponent,
    WeeklyFocusDashboardListComponent,
    WeeklyFocusDashboardDetailComponent,
    WeeklyFocusReportListComponent,
    WeeklyFocusReportDetailComponent,
    FactoryStandardEquipmentAndItemsComponent,
    DocumentDownloadComponent,
    ChequeIssuedComponent,
    CancellationReportComponent,
    VehicleStockSummaryReportDetailComponent,
    VehicleStockSummaryReportListComponent,
    OutstandingDeliveryComponent,
    FinalShortPaymentComponent,
    OutstandingCoeBiddingComponent,
    MailingLabelComponent,
    OutstandingCarAllocationComponent,
    BuyersOrderReportComponent,
    BuyersOrderReportDynamicComponent,
    RegistrationReportComponent,
    UnpaidInvoicesReportComponent,
    VehicleOverbookReportComponent,
    ProspectReportComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    SlickCarouselModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule,
    NgComponentOutlet,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
        },
      },
      behaviour: {
        autoHide: 4000,
      },
    }),
    ReactiveFormsModule,
    SlickCarouselModule,
    SlickCarouselModule,
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    UserManagementService,
    KapsSettingsService,
    AuthService,
    AuditMasterService,
    UserTypeManagementService,
    UserRightsManagementService,
    SalesTeamShuffleService,
    CustomerSearchService,
    ClickDriveCustomerService,
    ProspectService,
    PremiumCareMemberService,
    PremiumCareMemberStatusService,
    PremiumCareMemberEntitlementService,
    BookingService,
    InteriorColourService,
    VehicleSelectionService,
    VehicleStockAvailabilityService,
    PromotionsService,
    AccessoryService,
    ColoursByModelService,
    HirePurchaseService,
    InsuranceService,
    ControlParametersService,
    InventoryVehicleService,
    LoanStatusReportService,
    PriceListTemplateService,
    PriceListTemplateReportService,
    CreditMemoService,
    PriceListMasterService,
    LoanStatusService,
    SpecialPriceListMasterService,
    EmailBlackListService,
    AllInvoicesService,
    VehicleStockReportService,
    WeeklyFocusDashboardService,
    BuyersOrderReportService,
    WeeklyFocusReportService,
    FactoryStandardEquipmentAndItemsService,
    ChequeIssuedService,
    CancellationReportService,
    VehicleStockSummaryReportService,
    OutstandingDeliveryService,
    FinalShortPaymentService,
    OutstandingCoeBiddingService,
    MailingLabelService,
    OutstandingCarAllocationService,
    BuyersOrderReportDynamicService,
    RegistrationReportService,
    UnpaidInvoicesReportService,
    VehicleOverbookReportService,
    ProspectReportService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
