<section class="page-container">
    <div class="flex items-center justify-between">
        <h1 class="main-heading">COE Bids Details By Date</h1>
        <app-navpath />
    </div>
    <div class="content-container">
        <section class="content-container">
            <app-data-form
                [dataFields]="reportFields"
                [dataName]="'reportFields'"
            />
        </section>
        <div class="mb-2 mt-4 flex w-fit gap-1">
            <button class="shorter-btn darker-primary-btn">
                <fa-icon [icon]="faPrint" class="mr-1" />Export
            </button>
        </div>
    </div>

</section>
