import { Component, OnInit } from "@angular/core";
import {
  faMagnifyingGlass,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-customer-enquiry",
  templateUrl: "./customer-enquiry.component.html",
  styleUrls: ["./customer-enquiry.component.css"],
})
export class CustomerEnquiryComponent implements OnInit {
  faMagnifyingGlass = faMagnifyingGlass;
  faBroom = faBroom;
  searchCriteria!: FormField[];

  constructor() {}

  ngOnInit() {
    this.searchCriteria = [
      {
        type: "select",
        id: "searchCriteria",
        label: "Search criteria",
        options: [
          {
            value: "Buyer's name",
            display: "Buyer's name",
          },
          {
            value: "Buyer's NRIC",
            display: "Buyer's NRIC",
          },
          {
            value: "Buyer's order number",
            display: "Buyer's order number",
          },
          {
            value: "Registration number",
            display: "Registration number",
          },
          {
            value: "Invoice number",
            display: "Invoice number",
          },
          {
            value: "Engine number",
            display: "Engine number",
          },
          {
            value: "Chassis number",
            display: "Chassis number",
          },
        ],
      },
      {
        type: "text",
        id: "searchText",
        label: "Search text",
      },
    ];
  }
}
