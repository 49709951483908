<mat-form-field appearance="fill">
  <mat-select
    [(value)]="selectedOptions"
    multiple
    (selectionChange)="onSelectionChange($event)"
    [compareWith]="compareFn"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option.display }}
    </mat-option>
  </mat-select>
</mat-form-field>
