import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class ProspectReportService {
  constructor(private http: HttpUtilService) {}

  ProspectReport(
    FromDate: string,
    ToDate: string,
  ): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/ProspectReport",
      {
        model: {
          FromDate,
          ToDate,
        },
      },
    );
  }
}
