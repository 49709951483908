import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-shipment-master',
  templateUrl: './shipment-master.component.html',
  styleUrls: ['./shipment-master.component.css']
})
export class ShipmentMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
