<div class="sticky block">
  <button
    title="Show chat"
    [hidden]="isChatWindowVisible"
    (click)="toggleChatWindow()"
    class="chat-btn"
  >
    <fa-icon [icon]="faComments"></fa-icon>
  </button>
</div>
<div
  class="chat-container"
  [hidden]="!isChatWindowVisible"
  (onMinimize)="toggleChatWindow()"
>
  <div class="chat-header">
    Online Sales Chat
    <button
      title="Minimize"
      class="hover:text-gray-hover"
      (click)="toggleChatWindow()"
    >
      <i class="fas fa-minus"></i>
    </button>
  </div>
  <div class="h-48 overflow-y-auto p-3">
    <p>No users</p>
  </div>
  <div class="search-box">
    <fa-icon [icon]="faSearch" class="px-2 text-gray-300"></fa-icon>
    <input type="text" placeholder="Search" />
  </div>
</div>
