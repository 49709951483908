import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class VehicleSelectionService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets a list of active models in sales drop-down.
   * @returns An Observable of the active models list.
   */
  getModelActiveInSalesDrpList(): Observable<any> {
    return this.http.get('/ModelSpecificationService.asmx/GetModelActiveInSalesDrpList');
  }

  /**
   * Gets a list of colours for the given model.
   * @param modelCode - The code of the model to get colours for.
   * @returns An Observable of the colour list.
   */
  getColourDrpList(modelCode: string): Observable<any> {
    const encodedModelCode = encodeURIComponent(`"${modelCode}"`);
    const url = `/ColoursByModelService.asmx/GetColourDrpList?modelCode=${encodedModelCode}`;
    return this.http.get(url);
  }

  /**
   * Gets vehicle stock availability for the given model description.
   * @param modelDesc - The description of the model to check stock availability for.
   * @returns An Observable of the stock availability details.
   */
  getVehicleStockAvailability(formattedModelDesc: string): Observable<any> {
    
    const url = `/ModelSpecificationService.asmx/GetVehicleStockAvailability?modelDesc=${formattedModelDesc}`;

    return this.http.get(url);
  }

  /**
   * Gets all vehicles for the given colour and model codes.
   * @param colourCode - The code of the vehicle's colour.
   * @param modelCode - The code of the vehicle's model.
   * @returns An Observable of the vehicle list.
   */
  getAllVehicles(colourCode: string, modelCode: string): Observable<any> {
    const encodedColourCode = encodeURIComponent(`"${colourCode}"`);
    const encodedModelCode = encodeURIComponent(`"${modelCode}"`);
    const url = `/VehicleService.asmx/GetAll?colourCode=${encodedColourCode}&modelCode=${encodedModelCode}`;
    return this.http.get(url);
  }

}
