import { Component } from "@angular/core";
import { faComments, faSearch } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-chat-window",
  templateUrl: "./chat-window.component.html",
  styleUrls: ["./chat-window.component.css"],
})
export class ChatWindowComponent {
  
  isChatWindowVisible = false;
  faComments = faComments;
  faSearch = faSearch;

  toggleChatWindow(): void {
    this.isChatWindowVisible = !this.isChatWindowVisible;
  }
}
