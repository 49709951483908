<div class="page-container">
  <h1 class="main-heading">Open Category COE Depository</h1>
  <div class="content-container">
    <div class="box-header">
      <h4>Open Category COE Depository</h4>
    </div>
    <div class="box-body grid grid-cols-2">
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">COE Amount</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="text" id="" class="w-full" />
            </div>
          </div>
        </div>
        <div class="relative col-span-1">
          <div class="relative flex items-center px-6 py-2">
            <label for="" class="flex w-1/2"
              >COE Expiry Date (dd/mm/yyyy)</label
            >
            <div class="relative flex w-1/2 flex-col items-end">
              <div class="flex w-full items-center">
                <input type="date" id="" class="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2"
            >Receipt Date (dd/mm/yyyy)</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="date" id="" class="w-full" />
            </div>
          </div>
        </div>
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Number of Success Bids</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="text" id="" class="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
