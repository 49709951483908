import { Component, OnInit } from '@angular/core';
import { FormField } from 'src/app/interfaces/form-field.model';
import { WeeklyFocusDashboardService } from 'src/app/services/sales/weekly-focus-dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-weekly-focus-dashboard-list',
  templateUrl: './weekly-focus-dashboard-list.component.html',
  styleUrls: ['./weekly-focus-dashboard-list.component.css'],
})
export class WeeklyFocusDashboardListComponent implements OnInit {

  upperFormFields: FormField[] = [
    {
      type: 'select',
      id: 'team',
      label: 'Team',
      options: [],
    },
    {
      type: 'select',
      id: 'salesman',
      label: 'Salesman',
      options: [],
    },
  ];

  tableHeaders = [
    { title: 'TITLE', type: 'link', id: 'WeeklyFocusTitle' },
    { title: 'SALES CONSULTANT', type: 'data', id: 'LoginId' },
    { title: 'CREATED DATE', type: 'data', id: 'CreatedDate' },
    { title: 'SUBMITTED DATE', type: 'data', id: 'SubmittedDate' },
    { title: 'REMARKS', type: 'data', id: 'Remarks' },
    { title: 'ID', type: 'hidden', id: 'WeeklyFocusId' },
  ];
  tableEntries: any[] = [];

  selectedTeam: string = 'All';
  selectedSalesman: string = 'All';

  constructor(
    private weeklyFocusService: WeeklyFocusDashboardService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadTeams();
    this.loadSalesmen('All'); 
    this.loadWeeklyFocus('All', 'All'); 
  }
  
  loadTeams(): void {
    this.weeklyFocusService.getAllSalesTeamBasedOnPermission().subscribe(
      (response) => {
        console.log('Response from GetAllSalesTeamBasedOnPermission:', response);
        if (response?.body) {
          const teams = [
            { value: 'All', display: 'ALL' }, 
            ...response.body.map((team: any) => ({
              value: team.TeamName.replace(/\s+/g, '+'),
              display: team.TeamName,
            })),
          ];
          this.upperFormFields[0].options = teams;
        }
      },
      (error) => console.error('Error loading teams:', error)
    );
  }
  
  loadSalesmen(team: string): void {
    const formattedTeam = team === 'All' ? 'All' : team.replace(/\s+/g, '+');
    this.weeklyFocusService.getAllSalesmanBasedOnTeam(`%22${formattedTeam}%22`).subscribe(
      (response) => {
        console.log(`Response from GetAllSalesmanBasedOnTeam (team: ${team}):`, response);
        if (response?.body) {
          const salesmen = [
            { value: 'All', display: 'ALL' },
            ...response.body.map((salesman: any) => ({
              value: salesman.SalesmanCode,
              display: `${salesman.SalesmanDesc} (${salesman.SalesmanCode})`,
            })),
          ];
          this.upperFormFields[1].options = salesmen;
        }
      },
      (error) => console.error('Error loading salesmen:', error)
    );
  }
  
  loadWeeklyFocus(salesman: string, team: string): void {
    const formattedSalesman = salesman === 'All' ? 'All' : salesman;
    const formattedTeam = team === 'All' ? 'All' : team.replace(/\s+/g, '+');
  
    this.weeklyFocusService.getAllWeeklyFocus(
      `%22${formattedSalesman}%22`,
      `%22${formattedTeam}%22`
    ).subscribe(
      (response) => {
        console.log(`Response from GetAllWeeklyFocus (salesman: ${salesman}, team: ${team}):`, response);
  
        if (response?.body) {
          this.tableEntries = response.body.map((item: any) => ({
            WeeklyFocusTitle: item.WeeklyFocusTitle || '',
            LoginId: item.LoginId || '',
            CreatedDate: item.CreatedDate || '',
            SubmittedDate: item.SubmittedDate || '',
            Remarks: item.Remarks || '',
            WeeklyFocusId: item.WeeklyFocusId || '',
          }));
        } else {
          console.log('API response is empty, resetting table entries.');
          this.tableEntries = [];
        }
      },
      (error) => {
        console.error('Error loading weekly focus:', error);
        this.tableEntries = [];
      }
    );
  }
  
  handleUpdate(event: any): void {
    const teamField = event.fields.find((f: { id: string }) => f.id === 'team');
    const salesmanField = event.fields.find((f: { id: string }) => f.id === 'salesman');
  
    if (teamField) {
      this.selectedTeam = teamField.value;
      this.loadSalesmen(this.selectedTeam);
      this.loadWeeklyFocus('All', this.selectedTeam);
    }
  
    if (salesmanField) {
      this.selectedSalesman = salesmanField.value;
      this.loadWeeklyFocus(this.selectedSalesman, this.selectedTeam);
    }
  }

  openEntry(value: any): void {
    const routePath = ['../weekly-focus-dashboard', value.WeeklyFocusId];
    console.log('Navigating to route:', routePath);
    this.router.navigate(routePath, {
      relativeTo: this.route,
    }).then(success => {
      if (success) {
        console.log('Navigation successful:', routePath);
      } else {
        console.error('Navigation failed:', routePath);
      }
    }).catch(error => {
      console.error('Error during navigation:', error, 'Route:', routePath);
    });
  }  
}
