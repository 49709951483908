<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Audit master</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <app-data-form
      [dataFields]="auditMaster"
      [dataName]="'auditMaster'"
      (updateFields_child)="updateFields($event)"
    />
    <button class="shorter-btn search-btn" (click)="getAuditRecords()">
      <fa-icon [icon]="faFileDownload" class="mr-1" />Get audit
      records
    </button>
  </section>
  <section class="content-container" *ngIf="showRecords">
    <app-data-table
      [tableHeaders]="tableHeaders"
      [tableEntries]="tableEntries"
      (open)="openEntry($event)"
    />
  </section>
</div>
<div class="bg-audit-details" *ngIf="showDetails">
  <div class="container-audit-details">
    <div class="flex justify-between">
      <h2 class="sub-heading">Audit Details</h2>
      <fa-icon
        [icon]="faClose"
        class="cursor-pointer text-xl text-primary-lighter"
        (click)="showDetails = false"
      ></fa-icon>
    </div>
    <app-data-form
      [dataFields]="auditDetails"
      dataName="AuditDetails"
    />
    <app-data-table
      [tableHeaders]="detailsHeaders"
      [tableEntries]="detailsEntries"
    />
  </div>
</div>
