import { Component } from '@angular/core';
import {
  faPlus,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-model-and-specification-list',
  templateUrl: './model-and-specification-list.component.html',
  styleUrls: ['./model-and-specification-list.component.css']
})
export class ModelAndSpecificationListComponent {
  constructor() {}

  ngOnInit() {}

  searchInput: string = "";

  //Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  //Exported table variables

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "MODEL SHORT DESCRIPTION",
      type: "link",
    },
    {
      title: "MODEL GROUP",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "MODEL CODE",
      type: "data",
    },
    {
      title: "OCC NO",
      type: "data",
    }
  ];

  specifications = [
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "ACCENT 1.4 4DR A",
      "MODEL GROUP": "ACCENT",
      "MODEL CODE": "SBS4K461V",
      "OCC NO": "GAJQ"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "ACCENT 1.4 4DR M",
      "MODEL GROUP": "ACCENT",
      "MODEL CODE": "SBS4K4617",
      "OCC NO": "GAJQ"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 4DR AUTO",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "F2S4D261F",
      "OCC NO": "GCTL"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 4DR AUTO \"S\"",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "F2S4D261F",
      "OCC NO": "GCXE"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 4DR AUTO ELITE",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "F2S4D261F",
      "OCC NO": "GCXF"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 AUTO",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "0AS4D561V",
      "OCC NO": "GAU2"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 AUTO \"S\"",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "0AS4D561V",
      "OCC NO": "GAU1"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 AUTO ELITE (S/R)",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "0AS4D561V",
      "OCC NO": "GAR5"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 DCT HYBRID \"S\"",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "1VS4K6A1TEV1",
      "OCC NO": "G805A"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 DCT HYBRID \"S\" (PHV)",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "1VS4K6A1TEV1",
      "OCC NO": "G805"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "AVANTE 1.6 DCT HYBRID ELITE (S/R)",
      "MODEL GROUP": "AVANTE",
      "MODEL CODE": "1VS4K6A1TEV1",
      "OCC NO": "G806"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "ELANTRA 1.6 4DR AUTO",
      "MODEL GROUP": "ELANTRA",
      "MODEL CODE": "F2S4D261F",
      "OCC NO": "GCF9"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "ELANTRA 1.6 4DR AUTO \"S\"",
      "MODEL GROUP": "ELANTRA",
      "MODEL CODE": "F2S4D261F",
      "OCC NO": "GCF4"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "ELANTRA 1.6 4DR AUTO ELITE",
      "MODEL GROUP": "ELANTRA",
      "MODEL CODE": "F2S4D261F",
      "OCC NO": "GCFA"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "ELANTRA 1.6 4DR AUTO TURBO",
      "MODEL GROUP": "ELANTRA",
      "MODEL CODE": "F2S4D2G1U",
      "OCC NO": "H770"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "G70 2.0 GLS A TURBO",
      "MODEL GROUP": "G70",
      "MODEL CODE": "G9S4L8G1J",
      "OCC NO": "G264"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "G80 3.8 GLS A (S/R)",
      "MODEL GROUP": "G80",
      "MODEL CODE": "B1S4J6A1J",
      "OCC NO": "G948"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "GENESIS G80 2.5 TURBO AUTO (S/R)",
      "MODEL GROUP": "G80",
      "MODEL CODE": "JJS4L9G1J",
      "OCC NO": "D3KA"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "GENESIS GV80 2.5 TURBO AUTO",
      "MODEL GROUP": "GV80",
      "MODEL CODE": "JRW7L9G1K",
      "OCC NO": "D72F"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "GENESIS GV80 2.5 TURBO AUTO (S/R)",
      "MODEL GROUP": "GV80",
      "MODEL CODE": "JRW7L9G1K",
      "OCC NO": "D72H"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "I30 1.0 5DR DCT TURBO",
      "MODEL GROUP": "i30",
      "MODEL CODE": "G4S6K5G1U",
      "OCC NO": "G4PQ"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "I30 1.0 WAGON DCT TURBO",
      "MODEL GROUP": "i30",
      "MODEL CODE": "G4W5K5G1U",
      "OCC NO": "G2SV"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "i30 1.4 5DR DCT TURBO",
      "MODEL GROUP": "i30",
      "MODEL CODE": "G4S6K4G1U",
      "OCC NO": "GNTO"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "i30 1.4 FASTBACK DCT TURBO",
      "MODEL GROUP": "i30",
      "MODEL CODE": "G4T1K4G1U",
      "OCC NO": "HBBF"
    },
    {
      "checked": false,
      "MODEL SHORT DESCRIPTION": "i30 1.4 GLS 5DR DCT TURBO",
      "MODEL GROUP": "i30",
      "MODEL CODE": "G4S6K4G1U",
      "OCC NO": "0"
    }
  ];
}
