import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.css"],
})
export class TextInputComponent {
  @Input("dataType") dataType: string = "text";
  @Input("required") required: boolean = false;
  @Input("lock") lock: boolean = false;
  @Input("placeholder") placeholder: string = "";

  @Input("dataInput") dataInput: string | undefined;
  @Output() dataOutput = new EventEmitter<string>();

  faTriangleExclamation = faTriangleExclamation;
}
