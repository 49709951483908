import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class AuditMasterService {
  constructor(private http: HttpUtilService) {}

  /**
   * Fetches all active users.
   * @returns An Observable of the active users.
   */
  getAllActiveUsers(): Observable<any> {
    return this.http.get(
      "/AuditMasterService.asmx/GetAllActiveUsers",
    );
  }

  /**
   * Fetches all modules for a given user.
   * @param userId The ID of the user.
   * @returns An Observable of the modules.
   */
  getAllModules(userId: string): Observable<any> {
    return this.http.get("/AuditMasterService.asmx/GetAllModules", {
      params: { userId },
    });
  }

  /**
   * Fetches all tables for a given user and module.
   * @param userId The ID of the user.
   * @param moduleName The name of the module (default is 'All').
   * @returns An Observable of the tables.
   */
  getAllTable(
    userId: string,
    moduleName: string = "All",
  ): Observable<any> {
    return this.http.get("/AuditMasterService.asmx/GetAllTable", {
      params: { moduleName, userId },
    });
  }

  /**
   * Fetches all records for a given user, module, table, record ID and dates range.
   * @param Tablename The name of the table (default is 'All').
   * @param fromDate From date (default is '').
   * @param moduleName The name of the module (default is 'All').
   * @param recordId The id of the record (default is '').
   * @param toDate To date (default is '').
   * @param userId The ID of the user.
   */
  getAll(
    userId: string,
    Tablename: string = "All",
    fromDate: string = "",
    moduleName: string = "All",
    toDate: string = "",
    recordId: string = "",
  ): Observable<any> {
    return this.http.get("/AuditMasterService.asmx/GetAll", {
      params: {
        Tablename,
        fromDate,
        moduleName,
        recordId,
        toDate,
        userId,
      },
    });
  }

  /**
   * Fetches user details for a given user ID.
   * @param id The ID of the user.
   * @returns An Observable of the user details.
   */
  getUserDetail(id: string): Observable<any> {
    return this.http.get("/KapsUserService.asmx/GetUserDetail", {
      params: { id },
    });
  }

  /**
   * Deletes a user by ID.
   * @param id The ID of the user to delete.
   * @returns An Observable of the delete operation result.
   */
  deleteUser(id: string): Observable<any> {
    return this.http.post("/KapsUserService.asmx/Delete", { id });
  }

  /**
   * Fetches all active roles.
   * @returns An Observable of the active roles.
   */
  getAllActiveRoles(): Observable<any> {
    return this.http.get("/AdminService.asmx/GetAllActiveRoles");
  }

  /**
   * Fetches all sales team members.
   * @returns An Observable of the sales team members.
   */
  getAllSalesTeam(): Observable<any> {
    return this.http.get("/KapsUserService.asmx/GetAllSalesTeam");
  }

  /**
   * Updates a user.
   * @param user The user object to update.
   * @returns An Observable of the update operation result.
   */
  updateUser(user: object): Observable<any> {
    return this.http.post("/KapsUserService.asmx/UpdateUser", {
      user,
    });
  }

  /**
   * Adds a new user.
   * @param user The user object to add.
   * @returns An Observable of the add operation result.
   */
  addUser(user: object): Observable<any> {
    return this.http.post("/KapsUserService.asmx/AddUser", {
      model: user,
    });
  }
}
