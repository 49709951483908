import { Component, Input, OnInit, SimpleChanges, OnChanges } from "@angular/core";
import { faPencil, faEraser, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../interfaces/form-field.model";
import { UserRightsManagementService } from "src/app/services/administration/user-rights-management.service";

@Component({
  selector: "app-user-rights-management",
  templateUrl: "./user-rights-management.component.html",
  styleUrls: ["./user-rights-management.component.css"],
})
export class UserRightsManagementComponent implements OnInit, OnChanges {
  // ICONS
  faPencil = faPencil;
  faEraser = faEraser;
  faCopy = faCopy;
  individual = false;

  activeUsers: any[] = [];
  screens: any[] = [];
  selectedUserId: string = '';
  selectedmenuId: number = 0;
  filterText: string = '';

  allUserRights: any[] = [];
  types_select: any[] = [];
  filteredTypesSelect: any[] = [];

  upperFormFields: FormField[] = [
    {
      type: "select",
      id: "user",
      label: "",
      options: []
    },
    {
      type: "select",
      id: "right",
      label: "",
      options: []
    },
    {
      type: "text",
      id: "menuName",
      label: "",
    },
  ];

  constructor(
    private UserRightsManagementService: UserRightsManagementService,
  ) {}

  ngOnInit(): void {
    this.loadActiveUsers();
    this.loadScreens();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedUserId"] || changes["selectedmenuId"]) {
      this.fetchAndPrepareUserRights();
    }
  }

  loadActiveUsers(): void {
    this.UserRightsManagementService.getAllActiveUsers().subscribe(
      (response) => {
        this.activeUsers = response.d.map((user: any) => ({
          id: user.UserId,
          display: user.FullName,
          value: user.UserId,
        }));
        this.updateUpperFormFields();
      },
      (error) => {
        console.error("Error getting active users:", error);
      },
    );
  }

  loadScreens(): void {
    this.UserRightsManagementService.getAllScreens().subscribe(
      (response) => {
        this.screens = response.d.map((screen: any) => ({
          id: screen.MenuId,
          display: screen.MenuName,
          value: screen.MenuId,
          menuName: screen.MenuName,
          parentMenuId: screen.ParentMenuId
        }));

        // Adds "All" at the beginning of the list
        this.screens.unshift({
          id: 0,
          display: "All",
          value: 0,
          menuName: "All",
          parentMenuId: null
        });

        this.updateUpperFormFields();
      },
      (error) => {
        console.error("Error getting screens:", error);
      },
    );
  }

  updateUpperFormFields(): void {
    this.upperFormFields = [
      {
        type: "select",
        id: "user",
        label: "",
        options: this.activeUsers
      },
      {
        type: "select",
        id: "right",
        label: "",
        options: this.screens
      },
      {
        type: "text",
        id: "menuName",
        label: "",
      },
    ];
  }

  fetchAndPrepareUserRights(): void {
    if (this.selectedUserId && this.selectedmenuId === 0) {
      this.UserRightsManagementService.getUserRightsById(this.selectedUserId)
        .subscribe(response => {
          this.storeUserRights(response);
          this.applyFilter(); 
        }, error => {
          console.error("Error fetching rights:", error);
        });
    } else if (this.selectedUserId && this.selectedmenuId !== null && this.selectedmenuId !== 0) {
      this.UserRightsManagementService.getUserRightsByIdAndScreen(this.selectedUserId, this.selectedmenuId)
        .subscribe(response => {
          this.storeUserRights(response);
          this.applyFilter();
        }, error => {
          console.error("Error fetching rights with screen:", error);
        });
    }
  }

  storeUserRights(data: any) {
    this.allUserRights = data.d.map((item: any) => ({
      RightsMasterId: item.RightsMasterId || "00000000-0000-0000-0000-000000000000",
      menuId: item.MenuId,
      menuName: item.MenuName,
      IsAddAllowed: item.IsAddAllowed,
      IsUpdateAllowed: item.IsUpdateAllowed,
      IsDeleteAllowed: item.IsDeleteAllowed,
      IsPrintAllowed: item.IsPrintAllowed,
      IsViewAllowed: item.IsViewAllowed,
      IsAppear: item.IsAppear,
      IsAddBelongToRoleRight: item.IsAddBelongToRoleRight,
      IsUpdateBelongToRoleRight: item.IsUpdateBelongToRoleRight,
      IsDeleteBelongToRoleRight: item.IsDeleteBelongToRoleRight,
      IsPrintBelongToRoleRight: item.IsPrintBelongToRoleRight,
      IsViewBelongToRoleRight: item.IsViewBelongToRoleRight,
      parentMenuId: item.ParentMenuId
    }));

    this.types_select = this.allUserRights.map((item: any) => ({
      menuId: item.menuId,
      menuName: item.menuName,
      IsAddAllowed: { value: item.IsAddAllowed, clicked: item.IsAddBelongToRoleRight},
      IsUpdateAllowed: { value: item.IsUpdateAllowed, clicked: item.IsUpdateBelongToRoleRight},
      IsDeleteAllowed: { value: item.IsDeleteAllowed, clicked: item.IsDeleteBelongToRoleRight},
      IsPrintAllowed: { value: item.IsPrintAllowed, clicked: item.IsPrintBelongToRoleRight},
      IsViewAllowed: { value: item.IsViewAllowed, clicked: item.IsViewBelongToRoleRight}
    }));

    this.filteredTypesSelect = [...this.types_select]; 
    this.applyFilter(); 
  }

  applyFilter(): void {
    let filteredData = this.types_select;
  
    if (this.selectedmenuId !== 0) {
      filteredData = filteredData.filter(item => item.menuId === this.selectedmenuId);
    }
  
    if (this.filterText) {
      const lowerCaseFilter = this.filterText.toLowerCase();
      filteredData = filteredData.filter(item =>
        item.menuName.toLowerCase().includes(lowerCaseFilter)
      );
    }
  
    this.filteredTypesSelect = filteredData;
  }
  
  handleUpdate(event: any): void {
    const userChanged = event.fields.find((f: { id: string; value: string; }) => f.id === 'user');
    const screenChanged = event.fields.find((f: { id: string; value: number | null; }) => f.id === 'right');
    const filterChanged = event.fields.find((f: { id: string; value: string; }) => f.id === 'menuName');
  
    if (userChanged) {
      this.selectedUserId = userChanged.value;
      this.selectedmenuId = 0;
      this.fetchAndPrepareUserRights(); 
    }
  
    if (screenChanged) {
      this.selectedmenuId = parseInt(screenChanged.value) || 0;
    }
  
    if (filterChanged) {
      this.filterText = filterChanged.value;
    }
  
    this.applyFilter(); 
  }
  
  checkEntry(event: any): void {
    console.log("Check entry event:", event);
    this.types_select = event;
    this.applyFilter(); 
  }

  updateUserRights(): void {
    const preparedData = this.prepareUpdateData(); 
    this.UserRightsManagementService.updateUserRights(preparedData).subscribe(
      (response) => {
        console.log("User rights updated successfully:", response);
      },
      (error) => {
        console.error("Error updating user rights:", error);
      }
    );
  }

  prepareUpdateData(): any {
    this.allUserRights = this.types_select.map(type => {
      const correspondingRight = this.allUserRights.find(right => right.menuId === type.menuId);
      return {
        ...correspondingRight,
        IsAddAllowed: type.IsAddAllowed.value,  
        IsUpdateAllowed: type.IsUpdateAllowed.value,
        IsDeleteAllowed: type.IsDeleteAllowed.value,
        IsPrintAllowed: type.IsPrintAllowed.value,
        IsViewAllowed: type.IsViewAllowed.value,
      };
    });

    const userRightsData = this.allUserRights.map(item => ({
      RightsMasterId: item.RightsMasterId,
      MenuId: item.menuId,
      MenuName: item.menuName,
      ParentMenuId: item.parentMenuId,
      IsAddAllowed: item.IsAddAllowed,
      IsUpdateAllowed: item.IsUpdateAllowed,
      IsDeleteAllowed: item.IsDeleteAllowed,
      IsPrintAllowed: item.IsPrintAllowed,
      IsViewAllowed: item.IsViewAllowed,
      IsAddBelongToRoleRight: item.IsAddBelongToRoleRight,
      IsUpdateBelongToRoleRight: item.IsUpdateBelongToRoleRight,
      IsDeleteBelongToRoleRight: item.IsDeleteBelongToRoleRight,
      IsPrintBelongToRoleRight: item.IsPrintBelongToRoleRight,
      IsViewBelongToRoleRight: item.IsViewBelongToRoleRight,
      IsAppear: item.IsAppear
    }));

    return {
      identityUserId: this.selectedUserId,
      model: {
        UserId: this.selectedUserId,
        UserRights: userRightsData
      }
    };
  }

  referenceUserFields: FormField[] = [
    {
      type: "text",
      id: "roleName",
      label: "Role Name",
      required: true,
    },
    {
      type: "text",
      id: "description",
      label: "Description",
      required: true,
    },
  ];

  targetUserFields: FormField[] = [
    {
      type: "text",
      id: "roleName",
      label: "Role Name",
      required: true,
    },
    {
      type: "text",
      id: "description",
      label: "Description",
      required: true,
    },
    {
      type: "radio",
      id: "replicationOptions",
      label: "Replication Options",
      required: true,
      options: [
        {
          display: "Add to Existing Rights",
          value: "addToExistingRights",
        },
        {
          display: "Replace Existing Rights",
          value: "replaceExistingRights",
        },
      ],
    },
  ];

  tableHeaders = [
    {
      title: "SCREEN ID",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "SCREEN NAME",
      type: "data",
    },
    {
      title: "RIGHTS ADD",
      type: "checkbox",
    },
    {
      title: "RIGHTS UPDATE",
      type: "checkbox",
    },
    {
      title: "RIGHTS DELETE",
      type: "checkbox",
    },
    {
      title: "RIGHTS PRINT",
      type: "checkbox",
    },
    {
      title: "RIGHTS VIEW",
      type: "checkbox",
    },
  ];
}
