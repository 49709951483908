import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class KapsSettingsService {
  constructor(private http: HttpUtilService) {}

  /**
   * Fetches the settings.
   * @returns An Observable of the settings data.
   */
  getSettings(): Observable<any> {
    return this.http.get('/KapsSettingsService.asmx/GetSettings');
  }

  /**
   * Saves the other settings.
   * @param data The settings data to save.
   * @returns An Observable of the save operation result.
   */
  saveOtherSettings(data: any): Observable<any> {
    return this.http.post('/KapsSettingsService.asmx/SaveOtherSettings', data);
  }
}
