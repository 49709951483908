import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faSave,
  faTrash,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-coe-bid-staff",
  templateUrl: "./coe-bid-staff.component.html",
  styleUrls: ["./coe-bid-staff.component.css"],
})
export class CoeBidStaffComponent implements OnInit {
  faPlus = faPlus;
  faSave = faSave;
  faTrash = faTrash;
  faPrint = faPrint;

  searchCriteria!: FormField[];

  updateFields(event: any) {
    this.searchCriteria = event.fields;
  }

  ngOnInit() {
    this.searchCriteria = [
      {
        type: "text",
        id: "biddersNRIC",
        label: "Bidder's NRIC",
        required: true,
      },
      {
        type: "text",
        id: "biddersName",
        label: "Bidder's name",
        required: true,
      },
      {
        type: "text",
        id: "salesConsultant",
        label: "Sales consultant / department",
      },
      {
        type: "select",
        id: "selectFormat",
        label: "Select format",
      },
    ];
  }
}
