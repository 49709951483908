import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  HttpResponse,
  HttpUtilService,
} from "../http-util.service";
import {
  Role,
  Team,
  User,
} from "../../models/administration/user-management/user-management.model";

const SERVICE_NAME = "KapsUserService.asmx";

@Injectable({
  providedIn: "root",
})
export class UserManagementService {
  constructor(private http: HttpUtilService) {}

  getAll(): Observable<HttpResponse<User[]>> {
    return this.http.get(`${SERVICE_NAME}/GetAll`);
  }

  getUserDetail(id: string): Observable<HttpResponse<User>> {
    return this.http.get(`${SERVICE_NAME}/GetUserDetail`, {
      params: { id },
    });
  }

  deleteUser(id: string): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVICE_NAME}/Delete`, { id });
  }

  getAllActiveRoles(): Observable<HttpResponse<Role[]>> {
    return this.http.get("AdminService.asmx/GetAllActiveRoles");
  }

  getAllSalesTeam(): Observable<HttpResponse<Team[]>> {
    return this.http.get(`${SERVICE_NAME}/GetAllSalesTeam`);
  }

  updateUser(user: User): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVICE_NAME}/UpdateUser`, { user });
  }

  addUser(user: User): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVICE_NAME}/AddUser`, { model: user });
  }
}
