import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class PremiumCareMemberService {

  constructor(private http: HttpUtilService) {}

  /**
   * Fetches all active users.
   * @returns An Observable of the settings data.
   */
  getAllList(): Observable<any> {
    const url = `/PremiumCustomerService.asmx/GetAllList?chassis=%22%22&name=%22%22&orderBy=%22%22&orderByReverse=false&pageNumber=1&pageSize=20000&vinNo=%22%22`;
    return this.http.get(url);
  }

  /**
   * Gets membership details by membership ID.
   * @param membershipId - The ID of the membership.
   * @returns An Observable of the membership details.
   */
  getDetailInfoFromId(membershipId: string): Observable<any> {
    const encodedMembershipId = encodeURIComponent(`"${membershipId}"`);
    const url = `/PremiumCustomerService.asmx/GetDetailInfoFromId?membershipId=${encodedMembershipId}`;
    return this.http.get(url);
  }

  /**
   * Gets all membership types.
   * @returns An Observable of all membership types.
   */
  getAllMembershipType(): Observable<any> {
    const url = '/PremiumCustomerService.asmx/GetAllMembershipType';
    return this.http.get(url);
  }

  /**
   * Gets all vehicle groups.
   * @returns An Observable of all vehicle groups.
   */
  getAllVehicleGroup(): Observable<any> {
    const url = '/PremiumCustomerService.asmx/GetAllVehicleGroup';
    return this.http.get(url);
  }

  /**
   * Gets all model specifications.
   * @returns An Observable of all model specifications.
   */
  getAllModelSpecification(): Observable<any> {
    const url = '/ModelSpecificationService.asmx/GetAll';
    return this.http.get(url);
  }

  /**
   * Gets all premium benefits.
   * @returns An Observable of all premium benefits.
   */
  getAllPremiumBenefit(): Observable<any> {
    const url = '/PremiumCustomerService.asmx/GetAllPremiumBenefit';
    return this.http.get(url);
  }

  /**
   * Gets member benefits by membership ID.
   * @param membershipId - The ID of the membership.
   * @returns An Observable of the member benefits.
   */
  getMemberBenefits(membershipId: string): Observable<any> {
    const encodedMembershipId = encodeURIComponent(`"${membershipId}"`);
    const url = `/PremiumCustomerService.asmx/GetMemberBenefits?membershipId=${encodedMembershipId}`;
    return this.http.get(url);
  }

  /**
   * Gets the usage of member benefits by membership ID.
   * @param membershipId - The ID of the membership.
   * @returns An Observable of the member usage.
   */
  getMemberUsage(membershipId: string): Observable<any> {
    const encodedMembershipId = encodeURIComponent(`"${membershipId}"`);
    const url = `/PremiumCustomerService.asmx/GetMemberUsage?membershipId=${encodedMembershipId}`;
    return this.http.get(url);
  }

  /**
   * Edits member details.
   * @param memberData - The data of the member to be edited.
   * @returns An Observable of the response.
   */
  editMember(memberData: any): Observable<any> {
    const url = '/PremiumCustomerService.asmx/EditMember';

    // Wraps the data in an object with the key 'data'
    const payload = { data: memberData };

    return this.http.post(url, payload);
  }

  /**
   * Deletes a member by ID.
   * @param id - The ID of the member to be deleted.
   * @returns An Observable of the response.
   */
  deleteMember(id: string): Observable<any> {
    const url = '/PremiumCustomerService.asmx/DeleteMember';
    return this.http.post(url, { id });
  }

  /**
 * Adds a new member.
 * @param memberData - The data of the member to be added.
 * @returns An Observable of the response.
 */
addMember(memberData: any): Observable<any> {
  const url = '/PremiumCustomerService.asmx/AddMember';
  const payload = { data: memberData }; // Envolver el objeto en `data`
  return this.http.post(url, payload);
}


  /**
 * Adds a benefit to a member.
 * @param benefitData - The data of the benefit to be added.
 * @returns An Observable of the response.
 */
addMemberBenefit(benefitData: {
  BenefitCode: string;
  EntitleQty: number;
  MembershipId: string;
}): Observable<any> {
  const url = '/PremiumCustomerService.asmx/AddMemberBenefit';
  return this.http.post(url, { data: benefitData });
}


  /**
 * Adds usage for a member benefit.
 * @param usageData - The data of the usage to be added.
 * @returns An Observable of the response.
 */
addMemberUsage(usageData: {
  DateUsed: string;
  EntitleQtyUsed: number;
  PremiumBenefitId: string;
}): Observable<any> {
  const url = '/PremiumCustomerService.asmx/AddMemberUsage';
  return this.http.post(url, { data: usageData });
}


  /**
   * Deletes a benefit from a member.
   * @param id - The ID of the benefit to be deleted.
   * @returns An Observable of the response.
   */
  deleteMemberBenefit(id: string): Observable<any> {
    const url = '/PremiumCustomerService.asmx/DeleteMemberBenefit';
    return this.http.post(url, { id });
  }

  /**
   * Deletes a usage entry for a member benefit.
   * @param id - The ID of the usage to be deleted.
   * @returns An Observable of the response.
   */
  deleteMemberUsage(id: string): Observable<any> {
    const url = '/PremiumCustomerService.asmx/DeleteMemberUsage';
    return this.http.post(url, { id });
  }

  /**
   * Gets information from a vehicle number (VIN).
   * @param vinNo - The vehicle identification number (VIN).
   * @returns An Observable of the vehicle information.
   */
  getInfoFromVehicleNo(vinNo: string): Observable<any> {
    const encodedVinNo = encodeURIComponent(`"${vinNo}"`);
    const url = `/PremiumCustomerService.asmx/GetInfoFromVehicleNo?vinNo=${encodedVinNo}`;
    return this.http.get(url);
  }
  
  /**
   * Gets prospect information by IC number.
   * @param icNo - The identification number (IC).
   * @returns An Observable of the prospect information.
   */
  getProspectByIcNo(icNo: string): Observable<any> {
    const encodedIcNo = encodeURIComponent(`"${icNo}"`);
    const url = `/BookingService.asmx/GetProspectByIcNo?IcNo=${encodedIcNo}`;
    return this.http.get(url);
  }
}
