<div
  class="input-container"
  [ngClass]="{
    'missing': required && dataInput == undefined,
    'disabled': lock
  }"
>
  <select
    [name]="id"
    [id]="id"
    [(ngModel)]="dataInput"
    (ngModelChange)="selectItem()"
    [disabled]="lock"
    class="select-container"
    [ngClass]="{ 'text-gray-secondary': dataInput == undefined }"
  >
    <option hidden disabled [value]="undefined">Please select</option>
    <option
      [ngValue]="selectOption.value"
      *ngFor="let selectOption of selectOptions"
      class="text-gray-main"
    >
      <p>{{ selectOption.display }}</p>
    </option>
  </select>
  <fa-icon
    *ngIf="required && dataInput == undefined"
    [icon]="faTriangleExclamation"
    class="warning-btn"
  />
</div>
