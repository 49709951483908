import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { MasterColourService } from "src/app/services/vehicle/master-colour.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";
@Component({
  selector: "app-master-colour-list",
  templateUrl: "./master-colour-list.component.html",
  styleUrls: ["./master-colour-list.component.css"],
})
export class MasterColourListComponent {
  searchInput: string = "";
  allData: any[] = [];
  filteredData: any[] = [];
  showConfirmation: boolean = false;

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
      id: "Checked",
    },
    {
      title: "MODEL ID",
      type: "link",
      id: "ModelId",
    },
    {
      title: "MODEL GROUP",
      type: "data",
      id: "ModelGroup",
    },
    {
      title: "TYPE",
      type: "data",
      id: "Type",
    },
    {
      title: "EXTERIOR COLOUR",
      type: "data",
      id: "ExteriorColour",
    },
    {
      title: "DESCRIPTION",
      type: "data",
      id: "Description",
    },
    {
      title: "REMARKS",
      type: "data",
      id: "Remarks",
    },
    {
      title: "INTERIOR COLOURS",
      type: "data",
      id: "InteriorColours",
    },
    {
      title: "DISPLAY",
      type: "boolean",
      id: "Display",
      messages: ["YES", "NO"],
    },
    {
      title: "",
      type: "hidden",
      id: "MasterColourId",
    },
    {
      title: "",
      type: "hidden",
      id: "Doors",
    },
  ];

  constructor(
    private masterColourService: MasterColourService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.getAllList();
  }

  private getAllList(): void {
    this.masterColourService.getAll().subscribe(
      (response) => {
        this.allData = response.body.map((master_colour: any) => {
          return {
            "Checked": false,
            "ModelId": master_colour.ModelId || "",
            "ModelGroup": master_colour.ModelGroup || "",
            "Type": master_colour.Type || "",
            "ExteriorColour": master_colour.ExteriorColour || "",
            "Description": master_colour.Description || "",
            "Remarks": master_colour.Remarks || "",
            "InteriorColours": master_colour.InteriorColours || "",
            "Display": master_colour.Display,
            "MasterColourId": master_colour.ColourMasterId || "",
            "Doors": master_colour.Doors || "",
          };
        });
        this.filteredData = [...this.allData];
      },
      (error) => {
        console.error("Error fetching interior colour data:", error);
      },
    );
  }

  onSearch(searchValue: string): void {
    this.filteredData = this.allData.filter(
      (item) =>
        item.ModelId.toLowerCase().includes(
          searchValue.toLowerCase(),
        ) ||
        item.ModelGroup.toLowerCase().includes(
          searchValue.toLowerCase(),
        ) ||
        item.Type.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.ExteriorColour.toLowerCase().includes(
          searchValue.toLowerCase(),
        ) ||
        item.Description.toLowerCase().includes(
          searchValue.toLowerCase(),
        ) ||
        item.Remarks.toLowerCase().includes(
          searchValue.toLowerCase(),
        ) ||
        item.InteriorColours.toLowerCase().includes(
          searchValue.toLowerCase(),
        ),
    );
  }

  openEntry(value: any) {
    this.router.navigate(["../master-colour", value.MasterColourId], {
      relativeTo: this.route,
    });
  }

  /* addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../interior-colour", emptyGuid], {
      relativeTo: this.route,
    });
  } */

  deleteSelectedEntries(): void {
    const selectedItems = this.filteredData
      .filter((item) => item.Checked)
      .map((master_colour: any) => {
        return {
          "ColourMasterId": master_colour.MasterColourId,
          "Description": master_colour.Description,
          "Display": master_colour.Display,
          "Doors": master_colour.Doors,
          "ExteriorColour": master_colour.ExteriorColour,
          "InteriorColours": master_colour.InteriorColours,
          "IsChecked": master_colour.Checked,
          "ModelGroup": master_colour.ModelGroup,
          "ModelId": master_colour.ModelId,
          "Remarks":
            master_colour.Remarks !== ""
              ? master_colour.Remarks
              : null,
          "Type": master_colour.Type,
        };
      });
    this.masterColourService.Delete(selectedItems).subscribe({
      next: () => {
        this.notifier.notify(
          "success",
          "Selected items deleted successfully",
        );
        this.filteredData = this.filteredData.filter(
          (item) => !item.Checked,
        );
        this.showModal(false);
      },
      error: (error) => {
        console.error("Error deleting interior colours:", error);
        this.notifier.notify(
          "error",
          "Error deleting selected items.",
        );
      },
    });
  }

  showModal(modal: boolean) {
    const selectedItems = this.filteredData.filter(
      (item) => item.Checked,
    );
    if (selectedItems.length == 0 && modal) {
      this.notifier.notify(
        "error",
        "Please select at least one item to delete.",
      );
      return;
    } else {
      this.showConfirmation = modal;
      document.body.style.overflow = modal ? "hidden" : "auto";
    }
  }
}
