import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class PriceListTemplateReportService {
  private readonly baseUrl = "SalesReportService.asmx";

  constructor(private http: HttpUtilService) {}

  /**
   * Sends a POST request to print the Passenger Price List report.
   * @returns Observable with the response.
   */
  printPassengerPriceList(): Observable<any> {
    const endpoint = `${this.baseUrl}/PrintPassengerPriceList`;
    return this.http.post(endpoint, null);
  }

  /**
   * Sends a POST request to print the Commercial Price List report.
   * @returns Observable with the response.
   */
  printCommercialPriceList(): Observable<any> {
    const endpoint = `${this.baseUrl}/PrintCommercialPriceList`;
    return this.http.post(endpoint, null);
  }

  /**
   * Sends a POST request to print the STAREX Flyer in English.
   * @returns Observable with the response.
   */
  printSTAREXFlyerEng(): Observable<any> {
    const endpoint = `${this.baseUrl}/PrintSTAREXFlyerEng`;
    return this.http.post(endpoint, null);
  }
}
