<section class="page-container">
    <div class="flex items-center justify-between">
        <h1 class="main-heading">List Prices Template</h1>
        <app-navpath />
    </div>
    <div class="mb-2 flex w-fit gap-1">
        <button class="shorter-btn misc-btn" (click)="addEntry()">
            <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
        </button>
        <button class="shorter-btn delete-btn" (click)="deleteSelectedEntries()">
            <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Delete
        </button>
        <app-search-input
            (dataOutput)="onSearch($event)"
            [isTable]="true"
        ></app-search-input>
    </div>
    <div class="content-container">
        <app-data-table
            [tableHeaders]="tableHeaders"
            [tableEntries]="filteredData"
            (open)="openEntry($event)"
        ></app-data-table>
    </div>
</section>