import { Component } from '@angular/core';
import {
  faPlus,
  faMinus,
  faCircle,
  faPencil,
  faTrashCan,
  faTrash,
  faFloppyDisk,
  faCancel,
  faMagnifyingGlass,
  faSearch,
  faTriangleExclamation,
  faEraser,
  faPrint,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-export-to-hmc',
  templateUrl: './export-to-hmc.component.html',
  styleUrls: ['./export-to-hmc.component.css']
})
export class ExportToHMCComponent {
  defaultCode: string | undefined;
  defaultCodes: string[] = ["001A", "010A", "Other"];

 

  faPlus = faPlus;
  faMinus = faMinus;
  faCircle = faCircle;
  faPencil = faPencil;
  faTrashCan = faTrashCan;
  faFloppyDisk = faFloppyDisk;
  faCancel = faCancel;
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faSearch = faSearch
  faTriangleExclamation = faTriangleExclamation
  faEraser = faEraser
  faPrint = faPrint
  faDownload = faDownload

  textInput: string = "";
  required: boolean = true;


  searchBooking: FormField[] = [
    {
      type: "date",
      id: "searchFromRgeisterDate",
      label: "From Register Date (dd/mm/yyyy)",
    },
    {
      type: "date",
      id: "searchFromRgeisterDate",
      label: "From Register Date (dd/mm/yyyy)",
    },
    {
      type: "select",
      id: "defaultWhosaleCode",
      label: "Default Wholesale Code",
      options: [
        { display: "001A", value: "001A" },
        { display: "010A", value: "010A" },
        { display: "Other", value: "Other" },
      ]
    },
  ];

  buyersDetails!: FormField[];
  detailsVehicle!: FormField[];
  detailsCashDiscount!: FormField[];
  detailsDeposit!: FormField[];
  detailsOtherFees!: FormField[];
  detailsInsurance!: FormField[];
  detailsHireApplication!: FormField[];
  detailsTradeInVehicle!: FormField[];
  komocosAccount_Promotion!: FormField[];
  komocosAccount_Accessories!: FormField[];
  buyersAccount_NonAccessories!: FormField[];
  buyersAccount_Accessories!: FormField[];
  consultantAccount_NonAccessories!: FormField[];
  consultantAccount_Accessories!: FormField[];
  additionalRemarks!: FormField[];

  updateFields(event: any): void {
    switch (event.name) {
      case "searchBooking":
        this.searchBooking = event.fields;
        break;
      case "buyersDetails":
        this.buyersDetails = event.fields;
        break;
      case "detailsVehicle":
        this.detailsVehicle = event.fields;
        break;
      case "detailsCashDiscount":
        this.detailsCashDiscount = event.fields;
        break;
      case "detailsDeposit":
        this.detailsDeposit = event.fields;
        let local_PaymentType = this.detailsDeposit.find(
          (detail) => detail.id == "paymentType",
        )?.value;
        for (let i = 3; i <= 8; i++) {
          this.detailsDeposit[i].show = false;
        }
        switch (local_PaymentType) {
          case "CHEQUE":
            this.detailsDeposit[3].show = true;
            this.detailsDeposit[4].show = true;
            break;
          case "NETS":
          case "VISA/MASTER":
          case "AMEX":
            this.detailsDeposit[5].show = true;
            this.detailsDeposit[6].show = true;
            this.detailsDeposit[7].show = true;
            break;
          case "OTHERS":
            this.detailsDeposit[8].show = true;
            break;
        }
        break;
      case "detailsOtherFees":
        this.detailsOtherFees = event.fields;
        break;
      case "detailsInsurance":
        this.detailsInsurance = event.fields;
        break;
      case "detailsHireApplication":
        this.detailsHireApplication = event.fields;
        break;
      case "detailsTradeInVehicle":
        this.detailsTradeInVehicle = event.fields;
        let local_DetailsTradeInVehicle =
          this.detailsTradeInVehicle.find(
            (detail) => detail.id == "dealerName",
          )?.value;
        for (let i = 1; i <= 6; i++) {
          this.detailsTradeInVehicle[i].show = false;
        }
        switch (local_DetailsTradeInVehicle) {
          case "BOEKI AUTO & MARINE PTE LTD":
          case "KMPL USED CAR DIVISION":
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = true;
            }
            break;
          default:
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = false;
            }
        }
        break;
      case "komocosAccount_Promotion":
        this.komocosAccount_Promotion = event.fields;
        break;
      case "komocosAccount_Accessories":
        this.komocosAccount_Accessories = event.fields;
        break;
      case "buyersAccount_NonAccessories":
        this.buyersAccount_NonAccessories = event.fields;
        break;
      case "buyersAccount_Accessories":
        this.buyersAccount_Accessories = event.fields;
        break;
      case "consultantAccount_NonAccessories":
        this.consultantAccount_NonAccessories = event.fields;
        break;
      case "consultantAccount_Accessories":
        this.consultantAccount_Accessories = event.fields;
        break;
      case "additionalRemarks":
        this.additionalRemarks = event.fields;
        break;
    }
  }
}
