import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPencil, faBan, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';


@Component({
  selector: 'app-master-colour-detail',
  templateUrl: './master-colour-detail.component.html',
  styleUrls: ['./master-colour-detail.component.css']
})
export class MasterColourDetailComponent {
  faPencil = faPencil;
  faBan = faBan;
  faUsers = faUsers;

  menuItems: string[] = [];

  formFields: FormField[] = [
    { type: 'text', id: 'modelId', label: 'Model Id' },
    { type: 'select', id: 'doors', label: 'Doors', options: [{value: '2', display: '2'}, {value: '3', display: '3'}, {value: '4', display: '4'}, {value: '5', display: '5'}] },
    { type: 'text', id: 'exteriorColour', label: 'Exterior Colour'},
    { type: 'text', id: 'remarks', label: 'Remarks' },
    { type: 'select', id: 'modelGroup', label: 'Model Group', options: [{value: 'Accent', display: 'Accent'}, {value: 'Avante', display: 'Avante'}] },
    { type: 'text', id: 'type', label: 'Type' },
    { type: 'text', id: 'description', label: 'Description' }
  ];

  constructor(private route: ActivatedRoute) {
    this.route.url.subscribe(urlSegments => {
      if (urlSegments && urlSegments.length >= 2) {
        const menuItem1 = this.capitalizeFirstLetter(urlSegments[0].path.replace(/-/g, ' '));
        const menuItem2 = this.capitalizeFirstLetter(urlSegments[1].path.replace(/-/g, ' '));
        this.menuItems = [menuItem1, menuItem2];
      }
    });
  }

  private capitalizeFirstLetter(str: string): string {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
