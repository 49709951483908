import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class PromotionsService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets all promotion free items.
   * @returns An Observable of the promotion free items list.
   */
  getAll(): Observable<any> {
    return this.http.get('/PromotionFreeItemService.asmx/GetAll');
  }

  /**
   * Gets the details of a promotion free item by ID.
   * @param id - The ID of the promotion free item.
   * @returns An Observable of the promotion free item details.
   */
  getDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`);
    const url = `/PromotionFreeItemService.asmx/GetDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  /**
   * Saves promotion free item details (for both Add and Update).
   * @param data - The promotion free item data to save.
   * @returns An Observable for the result of the save operation.
   */
  saveDetails(data: any): Observable<any> {
    return this.http.post('/PromotionFreeItemService.asmx/SaveDetails', { data });
  }

  /**
   * Deletes the selected promotion free items.
   * @param items - The list of promotion free items to delete.
   * @returns An Observable for the delete operation.
   */
  deletePromotionFreeItems(items: any[]): Observable<any> {
    return this.http.post('/PromotionFreeItemService.asmx/Delete', { items });
  }
}
