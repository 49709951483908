import { Component } from "@angular/core";
import {
  faPlus,
  faMinus,
  faCircle,
  faPencil,
  faTrashCan,
  faTrash,
  faFloppyDisk,
  faCancel,
  faMagnifyingGlass,
  faSearch,
  faTriangleExclamation,
  faEraser,
  faPrint,
  faDownload,
  faCalculator,
  faLink
} from "@fortawesome/free-solid-svg-icons";

import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-gst-calculation",
  templateUrl: "./gst-calculation.component.html",
  styleUrls: ["./gst-calculation.component.css"],
})
export class GstCalculationComponent {
  titleSelect: string | undefined;
  coeType: string | undefined;
  modeContac: string | undefined;
  carType: string | undefined;
  discount: string | undefined;
  paymentType: string | undefined;
  loanPeriod: string | undefined;
  dealrName: string | undefined;
  insuranceCompany: string | undefined;
  titles: string[] = ["MR", "MRS", "MISS", "M/S", "MMDM", "DR", "MS"];
  coes: string[] = ["Bidding", "Own COE", "Immediate E"];
  cartypes: string[] = [
    "Standard",
    "Off Peak Car(OPC)",
    "Compressed Natural Gas (CNG)",
    "CNG OPC",
    "OPC",
  ];
  modscontac: string[] = [
    "REFERRAL",
    "REPEAT",
    "WALK-IN",
    "POSTALE",
    "ONLINE SALES",
  ];
  discounts: string[] = ["500", "700", "1000"];
  paymenttypes: string[] = [
    "AIG OTHERS",
    "AIG IN HOUSE",
    "AXA INSURANCE OTHERS",
    "AXA INSURANCE IN-HOUSE",
    "LIBERTY INSURANCE IN-HOUSE",
    "MSIG INSURANCE IN-HOUSE",
    "NTUC INCOME OTHERS",
    "NTUC INCOME IN-HOUSE",
    "OWN INSURANCE",
  ];
  loanperiods: string[] = [
    "12",
    "24",
    "36",
    "48",
    "60",
    "72",
    "84",
    "96",
    "108",
    "116",
    "118",
    "120",
  ];
  dealernames: string[] = [
    "No Trade In",
    "BOEKI AUTO & MARINE PTE LTD",
    "KMPL USED CAR DIVISION",
  ];

  faPlus = faPlus;
  faMinus = faMinus;
  faCircle = faCircle;
  faPencil = faPencil;
  faTrashCan = faTrashCan;
  faFloppyDisk = faFloppyDisk;
  faCancel = faCancel;
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faSearch = faSearch;
  faTriangleExclamation = faTriangleExclamation;
  faEraser = faEraser;
  faPrint = faPrint;
  faDownload = faDownload;
  faCalculator = faCalculator;
  faLink = faLink;

  textInput: string = "";
  required: boolean = true;
  searchText = "";
  searchControl = new FormControl("");
  items = ["Element1", "Element2", "Element3", "Element4"];
  filteredItems: string[] = [];
  showList = false;

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe((value) => {
        this.filterItems(value);
        if (value) {
          this.showList = true;
        }
      });
  }

  filterItems(searchText: string | null): void {
    if (!searchText) {
      this.filteredItems = [];
      this.showList = false;
    } else {
      this.filteredItems = this.items.filter((item) =>
        item.toLowerCase().includes(searchText.toLowerCase()),
      );
      this.showList = this.filteredItems.length > 0;
    }
  }

  selectItem(item: string): void {
    this.searchControl.setValue(item);
    this.filteredItems = [];
    this.showList = false;
  }

  searchBooking: FormField[] = [
    {
      type: "search",
      id: "contractNumber",
      label: "Contract Number",
    },
    {
      type: "date",
      id: "registrationDate",
      label: "Registration Date",
    },
    {
      type: "number",
      id: "registrationNumber",
      label: "Registration Number",
    },
  ];

  totalAmountsofcontract!: FormField[];
  lessAmountsNotTaxable!: FormField[];
  othersNonGSTVehicleOnly!: FormField[];
  rebatesNotIncludedGSTcalculation!: FormField[];
  others!: FormField[];
  detailsInsurance!: FormField[];
  detailsHireApplication!: FormField[];
  detailsTradeInVehicle!: FormField[];
  komocosAccount_Promotion!: FormField[];
  komocosAccount_Accessories!: FormField[];
  buyersAccount_NonAccessories!: FormField[];
  buyersAccount_Accessories!: FormField[];
  consultantAccount_NonAccessories!: FormField[];
  consultantAccount_Accessories!: FormField[];
  additionalRemarks!: FormField[];

  updateFields(event: any): void {
    switch (event.name) {
      case "searchBooking":
        this.searchBooking = event.fields;
        break;
      case "totalAmountsofcontract":
        this.totalAmountsofcontract = event.fields;
        break;
      case "lessAmountsNotTaxable":
        this.lessAmountsNotTaxable = event.fields;
        break;
      case "othersNonGSTVehicleOnly":
        this.othersNonGSTVehicleOnly = event.fields;
        break;
      case "rebatesNotIncludedGSTcalculation":
        this.rebatesNotIncludedGSTcalculation = event.fields;
        let local_PaymentType = this.rebatesNotIncludedGSTcalculation.find(
          (detail) => detail.id == "paymentType",
        )?.value;
        for (let i = 3; i <= 8; i++) {
          this.rebatesNotIncludedGSTcalculation[i].show = false;
        }
        switch (local_PaymentType) {
          case "CHEQUE":
            this.rebatesNotIncludedGSTcalculation[3].show = true;
            this.rebatesNotIncludedGSTcalculation[4].show = true;
            break;
          case "NETS":
          case "VISA/MASTER":
          case "AMEX":
            this.rebatesNotIncludedGSTcalculation[5].show = true;
            this.rebatesNotIncludedGSTcalculation[6].show = true;
            this.rebatesNotIncludedGSTcalculation[7].show = true;
            break;
          case "OTHERS":
            this.rebatesNotIncludedGSTcalculation[8].show = true;
            break;
        }
        break;
      case "others":
        this.others = event.fields;
        break;
      case "detailsInsurance":
        this.detailsInsurance = event.fields;
        break;
      case "detailsHireApplication":
        this.detailsHireApplication = event.fields;
        break;
      case "detailsTradeInVehicle":
        this.detailsTradeInVehicle = event.fields;
        let local_DetailsTradeInVehicle =
          this.detailsTradeInVehicle.find(
            (detail) => detail.id == "dealerName",
          )?.value;
        for (let i = 1; i <= 6; i++) {
          this.detailsTradeInVehicle[i].show = false;
        }
        switch (local_DetailsTradeInVehicle) {
          case "BOEKI AUTO & MARINE PTE LTD":
          case "KMPL USED CAR DIVISION":
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = true;
            }
            break;
          default:
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = false;
            }
        }
        break;
      case "komocosAccount_Promotion":
        this.komocosAccount_Promotion = event.fields;
        break;
      case "komocosAccount_Accessories":
        this.komocosAccount_Accessories = event.fields;
        break;
      case "buyersAccount_NonAccessories":
        this.buyersAccount_NonAccessories = event.fields;
        break;
      case "buyersAccount_Accessories":
        this.buyersAccount_Accessories = event.fields;
        break;
      case "consultantAccount_NonAccessories":
        this.consultantAccount_NonAccessories = event.fields;
        break;
      case "consultantAccount_Accessories":
        this.consultantAccount_Accessories = event.fields;
        break;
      case "additionalRemarks":
        this.additionalRemarks = event.fields;
        break;
    }
  }

  ngOnInit(): void {
    this.totalAmountsofcontract = [
      {
        type: "number",
        id: "vehiclePrice",
        label: "Vehicle Price",
      },
      {
        type: "text",
        id: "coeTopUp",
        label: "COE Top Up",
      },
      {
        type: "text",
        id: "additionalRoadTax",
        label: "Additional Road Tax",
      },
      {
        type: "text",
        id: "additionalRadioLicense",
        label: "Additional Radio License",
      },
      { type: "text", id: "cOEDeposit", label: "COE Deposit" },
      {
        type: "text",
        id: "accessories",
        label: "Accessories",
      },
      {
        type: "const",
        id: "totalLoanAmountPayable",
        label: "Total Loan Amount Payable",
      },
    ];
    this.lessAmountsNotTaxable = [
      {
        type: "number",
        id: "roadtaxRefund",
        label: "Road Tax Refund",
      },
      {
        type: "number",
        id: "registrationRebate",
        label: "Registration Rebate",
      },
      {
        type: "number",
        id: "cashDiscount",
        label: "Cash Discount",
      },
      {
        type: "number",
        id: "coeSubsidy",
        label: "COE Subsidy",
      },
      { type: "number", id: "coeRebate", label: "COE Rebate" },
      {
        type: "number",
        id: "dutyRebate",
        label: "Duty Rebate",
      },
      {
        type: "number",
        id: "arfRebate",
        label: "ARF Rebate",
      },
      {
        type: "number",
        id: "cevsRebate",
        label: "CEVS Rebate",
      },
      {
        type: "number",
        id: "costCOE",
        label: "Cost of COE",
      },
      {
        type: "number",
        id: "roadTax",
        label: "Road Tax",
      },
      {
        type: "number",
        id: "radioLicense",
        label: "Radio License",
      },
      {
        type: "number",
        id: "registration",
        label: "Registration",
      },
      {
        type: "number",
        id: "transWeighingFee",
        label: "Trans/WeighingFee",
      },
      {
        type: "number",
        id: "ARF",
        label: "ARF",
      },
      {
        type: "number",
        id: "cevsvesSurcharge",
        label: "CEVS/VES Surcharge",
      },
      {
        type: "number",
        id: "coeRebateLTA",
        label: "COE Rebate LTA",
      },
      {
        type: "number",
        id: "totalAmountPayabletoLTA",
        label: "Total Amount Payable to LTA",
      },
    ];
    this.othersNonGSTVehicleOnly = [
      {
        type: "text",
        id: "lessDescription",
        label: "Less (Description)",
      },
      {
        type: "number",
        id: "lessAmount",
        label: "Less (Amount)",
      },
      {
        type: "text",
        id: "Add (Description)",
        label: "Add (Description)",
      },
      {
        type: "number",
        id: "addAmount",
        label: "Add (Amount)",
      },
      {
        type: "const",
        id: "subTotal",
        label: "Sub Total",
      },
    ];
    this.rebatesNotIncludedGSTcalculation = [
      {
        type: "text",
        id: "parfRebate",
        label: "PARF Rebate",
      },
      {
        type: "number",
        id: "coeResidualRebate",
        label: "COE Residual Rebate",
      },
      {
        type: "text",
        id: "opcRebate",
        label: "OPC Rebate",
      },
    ];
    this.others = [
      {
        type: "text",
        id: "creditNote",
        label: "Credit Note",
      },
      {
        type: "text",
        id: "inWords",
        label: "In Words",
      },
      {
        type: "number",
        id: "regrossedTaxableAmountSubjectTo5GST",
        label: "Regrossed Taxable Amount Subject to 5% GST",
      },
      {
        type: "number",
        id: "gstPayableat5",
        label: "GTS Payable at 5%",
      },
      {
        type: "number",
        id: "regrossedTaxableAmountSubjectTo7GST",
        label: "Regrossed Taxable Amount Subject to 7% GST",
      },
      {
        type: "number",
        id: "gstPayableat7",
        label: "GTS Payable at 7%",
      },
      {
        type: "number",
        id: "regrossedTaxableAmountSubjectTo8GST",
        label: "Regrossed Taxable Amount Subject to 8% GST",
      },
      {
        type: "number",
        id: "gstPayableat8",
        label: "GTS Payable at 8%",
      },
      {
        type: "number",
        id: "regrossedTaxableAmountSubjectTo9GST",
        label: "Regrossed Taxable Amount Subject to 9% GST",
      },
      {
        type: "number",
        id: "gstPayableat9",
        label: "GTS Payable at 9%",
      },
      {
        type: "number",
        id: "subTotal",
        label: "Sub Total",
      },
    ];
  }
}
