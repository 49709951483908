import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class VehicleStockAvailabilityService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets a list of active models in sales drop-down.
   * @returns An Observable of the active models list.
   */
  getModelActiveInSalesDrpList(): Observable<any> {
    return this.http.get('/LookUpService.asmx/GetModelActiveInSalesDrpList');
  }

  /**
   * Gets a list of all active users.
   * @returns An Observable of the active users list.
   */
  getAllActiveUsers(): Observable<any> {
    return this.http.get('/LookUpService.asmx/GetAllActiveUsers');
  }

  /**
   * Gets vehicle stock availability for the given model description.
   * @param modelDesc - The description of the model to check stock availability for.
   * @returns An Observable of the stock availability details.
   */
  getVehicleStockAvailability(formattedModelDesc: string): Observable<any> {
    
    const url = `/ModelSpecificationService.asmx/GetVehicleStockAvailability?modelDesc=${formattedModelDesc}`;

    return this.http.get(url);
  }
}
