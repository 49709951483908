import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-data-form",
  templateUrl: "./data-form.component.html",
  styleUrls: ["./data-form.component.css"],
})
export class DataFormComponent implements OnInit {
  @Input() dataFields: FormField[] = [];
  @Input() columns: number = 2;
  @Input() dataName: string = "";

  @Output() clickAdd = new EventEmitter<{ fieldId: string }>();
  @Output() clickSearch = new EventEmitter<{ fieldId: string }>();
  @Output() updateFields_child = new EventEmitter<any>();
  @Output() clickUpdate = new EventEmitter<{ fieldId: string }>(); 
  allChecked: boolean = false;

  assignValue(
    content: any,
    filteredIndex: number,
    innerIndex?: number,
  ) {
    const filteredField = this.filterHidden(this.dataFields)[filteredIndex];
    const originalIndex = this.dataFields.findIndex(
      (field) => field.id === filteredField.id
    );
    if (originalIndex !== -1) {
      if (this.dataFields[originalIndex].type == "doubleText") {
        this.dataFields[originalIndex].value[innerIndex!] = content;
      } else if (
        !["formCheckbox", "formCheckboxSingle", "checkbox"].includes(
          this.dataFields[originalIndex].type
        )
      ) {
        this.dataFields[originalIndex].value = content;
      }
      this.updateFields_child.emit({
        fields: this.dataFields,
        name: this.dataName,
        updatedValue: originalIndex,
      });
    }
  }
  markAll(
    options: { display: string; value: string }[] | undefined,
    index: number,
    allChecked: boolean,
  ) {
    if (options) {
      for (let option = 0; option < options!.length; option++) {
        this.dataFields[index].options![option].marked = allChecked;
      }
      this.updateFields_child.emit({
        fields: this.dataFields,
        name: this.dataName,
      });
    }
  }

  filterHidden(fieldArray: FormField[]): any[] {
    if (fieldArray) {
      return fieldArray.filter(
        (item) => item.show || item.show == undefined
      );
    } else {
      return fieldArray;
    }
  }

  onSearch(event: Event, fieldId: string): void {
    event.preventDefault();
    this.clickSearch.emit({ fieldId });
  }

  onAdd(event: Event, fieldId: string): void {
    event.preventDefault();
    this.clickAdd.emit({ fieldId });
  }

  onUpdate(event: Event, fieldId: string): void {
    event.preventDefault();
    this.clickUpdate.emit({ fieldId });
  }


  constructor() {}

  ngOnInit() {}
}
