<section
  class="page-container"
  [ngClass]="{ 'overflow-y-hidden': showAcceptance }"
>
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Booking</h1>
    <app-navpath />
  </div>
  <div class="flex w-fit gap-1" *ngIf="!hasData">
    <button
      class="shorter-btn save-btn"
      [disabled]="!canUpload"
      (click)="Add()"
    >
      <fa-icon [icon]="faPlus" class="mr-1" />Generate Buyer's Order
    </button>
    <button class="shorter-btn clear-btn" (click)="clearForms()">
      <fa-icon [icon]="faBroom" class="mr-1" />Clear
    </button>
  </div>
  <div class="flex w-fit gap-1" *ngIf="hasData">
    <button
      class="shorter-btn save-btn"
      (click)="Update()"
      [disabled]="!canUpload"
    >
      <fa-icon [icon]="faEdit" class="mr-1" />Update Buyer's Order
    </button>
    <button
      class="shorter-btn delete-btn"
      *ngIf="!hasContract"
      (click)="showModalCancel(true)"
    >
      <fa-icon [icon]="faEdit" class="mr-1" />Cancel Buyer's Order
    </button>
    <button
      class="shorter-btn print-btn"
      (click)="GetBuyerOrderFormForBuyOnline()"
    >
      <fa-icon [icon]="faFilePdf" class="mr-1" />Print Buyer's Order
    </button>
    <button class="shorter-btn print-btn" (click)="showModal(true)">
      <fa-icon [icon]="faFilePdf" class="mr-1" />Print Without Selling
      Price
    </button>
    <button class="shorter-btn clear-btn" (click)="clearForms()">
      <fa-icon [icon]="faBroom" class="mr-1" />Clear
    </button>
  </div>
  <div class="content-container">
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Booking</h2>
      <app-data-form
        [dataFields]="Forms['SearchBooking']"
        [dataName]="'SearchBooking'"
        (updateFields_child)="updateFields($event)"
        (clickSearch)="searchBooking($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Buyer's Details</h2>
      <app-data-form
        [dataFields]="Forms['BuyerDetails']"
        [dataName]="'BuyerDetails'"
        (updateFields_child)="updateFields($event)"
        (clickSearch)="searchPostalCodeOrIcNo($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details of Vehicle</h2>
      <app-data-form
        [dataFields]="Forms['VehicleDetails']"
        [dataName]="'VehicleDetails'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Model stock Availability
      </h2>
      <app-data-table
        *ngIf="AvailabilityEntries.length > 0"
        [tableHeaders]="AvailabilityHeaders"
        [tableEntries]="AvailabilityEntries"
        [pagination]="false"
      ></app-data-table>
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of Cash Discount
      </h2>
      <app-data-form
        [dataFields]="Forms['DetailsCashDiscount']"
        [dataName]="'DetailsCashDiscount'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Purchase History</h2>
      <app-data-table
        *ngIf="HistoryEntries.length > 0"
        [tableHeaders]="HistoryHeaders"
        [tableEntries]="HistoryEntries"
        [pagination]="false"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details of Deposit</h2>
      <app-data-form
        [dataFields]="Forms['DetailsDeposit']"
        [dataName]="'DetailsDeposit'"
        (updateFields_child)="updateFields($event)"
      />
      <button class="shorter-btn save-btn" (click)="AddPayment()">
        <fa-icon [icon]="faPlus" class="mr-1" />Add Payment
      </button>
      <app-data-table
        *ngIf="DepositEntries.length > 0"
        [tableHeaders]="DepositHeaders"
        [tableEntries]="DepositEntries"
        (remove)="RemovePayment($event)"
        [pagination]="false"
        class="mt-4 block"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of Other Fees
      </h2>
      <app-data-form
        [dataFields]="Forms['DetailsOtherFees']"
        [dataName]="'DetailsOtherFees'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of Insurance
      </h2>
      <app-data-form
        [dataFields]="Forms['DetailsInsurance']"
        [dataName]="'DetailsInsurance'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of Hire Purchase Application
      </h2>
      <app-data-form
        [dataFields]="Forms['DetailsHireApplication']"
        [dataName]="'DetailsHireApplication'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">
        Details of Trade-In Vehicle
      </h2>
      <app-data-form
        [dataFields]="Forms['DetailsTradeInVehicle']"
        [dataName]="'DetailsTradeInVehicle'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Details Of Accounts</h2>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Komoco's Account</h2>
        <div class="mt-4">
          <h3 class="subsub-heading">Promotion</h3>
          <app-data-form
            [dataFields]="Forms['KomocosAccount_Promotion']"
            [dataName]="'KomocosAccount_Promotion'"
            (updateFields_child)="updateFields($event)"
            (clickAdd)="addPromotion($event)"
          />
          <app-data-table
            *ngIf="PromotionsEntries.length > 0"
            [tableHeaders]="PromotionsHeaders"
            [tableEntries]="PromotionsEntries"
            [pagination]="false"
            (remove)="RemovePromotion($event)"
            class="mb-4 block"
          />
          <h3 class="subsub-heading">Accessories</h3>
          <app-data-form
            [dataFields]="Forms['KomocosAccount_Accessories']"
            [dataName]="'KomocosAccount_Accessories'"
            (updateFields_child)="updateFields($event)"
            (clickAdd)="addAccessoryKomoco($event)"
          />
          <app-data-table
            *ngIf="AccessoriesKomocoEntries.length > 0"
            [tableHeaders]="AccessoriesHeaders"
            [tableEntries]="AccessoriesKomocoEntries"
            [pagination]="false"
            (remove)="RemoveItem($event, 'AccessoryKomoco')"
            class="mb-4 block"
          />
        </div>
      </section>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Buyer's Account</h2>
        <div class="mt-4">
          <h3 class="subsub-heading">Non accessories</h3>
          <app-data-form
            [dataFields]="Forms['BuyersAccount_NonAccessories']"
            [dataName]="'BuyersAccount_NonAccessories'"
            (updateFields_child)="updateFields($event)"
            (clickAdd)="addItemBuyer($event)"
          />
          <h3 class="subsub-heading">Accessories</h3>
          <app-data-form
            [dataFields]="Forms['BuyersAccount_Accessories']"
            [dataName]="'BuyersAccount_Accessories'"
            (updateFields_child)="updateFields($event)"
            (clickAdd)="addItemBuyer($event)"
          />
          <app-data-table
            *ngIf="ItemsBuyerEntries.length > 0"
            [tableHeaders]="ItemsHeaders"
            [tableEntries]="ItemsBuyerEntries"
            [pagination]="false"
            (remove)="RemoveItem($event, 'ItemBuyer')"
            class="mb-4 block"
          />
        </div>
      </section>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">
          Sales consultant's account
        </h2>
        <div class="mt-4">
          <h3 class="subsub-heading">Non accessories</h3>
          <app-data-form
            [dataFields]="Forms['SalesmanAccount_NonAccessories']"
            [dataName]="'SalesmanAccount_NonAccessories'"
            (updateFields_child)="updateFields($event)"
            (clickAdd)="addItemSalesman($event)"
          />
          <h3 class="subsub-heading">Accessories</h3>
          <app-data-form
            [dataFields]="Forms['SalesmanAccount_Accessories']"
            [dataName]="'SalesmanAccount_Accessories'"
            (updateFields_child)="updateFields($event)"
            (clickAdd)="addItemSalesman($event)"
          />
          <app-data-table
            *ngIf="ItemsSalesmanEntries.length > 0"
            [tableHeaders]="ItemsHeaders"
            [tableEntries]="ItemsSalesmanEntries"
            [pagination]="false"
            (remove)="RemoveItem($event, 'ItemSalesman')"
            class="mb-4 block"
          />
        </div>
      </section>
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Additional remarks</h2>
      <app-data-form
        [dataFields]="Forms['AdditionalRemarks']"
        [dataName]="'AdditionalRemarks'"
        (updateFields_child)="updateFields($event)"
      />
    </section>
    <div class="mt-4 flex w-fit gap-1" *ngIf="!hasData">
      <button
        class="shorter-btn save-btn"
        [disabled]="!canUpload"
        (click)="Add()"
      >
        <fa-icon [icon]="faPlus" class="mr-1" />Generate Buyer's Order
      </button>
      <button class="shorter-btn clear-btn" (click)="clearForms()">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
    <div class="mt-4 flex w-fit gap-1" *ngIf="hasData">
      <button
        class="shorter-btn save-btn"
        [disabled]="!canUpload"
        (click)="Update()"
      >
        <fa-icon [icon]="faEdit" class="mr-1" />Update Buyer's Order
      </button>
      <button
        class="shorter-btn delete-btn"
        *ngIf="!hasContract"
        (click)="showCancel = true"
      >
        <fa-icon [icon]="faEdit" class="mr-1" />Cancel Buyer's Order
      </button>
      <button
        class="shorter-btn print-btn"
        (click)="GetBuyerOrderFormForBuyOnline()"
      >
        <fa-icon [icon]="faFilePdf" class="mr-1" />Print Buyer's Order
      </button>
      <button class="shorter-btn print-btn" (click)="showModal(true)">
        <fa-icon [icon]="faFilePdf" class="mr-1" />Print Without
        Selling Price
      </button>
      <button class="shorter-btn clear-btn" (click)="clearForms()">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </div>
</section>
<div class="bg-acceptance" *ngIf="showAcceptance">
  <div class="container-acceptance">
    <div class="flex flex-col justify-between">
      <h2 class="sub-heading">Acceptance</h2>
      <label for="" class="flex items-center gap-x-3">
        <input
          type="checkbox"
          class="checkbox-single"
          name=""
          id=""
          [(ngModel)]="enableAcceptance"
        />
        <p class="font-normal">
          I hereby acknowledge and accept that the details stipulated
          on the buyer's order including model (
          <strong>{{
            getField("ModelName", "VehicleDetails").value
          }}</strong>
          ) and the colour (
          <strong>{{
            getField("Colour", "VehicleDetails").value
          }}</strong>
          ) is true to the best of my knowledge.
        </p>
      </label>
      <div class="mx-auto mt-4 flex w-fit gap-4">
        <button
          class="shorter-btn save-btn"
          [ngClass]="{
            'cursor-not-allowed opacity-50': !enableAcceptance
          }"
          (click)="PrintWithoutSellingPrice()"
          [disabled]="!enableAcceptance"
        >
          Yes
        </button>
        <button
          class="shorter-btn delete-btn"
          (click)="showModal(false)"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="bg-acceptance" *ngIf="showCancel">
  <div class="container-acceptance">
    <div class="flex flex-col justify-between">
      <div for="" class="mb-8 flex justify-between">
        <p class="font-normal">If yes then enter Cancel Remarks</p>
        <input
          type="text"
          name=""
          id=""
          class="cancel-remarks"
          [(ngModel)]="CancelRemarks"
        />
      </div>
      <div class="ml-auto mr-0 mt-4 flex w-fit gap-4">
        <button
          class="shorter-btn save-btn"
          (click)="CancelContract()"
        >
          Yes
        </button>
        <button
          class="shorter-btn delete-btn"
          (click)="showModalCancel(false)"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
