import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faPlus,
  faCancel,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { NotifierService } from "angular-notifier";
import { PriceListMasterService } from "src/app/services/sales/price-list-master.service";
import { formatDate } from "@angular/common";
import { getEmptyGuid } from "src/app/services/util.service";

@Component({
  selector: "app-price-list-master-detail",
  templateUrl: "./price-list-master-detail.component.html",
  styleUrls: ["./price-list-master-detail.component.css"],
})
export class PriceListMasterDetailComponent implements OnInit {
  faPlus = faPlus;
  faCancel = faCancel;
  faPencil = faPencil;

  mode: string = "Add";
  lockUpdate: boolean = true;
  lockRetailPrice: boolean = true;
  masterId: string = "0";
  __type: string = "";
  EFFECTIVEDATETemp: string | null = null;
  SURCHARGE: number = 0;

  availableModels: any[] = [];

  constructor(
    private priceListMasterService: PriceListMasterService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadActiveModelList();
    this.route.params.subscribe((params) => {
      this.masterId = params["id"];
      if (this.masterId !== "0") {
        this.mode = "Edit";
        this.lockUpdate = false;
        this.loadModelDetails();
        this.getField("updateBoth").show = true;
      }
    });
  }

  formFields: FormField[] = [
    {
      type: "select",
      id: "MODELSHORTDEC",
      label: "Model Short Desc",
      required: true,
      options: [],
    },
    {
      type: "text",
      id: "MODELGROUP",
      label: "Model Group",
      required: true,
    },
    {
      type: "text",
      id: "MODEL",
      label: "Model Code",
      required: true,
    },
    {
      type: "number",
      id: "ENGINECAP",
      label: "Eng. Cap",
    },
    {
      type: "number",
      id: "SEQ",
      label: "Sequence",
    },
    {
      type: "number",
      id: "SUBSEQ",
      label: "Model Sub Seq",
    },
    { type: "text", id: "CATEGORY", label: "Category" },
    {
      type: "date",
      id: "EFFECTIVEDATE",
      label: "Effective Date",
      required: true,
    },
    {
      type: "number",
      id: "PRICE",
      label: "Price",
      required: true,
    },
    {
      type: "number",
      id: "REGFEE",
      label: "Reg Fee",
      required: true,
    },
    {
      type: "number",
      id: "COE",
      label: "COE Off Set",
      required: true,
    },
    {
      type: "number",
      id: "ROADTAX",
      label: "6 Months Road Tax",
    },
    {
      type: "number",
      id: "ARFNET",
      label: "ARF Net",
      lock: true,
    },
    {
      type: "number",
      id: "CNG",
      label: "CNG Price",
    },
    {
      type: "number",
      id: "TRADEINDISCOUNT",
      label: "Trade-In Discount",
    },
    {
      type: "number",
      id: "INHOUSEFINREBATE",
      label: "In-House Finance Rebate",
    },
    {
      type: "number",
      id: "INHOUSEINSREBATE",
      label: "In-House Ins. Rebate",
    },
    {
      type: "number",
      id: "RETAILPRICE",
      label: "Retail Price",
      lock: true,
    },
    {
      type: "formCheckboxSingle",
      id: "updateBoth",
      label:
        "By default both Sales Price and List Price will be updated, unselect checkbox to update only List Price",
      value: true,
      show: false,
    },
  ];

  private loadModelDetails(): void {
    this.priceListMasterService.GetDetails(this.masterId).subscribe({
      next: (response) => {
        const { __type, Id, EFFECTIVEDATETemp, SURCHARGE, ...data } =
          response.body;
        this.__type = __type;
        this.EFFECTIVEDATETemp = EFFECTIVEDATETemp;
        this.SURCHARGE = SURCHARGE;
        Object.entries(data).forEach(([key, value]) => {
          if (key == "EFFECTIVEDATE") {
            this.getField(key).value = this.dateToField(
              value as string,
            );
            return;
          }
          this.getField(key).value = value;
        });
      },
      error: (error: any) => {
        console.error("Error loading model details:", error);
      },
    });
  }

  loadActiveModelList(): void {
    this.priceListMasterService.GetBasicActiveModelList().subscribe({
      next: (response) => {
        this.availableModels = response.body;
        this.getField("MODELSHORTDEC").options = response.body.map(
          (item) => ({
            display: item.ModelShortDesc,
            value: item.ModelShortDesc,
          }),
        );
      },
      error: console.error,
    });
  }

  updateFields(event: any) {
    if (event.updatedValue == 0) {
      const newModel = this.availableModels.find(
        (item) =>
          item.ModelShortDesc == this.getField("MODELSHORTDEC").value,
      );
      this.getField("MODELGROUP").value = newModel.ModelShort;
      this.getField("MODEL").value = newModel.ModelCode;
      this.SURCHARGE = newModel.CevsSurchargeOrRebate;
      this.lockRetailPrice = false;
    }
    this.calculatePrices();
    this.checkCanUpdate();
  }

  calculatePrices(): void {
    const newARFNET =
      (this.getField("PRICE").value ?? 0) -
      (this.getField("REGFEE").value ?? 0) -
      (this.getField("COE").value ?? 0) -
      (this.getField("ROADTAX").value ?? 0);
    this.getField("ARFNET").value = newARFNET;
    if (!this.lockRetailPrice) {
      const newRETAILPRICE =
        (this.SURCHARGE ?? 0) +
        (Number(this.getField("PRICE").value) ?? 0) -
        (this.getField("TRADEINDISCOUNT").value ?? 0) -
        (this.getField("INHOUSEFINREBATE").value ?? 0) -
        (this.getField("INHOUSEINSREBATE").value ?? 0);
      this.getField("RETAILPRICE").value = newRETAILPRICE;
    }
  }

  onCancel(): void {
    this.router.navigate(["../../price-list-master"], {
      relativeTo: this.route,
    });
  }

  Update(): void {
    const model = this.formFields.reduce((acc: any, field) => {
      if (field.id == "EFFECTIVEDATE") {
        acc[field.id] = formatDate(
          field.value,
          "dd/MM/yyyy",
          "en-US",
        );
      } else {
        acc[field.id] = field.value;
      }
      return acc;
    }, {});
    model["__type"] = this.__type;
    model["Id"] = this.masterId;
    model["EFFECTIVEDATETemp"] = this.EFFECTIVEDATETemp;
    model["SURCHARGE"] = this.SURCHARGE;
    this.priceListMasterService
      .Update(this.getField("updateBoth").value, model)
      .subscribe({
        next: () =>
          this.notifier.notify(
            "success",
            "Price List has been updated successfully",
          ),
        error: console.error,
      });
  }

  Add(): void {
    const model = this.formFields.reduce((acc: any, field) => {
      if (field.id == "EFFECTIVEDATE") {
        acc[field.id] = formatDate(
          field.value,
          "dd/MM/yyyy",
          "en-US",
        );
      } else {
        acc[field.id] = field.value ?? null;
      }
      return acc;
    }, {});
    model["Id"] = getEmptyGuid();
    model["SURCHARGE"] = this.SURCHARGE;
    this.priceListMasterService.Add(model).subscribe({
      next: (response) => {
        if (response.body.Code == 0) {
          this.notifier.notify(
            "success",
            "Price List has been added successfully",
          );
        } else {
          this.notifier.notify("error", response.body.Message);
        }
      },
      error: console.error,
    });
  }
  getField(id: string): any {
    const field = this.formFields.find((f) => f.id === id);
    return field;
  }

  checkCanUpdate(): void {
    this.lockUpdate = !(
      this.formFields
        .filter((item) => item.required == true && item.show != false)
        .every((item) => item.value) &&
      this.getField("RETAILPRICE").value >= 0 &&
      this.getField("ARFNET").value > 0
    );
  }

  dateToField(date: string): string {
    const [day, month, year] = date.split("/");
    if (!day || !month || !year) {
      throw new Error("Date has no valid format");
    }
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
}
