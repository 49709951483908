<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Users Management</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button
      class="shorter-btn add-btn"
      [routerLink]="[
        '../user-management',
        '00000000-0000-0000-0000-000000000000'
      ]"
    >
      <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add User
    </button>
    <app-search-input
      (dataOutput)="onSearch($event)"
      [isTable]="true"
    ></app-search-input>
  </div>
  <div class="content-container">
    <app-data-table
      (edit)="editEntry($event)"
      (remove)="removeEntry($event)"
      (open)="openEntry($event)"
      [tableHeaders]="tableHeaders"
      [tableEntries]="filteredUsers"
    ></app-data-table>
  </div>
</section>
