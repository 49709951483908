import { Component, OnInit } from "@angular/core";
import { faPlus, faTrash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { InsuranceService } from "src/app/services/sales/insurance.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: "app-insurance-list",
  templateUrl: "./insurance-list.component.html",
  styleUrls: ["./insurance-list.component.css"],
})
export class InsuranceListComponent implements OnInit {
  searchInput: string = "";
  filteredInsuranceCompanies: any[] = [];
  insuranceCompanies: any[] = [];

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    { title: "", type: "checkbox" },
    { title: "INSURANCE COMPANY", type: "link", id: "AcCode" },
    { title: "DESCRIPTION", type: "data", id: "Acname", classes: "max-w-64" },
    { title: "ADDRESS", type: "data", id: "Address1" },
    { title: "CONTACT PERSON", type: "data", id: "ContactPerson" },
    { title: "CONTACT NUMBER", type: "data", id: "ContactNumber" },
    { title: "ID", type: "hidden", id: "InsAccountId" },
  ];

  constructor(
    private insuranceService: InsuranceService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.insuranceService.getAll().subscribe(
      (response) => {
        if (response && Array.isArray(response.body)) {
          this.insuranceCompanies = response.body.map((company: any) => ({
            checked: false,
            AcCode: company.AcCode || "",
            Acname: company.Acname || "",
            Address1: company.Address1 || "",
            ContactPerson: company.ContactPerson || "",
            ContactNumber: company.ContactNumber || "",
            InsAccountId: company.InsAccountId || "",
          }));
          this.filteredInsuranceCompanies = [...this.insuranceCompanies];
        } else {
          console.error("Error: Incorrect data.");
        }
      },
      (error) => {
        console.error("Error getting data.", error);
      }
    );
  }
  
  

  openEntry(value: any): void {
    this.router.navigate(["../insurance", value.InsAccountId], {
      relativeTo: this.route,
    });
  }

  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../insurance", emptyGuid], {
      relativeTo: this.route,
    });
  }

  deleteSelectedEntries(): void {
    const selectedItems = this.filteredInsuranceCompanies.filter(item => item.checked);

    if (selectedItems.length === 0) {
      this.notifier.notify("error", "Please select at least one item to delete.");
      return;
    }

    this.insuranceService.delete(selectedItems).subscribe({
      next: () => {
        this.notifier.notify("success", "Selected items deleted successfully");
        this.filteredInsuranceCompanies = this.filteredInsuranceCompanies.filter(item => !item.checked);
      },
      error: (error) => {
        console.error("Error deleting insurance companies:", error);
        this.notifier.notify("error", "Error deleting selected items.");
      }
    });
  }

  onSearch(searchValue: string): void {
    this.filteredInsuranceCompanies = this.insuranceCompanies.filter(item =>
      item.AcCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.Acname.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.ContactPerson?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
}
