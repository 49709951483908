<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Weekly Focus Report Details</h1>
      <app-navpath></app-navpath>
    </div>
  
    <div class="mb-2 flex w-fit gap-1">
      <!-- Botones Save, Clear, Cancel -->
      <button class="shorter-btn misc-btn" (click)="saveEntry()">
        <fa-icon [icon]="isEdit ? faPencil : faSave" class="mr-1"></fa-icon>
        {{ isEdit ? 'Update' : 'Save' }}
      </button>
      <button class="shorter-btn misc-btn" (click)="clearEntry()">
        <fa-icon [icon]="faEraser" class="mr-1"></fa-icon>Clear
      </button>
      <button class="shorter-btn clear-btn" (click)="cancelEntry()">
        <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Cancel
      </button>
      <button class="shorter-btn save-btn" (click)="submitEntry()">
        <fa-icon [icon]="faPaperPlane" class="mr-1"></fa-icon>Submit
      </button>
    </div>
  
    <div class="content-container">
      <section class="content-container">
        <!-- Section A. SHOWROOM DUTY -->
        <h2 class="sub-heading w-full border-b">A. SHOWROOM DUTY</h2>
        <app-data-form
          [dataFields]="showroomDutyFormField"
          [dataName]="'showroomDutyFormField'"
        ></app-data-form>
  
        <!-- Leads -->
        <h3 class="mt-4">DO NOT FILLED UP NAMES WHO HAS PURCHASED.</h3>
        <app-data-form
          [dataFields]="showroomDutyTableFormField"
          [dataName]="'showroomDutyTableFormField'"
        ></app-data-form>
  
        <button class="shorter-btn misc-btn mt-2" (click)="addLead()">
          <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
        </button>
  
        <div class="content-container mt-3">
          <app-data-table
            [tableHeaders]="leadsTableHeaders"
            [tableEntries]="leadsTableEntries"
            (remove)="removeLead($event)"
          ></app-data-table>
        </div>

        <app-data-form [dataFields]="closingRatioFormField"></app-data-form>

  
        <!-- Section B. STANDBY DUTY -->
        <h2 class="sub-heading w-full border-b mt-4">B. STANDBY DUTY</h2>
  
        <!-- Prospecting -->
        <div class="mt-2">
          <h4>PROSPECTING</h4>
          <app-data-form
            [dataFields]="standbyProspectingFormField"
            [dataName]="'standbyProspectingFormField'"
          ></app-data-form>
        </div>
  
        <!-- Research -->
        <div class="mt-4">
          <h4>RESEARCH</h4>
          <app-data-form
            [dataFields]="standbyResearchFormField"
            [dataName]="'standbyResearchFormField'"
          ></app-data-form>
        </div>
        <!-- C. SPOTTERS & YOUR NETWORK -->
      <h2 class="sub-heading w-full border-b mt-4">C. SPOTTERS & YOUR NETWORK</h2>

      <!-- NEW SPOTTER'S NAMES -->
      <h3 class="mt-2">NEW SPOTTER'S NAMES</h3>
      <app-data-form
        [dataFields]="spotters1FormField"
        [dataName]="'spotters1FormField'"
      ></app-data-form>

      <button class="shorter-btn misc-btn mt-2" (click)="addSpotter1()">
        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
      </button>

      <div class="content-container mt-3">
        <app-data-table
          [tableHeaders]="spotters1TableHeaders"
          [tableEntries]="spotters1TableEntries"
          (remove)="removeSpotter1($event)"
        ></app-data-table>
      </div>

      <!-- REVISITED SPOTTER'S NAMES -->
      <h3 class="mt-4">REVISITED SPOTTER'S NAMES</h3>
      <app-data-form
        [dataFields]="spotters2FormField"
        [dataName]="'spotters2FormField'"
      ></app-data-form>

      <button class="shorter-btn misc-btn mt-2" (click)="addSpotter2()">
        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
      </button>

      <div class="content-container mt-3">
        <app-data-table
          [tableHeaders]="spotters2TableHeaders"
          [tableEntries]="spotters2TableEntries"
          (remove)="removeSpotter2($event)"
        ></app-data-table>
      </div>

      <!-- WHY NO BUSINESS FROM SPOTTER? -->
      <h3 class="mt-4">WHY NO BUSINESS FROM SPOTTER?</h3>
      <app-data-form
        [dataFields]="noBusinessSpotterForm"
        [dataName]="'noBusinessSpotterForm'"
      ></app-data-form>
      <!-- D. COMMERCIAL HOUSES -->
<h2 class="sub-heading w-full border-b mt-4">D. COMMERCIAL HOUSES</h2>
<app-data-form
  [dataFields]="commercialHousesFormField"
  [dataName]="'commercialHousesFormField'"
></app-data-form>

<!-- E. MARKET ASSESSMENT -->
<h2 class="sub-heading w-full border-b mt-4">E. MARKET ASSESSMENT</h2>
<app-data-form
  [dataFields]="marketAssessmentFormField"
  [dataName]="'marketAssessmentFormField'"
></app-data-form>

<!-- F. WHY NO CAT B CARS SALES -->
<h2 class="sub-heading w-full border-b mt-4">F. WHY NO CAT B CARS SALES</h2>
<app-data-form
  [dataFields]="whyNoCatBFormField"
  [dataName]="'whyNoCatBFormField'"
></app-data-form>

<!-- G. ANY OTHER COMMENTS OR SUGGESTIONS -->
<h2 class="sub-heading w-full border-b mt-4">G. ANY OTHER COMMENTS OR SUGGESTIONS</h2>
<app-data-form
  [dataFields]="commentsSuggestionsFormField"
  [dataName]="'commentsSuggestionsFormField'"
></app-data-form>

      </section>
    </div>
  </section>
  