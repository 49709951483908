import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { VehicleOverbookReportService } from "src/app/services/sales-reports/vehicle-overbook-report.service";

@Component({
  selector: "app-vehicle-overbook-report",
  templateUrl: "./vehicle-overbook-report.component.html",
  styleUrls: ["./vehicle-overbook-report.component.css"],
})
export class VehicleOverbookReportComponent implements OnInit {
  faPrint = faPrint;
  lockPrint: boolean = true;

  FormFields: FormField[] = [
    {
      type: "select",
      id: "Format",
      label: "Select format",
      options: [
        { display: "PDF", value: "pdf" },
        { display: "EXCEL", value: "excel" },
        { display: "BOTH", value: "both" },
      ],
      value: "pdf",
    },
  ];

  constructor(
    private vehicleOverbookReportService: VehicleOverbookReportService,
  ) {}

  ngOnInit() {}

  print(): void {
    const Format = this.getField("Format").value;

    this.vehicleOverbookReportService
      .VehicleOverbookReport()
      .subscribe({
        next: ({ body }) => {
          if (["pdf", "both"].includes(Format)) {
            window.open(body.Name, "_blank");
          }
          if (["excel", "both"].includes(Format)) {
            window.open(body.Value, "_blank");
          }
        },
        error: console.error,
      });
  }

  getField(id: string): FormField {
    return (
      this.FormFields.find((f) => f.id === id) || {
        id: "",
        type: "text",
        label: "",
        value: null,
      }
    );
  }
}
