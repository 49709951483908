<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Duty pullout list</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <app-data-form
      [dataFields]="searchCriteria"
      [dataName]="'searchCriteria'"
    />
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn primary-btn">
        <fa-icon [icon]="faMagnifyingGlass" class="mr-1" />Search by
        ETA date
      </button>
      <button class="shorter-btn primary-btn">
        <fa-icon [icon]="faMagnifyingGlass" class="mr-1" />Search by
        pullout list date
      </button>
      <button class="shorter-btn green-btn">
        <fa-icon [icon]="faSave" class="mr-1" />Update
      </button>
      <button class="shorter-btn alt-red-btn">
        <fa-icon [icon]="faPrint" class="mr-1" />Print
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </section>
  <section class="content-container">
    <app-data-table
      [tableHeaders]="tableHeaders"
      [tableEntries]="tableEntries"
    ></app-data-table>
  </section>
</div>
