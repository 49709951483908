import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencil, faBan, faUsers, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';
import { PriceListTemplateService } from 'src/app/services/sales/price-list-template.service';
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid, isEmptyGuid } from '../../../../services/util.service';

@Component({
  selector: 'app-price-list-template-detail',
  templateUrl: './price-list-template-detail.component.html',
  styleUrls: ['./price-list-template-detail.component.css']
})
export class PriceListTemplateDetailComponent implements OnInit {
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faUsers = faUsers;

  mode: string = "Add"; 
  templateId: string = getEmptyGuid(); 

  formFields: FormField[] = [
    { type: 'text', id: 'model', label: 'MODEL', value: '' },
    { type: 'number', id: 'engineCap', label: 'Eng. Cap', value: '' },
    { type: 'text', id: 'category', label: 'Category', value: '' },
    { type: 'number', id: 'sequence', label: 'Sequence', value: '' },
    { type: 'number', id: 'subSeq', label: 'Model Sub Seq', value: '' },
    { type: 'text', id: 'desc', label: 'Desc', value: '' },
    { type: 'switch', id: 'displayPassenger', label: 'Display in Print Passenger Price List report', value: false },
    { type: 'switch', id: 'displayCommercial', label: 'Display in Print Commercial Price List report', value: false },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private priceListTemplateService: PriceListTemplateService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.templateId = params['id'];

      if (!isEmptyGuid(this.templateId)) {
        this.mode = 'Edit';
        this.loadPriceListTemplateDetail();
      }
    });
  }

  private loadPriceListTemplateDetail(): void {
    this.priceListTemplateService.getAllPriceListTemplateDetails(this.templateId).subscribe({
      next: (response) => {
        const data = response.body;
        this.formFields = [
          { type: 'text', id: 'model', label: 'MODEL', value: data.MODEL },
          { type: 'number', id: 'engineCap', label: 'Eng. Cap', value: data.ENGINECAP },
          { type: 'text', id: 'category', label: 'Category', value: data.CATEGORY },
          { type: 'number', id: 'sequence', label: 'Sequence', value: data.SEQ },
          { type: 'number', id: 'subSeq', label: 'Model Sub Seq', value: data.SUBSEQ },
          { type: 'text', id: 'desc', label: 'Desc', value: data.StringDesc },
          { type: 'switch', id: 'displayPassenger', label: 'Display in Print Passenger Price List report', value: data.DisplayPassenger },
          { type: 'switch', id: 'displayCommercial', label: 'Display in Print Commercial Price List report', value: data.DisplayCommercial },
        ];
      },
      error: (error: any) => {
        console.error("Error loading price list template detail:", error);
        this.notifier.notify('error', 'Error loading price list template detail');
      }
    });
  }

  onSave(): void {
    if (this.mode === 'Add') {
      this.addPriceListTemplate();
    } else if (this.mode === 'Edit') {
      this.updatePriceListTemplate();
    }
  }

  private addPriceListTemplate(): void {
    const newTemplateData = {
      MODEL: this.getFieldValue('model'),
      ENGINECAP: this.getFieldValue('engineCap'),
      CATEGORY: this.getFieldValue('category'),
      SEQ: this.getFieldValue('sequence'),
      SUBSEQ: this.getFieldValue('subSeq'),
      StringDesc: this.getFieldValue('desc'),
      DisplayPassenger: this.getFieldValue('displayPassenger'),
      DisplayCommercial: this.getFieldValue('displayCommercial'),
    };
  
    this.priceListTemplateService.addPriceListTemplate({ model: newTemplateData }).subscribe({
      next: () => {
        this.notifier.notify('success', 'Price List Template added successfully');
        this.router.navigate(['../../price-list-template'], { relativeTo: this.route });
      },
      error: (error: any) => {
        console.error("Error adding price list template:", error);
        this.notifier.notify('error', 'Error adding price list template');
      }
    });
  }  

  private updatePriceListTemplate(): void {
    const updatedTemplateData = {
      ID: this.templateId,
      MODEL: this.getFieldValue('model'),
      ENGINECAP: this.getFieldValue('engineCap'),
      CATEGORY: this.getFieldValue('category'),
      SEQ: this.getFieldValue('sequence'),
      SUBSEQ: this.getFieldValue('subSeq'),
      StringDesc: this.getFieldValue('desc'),
      DisplayPassenger: this.getFieldValue('displayPassenger'),
      DisplayCommercial: this.getFieldValue('displayCommercial'),
    };

    this.priceListTemplateService.updatePriceListTemplate({ model: updatedTemplateData }).subscribe({
      next: () => {
        this.notifier.notify('success', 'Price List Template updated successfully');
        this.router.navigate(['../../price-list-template'], { relativeTo: this.route });
      },
      error: (error: any) => {
        console.error("Error updating price list template:", error);
        this.notifier.notify('error', 'Error updating price list template');
      }
    });
  }

  private getFieldValue(id: string): any {
    const field = this.formFields.find(f => f.id === id);
    return field?.value || (field?.type === 'number' ? null : '');
  }

  onCancel(): void {
    this.router.navigate(['../../price-list-template'], { relativeTo: this.route });
  }
}
