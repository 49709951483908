import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class BuyersOrderReportService {
  private readonly baseUrl: string = "SalesReportService.asmx";

  constructor(private http: HttpUtilService) {}

  /**
   * Fetches the preview of a buyer's order report.
   * @param payload The request body containing:
   *  - FromDate: Start date in "dd/MM/yyyy" format.
   *  - ToDate: End date in "dd/MM/yyyy" format.
   *  - ResultBy: Filter option (e.g., "All").
   *  - SortBy: Sorting criteria.
   *  - GroupBy: Grouping criteria.
   * @returns An Observable with the API response.
   */
  previewBuyerOrder(payload: {
    model: { FromDate: string; ToDate: string; ResultBy: string; SortBy: string; GroupBy: string };
  }): Observable<any> {
    return this.http.post(`${this.baseUrl}/PreviewBuyerOrder`, payload);
  }

  /**
   * Fetches the booking report.
   * @param payload The request body containing:
   *  - FromDate: Start date in "dd/MM/yyyy" format.
   *  - ToDate: End date in "dd/MM/yyyy" format.
   *  - ResultBy: Filter option (e.g., "All").
   *  - SortBy: Sorting criteria.
   *  - GroupBy: Grouping criteria.
   * @returns An Observable with the API response.
   */
  bookingReport(payload: {
    model: { FromDate: string; ToDate: string; ResultBy: string; SortBy: string; GroupBy: string };
  }): Observable<any> {
    return this.http.post(`${this.baseUrl}/BookingReport`, payload);
  }
}
