import { Component, OnInit } from "@angular/core";
import {
  faPencil,
  faPrint,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { ControlParametersService } from "src/app/services/sales/control-parameters.service";
import { FormField } from "../../../interfaces/form-field.model";
import { NotifierService } from "angular-notifier";
import {
  RoyaltyDiscountItem,
  category,
} from "src/app/models/sales/control-parameters";
import { formatDate } from "@angular/common";
import { getEmptyGuid } from "src/app/services/util.service";

@Component({
  selector: "app-control-parameters",
  templateUrl: "./control-parameters.component.html",
  styleUrls: ["./control-parameters.component.css"],
})
export class ControlParametersComponent implements OnInit {
  faIcons = { faPencil, faPrint, faUsers };
  monthAdjust: number = -1;
  VariableId: string = "";
  categories: category[] = [];
  RoyaltyDiscounts: RoyaltyDiscountItem[] = [];

  controlParametersDetail: FormField[] = [
    {
      type: "select",
      id: "Category",
      label: "Category",
      value: undefined,
    },
    {
      type: "number",
      id: "SpotterComm",
      label: "Maximum Spotter Commission",
      value: null,
    },
    {
      type: "number",
      id: "DepositAmount",
      label: "Minimum Deposit Amount",
      value: null,
    },
    {
      type: "number",
      id: "StkMasterMaxMonth",
      label: "Maximum ETA Month",
      value: null,
    },
    {
      type: "number",
      id: "StkMasterMinMonth",
      label: "Minimum ETA Month",
      value: null,
    },
    {
      type: "text",
      id: "SalesmanAcc",
      label: "Max Sales Consultant Accessory",
      value: "",
    },
    {
      type: "text",
      id: "ImmediateAdjustment",
      label: "Immediate Price Adjustment",
      value: "",
    },
    {
      type: "select",
      id: "BookingForImmediate",
      label: "Bookings For Immediate",
      value: undefined, //Se muestra como H pero el valor es B 😑
      options: [
        {
          display: "H",
          value: "B",
        },
        {
          display: "R",
          value: "R",
        },
      ],
    },
    {
      type: "select",
      id: "DisableBooking",
      label: "Disable Booking",
      value: undefined,
      options: [
        {
          display: "H",
          value: "B",
        },
        {
          display: "R",
          value: "R",
        },
      ],
    },
    {
      type: "text",
      id: "BlockTestDriveDayNumber",
      label: "Block Test Drive Days",
      value: "",
    },
    {
      type: "number",
      id: "BlockBookingUpdate",
      label: "Block Booking Days",
      value: null,
    },
    {
      type: "number",
      id: "monthAdjust",
      label: "Minimum Month Stock Summary Report",
      value: this.monthAdjust,
    },
    {
      type: "add",
      id: "RoyaltyDiscount",
      label: "Loyalty Discount",
      value: null,
    },
  ];
  testDriveReport: FormField[] = [
    {
      type: "date",
      id: "FromDate",
      label: "From Date (dd/mm/yyyy)",
      value: undefined,
      required: true,
    },
    {
      type: "date",
      id: "ToDate",
      label: "To Date (dd/mm/yyyy)",
      required: true,
      value: undefined,
    },
    {
      type: "select",
      id: "Format",
      label: "Select Format",
      value: "pdf",
      options: [
        { display: "PDF", value: "pdf" },
        { display: "EXCEL", value: "excel" },
        { display: "BOTH", value: "both" },
      ],
    },
  ];
  tableHeaders = [
    {
      title: "Price ($)",
      type: "data",
    },
    {
      title: "Action",
      type: "trash",
      Hclasses: "text-center",
    },
    {
      title: "",
      type: "hidden",
    },
  ];

  constructor(
    private notifier: NotifierService,
    private controlParametersService: ControlParametersService,
  ) {}

  ngOnInit(): void {
    this.loadCategories();
    this.loadRoyaltyDiscounts();
    this.loadControlParameters();
  }

  loadCategories() {
    this.controlParametersService.GetCategory().subscribe({
      next: (response) => {
        this.categories = response.body;
        this.getField("Category", "controlParametersDetail").options =
          response.body.map((item: any) => ({
            display: item.Category,
            value: item.Category,
          }));
      },
      error: console.error,
    });
  }

  loadRoyaltyDiscounts() {
    this.controlParametersService.GetRoyaltyDiscount().subscribe({
      next: (response) => {
        this.RoyaltyDiscounts = response.body;
      },
      error: console.error,
    });
  }

  loadControlParameters() {
    this.controlParametersService.GetAll().subscribe({
      next: (response) => {
        this.VariableId = response.body.VariableId;
        this.controlParametersDetail.forEach((field) => {
          if (
            response.body[field.id] !== undefined &&
            field.id !== "RoyaltyDiscount"
          ) {
            field.value = response.body[field.id];
          }
        });
      },
      error: console.error,
    });
  }

  processRoyaltyDiscount(
    Entries: RoyaltyDiscountItem[],
  ): { price: number; action: string }[] {
    return Entries.map((item) => ({
      price: item.Discount,
      action: "",
      id: item.Id,
    }));
  }

  updateFields(event: any) {
    if (event.updatedValue === 0) {
      const newCategory = this.categories.find(
        (item) =>
          item.Category ===
          this.getField("Category", "controlParametersDetail").value,
      );
      if (newCategory) {
        this.getField(
          "SpotterComm",
          "controlParametersDetail",
        ).value = newCategory.SpotterComm;
        this.getField(
          "DepositAmount",
          "controlParametersDetail",
        ).value = newCategory.DepositAmount;
      }
    }
  }

  addEntry(event: any): void {
    const discount = this.getField(
      event.fieldId,
      "controlParametersDetail",
    ).value;

    this.controlParametersService
      .AddRoyaltyDiscount(discount, getEmptyGuid())
      .subscribe({
        next: ({ body }) => {
          this.RoyaltyDiscounts.push(body);
          this.notifier.notify("success", "Discount added");
        },
        error: console.error,
      });
  }

  removeEntry(event: any): void {
    this.controlParametersService
      .DeleteRoyaltyDiscount(event.id)
      .subscribe({
        next: () => {
          this.RoyaltyDiscounts = this.RoyaltyDiscounts.filter(
            (discount) => discount.Id !== event.id,
          );
          this.notifier.notify("success", "Discount removed");
        },
        error: console.error,
      });
  }

  update() {
    const model = this.controlParametersDetail.reduce(
      (acc: any, field) => {
        if (field.id !== "RoyaltyDiscount")
          acc[field.id] = field.value;
        return acc;
      },
      {
        VariableId: this.VariableId,
        __type: "KAPS.Model.CrmVariables",
      },
    );

    this.controlParametersService.Update(model).subscribe({
      next: () =>
        this.notifier.notify(
          "success",
          "Control Parameters updated successfully",
        ),
      error: console.error,
    });
  }

  printSummary(): void {
    const monthAdjust = {
      Value: this.getField("monthAdjust", "controlParametersDetail")
        .value,
      Name: "monthAdjust",
    };
    this.controlParametersService
      .PrintStockSummary(monthAdjust)
      .subscribe({
        next: (response) => window.open(response.body.Name, "_blank"),
        error: console.error,
      });
  }

  printDriveReport() {
    const fromDate = this.getField(
      "FromDate",
      "testDriveReport",
    ).value;
    const toDate = this.getField("ToDate", "testDriveReport").value;
    if (fromDate && toDate) {
      const model = {
        FromDate: formatDate(fromDate, "dd/MM/yyyy", "en-US"),
        ToDate: formatDate(toDate, "dd/MM/yyyy", "en-US"),
      };
      if (model.FromDate < model.ToDate) {
        this.controlParametersService
          .TestDriveReport(model)
          .subscribe({
            next: (response) =>
              this.handleDriveReportResponse(response),
            error: console.error,
          });
      } else {
        this.notifier.notify(
          "warning",
          "ToDate is less than FromDate",
        );
      }
    } else {
      this.notifier.notify("warning", "Please specify the dates");
    }
  }

  handleDriveReportResponse(response: any) {
    const mode = this.getField("Format", "testDriveReport").value;
    switch (mode) {
      case "pdf":
        window.open(response.body.Name, "_blank");
        break;
      case "excel":
        window.open(response.body.Value, "_blank");
        break;
      case "both":
        window.open(response.body.Value, "_blank");
        window.open(response.body.Name, "_blank");
        break;
    }
  }

  tradeNetIntegration(): void {
    this.controlParametersService.TradeNetIntegration().subscribe({
      next: (response) => {
        if (response.body.data == "0") {
          this.notifier.notify("success", "TradeNet sync success");
        } else {
          this.notifier.notify("error", "TradeNet sync fail");
        }
      },
      error: console.error,
    });
  }

  getField(id: string, form: string): FormField {
    const forms: { [key: string]: FormField[] } = {
      controlParametersDetail: this.controlParametersDetail,
      testDriveReport: this.testDriveReport,
    };
    const formData = forms[form] || [];
    return (
      formData.find((f) => f.id === id) || {
        id: "",
        type: "text",
        label: "",
        value: undefined,
      }
    );
  }
}
