import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-select-multiple-input",
  templateUrl: "./select-multiple-input.component.html",
  styleUrls: ["./select-multiple-input.component.css"],
})
export class SelectMultipleInputComponent implements OnInit {
  @Input() options: string[] = [];
  @Input() selectedOptions: string[] = [];
  @Output() dataOutput = new EventEmitter<string[]>();
  searchText: string = "";

  constructor() {}

  ngOnInit() {}

  onSelectionChange(event: any) {
    this.dataOutput.emit(this.selectedOptions);
  }
}
