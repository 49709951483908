import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserRightsManagementService {
  private apiUrl = "http://localhost:53719"; 

  constructor(private http: HttpClient) {}

  /**
   * Retrieves all active users.
   * @returns An Observable with the active users' data.
   */
  getAllActiveUsers(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.get<any>(
      `${this.apiUrl}/Services/KapsUserService.asmx/GetAllActiveUsers`,
      { headers: headers, withCredentials: true },
    );
  }

  /**
   * Retrieves all screens.
   * @returns An Observable with the screens' data.
   */
  getAllScreens(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.get<any>(
      `${this.apiUrl}/Services/KapsUserService.asmx/GetAllScreens`,
      { headers: headers, withCredentials: true },
    );
  }

  /**
   * Retrieves user rights by user ID.
   * @param userId The ID of the user.
   * @returns An Observable with the user's rights data.
   */
  getUserRightsById(userId: string): Observable<any> {
    const encodedUserId = `%22${encodeURIComponent(userId)}%22`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.get<any>(
      `${this.apiUrl}/Services/KapsUserService.asmx/GetUserRightsById?id=${encodedUserId}`,
      { headers: headers, withCredentials: true },
    );
  }
  
  /**
   * Retrieves user rights by user ID and screen ID.
   * @param userId The ID of the user.
   * @param screenId The ID of the screen.
   * @returns An Observable with the user's rights data for the specified screen.
   */
  getUserRightsByIdAndScreen(userId: string, screenId: number): Observable<any> {
    const encodedUserId = `%22${encodeURIComponent(userId)}%22`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.get<any>(
      `${this.apiUrl}/Services/KapsUserService.asmx/GetUserRightsByIdAndScreen?id=${encodedUserId}&screenId=${screenId}`,
      { headers: headers, withCredentials: true },
    );
  }

  /**
   * Updates user rights.
   * @param model The user rights data to update.
   * @returns An Observable with the result of the update operation.
   */
  updateUserRights(model: object): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<any>(
      `${this.apiUrl}/Services/KapsUserService.asmx/UpdateUserRights`,
      model, 
      { headers: headers, withCredentials: true },
    );
  }  
}
