<div class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Buyer's Order Report</h1>
      <app-navpath />
    </div>
  
    <div class="content-container">
      <app-data-form [dataFields]="formFields" />
      <div class="mt-2 flex gap-x-1">
        <button class="shorter-btn delete-btn" (click)="preview()">
          <fa-icon [icon]="faEye" class="mr-1" />Preview
        </button>
        <button class="shorter-btn misc-btn" (click)="export()">
          <fa-icon [icon]="faPrint" class="mr-1" />Export
        </button>
      </div>
    </div>
  
    <div class="content-container" *ngIf="showTable">
      <div class="w-full overflow-x-auto">
        <table class="w-full min-w-[1200px] max-w-full text-sm">
          <thead>
            <tr class="table-header">
              <th *ngFor="let header of tableHeaders">
                {{ header.title }}
              </th>
            </tr>
          </thead>
          <tbody *ngFor="let group of tableEntries">
            <!-- Grouping row -->
            <tr class="bg-blue-100 font-bold">
              <td colspan="19">
                {{ group.ModelGroup || "Total" }}: ({{ group.TotalRecords }}) records
              </td>
            </tr>
    
            <!-- Data rows -->
            <tr class="table-row" *ngFor="let entry of group.Entries">
              <td class="table-entry" *ngFor="let header of tableHeaders">
                <ng-container *ngIf="header.type === 'link'">
                  <p class="link">{{ entry[header.id] }}</p>
                </ng-container>
                <ng-container *ngIf="header.type === 'data'">
                  {{ entry[header.id] || "" }}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
     
  </div>
  