<section class="page-container">
    <div class="flex items-center justify-between">
        <h1 class="main-heading">Accessory Report</h1>
        <app-navpath />
    </div>
    <div class="content-container">
        <section class="content-container">
            <app-data-form
                [dataFields]="reportFields"
                [dataName]="'reportFields'"
            />
        </section>
    </div>
    <div class="mb-2 flex w-fit gap-1">
        <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faPrint" class="mr-1" />Export
        </button>
        <button class="shorter-btn gray-btn">
            <fa-icon [icon]="faEraser" class="mr-1" />Clear
        </button>
    </div>
</section>
