<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      Prospect - <span>{{ mode }}</span>
    </h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button
      class="shorter-btn save-btn"
      (click)="updateProspect()"
      *ngIf="mode == 'Detail'"
    >
      <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Update
    </button>
    <button
      class="shorter-btn add-btn"
      (click)="addProspect()"
      *ngIf="mode == 'Add'"
    >
      <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Add
    </button>
    <button
      class="shorter-btn clear-btn"
      routerLink="../../../customer/prospect"
    >
      <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Cancel
    </button>
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="prospect"
      dataName="formFields"
      (updateFields_child)="updateFields($event)"
    />
    <div class="my-8 flex flex-col gap-8 px-8 text-sm">
      <p>
        I hereby consent to Komoco Motors Pte Ltd and/or its partners
        using my personal informaton in accordance with their privacy
        policy, and to send communications to me relating to
        marketing, advertising, event and promotional information on
        sales, After Sales, Products, Services and Merchandise that
        Komoco Motors Pte Ltd and/or its partners may be selling,
        marketing, offering or promoting whether such products or
        services exist now or are created in future through:
      </p>
      <div class="grid grid-cols-5">
        <label>
          <input
            type="checkbox"
            name=""
            id=""
            class="checkbox-single mr-2"
            [(ngModel)]="preferences.IsAgreeDirectMail"
            (change)="checkSelected()"
          />
          Direct mailer
        </label>
        <label>
          <input
            type="checkbox"
            name=""
            id=""
            class="checkbox-single mr-2"
            [(ngModel)]="preferences.IsAgreeEMail"
            (change)="checkSelected()"
          />
          Mail
        </label>
        <label>
          <input
            type="checkbox"
            name=""
            id=""
            class="checkbox-single mr-2"
            [(ngModel)]="preferences.IsAgreeSMS"
            (change)="checkSelected()"
          />
          SMS/MMS
        </label>
        <label>
          <input
            type="checkbox"
            name=""
            id=""
            class="checkbox-single mr-2"
            [(ngModel)]="preferences.IsAgreePhone"
            (change)="checkSelected()"
          />
          Phone call
        </label>
        <label>
          <input
            type="checkbox"
            name=""
            id=""
            class="checkbox-single mr-2"
            [(ngModel)]="preferencesAll"
            (change)="toggleAll($event)"
          />
          All
        </label>
      </div>
      <p class="font-bold">
        (Please mark your preferred channel of communication. You may
        mark more than one channel of communication. A copy of our
        privacy policy at:
        <a
          href="https://www.hyundai.com/sg/#/privacy/"
          class="text-blue-darker underline hover:text-blue-lighter hover:no-underline"
          >https://www.hyundai.com/sg/#/privacy/</a
        >.)
      </p>
      <p class="font-bold">
        Alternatively,
        <label class="ml-2 inline-block font-normal"
          ><input
            type="checkbox"
            name=""
            id=""
            class="checkbox-single mr-2"
            [(ngModel)]="preferences.DoNotReceive"
            (change)="toggleNoCommunication($event)"
          />
          I do not wish to receive any communication from Komoco
          Motors Pte Ltd and/or its partners.</label
        >
      </p>
      <p>
        By signing below, I represent and warrant that the contact
        information provided above is valid, correct and I am the
        ocupant, user and/or subscriber. I consent to Komoco Motors
        Pte Ltd and/or its partners using the above personal
        information for their recording, research, marketing and
        promotional purposes.
      </p>
    </div>
  </div>
</section>
