import { Component, OnInit } from "@angular/core";
import { faSave, faBroom } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-reinstate-booking",
  templateUrl: "./reinstate-booking.component.html",
  styleUrls: ["./reinstate-booking.component.css"],
})
export class ReinstateBookingComponent implements OnInit {

  searchBooking: FormField[] = [
    {
      type: "search",
      id: "searchOrderNumber",
      label: "Buyer's order number",
    },
    {
      type: "text",
      id: "searchBuyersDate",
      label: "Buyer's order date",
      lock: true,
    },
    {
      type: "text",
      id: "searchCancelDate",
      label: "Cancel date date (?)",
      lock: true,
    },
    {
      type: "radio",
      id: "searchLocation",
      options: [
        {
          display: "Local",
          value: "local",
        },
        {
          display: "Foraigner",
          value: "foraigner",
        },
        {
          display: "Company",
          value: "company",
        },
      ],
    },
  ];
  buyersDetails!: FormField[];
  detailsVehicle!: FormField[];
  detailsCashDiscount!: FormField[];
  detailsDeposit!: FormField[];
  detailsOtherFees!: FormField[];
  detailsInsurance!: FormField[];
  detailsHireApplication!: FormField[];
  detailsTradeInVehicle!: FormField[];
  komocosAccount_Promotion!: FormField[];
  komocosAccount_Accessories!: FormField[];
  buyersAccount_NonAccessories!: FormField[];
  buyersAccount_Accessories!: FormField[];
  consultantAccount_NonAccessories!: FormField[];
  consultantAccount_Accessories!: FormField[];
  additionalRemarks!: FormField[];

  // ICONS VARIABLES

  faSave = faSave;
  faBroom = faBroom;

  updateFields(event: any): void {
    switch (event.name) {
      case "searchBooking":
        this.searchBooking = event.fields;
        break;
      case "buyersDetails":
        this.buyersDetails = event.fields;
        break;
      case "detailsVehicle":
        this.detailsVehicle = event.fields;
        break;
      case "detailsCashDiscount":
        this.detailsCashDiscount = event.fields;
        break;
      case "detailsDeposit":
        this.detailsDeposit = event.fields;
        let local_PaymentType = this.detailsDeposit.find(
          (detail) => detail.id == "paymentType",
        )?.value;
        for (let i = 3; i <= 8; i++) {
          this.detailsDeposit[i].show = false;
        }
        switch (local_PaymentType) {
          case "CHEQUE":
            this.detailsDeposit[3].show = true;
            this.detailsDeposit[4].show = true;
            break;
          case "NETS":
          case "VISA/MASTER":
          case "AMEX":
            this.detailsDeposit[5].show = true;
            this.detailsDeposit[6].show = true;
            this.detailsDeposit[7].show = true;
            break;
          case "OTHERS":
            this.detailsDeposit[8].show = true;
            break;
        }
        break;
      case "detailsOtherFees":
        this.detailsOtherFees = event.fields;
        break;
      case "detailsInsurance":
        this.detailsInsurance = event.fields;
        break;
      case "detailsHireApplication":
        this.detailsHireApplication = event.fields;
        break;
      case "detailsTradeInVehicle":
        this.detailsTradeInVehicle = event.fields;
        let local_DetailsTradeInVehicle =
          this.detailsTradeInVehicle.find(
            (detail) => detail.id == "dealerName",
          )?.value;
        for (let i = 1; i <= 6; i++) {
          this.detailsTradeInVehicle[i].show = false;
        }
        switch (local_DetailsTradeInVehicle) {
          case "BOEKI AUTO & MARINE PTE LTD":
          case "KMPL USED CAR DIVISION":
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = true;
            }
            break;
          default:
            for (let i = 1; i <= 6; i++) {
              this.detailsTradeInVehicle[i].show = false;
            }
        }
        break;
      case "komocosAccount_Promotion":
        this.komocosAccount_Promotion = event.fields;
        break;
      case "komocosAccount_Accessories":
        this.komocosAccount_Accessories = event.fields;
        break;
      case "buyersAccount_NonAccessories":
        this.buyersAccount_NonAccessories = event.fields;
        break;
      case "buyersAccount_Accessories":
        this.buyersAccount_Accessories = event.fields;
        break;
      case "consultantAccount_NonAccessories":
        this.consultantAccount_NonAccessories = event.fields;
        break;
      case "consultantAccount_Accessories":
        this.consultantAccount_Accessories = event.fields;
        break;
      case "additionalRemarks":
        this.additionalRemarks = event.fields;
        break;
    }
  }

  ngOnInit() {
    this.buyersDetails = [
      {
        type: "text",
        id: "nricNo",
        label: "NRIC No/RCB No/Passport No",
        required: true,
      },
      {
        type: "select",
        id: "title",
        label: "Title",
        required: true,
        options: [
          { display: "MR", value: "MR" },
          { display: "MRS", value: "MRS" },
          { display: "MISS", value: "MISS" },
          { display: "M/S", value: "M/S" },
          { display: "MDM", value: "MDM" },
          { display: "DR", value: "DR" },
          { display: "MS", value: "MS" },
        ],
      },
      {
        type: "text",
        id: "nameNric",
        label: "Name as in NRIC/Passport",
        required: true,
      },
      {
        type: "text",
        id: "mobileNumber",
        label: "Mobile number",
        required: true,
      },
      { type: "text", id: "telNumber", label: "Home tel number" },
      {
        type: "text",
        id: "officeNumber",
        label: "Office Tel Number",
      },
      {
        type: "text",
        id: "blockHouse",
        label: "Block/House",
        required: true,
      },
      {
        type: "text",
        id: "road",
        label: "Road",
        required: true,
      },
      { type: "text", id: "unit", label: "Unit" },
      {
        type: "search",
        id: "postalCode",
        label: "Postal Code",
        required: true,
        options: [
          {
            display: "uno",
            value: "uno",
          },
          {
            display: "dos",
            value: "dos",
          },
          {
            display: "tres",
            value: "tres",
          },
          {
            display: "cuatro",
            value: "cuatro",
          },
          {
            display: "cinco",
            value: "cinco",
          },
        ],
      },
      {
        type: "text",
        id: "email",
        label: "Email",
        required: true,
      },
      {
        type: "date",
        id: "dateBirth",
        label: "Date of birth (dd/mm/yyyy)",
        required: true,
      },
      {
        type: "text",
        id: "occupation",
        label: "Occupation",
        required: true,
      },
      {
        type: "select",
        id: "modeContact",
        label: "Mode of contact",
        options: [
          {
            display: "Referral",
            value: "REFERRAL",
          },
          {
            display: "Repeat",
            value: "REPEAT",
          },
          {
            display: "Walk-in",
            value: "WALK-IN",
          },
          {
            display: "Postale",
            value: "POSTALE",
          },
        ],
      },
      {
        type: "checkbox",
        id: "modeConsent",
        label: "Mode of consent",
        options: [
          {
            display: "Direct mailer",
            value: "directMailer",
          },
          {
            display: "Mail",
            value: "mail",
          },
          {
            display: "SMS/MMS",
            value: "smsMms",
          },
          {
            display: "Phone call",
            value: "phoneCall",
          },
        ],
      },
      {
        type: "formCheckbox",
        id: "declarationCommunication",
        label:
          "I do not wish to receive any communication from Komoco Motors Pte Ltd and/or its partners.",
      },
    ];
    this.detailsVehicle = [
      {
        type: "select",
        id: "model",
        label: "Model",
        options: [
          {
            display: "IONIQ 6",
            value: "ioniq 6",
          },
          {
            display: "KONA ELECTRIC",
            value: "kona electric",
          },
          {
            display: "AVANTE",
            value: "avante",
          },
        ],
      },
      {
        type: "select",
        id: "colour",
        label: "Colour",
        options: [
          { display: "RED", value: "red" },
          { display: "BLUE", value: "blue" },
          { display: "YELLOW", value: "yellow" },
        ],
      },
      {
        type: "select",
        id: "interiorColour",
        label: "Interior colour",
        options: [
          { display: "RED", value: "red" },
          { display: "BLUE", value: "blue" },
          { display: "YELLOW", value: "yellow" },
        ],
      },
      {
        type: "select",
        id: "carType",
        label: "Car type",
        options: [
          { display: "Stantard", value: "stardar" },
          { display: "Automatic", value: "automatic" },
        ],
      },
      {
        type: "select",
        id: "COEType",
        label: "COE type",
        options: [
          { display: "Bidding", value: "bidding" },
          { display: "Own COE", value: "ownCOE" },
        ],
      },
      {
        type: "text",
        id: "category",
        label: "Category",
        lock: true,
      },
      {
        type: "number",
        id: "invoicePrice",
        label: "Invoice price ($)",
      },
      {
        type: "number",
        id: "nettSellingPrice",
        label: "Nett Selling Price ($)",
      },
      {
        type: "select",
        id: "expectedDeliveryDate",
        label: "Expected delivery date",
        options: [
          { display: "Tomorrow", value: "tomorrow" },
          { display: "Next week", value: "nextWeek" },
        ],
      },
      {
        type: "const",
        id: "roadTaxPeriod",
        label: "Road tax period",
        value: 6,
      },
    ];
    this.detailsCashDiscount = [
      {
        type: "number",
        id: "cashDiscount",
        label: "Cash discount $",
      },
      {
        type: "select",
        id: "loyaltyDiscount",
        label: "Loyalty discount $",
        options: [
          { display: "500", value: "red" },
          { display: "700", value: "700" },
          { display: "1000", value: "1000" },
        ],
      },
    ];
    this.detailsDeposit = [
      {
        type: "select",
        id: "paymentType",
        label: "Payment type",
        options: [
          {
            display: "No deposit",
            value: "NO DEPOSIT",
          },
          {
            display: "Cash",
            value: "CASH",
          },
          {
            display: "Cheque",
            value: "CHEQUE",
          },
          {
            display: "Nets",
            value: "NETS",
          },
          {
            display: "VISA/MASTER",
            value: "VISA/MASTER",
          },
          {
            display: "AMEX",
            value: "AMEX",
          },
          {
            display: "Others",
            value: "OTHERS",
          },
        ],
      },
      {
        type: "number",
        id: "amount",
        label: "Amount $",
      },
      {
        type: "date",
        id: "depositDate",
        label: "Deposit date",
      },
      {
        type: "select",
        id: "bank",
        label: "Bank",
        show: false,
        options: [
          {
            display: "DBS",
            value: "DBS",
          },
          {
            display: "UOB",
            value: "UOB",
          },
          {
            display: "CITIBANK",
            value: "CITIBANK",
          },
        ],
      },
      {
        type: "number",
        id: "chequeNumber",
        label: "Cheque number",
        show: false,
      },
      {
        type: "select",
        id: "terminalNumber",
        label: "Terminal number",
        show: false,
      },
      {
        type: "number",
        id: "batchNumber",
        label: "Batch number",
        show: false,
      },
      {
        type: "number",
        id: "invoiceNumber",
        label: "Invoice number",
        show: false,
      },
      {
        type: "text",
        id: "receiptNumber",
        label: "Receipt number",
        show: false,
      },
    ];
    this.detailsOtherFees = [
      {
        type: "number",
        id: "COEOffset",
        label: "COE Offset (rebate level)",
      },
      {
        type: "number",
        id: "COETopUp",
        label: "COE top up",
      },
      {
        type: "number",
        id: "SpottersCommision",
        label: "Spotter's commision",
      },
      {
        type: "number",
        id: "VESSurchargeRebate",
        label: "VES surcharge/rebate",
      },
    ];
    this.detailsInsurance = [
      {
        type: "select",
        id: "InsuranceCompany",
        label: "Insurance Company",
        options: [
          {
            display: "AIG OTHERS",
            value: "AIG OTHERS",
          },
          {
            display: "AIG IN-HOUSE",
            value: "AIG IN-HOUSE",
          },
          {
            display: "AXA INSURANCE OTHERS",
            value: "AXA INSURANCE OTHERS",
          },
          {
            display: "AXA INSURANCE IN-HOUSE",
            value: "AXA INSURANCE IN-HOUSE",
          },
          {
            display: "OWN INSURANCE",
            value: "OWN INSURANCE",
          },
        ],
      },
    ];
    this.detailsHireApplication = [
      {
        type: "select",
        id: "FinanceCompany",
        label: "Finance company",
        options: [
          {
            display: "No Finance",
            value: "No Finance",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_MA",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSCA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA2",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSMA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA1",
          },
          {
            display: "GENIE FINANCIAL SERVICES PTE LTD",
            value: "GFSPL",
          },
          {
            display: "GOLDBELL FINANCIAL SERVICES PTE LTD",
            value: "GFS",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA_NF",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA",
          },
          {
            display: "HL BANK",
            value: "HLB_OC",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL_OC",
          },
        ],
      },
      {
        type: "formCheckbox",
        id: "fp",
        label: "FP",
      },
      {
        type: "select",
        id: "LoanPeriodMonths",
        label: "Loan period (months)",
        options: [
          {
            display: "0",
            value: 0,
          },
          {
            display: "12",
            value: 12,
          },
          {
            display: "24",
            value: 24,
          },
          {
            display: "48",
            value: 48,
          },
          {
            display: "60",
            value: 60,
          },
        ],
      },
      {
        type: "number",
        id: "LoanAmount",
        label: "Loan amount",
      },
      {
        type: "number",
        id: "LoanRate",
        label: "Loan rate",
      },
      {
        type: "text",
        id: "MonthlyInstallment",
        label: "Monthly Installment ($)",
        lock: true,
      },
      {
        type: "select",
        id: "FinanceCompany2",
        label: "Finance company 2",
        lock: true,
        options: [
          {
            display: "No Finance",
            value: "No Finance",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_MA",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSCA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA2",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSMA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA1",
          },
          {
            display: "GENIE FINANCIAL SERVICES PTE LTD",
            value: "GFSPL",
          },
          {
            display: "GOLDBELL FINANCIAL SERVICES PTE LTD",
            value: "GFS",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA_NF",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA",
          },
          {
            display: "HL BANK",
            value: "HLB_OC",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL_OC",
          },
        ],
      },
      {
        type: "select",
        id: "FinanceCompany3",
        label: "Finance company 3",
        lock: true,
        options: [
          {
            display: "No Finance",
            value: "No Finance",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_MA",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSCA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA2",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSMA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA1",
          },
          {
            display: "GENIE FINANCIAL SERVICES PTE LTD",
            value: "GFSPL",
          },
          {
            display: "GOLDBELL FINANCIAL SERVICES PTE LTD",
            value: "GFS",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA_NF",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA",
          },
          {
            display: "HL BANK",
            value: "HLB_OC",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL_OC",
          },
        ],
      },
      {
        type: "select",
        id: "FinanceCompany4",
        label: "Finance company 4",
        lock: true,
        options: [
          {
            display: "No Finance",
            value: "No Finance",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_MA",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSCA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA2",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSMA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA1",
          },
          {
            display: "GENIE FINANCIAL SERVICES PTE LTD",
            value: "GFSPL",
          },
          {
            display: "GOLDBELL FINANCIAL SERVICES PTE LTD",
            value: "GFS",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA_NF",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA",
          },
          {
            display: "HL BANK",
            value: "HLB_OC",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL_OC",
          },
        ],
      },
      {
        type: "text",
        id: "LoanStatus",
        label: "Loan status",
      },
      {
        type: "date",
        id: "LoanSubmittedDate",
        label: "Loan submitted date",
      },
      {
        type: "date",
        id: "LoanStatusDate",
        label: "Loan status date",
      },
    ];
    this.detailsTradeInVehicle = [
      {
        type: "select",
        id: "dealerName",
        label: "Dealer name",
        options: [
          {
            display: "No trade in",
            value: "No trade in",
          },
          {
            display: "BOEKI AUTO & MARINE PTE LTD",
            value: "BOEKI AUTO & MARINE PTE LTD",
          },
          {
            display: "KMPL USED CAR DIVISION",
            value: "KMPL USED CAR DIVISION",
          },
        ],
      },
      {
        type: "text",
        id: "make",
        label: "Make",
        required: true,
        show: false,
      },
      {
        type: "number",
        id: "manufactureYear",
        label: "Manufacture year",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "vehicleNumber",
        label: "Vehicle number",
        required: true,
        show: false,
      },
      {
        type: "text",
        id: "model",
        label: "Model",
        required: true,
        show: false,
      },
      {
        type: "number",
        id: "komocoOvertradeSubsidy",
        label: "Komoco overtrade subsidy ($)",
        required: true,
        show: false,
      },
      {
        type: "number",
        id: "totalPurchasePrice",
        label: "Toal purchase price of used vehicle",
        required: true,
        show: false,
      },
    ];
    this.komocosAccount_Promotion = [
      {
        type: "add",
        id: "insurancePremium",
        label: "Insurance premium ($)",
      },
      {
        type: "formCheckbox",
        id: "6MonthsTaxRoad",
        label: "Additional 6 months tax road",
        lock: true,
      },
      {
        type: "add",
        id: "price",
        label: "Price ($)",
      },
      {
        type: "select",
        id: "promotionFreeItems",
        label: "Promotion (free items)",
      },
    ];
    this.komocosAccount_Accessories = [
      {
        type: "select",
        id: "accessory",
        label: "Accessory",
      },
      {
        type: "number",
        id: "priceNumber",
        label: "Price ($)",
      },
      {
        type: "add",
        id: "priceAdd",
        label: "Price ($)",
      },
      {
        type: "const",
        id: "totalAmount",
        label: "Total amount in Komoco's account ($)",
        value: 0,
      },
    ];
    this.buyersAccount_NonAccessories = [
      {
        type: "add",
        id: "insurancePremium",
        label: "Insurance premium ($)",
      },
      {
        type: "add",
        id: "adminFee",
        label: "Admin fee for overtrade ($)",
      },
      {
        type: "formCheckbox",
        id: "roadTax",
        label: "Additional 6 Months Road Tax",
      },
    ];
    this.buyersAccount_Accessories = [
      {
        type: "select",
        id: "accessory",
        label: "Accessory",
      },
      {
        type: "add",
        id: "price",
        label: "Price ($)",
      },
      {
        type: "const",
        id: "totalAmount",
        label: "Total Amount In Buyer's Account ($)",
        value: 0,
      },
    ];
    this.consultantAccount_NonAccessories = [
      {
        type: "add",
        id: "insurancePremium",
        label: "Insurance premium ($)",
      },
      {
        type: "formCheckbox",
        id: "roadTax",
        label: "Additional 6 months road tax",
      },
      {
        type: "add",
        id: "feeOvertrade",
        label: "Admin fee for overtrade ($)",
      },
      {
        type: "add",
        id: "COETopUp",
        label: "COE top up ($)",
      },
    ];
    this.consultantAccount_Accessories = [
      {
        type: "select",
        id: "accessory",
        label: "Accessory",
      },
      {
        type: "add",
        id: "price",
        label: "Price ($)",
      },
      {
        type: "const",
        id: "salesmanAccount",
        label: "Total amount in salesman's account ($)",
        value: 0,
      },
    ];
    this.additionalRemarks = [
      {
        type: "textarea",
        id: "remarks",
        label: "Remarks",
      },
      {
        type: "select",
        id: "salesConsultant",
        label: "Sales consultant",
        options: [
          {
            display: "ABW",
            value: "ABW",
          },
          {
            display: "ACC",
            value: "ACC",
          },
          {
            display: "ALPRO",
            value: "ALPRO",
          },
        ],
      },
    ];
  }
}
