<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">COE bidding results</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <h2 class="sub-heading w-full border-b">Upload COEs list</h2>
    <div class="flex">
      <app-data-form
        [dataFields]="uploadCOE"
        [dataName]="'uploadCOE'"
        (updateFields_child)="updateFields($event)"
        class="flex-1"
      />
      <div class="mt-2 flex w-fit gap-1">
        <button class="shorter-btn green-btn">
          <fa-icon [icon]="faUpload" class="mr-1" />Upload and show
          list
        </button>
        <button class="shorter-btn gray-btn">
          <fa-icon [icon]="faBroom" class="mr-1" />Clear
        </button>
      </div>
    </div>
  </section>
  <section class="content-container">
    <h2 class="sub-heading w-full border-b">Select COEs from list</h2>
    <app-data-form
      [dataFields]="selectCOE"
      [dataName]="'selectCOE'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
</div>
