import { Component, OnInit } from '@angular/core';
import { ShipmentMasterService } from 'src/app/services/sales/shipment-master.service';
import { NotifierService } from 'angular-notifier';
import { FormField } from 'src/app/interfaces/form-field.model';
import { faEraser, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-shipment-master',
  templateUrl: './shipment-master.component.html',
  styleUrls: ['./shipment-master.component.css']
})
export class ShipmentMasterComponent implements OnInit {

  faPlus = faPlus;
  faEraser = faEraser;
  faSave = faSave;

  isEditingShipment: boolean = false; 
  shipmentEditFormFields: FormField[] = []; 

  formFields: FormField[] = [];
  modelOptions: any[] = [{ value: '', display: 'Please select' }];
  exteriorColourOptions: any[] = [{ value: '', display: 'Please select' }];
  interiorColourOptions: any[] = [{ value: '', display: 'Please select' }];

  shipmentDetails: any;

  tableHeaders = [
    { title: 'ENTRY DATE', type: 'data', id: 'entryDate' },
    { title: 'ARRIVAL DATE', type: 'data', id: 'arrivalDate' },
    { title: 'MODEL', type: 'data', id: 'modelDescription' },
    { title: 'MODEL CODE', type: 'data', id: 'modelCode' },
    { title: 'OCC NUMBER', type: 'data', id: 'occNumber' },
    { title: 'COLOR CODE', type: 'data', id: 'exteriorColourCode' },
    { title: 'TYPE', type: 'data', id: 'type' },
    { title: 'INTERIOR CODE', type: 'data', id: 'interiorColourCode' },
    { title: 'QUANTITY', type: 'data', id: 'quantity' }, 
    { title: 'ACTION', type: 'trash', id: 'trash' },
  ];

  tableEntries: any[] = [];

  shipmentHistoryTableHeaders = [
    { title: "", type: "checkbox", id: 'checked'},
    { title: 'NO', type: 'data', id: 'no' },
    { title: 'MODEL', type: 'data', id: 'modelName' },
    { title: 'MODEL CODE', type: 'data', id: 'modelCode' },
    { title: 'ENTRY DATE', type: 'data', id: 'entryDate' },
    { title: 'ARRIVAL DATE', type: 'data', id: 'arrivalDate' },
    { title: 'COLOUR CODE', type: 'data', id: 'extColourCode' },
    { title: 'TYPE', type: 'data', id: 'type' },
    { title: 'OCC NUMBER', type: 'data', id: 'occNumber' },
    { title: 'INTERIOR CODE', type: 'data', id: 'intColourCode' },
    { title: 'QUANTITY', type: 'data', id: 'quantity' },
    { title: 'RECEIVED QTY', type: 'data', id: 'receivedQuantity' },
    { title: 'ETA REMARK', type: 'data', id: 'etaRemark' },
    { title: 'ACTION', type: 'editTrash', id: 'action' },
  ];
  
  shipmentHistoryTableEntries: any[] = [];

  constructor(
    private shipmentMasterService: ShipmentMasterService,
    private notifier: NotifierService
  ) {}

  shipmentHistoryFormFields: FormField[] = [
    {
      type: 'select',
      id: 'shipmentEntry',
      label: 'Shipment Entry',
      value: '',
      options: [],
    },
  ];
  
  updateShipmentFormFields: FormField[] = [
    {
      type: 'text',
      id: 'updateRemarks',
      label: 'Update Remark(s)',
      value: '',
    },
    {
      type: 'date',
      id: 'updateEtaDate',
      label: 'Update ETA Date',
      value: '',
    },
  ];
  

  ngOnInit(): void {
    this.initializeForm();
    this.loadShipmentEntryDates();
    this.loadActiveModelList();
    this.updateShipmentEntryDropdown();
    this.initializeShipmentEditForm();
  }

  selectedTab: string = 'newShipment';

  onTabSelect(tab: string): void {
    this.selectedTab = tab;
  }

  private initializeForm(): void {
    this.formFields = [
      { type: 'date', id: 'shipmentDate', label: 'Shipment Date of Entry (dd/mm/yyyy)', value: '' },
      { type: 'date', id: 'arrivalDate', label: 'Estimated Date of Arrival (dd/mm/yyyy)', value: '' },
      { type: 'select', id: 'modelDescription', label: 'Model Description', value: '', options: this.modelOptions },
      { type: 'text', id: 'modelCode', label: 'Model Code', value: '',},
      { type: 'text', id: 'occNumber', label: 'OCC Number', value: '',},
      { type: 'select', id: 'exteriorColourCode', label: 'Exterior Colour Code', value: '', options: this.exteriorColourOptions },
      { type: 'text', id: 'type', label: 'Type', value: '',},
      { type: 'select', id: 'interiorColourCode', label: 'Interior Colour Code', value: '', options: this.interiorColourOptions },
      { type: 'number', id: 'quantity', label: 'Quantity', value: '' }
    ];
  }

  private initializeShipmentEditForm(): void {
    this.shipmentEditFormFields = [
      { type: 'date', id: 'shipmentDate', label: 'Shipment Date of Entry (dd/mm/yyyy)', value: '' },
      { type: 'date', id: 'arrivalDate', label: 'Estimated Date of Arrival (dd/mm/yyyy)', value: '' },
      { type: 'select', id: 'modelDescription', label: 'Model Description', value: '', options: this.modelOptions },
      { type: 'text', id: 'modelCode', label: 'Model Code', value: '' },
      { type: 'text', id: 'occNumber', label: 'OCC Number', value: '' },
      { type: 'select', id: 'exteriorColourCode', label: 'Exterior Colour Code', value: '', options: this.exteriorColourOptions },
      { type: 'text', id: 'type', label: 'Type', value: '' },
      { type: 'select', id: 'interiorColourCode', label: 'Interior Colour Code', value: '', options: this.interiorColourOptions },
      { type: 'number', id: 'quantity', label: 'Quantity', value: '' }
    ];
  }

  private loadShipmentEntryDates(): void {
    this.shipmentMasterService.getShipmentEntryDates().subscribe({
      next: (response) => console.log('Shipment Entry Dates Response:', response),
      error: (error) => this.notifier.notify('error', 'Error fetching shipment entry dates')
    });
  }

  private updateFormFieldOptions(fieldId: string, options: any[]): void {
    const field = this.formFields.find(f => f.id === fieldId);
    if (field) field.options = options;
  }

  /*loadActiveModelList(): void {
    this.shipmentMasterService.getBasicActiveModelList().subscribe({
      next: (response) => {
        //console.log('Active Model List Response:', response);
        if (response && response.body && Array.isArray(response.body)) {
          this.modelOptions = [
            { value: '', display: 'Please select' },
            ...response.body.map((model: any) => ({
              value: model.ModelShortDesc,
              display: model.ModelShortDesc,
              modelCode: model.ModelCode,
              occNumber: model.OccNo,
              modelGroup: model.ModelShort,
            })),
          ];
          this.updateFormFieldOptions('modelDescription', this.modelOptions);
          //console.log('Updated model options:', this.modelOptions);
        } else {
          console.error('Unexpected response structure or missing data.');
          this.notifier.notify('error', 'Unexpected response structure or missing data.');
        }
      },
      error: (error) => {
        console.error('Error fetching active model list:', error);
        this.notifier.notify('error', 'Error fetching active model list.');
      },
    });
  }  */

  onModelChange(event: any): void {
    ////console.log('Dropdown change event:', event);
  
    const modelDescriptionField = this.formFields.find(f => f.id === 'modelDescription');
    const selectedModel = modelDescriptionField?.value || '';
  
    ////console.log('Detected Model Short Desc (from formFields):', selectedModel);
  
    if (selectedModel) {
      this.handleSelectedModel(selectedModel);
    } else {
      console.warn('No valid model selected or value not found.');
    }
  }
  
  private handleSelectedModel(selectedModel: string): void {
    //console.log('Raw selected model:', selectedModel);
  
    const selected = this.modelOptions.find(
      m => m.value?.trim().toLowerCase() === selectedModel.trim().toLowerCase()
    );
  
    if (!selected) {
      console.warn('Model not found in options:', selectedModel);
      this.updateFormFieldValue('modelCode', '');
      this.updateFormFieldValue('occNumber', '');
      return;
    }
  
    //console.log('Matching model:', selected);
  
    this.updateFormFieldValue('modelCode', selected.modelCode);
    this.updateFormFieldValue('occNumber', selected.occNumber);
  
    this.loadExteriorColours(selected.value, selected.modelGroup);
  }
  
  private loadExteriorColours(modelCode: string, modelGroup: string): void {
    const encodedModelCode = this.correctEncoding(modelCode);
    const encodedModelGroup = this.correctEncoding(modelGroup);
  
    //console.log('Encoded Model Code:', encodedModelCode);
    //console.log('Encoded Model Group:', encodedModelGroup);
  
    this.shipmentMasterService.getColourCodeByModel(encodedModelCode, encodedModelGroup).subscribe({
      next: (response) => {
        //console.log('Exterior Colours Response:', response);
        if (response && response.body && Array.isArray(response.body)) {
          this.exteriorColourOptions = [
            { value: '', display: 'Please select' },
            ...response.body.map((colour: any) => ({
              value: colour.ExteriorColour,
              display: `${colour.ExteriorColour} - ${colour.Description}`,
              type: colour.Type,
            })),
          ];
          this.updateFormFieldOptions('exteriorColourCode', this.exteriorColourOptions);
        } else {
          console.error('Unexpected response structure for exterior colours.');
          this.notifier.notify('error', 'Unexpected response structure for exterior colours.');
        }
      },
      error: (error) => {
        console.error('Error fetching exterior colours:', error);
        this.notifier.notify('error', 'Error fetching exterior colours.');
      },
    });
  }
  
  private correctEncoding(value: string): string {
    return encodeURIComponent(value)
      .replace(/%20/g, '+')
      .replace(/%2B/g, '+') 
      .replace(/%5C%22/g, '%5C%22')
      .replace(/%2522/g, '%22')
      .replace(/%255C/g, '%5C'); 
  }

  private getFieldValue(fieldId: string): string {
    const field = this.formFields.find(f => f.id === fieldId);
    return field ? field.value : '';
  }
  
  onExteriorColourChange(updatedField: any): void {
    //console.log('Dropdown change event:', updatedField);
  
    const exteriorColourField = this.formFields.find(
      (field) => field.id === 'exteriorColourCode'
    );
  
    if (!exteriorColourField) {
      console.warn('Exterior Colour field not found in formFields.');
      return;
    }
  
    const selectedColour = exteriorColourField.value;
    //console.log('Selected Exterior Colour Value:', selectedColour);
  
    const selectedOption = this.exteriorColourOptions.find(
      (option) => option.value === selectedColour
    );
  
    if (selectedOption) {
      //console.log('Matching Option Found:', selectedOption);
  
      this.updateFormFieldValue('type', selectedOption.type || '');
      //console.log('Field "type" updated to:', selectedOption.type);
  
      const extColour = selectedOption.value;
      const model = this.getFieldValue('modelDescription');
      //console.log('Exterior Colour for Interior API:', extColour);
      //console.log('Model Description for Interior API:', model);
  
      this.loadInteriorColours(extColour, model);
    } else {
      console.warn(
        'No matching option found for selected colour:',
        selectedColour,
        'Available options:',
        this.exteriorColourOptions
      );
      this.updateFormFieldValue('type', '');
    }
  }
  
  private loadInteriorColours(extColour: string, model: string): void {
    const encodedExtColour = this.correctEncoding(extColour);
    const encodedModel = this.correctEncoding(model); 
  
    //console.log('Encoded extColour:', encodedExtColour);
    //console.log('Encoded model (description):', encodedModel);
  
    this.shipmentMasterService.getIntColourCodeList(encodedExtColour, encodedModel).subscribe({
      next: (response) => {
        //console.log('Interior Colours Response:', response);
        if (response && response.body && Array.isArray(response.body)) {
          this.interiorColourOptions = [
            { value: '', display: 'Please select' },
            ...response.body.map((intColour: any) => ({
              value: intColour.Value,
              display: `${intColour.Value} - ${intColour.Name}`,
            })),
          ];
          this.updateFormFieldOptions('interiorColourCode', this.interiorColourOptions);
          //console.log('Updated Interior Colour Options:', this.interiorColourOptions);
        } else {
          console.error('Unexpected response structure for interior colours.');
          this.notifier.notify('error', 'Unexpected response structure for interior colours.');
        }
      },
      error: (error) => {
        console.error('Error fetching interior colours:', error);
        this.notifier.notify('error', 'Error fetching interior colours.');
      },
    });
  }
  
  private updateFormFieldValue(fieldId: string, value: any): void {
    const field = this.formFields.find(f => f.id === fieldId);
    if (!field) {
      console.warn(`Field with id '${fieldId}' not found.`);
      return;
    }
  
    field.value = value;
    //console.log(`Field '${fieldId}' updated to:`, value);
  }

  private formatToDDMMYYYY(date: string): string {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }
  
  onAddEntry(): void {
    const newEntry = {
      entryDate: this.formatToDDMMYYYY(this.getFieldValue('shipmentDate')),
      arrivalDate: this.formatToDDMMYYYY(this.getFieldValue('arrivalDate')),
      modelDescription: this.getFieldValue('modelDescription'),
      modelCode: this.getFieldValue('modelCode'),
      occNumber: this.getFieldValue('occNumber'),
      exteriorColourCode: this.getSelectedOptionText('exteriorColourCode'),
      type: this.getFieldValue('type'),
      interiorColourCode: this.getSelectedOptionText('interiorColourCode'),
      quantity: this.getFieldValue('quantity'),
      trash: ''
    };
  
    if (
      !newEntry.entryDate ||
      !newEntry.arrivalDate ||
      !newEntry.modelDescription ||
      !newEntry.modelCode ||
      !newEntry.quantity
    ) {
      this.notifier.notify('error', 'Please fill in all required fields.');
      return;
    }
  
    this.tableEntries.push(newEntry);
    //console.log('New entry added to table:', newEntry);
  }
  
  onRemoveEntry(entry: any): void {
    const index = this.tableEntries.indexOf(entry);
    if (index > -1) {
      this.tableEntries.splice(index, 1);
      //console.log('Entry removed:', entry);
      this.notifier.notify('success', 'Entry removed successfully.');
    } else {
      this.notifier.notify('error', 'Failed to remove entry.');
    }
  }

  onClearForm(): void {
    this.formFields.forEach(field => {
      if (field.hasOwnProperty('value')) {
        field.value = ''; 
      }
    });
  
    //console.log('Form cleared:', this.formFields);
  }
  
  onSaveEntries(): void {
    const modelList = this.tableEntries.map(entry => ({
      EntryDate: entry.entryDate,
      ArrivalDate: entry.arrivalDate,
      ModelName: entry.modelDescription,
      ModelCode: entry.modelCode,
      OccNo: entry.occNumber,
      ModelGroup: entry.modelDescription.split(' ')[0],
      Quantity: entry.quantity,
      ExtColourCode: entry.exteriorColourCode,
      IntColourCode: entry.interiorColourCode,
      ExtColourType: entry.type,
      ShipmentMasterOrderNo: 0,
      Classification: 'NORMAL',
      IntColourType: null,
      OrdersRemark: null,
      EtaRemark: null,
      Checked: true,
    }));
  
    const payload = { modelList };
  
    this.shipmentMasterService.addShipment(payload).subscribe({
      next: (response) => {
        //console.log('Shipment added successfully:', response);
        this.notifier.notify('success', 'New shipment saved successfully.');
        this.updateShipmentEntryDropdown();
      },
      error: (error) => {
        console.error('Error adding shipment:', error);
        this.notifier.notify('error', 'Failed to save new shipment.');
      },
    });
  }

  updateShipmentEntryDropdown(): void {
    this.shipmentMasterService.getShipmentEntryDates().subscribe({
      next: (response) => {
        const shipmentDates: string[] = response.body || [];
        //console.log('Updated shipment entry dates:', shipmentDates);
        const shipmentEntryField = this.shipmentHistoryFormFields.find(f => f.id === 'shipmentEntry');
        if (shipmentEntryField) {
          shipmentEntryField.options = shipmentDates.map((date: string) => ({
            value: date,
            display: date,
          }));
          //console.log('Dropdown options updated:', shipmentEntryField.options);
        } else {
          console.warn('Shipment entry field not found.');
        }
      },
      error: (error) => {
        console.error('Error fetching shipment entry dates:', error);
        this.notifier.notify('error', 'Failed to fetch shipment entry dates.');
      },
    });
  }
  
  shipmentsByDate: any[] = []; 

  onShipmentEntryChange(event: any): void {
    const selectedEntry = this.shipmentHistoryFormFields.find(f => f.id === 'shipmentEntry')?.value;
  
    if (!selectedEntry) {
      console.warn('No shipment entry selected.');
      return;
    }
  
    const formattedDate = `%22${selectedEntry.replace(/\//g, '-')}%22`;
    //console.log('Formatted date for API:', formattedDate);
  
    this.shipmentMasterService.getAllShipmentByDate(formattedDate).subscribe({
      next: (response) => {
        //console.log('Raw response from API:', response);
  
        const shipments = response.body || [];
        //console.log('Parsed shipments:', shipments);
  
        if (shipments.length === 0) {
          console.warn('No shipments found for the selected date.');
          this.shipmentHistoryTableEntries = [];
          return;
        }
  
        this.shipmentHistoryTableEntries = shipments.map((shipment: any) => ({
          checked: '',
          no: shipment.ShipmentMasterOrderNo,
          modelName: shipment.ModelName,
          modelCode: shipment.ModelCode,
          entryDate: shipment.EntryDate,
          arrivalDate: shipment.ArrivalDate,
          extColourCode: shipment.ExtColourCode,
          type: shipment.ExtColourType,
          occNumber: shipment.OccNo,
          intColourCode: shipment.IntColourCode,
          quantity: shipment.Quantity,
          receivedQuantity: shipment.ReceivedQuantity,
          etaRemark: shipment.EtaRemark || '',
          action: '',
        }));
  
        //console.log('Mapped shipment history table entries:', this.shipmentHistoryTableEntries);
      },
      error: (error) => {
        console.error('Error fetching shipments by date:', error);
        this.notifier.notify('error', 'Failed to fetch shipments for the selected date.');
      },
    });
  }
  
  private getSelectedOptionText(fieldId: string): string {
    const field = this.formFields.find((f) => f.id === fieldId);
    if (!field || !field.options) return '';
    const selectedOption = field.options.find((opt) => opt.value === field.value);
    return selectedOption ? selectedOption.display : '';
  }
  
  editShipmentHistoryEntry(value: any): void {
    this.isEditingShipment = true;

    if (!value.no) {
        console.warn('No shipment code provided for editing.');
        this.notifier.notify('warning', 'No shipment selected for editing.');
        return;
    }

    const shipmentCode = value.no;

    this.shipmentMasterService.getShipmentDetails(shipmentCode).subscribe({
        next: (response) => {
            this.shipmentDetails = response?.body;
            if (this.shipmentDetails) {
                const modelNameToUseAsModelCode = this.shipmentDetails.ModelName;
                this.loadActiveModelList(() => {
                    const matchingOption = this.modelOptions.find(
                        (option) => option.value.trim() === this.shipmentDetails.ModelName.trim()
                    );
                    if (matchingOption) {
                        this.shipmentEditFormFields.forEach((field) => {
                            switch (field.id) {
                                case 'modelDescription':
                                    field.value = matchingOption.value;
                                    field.options = this.modelOptions;
                                    break;
                                case 'modelCode':
                                    field.value = matchingOption.modelCode || '';
                                    break;
                                case 'occNumber':
                                    field.value = matchingOption.occNumber || '';
                                    break;
                                case 'shipmentDate':
                                    field.value = this.convertDateToISO(this.shipmentDetails.EntryDate || '');
                                    break;
                                case 'arrivalDate':
                                    field.value = this.convertDateToISO(this.shipmentDetails.ArrivalDate || '');
                                    break;
                                case 'quantity':
                                    field.value = this.shipmentDetails.Quantity || '';
                                    break;
                            }
                        });

                        this.loadExteriorColoursForEditForm(
                            modelNameToUseAsModelCode,
                            this.shipmentDetails.ModelGroup,
                            this.shipmentDetails.ExtColourCode,
                            () => {
                                this.loadInteriorColoursForEditForm(
                                    this.shipmentDetails.ExtColourCode.split(' ')[0].trim(),
                                    modelNameToUseAsModelCode,
                                    this.shipmentDetails.IntColourCode
                                );
                            }
                        );
                    } else {
                        console.warn('No matching option found for Model Name:', this.shipmentDetails.ModelName);
                    }
                });
            } else {
                console.warn('No details found in the response for shipment code:', shipmentCode);
                this.notifier.notify('warning', 'No details found for the selected shipment.');
            }
        },
        error: (error) => {
            console.error('Error fetching shipment details:', error);
            this.notifier.notify('error', 'Failed to retrieve shipment details.');
        },
    });
}
  
  private loadInteriorColoursForEditForm(
    extColour: string,
    modelCode: string,
    preselectedInteriorCode?: string 
  ): void {
    const encodedExtColour = this.correctEncoding(extColour);
    const encodedModelCode = this.correctEncoding(modelCode);
  
    /*console.log('Calling getIntColourCodeList with:', {
      extColour: encodedExtColour,
      modelCode: encodedModelCode,
    });*/
  
    this.shipmentMasterService.getIntColourCodeList(encodedExtColour, encodedModelCode).subscribe({
      next: (response) => {
        ////console.log('Interior Colours Response:', response);
        if (response && response.body && Array.isArray(response.body)) {
          this.interiorColourOptions = [
            { value: '', display: 'Please select' },
            ...response.body.map((intColour: any) => ({
              value: intColour.Value,
              display: `${intColour.Value} - ${intColour.Name}`,
            })),
          ];
  
          const interiorColourField = this.shipmentEditFormFields.find((field) => field.id === 'interiorColourCode');
          if (interiorColourField) {
            interiorColourField.options = this.interiorColourOptions;
  
            if (preselectedInteriorCode) {
              const cleanInteriorCode = preselectedInteriorCode.split(' ')[0].trim();
              interiorColourField.value = cleanInteriorCode;
              ////console.log('Preselected Interior Colour Code:', cleanInteriorCode);
            }
          }
          ////console.log('Updated Interior Colour Options:', this.interiorColourOptions);
        } else {
          console.error('Unexpected response structure for interior colours.');
          this.notifier.notify('error', 'Unexpected response structure for interior colours.');
        }
      },
      error: (error) => {
        console.error('Error fetching interior colours:', error);
        this.notifier.notify('error', 'Error fetching interior colours.');
      },
    });
  }

  onModelChangeForEditForm(event: any): void {
    const modelDescriptionField = this.shipmentEditFormFields.find((f) => f.id === 'modelDescription');
    const selectedModel = modelDescriptionField?.value || '';
  
    if (selectedModel) {
      this.handleSelectedModelForEditForm(selectedModel);
    } else {
      console.warn('No valid model selected in edit form.');
    }
  }
  
  private handleSelectedModelForEditForm(selectedModel: string): void {
    const selected = this.modelOptions.find(
      (m) => m.value?.trim().toLowerCase() === selectedModel.trim().toLowerCase()
    );
  
    if (!selected) {
      console.warn('Model not found in options (edit form):', selectedModel);
      this.updateEditFormFieldValue('modelCode', '');
      this.updateEditFormFieldValue('occNumber', '');
      return;
    }
  
    this.updateEditFormFieldValue('modelCode', selected.modelCode);
    this.updateEditFormFieldValue('occNumber', selected.occNumber);
  
    this.loadExteriorColoursForEditForm(selected.value, selected.modelGroup);
  }

  private updateEditFormFieldValue(fieldId: string, value: any): void {
    const field = this.shipmentEditFormFields.find((f) => f.id === fieldId);
    if (!field) {
      console.warn(`Field with id '${fieldId}' not found in edit form.`);
      return;
    }
  
    field.value = value;
  }
  
  private getEditFormFieldValue(fieldId: string): string {
    const field = this.shipmentEditFormFields.find((f) => f.id === fieldId);
    return field ? field.value : '';
  }
  
  onExteriorColourChangeForEditForm(event: any): void {
    const exteriorColourField = this.shipmentEditFormFields.find(
      (field) => field.id === 'exteriorColourCode'
    );
  
    if (!exteriorColourField) {
      console.warn('Exterior Colour field not found in edit form.');
      return;
    }
  
    const selectedColour = exteriorColourField.value;
    const selectedOption = this.exteriorColourOptions.find((option) => option.value === selectedColour);
  
    if (selectedOption) {
      this.updateEditFormFieldValue('type', selectedOption.type || '');
      const extColour = selectedOption.value;
      const model = this.getEditFormFieldValue('modelDescription');
      this.loadInteriorColoursForEditForm(extColour, model);
    } else {
      console.warn('No matching option found for selected exterior colour (edit form).');
      this.updateEditFormFieldValue('type', '');
    }
  }

  private convertDateToISO(date: string): string {
    if (!date) return '';
    
    const [day, month, year] = date.split('/');
    
    if (!day || !month || !year) {
      console.warn('Invalid date format:', date);
      return '';
    }
    
    return `${year}-${month}-${day}`;
  }
  
  
  loadActiveModelList(callback?: () => void): void {
    this.shipmentMasterService.getBasicActiveModelList().subscribe({
      next: (response) => {
        //console.log('Active Model List Response:', response);
        if (response && response.body && Array.isArray(response.body)) {
          this.modelOptions = [
            { value: '', display: 'Please select' },
            ...response.body.map((model: any) => ({
              value: model.ModelShortDesc,
              display: model.ModelShortDesc,
              modelCode: model.ModelCode,
              occNumber: model.OccNo,
              modelGroup: model.ModelShort,
            })),
          ];
          this.updateFormFieldOptions('modelDescription', this.modelOptions);
          //console.log('Updated model options:', this.modelOptions);
          if (callback) callback(); 
        } else {
          console.error('Unexpected response structure or missing data.');
          this.notifier.notify('error', 'Unexpected response structure or missing data.');
        }
      },
      error: (error) => {
        console.error('Error fetching active model list:', error);
        this.notifier.notify('error', 'Error fetching active model list.');
      },
    });
  }  

  private loadExteriorColoursForEditForm(
    modelCode: string,
    modelGroup: string,
    extColourCode?: string,
    callback?: () => void
  ): void {
    if (!modelCode) {
      console.error('Model Code is required but not provided.');
      this.notifier.notify('error', 'Model Code is missing for fetching exterior colours.');
      return;
    }
  
    const encodedModelGroup = this.correctEncoding(modelGroup);
  
    //console.log('Encoded Model Code for Edit Form:', modelCode);
    //console.log('Encoded Model Group for Edit Form:', encodedModelGroup);
  
    this.shipmentMasterService.getColourCodeByModel(modelCode, encodedModelGroup).subscribe({
      next: (response) => {
        //console.log('Exterior Colours Response for Edit Form:', response);
  
        if (response && response.body && Array.isArray(response.body)) {
          this.exteriorColourOptions = [
            { value: '', display: 'Please select' },
            ...response.body.map((colour: any) => ({
              value: colour.ExteriorColour,
              display: `${colour.ExteriorColour} - ${colour.Description}`,
              type: colour.Type,
            })),
          ];
  
          const exteriorColourField = this.shipmentEditFormFields.find(
            (field) => field.id === 'exteriorColourCode'
          );
          if (exteriorColourField) {
            exteriorColourField.options = this.exteriorColourOptions;
          }
  
          if (extColourCode) {
            const extColourCodePrefix = extColourCode.split(' ')[0]?.trim();
            //console.log('Extracted ExtColourCode Prefix:', extColourCodePrefix);
  
            const matchingOption = this.exteriorColourOptions.find(
              (option) => option.value.trim() === extColourCodePrefix
            );
  
            if (matchingOption) {
              //console.log('Matching Exterior Colour Code for Edit Form:', matchingOption);
  
              if (exteriorColourField) {
                exteriorColourField.value = matchingOption.value;
              }
  
              const typeField = this.shipmentEditFormFields.find((field) => field.id === 'type');
              if (typeField) {
                typeField.value = matchingOption.type || '';
                //console.log('Updated type field value for Edit Form:', matchingOption.type);
              }
            } else {
              console.warn('No matching Exterior Colour Code found for:', extColourCodePrefix);
            }
          }
  
          if (callback) callback();
        } else {
          console.error('Unexpected response structure for exterior colours in Edit Form.');
          this.notifier.notify('error', 'Unexpected response structure for exterior colours in Edit Form.');
        }
      },
      error: (error) => {
        console.error('Error fetching exterior colours for Edit Form:', error);
        this.notifier.notify('error', 'Error fetching exterior colours for Edit Form.');
      },
    });
  }  
  
  removeShipmentHistoryEntry(value: any): void {
    if (!value.no) {
      console.warn('No shipment ID provided for deletion.');
      this.notifier.notify('warning', 'No shipment selected for deletion.');
      return;
    }
  
    const shipmentId = value.no; 
    //console.log('Removing shipment entry with ID:', shipmentId);
  
    this.shipmentMasterService.deleteShipment(shipmentId).subscribe({
      next: (response: any) => {
        //console.log('Shipment deleted successfully:', response);
        //this.notifier.notify('success', 'Shipment deleted successfully.');
        const selectedEntry = this.shipmentHistoryFormFields.find(f => f.id === 'shipmentEntry')?.value;
        if (!selectedEntry) {
          console.warn('No shipment entry selected for refreshing table.');
          return;
        }
  
        const formattedDate = `%22${selectedEntry.replace(/\//g, '-')}%22`;
        this.shipmentMasterService.getAllShipmentByDate(formattedDate).subscribe({
          next: (response: any) => {
            //console.log('Updated shipment history table entries:', response);
            this.shipmentHistoryTableEntries = response.body.map((shipment: any) => ({
              checked: '',
              no: shipment.ShipmentMasterOrderNo,
              modelName: shipment.ModelName,
              modelCode: shipment.ModelCode,
              entryDate: shipment.EntryDate,
              arrivalDate: shipment.ArrivalDate,
              extColourCode: shipment.ExtColourCode,
              type: shipment.ExtColourType,
              occNumber: shipment.OccNo,
              intColourCode: shipment.IntColourCode,
              quantity: shipment.Quantity,
              receivedQuantity: shipment.ReceivedQuantity,
              etaRemark: shipment.EtaRemark || '',
              action: '',
            }));
            //this.notifier.notify('success', 'Table updated successfully.');
          },
          error: (error: any) => {
            console.error('Error refreshing shipment history table:', error);
            this.notifier.notify('error', 'Failed to refresh the table.');
          },
        });
      },
      error: (error: any) => {
        console.error('Error deleting shipment entry:', error);
        this.notifier.notify('error', 'Failed to delete shipment.');
      },
    });
  }
  
  onUpdateShipmentFormChange(event: any): void {
    //console.log('Update Shipment Form Field Changed:', event);
  }

  onUpdateEtaRemarks(): void {
    const selectedItems = this.shipmentHistoryTableEntries.filter(item => item.checked);
  
    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one item to update remarks.');
      return;
    }
  
    const orderNoList = selectedItems.map(item => item.no);
  
    const remarksField = this.updateShipmentFormFields.find(field => field.id === 'updateRemarks');
    if (!remarksField || !remarksField.value.trim()) {
      this.notifier.notify('error', 'Remarks cannot be empty.');
      return;
    }
  
    const remarkValue = remarksField.value;
  
    const payload = {
      model: {
        OrderNoList: orderNoList,
        Value: remarkValue,
      },
    };
  
    this.shipmentMasterService.updateRemarks(payload).subscribe({
      next: (response: any) => {
        //console.log('Remarks updated successfully:', response);
        this.notifier.notify('success', 'Remarks updated successfully.');
  
        // Llama a GetAllShipmentByDate para actualizar la tabla
        const selectedEntry = this.shipmentHistoryFormFields.find(f => f.id === 'shipmentEntry')?.value;
        if (!selectedEntry) {
          console.warn('No shipment entry selected for refreshing table.');
          return;
        }
  
        const formattedDate = `%22${selectedEntry.replace(/\//g, '-')}%22`;
        this.shipmentMasterService.getAllShipmentByDate(formattedDate).subscribe({
          next: (response: any) => {
            //console.log('Updated shipment history table entries:', response);
            this.shipmentHistoryTableEntries = response.body.map((shipment: any) => ({
              checked: '',
              no: shipment.ShipmentMasterOrderNo,
              modelName: shipment.ModelName,
              modelCode: shipment.ModelCode,
              entryDate: shipment.EntryDate,
              arrivalDate: shipment.ArrivalDate,
              extColourCode: shipment.ExtColourCode,
              type: shipment.ExtColourType,
              occNumber: shipment.OccNo,
              intColourCode: shipment.IntColourCode,
              quantity: shipment.Quantity,
              receivedQuantity: shipment.ReceivedQuantity,
              etaRemark: shipment.EtaRemark || '',
              action: '',
            }));
          },
          error: (error: any) => {
            console.error('Error refreshing shipment history table:', error);
            this.notifier.notify('error', 'Failed to refresh the table.');
          },
        });
      },
      error: (error: any) => {
        console.error('Error updating remarks:', error);
        this.notifier.notify('error', 'Failed to update remarks.');
      },
    });
  }
  
  onUpdateEtaDate(): void {
    const selectedItems = this.shipmentHistoryTableEntries.filter(item => item.checked);
  
    if (selectedItems.length === 0) {
      this.notifier.notify('error', 'Please select at least one item to update ETA Date.');
      return;
    }
  
    const orderNoList = selectedItems.map(item => item.no);
  
    const etaDateField = this.updateShipmentFormFields.find(field => field.id === 'updateEtaDate');
    if (!etaDateField || !/^\d{4}-\d{2}-\d{2}$/.test(etaDateField.value)) {
      this.notifier.notify('error', 'Invalid ETA Date. Use format yyyy-mm-dd.');
      return;
    }
  
    const etaDate = etaDateField.value.split('-').reverse().join('/'); // Convierte a formato dd/mm/yyyy
  
    const payload = {
      model: {
        OrderNoList: orderNoList,
        Value: etaDate,
      },
    };
  
    this.shipmentMasterService.updateArrivalDate(payload).subscribe({
      next: (response: any) => {
        //console.log('ETA Date updated successfully:', response);
        this.notifier.notify('success', 'ETA Date updated successfully.');
  
        const selectedEntry = this.shipmentHistoryFormFields.find(f => f.id === 'shipmentEntry')?.value;
        if (!selectedEntry) {
          console.warn('No shipment entry selected for refreshing table.');
          return;
        }
  
        const formattedDate = `%22${selectedEntry.replace(/\//g, '-')}%22`;
        this.shipmentMasterService.getAllShipmentByDate(formattedDate).subscribe({
          next: (response: any) => {
            //console.log('Updated shipment history table entries:', response);
            this.shipmentHistoryTableEntries = response.body.map((shipment: any) => ({
              checked: '',
              no: shipment.ShipmentMasterOrderNo,
              modelName: shipment.ModelName,
              modelCode: shipment.ModelCode,
              entryDate: shipment.EntryDate,
              arrivalDate: shipment.ArrivalDate,
              extColourCode: shipment.ExtColourCode,
              type: shipment.ExtColourType,
              occNumber: shipment.OccNo,
              intColourCode: shipment.IntColourCode,
              quantity: shipment.Quantity,
              receivedQuantity: shipment.ReceivedQuantity,
              etaRemark: shipment.EtaRemark || '',
              action: '',
            }));
          },
          error: (error: any) => {
            console.error('Error refreshing shipment history table:', error);
            this.notifier.notify('error', 'Failed to refresh the table.');
          },
        });
      },
      error: (error: any) => {
        console.error('Error updating ETA Date:', error);
        this.notifier.notify('error', 'Failed to update ETA Date.');
      },
    });
  }
  
  onUpdateShipmentDetails(): void {
    if (!this.shipmentDetails) {
        console.error('Shipment details not available for update.');
        this.notifier.notify('error', 'No shipment details available for update.');
        return;
    }

    const modelCode = this.shipmentEditFormFields.find(f => f.id === 'modelCode')?.value;
    const modelGroup = this.modelOptions.find(opt => opt.modelCode === modelCode)?.modelGroup || '';

    const formatToDDMMYYYY = (date: string): string => {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    };

    const payload = {
        model: {
            ShipmentMasterOrderNo: this.shipmentDetails.ShipmentMasterOrderNo,
            ModelName: this.shipmentEditFormFields.find(f => f.id === 'modelDescription')?.value || '',
            ModelCode: modelCode || '',
            ModelGroup: modelGroup,
            Classification: this.shipmentDetails.Classification,
            OccNo: this.shipmentEditFormFields.find(f => f.id === 'occNumber')?.value || '',
            ExtColourCode: this.shipmentEditFormFields.find(f => f.id === 'exteriorColourCode')?.value || '',
            ExtColourType: this.shipmentEditFormFields.find(f => f.id === 'type')?.value || '',
            IntColourCode: this.shipmentEditFormFields.find(f => f.id === 'interiorColourCode')?.value || '',
            IntColourType: this.shipmentDetails.IntColourType,
            Quantity: this.shipmentEditFormFields.find(f => f.id === 'quantity')?.value || 0,
            ReceivedQuantity: this.shipmentDetails.ReceivedQuantity,
            EntryDate: formatToDDMMYYYY(this.shipmentEditFormFields.find(f => f.id === 'shipmentDate')?.value || ''),
            ArrivalDate: formatToDDMMYYYY(this.shipmentEditFormFields.find(f => f.id === 'arrivalDate')?.value || ''),
            OrdersRemark: this.shipmentDetails.OrdersRemark,
            EtaRemark: this.shipmentEditFormFields.find(f => f.id === 'etaRemark')?.value || '',
        },
    };

    this.shipmentMasterService.updateShipment(payload).subscribe({
        next: (response) => {
            console.log('Shipment updated successfully:', response);
            this.notifier.notify('success', 'Shipment updated successfully.');

            const selectedEntry = this.shipmentHistoryFormFields.find(f => f.id === 'shipmentEntry')?.value;
            if (!selectedEntry) {
                console.warn('No shipment entry selected for refreshing table.');
                return;
            }

            const formattedDate = `%22${selectedEntry.replace(/\//g, '-')}%22`;
            this.shipmentMasterService.getAllShipmentByDate(formattedDate).subscribe({
                next: (response: any) => {
                    console.log('Updated shipment history table entries:', response);
                    this.shipmentHistoryTableEntries = response.body.map((shipment: any) => ({
                        checked: '',
                        no: shipment.ShipmentMasterOrderNo,
                        modelName: shipment.ModelName,
                        modelCode: shipment.ModelCode,
                        entryDate: shipment.EntryDate,
                        arrivalDate: shipment.ArrivalDate,
                        extColourCode: shipment.ExtColourCode,
                        type: shipment.ExtColourType,
                        occNumber: shipment.OccNo,
                        intColourCode: shipment.IntColourCode,
                        quantity: shipment.Quantity,
                        receivedQuantity: shipment.ReceivedQuantity,
                        etaRemark: shipment.EtaRemark || '',
                        action: '',
                    }));
                },
                error: (error: any) => {
                    console.error('Error refreshing shipment history table:', error);
                    this.notifier.notify('error', 'Failed to refresh the table.');
                },
            });
        },
        error: (error) => {
            console.error('Error updating shipment:', error);
            this.notifier.notify('error', 'Failed to update shipment.');
        },
    });
}

  onCancelEdit(): void {
    this.isEditingShipment = false;
    //console.log('Cancelled editing shipment.');
  }
  
}
