<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">Model and Specification Detail</h1>
      <app-navpath />
    </div>
    <div class="mb-2 flex w-fit gap-1">
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1" />Add Model
      </button>
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faPencil" class="mr-1" />Update Model
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBan" class="mr-1" />Cancel
      </button>
    </div>
    <div class="content-container">
        <div class="content-container">
            <app-data-form [dataFields]="detailForm" />
        </div>
    </div>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Standard Specification</h2>
      <app-data-form [dataFields]="standard_specification" />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Brakes</h2>
      <app-data-form [dataFields]="brakes" />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Steering Wheel</h2>
      <app-data-form [dataFields]="steering_wheel" />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Suspension</h2>
      <app-data-form [dataFields]="suspension" />
    </section>
    <section class="content-container">
      <h2 class="sub-heading w-full border-b">Dimension</h2>
      <app-data-form [dataFields]="dimension" />
    </section>
    <div class="mb-2 flex w-fit gap-1 mt-2">
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faPlus" class="mr-1" />Add Model
      </button>
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faPencil" class="mr-1" />Update Model
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBan" class="mr-1" />Cancel
      </button>
    </div>
</section>
