import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faEdit,
  faTrash,
  faBroom,
  faEye,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-coe-allocation",
  templateUrl: "./coe-allocation.component.html",
  styleUrls: ["./coe-allocation.component.css"],
})
export class CoeAllocationComponent implements OnInit {
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faBroom = faBroom;
  faEye = faEye;
  faUpload = faUpload;

  detailsContract!: FormField[];
  detailsCOE!: FormField[];
  uploadCOECertificates!: FormField[];
  legends = [
    {
      label: "Invalid Contract Number.",
      color: "bg-[#00ffff]",
      active: true,
    },
    {
      label: "Duplicated COE Certificates.",
      color: "bg-[#6495ed]",
      active: true,
    },
    {
      label: "Name didn't matched.",
      color: "bg-[#9acd32]",
      active: true,
    },
  ];
  selectedTab = "single";

  updateFields(event: any): void {
    switch (event.name) {
      case "detailsContract":
        this.detailsContract = event.fields;
        break;
      case "detailsCOE":
        this.detailsCOE = event.fields;
        break;
      case "uploadCOECertificates":
        this.uploadCOECertificates = event.fields;
        break;
    }
  }

  filterLegends(legends: any[]): any[] {
    return legends.filter((legend) => legend.active);
  }

  ngOnInit() {
    this.detailsContract = [
      {
        type: "text",
        id: "contractNumber",
        label: "Contract number",
      },
      {
        type: "text",
        id: "contractName",
        label: "Contract name",
      },
    ];
    this.detailsCOE = [
      {
        type: "text",
        id: "bidderNRIC",
        label: "Bidder's NRIC",
      },
      {
        type: "text",
        id: "bidderName",
        label: "Bidder's name",
        required: true,
      },
      {
        type: "text",
        id: "COEcertificateNo",
        label: "COE certificade No",
      },
      {
        type: "text",
        id: "COEAmount",
        label: "COE amount",
      },
      {
        type: "number",
        id: "COEDepositAmount",
        label: "COE deposit amount",
      },
      {
        type: "date",
        id: "COEBidDate",
        label: "COE Bid Date (dd/mm/yyyy)",
      },
      {
        type: "select",
        id: "COECategory",
        label: "COE category",
        options: [
          {
            display: "A",
            value: "A",
          },
          {
            display: "B",
            value: "B",
          },
          {
            display: "C",
            value: "C",
          },
          {
            display: "E",
            value: "E",
          },
        ],
      },
      {
        type: "date",
        id: "COEExpiryDate",
        label: "COE expiry date (dd/mm/yyyy)",
      },
      {
        type: "date",
        id: "COEActualTakenDate",
        label: "COE actual taken date",
      },
      {
        type: "text",
        id: "takenBy",
        label: "Taken by",
      },
    ];
    this.uploadCOECertificates = [
      {
        type: "date",
        id: "inputBidDate",
        label: "Input COE bid date (dd/mm/yyyy)",
      },
      {
        type: "file",
        id: "fileLocation",
        label: "File location",
      },
    ];
  }
}
