import { Component } from '@angular/core';
import { FormField } from "src/app/interfaces/form-field.model";
import {
  faPlus,
  faMinus,
  faCircle,
  faPencil,
  faTrashCan,
  faTrash,
  faFloppyDisk,
  faCancel,
  faMagnifyingGlass,
  faSearch,
  faTriangleExclamation,
  faEraser,
  faSave,
  faBroom,
  faCar,
  faUserGroup,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-salesman-receipt',
  templateUrl: './salesman-receipt.component.html',
  styleUrls: ['./salesman-receipt.component.css']
})
export class SalesmanReceiptComponent {

  faPlus = faPlus;
  faMinus = faMinus;
  faCircle = faCircle;
  faPencil = faPencil;
  faTrashCan = faTrashCan;
  faFloppyDisk = faFloppyDisk;
  faCancel = faCancel;
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faSearch = faSearch;
  faTriangleExclamation = faTriangleExclamation;
  faEraser = faEraser;
  faCar = faCar;
  faSave = faSave;
  faBroom = faBroom;
  faUserGroup = faUserGroup;
  faPrint = faPrint

  buyersDetails!: FormField[];
  buyersDetails2!: FormField[];
  detailsVehicle!: FormField[];
  detailsVehicle2!: FormField[];
  detailsVehicle3!: FormField[];
  detailsCashDiscount!: FormField[];
  detailsDeposit!: FormField[];
  detailsOtherFees!: FormField[];
  detailsInsurance!: FormField[];
  detailsHireApplication!: FormField[];
  detailsTradeInVehicle!: FormField[];
  komocosAccount_Promotion!: FormField[];
  komocosAccount_Accessories!: FormField[];
  buyersAccount_NonAccessories!: FormField[];
  buyersAccount_Accessories!: FormField[];
  consultantAccount_NonAccessories!: FormField[];
  consultantAccount_Accessories!: FormField[];
  additionalRemarks!: FormField[];

  searchBooking: FormField[] = [
    {
      type: "text",
      id: "buyerOrderNumber",
      label: "Buyer Order Number",
    },
    {
      type: "text",
      id: "contractNumber",
      label: "Contract Number",
    },
    {
      type: "text",
      id: "chequeNumber",
      label: "Cheque Number",
    },
    {
      type: "text",
      id: "receiptNumber",
      label: "Receipt Number",
    },
    {
      type: "checkbox",
      id: "transferReceipt",
      label: "Transfer Receipt",
    },
  ];
  updateFields(event: any): void {
    switch (event.name) {
      case "searchBooking":
        this.searchBooking = event.fields;
        break;
      case "buyersDetails":
        this.buyersDetails = event.fields;
        break;
      case "buyersDetails2":
        this.buyersDetails2 = event.fields;
        break;
      case "detailsVehicle":
        this.detailsVehicle = event.fields;
        break;
      case "detailsVehicle2":
        this.detailsVehicle = event.fields;
        break;
      case "detailsVehicle3":
        this.detailsVehicle = event.fields;
        break;
      case "detailsCashDiscount":
        this.detailsCashDiscount = event.fields;
        break;
      case "detailsDeposit":
        this.detailsDeposit = event.fields;
        break;
      case "detailsOtherFees":
        this.detailsOtherFees = event.fields;
        break;
      case "detailsInsurance":
        this.detailsInsurance = event.fields;
        break;
      case "detailsHireApplication":
        this.detailsHireApplication = event.fields;
        break;
      case "detailsTradeInVehicle":
        this.detailsTradeInVehicle = event.fields;
        break;
      case "komocosAccount_Promotion":
        this.komocosAccount_Promotion = event.fields;
        break;
      case "komocosAccount_Accessories":
        this.komocosAccount_Accessories = event.fields;
        break;
      case "buyersAccount_NonAccessories":
        this.buyersAccount_NonAccessories = event.fields;
        break;
      case "buyersAccount_Accessories":
        this.buyersAccount_Accessories = event.fields;
        break;
      case "consultantAccount_NonAccessories":
        this.consultantAccount_NonAccessories = event.fields;
        break;
      case "consultantAccount_Accessories":
        this.consultantAccount_Accessories = event.fields;
        break;
      case "additionalRemarks":
        this.additionalRemarks = event.fields;
        break;
    }
  }

  ngOnInit(): void {
    this.buyersDetails = [
      {
        type: "const",
        id: "contractDate",
        label: "Contract Date:",
      },
      {
        type: "const",
        id: "buyerOrderNumber:",
        label: "Buyer's Order Number:",
      },
      {
        type: "const",
        id: "invoiceDate",
        label: "Invoice Date:",
      },
      {
        type: "const",
        id: "invoiceNumber",
        label: "Invoice Number:",
      },
      {
        type: "const",
        id: "nricNoRCBNoPassportNo",
        label: "NRIC No/RCB No/Passport No:",
      },
      {
        type: "const",
        id: "name",
        label: "Name:",
      },
      {
        type: "const",
        id: "mobileNumber",
        label: "Mobile Number:",
      },
      {
        type: "const",
        id: "address",
        label: "Address",
      },
      {
        type: "const",
        id: "salesConsultant",
        label: "Sales Consultant:",
      },
      
    ];
    this.buyersDetails2 = [
      {
        type: "text",
        id: "previousReceiptNumber",
        label: "Previous Receipt Number",
        lock: true,
      },
      {
        type: "date",
        id: "receiptDate",
        label: "Receipt Date (dd/mm/yyyy)",
      },
      {
        type: "date",
        id: "transactionDate",
        label: "Transaction Date (dd/mm/yyyy)",
      },
      {
        type: "select",
        id: "paymentType",
        label: "Payment Type",
        options:[
          { display: "PARF", value: "PARF" },
          { display: "COE Deposit", value: "COE Deposit" },
          { display: "Costumer", value: "Costumer" },
          { display: "Salesman Accessories", value: "Salesman Accessories" },
          { display: "Aditional Road Tax", value: "Aditional Road Tax" },
        ],
      },
      {
        type: "select",
        id: "paymentMode",
        label: "Payment Mode",
        options:[
          { display: "CREDIT CARD", value: "CREDIT CARD" },
          { display: "CHEQUE", value: "CHEQUE" },
          { display: "GIRO", value: "GIRO" },
          { display: "TT", value: "TT" },
          { display: "NETS", value: "NETS" },
          { display: "AMEX", value: "AMEX" },
          { display: "CASH", value: "CASH" },
          { display: "CASHIER'S ORDER", value: "CASHIER'S ORDER" },
          { display: "ONLINE CREDIT CARD", value: "ONLINE CREDIT CARD" },
          { display: "PAYNOW", value: "PAYNOW" },
          { display: "OTHERS", value: "OTHERS" },
        ],
      },
      {
        type: "text",
        id: "issuer",
        label: "Issuer",
      },
      {
        type: "text",
        id: "bank",
        label: "bank",
      },
      {
        type: "text",
        id: "description",
        label: "Description",
      },
      {
        type: "number",
        id: "amount",
        label: "Amount",
      },
      {
        type: "number",
        id: "totalPayment",
        label: "Total Payment",
        lock: true
      },
      {
        type: "select",
        id: "Status",
        label: "Status",
        options:[
          { display: "Banked", value: "Banked" },
          { display: "Re-Banked", value: "Re-Banked" },
          { display: "Clared", value: "Clared" },
          { display: "Cheque Returned", value: "Cheque Returned" },
          { display: "Receipt Cancelled", value: "Receipt Cancelled" },
        ],
      },
    ];
  }
}
