import { Component, OnInit } from "@angular/core";
import { formatDate } from "@angular/common";
import { FormField } from "../../../interfaces/form-field.model";
import { faPrint, faEraser } from "@fortawesome/free-solid-svg-icons";
import { NotifierService } from "angular-notifier";
import { of, switchMap, tap } from "rxjs";
import { PremiumCareMemberStatusService } from "src/app/services/customer-reports/premium-care-member-status.service";

@Component({
  selector: "app-premium-care-member-status",
  templateUrl: "./premium-care-member-status.component.html",
  styleUrls: ["./premium-care-member-status.component.css"],
})
export class PremiumCareMemberStatusComponent implements OnInit {
  faPrint = faPrint;
  faEraser = faEraser;

  activeMemberships: any[] = [];
  form!: FormField[];

  constructor(
    private notifier: NotifierService,
    private PremiumCareMemberStatusService: PremiumCareMemberStatusService,
  ) {}

  updateFields(event: any): void {
    this.form = event.fields;
  }

  ngOnInit() {
    this.fetchFormData().subscribe({
      next: () => this.setUpFormFields(),
      error: (error) =>
        this.handleError("Error initializing data", error),
    });
  }

  private fetchFormData() {
    return of(null).pipe(
      switchMap(() =>
        this.PremiumCareMemberStatusService.GetAllMembershipType().pipe(
          tap((response) =>
            this.processActiveMemberships(response.body),
          ),
        ),
      ),
    );
  }

  private processActiveMemberships(responseBody: any[]): void {
    this.activeMemberships = responseBody.map((model) => ({
      display: model.Name,
      value: model.Value,
      id: model.Id,
    }));
    this.activeMemberships.push({
      display: "All",
      value: "",
      id: "all",
    });
  }

  private setUpFormFields(): void {
    this.form = [
      {
        type: "radio",
        id: "Status",
        label: "Status",
        options: [
          {
            value: "Active",
            display: "Active",
          },
          {
            value: "Inactive",
            display: "Inactive",
          },
        ],
        value: "Active",
      },
      {
        type: "select",
        id: "Type",
        label: "Membership type",
        options: this.activeMemberships,
        value: "",
      },
      {
        type: "date",
        id: "JoinFrom",
        label: "Effective From Date (dd/mm/yyyy)",
      },
      {
        type: "date",
        id: "JoinTo",
        label: "To Date (dd/mm/yyyy)",
      },
      {
        type: "date",
        id: "ExpireFrom",
        label: "Expiry From Date (dd/mm/yyyy)",
      },
      {
        type: "date",
        id: "ExpireTo",
        label: "To Date (dd/mm/yyyy)",
      },
      {
        type: "select",
        id: "Format",
        label: "Select format",
        options: [
          {
            value: "pdf",
            display: "PDF",
          },
          {
            value: "excel",
            display: "EXCEL",
          },
          {
            value: "both",
            display: "BOTH",
          },
        ],
        value: "pdf",
      },
    ];
  }

  clearForm(): void {
    this.form.map((item) => {
      if (item.id == "Status") {
        item.value = "Active";
      } else if (item.id == "Type") {
        item.value = "";
      } else if (item.id == "Format") {
        item.value = "pdf";
      } else {
        item.value = "";
      }
    });
  }

  printStatus(): void {
    const searchData = this.populateSearchBody();
    console.log(searchData);
    this.PremiumCareMemberStatusService.PremiumStatusReport(
      searchData,
    ).subscribe({
      next: (response) => {
        if (this.getFieldValue("Format") == "pdf") {
          window.open(response.body.Name, "_blank");
        } else if (this.getFieldValue("Format") == "excel") {
          window.open(response.body.Value, "_blank");
        } else if (this.getFieldValue("Format") == "both") {
          window.open(response.body.Value, "_blank");
          window.open(response.body.Name, "_blank");
        }
      },
      error: (error) => {
        console.log(error);
        this.handleError("Error getting data", error);
      },
    });
  }

  private populateSearchBody(): any {
    return {
      ExpireFrom:
        !this.getFieldValue("ExpireFrom") ||
        this.getFieldValue("ExpireTo") == ""
          ? ""
          : formatDate(
              this.getFieldValue("ExpireFrom"),
              "dd/MM/yyyy",
              "en-US",
            ),
      ExpireTo:
        !this.getFieldValue("ExpireTo") ||
        this.getFieldValue("ExpireTo") == ""
          ? ""
          : formatDate(
              this.getFieldValue("ExpireTo"),
              "dd/MM/yyyy",
              "en-US",
            ),
      Format: this.getFieldValue("Format"),
      JoinFrom:
        !this.getFieldValue("JoinFrom") ||
        this.getFieldValue("JoinFrom") == ""
          ? ""
          : formatDate(
              this.getFieldValue("JoinFrom"),
              "dd/MM/yyyy",
              "en-US",
            ),
      JoinTo:
        !this.getFieldValue("JoinTo") ||
        this.getFieldValue("JoinTo") == ""
          ? ""
          : formatDate(
              this.getFieldValue("JoinTo"),
              "dd/MM/yyyy",
              "en-US",
            ),
      Status: this.getFieldValue("Status"),
      Type: this.getFieldValue("Type"),
    };
  }

  private getFieldValue(id: string, index?: number): any {
    const field = this.form.find((f) => f.id === id);
    return index !== undefined ? field?.value[index] : field?.value;
  }

  private handleError(message: string, error: any): void {
    console.error(message, error);
    this.notifier.notify("error", message);
  }
}
