<div class="page-container">
    <div class="flex items-center justify-between">
        <h1 class="main-heading">
            Reservation Report
        </h1>
        <app-navpath />
    </div>
    <div class="content-container">
        <app-data-form [dataFields]="reservationForm" />
    </div>
    <div class="content-container">
        <h2 class="sub-heading w-full border-b">Reservation Report</h2>
        <app-data-table
            [tableHeaders]="reservationReportHeaders"
            [tableEntries]="reservationsEmpty"
        />
    </div>
    <div class="content-container">
        <h2 class="sub-heading w-full border-b">Reservation List</h2>
        <app-data-table
            [tableHeaders]="reservationListHeaders"
            [tableEntries]="reservations"
        />
    </div>
</div>