import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class MasterColourService {
  constructor(private http: HttpUtilService) {}

  /**
   * Gets all master colour elements
   * @returns An Observable of the interior colour list
   */
  getAll(): Observable<any> {
    return this.http.get("/ColourMasterService.asmx/GetAll");
  }

  /**
   * Deletes the selected master colours.
   * @param items - The list of master colours to delete.
   * @returns An Observable for the delete operation.
   */
  Delete(items: any[]): Observable<any> {
    return this.http.post("ColourMasterService.asmx/Delete", {
      items,
    });
  }

  /**
   * Gets interior colour detail by ID.
   * @param id - The ID of the interior colour.
   * @returns An Observable of the interior colour details.
   */
  getDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`);
    const url = `/InteriorColourService.asmx/GetDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  /**
   * Saves interior colour details (for both Add and Update).
   * @param data - The interior colour data to save.
   * @returns An Observable for the result of the save operation.
   */
  saveDetails(data: any): Observable<any> {
    return this.http.post("/InteriorColourService.asmx/SaveDetails", {
      data,
    });
  }
}
