<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">GIRO Payment</h1>
      <app-navpath />
    </div>
    <div class="content-container">
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Contract Detail</h2>
        <app-data-form
          [dataFields]="contractDetail"
          [dataName]="'contractDetail'"
        />
      </section>
    </div>
    <div class="content-container">
        <section class="content-container">
          <h2 class="sub-heading w-full border-b">GIRO Detail</h2>
          <app-data-form
            [dataFields]="giroDetail"
            [dataName]="'giroDetail'"
          />
        </section>
        <p>FINAL (EXCESS) / SHORT AFTER CHEQUE ISSUED: $0.00</p>
      </div>
    <div class="mt-4 flex w-fit gap-1">
        <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faPlus" class="mr-1" />Add New Giro
        </button>
        <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faSave" class="mr-1" />Update Giro
        </button>
        <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faTrash" class="mr-1" />Cancel Giro
        </button>
        <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faPrint" class="mr-1" />Print Giro
        </button>
        <button class="shorter-btn gray-btn">
            <fa-icon [icon]="faLink" class="mr-1" />Clear
        </button>
    </div>
  </section>
