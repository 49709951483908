import { Component } from "@angular/core";
import { FormField } from "src/app/interfaces/form-field.model";
import {
  faPlus,
  faMinus,
  faCircle,
  faPencil,
  faTrashCan,
  faTrash,
  faFloppyDisk,
  faCancel,
  faMagnifyingGlass,
  faSearch,
  faTriangleExclamation,
  faEraser,
  faSave,
  faBroom,
  faCar,
} from "@fortawesome/free-solid-svg-icons";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-parf-transfer-date",
  templateUrl: "./parf-transfer-date.component.html",
  styleUrls: ["./parf-transfer-date.component.css"],
})
export class ParfTransferDateComponent {
  faPlus = faPlus;
  faMinus = faMinus;
  faCircle = faCircle;
  faPencil = faPencil;
  faTrashCan = faTrashCan;
  faFloppyDisk = faFloppyDisk;
  faCancel = faCancel;
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faSearch = faSearch;
  faTriangleExclamation = faTriangleExclamation;
  faEraser = faEraser;
  faCar = faCar;
  faSave = faSave;
  faBroom = faBroom;

  buyersDetails!: FormField[];
  buyersDetails2!: FormField[];
  detailsVehicle!: FormField[];
  detailsVehicle2!: FormField[];
  detailsVehicle3!: FormField[];
  detailsCashDiscount!: FormField[];
  detailsDeposit!: FormField[];
  detailsOtherFees!: FormField[];
  detailsInsurance!: FormField[];
  detailsHireApplication!: FormField[];
  detailsTradeInVehicle!: FormField[];
  komocosAccount_Promotion!: FormField[];
  komocosAccount_Accessories!: FormField[];
  buyersAccount_NonAccessories!: FormField[];
  buyersAccount_Accessories!: FormField[];
  consultantAccount_NonAccessories!: FormField[];
  consultantAccount_Accessories!: FormField[];
  additionalRemarks!: FormField[];

  searchBooking: FormField[] = [
    {
      type: "text",
      id: "contractNumber",
      label: "Contract Number",
    },
    {
      type: "date",
      id: "PARFTransferDate",
      label: "PARF Transfer Date (dd/mm/yyyy)",
    },
  ];
  updateFields(event: any): void {
    switch (event.name) {
      case "searchBooking":
        this.searchBooking = event.fields;
        break;
      case "buyersDetails":
        this.buyersDetails = event.fields;
        break;
      case "buyersDetails2":
        this.buyersDetails = event.fields;
        break;
      case "detailsVehicle":
        this.detailsVehicle = event.fields;
        break;
      case "detailsVehicle2":
        this.detailsVehicle = event.fields;
        break;
      case "detailsVehicle3":
        this.detailsVehicle = event.fields;
        break;
      case "detailsCashDiscount":
        this.detailsCashDiscount = event.fields;
        break;
      case "detailsDeposit":
        this.detailsDeposit = event.fields;
        break;
      case "detailsOtherFees":
        this.detailsOtherFees = event.fields;
        break;
      case "detailsInsurance":
        this.detailsInsurance = event.fields;
        break;
      case "detailsHireApplication":
        this.detailsHireApplication = event.fields;
        break;
      case "detailsTradeInVehicle":
        this.detailsTradeInVehicle = event.fields;
        break;
      case "komocosAccount_Promotion":
        this.komocosAccount_Promotion = event.fields;
        break;
      case "komocosAccount_Accessories":
        this.komocosAccount_Accessories = event.fields;
        break;
      case "buyersAccount_NonAccessories":
        this.buyersAccount_NonAccessories = event.fields;
        break;
      case "buyersAccount_Accessories":
        this.buyersAccount_Accessories = event.fields;
        break;
      case "consultantAccount_NonAccessories":
        this.consultantAccount_NonAccessories = event.fields;
        break;
      case "consultantAccount_Accessories":
        this.consultantAccount_Accessories = event.fields;
        break;
      case "additionalRemarks":
        this.additionalRemarks = event.fields;
        break;
    }
  }

  ngOnInit(): void {
    this.buyersDetails = [
      {
        type: "date",
        id: "contractDate",
        label: "Contract Date:",
      },
      {
        type: "number",
        id: "parfValue",
        label: "PARF Value",
      },
      {
        type: "number",
        id: "coeRebateValue",
        label: "COE Rebate Value",
      },
      {
        type: "number",
        id: "totalAmount",
        label: "TTotal PARF Amount",
      },
    ];
    
  }
}
