<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Car registration</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <app-data-form
      [dataFields]="carRegistration"
      [dataName]="'carRegistration'"
      (updateFields_child)="updateFields($event)"
    />
  </section>
  <section class="content-container">
    <h2 class="sub-heading w-full">
      Factory standard equipment & items
    </h2>
    <div class="grid grid-cols-2">
      <div
        *ngFor="
          let item of equipmentItems;
          let index = index;
          trackBy: trackByIndex
        "
        class="flex bg-gray-50 px-4 py-2 text-sm hover:bg-gray-100"
      >
        <p class="flex-1">{{ index + 1 }}.</p>
        <input
          type="text"
          [(ngModel)]="equipmentItems[index]"
          class="text-container flex-2"
        />
      </div>
    </div>
  </section>
  <section class="content-container">
    <h2 class="sub-heading w-full border-b">Vin series checking</h2>
    <app-data-form
      [dataFields]="vinSeriesChecking"
      [dataName]="'vinSeriesChecking'"
      (updateFields_child)="updateFields($event)"
    />
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn green-btn">
        <fa-icon [icon]="faSave" class="mr-1" />Update
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
      <button class="shorter-btn alt-red-btn">
        <fa-icon [icon]="faPrint" class="mr-1" />Print
      </button>
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faSave" class="mr-1" />Full doc submitted
      </button>
    </div>
  </section>
</div>
