import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map, timeout } from "rxjs/operators";
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: "root",
})
export class HttpUtilService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {}

  private readonly TIMEOUT = environment.API_TIMEOUT;
  private readonly BASE_URL = environment.KAPS_API_BACKEND_URL;

  // #region HTTP Methods

  /**
   * Sends a GET request to the specified URL.
   * @param url The endpoint URL.
   * @param options Optional parameters for the request.
   * @param requestTimeout Optional timeout for the request.
   * @returns An Observable of the response.
   */
  get<T>(
    url: string,
    options?: RequestOptions,
    requestTimeout?: number,
  ): Observable<HttpResponse<T>> {
    this.loadingService.startLoading("request");
    const actualTimeout = requestTimeout ?? this.TIMEOUT;
    return this.http
      .get<
        HttpResponse<T>
      >(this.BASE_URL + url, this.massageParam(options))
      .pipe(
        map((response) => this.massageResponse<T>(response)),
        timeout(actualTimeout),
        catchError(this.handleError),
        finalize(() => this.loadingService.stopLoading("request")),
      );
  }

  /**
   * Sends a POST request to the specified URL.
   * @param url The endpoint URL.
   * @param body The body of the request.
   * @param options Optional parameters for the request.
   * @param requestTimeout Optional timeout for the request.
   * @returns An Observable of the response.
   */
  post<T>(
    url: string,
    body: any,
    options?: RequestOptions,
    requestTimeout?: number,
  ): Observable<HttpResponse<T>> {
    this.loadingService.startLoading("request");
    const actualTimeout = requestTimeout ?? this.TIMEOUT;
    return this.http
      .post<
        HttpResponse<T>
      >(this.BASE_URL + url, body, this.massageParam(options))
      .pipe(
        map((response) => this.massageResponse<T>(response)),
        timeout(actualTimeout),
        catchError(this.handleError),
        finalize(() => this.loadingService.stopLoading("request")),
      );
  }

  // #endregion

  // #region Error Handling

  /**
   * Handles HTTP errors.
   * @param error The HTTP error response.
   * @returns An Observable that throws an error.
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error("An error occurred:", error.message);
    return throwError(() => new Error(error.message));
  }

  // #endregion

  // #region Request Massaging

  /**
   * Massages the request parameters.
   * This is a temporary solution until v2 Backend is rolled out.
   * @param options The request options.
   * @returns The massaged request options.
   */
  private massageParam(
    options?: RequestOptions,
  ): RequestOptions | undefined {
    if (options != undefined) {
      if (options.params && typeof options.params === "object") {
        for (const key in options.params) {
          if (options.params.hasOwnProperty(key)) {
            options.params[key] = `'${options.params[key]}'`;
          }
        }
      }
    }
    return options;
  }

  // #endregion

  // #region Response Massaging (Temporary)

  /**
   * Massages the response from the backend.
   * This is a temporary solution until v2 Backend is rolled out.
   * @param response The response from the backend.
   * @returns The massaged response.
   */
  private massageResponse<T>(response: any): HttpResponse<T> {
    let statusCode = 500;
    let message = "Corrupted response";
    let body = {} as T;

    if (response && (response.d || response.Data)) {
      const r = response.Data ? response : response.d;
      statusCode = this.handleStatusCode(r.Code);
      message =
        r.Message ?? this.defaultMessageByStatusCode(statusCode);
      body = response.Data ? response : r.Data ?? response.d ?? {};
    }
    return { statusCode, message, body };
  }

  /**
   * Handles the status code.
   * @param statusCode The status code.
   * @returns The mapped status code.
   */
  private handleStatusCode(statusCode: any): number {
    const statusCodeMap = new Map<any, number>([
      [HttpStatusCode.Ok, 200],
      [0, 200],
      [HttpStatusCode.Created, 201],
      [HttpStatusCode.Accepted, 202],
      [HttpStatusCode.NoContent, 204],
      [HttpStatusCode.BadRequest, 400],
      [HttpStatusCode.Unauthorized, 401],
      [HttpStatusCode.Forbidden, 403],
      [HttpStatusCode.NotFound, 404],
      [HttpStatusCode.InternalServerError, 500],
      [-1, 500],
      [HttpStatusCode.BadGateway, 502],
      [HttpStatusCode.ServiceUnavailable, 503],
    ]);

    return statusCodeMap.get(statusCode) ?? 200; // Default to 500 if status code is unknown
  }

  /**
   * Returns the default message for a given status code.
   * @param statusCode The status code.
   * @returns The default message.
   */
  private defaultMessageByStatusCode(statusCode: number): string {
    const defaultMessageMap = new Map<number, string>([
      [200, "OK"],
      [201, "Created"],
      [202, "Accepted"],
      [204, "No Content"],
      [400, "Bad Request"],
      [401, "Unauthorized"],
      [403, "Forbidden"],
      [404, "Not Found"],
      [500, "Internal Server Error"],
      [502, "Bad Gateway"],
      [503, "Service Unavailable"],
    ]);

    return defaultMessageMap.get(statusCode) ?? "Unknown Error";
  }

  // #endregion

  // #region Utility Methods

  /**
   * Retrieves a nested value from a JSON object using a dot-separated key.
   * @param json The JSON object.
   * @param key The dot-separated key.
   * @returns The value associated with the key, or null if not found.
   */
  getValueFromJson<T>(json: any, key: string): T {
    const keys = key.split(".");
    return keys.reduce((acc, k) => {
      return acc && acc[k] !== undefined ? acc[k] : null;
    }, json);
  }

  // #endregion
}

export interface HttpResponse<T> {
  statusCode: number;
  message: string;
  body: T;
}

interface RequestOptions {
  params?: any;
  headers?: any;
  observe?: any;
}
