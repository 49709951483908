import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class VehicleOverbookReportService {
  constructor(private http: HttpUtilService) {}

  VehicleOverbookReport(): Observable<any> {
    return this.http.post(
      "SalesReportService.asmx/VehicleOverbookReport",
      {},
    );
  }
}
