<div class="navbar-container">
  <button class="ml-4" (click)="switchMenu()">
    <svg
      class="hamburger alt-darker-blue-icon"
      viewBox="0 0 30 25"
      (mouseenter)="switchMid()"
      (mouseleave)="switchMid()"
    >
      <line
        class="hamburger_bar hamburger_bar--top"
        [class.is-opened]="showMenu"
        x1="0"
        y1="50%"
        x2="100%"
        y2="50%"
      />
      <line
        id="midBar"
        class="hamburger_bar hamburger_bar--mid"
        [class.is-opened]="showMenu"
        x1="30%"
        y1="50%"
        x2="100%"
        y2="50%"
      />
      <line
        class="hamburger_bar hamburger_bar--bot"
        [class.is-opened]="showMenu"
        x1="0"
        y1="50%"
        x2="100%"
        y2="50%"
      />
    </svg>
  </button>
  <div class="flex">
    <div
      class="user-element"
      (click)="
        showNotifications = !showNotifications;
        showUserOptions = false
      "
      (click)="$event.stopPropagation()"
    >
      <fa-icon
        [icon]="faBell"
        class="user-link"
        title="Notifications"
      ></fa-icon>
      <div
        class="absolute-box text-primary-darker"
        *ngIf="showNotifications && !showUserOptions"
        (click)="$event.stopPropagation()"
      >
        <p class="block border-b p-2 text-sm font-bold">
          You have 0 new notification/s.
        </p>
        <div class="min-h-48"></div>
        <p class="block p-2 text-center text-xs">Mark all as read</p>
      </div>
    </div>
    <div
      class="user-element h-full gap-3"
      (click)="
        showUserOptions = !showUserOptions; showNotifications = false
      "
      (click)="$event.stopPropagation()"
    >
      <img
        [src]="profilePic"
        alt="user-profile-img"
        class="my-auto h-8 rounded-full shadow-md"
      />
      <span
        class="user-link text-main-gray text-sm font-bold uppercase"
        title="Username"
      >
        {{ userInfo[STORAGE_KEYS.USER_INFO["FULL_NAME"].sessionKey] }}
      </span>
      <div
        class="absolute-box"
        *ngIf="showUserOptions && !showNotifications"
        (click)="$event.stopPropagation()"
      >
        <div class="p-2 pb-10 text-center">
          <img
            [src]="profilePic"
            alt="user-profile-img"
            class="m-auto mb-2 h-20 rounded-full"
          />
          <p class="text-xs uppercase">
            {{
              userInfo[STORAGE_KEYS.USER_INFO["FULL_NAME"].sessionKey]
            }}
          </p>
          <p class="text-xs">Member since</p>
        </div>
        <div class="flex justify-between bg-gray-50 p-2">
          <button class="regular-btn password-btn">
            Change password
          </button>
          <button class="regular-btn sign-out-btn">Sign out</button>
        </div>
      </div>
    </div>
  </div>
</div>
