import { Component, OnInit } from "@angular/core";
import { FormField } from "src/app/interfaces/form-field.model";
import { faSave, faBroom } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-account-adjustment",
  templateUrl: "./account-adjustment.component.html",
  styleUrls: ["./account-adjustment.component.css"],
})
export class AccountAdjustmentComponent implements OnInit {
  faSave = faSave;
  faBroom = faBroom;
  adjustmentForm!: FormField[];

  updateFields(event: any): void {
    this.adjustmentForm = event.fields;
  }

  constructor() {}

  ngOnInit() {
    this.adjustmentForm = [
      {
        type: "text",
        id: "contractNumber",
        label: "Contract number",
        required: true,
      },
      {
        type: "text",
        id: "buyerName",
        label: "Buyer name",
        lock: true,
      },
      {
        type: "text",
        id: "invoiceNumber",
        label: "Invoice number",
        lock: true,
      },
      {
        type: "number",
        id: "balanceAmount",
        label: "Balance amount ($)",
        lock: true,
      },
      {
        type: "number",
        id: "adjustmentAmount",
        label: "Adjustment amount ($)",
        required: true,
      },
      {
        type: "number",
        id: "afterAdjustment",
        label: "Amount after adjustment ($)",
        lock: true,
      },
      {
        type: "select",
        id: "category",
        label: "Category",
      },
      {
        type: "text",
        id: "reasonAdjustment",
        label: "Reason for adjustment",
      },
    ];
  }
}
