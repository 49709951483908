<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Discount</h1>
    <app-navpath></app-navpath>
  </div>
  <div class="content-container">
    <section class="content-container">
      <app-data-form
        [dataFields]="updateDiscounts"
        [dataName]="'updateDiscounts'"
        (clickUpdate)="onUpdateEvent($event)"
      ></app-data-form>
    </section>
    <div class="relative grid grid-cols-2 gap-6">
      <div class="col-span-1">
        <div class="content-container">
          <h2 class="sub-heading w-full border-b">COE Type : Bidding and OWN COE</h2>
          <app-data-table
            [tableHeaders]="tableHeaders"
            [tableEntries]="coe_bidding"
          ></app-data-table>
        </div>
      </div>
      <div class="col-span-1">
        <div class="content-container">
          <h2 class="sub-heading w-full border-b">COE Type : Immediate Discount</h2>
          <app-data-table
            [tableHeaders]="tableHeaders"
            [tableEntries]="immediate"
          ></app-data-table>
        </div>
      </div>
    </div>
  </div>
</section>
