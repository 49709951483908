import {Component, HostListener, OnInit} from "@angular/core";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {UserInfoService} from "./services/user-info.service";
import {STORAGE_KEYS} from "./constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private userInfoService: UserInfoService,
  ) {
  }

  ngOnInit() {
    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop();
    }, 5000);

    // theme changing based on company selected
    this.userInfoService.userInfo$.subscribe(userInfo => {
      const companyCode: string = userInfo[STORAGE_KEYS.USER_INFO['SELECTED_COMPANY']?.sessionKey];
      if (companyCode) {
        document.documentElement.setAttribute('data-theme', companyCode);
      }
    });
  }

  @HostListener("DOMContentLoaded", ["$event"])
  domLoaded(event: any) {
    this.ngxLoader.stop();
  }
}
