<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">User Type Management</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button
      class="shorter-btn add-btn"
      [routerLink]="[
        '../user-type-management',
        getEmptyGuid()
      ]"
    >
      <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add User Type
    </button>
    <app-search-input
      (dataOutput)="onSearch($event)"
      [isTable]="true"
    ></app-search-input>
  </div>
  <div class="content-container">
    <app-data-table
      (edit)="editEntry($event)"
      [tableHeaders]="tableHeaders"
      [tableEntries]="filteredRoles"
    ></app-data-table>
  </div>
</section>
