import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPencil, faCancel, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { PromotionsService } from "src/app/services/vehicle/promotions.service";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid, isEmptyGuid } from "src/app/services/util.service";

@Component({
  selector: "app-promotion-detail",
  templateUrl: "./promotion-detail.component.html",
  styleUrls: ["./promotion-detail.component.css"],
})
export class PromotionDetailComponent implements OnInit {
  faPencil = faPencil;
  faCancel = faCancel;
  faPlus = faPlus;

  mode: string = "Add";
  promotionId: string = getEmptyGuid(); 

  promotionDetail: FormField[] = [
    { type: "text", id: "promotionCode", label: "Promotion Code", required: true, value: "" },
    { type: "text", id: "description", label: "Description", required: true, value: "" },
    { type: "switch", id: "status", label: "Status", value: false },
  ];

  constructor(
    private promotionsService: PromotionsService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.promotionId = params['id'];

      if (!isEmptyGuid(this.promotionId)) {
        this.mode = 'Edit';
        this.loadPromotionDetail();
      }
    });
  }

  private loadPromotionDetail(): void {
    this.promotionsService.getDetail(this.promotionId).subscribe({
      next: (response) => {
        if (response && response.body) {
          const data = response.body;
          this.promotionDetail = [
            { type: "text", id: "promotionCode", label: "Promotion Code", required: true, value: data.PromotionCode || "" },
            { type: "text", id: "description", label: "Description", required: true, value: data.Description || "" },
            { type: "switch", id: "status", label: "Status", value: data.Status || false },
          ];
        } else {
          console.error("Invalid response structure", response);
          this.notifier.notify('error', 'Failed to load promotion details.');
        }
      },
      error: (error: any) => {
        console.error("Error loading promotion detail:", error);
        this.notifier.notify('error', 'Error loading promotion details.');
      }
    });
  }
  
  public onSave(): void {

    const promotionFreeItemId = this.mode === 'Add' 
      ? '00000000-0000-0000-0000-000000000000' 
      : this.promotionId;  
  
    const promotionData = {
      PromotionFreeItemId: promotionFreeItemId,  
      PromotionCode: this.getFieldValue('promotionCode'),
      Description: this.getFieldValue('description'),
      Status: this.getFieldValue('status') === true ? true : false
    };

    this.promotionsService.saveDetails(promotionData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Promotion saved successfully');
        this.router.navigate(['../../promotion-free-item'], { relativeTo: this.route }); 
      },
      error: (error: any) => {
        console.error("Error saving promotion:", error);
        this.notifier.notify('error', 'Failed to save promotion. Please try again.');
      }
    });
  }
  

  private addPromotion(): void {
    const newPromotionData = {
      PromotionFreeItemId: 0,
      PromotionCode: this.getFieldValue('promotionCode'),
      Description: this.getFieldValue('description'),
      Status: this.getFieldValue('status'),
    };

    this.promotionsService.saveDetails(newPromotionData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Promotion added successfully');
        this.router.navigate(['../../promotion-free-item'], { relativeTo: this.route });
      },
      error: (error: any) => {
        console.error("Error adding promotion:", error);
        this.notifier.notify('error', 'Error adding promotion');
      }
    });
  }

  private updatePromotion(): void {
    const updatedPromotionData = {
      PromotionFreeItemId: this.promotionId,
      PromotionCode: this.getFieldValue('promotionCode'),
      Description: this.getFieldValue('description'),
      Status: this.getFieldValue('status'),
    };

    this.promotionsService.saveDetails(updatedPromotionData).subscribe({
      next: () => {
        this.notifier.notify('success', 'Promotion updated successfully');
        this.router.navigate(['../../promotion-free-item'], { relativeTo: this.route });
      },
      error: (error: any) => {
        console.error("Error updating promotion:", error);
        this.notifier.notify('error', 'Error updating promotion');
      }
    });
  }

  private getFieldValue(id: string): any {
    const field = this.promotionDetail.find(f => f.id === id);
    return field?.value || '';
  }

  onCancel(): void {
    this.router.navigate(['../../promotion-free-item'], { relativeTo: this.route });
  }
}
