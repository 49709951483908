import { Component } from '@angular/core';
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-location-master-list',
  templateUrl: './location-master-list.component.html',
  styleUrls: ['./location-master-list.component.css']
})
export class LocationMasterListComponent {

//Icons imports
  faPlus = faPlus;
  faTrash = faTrash;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "LOCATION",
      type: "link",
    },
    {
      title: "CHEQUE FROM",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "CHEQUE TO",
      type: "data",
    },
    {
      title: "CURRENT CHEQUE",
      type: "data",
    }
  ];


  tableEntries = [
    {
      "checked": false,
      "location": "DBSOT",
      "chequeFrom": "00000001",
      "chequeTo": "99999999",
      "currentCheque": "21120041"
    },
    {
      "checked": false,
      "location": "UOB",
      "chequeFrom": "00000001",
      "chequeTo": "99999999",
      "currentCheque": "2"
    },
    {
      "checked": false,
      "location": "MB",
      "chequeFrom": "111538",
      "chequeTo": "117999",
      "currentCheque": "2"
    },
    {
      "checked": false,
      "location": "loc",
      "chequeFrom": "865901",
      "chequeTo": "879999",
      "currentCheque": "823677"
    }
  ]
}
