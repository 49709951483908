import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faSave,
  faTrash,
  faPrint,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-cheque-issue",
  templateUrl: "./cheque-issue.component.html",
  styleUrls: ["./cheque-issue.component.css"],
})
export class ChequeIssueComponent implements OnInit {
  faPlus = faPlus;
  faSave = faSave;
  faTrash = faTrash;
  faPrint = faPrint;
  faBroom = faBroom;

  selectedTab = "with";

  contractDetail!: FormField[];
  chequeDetail!: FormField[];
  chequeDetail__without!: FormField[];
  others!: FormField[];

  constructor() {}

  updateFields(event: any): void {
    switch (event.name) {
      case "contractDetail":
        this.contractDetail = event.fields;
        break;
      case "chequeDetail":
        this.chequeDetail = event.fields;
        break;
      case "chequeDetail__without":
        this.chequeDetail__without = event.fields;
        break;
      case "others":
        this.others[15].value = 0;
        const vaca = this.others
          .filter((x) => x.value)
          .map((x) => Number(x.value))
          .reduce((a, b) => a + b);
        this.others[15].value = vaca;
        this.others = event.fields;
        break;
    }
  }

  ngOnInit() {
    this.contractDetail = [
      {
        type: "text",
        id: "contractNumber",
        label: "Contract number",
      },
      {
        type: "text",
        id: "contractDate",
        label: "Contract date",
        lock: true,
      },
      {
        type: "text",
        id: "buyerOrderNumber",
        label: "Buyer order number",
        lock: true,
      },
      {
        type: "text",
        id: "invoiceNumber",
        label: "Invoice number",
        lock: true,
      },
      {
        type: "text",
        id: "model",
        label: "Model",
        lock: true,
      },
      {
        type: "text",
        id: "registerNumber",
        label: "Register number",
        lock: true,
      },
      {
        type: "text",
        id: "registerDate",
        label: "Register date",
        lock: true,
      },
      {
        type: "text",
        id: "vinNumber",
        label: "Vin number",
        lock: true,
      },
      {
        type: "text",
        id: "buyerName",
        label: "Buyer name",
        lock: true,
      },
      {
        type: "text",
        id: "salesConsultantName",
        label: "Sales consultant name",
        lock: true,
      },
      {
        type: "text",
        id: "salesConsultantCode",
        label: "Sales consultant code",
        lock: true,
      },
      {
        type: "text",
        id: "spotterComissionName",
        label: "Spotter comission name",
        lock: true,
      },
    ];
    this.chequeDetail = [
      {
        type: "radio",
        id: "chequeType",
        options: [
          {
            value: "Buyer",
            display: "Buyer",
          },
          {
            value: "Sales consultant",
            display: "Sales consultant",
          },
          {
            value: "Spotter comission",
            display: "Spotter comission",
          },
          {
            value: "Others",
            display: "Others",
          },
        ],
      },
      {
        type: "select",
        id: "bank",
        label: "Bank",
        required: true,
      },
      {
        type: "date",
        id: "chequeDate",
        label: "Cheque date (dd/mm/yyyy)",
      },
      {
        type: "text",
        id: "address1",
        label: "Address 1",
      },
      {
        type: "text",
        id: "address2",
        label: "Address 2",
      },
      {
        type: "text",
        id: "address3",
        label: "Address 3",
      },
      {
        type: "text",
        id: "postalCode",
        label: "Postal code",
      },
      {
        type: "text",
        id: "payTo",
        label: "Pay to",
        required: true,
      },
      {
        type: "number",
        id: "amount",
        label: "Amount",
        required: true,
      },
      {
        type: "text",
        id: "chequeNumber",
        label: "Cheque number",
      },
      {
        type: "select",
        id: "purpose",
        label: "Purpose",
        required: true,
      },
      {
        type: "text",
        id: "accountCode",
        label: "Account codde",
        lock: true,
      },
    ];
    this.chequeDetail__without = [
      {
        type: "text",
        id: "chequeNumber",
        label: "Cheque number",
      },
      {
        type: "select",
        id: "bank",
        label: "Bank",
      },
      {
        type: "text",
        id: "buyerOrderNumber",
        label: "Buyer order number",
      },
      {
        type: "text",
        id: "referenceCode",
        label: "Reference code",
      },
      {
        type: "date",
        id: "chequeDate",
        label: "Cheque date (dd/mm/yyyy)",
      },
      {
        type: "text",
        id: "payTo",
        label: "Pay to",
      },
      {
        type: "number",
        id: "amount",
        label: "Amount",
      },
      {
        type: "text",
        id: "gst",
        label: "GST",
      },
      {
        type: "select",
        id: "purpose",
        label: "Purpose",
      },
      {
        type: "text",
        id: "accountCode",
        label: "Account code",
        lock: true,
      },
    ];
    this.others = [
      {
        type: "text",
        id: "line1",
        label: "Line 1",
      },
      {
        type: "number",
        id: "amount1",
        label: "Amount",
      },
      {
        type: "text",
        id: "line2",
        label: "Line 2",
      },
      {
        type: "number",
        id: "amount2",
        label: "Amount",
      },
      {
        type: "text",
        id: "line3",
        label: "Line 3",
      },
      {
        type: "number",
        id: "amount3",
        label: "Amount",
      },
      {
        type: "text",
        id: "line4",
        label: "Line 4",
      },
      {
        type: "number",
        id: "amount4",
        label: "Amount",
      },
      {
        type: "text",
        id: "line5",
        label: "Line 5",
      },
      {
        type: "number",
        id: "amount5",
        label: "Amount",
      },
      {
        type: "text",
        id: "line6",
        label: "Line 6",
      },
      {
        type: "number",
        id: "amount6",
        label: "Amount",
      },
      {
        type: "text",
        id: "line7",
        label: "Line 7",
      },
      {
        type: "number",
        id: "amount7",
        label: "Amount",
      },
      {
        type: "const",
        id: "empty",
      },
      {
        type: "number",
        id: "totalAmount",
        label: "Total amount",
      },
    ];
  }
}
