import { Component, OnInit } from "@angular/core";
import { faPencil, faBroom } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-coe-bidding-preparation",
  templateUrl: "./coe-bidding-preparation.component.html",
  styleUrls: ["./coe-bidding-preparation.component.css"],
})
export class CoeBiddingPreparationComponent implements OnInit {
  // ICONS VARIABLES

  faPencil = faPencil;
  faBroom = faBroom;

  type: string = "WCN";
  coeBiddingPreparation!: FormField[];

  updateFields(event: any): void {
    this.coeBiddingPreparation = event.fields;
    /* LOCK FIELDS LOGIC */
    let local_contract = this.coeBiddingPreparation.find(
      (detail) => detail.id == "contractNumber",
    )?.value;
    for (let i = 1; i <= 6; i++) {
      this.coeBiddingPreparation[i].lock = true;
    }
    switch (local_contract) {
      case "WCN":
        this.coeBiddingPreparation[1].lock = false;
        this.coeBiddingPreparation[2].lock = false;
        this.coeBiddingPreparation[3].lock = false;
        break;
      case "NCNBD":
        this.coeBiddingPreparation[4].lock = false;
        this.coeBiddingPreparation[5].lock = false;
        break;
      case "NCNBN":
        this.coeBiddingPreparation[6].lock = false;
        break;
    }
  }

  ngOnInit() {
    this.coeBiddingPreparation = [
      {
        type: "select",
        id: "contractNumber",
        label: "Contract",
        value: "WCN",
        options: [
          {
            display: "With contract number",
            value: "WCN",
          },
          {
            display: "Without contract number (buyer's order date)",
            value: "NCNBD",
          },
          {
            display: "Without contract number (buyer's order number)",
            value: "NCNBN",
          },
        ],
      },
      {
        type: "select",
        id: "arrangeBy",
        label: "Arrange by",
        required: true,
        lock: false,
      },
      {
        type: "number",
        id: "startingContractNumber",
        label: "Starting contract number",
        lock: false,
      },
      {
        type: "number",
        id: "endingContractNumber",
        label: "Ending contract number",
        lock: false,
      },
      {
        type: "date",
        id: "fromBuyersOrderDate",
        label: "From buyer's order date (dd/mm/yyyy)",
        lock: true,
      },
      {
        type: "date",
        id: "toBuyersOrderDate",
        label: "To buyer's order date (dd/mm/yyyy)",
        lock: true,
      },
      {
        type: "number",
        id: "startingBuyersOrderNumber",
        label: "Starting buyer's order number",
        lock: true,
      },
    ];
  }
}
