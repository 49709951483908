import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencil, faBan, faUsers, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormField } from '../../../../interfaces/form-field.model';
import { ColoursByModelService } from 'src/app/services/vehicle/colours-by-model.service';
import { NotifierService } from 'angular-notifier';
import { getEmptyGuid, isEmptyGuid } from '../../../../services/util.service';

@Component({
  selector: 'app-colours-by-model-detail',
  templateUrl: './colours-by-model-detail.component.html',
  styleUrls: ['./colours-by-model-detail.component.css']
})
export class ColoursByModelDetailComponent implements OnInit {
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faUsers = faUsers;

  mode: string = "Add"; 
  colourByModelMasterId: string = getEmptyGuid(); 
  formFields: FormField[] = [];
  modelOptions: any[] = [{ value: '', display: 'Please select' }];
  modelGroupOptions: any[] = [{ value: '', display: 'Please select' }];
  exteriorColourOptions: any[] = [{ value: '', display: 'Please select' }];
  interiorColourOptions: any[] = [{ value: '', display: 'Please select' }];
  selectedModelMasterId: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coloursByModelService: ColoursByModelService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.colourByModelMasterId = params['id'];
      this.loadModelSpecifications();
      this.loadModelGroupList();
      if (!isEmptyGuid(this.colourByModelMasterId)) {
        this.mode = 'Edit';
        this.loadColourByModelDetail();
      } else {
        this.mode = 'Add';
        this.initializeAddForm();
      }
    });
  }

  private initializeAddForm(): void {
    this.formFields = [
      { type: 'select', id: 'model', label: 'Model', value: '', options: this.modelOptions },
      { type: 'text', id: 'modelCode', label: 'Model Code', value: '' },
      { type: 'text', id: 'occNumber', label: 'OCC Number', value: '' },
      { type: 'select', id: 'modelGroup', label: 'Model Group', value: '', options: this.modelGroupOptions },
      { type: 'select', id: 'exteriorColourCode', label: 'Exterior Colour Code', value: '', options: this.exteriorColourOptions },
      { type: 'text', id: 'exteriorColourDesc', label: 'Exterior Colour Desc', value: '' },
      { type: 'text', id: 'exteriorType', label: 'Exterior Type', value: '' },
      { type: 'select', id: 'interiorColourCode', label: 'Interior Colour Code', value: '', options: this.interiorColourOptions },
      { type: 'text', id: 'interiorColourDesc', label: 'Interior Colour Desc', value: '' },
      { type: 'select', id: 'status', label: 'Status', value: 'Active', options: [{ value: 'Active', display: 'Active' }, { value: 'Inactive', display: 'Inactive' }] }
    ];
  }
  private setInitialModelValues(): void {
    if (this.modelOptions.length > 0) {
      const firstModel = this.modelOptions[0]; 
      this.updateFormFieldValue('model', firstModel.value);
      this.updateFormFieldValue('modelCode', firstModel.modelCode);
      this.updateFormFieldValue('occNumber', firstModel.occNumber);
    }
  }

  private setInitialModelGroupValues(): void {
    if (this.modelGroupOptions.length > 0) {
      const firstModelGroup = this.modelGroupOptions[0]; 
      this.updateFormFieldValue('modelGroup', firstModelGroup.value); 
    }
  }

  private loadColourByModelDetail(): void {
    this.coloursByModelService.getDetail(this.colourByModelMasterId).subscribe({
      next: (response) => {
        const data = response.body;
        this.formFields = [
          { type: 'select', id: 'model', label: 'Model', value: data.ModelShortDesc, options: this.modelOptions },
          { type: 'text', id: 'modelCode', label: 'Model Code', value: data.ModelCode },
          { type: 'text', id: 'occNumber', label: 'OCC Number', value: data.OccNo },
          { type: 'select', id: 'modelGroup', label: 'Model Group', value: data.ModelGroup, options: this.modelGroupOptions },
          { type: 'select', id: 'exteriorColourCode', label: 'Exterior Colour Code', value: data.ExtColourCode, options: this.exteriorColourOptions },
          { type: 'text', id: 'exteriorColourDesc', label: 'Exterior Colour Desc', value: data.ExtColourDescription },
          { type: 'text', id: 'exteriorType', label: 'Exterior Type', value: data.ExtType },
          { type: 'select', id: 'interiorColourCode', label: 'Interior Colour Code', value: data.IntColourCode, options: this.interiorColourOptions },
          { type: 'text', id: 'interiorColourDesc', label: 'Interior Colour Desc', value: data.IntColourDescription },
          { type: 'select', id: 'status', label: 'Status', value: data.Status === "True" ? 'Active' : 'Inactive', options: [{ value: 'Active', display: 'Active' }, { value: 'Inactive', display: 'Inactive' }] }
        ];
        if (data.ModelGroup) this.onModelGroupSelected(data.ModelGroup);
        if (data.ExtColourCode) this.onExteriorColourCodeSelected(data.ExtColourCode);
      },
      error: (error: any) => {
        console.error("Error loading colour by model detail:", error);
        this.notifier.notify('error', 'Error loading colour by model detail');
      }
    });
  }

  private loadModelSpecifications(): void {
    this.coloursByModelService.getAllModelSpecifications().subscribe({
      next: (response) => {
        this.modelOptions = [{ value: '', display: 'Please select' }, ...response.body.map((model: any) => ({
          value: model.ModelShortDesc,
          display: model.ModelShortDesc,
          modelCode: model.ModelCode,
          occNumber: model.OccNo,
          ModelMasterId: model.ModelMasterId
        }))];
        if (this.mode === 'Add') {
          this.updateFormFieldOptions('model', this.modelOptions);
        }
      },
      error: (error: any) => {
        console.error("Error loading model specifications:", error);
        this.notifier.notify('error', 'Error loading model specifications');
      }
    });
  }
  private loadModelGroupList(): void {
    this.coloursByModelService.getModelGroupList().subscribe({
      next: (response) => {
        this.modelGroupOptions = [{ value: '', display: 'Please select' }, ...response.body.map((group: any) => ({
          value: group.Value,
          display: group.Name
        }))];
    
        if (this.mode === 'Add') {
          this.updateFormFieldOptions('modelGroup', this.modelGroupOptions);
          this.setInitialModelGroupValues(); 
        }
      },
      error: (error: any) => {
        console.error("Error loading model groups:", error);
        this.notifier.notify('error', 'Error loading model groups');
      }
    });
  }

  onModelChange(selectedModel: string): void {
    const selected = this.modelOptions.find(m => m.value === selectedModel);
    if (selected) {
      this.updateFormFieldValue('modelCode', selected.modelCode);
      this.updateFormFieldValue('occNumber', selected.occNumber);
      this.selectedModelMasterId = selected.ModelMasterId;
    }
  }

  onModelGroupSelected(selectedModelGroup: string): void {
    if (selectedModelGroup) {
      this.coloursByModelService.getColourCodeListByModelGroup(selectedModelGroup).subscribe({
        next: (response) => {
          this.exteriorColourOptions = [{ value: '', display: 'Please select' }, ...response.body.map((colour: any) => ({
            value: colour.ExteriorColour,
            display: colour.ExteriorColour,
            Description: colour.Description,
            Type: colour.Type
          }))];
          this.updateFormFieldOptions('exteriorColourCode', this.exteriorColourOptions);
        },
        error: (error: any) => {
          console.error("Error fetching exterior colour codes:", error);
          this.notifier.notify('error', 'Error fetching exterior colour codes');
        }
      });
    }
  }

  handleExteriorColourChange(event: any): void {
    const selectedColourField = event.fields.find((f: { id: string }) => f.id === 'exteriorColourCode');
    if (selectedColourField) {
      const selectedExteriorColour = selectedColourField.value;
      //console.log("Selected Exterior Colour:", selectedExteriorColour);

      const selectedColourData = this.exteriorColourOptions.find(
        (colour: any) => colour.value === selectedExteriorColour
      );

      if (selectedColourData) {
        //console.log("Description:", selectedColourData.Description);
        //console.log("Type:", selectedColourData.Type);
        this.updateFormFieldValue('exteriorColourDesc', selectedColourData.Description);
        this.updateFormFieldValue('exteriorType', selectedColourData.Type);
      } else {
        //console.log("No matching colour found for the selected Exterior Colour Code.");
      }

      this.onExteriorColourCodeSelected(selectedExteriorColour);
    }
  }

  private onExteriorColourCodeSelected(selectedExteriorColour: string): void {
    const modelGroup = this.getFieldValue('modelGroup');
    if (modelGroup && selectedExteriorColour) {
      const payload = {
        model: {  
          ModelGroup: modelGroup,
          ExteriorColour: selectedExteriorColour
        }
      };

      this.coloursByModelService.getInteriorColourCodeByModelCode(payload).subscribe({
        next: (response) => {
          if (response && Array.isArray(response.body)) {
            this.interiorColourOptions = [{ value: '', display: 'Please select' }, 
              ...response.body
                .filter((item: any) => this.mode === 'Add' ? item.Status : true)
                .map((item: any) => ({
                  value: item.Value,
                  display: item.Value,
                  Name: item.Name
                }))
            ];                  
            this.updateFormFieldOptions('interiorColourCode', this.interiorColourOptions);
            //console.log("Interior Colour Options:", this.interiorColourOptions);
          } else {
            console.error("Unexpected response format:", response);
          }
        },
        error: (error) => {
          console.error("Error fetching interior colour codes:", error);
          this.notifier.notify('error', 'Error fetching interior colour codes');
        }
      });
    }
  }

  private updateFormFieldOptions(fieldId: string, options: any[]): void {
    const field = this.formFields.find(f => f.id === fieldId);
    if (field) {
      field.options = options;
    }
  }

  private updateFormFieldValue(fieldId: string, value: string): void {
    const field = this.formFields.find(f => f.id === fieldId);
    if (field) {
      field.value = value;
    }
  }

  onSave(): void {
    const colourData = {
      ColourByModelMasterId: this.mode === 'Add' ? 0 : this.colourByModelMasterId,
      ModelShortDesc: this.getFieldValue('model'),
      ModelCode: this.getFieldValue('modelCode'),
      OccNo: this.getFieldValue('occNumber'),
      ModelGroup: this.getFieldValue('modelGroup'),
      ExtColourCode: this.getFieldValue('exteriorColourCode'),
      ExtColourDescription: this.getFieldValue('exteriorColourDesc'),
      ExtType: this.getFieldValue('exteriorType'),
      IntColourCode: this.getFieldValue('interiorColourCode'),
      IntColourDescription: this.getFieldValue('interiorColourDesc'),
      Status: this.getFieldValue('status') === 'Active' ? 'True' : 'False',
      Model: this.mode === 'Add' ? '0' : this.selectedModelMasterId
    };
  
    this.coloursByModelService.saveDetails({ data: colourData }).subscribe({
      next: () => {
        this.notifier.notify('success', `Colour by Model ${this.mode === 'Add' ? 'added' : 'updated'} successfully`);
        this.router.navigate(['../../colours-by-model'], { relativeTo: this.route });
      },
      error: (error: any) => {
        console.error(`Error ${this.mode === 'Add' ? 'adding' : 'updating'} colour by model:`, error);
        this.notifier.notify('error', `Error ${this.mode === 'Add' ? 'adding' : 'updating'} colour by model`);
      }
    });
  }
  
  public getFieldValue(id: string): string {
    const field = this.formFields.find(f => f.id === id);
    return field?.value || '';
  }
  onCancel(): void {
    this.router.navigate(['../../colours-by-model'], { relativeTo: this.route });
  }

  handleModelGroupChange(event: any): void {
    const selectedModelGroupField = event.fields.find((f: { id: string }) => f.id === 'modelGroup');
    if (selectedModelGroupField) {
      const selectedModelGroupValue = selectedModelGroupField.value;
      this.onModelGroupSelected(selectedModelGroupValue); 
    }
  }

  handleModelChange(event: any): void {
    const selectedModelField = event.fields.find((f: { id: string }) => f.id === 'model');
    if (selectedModelField) {
      const selectedModelValue = selectedModelField.value;
      this.onModelChange(selectedModelValue);
    }
  }

  handleInteriorColourChange(event: any): void {
    const selectedColourField = event.fields.find((f: { id: string }) => f.id === 'interiorColourCode');
    if (selectedColourField) {
      const selectedInteriorColour = selectedColourField.value;
      //console.log("Selected Interior Colour:", selectedInteriorColour);

      const selectedColourData = this.interiorColourOptions.find(
        (colour: any) => colour.value === selectedInteriorColour
      );

      if (selectedColourData) {
        //console.log("Interior Colour Desc:", selectedColourData.Name);
        this.updateFormFieldValue('interiorColourDesc', selectedColourData.Name);
      } else {
        //console.log("No matching colour found for the selected Interior Colour Code.");
      }
    }
  }
}
