import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class AccessoryService {

  constructor(private http: HttpUtilService) {}

  /**
   * Gets all interior colour elements
   * @returns An Observable of the interior colour list
   */
  getAll(): Observable<any> {
    return this.http.get('/AccessoryManagerService.asmx/GetAll');
  }

  /**
   * Gets interior colour detail by ID.
   * @param id - The ID of the interior colour.
   * @returns An Observable of the interior colour details.
   */
  getDetail(id: string): Observable<any> {
    const encodedId = encodeURIComponent(`"${id}"`); 
    const url = `/AccessoryManagerService.asmx/GetDetail?id=${encodedId}`;
    return this.http.get(url);
  }

  /**
   * Saves interior colour details (for both Add and Update).
   * @param data - The interior colour data to save.
   * @returns An Observable for the result of the save operation.
   */
  saveDetails(data: any): Observable<any> {
    return this.http.post('/AccessoryManagerService.asmx/SaveDetails', { data });
  }

   /**
   * Deletes the selected interior colours.
   * @param items - The list of interior colours to delete.
   * @returns An Observable for the delete operation.
   */
   delete(items: any[]): Observable<any> {
    return this.http.post('/AccessoryManagerService.asmx/Delete', { items });
  }
}
