<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Car allocation</h1>
    <app-navpath />
  </div>
  <ul class="tabs-container">
    <li
      (click)="selectedTab = 'single'"
      [ngClass]="
        selectedTab == 'single' ? 'tab-item_selected' : 'tab-item'
      "
    >
      Single allocation
    </li>
    <li
      (click)="selectedTab = 'all'"
      [ngClass]="
        selectedTab == 'all' ? 'tab-item_selected' : 'tab-item'
      "
    >
      Allocate all
    </li>
  </ul>
  <section
    class="content-container__tabs"
    [hidden]="!(selectedTab == 'single')"
  >
    <div class="content-container">
      <h2 class="sub-heading w-full border-b">
        Search available vehicles
      </h2>
      <app-data-form
        [dataFields]="searchVehicles"
        [dataName]="'searchVehicles'"
        (updateFields_child)="updateFields($event)"
      />
      <app-data-table
        [tableHeaders]="tableSingleAllocation__headers"
        [tableEntries]="tableSingleAllocation__entries"
      ></app-data-table>
    </div>
    <div class="content-container">
      <h2 class="sub-heading w-full border-b">Details of vehicle</h2>
      <app-data-form
        [dataFields]="detailsVehicle"
        [dataName]="'detailsVehicle'"
        (updateFields_child)="updateFields($event)"
      />
    </div>
    <div class="content-container">
      <h2 class="sub-heading w-full border-b">Details of contract</h2>
      <app-data-form
        [dataFields]="detailsContract"
        [dataName]="'detailsContract'"
        (updateFields_child)="updateFields($event)"
      />
    </div>
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn darker-primary-btn">
        <fa-icon [icon]="faPencil" class="mr-1" />Allocate
      </button>
      <button class="shorter-btn red-btn locked-btn" disabled>
        <fa-icon [icon]="faTrash" class="mr-1" />Drop allocation
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </section>
  <section
    class="content-container__tabs"
    [hidden]="!(selectedTab == 'all')"
  >
    <button class="regular-btn green-btn">
      <fa-icon [icon]="faPencil" class="mr-1" />Allocate all
    </button>
    <app-data-table
      class="block mt-4"
      [tableHeaders]="tableAllAllocation__headers"
      [tableEntries]="tableAllAllocation__entries"
    ></app-data-table>
  </section>
</div>
