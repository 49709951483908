import { Component, OnInit } from "@angular/core";
import {
  faPrint,
  faEye,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-cheque-deposit-listing",
  templateUrl: "./cheque-deposit-listing.component.html",
  styleUrls: ["./cheque-deposit-listing.component.css"],
})
export class ChequeDepositListingComponent implements OnInit {
  faPrint = faPrint;
  faEye = faEye;
  faBroom = faBroom;

  chequeDeposit!: FormField[];
  constructor() {}

  updateFields(event: any): void {
    this.chequeDeposit = event.fields;
  }

  ngOnInit() {
    this.chequeDeposit = [
      {
        type: "date",
        id: "receiptDate",
        label: "Receipt date (dd/mm/yyyy)",
      },
      {
        type: "text",
        id: "depositName",
        label: "Deposit's name",
      },
      {
        type: "text",
        id: "depositAccountNumber",
        label: "Deposit's account number",
      },
    ];
  }
}
