import { Component, Input } from "@angular/core";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: "app-la-debuggeadora",
  templateUrl: "./la-debuggeadora.component.html",
  styleUrls: ["./la-debuggeadora.component.css"],
})
export class LaDebuggeadoraComponent {
  selectedTab = "customer";
  @Input() exampleArray_Customer: FormField[] = [
    {
      type: "select",
      id: "paymentType",
      label: "Payment type",
      options: [
        {
          display: "No deposit",
          value: "NO DEPOSIT",
        },
        {
          display: "Cash",
          value: "CASH",
        },
        {
          display: "Cheque",
          value: "CHEQUE",
        },
        {
          display: "Nets",
          value: "NETS",
        },
      ],
    },
    {
      type: "number",
      id: "amount",
      label: "Amount $",
    },
    {
      type: "date",
      id: "depositDate",
      label: "Deposit date",
    },
    {
      type: "select",
      id: "bank",
      label: "Bank",
      show: false,
      options: [
        {
          display: "DBS",
          value: "DBS",
        },
        {
          display: "UOB",
          value: "UOB",
        },
        {
          display: "CITIBANK",
          value: "CITIBANK",
        },
      ],
    },
    {
      type: "number",
      id: "chequeNumber",
      label: "Cheque number",
    },
  ];
  @Input() exampleArray_Client: FormField[] = [
    {
      type: "select",
      id: "pichulinaType",
      label: "Pichulina",
      options: [
        {
          display: "No deposit",
          value: "NO DEPOSIT",
        },
        {
          display: "Cash",
          value: "CASH",
        },
        {
          display: "Cheque",
          value: "CHEQUE",
        },
        {
          display: "Nets",
          value: "NETS",
        },
      ],
    },
    {
      type: "number",
      id: "amount",
      label: "Amount $",
    },
    {
      type: "date",
      id: "depositDate",
      label: "Deposit date",
    },
    {
      type: "select",
      id: "bank",
      label: "Bank",
      show: false,
      options: [
        {
          display: "DBS",
          value: "DBS",
        },
        {
          display: "UOB",
          value: "UOB",
        },
        {
          display: "CITIBANK",
          value: "CITIBANK",
        },
      ],
    },
    {
      type: "number",
      id: "chequeNumber",
      label: "Cheque number",
    },
  ];

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "ACCESSORY CODE",
      type: "link",
    },
    {
      title: "DESCRIPTION",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "COST PRICE",
      type: "data",
    },
    {
      title: "NETT PRICE",
      type: "data",
    },
    {
      title: "RETAIL PRICE",
      type: "data",
    },
    {
      title: "SALESMAN PRICE",
      type: "data",
    },
    {
      title: "CLASSIFICATION",
      type: "data",
    },
    {
      title: "FITTED",
      type: "data",
    },
    {
      title: "STATUS",
      type: "boolean",
      messages: ["Active", "Inactive"],
    },
  ];

  accessories = [
    {
      checked: false,
      accessoryCode: "$1899 ZIPI DESIGN",
      description:
        "$1899 ZIPI DESIGN,S/G LL,FRONT/FM, CR MOLDG, W/VISOR,DR CAT",
      costPrice: 0,
      nettPrice: 0,
      retailPrice: 1899,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "$699 GETZ PKG 11/08",
      description: "L/S,REVERSE SENSOR, METALGRAIN",
      costPrice: 0,
      nettPrice: 0,
      retailPrice: 699,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "07030/35-38000",
      description: "Weathershield LH/RH - SONATA",
      costPrice: 124.18,
      nettPrice: 0,
      retailPrice: 130.39,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "07030/35-3A000",
      description: "WEATHERSHIELD LH/RH - TRAJET",
      costPrice: 140.62,
      nettPrice: 0,
      retailPrice: 147.65,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "07036-38000",
      description: "Slimline Weathershield - SONATA",
      costPrice: 45.82,
      nettPrice: 0,
      retailPrice: 48.11,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "08000-1C5xx",
      description: "Getz Mini Car 1:32",
      costPrice: 14.91,
      nettPrice: 0,
      retailPrice: 15.66,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "08000-260xx",
      description: "Santa Fe Mini Car 1:32",
      costPrice: 17.15,
      nettPrice: 0,
      retailPrice: 18.01,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "08000-2C0xx",
      description: "Coupe Mini Car 1:32",
      costPrice: 14.91,
      nettPrice: 0,
      retailPrice: 15.66,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "08030/35-M-1410",
      description: "SUN VISOR WITH MIRROR (L/R) -ACCENT",
      costPrice: 0,
      nettPrice: 0,
      retailPrice: 0,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "08038-M4000",
      description: "Weathershield (Std) - MATRIX",
      costPrice: 90.59,
      nettPrice: 0,
      retailPrice: 95.11,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
    {
      checked: false,
      accessoryCode: "08040-260xx",
      description: "Santa Fe Collectable Car 1:16",
      costPrice: 40.03,
      nettPrice: 0,
      retailPrice: 44.14,
      salesmanPrice: 0,
      classification: "E",
      fitted: "L",
      status: false,
    },
  ];

  updateFields(event: any): void {
    if (event.name == "exampleArray_Customer") {
      this.exampleArray_Customer = event.fields;
      if (this.exampleArray_Customer[0].value == "CHEQUE") {
        this.exampleArray_Customer[3].show = true;
      } else {
        this.exampleArray_Customer[3].show = false;
      }
    }
  }
}
