import { Component } from '@angular/core';
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-coe-bid-details-by-date-report',
  templateUrl: './coe-bid-details-by-date-report.component.html',
  styleUrls: ['./coe-bid-details-by-date-report.component.css']
})
export class CoeBidDetailsByDateReportComponent {

// ICONS VARIABLES
faPrint = faPrint;

reportFields: FormField[] = [
  {
    type: "radio",
    id: "reportType",
    required: true,
    options: [
      {
        display: "Actual",
        value: "actual",
      },
      {
        display: "Less Taken By",
        value: "lessTakenBy",
      },
    ],
  },
  {
    type: "select",
    id: "bidFor",
    label: "Bid For",
    options: [
      {
        display: "K",
        value: "K",
      },
      {
        display: "C",
        value: "C",
      },
      {
        display: "P",
        value: "P",
      },
    ],
  },
  {
    type: "date",
    id: "stockAt",
    label: "Stock At (dd/mm/yyyy)",
    required: true,
  },
  {
    type: "select",
    id: "selectFormat",
    label: "Select Format",
    options: [
      {
        display: "PDF",
        value: "Pdf",
      },
      {
        display: "EXCEL",
        value: "Excel",
      },
      {
        display: "BOTH",
        value: "Both",
      },
    ],
  },
]


}
