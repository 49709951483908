<div *ngIf="message" class="fixed bottom-4 right-4 z-100">
  <div id="alertBox" [ngClass]="{
    'flex items-center p-4 mb-4 text-sm rounded-lg': true,
    'text-red-700 bg-red-100 dark:bg-red-200 dark:text-red-800': message.type === messageType.Error,
    'text-green-700 bg-green-100 dark:bg-green-200 dark:text-green-800': message.type === messageType.Success,
    'text-yellow-700 bg-yellow-100 dark:bg-yellow-200 dark:text-yellow-800': message.type === messageType.Warning
  }" role="alert">
    <fa-icon icon="info-circle"></fa-icon>
    <span class="sr-only">Info</span>
    <div>
      <span class="ms-3 font-bold">{{message.type | titlecase}}:</span> {{message.text}}
    </div>
    <button id="closeInfoBtn" class="ml-2" (click)="hideAlert()">
      <fa-icon icon="times"></fa-icon>
      <span class="sr-only">Close</span>
    </button>
  </div>
</div>
