import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
} from "@angular/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-search-input",
  templateUrl: "./search-input.component.html",
  styleUrls: ["./search-input.component.css"],
})
export class SearchInputComponent implements OnInit {
  @Input("dataInput") dataInput: string | undefined;
  @Input("textOptions") textOptions: {
    value: string;
    display: string;
  }[] = [];
  @Input("required") required: boolean = false;
  @Input("lock") lock: boolean = false;
  @Input("isTable") isTable: boolean = false;
  @Input("isButtonActive") isButtonActive: boolean = false;
  @Input("placeHolder") placeHolder: string = "Search...";

  @Output() dataOutput = new EventEmitter<string>();
  @Output() clickSearch = new EventEmitter<Event>();

  filteredOptions: { value: string; display: string }[] = [];
  searchControl = new FormControl("");
  showFiltered: boolean = false;
  faSearch = faSearch;

  @HostListener("document:click", ["$event"]) onDocumentClick() {
    this.showFiltered = false;
  }

  ngOnInit(): void {
    if (this.lock) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataInput != undefined) {
      this.searchControl.setValue(this.dataInput);
    }
    if (changes["lock"]) {
      if (this.lock) {
        this.searchControl.disable();
      } else {
        this.searchControl.enable();
      }
    }
  }

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        this.filterItems(value);
        if (value) {
          this.showFiltered = true;
        }
      });
  }

  filterItems(searchText: string | null): void {
    if (!searchText) {
      this.filteredOptions = [];
      this.showFiltered = false;
    } else {
      this.filteredOptions = this.textOptions.filter((item) =>
        item.value.toLowerCase().includes(searchText.toLowerCase()),
      );
      this.showFiltered = this.filteredOptions.length > 0;
    }
  }

  selectItem(item: string): void {
    this.searchControl.setValue(item);
    this.dataOutput.emit(item);
    this.filteredOptions = [];
    this.showFiltered = false;
  }

  updateFormControl(event: any): void {
    const value = event.target.value;
    this.searchControl.setValue(value);
    this.dataOutput.emit(value);
  }

  search(event: any): void {
    if (this.isButtonActive) {
      this.clickSearch.emit(event);
    }
  }
}
