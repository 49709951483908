import {Injectable} from '@angular/core';
import {HomeService} from '../services/home/home.service';
import {REQUIRED_KEYS, STORAGE_KEYS} from '../constants';
import {HttpUtilService} from '../services/http-util.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UserInfoService} from "../services/user-info.service";
import {SessionStorageService} from "../services/session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private homeService: HomeService,
    private userInfoService: UserInfoService,
    private sessionStorageService: SessionStorageService,
    private httpUtilService: HttpUtilService
  ) {
  }

  /**
   * Checks if the user is logged in by verifying the presence of a specific cookie.
   * @returns An Observable<boolean> indicating if the user is logged in.
   */
  isLoggedIn(): Observable<boolean> {
    const isUserLoggedIn = document.cookie.split(';').some(item => item.trim().startsWith('.ASPXAUTH='));

    if (isUserLoggedIn) {
      const allKeyPresent = REQUIRED_KEYS.USER_INFO.every(key => this.sessionStorageService.getItem(key));

      if (!allKeyPresent) {
        return this.updateSessionStorageIfNeeded().pipe(
          catchError(error => {
            console.error('Error updating session storage:', error);
            return of(false);
          })
        );
      }
      this.userInfoService.updateUserInfo();
      return of(true);
    }
    return of(false);
  }

  /**
   * Checks if the required user information is present in session storage.
   * If not, it fetches the information from the server and updates session storage.
   * @returns An Observable<boolean> indicating if the required user information is present in session storage.
   */
  private updateSessionStorageIfNeeded(): Observable<boolean> {
    return this.homeService.getLoginUser().pipe(
      map(response => {
        if (response.body) {
          const userInfo = response.body;
          const sessionKeys = STORAGE_KEYS.USER_INFO;
          let allKeysAdded = true;

          Object.keys(sessionKeys).forEach(key => {
            const {sessionKey, responseKey} = sessionKeys[key as keyof typeof sessionKeys];
            try {
              const value = this.httpUtilService.getValueFromJson(userInfo, responseKey);
              if (value) {
                this.userInfoService.setUserInfo(sessionKey, value);
              } else {
                console.warn(`Value for key ${responseKey} not found in response.`);
                allKeysAdded = false;
              }
            } catch (error) {
              console.error(`Error setting session storage for key ${sessionKey}:`, error);
              allKeysAdded = false;
            }
          });
          return allKeysAdded;
        } else {
          console.error('Invalid response received from getLoginUser.');
          return false;
        }
      }),
      catchError(error => {
        console.error('Error fetching user login info:', error);
        return of(false);
      })
    );
  }
}
