import { Component, OnInit } from "@angular/core";
import { faPlus, faTrash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { HirePurchaseService } from "src/app/services/sales/hire-purchase.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: "app-hire-purchase",
  templateUrl: "./hire-purchase-list.component.html",
  styleUrls: ["./hire-purchase-list.component.css"],
})
export class HirePurchaseListComponent implements OnInit {

  searchInput: string = "";
  filteredFinanceCompanies: any[] = [];
  financeCompanies: any[] = [];

  // Icons imports
  faPlus = faPlus;
  faTrash = faTrash;
  faSearch = faSearch;

  tableHeaders = [
    { title: "", type: "checkbox" },
    { title: "FINANCE COMPANY", type: "link", id: "AcCode" },
    { title: "DESCRIPTION", type: "data", id: "Acname", classes: "max-w-64" },
    { title: "ADDRESS", type: "data", id: "Address1" },
    { title: "CONTACT PERSON", type: "data", id: "ContactPerson" },
    { title: "CONTACT NUMBER", type: "data", id: "ContactNumber" },
    { title: "ID", type: "hidden", id: "InvAccountId" },
  ];

  constructor(
    private HirePurchaseService: HirePurchaseService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.HirePurchaseService.getAll().subscribe(
      (response) => {
        if (response && Array.isArray(response.body)) { 
          this.financeCompanies = response.body.map((company: any) => ({
            checked: false,
            AcCode: company.AcCode || "",
            Acname: company.Acname || "",
            Address1: company.Address1 || "",
            ContactPerson: company.ContactPerson || "",
            ContactNumber: company.ContactNumber || 0,
            InvAccountId: company.InvAccountId || "",
          }));
          this.filteredFinanceCompanies = [...this.financeCompanies];
        } else {
          console.error("Error: Incorrect data.");
        }
      },
      (error) => {
        console.error("Error getting data.", error);
      }
    );
  }
  

  openEntry(value: any): void {
    this.router.navigate(["../hire-purchase", value.InvAccountId], {
      relativeTo: this.route,
    });
  }

  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(["../hire-purchase", emptyGuid], {
      relativeTo: this.route,
    });
  }

  deleteSelectedEntries(): void {
    const selectedItems = this.filteredFinanceCompanies.filter(item => item.checked);

    if (selectedItems.length === 0) {
      this.notifier.notify("error", "Please select at least one item to delete.");
      return;
    }

    this.HirePurchaseService.delete(selectedItems).subscribe({
      next: () => {
        this.notifier.notify("success", "Selected items deleted successfully");
        this.filteredFinanceCompanies = this.filteredFinanceCompanies.filter(item => !item.checked);
      },
      error: (error) => {
        console.error("Error deleting finance companies:", error);
        this.notifier.notify("error", "Error deleting selected items.");
      }
    });
  }

  onSearch(searchValue: string): void {
    this.filteredFinanceCompanies = this.financeCompanies.filter(item =>
      item.AcCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.Acname.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.ContactPerson?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
}
