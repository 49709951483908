import {Component, OnInit} from '@angular/core';
import {AlertService, Message, MessageType} from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  message: Message | null = null;
  messageType = MessageType;

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.alertService.$message.subscribe(message => {
      this.message = message;
    });
  }

  hideAlert(): void {
    this.alertService.clearMessage();
  }
}
