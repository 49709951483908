<div
  class="page-container"
  [class]="{ 'overflow-y-hidden': showMenu }"
>
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Sales Team Shuffle</h1>
    <app-navpath></app-navpath>
  </div>
  <div class="mb-2 flex w-full justify-between">
    <div class="flex gap-1">
      <button class="shorter-btn save-btn" (click)="updateChanges()">
        <fa-icon [icon]="faEdit" class="mr-1"></fa-icon>Update
      </button>
      <app-search-input
        (dataOutput)="onSearch($event)"
        [isTable]="true"
      ></app-search-input>
    </div>
    <button class="shorter-btn misc-btn" (click)="openList()">
      <fa-icon [icon]="faListAlt" class="mr-1"></fa-icon>Sales Team
    </button>
  </div>
  <section class="content-container">
    <table class="w-full max-w-full text-sm">
      <colgroup>
        <col
          *ngFor="let header of tableHeaders"
          [ngClass]="header.width"
        />
      </colgroup>
      <tbody>
        <tr class="table-header">
          <th *ngFor="let header of tableHeaders">
            <p [ngClass]="header.classes">{{ header.title }}</p>
          </th>
        </tr>
        <tr
          *ngFor="
            let entry of filteredUsers.slice(
              pageSize * pageIndex,
              pageTop
            );
            let index = index
          "
        >
          <td
            *ngFor="
              let data of entry | keyvalue: returnzero;
              let subindex = index
            "
          >
            <p
              *ngIf="tableHeaders[subindex].type == 'data'"
              [ngClass]="tableHeaders[subindex].classes"
            >
              {{ data.value }}
            </p>
            <app-select-input
              *ngIf="tableHeaders[subindex].type == 'select'"
              [dataInput]="users_info[index].team"
              (dataOutput)="assignValue($event, index)"
              [selectOptions]="selectOptions"
              [id]="users_info[index].name"
              class="flex-grow"
            ></app-select-input>
          </td>
        </tr>
      </tbody>
    </table>
    <mat-paginator
      #paginator
      class="mt-4"
      (page)="handlePageEvent($event)"
      [length]="filteredUsers.length"
      [pageSize]="pageSize"
      [showFirstLastButtons]="true"
      [pageSizeOptions]="pageSizeOptions"
    ></mat-paginator>
  </section>
</div>

<div class="bg-menu" *ngIf="showMenu">
  <div class="container-menu">
    <div class="flex justify-between">
      <h2 class="sub-heading">Manage Salesman Team</h2>
      <fa-icon
        [icon]="faClose"
        class="cursor-pointer text-xl text-primary-lighter"
        (click)="showMenu = false"
      ></fa-icon>
    </div>
    <div *ngIf="!showTeam">
      <div class="mb-4 flex gap-1">
        <button class="shorter-btn add-btn" (click)="addEntry()">
          <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
        </button>
        <button
          class="shorter-btn delete-btn"
          (click)="deleteSelectedTeams()"
        >
          <fa-icon [icon]="faTrash" class="mr-1"></fa-icon>Delete
        </button>
      </div>
      <app-data-table
        [tableHeaders]="teamHeaders"
        [tableEntries]="teamEntries"
        [pagination]="false"
        (open)="openEntry($event)"
        (check)="checkEntry($event)"
      ></app-data-table>
    </div>
    <div *ngIf="showTeam" class="flex flex-col gap-2">
      <label class="flex w-full items-center">
        <p
          class="w-1/3 text-sm font-bold"
          [class]="{ 'text-red-warning': teamData.name == '' }"
        >
          Name
        </p>
        <app-text-input
          [dataType]="'text'"
          [dataInput]="teamData.name"
          (dataOutput)="assignTeam({ name: $event })"
          [required]="true"
          class="w-2/3 flex-grow"
        ></app-text-input>
      </label>
      <label class="flex w-full items-center">
        <p class="w-1/3 text-sm font-bold">Description</p>
        <app-text-input
          [dataType]="'text'"
          [dataInput]="teamData.description"
          (dataOutput)="assignTeam({ description: $event })"
          class="w-2/3 flex-grow"
        ></app-text-input>
      </label>
      <div class="mt-3 flex justify-end gap-1">
        <button
          class="shorter-btn save-btn"
          [disabled]="isSaveDisabled()"
          (click)="saveTeam()"
        >
          <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Save
        </button>
        <button
          class="shorter-btn misc-btn"
          (click)="showTeam = false"
        >
          <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Back to list
        </button>
      </div>
    </div>
  </div>
</div>
