import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faBan, faPencil, faPlus, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { UserTypeManagementService } from "src/app/services/administration/use-type-management.service";
import { NotifierService } from "angular-notifier";
import { of, switchMap, tap } from "rxjs";
import { RoleRequest, RoleRight } from "../../../../models/administration/user-type-management/role-right.model";
import { isEmptyGuid } from "../../../../services/util.service";
import { map } from "rxjs/operators";

@Component({
  selector: "app-user-type-management-detail",
  templateUrl: "./user-type-management-detail.component.html",
  styleUrls: ["./user-type-management-detail.component.css"],
})
export class UserTypeManagementDetailComponent implements OnInit {
  faPencil = faPencil;
  faBan = faBan;
  faSquareCheck = faSquareCheck;
  faSquare = faSquare;
  faPlus = faPlus;

  roleId: string = "";
  mode: string = 'Add';
  types_select: RoleRight[] = [];
  formFields: FormField[] = [
    { type: "text", id: "roleName", label: "Role Name", required: true },
    { type: "text", id: "roleDescription", label: "Description", required: true },
    { type: "formCheckboxSingle", id: "accessAllData", label: "Access All Data", value: false },
    { type: "formCheckboxSingle", id: "viewAllMenu", label: "View All Menu", value: false },
    { type: "formCheckboxSingle", id: "viewBirthdayOnDashBoard", label: "Dashboard - Upcoming Months Buyer's Birthday Info", value: false },
    { type: "formCheckboxSingle", id: "viewBuyerOrderReportOnDashBoard", label: "Dashboard - Buyers' Orders Report", value: false },
    { type: "formCheckboxSingle", id: "allowWithoutMinimumDeposit", label: "Allow Without Minimum Deposit", value: false },
    { type: "formCheckboxSingle", id: "viewCampaignLink", label: "Dashboard - Campaign Link", value: false },
  ];
  tableHeaders = [
    { title: "", type: "checkbox" },
    { title: "SCREEN ID", type: "data", classes: "max-w-64" },
    { title: "SCREEN NAME", type: "data" },
    { title: "RIGHTS ADD", type: "checkbox" },
    { title: "RIGHTS UPDATE", type: "checkbox" },
    { title: "RIGHTS DELETE", type: "checkbox" },
    { title: "RIGHTS PRINT", type: "checkbox" },
    { title: "RIGHTS VIEW", type: "checkbox" },
  ];

  constructor(
    private userTypeManagementService: UserTypeManagementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifier: NotifierService,
  ) {}

  ngOnInit(): void {
    this.setModeAndRoleId();
    this.fetchRoleRights().subscribe({
      next: (response) => this.processResponse(response),
      error: (error) => {
        this.notifier.notify("error", "Error getting user types");
        console.error("Error getting active roles:", error);
      }
    });
  }

  updateFields(event: any): void {
    this.formFields = event.fields;
  }

  checkEntry(event: any): void {
    this.types_select = event;
  }

  updateRole(): void {
    const role = this.populateRoleRequestBody();
    const updateFunction = this.mode === "Add" ? this.userTypeManagementService.addRole : this.userTypeManagementService.updateRole;
    updateFunction(role).subscribe({
      next: () => {
        this.notifier.notify("success", this.mode === "Add" ? "User type added" : "User type updated");
        this.router.navigate(["../../user-type-management-list"], { relativeTo: this.activatedRoute });
      },
      error: (error) => {
        this.notifier.notify("error", this.mode === "Add" ? "Error adding user type" : "Error updating user type");
        console.error("Error adding new role:", error);
      }
    });
  }

  checkAndUncheckAll(checked: boolean): void {
    this.types_select.forEach((type) => {
      type.IsAppear = checked;
      type.IsAddAllowed = checked;
      type.IsUpdateAllowed = checked;
      type.IsDeleteAllowed = checked;
      type.IsPrintAllowed = checked;
      type.IsViewAllowed = checked;
    });
  }

  private setModeAndRoleId(): void {
    this.activatedRoute.params.pipe(
      tap(data => {
        this.roleId = data["id"];
        if (!isEmptyGuid(this.roleId)) {
          this.mode = "Detail";
        }
      })
    ).subscribe();
  }

  private fetchRoleRights() {
    return this.activatedRoute.params.pipe(
      switchMap(() => {
        if (this.mode === 'Add') {
          return this.userTypeManagementService.getRoleRights().pipe(
            map(response => ({
              roleRight: response.body,
              specialRole: {}
            }))
          );
        } else if (this.mode === 'Detail') {
          return this.userTypeManagementService.getRoleRightsById(this.roleId).pipe(
            map(response => ({
              roleRight: response.body.CrmRoleRight,
              specialRole: response.body.CrmSpecialRole
            }))
          );
        }
        return of({ roleRight: [], specialRole: {} });
      })
    );
  }

  private processResponse(response: any): void {
    this.types_select = response.roleRight.map((type: any) => ({
      IsAppear: type.IsAppear || false,
      MenuId: type.MenuId || "",
      MenuName: type.MenuName || false,
      IsAddAllowed: type.IsAddAllowed || false,
      IsUpdateAllowed: type.IsUpdateAllowed || false,
      IsDeleteAllowed: type.IsDeleteAllowed || false,
      IsPrintAllowed: type.IsPrintAllowed || false,
      IsViewAllowed: type.IsViewAllowed || false,
    }));

    this.formFields = this.formFields.map(field => {
      field.value = Object.entries(response.specialRole).find(
        ([key]) => key.toLowerCase() === field.id.toLowerCase()
      )?.[1] || field.value;
      return field;
    });
  }

  private populateRoleRequestBody(): RoleRequest {
    const specialRoleMap = new Map<string, any>();

    this.formFields.forEach(field => {
      let key = field.id.charAt(0).toUpperCase() + field.id.slice(1);
      if (key === 'RoleDescription') key = 'Description';
      specialRoleMap.set(key, field.value);
    });

    specialRoleMap.set("RightsList", this.types_select);

    return Object.fromEntries(specialRoleMap) as RoleRequest;
  }
}
