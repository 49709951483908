import { Component } from '@angular/core';
import { faSave, faPrint, faLink } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-cards-deposit-listing',
  templateUrl: './cards-deposit-listing.component.html',
  styleUrls: ['./cards-deposit-listing.component.css']
})
export class CardsDepositListingComponent {

  constructor() {}

  ngOnInit() {}
  
  // ICONS VARIABLES

  faSave = faSave;
  faPrint = faPrint;
  faLink = faLink;

  showReceiptTable: boolean = false;

  cardsForm: FormField[] = [
    {
      type: "date",
      id: "receiptDate",
      label: "Receipt Date (dd/mm/yyyy)",
    },
    {
      type: "text",
      id: "depositsName",
      label: "Deposit's Name",
    },
    {
      type: "text",
      id: "depositsAccountNumber",
      label: "Deposit's Account Number",
    },
    {
      type: "const",
      id: "totalReceiptAmount",
      label: "Total Receipt Amount",
      value: "",
    },
  ];

  listing_headers = [
    {
      title: "FINANCE COMPANY",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "TERMINAL NO",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BATCH NO",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "INVOICE NO",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "OR NUMBER",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "CONTRACT NUMBER",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "SALES CONSULTANT",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "RECEIPT AMOUNT",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "BANK IN",
      type: "checkbox",
    },
  ];

  listing = [
    {
      "financeCompany": "MC",
      "terminalNo": "",
      "batchNo": "",
      "invoiceNo": "",
      "orNumber": "13328",
      "contractNumber": "18020260",
      "salesConsultant": "SEK",
      "receiptAmount": 590.23,
      "bankIn": false
    },
    {
      "financeCompany": "MC",
      "terminalNo": "",
      "batchNo": "",
      "invoiceNo": "",
      "orNumber": "13339",
      "contractId": "18020269",
      "salesConsultant": "SEK",
      "receiptAmount": 1976.00,
      "bankIn": false
    },
    {
      "financeCompany": "NETS",
      "terminalNo": "",
      "batchNo": "",
      "invoiceNo": "",
      "orNumber": "13340",
      "contractId": "18020269",
      "salesConsultant": "SEK",
      "receiptAmount": 0.25,
      "bankIn": false
    },
    {
      "financeCompany": "MC",
      "terminalNo": "",
      "batchNo": "",
      "invoiceNo": "",
      "orNumber": "13344",
      "contractId": "18040533",
      "salesConsultant": "SEK",
      "receiptAmount": 590.23,
      "bankIn": false
    }
  ];

  showReceiptAndUpdateTotal() {
    this.showReceiptTable = true;
    const totalAmountField = this.cardsForm.find(field => field.id === 'totalReceiptAmount');
    if (totalAmountField) {
      totalAmountField.value = "6";
    }
  }
}
