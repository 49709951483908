import { Component, OnInit } from '@angular/core';
import { FactoryStandardEquipmentAndItemsService } from 'src/app/services/sales/factory-standard-equipment-and-items.service';
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FormField } from 'src/app/interfaces/form-field.model';

@Component({
  selector: 'app-factory-standard-equipment-and-items',
  templateUrl: './factory-standard-equipment-and-items.component.html',
  styleUrls: ['./factory-standard-equipment-and-items.component.css']
})
export class FactoryStandardEquipmentAndItemsComponent implements OnInit {
  
  faSave = faSave;

  categoryField: FormField[] = [
    {
      type: 'select',
      id: 'category',
      label: 'Category',
      options: [],
      required: true,
      value: 'A'
    }
  ];

  itemsFields: FormField[] = Array.from({ length: 12 }, (_, i) => ({
    type: 'text',
    id: `DelCerDesc${i + 1}`,
    label: `${i + 1}.`,
    value: ''
  }));

  categories: any[] = [];
  selectedCategory: string = 'A';
  deliveryItems: any = {};
  currentId: string = '';

  constructor(
    private factoryService: FactoryStandardEquipmentAndItemsService
  ) {}

  ngOnInit(): void {
    this.getAllCategories();
    this.getDeliveryCerByCategory(this.selectedCategory);
  }

  getAllCategories(): void {
    this.factoryService.getAllCategory().subscribe({
      next: (response) => {
        console.log('Categories response:', response);
        if (response && response.body) {
          this.categories = response.body;
          this.categoryField[0].options = this.categories.map((cat: any) => ({
            value: cat.Value,
            display: cat.Name
          }));
        }
      },
      error: (error) => {
        console.error('Error fetching categories:', error);
      }
    });
  }

  getDeliveryCerByCategory(category: string): void {
    this.factoryService.getDeliveryCerByCategory(category).subscribe({
      next: (response) => {
        console.log(`Delivery Items for Category ${category}:`, response);
        if (response && response.body) {
          this.deliveryItems = response.body;
          this.currentId = response.body.Id;
          this.populateItemsFields();
        }
      },
      error: (error) => {
        console.error('Error fetching delivery items:', error);
      }
    });
  }

  populateItemsFields(): void {
    this.itemsFields.forEach((field, index) => {
      field.value = this.deliveryItems[`DelCerDesc${index + 1}`] || '';
    });
  }

  onCategoryChange(event: any): void {
    console.log('Category changed to:', event);
    this.selectedCategory = event;
    this.getDeliveryCerByCategory(this.selectedCategory);
  }

  updateDeliveryCer(): void {
    if (!this.currentId) {
      console.error("No valid ID found for updating.");
      return;
    }

    const payload: any = {
      Category: this.selectedCategory,
      Id: this.currentId
    };

    this.itemsFields.forEach((field, index) => {
      payload[`DelCerDesc${index + 1}`] = field.value || null;
    });

    console.log('Payload being sent:', JSON.stringify({ model: payload }, null, 2));

    this.factoryService.updateDeliveryCerByCategory(payload).subscribe({
      next: (response) => {
        console.log('Update API Response:', response);
      },
      error: (error) => {
        console.error('Error updating Delivery Certificate:', error);
      }
    });
  }
}
