import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faPencil,
  faBan,
  faUsers,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { MasterColourService } from "src/app/services/vehicle/master-colour.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-master-colour-detail",
  templateUrl: "./master-colour-detail.component.html",
  styleUrls: ["./master-colour-detail.component.css"],
})
export class MasterColourDetailComponent {
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;
  faUsers = faUsers;

  mode: string = "Add";
  lockUpdate: boolean = false;
  masterColourId: string = "0";

  availableInteriorColours: any[] = [];

  formFields: FormField[] = [
    {
      type: "text",
      id: "ModelId",
      label: "Model Id",
      lock: true,
      value: "",
    },
    {
      type: "select",
      id: "ModelGroup",
      label: "Model Group",
      required: true,
      value: undefined,
      options: [],
    },
    {
      type: "select",
      id: "Doors",
      label: "Doors",
      required: true,
      value: undefined,
      options: [
        {
          display: "2",
          value: "2",
        },
        {
          display: "3",
          value: "3",
        },
        {
          display: "4",
          value: "4",
        },
        {
          display: "5",
          value: "5",
        },
      ],
    },
    { type: "text", id: "Type", label: "Type", value: "" },
    {
      type: "text",
      id: "ExteriorColour",
      label: "Exterior Colour",
      required: true,
      value: "",
    },
    {
      type: "text",
      id: "Description",
      label: "Description",
      value: "",
    },
    { type: "text", id: "Remarks", label: "Remarks", value: "" },
    {
      type: "switch",
      id: "Display",
      label: "Display",
      value: false,
    },
    {
      type: "selectMultiple",
      id: "InteriorColours",
      label: "Interior Colours",
      value: "",
      options: [],
      selectedOptions: [],
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private masterColourService: MasterColourService,
    private notifier: NotifierService,
  ) {
    masterColourService.GetModelGroup().subscribe({
      next: (response) => {
        this.getField("ModelGroup").options = response.body.map(
          (item: any) => ({
            display: item.Name,
            value: item.Value,
          }),
        );
        this.checkCanUpdate();
      },
      error: (error) => console.log(error),
    });
    this.route.params.subscribe((params) => {
      this.masterColourId = params["id"];
      if (this.masterColourId !== "0") {
        this.mode = "Edit";
        this.loadMasterColourDetail();
      }
    });
  }

  private loadMasterColourDetail(): void {
    this.masterColourService
      .getDetail(this.masterColourId)
      .subscribe({
        next: (response) => {
          const { ColourMasterId, ...data } = response.body;
          Object.entries(data).forEach(([key, value]) => {
            if (key == "InteriorColours") {
              this.getField(key).selectedOptions = (value as string)
                .split("/")
                .map(item => ({ value: item, display: item }));
            } else {
              this.getField(key).value = value;
            }
          });
          this.changeInteriorColourOptions();
        },
        error: (error: any) => {
          console.error("Error loading master colour detail:", error);
        },
      });
  }

  updateFields(event: any): void {
    this.checkCanUpdate();
    this.formFields = event.fields;
    if (event.updatedValue == 1) {
      this.getField("InteriorColours").selectedOptions = [];
      this.changeInteriorColourOptions();
    }
  }

  changeInteriorColourOptions(): void {
    this.masterColourService
      .GetColourCode(this.getField("ModelGroup").value)
      .subscribe({
        next: (response) => {
          this.availableInteriorColours = response.body;
          this.getField("InteriorColours").options =
            response.body.map((colour: any) => ({
              value: colour.Value,
              display: colour.Value,
            }));
        },
        error: (error) => console.log(error),
      });
  }

  SaveDetails(): void {
    const data = {
      ColourMasterId: this.masterColourId,
      Description: this.getField("Description").value,
      Display: this.getField("Display").value,
      Doors: this.getField("Doors").value,
      InteriorColours: this.getField("InteriorColours")
        .selectedOptions.map((option: any) => option.value)
        .join("/"),
      InteriorColoursSelected: this.availableInteriorColours.filter(
        (item) =>
          this.getField("InteriorColours").selectedOptions.some(
            (option: any) => option.value === item.Value,
          ),
      ),

      ExteriorColour: this.getField("ExteriorColour").value,
      ModelGroup: this.getField("ModelGroup").value,
      ModelId: this.getField("ModelId").value,
      Remarks: this.getField("Remarks").value,
      Type: this.getField("Type").value,
    };
    this.masterColourService.saveDetails(data).subscribe({
      next: () => {
        this.notifier.notify(
          "success",
          "Colour has been updated successfully",
        );
        this.router.navigate(["../../master-colour"], {
          relativeTo: this.route,
        });
      },

      error: (error) => {
        this.notifier.notify("error", "Error updating colour");
        console.log(error);
      },
    });
  }

  getField(id: string): any {
    const field = this.formFields.find((f) => f.id === id);
    return field;
  }

  onCancel(): void {
    this.router.navigate(["../../master-colour"], {
      relativeTo: this.route,
    });
  }

  checkCanUpdate(): void {
    this.lockUpdate = !this.formFields
      .filter((item) => item.required == true)
      .every((item) => item.value);
  }
}
