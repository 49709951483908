<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Vehicle selection</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <section class="content-container">
      <app-data-form
        [dataFields]="upperFormFields"
        [dataName]="'upperFormFields'"
        [columns]="0"
        (updateFields_child)="handleUpdate($event)"
      ></app-data-form>
    </section> 

    <app-data-table
      *ngIf="tableVisible"
      [tableHeaders]="tableHeaders_vehicleStock"
      [tableEntries]="filteredData"
    ></app-data-table>

    <!-- Ocultar toda la sección hasta que se seleccione un color -->
    <div *ngIf="specVisible">
      <h2 class="sub-heading w-full border-b">
        Standard specification
      </h2>
      <app-data-form
        [dataFields]="standardSpecification"
        [dataName]="'standardSpecification'"
      ></app-data-form>

      <div class="mb-4 grid grid-cols-2 gap-4">
        <div>
          <h2 class="sub-heading w-full border-b">
            Standard accessories
          </h2>
          <app-data-table
            [tableHeaders]="tableHeaders_standardAccessories"
            [tableEntries]="standardAccessories"
            [pagination]="false"
          ></app-data-table>
        </div>
        <div>
          <h2 class="sub-heading w-full border-b">
            Optional accessories
          </h2>
          <app-data-table
            [tableHeaders]="tableHeaders_optionalAccessories"
            [tableEntries]="optionalAccessories"
            [pagination]="false"
          ></app-data-table>
        </div>
      </div>

      <button class="shorter-btn misc-btn" (click)="goToBooking()">
        <fa-icon [icon]="faSignInAlt" class="mr-1" />Proceed to booking
      </button>
    </div>
  </section>
</section>
