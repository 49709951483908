<div class="page-container">
  <h1 class="main-heading">Invoice</h1>
  <div class="content-container">
    <div class="box-header"><h4>Search</h4></div>
    <div class="box-body grid grid-cols-2">
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="buyerOrderNumber" class="w-1/2"
            >Contract Number</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="buyerOrderNumber"
                class="w-full"
                [formControl]="searchControl"
              />
              <fa-icon [icon]="faSearch" class="search-btn"></fa-icon>
            </div>
            <div *ngIf="showList" class="search-result">
              <div
                *ngFor="let item of filteredItems"
                class="block"
                (click)="selectItem(item)"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Sales Consultant</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2"
            >Latest Invoice Number</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="contractNumber" class="w-1/2"
            >Invoice Number</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                [formControl]="searchControl"
              />
              <fa-icon [icon]="faSearch" class="search-btn"></fa-icon>
            </div>
            <div *ngIf="showList" class="search-result">
              <div
                *ngFor="let item of filteredItems"
                class="block"
                (click)="selectItem(item)"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2"
            >Invoice Date (dd/mm/yyyy)</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="date" id="" class="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div class="box-header"><h4>Details Of Finance Company</h4></div>
    <div class="box-body grid grid-cols-2">
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Finance Company</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="text" id="" class="w-full" />
              <fa-icon
                *ngIf="required && textInput.length == 0"
                [icon]="faTriangleExclamation"
                class="warning-btn icon-inside-input"
              />
            </div>
          </div>
        </div>
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Address 2</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="text" id="" class="w-full" />
              <fa-icon
                *ngIf="required && textInput.length == 0"
                [icon]="faTriangleExclamation"
                class="warning-btn icon-inside-input"
              />
            </div>
          </div>
        </div>
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Postal Code</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="text" id="" class="w-full" />
              <fa-icon
                *ngIf="required && textInput.length == 0"
                [icon]="faTriangleExclamation"
                class="warning-btn icon-inside-input"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Address 1</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="text" id="" class="w-full" />
              <fa-icon
                *ngIf="required && textInput.length == 0"
                [icon]="faTriangleExclamation"
                class="warning-btn icon-inside-input"
              />
            </div>
          </div>
        </div>
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Address 3</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input type="text" id="" class="w-full" />
              <fa-icon
                *ngIf="required && textInput.length == 0"
                [icon]="faTriangleExclamation"
                class="warning-btn icon-inside-input"
              />
            </div>
          </div>
        </div>

        <div class="relative flex items-center px-6 py-2">
          <label for="modeContac" class="flex w-1/2"
            >Mode of Contact</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <select
                id="modeContac"
                class="w-full"
                [(ngModel)]="modeContac"
              >
                <option
                  *ngFor="let modcontac of modscontac"
                  [value]="modcontac"
                >
                  {{ modcontac }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div class="box-header"><h4>Details Of Buyer</h4></div>
    <div class="box-body grid grid-cols-2">
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Name</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2">Road</label>
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2"
            >Postal Code</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="relative col-span-1">
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2"
            >Block</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="relative flex items-center px-6 py-2">
          <label for="" class="flex w-1/2"
            >Unit</label
          >
          <div class="relative flex w-1/2 flex-col items-end">
            <div class="flex w-full items-center">
              <input
                type="text"
                id="contractNumber"
                class="w-full"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div class="content-container">
    <div class="box-header"><h4>Details Of Vehicle</h4></div>
    <div class="box-body grid grid-cols-2">
      <div class="relative col-span-1">
        <div class="relative py-2">
          <label for="" class="">Model</label>
          <input type="text" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="" class=" ">Engine Number</label>
          <input type="text" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="" class=" ">Registration Number</label>
          <input type="number" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="" class=" ">Finance Receipt Number</label>
          <input type="text" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="" class=" ">Certificate of Entitlement</label>
          <input type="text" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="loanPeriod" class="">Road Tax Duration</label>
          <select
            id="loanPeriod"
            class="absolute right-0 mr-10 w-1/2"
            [(ngModel)]="loanPeriod"
          >
            <option *ngFor="let loanperiod of loanperiods" [value]="loanperiod">
              {{ loanperiod }}
            </option>
          </select>
        </div>
      </div>
      <div class="relative col-span-1">
        <div class="relative py-2">
          <label for="" class="">Colour</label>
          <input type="text" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative  my-7">
          <label for="" class="">Chasis Number</label>
          <input type="text" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="" class=" ">Registration Date</label>
          <input type="date" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="" class=" ">Finance Receipt Date</label>
          <input type="date" class="absolute right-0 mr-10 w-1/2" />
        </div>
        <div class="relative my-7">
          <label for="" class=" ">Insurance Company</label>
          <input type="text" class="absolute right-0 mr-10 w-1/2" />
        </div>
      </div>
    </div>
  </div>
</div>
