import { Component, OnInit, Input } from "@angular/core";
import { FormField } from "src/app/interfaces/form-field.model";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { VehicleSelectionService } from "src/app/services/vehicle/vehice-selection.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-vehicle-selection",
  templateUrl: "./vehicle-selection.component.html",
  styleUrls: ["./vehicle-selection.component.css"],
})
export class VehicleSelectionComponent implements OnInit {
  faSignInAlt = faSignInAlt;

  @Input() standardSpecification!: FormField[];

  searchInput: string = "";

  models: any[] = [];
  selectedModelCode: string = '';
  selectedModelName: string = '';
  selectedColour: string = '';
  filteredData: any[] = [];
  colours: any[] = [];
  vehicleStock: any[] = [];
  sellingPrice: string = ''; // Valor para el precio de venta

  tableVisible: boolean = false;
  specVisible: boolean = false;

  // Restauro los encabezados de las tablas
  tableHeaders_vehicleStock = this.generateTableHeadersForMonths();

  tableHeaders_standardAccessories = [
    { title: "ITEM", type: "data" },
  ];

  standardAccessories: any[] = [];

  tableHeaders_optionalAccessories = [
    { title: "ITEM", type: "data" },
    { title: "PRICE ($)", type: "data" },
  ];

  optionalAccessories: any[] = [];

  upperFormFields: FormField[] = [
    {
      type: "select",
      id: "model",
      label: "Select Model",
      options: []
    },
    {
      type: "select",
      id: "right",
      label: "Select Colour",
      options: []
    },
    {
      type: "text",
      id: "menuName",
      label: "",
      lock: true
    },
  ];


  constructor(
    private vehicleSelectionService: VehicleSelectionService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  

  ngOnInit() {
    this.vehicleSelectionService.getModelActiveInSalesDrpList().subscribe(
      (response) => {
        if (response && response.body) {
          this.models = response.body.map((model: any) => ({
            value: model.Value,
            display: model.Name,
          }));
          this.updateUpperFormFields();
        } else {
          console.error('La respuesta no contiene la estructura esperada.', response);
        }
      },
      (error) => {
        console.error("Error getting models:", error);
      }
    );

    this.standardSpecification = []; // Iniciamos con vacío
  }

  updateUpperFormFields(): void {
    const selectedModel = this.selectedModelCode;
    const selectedColour = this.upperFormFields[1].value;
  
    this.upperFormFields = [
      {
        type: "select",
        id: "model",
        label: "Select Model",
        options: this.models,
        value: selectedModel
      },
      {
        type: "select",
        id: "right",
        label: "Select Colour",
        options: this.colours,
        value: selectedColour
      },
      {
        type: "text",
        id: "menuName",
        label: "Selling Price",
        lock: true,
        value: this.sellingPrice // Asigna el valor del precio de venta
      },
    ];
  }
  

  handleUpdate(event: any): void {
    const modelChanged = event.fields.find((f: { id: string; value: string; }) => f.id === 'model');
    const colourChanged = event.fields.find((f: { id: string; value: string; }) => f.id === 'right');

    if (modelChanged) {
      this.selectedModelCode = modelChanged.value;
      const selectedModel = this.models.find((model: any) => model.value === this.selectedModelCode);
      this.selectedModelName = selectedModel ? selectedModel.display : '';
      this.tableVisible = true;
      this.getColoursByModel(this.selectedModelCode);
      this.getVehicleStockAvailability(this.selectedModelName);
    }

    if (colourChanged && colourChanged.value) {
      this.selectedColour = colourChanged.value; // Guardar el color seleccionado
      this.specVisible = true; // Mostrar la sección solo si se selecciona un color
      this.getVehicleDetails(this.selectedModelCode, this.selectedColour); // Llamar a la API para obtener los detalles
    } else {
      this.specVisible = false; // Ocultar la sección si no hay color seleccionado
    }
  }

  getColoursByModel(modelCode: string): void {
    this.vehicleSelectionService.getColourDrpList(modelCode).subscribe(
      (response) => {
        if (response && response.body) {
          this.colours = response.body.map((colour: any) => ({
            value: `${colour.Value}`,
            display: `${colour.Value} - ${colour.Name}`,
          }));
          this.updateUpperFormFields();
          //console.log('Colores recibidos y cargados:', this.colours);
        } else {
          console.error('La respuesta no contiene la estructura esperada para los colores.', response);
        }
      },
      (error) => {
        console.error("Error obteniendo colores:", error);
      }
    );
  }

  getVehicleStockAvailability(modelDesc: string): void {
    let formattedModelDesc = modelDesc.replace(/\s+/g, '+');
    formattedModelDesc = formattedModelDesc.replace(/"/g, '%5C%22');
    formattedModelDesc = `%22${formattedModelDesc}%22`;

    //console.log("URL generada:", formattedModelDesc);

    this.vehicleSelectionService.getVehicleStockAvailability(formattedModelDesc).subscribe(
      (response) => {
        if (response && response.body && response.body.GridData && response.body.ExtraData) {
          const gridData = response.body.GridData;
          const extraData = response.body.ExtraData;

          this.filteredData = gridData.map((item: any) => {
            return {
              "extColour": `${item.ExtColourCode} - ${item.ExtColourDescription}`,
              "type": item.Type,
              "intColour": `${item.IntColourCode} - ${item.IntColourDescription}`,
              "inStock": item.InStock,
            };
          });

          this.filteredData = this.filteredData.map((row: any) => {
            extraData.forEach((extra: any) => {
              const matchingItem = extra.ItemList.find((item: any) =>
                `${item.ExtColourCode}` === row.extColour &&
                `${item.IntColourCode}` === row.intColour
              );
              row[`eta_${extra.Id}`] = matchingItem ? matchingItem.Quantity : 0;
            });
            return row;
          });

          this.tableHeaders_vehicleStock = this.generateTableHeadersForMonths();
          //console.log('Datos de stock procesados y nombres de columnas actualizados:', this.tableHeaders_vehicleStock);
        } else {
          console.error('La respuesta no contiene la estructura esperada para los datos de stock.', response);
        }
      },
      (error) => {
        console.error("Error obteniendo disponibilidad de stock:", error);
      }
    );
  }


  generateTableHeadersForMonths(): any[] {
    const currentMonth = new Date().getMonth();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const headers = [
      { title: "Exterior colour", type: "data", id: "extColour" },
      { title: "Type", type: "data", id: "type" },
      { title: "Int colour", type: "data", id: "intColour" },
      { title: "In stock", type: "data", id: "inStock" }
    ];

    for (let i = 0; i < 5; i++) {
      const monthIndex = (currentMonth + i) % 12;
      headers.push({
        title: `ETA (Mid ${months[monthIndex]})`,
        type: "data",
        id: `eta_${i}`
      });
    }

    return headers;
  }

  getVehicleDetails(modelCode: string, colourCode: string): void {
    this.vehicleSelectionService.getAllVehicles(colourCode, modelCode).subscribe(
      (response) => {
        // Asegurarte de que los datos se acceden directamente desde response.body
        if (response && response.body) {
          const vehicleData = response.body;
  
          // 1. Actualizar el precio de venta
          this.sellingPrice = vehicleData.SellingPrice ? vehicleData.SellingPrice : "N/A";
          
          // Asegúrate de actualizar el campo de Selling Price en el formulario
          this.updateUpperFormFields(); 
  
          // 2. Actualizar Standard Specification
          this.standardSpecification = this.mapSpecificationData(vehicleData.Specification || {});
  
          // 3. Actualizar tablas de accesorios
          this.standardAccessories = vehicleData.StandardAccessories.length > 0 ? vehicleData.StandardAccessories.map((item: any) => ({
            item: item.Name || "N/A"
          })) : [];
  
          this.optionalAccessories = vehicleData.OptionalAccessories.length > 0 ? vehicleData.OptionalAccessories.map((item: any) => ({
            item: item.Name || "N/A", 
            price: item.Value || "N/A"
          })) : [];
  
          //console.log("Respuesta completa de la API:", response);
          //console.log("Datos del vehículo:", response.body);

          //console.log("Detalles del vehículo procesados:", vehicleData);
        } else {
          console.error("La estructura de la respuesta de la API no es la esperada.", response);
        }
      },
      (error) => {
        console.error("Error al obtener los detalles del vehículo:", error);
      }
    );
  }
  
  
  
  
  // Función para mapear los datos de las especificaciones
  mapSpecificationData(specification: any): FormField[] {
    return [
      { type: "const", id: "engineType", label: "Engine Type", value: specification.EngineType || "" },
      { type: "const", id: "displacement", label: "Displacement (cc)", value: specification.Displacement || "" },
      { type: "const", id: "boreStroke", label: "Bore x Stroke (mm)", value: specification.BoreStroke || "" },
      { type: "const", id: "compressionRatio", label: "Compression Ratio", value: specification.CompressionRatio || "" },
      { type: "const", id: "maxPower", label: "Max Power (kW/rpm)", value: specification.MaxPower || "" },
      { type: "const", id: "maxTorque", label: "Max Torque (N.m/rpm)", value: specification.MaxTorque || "" },
      { type: "const", id: "lubricantCap", label: "Lubricant Capacity (litre)", value: specification.LubricantCap || "" },
      { type: "const", id: "brakesGrl", label: "General Brakes", value: specification.BrakesGrl || "" },
      { type: "const", id: "frontBrake", label: "Front Brake Type", value: specification.FrontBrake || "" },
      { type: "const", id: "rearBrake", label: "Rear Brake Type", value: specification.RearBrake || "" },
      { type: "const", id: "booster", label: "Booster Size/Type", value: specification.Booster || "" },
      { type: "const", id: "susFront", label: "Suspension Front", value: specification.SusFront || "" },
      { type: "const", id: "susRear", label: "Suspension Rear", value: specification.SusRear || "" },
      { type: "const", id: "length", label: "Length (mm)", value: specification.Length || "" },
      { type: "const", id: "width", label: "Width (mm)", value: specification.Width || "" },
      { type: "const", id: "height", label: "Height (mm)", value: specification.Height || "" },
      { type: "const", id: "wheelBase", label: "WheelBase (mm)", value: specification.WheelBase || "" },
      { type: "const", id: "steeringWheelType", label: "Steering Wheel Type", value: specification.SteeringWheelType || "" },
      { type: "const", id: "steeringGearRatio", label: "Overall Steering Gear Ratio", value: specification.SteeringGearRatio || "" },
      { type: "const", id: "steeringWheelTurn", label: "Number of Steering Wheel Turn", value: specification.SteeringWheelTurn || "" },
      { type: "const", id: "turningRadius", label: "Turning Radius at Wheel (m)", value: specification.TurningRadius || "" },
    ];
  }
  
  goToBooking() {
    this.router.navigate(["../booking"], {
      relativeTo: this.route,
    });
  }
}

