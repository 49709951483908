import { Component, OnInit } from "@angular/core";
import { VehicleStockAvailabilityService } from "src/app/services/vehicle/vehicle-stock-availability.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormField } from "src/app/interfaces/form-field.model";

interface ModelOption {
  value: string;
  display: string;
}

@Component({
  selector: "app-vehicle-stock-availability",
  templateUrl: "./vehicle-stock-availability.component.html",
  styleUrls: ["./vehicle-stock-availability.component.css"],
})
export class VehicleStockAvailabilityComponent implements OnInit {
  upperFormFields: FormField[] = [
    {
      type: "select",
      id: "model",
      label: "Select Model",
      options: [],
      value: '',
    }
  ];

  models: ModelOption[] = [];
  selectedModelCode: string = '';  // Código del modelo seleccionado
  selectedModelName: string = '';  // Nombre del modelo seleccionado
  tableVisible: boolean = false;   // Controla la visibilidad de la tabla

  tableHeaders_vehicleStock: any[] = [];
  filteredData: any[] = [];
  tableHeaders_newTable: any[] = [];
  filteredData_newTable: any[] = [];

  constructor(
    private vehicleStockAvailabilityService: VehicleStockAvailabilityService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Inicializamos los headers de la tabla dinámica
    this.tableHeaders_newTable = this.generateTableHeadersForMonths();

    // Cargar los modelos disponibles al inicializar
    this.vehicleStockAvailabilityService.getModelActiveInSalesDrpList().subscribe(
      (response: { body: Array<{ id: string; name: string }> }) => {
        if (response && response.body) {
          this.models = response.body.map((model: any) => ({
            value: model.id,
            display: model.name,
          }));
          this.updateUpperFormFields();
        } else {
          console.error("Estructura de respuesta inesperada:", response);
        }
      },
      (error: any) => {
        console.error("Error al obtener los modelos:", error);
      }
    );
  }

  // Método para manejar el cambio en el dropdown
  handleUpdate(event: any): void {
    const selectedModel = event.fields.find((f: { id: string }) => f.id === 'model');
    if (selectedModel && selectedModel.value) {
      console.log('Valor seleccionado:', selectedModel.value);
      this.selectedModelCode = selectedModel.value;
      const model = this.models.find((model: any) => model.value === this.selectedModelCode);
      this.selectedModelName = model ? model.display : '';
      
      // Mostrar la tabla solo si se ha seleccionado un valor
      this.tableVisible = true;

      // Llamar a la función para obtener los datos de stock
      this.getVehicleStockAvailability(this.selectedModelName);
      // Llamar a la nueva API para la segunda tabla
      this.getNewTableData(this.selectedModelName);
    } else {
      console.log('No se seleccionó ningún modelo');
      this.tableVisible = false; // Ocultar la tabla si no se selecciona un modelo
    }
  }

  // Llama a la API para obtener disponibilidad de stock del vehículo
  getVehicleStockAvailability(modelDesc: string): void {
    let formattedModelDesc = modelDesc.replace(/\s+/g, '+');
    formattedModelDesc = formattedModelDesc.replace(/"/g, '%5C%22');
    formattedModelDesc = `%22${formattedModelDesc}%22`;

    this.vehicleStockAvailabilityService.getVehicleStockAvailability(formattedModelDesc).subscribe(
      (response) => {
        if (response && response.body && response.body.GridData && response.body.ExtraData) {
          const gridData = response.body.GridData;
          const extraData = response.body.ExtraData;

          this.filteredData = gridData.map((item: any) => {
            return {
              "extColour": `${item.ExtColourCode} - ${item.ExtColourDescription}`,
              "type": item.Type,
              "intColour": `${item.IntColourCode} - ${item.IntColourDescription}`,
              "inStock": item.InStock,
            };
          });

          this.filteredData = this.filteredData.map((row: any) => {
            extraData.forEach((extra: any) => {
              const matchingItem = extra.ItemList.find((item: any) =>
                `${item.ExtColourCode}` === row.extColour &&
                `${item.IntColourCode}` === row.intColour
              );
              row[`eta_${extra.Id}`] = matchingItem ? matchingItem.Quantity : 0;
            });
            return row;
          });

          this.tableHeaders_vehicleStock = this.generateTableHeadersForMonths();
        } else {
          console.error('La respuesta no contiene la estructura esperada para los datos de stock.', response);
        }
      },
      (error) => {
        console.error("Error obteniendo disponibilidad de stock:", error);
      }
    );
  }

  // Nueva función para llamar la API de `GetVehicleStockAvailability`
  getNewTableData(modelDesc: string): void {
    let formattedModelDesc = modelDesc.replace(/\s+/g, '+');
    formattedModelDesc = formattedModelDesc.replace(/"/g, '%5C%22');
    formattedModelDesc = `%22${formattedModelDesc}%22`;

    this.vehicleStockAvailabilityService.getVehicleStockAvailability(formattedModelDesc).subscribe(
      (response) => {
        if (response && response.body && response.body.GridData && response.body.ExtraData) {
          const gridData = response.body.GridData;
          const extraData = response.body.ExtraData;

          this.filteredData_newTable = gridData.map((item: any) => {
            return {
              "extColour": `${item.ExtColourCode} - ${item.ExtColourDescription}`,
              "type": item.Type,
              "intColour": `${item.IntColourCode} - ${item.IntColourDescription}`,
              "inStock": item.InStock,
            };
          });

          this.filteredData_newTable = this.filteredData_newTable.map((row: any) => {
            extraData.forEach((extra: any) => {
              const matchingItem = extra.ItemList.find((item: any) =>
                `${item.ExtColourCode}` === row.extColour &&
                `${item.IntColourCode}` === row.intColour
              );
              row[`eta_${extra.Id}`] = matchingItem ? matchingItem.Quantity : 0;
            });
            return row;
          });

          this.tableHeaders_newTable = this.generateTableHeadersForMonths();
        } else {
          console.error('La respuesta no contiene la estructura esperada para los datos.', response);
        }
      },
      (error) => {
        console.error("Error obteniendo disponibilidad para la nueva tabla:", error);
      }
    );
  }

  // Método para redirigir cuando se hace clic en un enlace de la tabla
  openEntry(value: any) {
    this.router.navigate(["/vehicle/vehicle-selection"], {
      relativeTo: this.route,
    });
  }

  generateTableHeadersForMonths(): any[] {
    const currentMonth = new Date().getMonth();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const headers = [
      { title: "Exterior colour", type: "link", id: "extColour" },  // Modificado a "link"
      { title: "Type", type: "data", id: "type" },
      { title: "Int colour", type: "data", id: "intColour" },
      { title: "In stock", type: "data", id: "inStock" }
    ];

    for (let i = 0; i < 5; i++) {
      const monthIndex = (currentMonth + i) % 12;
      headers.push({
        title: `ETA (Mid ${months[monthIndex]})`,
        type: "data",
        id: `eta_${i}`
      });
    }

    return headers;
  }

  updateUpperFormFields(): void {
    this.upperFormFields = [
      {
        type: "select",
        id: "model",
        label: "Select Model",
        options: this.models,
        value: '',  // No selecciona ningún valor por defecto
      },
    ];
  }
}
