import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PremiumCareMemberService } from "src/app/services/customer/premium-care-member.service";
import { NotifierService } from "angular-notifier";
import {getEmptyGuid} from "../../../../services/util.service";

@Component({
  selector: 'app-premium-care-member-list',
  templateUrl: './premium-care-member-list.component.html',
  styleUrls: ['./premium-care-member-list.component.css']
})
export class PremiumCareMemberListComponent implements OnInit {

  // ICONS VARIABLES
  faPlus = faPlus;
  allData: any[] = [];
  filteredData: any[] = [];

  tableHeaders = [
    { title: "VEHICLE NO", type: "data", id: "vehicleNo" },
    { title: "CHASSIS NO", type: "data", id: "chassisNo" },
    { title: "NAME", type: "data", id: "name", classes: "max-w-64" },
    { title: "MEMBER TYPE", type: "data", id: "memberType" },
    { title: "EFFECTIVE DATE", type: "data", id: "effectiveDate", classes: "max-w-64" },
    { title: "EXPIRY DATE", type: "data", id: "expiryDate", classes: "max-w-64" },
    { title: "STATUS", type: "data", id: "status", classes: "max-w-64" },
    { title: "", type: "editTrash", id: "editTrash" },
    { title: "", type: "hidden", id: "id" }
  ];

  constructor(
    private premiumCareMemberService: PremiumCareMemberService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getAllList();
  }

  private getAllList(): void {
    this.premiumCareMemberService.getAllList().subscribe(
      (response) => {
        this.allData = response.body.map((vehicle: any) => {
          const container = {
            "vehicleNo": "",
            "chassisNo": "",
            "name": "",
            "memberType": "",
            "effectiveDate": "",
            "expiryDate": "",
            "status": "",
            "membershipId": "",
          };
          container["vehicleNo"] = vehicle.VehicleNo;
          container["chassisNo"] = vehicle.ChassisNumber;
          container["name"] = vehicle.Name;
          container["memberType"] = vehicle.MemberType;
          container["effectiveDate"] = vehicle.JoinedDate;
          container["expiryDate"] = vehicle.ExpiryDate;
          container["status"] = vehicle.Status;
          container["membershipId"] = vehicle.MembershipId;
          return container;
        });
        this.filteredData = this.allData;
        console.log("Data:", this.filteredData);
      },
      (error) => {
        console.error("Error fetching membership data:", error);
      },
    );
  }

  onSearch(searchValue: string): void {
    this.filteredData = this.allData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.vehicleNo.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.chassisNo.toLowerCase().includes(searchValue.toLowerCase()) // corregir chassisNoeNo por chassisNo
    );
  }  

  editEntry(value: any) {
    this.router.navigate(
      ["../premium-care-member", value.membershipId],
      { relativeTo: this.route },
    );
  }

  addEntry() {
    this.router.navigate(
      ["../premium-care-member", this.getEmptyGuid()],
      { relativeTo: this.route },
    );
  }
  
  removeEntry(value: any): void {
    const membershipId = value.membershipId;

    this.premiumCareMemberService.deleteMember(membershipId).subscribe(
      (response) => {

        this.notifier.notify('success', 'Member deleted successfully.');
  
        this.allData = this.allData.filter(item => item.membershipId !== membershipId);
        this.filteredData = this.filteredData.filter(item => item.membershipId !== membershipId);
      },
      (error) => {
        console.error('Error deleting member:', error);
        this.notifier.notify('error', 'Failed to delete the member. Please try again.');
      }
    );
  }
  

  protected readonly getEmptyGuid = getEmptyGuid;

}
