<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Customer Search</h1>
    <app-navpath />
  </div>

  <div class="content-container">
    <h2 class="sub-heading w-full border-b">Buyer's Details</h2>
    <div class="flex items-center gap-4">
      <app-data-form
        [dataFields]="searchFields"
        [dataName]="'search'"
        class="flex-grow"
        (updateFields_child)="updateSearchFields($event.fields)"
      ></app-data-form>
      <button class="shorter-btn search-btn w-1/6" (click)="onSearch()">
        <fa-icon [icon]="faSearch" class="mr-1" />Search
      </button>
    </div>
  </div> 
  <div class="content-container">
      <h2 class="sub-heading w-full border-b">Buyer's Details Based On Model</h2>
      <div class="flex items-center gap-4">
        <app-data-form
          [dataFields]="modelForm"
          [dataName]="'modelForm'"
          class="flex-grow"
          (updateFields_child)="updateModelFields($event.fields)"
        />
        <button class="shorter-btn add-btn w-1/2" (click)="onDownloadCustomerList()">
          <fa-icon [icon]="faDownload" class="mr-1" />Download Customer List
        </button>      
      </div>
  </div>
  <div *ngIf="tableData.length > 0" class="content-container">
    <h2 class="sub-heading w-full border-b">Search Results</h2>
    <app-data-table
      (edit)="editEntry($event)"
      (print)="printEntry($event)"
      [tableHeaders]="tableHeaders"
      [tableEntries]="tableData"
    ></app-data-table>
  </div>
</section>