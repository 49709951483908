import { Component } from '@angular/core';
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";

@Component({
  selector: 'app-fob-invoice-price',
  templateUrl: './fob-invoice-price.component.html',
  styleUrls: ['./fob-invoice-price.component.css']
})
export class FobInvoicePriceComponent {

  faSave = faSave;
  faTrash = faTrash;

  fobTable: FormField[] = [
    {
      type: "select",
      id: "invoiceNo",
      label: "Invoice No",
      options: [
        { display: "E111A22AB1-A-02", value: "E111A22AB1-A-02" },
        { display: "E503A22AB3-A-01", value: "E503A22AB3-A-01" },
      ],
    },
    {
      type: "text",
      id: "model",
      label: "Model",
      lock: true,
    },
    {
      type: "text",
      id: "colour",
      label: "Colour",
      lock: true,
    },
    {
      type: "text",
      id: "unit",
      label: "Unit",
      lock: true,
    },
    {
      type: "number",
      id: "exchangeRate",
      label: "Exchange Rate (Bank Rate)",
    },
    {
      type: "number",
      id: "fob",
      label: "FOB US$",
    },
    {
      type: "number",
      id: "freight",
      label: "Freight",
    },
  ];

  fob_headers = [
    {
      title: "MODEL",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "COLOUR",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "UNIT",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "EXCHANGE RATE",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "FOB",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "FREIGHT",
      type: "data",
      classes: "max-w-64",
    },
    {
      title: "ACTION",
      type: "data",
      classes: "max-w-64",
    },
  ];

  fob_entries = [
    {
      "model": "ELAN XD 1.6 A",
      "colour": "AH",
      "unit": 1,
      "exchangeRate": 1.623,
      "fob": 8580,
      "freight": 265,
      "action": ""
    },
    {
      "model": "ELAN XD 1.6 A",
      "colour": "SK",
      "unit": 1,
      "exchangeRate": 1.623,
      "fob": 8580,
      "freight": 265,
      "action": ""
    },
    {
      "model": "ELAN XD 1.6 A",
      "colour": "TW",
      "unit": 2,
      "exchangeRate": 1.623,
      "fob": 8540,
      "freight": 265,
      "action": ""
    }
  ]
}
