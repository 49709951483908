<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Vehicle Overbook Report</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form [dataFields]="FormFields" />
    <button class="shorter-btn print-btn" (click)="print()">
      <fa-icon [icon]="faPrint" class="mr-1" />Print
    </button>
  </div>
</div>
