<main>
  <app-loading-page></app-loading-page>
  <div class="flex w-full">
    <app-sidenavbar
      id="side-menu"
      class="side-menu"
      [showSideMenu]="showSideMenu"
      [ngClass]="showSideMenu ? 'basis-60' : 'basis-14'"
    />
    <div class="flex-1 min-w-0">
      <app-navbar
        class="sticky top-0 z-10"
        (clickMenu)="showSideMenu = !showSideMenu"
      />
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-alert></app-alert>
  <app-chat-window class="sticky bottom-0"/>
</main>
