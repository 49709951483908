<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      Premium Care Member Status Entitlement
    </h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="form"
      dataName="form"
      (updateFields_child)="updateFields($event)"
    />
    <div class="mb-2 flex w-fit gap-1">
      <button class="shorter-btn print-btn" (click)="printStatus()">
        <fa-icon [icon]="faPrint" class="mr-1"></fa-icon>Print
      </button>
      <button class="shorter-btn clear-btn" (click)="clearForm()">
        <fa-icon [icon]="faEraser" class="mr-1"></fa-icon>Clear
      </button>
    </div>
  </div>
</section>
