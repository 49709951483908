import { Component } from '@angular/core';
import { FormField } from "src/app/interfaces/form-field.model";
import {
  faPlus,
  faMinus,
  faCircle,
  faPencil,
  faTrashCan,
  faTrash,
  faFloppyDisk,
  faCancel,
  faMagnifyingGlass,
  faSearch,
  faTriangleExclamation,
  faEraser,
  faSave,
  faBroom,
  faCar,
} from "@fortawesome/free-solid-svg-icons";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormControl } from "@angular/forms";
@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent {
  selectedTab = "customer";
  titleSelect: string | undefined;
  coeType: string | undefined;
  modeContac: string | undefined;
  carType: string | undefined;
  discount: string | undefined;
  paymentType: string | undefined;
  loanPeriod: string | undefined;
  dealrName: string | undefined;
  insuranceCompany: string | undefined;
  titles: string[] = ["MR", "MRS", "MISS", "M/S", "MMDM", "DR", "MS"];
  coes: string[] = ["Bidding", "Own COE", "Immediate E"];
  cartypes: string[] = [
    "Standard",
    "Off Peak Car(OPC)",
    "Compressed Natural Gas (CNG)",
    "CNG OPC",
    "OPC",
  ];
  modscontac: string[] = [
    "REFERRAL",
    "REPEAT",
    "WALK-IN",
    "POSTALE",
    "ONLINE SALES",
  ];
  discounts: string[] = ["500", "700", "1000"];
  paymenttypes: string[] = [
    "AIG OTHERS",
    "AIG IN HOUSE",
    "AXA INSURANCE OTHERS",
    "AXA INSURANCE IN-HOUSE",
    "LIBERTY INSURANCE IN-HOUSE",
    "MSIG INSURANCE IN-HOUSE",
    "NTUC INCOME OTHERS",
    "NTUC INCOME IN-HOUSE",
    "OWN INSURANCE",
  ];
  loanperiods: string[] = [
    "12",
    "24",
    "36",
    "48",
    "60",
    "72",
    "84",
    "96",
    "108",
    "116",
    "118",
    "120",
  ];
  dealernames: string[] = [
    "No Trade In",
    "BOEKI AUTO & MARINE PTE LTD",
    "KMPL USED CAR DIVISION",
  ];

  faPlus = faPlus;
  faMinus = faMinus;
  faCircle = faCircle;
  faPencil = faPencil;
  faTrashCan = faTrashCan;
  faFloppyDisk = faFloppyDisk;
  faCancel = faCancel;
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faSearch = faSearch;
  faTriangleExclamation = faTriangleExclamation;
  faEraser = faEraser;
  faCar = faCar;

  textInput: string = "";
  required: boolean = true;
  searchText = "";
  searchControl = new FormControl("");
  items = ["Element1", "Element2", "Element3", "Element4"];
  filteredItems: string[] = [];
  showList = false;

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe((value) => {
        this.filterItems(value);
        if (value) {
          this.showList = true;
        }
      });
  }

  filterItems(searchText: string | null): void {
    if (!searchText) {
      this.filteredItems = [];
      this.showList = false;
    } else {
      this.filteredItems = this.items.filter((item) =>
        item.toLowerCase().includes(searchText.toLowerCase()),
      );
      this.showList = this.filteredItems.length > 0;
    }
  }

  selectItem(item: string): void {
    this.searchControl.setValue(item);
    this.filteredItems = [];
    this.showList = false;
  }
  searchBooking: FormField[] = [
    {
      type: "search",
      id: "searchOrderNumber",
      label: "Contract Number",
    },
    {
      type: "search",
      id: "searchBuyersDate",
      label: "Invoice Number",
    },
    {
      type: "search",
      id: "searchContractNumber",
      label: "Registration Number",
    },
    {
      type: "search",
      id: "searchQuotationNumber",
      label: "NRIC",
    },
    {
      type: "search",
      id: "searchLocation",
      label: "Buyer's Order Number",
    },
    {
      type: "search",
      id: "searchLocation",
      label: "Chasis Number",
    },
    {
      type: "search",
      id: "searchLocation",
      label: "Engine Number",
    },
    {
      type: "search",
      id: "searchLocation",
      label: "Motor Number",
    },
  ];

  buyersDetails!: FormField[];
  buyersDetails2!: FormField[];
  detailsVehicle!: FormField[];
  detailsVehicle2!: FormField[];
  detailsVehicle3!: FormField[];
  detailsVehicle4!: FormField[];
  detailsCashDiscount!: FormField[];
  detailsDeposit!: FormField[];
  detailsOtherFees!: FormField[];
  detailsInsurance!: FormField[];
  detailsHireApplication!: FormField[];
  detailsTradeInVehicle!: FormField[];
  komocosAccount_Promotion!: FormField[];
  komocosAccount_Accessories!: FormField[];
  buyersAccount_NonAccessories!: FormField[];
  buyersAccount_Accessories!: FormField[];
  consultantAccount_NonAccessories!: FormField[];
  consultantAccount_Accessories!: FormField[];
  additionalRemarks!: FormField[];

  // ICONS VARIABLES

  faSave = faSave;
  faBroom = faBroom;

  updateFields(event: any): void {
    switch (event.name) {
      case "searchBooking":
        this.searchBooking = event.fields;
        break;
      case "buyersDetails":
        this.buyersDetails = event.fields;
        break;
      case "buyersDetails2":
        this.buyersDetails = event.fields;
        break;
      case "detailsVehicle":
        this.detailsVehicle = event.fields;
        break;
      case "detailsVehicle2":
        this.detailsVehicle = event.fields;
        break;
      case "detailsVehicle3":
        this.detailsVehicle = event.fields;
        break;
        case "detailsVehicle4":
        this.detailsVehicle = event.fields;
        break;
      case "detailsCashDiscount":
        this.detailsCashDiscount = event.fields;
        break;
      case "detailsDeposit":
        this.detailsDeposit = event.fields;
        break;
      case "detailsOtherFees":
        this.detailsOtherFees = event.fields;
        break;
      case "detailsInsurance":
        this.detailsInsurance = event.fields;
        break;
      case "detailsHireApplication":
        this.detailsHireApplication = event.fields;
        break;
      case "detailsTradeInVehicle":
        this.detailsTradeInVehicle = event.fields;
        break;
      case "komocosAccount_Promotion":
        this.komocosAccount_Promotion = event.fields;
        break;
      case "komocosAccount_Accessories":
        this.komocosAccount_Accessories = event.fields;
        break;
      case "buyersAccount_NonAccessories":
        this.buyersAccount_NonAccessories = event.fields;
        break;
      case "buyersAccount_Accessories":
        this.buyersAccount_Accessories = event.fields;
        break;
      case "consultantAccount_NonAccessories":
        this.consultantAccount_NonAccessories = event.fields;
        break;
      case "consultantAccount_Accessories":
        this.consultantAccount_Accessories = event.fields;
        break;
      case "additionalRemarks":
        this.additionalRemarks = event.fields;
        break;
    }
  }

  ngOnInit(): void {
    this.selectedTab = "";
    this.buyersDetails = [
      {
        type: "const",
        id: "totalAmountInSalesConsultantAccount",
        label: "Total Amount In Sales Consultant's Account:",
      },
    ];
    this.buyersDetails2 = [
      {
        type: "const",
        id: "totalAmountReceived:",
        label: "Total Amount Received:",
      },
    ];
    this.detailsVehicle = [
      {
        type: "const",
        id: "totalAmountInBuyerAccount:",
        label: "Total Amount In Buyer's Account:",
        value: 6,
      },
    ];
    this.detailsVehicle2 = [
      {
        type: "date",
        id: "model",
        label: "PARF Received Date",
      },
      {
        type: "number",
        id: "model",
        label: "PARF Value",
      },
      {
        type: "date",
        id: "settlementInstructiontoa",
        label: "Settlement Instruction to A/c",
      },
      {
        type: "number",
        id: "coeRebateValue",
        label: "COE Rebate Value",
      },
    ];
    this.detailsVehicle3 = [
      {
        type: "number",
        id: "insurancePremium",
        label: "Insurance Premium",
      },
      {
        type: "select",
        id: "insuranceCompany",
        label: "Insurance Company",
      },
      {
        type: "number",
        id: "firstInstallment",
        label: "First Installment",
      },
      {
        type: "select",
        id: "financeCompany",
        label: "Finance Company",
        options: [
          {
            display: "No Finance",
            value: "No Finance",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_MA",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSCA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA2",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSMA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA1",
          },
          {
            display: "GENIE FINANCIAL SERVICES PTE LTD",
            value: "GFSPL",
          },
          {
            display: "GOLDBELL FINANCIAL SERVICES PTE LTD",
            value: "GFS",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA_NF",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA",
          },
          {
            display: "HL BANK",
            value: "HLB_OC",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL_OC",
          },
        ],
      },
      {
        type: "text",
        id: "administrativeCharge",
        label: "Administrative Charge",
      },
      {
        type: "radio",
        id: "administrativeCharge",
        label: "Proceed to Cheque Issue",
      },
    ];
    this.detailsVehicle4 = [
      {
        type: "const",
        id: "totalAmountInBuyerAccount:",
        label: "Total Amount In Buyer's Account:",
        value: 0,
      },
    ];
    this.detailsCashDiscount = [
      {
        type: "number",
        id: "vehiclePrice",
        label: "Vehicle Price",
      },
      {
        type: "number",
        id: "COE Top Up",
        label: "COE Top Up",
      },
      {
        type: "number",
        id: "cevsSurcharge",
        label: "CEVS/VES Surcharge",
      },
      {
        type: "number",
        id: "additionalRoadTax",
        label: "Additional Road Tax",
      },
      {
        type: "number",
        id: "Accessories",
        label: "Accessories",
      },
      {
        type: "number",
        id: "numberRetentionFee",
        label: "Number Retention Fee",
      },
      {
        type: "const",
        id: "totalDueAmount:",
        label: "Total Due Amount:",
      },
    ];
    this.detailsOtherFees = [
      {
        type: "number",
        id: "cashDiscount",
        label: "Cash Discount",
      },
      {
        type: "number",
        id: "opcRebate",
        label: "OPC Rebate",
      },
      {
        type: "number",
        id: "coeRebate",
        label: "COE Rebate",
      },
      {
        type: "number",
        id: "arfRebate",
        label: "ARF Rebate",
      },
      {
        type: "number",
        id: "dutyRebate",
        label: "Duty Rebate",
      },
    ];
    this.detailsInsurance = [
      {
        type: "number",
        id: "parfRebate",
        label: "PARF Rebate",
      },

      {
        type: "number",
        id: "coeResidualRebate",
        label: "COE Residual Rebate",
      },
    ];
    this.detailsHireApplication = [
      {
        type: "const",
        id: "totalrebateAmount:",
        label: "Total Rebate Amount:",
      },
      {
        type: "const",
        id: "totalDueAmountAfterRebates",
        label: "Total Due Amount (after rebates):",
      },
    ];
    this.detailsTradeInVehicle = [
      {
        type: "select",
        id: "financeCompany",
        label: "Finance Company",
        options: [
          {
            display: "No Finance",
            value: "No Finance",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_MA",
          },
          {
            display: "CITIBANK SINGAPORE LTD",
            value: "CB_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_OC",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSCA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA2",
          },
          {
            display: "DBS BANK LTD",
            value: "DBSMA",
          },
          {
            display: "DBS BANK LTD",
            value: "DBS_CA1",
          },
          {
            display: "GENIE FINANCIAL SERVICES PTE LTD",
            value: "GFSPL",
          },
          {
            display: "GOLDBELL FINANCIAL SERVICES PTE LTD",
            value: "GFS",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA_NF",
          },
          {
            display: "HITACHI CAPITAL ASIA PACIFIC PTE LTD",
            value: "HICA",
          },
          {
            display: "HL BANK",
            value: "HLB_OC",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL",
          },
          {
            display: "HONG LEONG FINANCE LTD",
            value: "HL_OC",
          },
        ],
      },
      {
        type: "const",
        id: "",
        label: " ",
      },
      {
        type: "number",
        id: "loanPeriod",
        label: "Loan Period (Months)",
      },
      {
        type: "const",
        id: "",
        label: " ",
      },
      {
        type: "number",
        id: "loanRate",
        label: "Loan Rate (%)",
      },
      {
        type: "const",
        id: "",
        label: " ",
      },
      {
        type: "number",
        id: "loanAmount",
        label: "Loan Amount",
      },
    ];

    this.additionalRemarks = [
      {
        type: "const",
        id: "remarks",
        label: "Status:",
      },
      {
        type: "const",
        id: "remarks",
        label: "Adjustment Amount:",
      },
      {
        type: "const",
        id: "remarks",
        label: "Balance Amount:",
      },
      {
        type: "const",
        id: "remarks",
        label: "RemaFinal Balance:",
      },
    ];
  }
}
