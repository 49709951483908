import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from "@angular/common";
import {
  faBan,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "../../../../interfaces/form-field.model";
import { ProspectService } from "src/app/services/customer/prospect.service";
import { NotifierService } from "angular-notifier";
import { of, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { isEmptyGuid } from "../../../../services/util.service";
import { CommunicationPreferences } from "src/app/models/administration/prospect/prospect.model";

@Component({
  selector: "app-prospect-detail",
  templateUrl: "./prospect-detail.component.html",
  styleUrls: ["./prospect-detail.component.css"],
})
export class ProspectDetailComponent implements OnInit {
  // ICONS
  faPencil = faPencil;
  faBan = faBan;
  faPlus = faPlus;

  mode = "Add";
  userId: string = "";
  CustomerId: string = "";
  activeModels: any[] = [];
  activeUsers: any[] = [];
  prospect!: FormField[];
  preferences: CommunicationPreferences =
    new CommunicationPreferences();
  preferencesAll = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private prospectService: ProspectService,
    private notifier: NotifierService,
  ) {}

  ngOnInit(): void {
    this.setModeAndProspectId();
    this.fetchSCAndModels().subscribe({
      next: () => this.setUpFormFields(),
      error: (error) =>
        this.handleError("Error initializing data", error),
    });
  }

  toggleAll(event: any) {
    const isChecked = event.target.checked;
    this.preferences.IsAgreeDirectMail = isChecked;
    this.preferences.IsAgreeEMail = isChecked;
    this.preferences.IsAgreeSMS = isChecked;
    this.preferences.IsAgreePhone = isChecked;
    this.preferences.DoNotReceive = false;
  }

  toggleNoCommunication(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.preferences.IsAgreeDirectMail = false;
      this.preferences.IsAgreeEMail = false;
      this.preferences.IsAgreeSMS = false;
      this.preferences.IsAgreePhone = false;
      this.preferencesAll = false;
    }
  }

  checkSelected() {
    this.preferences.DoNotReceive = false;
    if (
      this.preferences.IsAgreeDirectMail &&
      this.preferences.IsAgreeEMail &&
      this.preferences.IsAgreeSMS &&
      this.preferences.IsAgreePhone
    ) {
      this.preferencesAll = true;
    } else {
      this.preferencesAll = false;
    }
  }

  updateFields(event: any): void {
    this.prospect = event.fields;
    if (this.prospect[18].value === "Others") {
      this.prospect[18].id = "AltSource";
      this.prospect[19].id = "Source";
      this.prospect[19].lock = false;
    } else if (this.prospect[18].value !== "Others") {
      this.prospect[18].id = "Source";
      this.prospect[19].id = "AltSource";
      this.prospect[19].value = "";
      this.prospect[19].lock = true;
    }
  }

  addProspect(): void {
    const newProspect = this.populateNewProspectRequestBody();
    this.prospectService.SaveProspectCustomer(newProspect).subscribe({
      next: () =>
        this.handleSuccess("Customer added", "../../prospect"),
      error: (error) => this.handleError("Error adding user", error),
    });
  }

  updateProspect(): void {
    const newProspect = this.populateProspectRequestBody();
    this.prospectService
      .UpdateProspectCustomer(this.userId, newProspect)
      .subscribe({
        next: () =>
          this.handleSuccess("Customer updated", "../../prospect"),
        error: (error) =>
          this.handleError("Error adding user", error),
      });
  }

  private setModeAndProspectId(): void {
    this.activatedRoute.params
      .pipe(
        tap((data) => {
          this.userId = data["id"];
          if (!isEmptyGuid(this.userId)) {
            this.mode = "Detail";
          }
        }),
      )
      .subscribe();
  }

  private fetchSCAndModels() {
    return of(null).pipe(
      switchMap(() =>
        this.prospectService.getModelActiveInSalesDrpList().pipe(
          tap((response) => this.processActiveModels(response.body)),
          switchMap(() => this.prospectService.getAllActiveUsers()),
          tap((response) => this.processActiveUsers(response.body)),
        ),
      ),
    );
  }

  private processActiveModels(responseBody: any[]): void {
    this.activeModels = responseBody.map((model) => ({
      display: model.name,
      value: model.name,
      id: model.id,
    }));
  }

  private processActiveUsers(responseBody: any[]): void {
    this.activeUsers = responseBody.map((user) => ({
      display: user.display_name,
      value: user.user_name,
      id: user.user_id,
    }));
  }

  private parseDate(dateStr: string) {
    let [day, month, year] = dateStr.split("/").map(Number);
    let date = new Date(year, month - 1, day);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  }

  private setUpFormFields(): void {
    this.prospectService.GetCustomerDetail(this.userId).subscribe({
      next: (response) => {
        this.prospect = this.getUserFormFields(response.body);
        this.preferences.DoNotReceive = response.body.DoNotReceive;
        this.preferences.IsAgreeDirectMail =
          response.body.IsAgreeDirectMail;
        this.preferences.IsAgreeEMail = response.body.IsAgreeEMail;
        this.preferences.IsAgreePhone = response.body.IsAgreePhone;
        this.preferences.IsAgreeSMS = response.body.IsAgreeSMS;
      },
      error: (error) =>
        this.handleError("Error getting user data", error),
    });
  }

  private getUserFormFields(responseBody: any): FormField[] {
    return [
      {
        type: "text",
        id: "IcNo",
        label: "NRIC No/RCB No/Passport No",
        required: true,
        value: responseBody.IcNo,
      },
      {
        type: "select",
        id: "Title",
        label: "Title",
        required: true,
        options: this.getTitleOptions(),
        value: responseBody.Title,
      },
      {
        type: "text",
        id: "Name",
        label: "Name as in NRIC/Passport",
        required: true,
        value: responseBody.Name,
      },
      {
        type: "text",
        id: "LicenseNo",
        label: "Driving licence number",
        value: responseBody.LicenseNo,
      },
      {
        type: "text",
        id: "Hp",
        label: "Mobile number",
        required: true,
        value: responseBody.Hp,
      },
      {
        type: "text",
        id: "AddressBlock",
        label: "Block/House",
        value: responseBody.AddressBlock,
      },
      {
        type: "text",
        id: "AddressRoad",
        label: "Road",
        value: responseBody.AddressRoad,
      },
      {
        type: "text",
        id: "AddressUnit",
        label: "Unit",
        value: responseBody.AddressUnit,
      },
      {
        type: "text",
        id: "PostalCode",
        label: "Postal code",
        value: responseBody.PostalCode,
      },
      {
        type: "email",
        id: "Email",
        label: "Email",
        required: true,
        value: responseBody.Email,
      },
      {
        type: "date",
        id: "Dob",
        label: "Date Of birth (dd/mm/yyyy)",
        required: true,
        value:
          responseBody.Dob === null || responseBody.Dob === ""
            ? ""
            : this.parseDate(responseBody.Dob),
      },
      {
        type: "select",
        id: "ModeContact",
        label: "Mode of contact",
        options: this.getContactOptions(),
        value: responseBody.ModeContact,
      },
      {
        type: "select",
        id: "ModelInterested",
        label: "Model interested",
        required: true,
        options: this.activeModels,
        value: responseBody.ModelInterested,
      },
      {
        type: "text",
        id: "CurrentCarModel",
        label: "Current car",
        value: responseBody.CurrentCarModel,
      },
      {
        type: "text",
        id: "CurrentRegNo",
        label: "Registration number",
        value: responseBody.CurrentRegNo,
      },
      {
        type: "date",
        id: "CurrentRegDate",
        label: "Registration date (dd/mm/yyyy)",
        value:
          responseBody.CurrentRegDate === "" ||
          responseBody.CurrentRegDate === null
            ? ""
            : this.parseDate(responseBody.CurrentRegDate),
      },
      {
        type: "select",
        id: "SalesConsultant",
        label: "Sales consultant",
        options: this.activeUsers,
        value: this.activeUsers.find(
          (item) => item.id == responseBody.UserOwner,
        )?.value,
      },
      {
        type: "text",
        id: "Remarks",
        label: "Remarks",
        value: responseBody.Remarks,
      },
      {
        type: "select",
        id: "Source",
        label: "Where did you find out about our product/offer?",
        options: this.getSourcesOptions(),
        value: [
          "Newspaper",
          "Magazine",
          "Radio",
          "Internet",
        ].includes(responseBody.Source)
          ? responseBody.Source
          : "Others",
      },
      {
        type: "text",
        id: "AltSource",
        label: "Please specify",
        lock: ["Newspaper", "Magazine", "Radio", "Internet"].includes(
          responseBody.Source,
        ),
        value: ![
          "Newspaper",
          "Magazine",
          "Radio",
          "Internet",
        ].includes(responseBody.Source)
          ? responseBody.Source
          : "",
      },
      {
        type: "formCheckbox",
        id: "IsBought",
        label: "Already bought the car",
        value: responseBody.IsBought,
      },
    ];
  }

  private populateNewProspectRequestBody(): any {
    return {
      AddedFromTestDrive: "",
      AddressBlock:
        this.getFieldValue("AddressBlock") !== null
          ? this.getFieldValue("AddressBlock")
          : "",
      AddressRoad:
        this.getFieldValue("AddressRoad") !== null
          ? this.getFieldValue("AddressRoad")
          : "",
      AddressUnit:
        this.getFieldValue("AddressUnit") !== null
          ? this.getFieldValue("AddressUnit")
          : "",
      Bought: "",
      Category: "",
      CreatedBy: "",
      CreatedDate: "",
      CurrentCarModel:
        this.getFieldValue("CurrentCarModel") !== null
          ? this.getFieldValue("CurrentCarModel")
          : "",
      CurrentRegDate:
        this.getFieldValue("CurrentRegDate") === null ||
        this.getFieldValue("CurrentRegDate") === ""
          ? ""
          : formatDate(
              this.getFieldValue("CurrentRegDate"),
              "dd/MM/yyyy",
              "en-US",
            ),
      CurrentRegNo:
        this.getFieldValue("CurrentRegNo") !== null
          ? this.getFieldValue("CurrentRegNo")
          : "",
      CustomerId: 0,
      DoNotReceive: this.preferences.DoNotReceive
        ? this.preferences.DoNotReceive
        : false,
      Dob: formatDate(
        this.getFieldValue("Dob"),
        "dd/MM/yyyy",
        "en-US",
      ),
      Email: this.getFieldValue("Email"),
      FamilyName: "",
      Gender: "",
      GivenName: "",
      Hp: this.getFieldValue("Hp"),
      IcNo: this.getFieldValue("IcNo"),
      IsAgreeDirectMail: this.preferences.IsAgreeDirectMail,
      IsAgreeEMail: this.preferences.IsAgreeEMail,
      IsAgreePhone: this.preferences.IsAgreePhone,
      IsAgreeSMS: this.preferences.IsAgreeSMS,
      IsBought:
        this.getFieldValue("IsBought") == null
          ? false
          : this.getFieldValue("IsBought"),
      IsDeleted: false,
      IsIsAgreeDirectMail: false,
      IsIsAgreePhone: false,
      IsIsAgreeSMS: false,
      LastModifiedBy: "",
      LastModifiedDate: "",
      LicenseNo:
        this.getFieldValue("LicenseNo") !== null
          ? this.getFieldValue("LicenseNo")
          : "",
      ModeContact:
        this.getFieldValue("ModeContact") !== null
          ? this.getFieldValue("ModeContact")
          : "",
      ModelInterested: this.getFieldValue("ModelInterested"),
      Name: this.getFieldValue("Name"),
      Occupation: "",
      OfficeNo: "",
      PostalCode:
        this.getFieldValue("PostalCode") !== null
          ? this.getFieldValue("PostalCode")
          : "",
      Remarks:
        this.getFieldValue("Remarks") !== null
          ? this.getFieldValue("Remarks")
          : "",
      ResidenceNo: "",
      Source:
        this.getFieldValue("Source") !== null
          ? this.getFieldValue("Source")
          : "",
      Status: "",
      Title: this.getFieldValue("Title"),
      UserOwner:
        this.getFieldValue("SalesConsultant") === null
          ? ""
          : this.activeUsers.find(
              (item) =>
                item.value == this.getFieldValue("SalesConsultant"),
            )?.id,
      modelinterestedothers: "",
    };
  }

  private populateProspectRequestBody(): any {
    return {
      AddressBlock:
        this.getFieldValue("AddressBlock") !== null
          ? this.getFieldValue("AddressBlock")
          : "",
      AddressRoad:
        this.getFieldValue("AddressRoad") !== null
          ? this.getFieldValue("AddressRoad")
          : "",
      AddressUnit:
        this.getFieldValue("AddressUnit") !== null
          ? this.getFieldValue("AddressUnit")
          : "",
      Category: "PROSPECT",
      CreatedBy: "",
      CreatedDate: "",
      CurrentCarModel:
        this.getFieldValue("CurrentCarModel") !== null
          ? this.getFieldValue("CurrentCarModel")
          : "",
      CurrentRegDate: this.getFieldValue("CurrentRegDate")
        ? formatDate(
            this.getFieldValue("CurrentRegDate"),
            "dd/MM/yyyy",
            "en-US",
          )
        : "",
      CurrentRegNo:
        this.getFieldValue("CurrentRegNo") !== null
          ? this.getFieldValue("CurrentRegNo")
          : "",
      CustomerId: this.userId,
      DoNotReceive: this.preferences.DoNotReceive
        ? this.preferences.DoNotReceive
        : false,
      Dob: formatDate(
        this.getFieldValue("Dob"),
        "dd/MM/yyyy",
        "en-US",
      ),
      Email: this.getFieldValue("Email"),
      FamilyName: "",
      GivenName: "",
      Hp: this.getFieldValue("Hp"),
      IcNo: this.getFieldValue("IcNo"),
      IsAddedFromTestDrive: true,
      IsAgreeDirectMail: this.preferences.IsAgreeDirectMail,
      IsAgreeEMail: this.preferences.IsAgreeEMail,
      IsAgreePhone: this.preferences.IsAgreePhone,
      IsAgreeSMS: this.preferences.IsAgreeSMS,
      IsBought:
        this.getFieldValue("IsBought") == null
          ? false
          : this.getFieldValue("IsBought"),
      IsDeleted: false,
      LastModifiedBy: "",
      LastModifiedDate: "",
      LicenseNo:
        this.getFieldValue("LicenseNo") !== null
          ? this.getFieldValue("LicenseNo")
          : "",
      ModeContact:
        this.getFieldValue("ModeContact") !== null
          ? this.getFieldValue("ModeContact")
          : "",
      ModelInterested: this.getFieldValue("ModelInterested"),
      Name: this.getFieldValue("Name"),
      Occupation: null,
      OfficeNo: null,
      PostalCode:
        this.getFieldValue("PostalCode") !== null
          ? this.getFieldValue("PostalCode")
          : "",
      Remarks:
        this.getFieldValue("Remarks") !== null
          ? this.getFieldValue("Remarks")
          : "",
      ResidenceNo: "0",
      Source:
        this.getFieldValue("Source") !== null
          ? this.getFieldValue("Source")
          : "",
      Status: null,
      Title: this.getFieldValue("Title"),
      UserOwner:
        this.getFieldValue("SalesConsultant") === null
          ? ""
          : this.activeUsers.find(
              (item) =>
                item.value == this.getFieldValue("SalesConsultant"),
            )?.id,
    };
  }

  private getFieldValue(id: string, index?: number): any {
    const field = this.prospect.find((f) => f.id === id);
    return index !== undefined ? field?.value[index] : field?.value;
  }

  private getTitleOptions(): any[] {
    return [
      { display: "MR", value: "MR" },
      { display: "MRS", value: "MRS" },
      { display: "MISS", value: "MISS" },
      { display: "M/S", value: "M/S" },
      { display: "MDM", value: "MDM" },
      { display: "DR", value: "DR" },
      { display: "MS", value: "MS" },
    ];
  }

  private getContactOptions(): any[] {
    return [
      { display: "", value: "" },
      { display: "REFERRAL", value: "REF" },
      { display: "REPEAT", value: "RPT" },
      { display: "WALK-IN", value: "WI" },
      { display: "CALL-IN", value: "OC" },
    ];
  }

  private getSourcesOptions(): any[] {
    return [
      { display: "", value: "" },
      { display: "Newspaper", value: "Newspaper" },
      { display: "Magazine", value: "Magazine" },
      { display: "Radio", value: "Radio" },
      { display: "Internet", value: "Internet" },
      { display: "Others", value: "Others" },
    ];
  }

  private handleSuccess(message: string, redirectPath: string): void {
    this.notifier.notify("success", message);
    this.router.navigate([redirectPath], {
      relativeTo: this.activatedRoute,
    });
  }

  private handleError(message: string, error: any): void {
    console.error(message, error);
    this.notifier.notify("error", message);
  }
}
