<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      User Management - <span>{{ mode }}</span>
    </h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1" *ngIf="mode == 'Detail'">
    <button class="shorter-btn save-btn" (click)="updateUser()">
      <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Update User
    </button>
    <button
      class="shorter-btn clear-btn"
      routerLink="../../../administration/user-management"
    >
      <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Cancel
    </button>
  </div>
  <div class="mb-2 flex w-fit gap-1" *ngIf="mode == 'Add'">
    <button class="shorter-btn add-btn" (click)="addUser()">
      <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add User
    </button>
    <button
      class="shorter-btn clear-btn"
      routerLink="../../../administration/user-management"
    >
      <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Cancel
    </button>
  </div>
  <div class="content-container">
    <app-data-form
      [dataFields]="user"
      dataName="formFields"
      (updateFields_child)="updateFields($event)"
    />
  </div>
</section>
