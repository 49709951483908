import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { KapsSettingsService } from "src/app/services/administration/kaps-settings.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-kaps-settings",
  templateUrl: "./kaps-settings.component.html",
  styleUrls: ["./kaps-settings.component.css"],
})
export class KapsSettingsComponent implements OnInit {
  faEdit = faEdit;
  settingsId: string = "";
  settingsInformation!: FormField[];
  formValues: any = {};

  constructor(
    private kapsSettingsService: KapsSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifier: NotifierService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.settingsId = params['id'] || '';
      this.initializeSettings();
    });
  }

  ngOnInit(): void {}

  initializeSettings(): void {
    this.kapsSettingsService.getSettings().subscribe({
      next: (response) => {
        const data = response.body;  // Access body
        if (!data) {
          this.notifier.notify("error", "No data received from API");
          console.error("No data found in response:", response);
          return;
        }
        // Filters the needed fields
        this.formValues = {
          base_url: data.base_url,
          bt_notify_email: data.bt_notify_email,
          custom_birthday_message: data.custom_birthday_message,
          focus_report_recipient: data.focus_report_recipient,
          is_deleted: data.is_deleted,
          report_domain: data.report_domain,
          report_end_point: data.report_end_point,
          report_folder: data.report_folder,
          report_password: data.report_password,
          report_user: data.report_user,
          service_cc_email: data.service_cc_email,
          service_cc_userid: data.service_cc_userid,
          setting_id: data.setting_id,
          setting_name: data.setting_name,
          smtp_bcc: data.smtp_bcc,
          smtp_enabled: data.smtp_enabled,
          smtp_from: data.smtp_from,
          smtp_password: data.smtp_password,
          smtp_port: data.smtp_port,
          smtp_priority: data.smtp_priority,
          smtp_requires_credential: data.smtp_requires_credential,
          smtp_send_async: data.smtp_send_async,
          smtp_server_name: data.smtp_server_name,
          smtp_ssl_enabled: data.smtp_ssl_enabled,
          smtp_to: data.smtp_to,
          smtp_username: data.smtp_username,
          upload_path_folder: data.upload_path_folder,
        };
        this.populateSettingsInformation();
      },
      error: (error) => {
        this.notifier.notify("error", "Error fetching KAPS settings data");
        console.error("Error fetching KAPS settings data:", error);
      },
    });
  }

  private populateSettingsInformation(): void {
    this.settingsInformation = [
      {
        type: "singleCheckbox",
        id: "smtp_enabled",
        label: "SMTP enabled",
        required: true,
        value: this.formValues.smtp_enabled,
      },
      {
        type: "email",
        id: "smtp_to",
        label: "SMTP to",
        required: true,
        value: this.formValues.smtp_to,
      },
      {
        type: "text",
        id: "smtp_server_name",
        label: "SMTP server name",
        required: true,
        value: this.formValues.smtp_server_name,
      },
      {
        type: "select",
        id: "smtp_priority",
        label: "SMTP priority",
        required: true,
        options: [
          { display: "LOW", value: "LOW" },
          { display: "NORMAL", value: "NORMAL" },
          { display: "HIGH", value: "HIGH" },
        ],
        value: this.formValues.smtp_priority,
      },
      {
        type: "text",
        id: "smtp_port",
        label: "SMTP port",
        required: true,
        value: this.formValues.smtp_port,
      },
      {
        type: "singleCheckbox",
        id: "smtp_requires_credential",
        label: "SMTP requires credentials",
        required: true,
        value: this.formValues.smtp_requires_credential,
      },
      {
        type: "singleCheckbox",
        id: "smtp_ssl_enabled",
        label: "SMTP SSL enabled",
        required: true,
        value: this.formValues.smtp_ssl_enabled,
      },
      {
        type: "text",
        id: "smtp_username",
        label: "SMTP username",
        required: true,
        value: this.formValues.smtp_username,
      },
      {
        type: "singleCheckbox",
        id: "smtp_send_async",
        label: "SMTP Send Async",
        required: true,
        value: this.formValues.smtp_send_async,
      },
      {
        type: "password",
        id: "smtp_password",
        label: "SMTP password",
        required: true,
        value: this.formValues.smtp_password,
      },
      {
        type: "email",
        id: "smtp_from",
        label: "SMTP from",
        required: true,
        value: this.formValues.smtp_from,
      },
      {
        type: "text",
        id: "focus_report_recipient",
        label: "Focus report recipient",
        value: this.formValues.focus_report_recipient,
      },
    ];
  }

  updateFields(fields: FormField[]): void {
    this.settingsInformation = fields;
  }

  uploadForm(): void {
    this.updateFieldsInFormValues();

    const updatedSettings = {
      ...this.formValues,
      settingId: this.settingsId || null,
    };

    this.kapsSettingsService.saveOtherSettings({ data: updatedSettings }).subscribe({
      next: () => {
        this.notifier.notify("success", "Settings updated successfully");
      },
      error: (error) => {
        this.notifier.notify("error", "Error updating settings");
        console.error("Error updating settings:", error);
      },
    });
  }

  private updateFieldsInFormValues(): void {
    this.settingsInformation.forEach((field) => {
      this.formValues[field.id] = field.value;
      //console.log(`Updated formValues: ${field.id} =`, field.value);
    });
  }
}
