import { Component, OnInit } from "@angular/core";
import { formatDate } from "@angular/common";
import {
  faFileDownload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { AuditMasterService } from "src/app/services/administration/audit-master.service";
import { getEmptyGuid } from "src/app/services/util.service";

@Component({
  selector: "app-audit-master",
  templateUrl: "./audit-master.component.html",
  styleUrls: ["./audit-master.component.css"],
})
export class AuditMasterComponent implements OnInit {
  faFileDownload = faFileDownload;
  faClose = faClose;
  auditMaster: FormField[] = this.initializeAuditMasterFields();
  showDetails = false;
  showRecords = false;
  userId = getEmptyGuid();
  recordResponse: any[] = [];

  tableHeaders = [
    { title: "MODULE/DB TABLE", type: "data" },
    { title: "ACTION TYPE", type: "data" },
    { title: "USER NAME", type: "data", classes: "max-w-64" },
    { title: "DATE TIME", type: "data" },
    { title: "RECORD ID", type: "data" },
    { title: "ACTION", type: "read" },
  ];

  tableEntries: Array<{
    Module: string;
    ActionType: any;
    UserName: any;
    DateTime: any;
    RecordId: any;
    Action: string;
    innerId: any;
  }> = [];

  detailsHeaders = [
    { title: "FIELD NAME", type: "data" },
    { title: "BEFORE CHANGE", type: "data" },
    { title: "AFTER CHANGE", type: "data" },
  ];

  detailsEntries: Array<{
    FieldName: string;
    ValueAfter: string;
    ValueBefore: string;
  }> = [];

  auditDetails: FormField[] = this.initializeAuditDetailsFields();

  constructor(private auditMasterService: AuditMasterService) {}

  ngOnInit(): void {
    this.loadActiveUsers();
    this.loadModules();
    this.loadTables();
  }

  private initializeAuditMasterFields(): FormField[] {
    return [
      { type: "select", id: "user", label: "User", options: [] },
      { type: "select", id: "module", label: "Module", options: [] },
      {
        type: "select",
        id: "dbTable",
        label: "Db table",
        options: [],
      },
      { type: "text", id: "recordId", label: "Record ID" },
      {
        type: "date",
        id: "fromDate",
        label: "Form date (dd/mm/yyyy)",
      },
      { type: "date", id: "toDate", label: "To date (dd/mm/yyyy)" },
    ];
  }

  private initializeAuditDetailsFields(): FormField[] {
    return [
      { type: "const", id: "user", label: "User" },
      { type: "const", id: "tableName", label: "Table Name" },
      { type: "const", id: "recordId", label: "Record Id" },
      { type: "const", id: "actionType", label: "Action Type" },
    ];
  }

  private loadActiveUsers(): void {
    this.auditMasterService.getAllActiveUsers().subscribe({
      next: (response) => {
        this.populateOptions(
          this.auditMaster[0],
          response.body,
          "Name",
          "Value",
          "All",
        );
        this.auditMaster[0].value = "All";
      },
      error: (error) => {
        console.error("Error fetching active users data:", error);
      },
    });
  }

  private loadModules(): void {
    this.auditMasterService.getAllModules(this.userId).subscribe({
      next: (response) => {
        this.populateOptions(
          this.auditMaster[1],
          response.body,
          "",
          "",
          "All",
        );
        this.auditMaster[1].value = "All";
      },
      error: (error) => {
        console.error("Error fetching modules data:", error);
      },
    });
  }

  private loadTables(): void {
    this.auditMasterService.getAllTable(this.userId).subscribe({
      next: (response) => {
        this.populateOptions(
          this.auditMaster[2],
          response.body,
          "",
          "",
          "All",
        );
        this.auditMaster[2].value = "All";
      },
      error: (error) => {
        console.error("Error fetching tables data:", error);
      },
    });
  }

  private populateOptions(
    field: FormField,
    data: any[],
    displayField: string,
    valueField: string,
    defaultOption: string,
  ): void {
    field.options = [
      { display: defaultOption, value: defaultOption },
    ];
    data.forEach((item) => {
      field.options?.push({
        display: item[displayField] || item,
        value: item[valueField] || item,
        id: item[valueField],
      });
    });
  }

  updateFields(event: any): void {
    this.auditMaster = event.fields;
    this.formatDateFields(4, 5);

    if (event.updatedValue === 0) {
      this.userId = this.getSelectedUserId();
      this.loadModules();
      this.loadTables();
    } else if (event.updatedValue === 1) {
      this.loadTablesByModule();
    }
  }

  private formatDateFields(...fieldIndices: number[]): void {
    fieldIndices.forEach((index) => {
      if (this.auditMaster[index].value) {
        this.auditMaster[index].value = formatDate(
          this.auditMaster[index].value!,
          "dd-MM-yyyy",
          "en-US",
        );
      }
    });
  }

  private getSelectedUserId(): string {
    return this.auditMaster[0].options?.find(
      (item) => item.value === this.auditMaster[0].value,
    )?.id!;
  }

  private loadTablesByModule(): void {
    this.auditMasterService
      .getAllTable(this.userId, this.auditMaster[1].value)
      .subscribe({
        next: (response) => {
          this.populateOptions(
            this.auditMaster[2],
            response.body,
            "",
            "",
            "All",
          );
        },
        error: (error) => {
          console.error(
            "Error fetching tables by module data:",
            error,
          );
        },
      });
  }

  getAuditRecords(): void {
    const [dbTable, fromDate, module, toDate, recordId] = [
      this.auditMaster[2].value,
      this.auditMaster[4].value,
      this.auditMaster[1].value,
      this.auditMaster[5].value,
      this.auditMaster[3].value,
    ];

    this.auditMasterService
      .getAll(
        this.userId,
        dbTable,
        fromDate,
        module,
        toDate,
        recordId,
      )
      .subscribe({
        next: (response) => {
          this.showRecords = true;
          this.recordResponse = response.body;
          this.populateTableEntries(response.body);
        },
        error: (error) => {
          console.error("Error fetching records data:", error);
        },
      });
  }

  private populateTableEntries(data: any[]): void {
    this.tableEntries = data.map((entry) => ({
      Module: `${entry.ModuleName}/${entry.TableName}`,
      ActionType: entry.AuditActionType,
      UserName: entry.UserName,
      DateTime: entry.CreatedDate,
      RecordId: entry.SearchableRecordId,
      Action: "",
      innerId: entry.SearchableRecordId,
    }));
  }

  openEntry(value: any): void {
    const activeRecord = this.recordResponse.find(
      (item) => item.SearchableRecordId === value.innerId,
    );
    if (activeRecord) {
      this.auditDetails[0].value = activeRecord.UserName;
      this.auditDetails[1].value = activeRecord.TableName;
      this.auditDetails[2].value = activeRecord.SearchableRecordId;
      this.auditDetails[3].value = activeRecord.AuditActionType;
      this.detailsEntries = activeRecord.Changes;
      this.showDetails = true;
    }
  }
}
