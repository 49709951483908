<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">User Rights Management</h1>
    <app-navpath></app-navpath>
  </div>
  <label class="my-4 flex items-center justify-end gap-3">
    Individual Selection
    <label class="switch">
      <input type="checkbox" [(ngModel)]="individual" />
      <span class="slider round"></span>
    </label>
  </label>
  <div class="content-container" [hidden]="individual">
    <h2 class="sub-heading w-full border-b">Rights Replication</h2>
    <div class="replication-cols grid grid-cols-2 gap-4">
      <section
        class="content-container rounded bg-gray-100 p-4 shadow"
      >
        <h3 class="sub-heading w-full border-b">Reference User</h3>
        <app-data-form
          [dataFields]="referenceUserFields"
          [dataName]="'referenceUserFields'"
          [columns]="1"
        ></app-data-form>
      </section>
      <section
        class="content-container rounded bg-gray-100 p-4 shadow"
      >
        <h3 class="sub-heading w-full border-b">Target User</h3>
        <app-data-form
          [dataFields]="targetUserFields"
          [dataName]="'targetUserFields'"
          [columns]="1"
        ></app-data-form>
      </section>
    </div>
    <div class="mb-2 mt-4 flex w-fit gap-1">
      <button class="shorter-btn misc-btn">
        <fa-icon [icon]="faCopy" class="mr-1"></fa-icon>Replicate
        Rights
      </button>
      <button class="shorter-btn clear-btn">
        <fa-icon [icon]="faEraser" class="mr-1"></fa-icon>Clear
      </button>
    </div>
  </div>
  <div class="content-container" [hidden]="!individual">
    <section class="content-container">
      <app-data-form
        [dataFields]="upperFormFields"
        [dataName]="'upperFormFields'"
        [columns]="0"
        (updateFields_child)="handleUpdate($event)"
      ></app-data-form>
    </section>
    <div class="content-container">
      <app-data-table
        [tableHeaders]="tableHeaders"
        [tableEntries]="filteredTypesSelect"
        (check)="checkEntry($event)"
        [pagination]="false"
      ></app-data-table>
    </div>
    <div class="mb-2 mt-4 flex w-fit gap-1">
      <button
        class="shorter-btn save-btn"
        (click)="updateUserRights()"
      >
        <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Update
      </button>
    </div>
  </div>
</section>
