import {Component, OnInit} from "@angular/core";
import {PageEvent} from "@angular/material/paginator";
import {faBan, faClose, faEdit, faListAlt, faPlus, faSave, faTrash,} from "@fortawesome/free-solid-svg-icons";
import {NotifierService} from "angular-notifier";
import {SalesTeamShuffleService} from "src/app/services/administration/sales-team-shuffle.service";

@Component({
  selector: "app-sales-team-shuffle",
  templateUrl: "./sales-team-shuffle.component.html",
  styleUrls: ["./sales-team-shuffle.component.css"],
})
export class SalesTeamShuffleComponent implements OnInit {
  faEdit = faEdit;
  faListAlt = faListAlt;
  faClose = faClose;
  faPlus = faPlus;
  faTrash = faTrash;
  faSave = faSave;
  faBan = faBan;
  showMenu = false;
  showTeam = false;
  filteredUsers: any[] = [];
  teams: any[] = [];
  originalUsersInfo: any[] = [];
  fullTeamData: any[] = [];
  // Paginator variables
  pageSize: number = 5;
  pageIndex: number = 0;
  pageTop!: number;
  pageEvent: PageEvent | any;
  pageSizeOptions = [5, 25, 50];
  tableHeaders = [
    {
      title: "SALESMAN CODE",
      type: "data",
      width: "w-1/4",
      classes: "text-center",
    },
    {
      title: "NAME",
      type: "data",
      width: "w-1/2",
      classes: "",
    },
    {
      title: "TEAM",
      type: "select",
      width: "w-1/4",
      classes: "",
    },
  ];
  users_info: any[] = [];
  selectOptions: any[] = [];
  teamHeaders = [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "NAME",
      type: "link",
    },
    {
      title: "DESCRIPTION",
      type: "data",
    },
  ];

  teamEntries: any[] = [];

  teamData = {
    name: "",
    description: "",
  };
  private readonly notifier: NotifierService;

  constructor(
    notifierService: NotifierService,
    private salesTeamShuffleService: SalesTeamShuffleService
  ) {
    this.notifier = notifierService;
    this.filteredUsers = this.users_info;
  }

  // Paginator update function
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    if (this.pageSize * (this.pageIndex + 1) > this.users_info.length) {
      this.pageTop = this.users_info.length;
    } else {
      this.pageTop = this.pageSize * (this.pageIndex + 1);
    }
  }

  returnzero() {
    return 0;
  }

  onSearch(searchValue: string): void {
    this.filteredUsers = this.users_info.filter(
      (item) =>
        item.salesmanCode.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  assignValue(content: any, index: number) {
    this.users_info[index].team = content;
    console.log(this.users_info);
  }

  assignTeam(event: any) {
    this.teamData = {...this.teamData, ...event};
    console.log(this.teamData);
  }

  openList(): void {
    this.salesTeamShuffleService.getAllSalesTeam().subscribe(
      (response) => {
        const salesTeams = response?.body;
        if (!salesTeams || !Array.isArray(salesTeams)) {
          console.error("No data found in sales teams response:", response);
          return;
        }

        this.fullTeamData = salesTeams.map((team: any) => ({
          Id: team.Id,
          TeamName: team.TeamName,
          Description: team.Description,
          IsDeleted: team.IsDeleted,
        }));

        this.teamEntries = this.fullTeamData.map((team: any) => ({
          marked: false,
          name: team.TeamName,
          description: team.Description,
        }));

        this.showTeam = false;
        this.showMenu = true;
      },
      (error) => {
        console.error("Error fetching sales team data:", error);
      }
    );
  }

  openEntry(event: any) {
    this.teamData.name = event.name;
    this.teamData.description = event.description;
    this.showTeam = true;
  }

  addEntry() {
    this.teamData.name = "";
    this.teamData.description = "";
    this.showTeam = true;
  }

  checkEntry(event: Event) {
    console.log(event);
  }

  testFun() {
    this.notifier.notify("success", "Quedon't");
  }

  ngOnInit() {
    this.pageTop = this.pageSize;

    this.salesTeamShuffleService.getAllSalesTeam().subscribe(
      (response) => {
        const salesTeams = response?.body;
        if (!salesTeams || !Array.isArray(salesTeams)) {
          console.error("No data found in sales teams response:", response);
          return;
        }

        this.fullTeamData = salesTeams.map((team: any) => ({
          Id: team.Id,
          TeamName: team.TeamName,
          Description: team.Description,
          IsDeleted: team.IsDeleted,
        }));

        this.teamEntries = this.fullTeamData.map((team: any) => ({
          marked: false,
          name: team.TeamName,
          description: team.Description,
        }));

        this.selectOptions = salesTeams.map((team: any) => ({
          display: team.TeamName,
          value: team.TeamName,
        }));

        this.salesTeamShuffleService.getAllSalesman().subscribe(
          (response) => {
            const salesmen = response?.body;
            if (!salesmen || !Array.isArray(salesmen)) {
              console.error("No data found in salesmen response:", response);
              return;
            }

            this.users_info = salesmen.map((salesman: any) => ({
              salesmanCode: salesman.SalesmanCode,
              name: salesman.SalesmanDesc,
              team: salesman.Team,
            }));

            this.filteredUsers = [...this.users_info];
            this.originalUsersInfo = JSON.parse(
              JSON.stringify(this.users_info)
            );
          },
          (error) => {
            console.error("Error fetching salesman data:", error);
          }
        );
      },
      (error) => {
        console.error("Error fetching sales team data:", error);
      }
    );
  }

  updateChanges() {
    const changedUsers = this.users_info.filter((user, index) => {
      return user.team !== this.originalUsersInfo[index].team;
    });

    if (changedUsers.length > 0) {
      const updatedSalesmen = changedUsers.map((user) => {
        const fullData = this.users_info.find(
          (item) => item.salesmanCode === user.salesmanCode
        );

        return {
          Salesman: fullData.name,
          SalesType: "SALESMAN",
          SalesmanCode: fullData.salesmanCode,
          SalesmanDesc: fullData.name,
          Team: user.team,
          UserId: fullData.UserId,
          isChanged: 1,
        };
      });

      const payload = {data: updatedSalesmen};  // Creates 'data'

      this.salesTeamShuffleService.updateSalesmanTeam(payload).subscribe(
        (response) => {
          this.notifier.notify("success", "Changes saved successfully");
          this.originalUsersInfo = JSON.parse(
            JSON.stringify(this.users_info)
          );
        },
        (error) => {
          console.error("Error updating salesman team:", error);
          this.notifier.notify("error", "Error saving changes");
        }
      );
    } else {
      this.notifier.notify("info", "No changes detected");
    }
  }


  saveTeam() {
    if (
      this.teamData.name &&
      typeof this.teamData.name === "string" &&
      this.teamData.name.trim() !== ""
    ) {
      const newTeam = {
        Id: 0,
        TeamName: this.teamData.name,
        Description: this.teamData.description,
      };

      const payload = {data: newTeam};

      this.salesTeamShuffleService.saveSalesTeam(payload).subscribe(
        (response) => {
          this.notifier.notify("success", "Team saved successfully");
          this.showTeam = false;
          this.loadTeams();
        },
        (error) => {
          console.error("Error saving team:", error);
          this.notifier.notify("error", "Error saving team");
        }
      );
    } else {
      this.notifier.notify("error", "Team name is required");
    }
  }


  loadTeams() {
    this.salesTeamShuffleService.getAllSalesTeam().subscribe(
      (response) => {
        const salesTeams = response?.body;
        if (!salesTeams || !Array.isArray(salesTeams)) {
          console.error("No data found in sales teams response:", response);
          return;
        }

        this.fullTeamData = salesTeams.map((team: any) => ({
          Id: team.Id,
          TeamName: team.TeamName,
          Description: team.Description,
          IsDeleted: team.IsDeleted,
        }));

        this.teamEntries = this.fullTeamData.map((team: any) => ({
          marked: false,
          name: team.TeamName,
          description: team.Description,
        }));

        this.showMenu = true;
      },
      (error) => {
        console.error("Error fetching sales team data:", error);
      }
    );
  }

  deleteSelectedTeams() {
    const selectedTeams = this.teamEntries
      .filter((team) => team.marked)
      .map((team) => {
        const fullData = this.fullTeamData.find(
          (item) => item.TeamName === team.name
        );
        return {
          Id: fullData.Id,
          TeamName: team.name,
          Description: team.description,
          selected: true,
          IsDeleted: false,
        };
      });

    if (selectedTeams.length > 0) {
      this.salesTeamShuffleService.deleteSalesTeam(selectedTeams).subscribe(
        (response) => {
          this.notifier.notify("success", "Teams deleted successfully");
          this.loadTeams();
        },
        (error) => {
          console.error("Error deleting teams:", error);
          this.notifier.notify("error", "Error deleting teams");
        }
      );
    } else {
      this.notifier.notify("info", "No teams selected for deletion");
    }
  }

  isSaveDisabled(): boolean {
    return !this.teamData.name || this.teamData.name.trim() === '';
  }
}
