import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service"; // Adjust path as needed
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return true;
        } else {
          window.location.href = environment.KAPS_V1_DIRECT_URL;
          return false;
        }
      }),
      catchError(error => {
        console.error('Error during authentication check:', error);
        window.location.href = environment.KAPS_V1_DIRECT_URL;
        return of(false);
      })
    );
  }
}
