<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading">
        <fa-icon [icon]="faCar" class="mr-1" /> Sales Consultant Receipt
      </h1>
      <app-navpath />
    </div>
    <div class="content-container">
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">
            Search
        </h2>
        <app-data-form
          [dataFields]="searchBooking"
          [dataName]="'searchBooking'"
          (updateFields_child)="updateFields($event)"
        />
      </section>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">
            Details Of Buyer
        </h2>
        <app-data-form
          [dataFields]="buyersDetails"
          [dataName]="'buyersDetails'"
          (updateFields_child)="updateFields($event)"
        />
      </section>
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">
            Details Of Buyer's Receipt
        </h2>
        <app-data-form
          [dataFields]="buyersDetails2"
          [dataName]="'buyersDetails2'"
          (updateFields_child)="updateFields($event)"
        />
      </section>
      <div class="mt-4 flex w-fit gap-1">
        <button class="shorter-btn darker-primary-btn locked-btn">
            <fa-icon [icon]="faPlus" class="mr-1" />Add New Receipt
          </button>
        <button class="shorter-btn darker-primary-btn">
          <fa-icon [icon]="faPencil" class="mr-1" />Update
        </button>
        <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faTrash" class="mr-1" />Delete
          </button>
          <button class="shorter-btn darker-primary-btn">
            <fa-icon [icon]="faPrint" class="mr-1" />Print
          </button>
          <button class="shorter-btn gray-btn">
            <fa-icon [icon]="faEraser" class="mr-1" />Clear
          </button>
      </div>
    </div>
  </section>
