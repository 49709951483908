import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-update-input",
  templateUrl: "./update-input.component.html",
  styleUrls: ["./update-input.component.css"],
})
export class UpdateInputComponent implements OnInit, OnChanges {
  @Input() dataInput: string | undefined;
  @Output() dataOutput = new EventEmitter<string>();
  @Output() clickUpdate = new EventEmitter<Event>();
  @Input() admitsUndefined: boolean = false;

  updateControl = new FormControl("");
  faPencil = faPencil;

  ngOnInit(): void {
    this.updateControl.enable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataInput !== undefined) {
      this.updateControl.setValue(this.dataInput);
    }
    if (changes["lock"]) {
      this.updateControl.enable();
    }
  }

  updateFormControl(event: any): void {
    const value = event.target.value;
    this.updateControl.setValue(value);
    this.dataOutput.emit(value);
  }

  update(event: any): void {
    event.preventDefault();
    this.clickUpdate.emit(event);
  }
}
