<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Promotion (free item) detail</h1>
    <app-navpath></app-navpath>
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <!-- Add -->
    <button *ngIf="mode === 'Add'" class="shorter-btn misc-btn" (click)="onSave()">
      <fa-icon [icon]="faPlus" class="mr-1" />Add
    </button>
  
    <!-- Edit -->
    <button *ngIf="mode === 'Edit'" class="shorter-btn misc-btn" (click)="onSave()">
      <fa-icon [icon]="faPencil" class="mr-1" />Update
    </button>
  
    <!-- Cancel -->
    <button class="shorter-btn clear-btn" (click)="onCancel()">
      <fa-icon [icon]="faCancel" class="mr-1" />Cancel
    </button>
  </div>
  <div class="content-container">
    <app-data-form [dataFields]="promotionDetail"></app-data-form>
  </div>
</div>
