import { Component, OnInit } from "@angular/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { formatDate } from "@angular/common";
import { NotifierService } from "angular-notifier";
import { RegistrationReportService } from "src/app/services/sales-reports/registration-report.service";

@Component({
  selector: "app-registration-report",
  templateUrl: "./registration-report.component.html",
  styleUrls: ["./registration-report.component.css"],
})
export class RegistrationReportComponent implements OnInit {
  faPrint = faPrint;
  lockPrint: boolean = true;

  FormFields: FormField[] = [
    {
      type: "date",
      id: "FromDate",
      label: "From Register Date (dd/mm/yyyy)",
      required: true,
    },
    {
      type: "date",
      id: "ToDate",
      label: "To Register Date (dd/mm/yyyy)",
      required: true,
    },
    {
      type: "select",
      id: "Format",
      label: "Select format",
      options: [
        { display: "PDF", value: "pdf" },
        { display: "EXCEL", value: "excel" },
        { display: "BOTH", value: "both" },
      ],
      value: "pdf",
    },
    {
      type: "select",
      id: "GroupBy",
      label: "Group By",
      options: [
        { display: "No Group", value: "nogroup" },
        { display: "Model", value: "model" },
        { display: "Sales Consultant", value: "salesconsultant" },
      ],
      value: "nogroup",
    },
  ];

  constructor(
    private notifier: NotifierService,
    private registrationReportService: RegistrationReportService,
  ) {}

  ngOnInit() {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0,
    );

    this.getField("FromDate").value = formatDate(
      firstDay,
      "yyyy-MM-dd",
      "en-US",
    );
    this.getField("ToDate").value = formatDate(
      lastDay,
      "yyyy-MM-dd",
      "en-US",
    );
    this.update();
  }

  update(): void {
    if (
      this.getField("FromDate").value &&
      this.getField("ToDate").value
    ) {
      this.lockPrint = false;
    } else {
      this.lockPrint = true;
    }
  }

  print(): void {
    const FromDate = this.getField("FromDate").value;
    const ToDate = this.getField("ToDate").value;
    const Format = this.getField("Format").value;
    const GroupBy = this.getField("GroupBy").value;

    if (!FromDate || !ToDate) {
      this.notifier.notify(
        "error",
        "Please provide valid date values",
      );
      return;
    }

    const formattedFromDate = formatDate(
      FromDate,
      "dd/MM/yyyy",
      "en-US",
    );
    const formattedToDate = formatDate(ToDate, "dd/MM/yyyy", "en-US");

    this.registrationReportService
      .RegistrationReport(formattedFromDate, GroupBy, formattedToDate)
      .subscribe({
        next: ({ body }) => {
          if (["pdf", "both"].includes(Format)) {
            window.open(body.Name, "_blank");
          }
          if (["excel", "both"].includes(Format)) {
            window.open(body.Value, "_blank");
          }
        },
        error: console.error,
      });
  }

  getField(id: string): FormField {
    return (
      this.FormFields.find((f) => f.id === id) || {
        id: "",
        type: "text",
        label: "",
        value: null,
      }
    );
  }
}
