<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Shipment / Production Master</h1>
    <app-navpath></app-navpath>
  </div>

  <!-- Tabs -->
  <ul class="tabs-container">
    <li
      (click)="onTabSelect('newShipment')"
      [ngClass]="selectedTab === 'newShipment' ? 'tab-item_selected' : 'tab-item'"
    >
      New Shipment / Production
    </li>
    <li
      (click)="onTabSelect('shipmentHistory')"
      [ngClass]="selectedTab === 'shipmentHistory' ? 'tab-item_selected' : 'tab-item'"
    >
      Shipment History
    </li>
  </ul>

  <!-- Content -->
  <div class="content-container__tabs">
    <!-- New Shipment / Production -->
    <div *ngIf="selectedTab === 'newShipment'">
      <app-data-form 
        [dataFields]="formFields" 
        (updateFields_child)="onModelChange($event); onExteriorColourChange($event.updatedValue)">
      </app-data-form>
      <div class="mb-4 flex w-fit gap-1">
        <button (click)="onAddEntry()" class="shorter-btn misc-btn">
          <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add
        </button>
        <button (click)="onClearForm()" class="shorter-btn clear-btn">
          <fa-icon [icon]="faEraser" class="mr-1"></fa-icon>Clear
        </button>    
      </div>
      <div class="content-container">  
        <app-data-table
          [tableHeaders]="tableHeaders"
          [tableEntries]="tableEntries"
          (remove)="onRemoveEntry($event)"
        ></app-data-table>     
      </div>
      <div class="mt-4 flex w-fit gap-1">
        <button (click)="onSaveEntries()" class="shorter-btn misc-btn">
          <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Save New Shipment
        </button> 
      </div>
    </div>

    <!-- Shipment History -->
    <div *ngIf="selectedTab === 'shipmentHistory'">
      <app-data-form
        [dataFields]="shipmentHistoryFormFields"
        (updateFields_child)="onShipmentEntryChange($event)"
      ></app-data-form>
      <div *ngIf="isEditingShipment" class="mb-4">
        <app-data-form
          [dataFields]="shipmentEditFormFields"
          (updateFields_child)="onModelChangeForEditForm($event); onExteriorColourChangeForEditForm($event.updatedValue)"
        ></app-data-form>
        <div class="mb-4 flex w-fit gap-1">
          <button (click)="onUpdateShipmentDetails()" class="shorter-btn misc-btn">
            <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Update
          </button>
          <button (click)="onCancelEdit()" class="shorter-btn clear-btn">
            <fa-icon [icon]="faEraser" class="mr-1"></fa-icon>Cancel
          </button>
        </div>
      </div>
      <div class="content-container">
        <app-data-table
          [tableHeaders]="shipmentHistoryTableHeaders"
          [tableEntries]="shipmentHistoryTableEntries"
          (edit)="editShipmentHistoryEntry($event)"
          (remove)="removeShipmentHistoryEntry($event)"
        ></app-data-table>
      </div>
      <app-data-form
        [dataFields]="updateShipmentFormFields"
        (updateFields_child)="onUpdateShipmentFormChange($event)"
      ></app-data-form>
      <div class="mt-4 grid grid-cols-2 gap-4">
        <button (click)="onUpdateEtaRemarks()" class="shorter-btn misc-btn w-fit px-4 py-2 ml-6 text-sm">
          <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Update Remarks
        </button>
        <button (click)="onUpdateEtaDate()" class="shorter-btn misc-btn w-fit px-4 py-2 ml-6 text-sm">
          <fa-icon [icon]="faSave" class="mr-1"></fa-icon>Update ETA Date
        </button>
      </div>               
    </div>    
  </div>
</section>
