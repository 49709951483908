import { Component, OnInit } from "@angular/core";
import {
  faPlus,
  faTrash,
  faCancel,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { VehicleStockSummaryReportService } from "src/app/services/sales/vehicle-stock-summary-report.service";
import { getEmptyGuid } from "src/app/services/util.service";

@Component({
  selector: "app-vehicle-stock-summary-report-list",
  templateUrl: "./vehicle-stock-summary-report-list.component.html",
  styleUrls: ["./vehicle-stock-summary-report-list.component.css"],
})
export class VehicleStockSummaryReportListComponent
  implements OnInit
{
  constructor(
    private vehicleStockSummaryReportService: VehicleStockSummaryReportService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  searchInput: string = "";
  showModal: boolean = false;
  allVehicles: any[] = [];
  filteredVehicles: any[] = [];
  filteredByFilters: any[] = [];

  faPlus = faPlus;
  faTrash = faTrash;
  faCancel = faCancel;
  faCheck = faCheck;

  tableHeaders = [
    {
      title: "",
      type: "checkbox",
      id: "Checked",
    },
    {
      title: "MODEL CODE",
      type: "link",
      id: "ModelCode",
    },
    {
      title: "MODEL SHORT DESC",
      type: "data",
      id: "ModelShortDesc",
    },
    {
      title: "ORDER NO",
      type: "data",
      id: "OrderNo",
    },
    {
      title: "CREATED DATE",
      type: "data",
      id: "CreatedDate",
    },
    {
      title: "SHOW IN REPORT",
      type: "boolean",
      id: "ShowInReport",
      messages: ["Yes", "No"],
    },
    {
      title: "ADD CALC IN YTD",
      type: "boolean",
      id: "AddCalcInYTD",
      messages: ["Yes", "No"],
    },
    {
      title: "IS TAXI",
      type: "boolean",
      id: "IsGrab",
      messages: ["Yes", "No"],
    },
    {
      title: "CATEGORY",
      type: "data",
      id: "Category",
    },
    {
      title: "",
      type: "hidden",
      id: "Id",
    },
  ];

  filters = [
    {
      value: "A",
      checked: true,
      label: "A",
    },
    {
      value: "B",
      checked: true,
      label: "B",
    },
    {
      value: "C",
      checked: true,
      label: "C",
    },
    {
      value: "COM",
      checked: true,
      label: "COM",
    },
    {
      value: "ShowReport",
      checked: true,
      label: "Show in report",
    },
    {
      value: "AddCalc",
      checked: true,
      label: "Add in calc",
    },
    {
      value: "IsTaxi",
      checked: true,
      label: "Is taxi",
    },
  ];

  ngOnInit() {
    this.getAllList();
  }

  private getAllList(): void {
    this.vehicleStockSummaryReportService
      .GetVehicleStockSummary()
      .subscribe(
        (response) => {
          const allowedKeys = this.tableHeaders.map(
            (header) => header.id,
          );
          this.allVehicles = response.body.map((obj: any) => {
            const filteredObj: any = {};
            filteredObj["Checked"] = false;
            allowedKeys.forEach((key) => {
              if (obj.hasOwnProperty(key)) {
                if (key == "CreatedDate") {
                  filteredObj[key] = this.formatDate(obj[key]);
                } else {
                  filteredObj[key] = obj[key];
                }
              }
            });
            return filteredObj;
          });
          this.applyFilters();
          this.onSearch(this.searchInput);
        },
        (error) => {
          console.error("Error fetching data:", error);
        },
      );
  }

  openModal(modal: boolean): void {
    const entries = this.filteredVehicles.filter(
      (item) => item.Checked == true,
    );
    if (entries.length == 0 && modal) {
      this.notifier.notify(
        "error",
        "Please, select at least one record to delete",
      );
      return;
    } else {
      this.showModal = modal;
      document.body.style.overflow = modal ? "hidden" : "auto";
    }
  }

  onSearch(searchValue: string): void {
    this.searchInput = searchValue;
    const commonKeys = ["ModelShortDesc"];

    this.filteredVehicles = this.filteredByFilters.filter((item) =>
      commonKeys.some((key) =>
        String(item[key])
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      ),
    );
  }

  private filterItems(
    items: any[],
    searchValue: string,
    keys: string[],
  ): any[] {
    const lowerSearchValue = searchValue.toLowerCase();
    return items.filter((item) =>
      keys.some((key) =>
        String(item[key]).toLowerCase().includes(lowerSearchValue),
      ),
    );
  }

  addEntry(): void {
    const emptyGuid = getEmptyGuid();
    this.router.navigate(
      ["../vehicle-stock-summary-report", emptyGuid, "A"],
      { relativeTo: this.route },
    );
  }

  openEntry(value: any) {
    this.router.navigate(
      ["../vehicle-stock-summary-report", value.Id, value.Category],
      {
        relativeTo: this.route,
      },
    );
  }

  applyFilters(): void {
    const activeFilters = this.filters
      .filter((filter) => filter.checked)
      .map((filter) => filter.value);

    this.filteredByFilters = this.allVehicles.filter((vehicle) => {
      const categoryFilters = ["A", "B", "C", "COM"];
      const isCategoryMatch = categoryFilters.some(
        (category) =>
          activeFilters.includes(category) &&
          vehicle.Category === category,
      );

      const showReportMatch = activeFilters.includes("ShowReport")
        ? vehicle.ShowInReport === true
        : true;

      const addCalcMatch = activeFilters.includes("AddCalc")
        ? vehicle.AddCalcInYTD === true
        : true;

      const isTaxiMatch = activeFilters.includes("IsTaxi")
        ? vehicle.IsGrab === true
        : true;

      return (
        isCategoryMatch &&
        showReportMatch &&
        addCalcMatch &&
        isTaxiMatch
      );
    });

    this.onSearch(this.searchInput);
  }

  formatDate(input: string): string {
    const timestamp = parseInt(
      input.replace(/\/Date\((\d+)\)\//, "$1"),
      10,
    );

    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  removeEntry(): void {
    const selectedItems = this.filteredVehicles
      .filter((item) => item.Checked == true)
      .map((item) => {
        return {
          Category: item.Category,
          Id: item.Id,
        };
      });
    this.vehicleStockSummaryReportService
      .Delete(selectedItems)
      .subscribe({
        next: () => {
          this.notifier.notify(
            "success",
            "Selected items deleted successfully",
          );
          this.ngOnInit();
          this.openModal(false);
        },
        error: (error) => {
          console.error("Error deleting records:", error);
          this.openModal(false);
        },
      });
  }
}
