import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { faSave, faEraser, faBan, faPencil, faPlus, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { WeeklyFocusReportService } from 'src/app/services/sales/weekly-focus-report.service';
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";
import { FormField } from 'src/app/interfaces/form-field.model';

@Component({
  selector: 'app-weekly-focus-report-detail',
  templateUrl: './weekly-focus-report-detail.component.html',
  styleUrls: ['./weekly-focus-report-detail.component.css']
})
export class WeeklyFocusReportDetailComponent implements OnInit {

  faSave   = faSave;
  faEraser = faEraser;
  faBan    = faBan;
  faPencil = faPencil;
  faPlus   = faPlus;
  faPaperPlane = faPaperPlane;

  isEdit = false;
  weeklyFocusId: string = getEmptyGuid();

  userId: string = getEmptyGuid();
  weeklyFocusTitle: string = getEmptyGuid();

  // 1) Showroom Duty fields
  showroomDutyFormField: FormField[] = [
    { type: 'text', id: 'customerAttendedCatA', label: 'CUSTOMERS ATTENDED (CAT A)', required: true },
    { type: 'text', id: 'customerAttendedCatB', label: 'CUSTOMERS ATTENDED (CAT B)', required: true },
    { type: 'text', id: 'testDriveCatA',        label: 'TEST DRIVE (CAT A)',         required: true },
    { type: 'text', id: 'testDriveCatB',        label: 'TEST DRIVE (CAT B)',         required: true },
    { type: 'text', id: 'closedDealCatA',       label: 'CLOSED DEAL (CAT A)',        required: true },
    { type: 'text', id: 'closedDealCatB',       label: 'CLOSED DEAL (CAT B)',        required: true },
    { type: 'text', id: 'pendingCatA',          label: 'PENDING (CAT A)',            required: true },
    { type: 'text', id: 'pendingCatB',          label: 'PENDING (CAT B)',            required: true },
    { type: 'text', id: 'rejectedCatA',         label: 'REJECTED (CAT A)',           required: true },
    { type: 'text', id: 'rejectedCatB',         label: 'REJECTED (CAT B)',           required: true },
  ];

  // 2) Leads form fields
  showroomDutyTableFormField: FormField[] = [
    { type: 'text', id: 'fullName',        label: 'Full Name',         required: false },
    { type: 'text', id: 'contactNo',       label: 'Contact No',        required: false },
    { type: 'text', id: 'modelInterested', label: 'Model Interested',  required: false },
    { type: 'text', id: 'whyDontBuy',      label: "Why Don't Buy?",    required: false },
  ];

  closingRatioFormField: FormField[] = [
    { type: 'text', id: 'closingRatio',   label: '# SALES CLOSING RATIO',    required: false },
    { type: 'text', id: 'workingHours',   label: 'HOW MANY WORKING HOURS?',  required: false }
  ];

  // B. STANDBY DUTY
  // 1) Prospecting
  standbyProspectingFormField: FormField[] = [
    { type: 'text', id: 'phoneCalls',     label: 'PHONE CALLS',       required: true },
    { type: 'text', id: 'internetEmails', label: 'INTERNET: EMAILS',  required: true },
    { type: 'text', id: 'clearDoc',       label: 'CLEAR DOCUMENTATION', required: true },
  ];

  // 2) Research
  standbyResearchFormField: FormField[] = [
    { type: 'text', id: 'comptProducts',  label: 'COMPETITORS PRODUCTS & PRICE', required: true },
    { type: 'text', id: 'usedVehicleVal', label: 'OUR USED VEHICLE VALUE',        required: true },
  ];

  leadsTableHeaders = [
    { title: 'FULL NAME',         type: 'data',  id: 'fullName' },
    { title: 'CONTACT NO.',       type: 'data',  id: 'contactNo' },
    { title: 'MODEL INTERESTED',  type: 'data',  id: 'modelInterested' },
    { title: "WHY DON'T BUY?",    type: 'data',  id: 'whyDontBuy' },
    { title: '',                  type: 'trash' }
  ];

  leadsTableEntries: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private weeklyFocusReportService: WeeklyFocusReportService,
    private notifier: NotifierService
  ) {}

  // C. SPOTTERS & YOUR NETWORK
  spotters1FormField: FormField[] = [
    { type: 'text', id: 'spotterName', label: 'Spotter Name', required: false },
    { type: 'text', id: 'spotterNRIC', label: 'Spotter NRIC', required: false },
  ];

  spotters2FormField: FormField[] = [
    { type: 'text', id: 'spotterName', label: 'Spotter Name', required: false }
  ];

  noBusinessSpotterForm: FormField[] = [
    {
      type: 'textarea',
      id: 'noBusinessSpotter',
      label: 'WHY NO BUSINESS FROM SPOTTER?',
      required: true
    }
  ];

  // D) COMMERCIAL HOUSES (todos “text”)
  commercialHousesFormField: FormField[] = [
    { type: 'text', id: 'fleetSales',        label: 'FLEET SALES (HOW MANY SOLD?)', required: true },
    { type: 'text', id: 'companyPurchase',   label: 'COMPANY PURCHASES',            required: true },
    { type: 'text', id: 'companyExec',       label: 'INDIVIDUAL COMPANY EXECUTIVES', required: true },
    { type: 'text', id: 'repeatSales',       label: 'HOW MANY REPEAT SALES?',       required: true },
    { type: 'text', id: 'others',            label: 'OTHERS',                       required: true },
  ];

  // E) MARKET ASSESSMENT (todas “textarea”)
  marketAssessmentFormField: FormField[] = [
    { type: 'textarea', id: 'doBetter',      label: 'HOW CAN YOU DO BETTER?',           required: true },
    { type: 'textarea', id: 'need',         label: 'WHAT YOU NEED?',                   required: true },
    { type: 'textarea', id: 'companyHelp',  label: 'HOW CAN THE COMPANY HELP YOU?',     required: true },
    { type: 'textarea', id: 'comptActivity',label: "COMPETITORS' ACTIVITIES & PROMOTIONS? (KIA, MAZDA, NISSAN, SKODA, PEUGEOT ETC.)", required: true },
  ];

  // F) WHY NO CAT B CARS SALES (textarea)
  whyNoCatBFormField: FormField[] = [
    { type: 'textarea', id: 'noCatBCarSales', label: 'WHY NO CAT B CARS SALES', required: false }
  ];

  // G) ANY OTHER COMMENTS OR SUGGESTIONS (textarea)
  commentsSuggestionsFormField: FormField[] = [
    { type: 'textarea', id: 'commentsSuggestions', label: 'ANY OTHER COMMENTS OR SUGGESTIONS', required: false }
  ];

  spotters1TableHeaders = [
    { title: 'SPOTTER NAME', type: 'data', id: 'spotterName' },
    { title: 'SPOTTER NRIC', type: 'data', id: 'spotterNRIC' },
    { title: '', type: 'trash' }
  ];

  spotters2TableHeaders = [
    { title: 'SPOTTER NAME', type: 'data', id: 'spotterName' },
    { title: '', type: 'trash' }
  ];

  spotters1TableEntries: any[] = [];
  spotters2TableEntries: any[] = [];

  ngOnInit(): void {
    const paramId = this.route.snapshot.paramMap.get('id');
    if (paramId) {
      this.weeklyFocusId = paramId;
    }

    if (this.weeklyFocusId !== getEmptyGuid()) {
      this.isEdit = true;
      this.weeklyFocusReportService.getWeeklyFocusDetail(this.weeklyFocusId).subscribe(
        (response) => {
          const data = response?.body;
          if (!data) return;

          this.userId = data.UserId;
          this.weeklyFocusTitle = data.WeeklyFocusTitle;

          // A) Showroom Duty
          this.populateShowroomDutyForm(response);

          // B) Leads
          if (Array.isArray(data.Leads)) {
            this.leadsTableEntries = data.Leads.map((lead: any) => ({
              fullName: lead.FullName || '',
              contactNo: lead.Contact || '',
              modelInterested: lead.ModelInterested || '',
              whyDontBuy: lead.ReasonDontBuy || '',
              remove: ''
            }));
          }
          // # SALES CLOSING RATIO
          this.setCRFormFieldValue('closingRatio', data.ClosingRatio);
          this.setCRFormFieldValue('workingHours', data.WorkingHours);

          // B) STANDBY DUTY
          this.setStandbyProspectingValue('phoneCalls', data.PhoneCalls);
          this.setStandbyProspectingValue('internetEmails', data.InternetEmails);
          this.setStandbyProspectingValue('clearDoc', data.ClearDoc);

          this.setStandbyResearchValue('comptProducts', data.ComptProducts);
          this.setStandbyResearchValue('usedVehicleVal', data.UsedVehicleVal);

          // C) SPOTTERS
          if (Array.isArray(data.Spotters1)) {
            this.spotters1TableEntries = data.Spotters1.map((s: any) => ({
              spotterName: s.SpotterName || '',
              spotterNRIC: s.SpotterNRIC || '',
              remove: ''
            }));
          }
          if (Array.isArray(data.Spotters2)) {
            this.spotters2TableEntries = data.Spotters2.map((s: any) => ({
              spotterName: s.SpotterName || '',
              remove: ''
            }));
          }
          this.setNoBusinessSpotterValue('noBusinessSpotter', data.NoBusinessSpotter);

          // D) COMMERCIAL HOUSES
          this.setCommercialHousesValue('fleetSales', data.FleetSales);
          this.setCommercialHousesValue('companyPurchase', data.CompanyPurchase);
          this.setCommercialHousesValue('companyExec', data.CompanyExec);
          this.setCommercialHousesValue('repeatSales', data.RepeatSales);
          this.setCommercialHousesValue('others', data.Others);

          // E) MARKET ASSESSMENT
          this.setMarketAssessmentValue('doBetter', data.DoBetter);
          this.setMarketAssessmentValue('need', data.Need);
          this.setMarketAssessmentValue('companyHelp', data.CompanyHelp);
          this.setMarketAssessmentValue('comptActivity', data.ComptActivity);

          // F) WHY NO CAT B CARS SALES
          this.setWhyNoCatBValue('noCatBCarSales', data.NoCatBCarSales);

          // G) ANY OTHER COMMENTS OR SUGGESTIONS
          this.setCommentsSuggestionsValue('commentsSuggestions', data.CommentsSuggestions);
        },
        (error) => {
          console.error('Error getting WeeklyFocus detail:', error);
        }
      );
    }
  }

  private setCommercialHousesValue(fieldId: string, value: any): void {
    const field = this.commercialHousesFormField.find(f => f.id === fieldId);
    if (field) field.value = value ?? '';
  }

  private setMarketAssessmentValue(fieldId: string, value: any): void {
    const field = this.marketAssessmentFormField.find(f => f.id === fieldId);
    if (field) field.value = value ?? '';
  }

  private setWhyNoCatBValue(fieldId: string, value: any): void {
    const field = this.whyNoCatBFormField.find(f => f.id === fieldId);
    if (field) field.value = value ?? '';
  }

  private setCommentsSuggestionsValue(fieldId: string, value: any): void {
    const field = this.commentsSuggestionsFormField.find(f => f.id === fieldId);
    if (field) field.value = value ?? '';
  }

  
  private setNoBusinessSpotterValue(fieldId: string, value: any): void {
    const field = this.noBusinessSpotterForm.find(f => f.id === fieldId);
    if (field) {
      field.value = value ?? '';
    }
  }

  addSpotter1(): void {
    const nameField = this.spotters1FormField.find(f => f.id === 'spotterName');
    const nricField = this.spotters1FormField.find(f => f.id === 'spotterNRIC');

    const newEntry = {
      spotterName: nameField?.value || '',
      spotterNRIC: nricField?.value || '',
      trash: ''
    };
    this.spotters1TableEntries.push(newEntry);

    if (nameField) nameField.value = '';
    if (nricField) nricField.value = '';
  }

  addSpotter2(): void {
    const nameField = this.spotters2FormField.find(f => f.id === 'spotterName');
    const newEntry = {
      spotterName: nameField?.value || '',
      trash: ''
    };
    this.spotters2TableEntries.push(newEntry);

    if (nameField) nameField.value = '';
  }

  removeSpotter1(value: any): void {
    const idx = this.spotters1TableEntries.indexOf(value);
    if (idx >= 0) {
      this.spotters1TableEntries.splice(idx, 1);
    }
  }

  removeSpotter2(value: any): void {
    const idx = this.spotters2TableEntries.indexOf(value);
    if (idx >= 0) {
      this.spotters2TableEntries.splice(idx, 1);
    }
  }

   private setStandbyProspectingValue(fieldId: string, value: any): void {
    const field = this.standbyProspectingFormField.find(f => f.id === fieldId);
    if (field) {
      field.value = value ?? '';
    }
  }

  private setStandbyResearchValue(fieldId: string, value: any): void {
    const field = this.standbyResearchFormField.find(f => f.id === fieldId);
    if (field) {
      field.value = value ?? '';
    }
  }

  private populateShowroomDutyForm(apiResponse: any): void {
    const data = apiResponse?.body;
    if (!data) return;
    this.setFormFieldValue('customerAttendedCatA', data.CustomerAttendedCatA);
    this.setFormFieldValue('customerAttendedCatB', data.CustomerAttendedCatB);
    this.setFormFieldValue('testDriveCatA',       data.TestDriveCatA);
    this.setFormFieldValue('testDriveCatB',       data.TestDriveCatB);
    this.setFormFieldValue('closedDealCatA',      data.ClosedDealCatA);
    this.setFormFieldValue('closedDealCatB',      data.ClosedDealCatB);
    this.setFormFieldValue('pendingCatA',         data.PendingCatA);
    this.setFormFieldValue('pendingCatB',         data.PendingCatB);
    this.setFormFieldValue('rejectedCatA',        data.RejectedCatA);
    this.setFormFieldValue('rejectedCatB',        data.RejectedCatB);
  }

  private setFormFieldValue(fieldId: string, value: any): void {
    const field = this.showroomDutyFormField.find(f => f.id === fieldId);
    if (field) {
      field.value = value ?? '';
    }
  }

  private setCRFormFieldValue(fieldId: string, value: any): void {
    const field = this.closingRatioFormField.find(f => f.id === fieldId);
    if (field) {
      field.value = value ?? '';
    }
  }

  addLead(): void {
    const fullNameField        = this.showroomDutyTableFormField.find(f => f.id === 'fullName');
    const contactNoField       = this.showroomDutyTableFormField.find(f => f.id === 'contactNo');
    const modelInterestedField = this.showroomDutyTableFormField.find(f => f.id === 'modelInterested');
    const whyDontBuyField      = this.showroomDutyTableFormField.find(f => f.id === 'whyDontBuy');

    const newLead = {
      fullName:        fullNameField?.value        || '',
      contactNo:       contactNoField?.value       || '',
      modelInterested: modelInterestedField?.value || '',
      whyDontBuy:      whyDontBuyField?.value      || '',
      trash: ''
    };

    this.leadsTableEntries.push(newLead);

    if (fullNameField)        fullNameField.value        = '';
    if (contactNoField)       contactNoField.value       = '';
    if (modelInterestedField) modelInterestedField.value = '';
    if (whyDontBuyField)      whyDontBuyField.value      = '';
  }

  removeLead(value: any): void {
    const index = this.leadsTableEntries.indexOf(value);
    if (index >= 0) {
      this.leadsTableEntries.splice(index, 1);
    }
  }

  saveEntry(): void {
    if (this.isEdit) {
      const updatePayload = this.buildUpdatePayload();
      
      this.weeklyFocusReportService.editWeeklyFocus(updatePayload).subscribe(
        (response) => {
          console.log('EditWeeklyFocus response:', response);
          this.notifier.notify('success', 'Weekly Focus updated successfully!');

          this.router.navigate(['/sales/weekly-focus-report'])
          .then(success => console.log('Navigation success:', success))
          .catch(err => console.error('Navigation error:', err));
        },
        (error) => {
          console.error('Error editing WeeklyFocus:', error);
          this.notifier.notify('error', 'Failed to update Weekly Focus.');
        }
      );
    } else {
      const createPayload = this.buildAddPayload();
      this.weeklyFocusReportService.addWeeklyFocus(createPayload).subscribe(
      (response) => {
        console.log('AddWeeklyFocus response:', response);
        this.notifier.notify('success', 'Weekly Focus created successfully!');
        this.router.navigate(['/sales/weekly-focus-report']);
      },
      (error) => {
        console.error('Error creating WeeklyFocus:', error);
        this.notifier.notify('error', 'Failed to create Weekly Focus.');
      }
    );
    }
  }

  private buildAddPayload(): any {
    return {
      WeeklyFocusId:  "00000000-0000-0000-0000-000000000000",
      UserId:         "00000000-0000-0000-0000-000000000000",
  
      WeeklyFocusTitle: this.getNextFridayString(),
  
      // A) SHOWROOM DUTY
      CustomerAttendedCatA: this.showroomDutyFormField.find(f => f.id === 'customerAttendedCatA')?.value || '',
      CustomerAttendedCatB: this.showroomDutyFormField.find(f => f.id === 'customerAttendedCatB')?.value || '',
      TestDriveCatA:        this.showroomDutyFormField.find(f => f.id === 'testDriveCatA')?.value || '',
      TestDriveCatB:        this.showroomDutyFormField.find(f => f.id === 'testDriveCatB')?.value || '',
      ClosedDealCatA:       this.showroomDutyFormField.find(f => f.id === 'closedDealCatA')?.value || '',
      ClosedDealCatB:       this.showroomDutyFormField.find(f => f.id === 'closedDealCatB')?.value || '',
      PendingCatA:          this.showroomDutyFormField.find(f => f.id === 'pendingCatA')?.value || '',
      PendingCatB:          this.showroomDutyFormField.find(f => f.id === 'pendingCatB')?.value || '',
      RejectedCatA:         this.showroomDutyFormField.find(f => f.id === 'rejectedCatA')?.value || '',
      RejectedCatB:         this.showroomDutyFormField.find(f => f.id === 'rejectedCatB')?.value || '',
  
      // # SALES CLOSING RATIO, HOW MANY WORKING HOURS?
      ClosingRatio: this.closingRatioFormField.find(f => f.id === 'closingRatio')?.value || '',
      WorkingHours: this.closingRatioFormField.find(f => f.id === 'workingHours')?.value || '',
  
      // B) STANDBY DUTY
      // 1) Prospecting
      PhoneCalls:     this.standbyProspectingFormField.find(f => f.id === 'phoneCalls')?.value || '',
      InternetEmails: this.standbyProspectingFormField.find(f => f.id === 'internetEmails')?.value || '',
      ClearDoc:       this.standbyProspectingFormField.find(f => f.id === 'clearDoc')?.value || '',
  
      // 2) Research
      ComptProducts:  this.standbyResearchFormField.find(f => f.id === 'comptProducts')?.value || '',
      UsedVehicleVal: this.standbyResearchFormField.find(f => f.id === 'usedVehicleVal')?.value || '',
  
      // C) SPOTTERS & YOUR NETWORK
      NoBusinessSpotter: this.noBusinessSpotterForm.find(f => f.id === 'noBusinessSpotter')?.value || '',
  
      // D) COMMERCIAL HOUSES
      FleetSales:      this.commercialHousesFormField.find(f => f.id === 'fleetSales')?.value || '',
      CompanyPurchase: this.commercialHousesFormField.find(f => f.id === 'companyPurchase')?.value || '',
      CompanyExec:     this.commercialHousesFormField.find(f => f.id === 'companyExec')?.value || '',
      RepeatSales:     this.commercialHousesFormField.find(f => f.id === 'repeatSales')?.value || '',
      Others:          this.commercialHousesFormField.find(f => f.id === 'others')?.value || '',
  
      // E) MARKET ASSESSMENT
      DoBetter:       this.marketAssessmentFormField.find(f => f.id === 'doBetter')?.value || '',
      Need:           this.marketAssessmentFormField.find(f => f.id === 'need')?.value || '',
      CompanyHelp:    this.marketAssessmentFormField.find(f => f.id === 'companyHelp')?.value || '',
      ComptActivity:  this.marketAssessmentFormField.find(f => f.id === 'comptActivity')?.value || '',
  
      // F) WHY NO CAT B CARS SALES
      NoCatBCarSales: this.whyNoCatBFormField.find(f => f.id === 'noCatBCarSales')?.value || '',
  
      // G) ANY OTHER COMMENTS OR SUGGESTIONS
      CommentsSuggestions: this.commentsSuggestionsFormField.find(f => f.id === 'commentsSuggestions')?.value || '',
  
      // Leads, Spotters, Remarks (vacío)
      Leads:     this.buildLeadsArrayForAdd(),
      Spotters1: this.buildSpotters1ArrayForAdd('N'),
      Spotters2: this.buildSpotters2ArrayForAdd('R'),
      Remarks:   []
    };
  }
  

  private getNextFridayString(): string {
    const today = new Date();
    
    const dayOfWeek = today.getDay(); // 0 => Sunday
    let daysToAdd = 5 - dayOfWeek;
  
    if (daysToAdd < 0) {
      daysToAdd += 7; 
    }
  
    const fridayDate = new Date(today);
    fridayDate.setDate(today.getDate() + daysToAdd);
  
    const yyyy = fridayDate.getFullYear().toString();
    const mm = String(fridayDate.getMonth() + 1).padStart(2, '0');
    const dd = String(fridayDate.getDate()).padStart(2, '0');
  
    return `${yyyy}${mm}${dd}`;
  }

  private buildLeadsArrayForAdd(): any[] {
    return this.leadsTableEntries.map((lead: any) => ({
      WeeklyFocusLeadId: '00000000-0000-0000-0000-000000000000',
      WeeklyFocusId: '00000000-0000-0000-0000-000000000000',
      FullName: lead.fullName || '',
      Contact: lead.contactNo || '',
      ModelInterested: lead.modelInterested || '',
      ReasonDontBuy: lead.whyDontBuy || ''
    }));
  }
  
  private buildSpotters1ArrayForAdd(spotterType: string): any[] {
    return this.spotters1TableEntries.map((s: any) => ({
      WeeklyFocusSpotterId: '00000000-0000-0000-0000-000000000000',
      WeeklyFocusId: '00000000-0000-0000-0000-000000000000',
      SpotterType: spotterType, 
      SpotterName: s.spotterName || '',
      SpotterNRIC: s.spotterNRIC || null,
      SpotterContact: null
    }));
  }

  private buildSpotters2ArrayForAdd(spotterType: string): any[] {
    return this.spotters2TableEntries.map((s: any) => {
      return {
        __type: "KAPS.Model.Sales.CrmWeeklyFocusSpotter",
        WeeklyFocusSpotterId: "00000000-0000-0000-0000-000000000000",
        WeeklyFocusId: "00000000-0000-0000-0000-000000000000",
        SpotterType: "R",
        SpotterName: s.spotterName || '',
        SpotterNRIC: null,
        SpotterContact: null
      };
    });
  }
  
  
  private buildUpdatePayload(): any {
    const WeeklyFocusId = this.weeklyFocusId;
    const UserId = this.userId;
    const WeeklyFocusTitle = this.weeklyFocusTitle;
  
    const dataObj: any = {
      __type: "KAPS.Model.Sales.CrmWeeklyFocusDetail",
  
      WeeklyFocusId,
      UserId,
      WeeklyFocusTitle,
  
      // A) SHOWROOM DUTY
      CustomerAttendedCatA: this.showroomDutyFormField.find(f => f.id === 'customerAttendedCatA')?.value || '',
      CustomerAttendedCatB: this.showroomDutyFormField.find(f => f.id === 'customerAttendedCatB')?.value || '',
      TestDriveCatA:        this.showroomDutyFormField.find(f => f.id === 'testDriveCatA')?.value || '',
      TestDriveCatB:        this.showroomDutyFormField.find(f => f.id === 'testDriveCatB')?.value || '',
      ClosedDealCatA:       this.showroomDutyFormField.find(f => f.id === 'closedDealCatA')?.value || '',
      ClosedDealCatB:       this.showroomDutyFormField.find(f => f.id === 'closedDealCatB')?.value || '',
      PendingCatA:          this.showroomDutyFormField.find(f => f.id === 'pendingCatA')?.value || '',
      PendingCatB:          this.showroomDutyFormField.find(f => f.id === 'pendingCatB')?.value || '',
      RejectedCatA:         this.showroomDutyFormField.find(f => f.id === 'rejectedCatA')?.value || '',
      RejectedCatB:         this.showroomDutyFormField.find(f => f.id === 'rejectedCatB')?.value || '',
  
      // # SALES CLOSING RATIO, HOW MANY WORKING HOURS?
      ClosingRatio: this.closingRatioFormField.find(f => f.id === 'closingRatio')?.value || '',
      WorkingHours: this.closingRatioFormField.find(f => f.id === 'workingHours')?.value || '',
  
      // B) STANDBY DUTY
      // 1) Prospecting
      PhoneCalls:      this.standbyProspectingFormField.find(f => f.id === 'phoneCalls')?.value || '',
      InternetEmails:  this.standbyProspectingFormField.find(f => f.id === 'internetEmails')?.value || '',
      ClearDoc:        this.standbyProspectingFormField.find(f => f.id === 'clearDoc')?.value || '',
  
      // 2) Research
      ComptProducts:   this.standbyResearchFormField.find(f => f.id === 'comptProducts')?.value || '',
      UsedVehicleVal:  this.standbyResearchFormField.find(f => f.id === 'usedVehicleVal')?.value || '',
  
      // C) SPOTTERS & YOUR NETWORK
      NoBusinessSpotter: this.noBusinessSpotterForm.find(f => f.id === 'noBusinessSpotter')?.value || '',
  
      // D) COMMERCIAL HOUSES
      FleetSales:      this.commercialHousesFormField.find(f => f.id === 'fleetSales')?.value || '',
      CompanyPurchase: this.commercialHousesFormField.find(f => f.id === 'companyPurchase')?.value || '',
      CompanyExec:     this.commercialHousesFormField.find(f => f.id === 'companyExec')?.value || '',
      RepeatSales:     this.commercialHousesFormField.find(f => f.id === 'repeatSales')?.value || '',
      Others:          this.commercialHousesFormField.find(f => f.id === 'others')?.value || '',
  
      // E) MARKET ASSESSMENT
      DoBetter:      this.marketAssessmentFormField.find(f => f.id === 'doBetter')?.value || '',
      Need:          this.marketAssessmentFormField.find(f => f.id === 'need')?.value || '',
      CompanyHelp:   this.marketAssessmentFormField.find(f => f.id === 'companyHelp')?.value || '',
      ComptActivity: this.marketAssessmentFormField.find(f => f.id === 'comptActivity')?.value || '',
  
      // F) WHY NO CAT B CARS SALES
      NoCatBCarSales: this.whyNoCatBFormField.find(f => f.id === 'noCatBCarSales')?.value || '',
  
      // G) ANY OTHER COMMENTS OR SUGGESTIONS
      CommentsSuggestions: this.commentsSuggestionsFormField.find(f => f.id === 'commentsSuggestions')?.value || '',
  
      // Leads, Spotters y Remarks
      Leads:     this.buildLeadsArray(),
      Spotters1: this.buildSpotters1Array('N'),
      Spotters2: this.buildSpotters2Array('R'),
      Remarks:   []
    };
  
    return dataObj;
  }
  
  private buildLeadsArray(): any[] {
    return this.leadsTableEntries.map((lead: any) => {
      return {
        __type: "KAPS.Model.Sales.CrmWeeklyFocusLead",
        WeeklyFocusLeadId: lead.WeeklyFocusLeadId || '00000000-0000-0000-0000-000000000000',
        WeeklyFocusId: this.weeklyFocusId,
        FullName: lead.fullName || '',
        Contact: lead.contactNo || '',
        ModelInterested: lead.modelInterested || '',
        ReasonDontBuy: lead.whyDontBuy || ''
      };
    });
  }
  
  private buildSpotters1Array(spotterType: string): any[] {
    return this.spotters1TableEntries.map((s: any) => {
      return {
        __type: "KAPS.Model.Sales.CrmWeeklyFocusSpotter",
        WeeklyFocusSpotterId: s.WeeklyFocusSpotterId || '00000000-0000-0000-0000-000000000000',
        WeeklyFocusId: this.weeklyFocusId,
        SpotterType: spotterType, // 'N'
        SpotterName: s.spotterName || '',
        SpotterNRIC: s.spotterNRIC || null,
        SpotterContact: null
      };
    });
  }
  
  private buildSpotters2Array(spotterType: string): any[] {
    return this.spotters2TableEntries.map((s: any) => {
      return {
        __type: "KAPS.Model.Sales.CrmWeeklyFocusSpotter",
        WeeklyFocusSpotterId: s.WeeklyFocusSpotterId || '00000000-0000-0000-0000-000000000000',
        WeeklyFocusId: this.weeklyFocusId,
        SpotterType: spotterType, // 'R'
        SpotterName: s.spotterName || '',
        SpotterNRIC: null,
        SpotterContact: null
      };
    });
  }
  
  
  clearEntry(): void {
    [
      this.showroomDutyFormField,
      this.showroomDutyTableFormField,
      this.closingRatioFormField,
      this.standbyProspectingFormField,
      this.standbyResearchFormField,
      this.spotters1FormField,
      this.spotters2FormField,
      this.noBusinessSpotterForm,
      this.commercialHousesFormField,
      this.marketAssessmentFormField,
      this.whyNoCatBFormField,
      this.commentsSuggestionsFormField
    ].forEach((fieldsArray) => {
      fieldsArray.forEach((field) => {
        field.value = '';
      });
    });
  
    //console.log('All forms and tables have been cleared.');
  }
  

  cancelEntry(): void {
    this.router.navigate(['/sales/weekly-focus-report'])
      .then(success => console.log('Navigation success:', success))
      .catch(err => console.error('Navigation error:', err));
  }

  submitEntry(): void {
    if (this.weeklyFocusId === getEmptyGuid()) {
      this.notifier.notify('error', 'Invalid Weekly Focus ID.');
      return;
    }

    this.weeklyFocusReportService.submitWeeklyFocus(this.weeklyFocusId).subscribe({
      next: (response) => {
        console.log('SubmitWeeklyFocus response:', response);
        this.notifier.notify('success', 'Weekly Focus submitted successfully!');
        this.router.navigate(['/sales/weekly-focus-report']);
      },
      error: (error) => {
        console.error('Error submitting WeeklyFocus:', error);
        this.notifier.notify('error', 'Failed to submit Weekly Focus.');
      }
    });
  }
  
}
