<div
  [ngClass]="{
    'form-container': true,
    'single-column': columns === 1,
    'single-row': columns === 0,
    'double-column': columns === 2
  }"
>
  <div
    *ngFor="
      let dataField of filterHidden(dataFields);
      let index = index
    "
  >
    <!-- FORM CHECKBOX -->
    <label
      *ngIf="
        ['formCheckbox', 'formCheckboxSingle'].includes(
          dataField.type
        )
      "
      class="flex h-full items-center gap-x-2"
    >
      <input
        type="checkbox"
        [class]="
          dataField.type == 'formCheckboxSingle'
            ? 'checkbox-single'
            : 'checkbox-form'
        "
        name=""
        [id]="dataField.id"
        [disabled]="dataField.lock"
        [(ngModel)]="dataField.value"
        (ngModelChange)="assignValue($event, index)"
      />
      <p>
        {{ dataField.label }}
      </p>
    </label>
    <!-- LABEL TEXT -->
    <label
      class="flex h-full w-full items-center"
      *ngIf="
        !['formCheckbox', 'formCheckboxSingle'].includes(
          dataField.type
        )
      "
    >
      <p
        *ngIf="dataField.label"
        class="w-1/3 whitespace-pre-line pr-2 font-bold"
        [ngClass]="{
          'text-red-warning':
            dataField.required &&
            (dataField.value == undefined || dataField.value === '')
        }"
      >
        {{ dataField.label }}
      </p>
      <!-- TEXT INPUT -->
      <app-text-input
        *ngIf="
          ['text', 'number', 'date', 'password', 'email'].includes(
            dataField.type
          )
        "
        [dataType]="dataField.type"
        [dataInput]="
          dataField.value || dataField.value === 0
            ? dataField.value
            : ''
        "
        (dataOutput)="assignValue($event, index)"
        [required]="dataField.required ? dataField.required : false"
        [lock]="dataField.lock ? dataField.lock : false"
        [id]="dataField.id"
        class="w-2/3 flex-grow"
      />
      <!-- DOUBLE TEXT INPUT, IT'S RECOMENDED TO SPECIFY VALUE AS value: ["", ""] -->
      <div
        class="flex w-2/3 flex-grow gap-x-2"
        *ngIf="dataField.type == 'doubleText'"
        [id]="dataField.id"
      >
        <app-text-input
          dataType="text"
          [dataInput]="dataField.value[0]"
          (dataOutput)="assignValue($event, index, 0)"
          [required]="dataField.required ? dataField.required : false"
          [lock]="dataField.lock ? dataField.lock : false"
        /><app-text-input
          dataType="text"
          [dataInput]="dataField.value[1]"
          (dataOutput)="assignValue($event, index, 1)"
          [required]="dataField.required ? dataField.required : false"
          [lock]="dataField.lock ? dataField.lock : false"
        />
      </div>
      <!-- SEARCH INPUT -->
      <app-search-input
        *ngIf="dataField.type == 'search'"
        [dataInput]="dataField.value"
        (clickSearch)="onSearch($event, dataField.id)"
        [isButtonActive]="
          dataField.searchEvent ? dataField.searchEvent : false
        "
        (dataOutput)="assignValue($event, index)"
        [textOptions]="dataField.options ? dataField.options : []"
        [required]="dataField.required ? dataField.required : false"
        [lock]="dataField.lock ? dataField.lock : false"
        [id]="dataField.id"
        class="w-2/3 flex-grow"
      />
      <!-- CONST INPUT -->
      <p *ngIf="dataField.type == 'const'">{{ dataField.value }}</p>
      <!-- SELECT INPUT -->
      <app-select-input
        *ngIf="dataField.type == 'select'"
        [dataInput]="dataField.value"
        (dataOutput)="assignValue($event, index)"
        [selectOptions]="dataField.options ? dataField.options : []"
        [required]="dataField.required ? dataField.required : false"
        [lock]="dataField.lock ? dataField.lock : false"
        [id]="dataField.id"
        class="w-2/3 flex-grow"
      />
      <!-- SELECT MULTIPLE INPUT -->
      <app-select-multiple-input
        *ngIf="dataField.type == 'selectMultiple'"
        [selectedOptions]="dataField.selectedOptions"
        (dataOutput)="assignValue($event, index)"
        [options]="dataField.options"
        [id]="dataField.id"
        class="w-2/3 flex-grow"
      />
      <!-- CHECKBOX OPTIONS LIST -->
      <form
        *ngIf="dataField.type == 'checkbox'"
        [id]="dataField.id"
        class="flex w-2/3 flex-wrap justify-between gap-1"
      >
        <label *ngFor="let optionCheckbox of dataField.options">
          <input
            type="checkbox"
            class="checkbox-form"
            [name]="optionCheckbox.display"
            [id]="optionCheckbox.value"
            [(ngModel)]="optionCheckbox.marked"
            [disabled]="dataField.lock"
            (change)="assignValue($event, index)"
          />
          {{ optionCheckbox.display }}
        </label>
        <label *ngIf="dataField.hasAll ? dataField.hasAll : true">
          <input
            type="checkbox"
            class="checkbox-form"
            name="all"
            id="all"
            [(ngModel)]="allChecked"
            (change)="markAll(dataField.options, index, allChecked)"
            [disabled]="dataField.lock"
          />
          All
        </label>
      </form>
      <!-- RADIO OPTIONS LIST -->
      <form
        *ngIf="dataField.type == 'radio'"
        class="flex w-2/3 flex-grow justify-around"
      >
        <label *ngFor="let optionRadio of dataField.options">
          <input
            type="radio"
            [value]="optionRadio.value"
            [name]="optionRadio.value"
            [id]="optionRadio.value"
            [(ngModel)]="dataField.value"
            [disabled]="dataField.lock"
          />
          {{ optionRadio.display }}
        </label>
      </form>
      <!-- TEXTAREA INPUT -->
      <textarea
        *ngIf="dataField.type == 'textarea'"
        class="textarea w-2/3"
        rows="4"
        [id]="dataField.id"
        [disabled]="dataField.lock"
        [(ngModel)]="dataField.value"
      ></textarea>
      <!-- ADD INPUT -->
      <app-add-input
        *ngIf="dataField.type == 'add'"
        [dataInput]="dataField.value"
        (clickAdd)="onAdd($event, dataField.id)"
        (dataOutput)="assignValue($event, index)"
        [admitsUndefined]="dataField.admitsUndefined"
        [id]="dataField.id"
        class="w-2/3 flex-grow"
      />
      <!-- SWITCH INPUT -->
      <label class="switch" *ngIf="dataField.type == 'switch'">
        <input type="checkbox" [(ngModel)]="dataField.value" />
        <span class="slider round"></span>
      </label>
      <!-- FILE INPUT -->
      <input
        *ngIf="dataField.type == 'file'"
        [disabled]="dataField.lock ? dataField.lock : false"
        [id]="dataField.id"
        type="file"
      />
      <!-- PRINT INPUT -->
      <app-print-input
        *ngIf="dataField.type == 'print'"
        (dataOutput)="assignValue($event, index)"
        [id]="dataField.id"
        class="w-2/3 flex-grow"
      />
      <!-- UPDATE INPUT -->
      <app-update-input
        *ngIf="dataField.type == 'update'"
        [dataInput]="dataField.value"
        (clickUpdate)="onUpdate($event, dataField.id)"
        (dataOutput)="assignValue($event, index)"
        [admitsUndefined]="dataField.admitsUndefined"
        [id]="dataField.id"
        class="w-2/3 flex-grow"
      />
      <!-- SINGLE CHECKBOX -->
      <input
        type="checkbox"
        *ngIf="dataField.type == 'singleCheckbox'"
        [(ngModel)]="dataField.value"
        [disabled]="dataField.lock"
        [id]="dataField.id"
        class="checkbox-single"
      />
    </label>
  </div>
</div>
