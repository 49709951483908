// Session storage constants
import { StorageKeys } from './models/session-storage/storage-keys.model';

export const STORAGE_KEYS: StorageKeys<string> = {
  USER_INFO: {
    FULL_NAME: {
      sessionKey: 'UserInfo.FullName',
      responseKey: 'user.userInfo.username'
    },
    JOB_DESCRIPTION: {
      sessionKey: 'UserInfo.JobDescription',
      responseKey: 'user.userInfo.job_description'
    },
    USER_ID: {
      sessionKey: 'UserInfo.UserId',
      responseKey: 'user.userInfo.userid'
    },
    PROFILE_PIC: {
      sessionKey: 'UserInfo.ProfilePic',
      responseKey: 'user.userInfo.userProfilePath'
    },
    COMPANIES: {
      sessionKey: 'UserInfo.Companies',
      responseKey: 'user.switchCompanies'
    },
    SELECTED_COMPANY: {
      sessionKey: 'UserInfo.SelectedCompany',
      responseKey: 'user.sessionCompany'
    },
  }
}

export const REQUIRED_KEYS = {
  USER_INFO: Object.values(STORAGE_KEYS.USER_INFO).map(key => key.sessionKey),
}

export const GENERIC_PROFILE_PIC = 'assets/images/main/generic_avatar.png';
