<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      <fa-icon [icon]="faIcons.faUsers" />
      Manage Control Parameters
    </h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <h2 class="sub-heading w-full border-b">
      Control Parameters detail
    </h2>
    <app-data-form
      [dataFields]="controlParametersDetail"
      (updateFields_child)="updateFields($event)"
      (clickAdd)="addEntry($event)"
    />
    <app-data-table
      *ngIf="RoyaltyDiscounts.length > 0"
      [tableHeaders]="tableHeaders"
      [tableEntries]="processRoyaltyDiscount(RoyaltyDiscounts)"
      (remove)="removeEntry($event)"
      [pagination]="false"
      class="block px-10"
    />
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn save-btn" (click)="update()">
        <fa-icon [icon]="faIcons.faPencil" class="mr-1" />Update
      </button>
      <button class="shorter-btn print-btn" (click)="printSummary()">
        <fa-icon [icon]="faIcons.faPrint" class="mr-1" />Print Stock
        Summary Report
      </button>
      <button
        class="shorter-btn misc-btn"
        (click)="tradeNetIntegration()"
      >
        <fa-icon [icon]="faIcons.faPrint" class="mr-1" />TradeNet Sync
      </button>
    </div>
  </div>
  <div class="content-container">
    <h2 class="sub-heading w-full border-b">Test Drive Report</h2>
    <app-data-form [dataFields]="testDriveReport" />
    <button
      class="shorter-btn print-btn mt-4"
      (click)="printDriveReport()"
    >
      <fa-icon [icon]="faIcons.faPrint" class="mr-1" />Print
    </button>
  </div>
</div>
