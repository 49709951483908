<section class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">
      User Type Management - <span>{{ mode }}</span>
    </h1>
    <app-navpath></app-navpath>
  </div>
  <div class="mb-2 flex w-fit gap-2">
    <button
      class="shorter-btn save-btn"
      *ngIf="mode == 'Detail'"
      (click)="updateRole()"
    >
      <fa-icon [icon]="faPencil" class="mr-1"></fa-icon>Update User
      Type
    </button>
    <button
      class="shorter-btn add-btn"
      *ngIf="mode == 'Add'"
      (click)="updateRole()"
    >
      <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add User Type
    </button>
    <button
      class="shorter-btn clear-btn"
      [routerLink]="['/administration/user-type-management']"
    >
      <fa-icon [icon]="faBan" class="mr-1"></fa-icon>Cancel
    </button>
  </div>
  <div class="content-container">
    <section class="content-container">
      <app-data-form
        [dataFields]="formFields"
        [dataName]="'formFields'"
        (updateFields_child)="updateFields($event)"
      ></app-data-form>
    </section>
    <div class="my-4 flex w-fit gap-2">
      <button
        class="shorter-btn misc-btn"
        (click)="checkAndUncheckAll(true)"
      >
        <fa-icon [icon]="faSquareCheck" class="mr-1"></fa-icon>Check
        All
      </button>
      <button
        class="shorter-btn clear-btn"
        (click)="checkAndUncheckAll(false)"
      >
        <fa-icon [icon]="faSquare" class="mr-1"></fa-icon>Uncheck All
      </button>
    </div>
    <div class="content-container">
      <app-data-table
        [tableHeaders]="tableHeaders"
        [tableEntries]="types_select"
        (check)="checkEntry($event)"
      ></app-data-table>
    </div>
  </div>
</section>
