import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpResponse, HttpUtilService } from "../http-util.service";

@Injectable({
  providedIn: "root",
})
export class PremiumCareMemberStatusService {
  constructor(private http: HttpUtilService) {}

  GetAllMembershipType(): Observable<
    HttpResponse<
      {
        __type: string;
        Value: string;
        Name: string;
        Status: boolean;
        Code: number;
        Message: string | null;
        Data: string | null;
        Id: string | null;
        IsGetData: string | null;
      }[]
    >
  > {
    return this.http.get(
      `PremiumCustomerService.asmx/GetAllMembershipType`,
    );
  }

  PremiumStatusReport(model: {
    ExpireFrom: string;
    ExpireTo: string;
    Format: string;
    JoinFrom: string;
    JoinTo: string;
    Status: string;
    Type: string;
  }): Observable<HttpResponse<any>> {
    return this.http.post(
      `CustomerReportService.asmx/PremiumStatusReport`,
      {
        model: model,
      },
    );
  }
}
