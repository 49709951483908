import { 
  Component, 
  OnInit, 
  Output, 
  EventEmitter 
} from '@angular/core';
import { faSearch } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-table-search-input',
  templateUrl: './table-search-input.component.html',
  styleUrls: ['./table-search-input.component.css']
})
export class TableSearchInputComponent implements OnInit {

  ngOnInit() {
    this.searchValue = ""
  }

  @Output() childInput = new EventEmitter<string>();

  searchValue: string = ""

  childSearchInput(value: string): void {
    this.searchValue = value
    console.log(value)
    this.childInput.emit(value)
  }

  faSearch = faSearch

}
