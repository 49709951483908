import {NgModule} from "@angular/core";
import {ExtraOptions, RouterModule, Routes} from "@angular/router";

// ACCESS PAGES
import {HomeComponent} from "./pages/home.component";

// ADMINISTRATION
import {AuditMasterComponent} from "./pages/administration/audit-master/audit-master.component";
import {SalesTeamShuffleComponent} from "./pages/administration/sales-team-shuffle/sales-team-shuffle.component";
import {
  UserManagementListComponent
} from "./pages/administration/user-management/user-management-list/user-management-list.component";
import {
  UserManagementDetailComponent
} from "./pages/administration/user-management/user-management-detail/user-management-detail.component";
import {
  UserTypeManagementListComponent
} from "./pages/administration/user-type-management/user-type-management-list/user-type-management-list.component";
import {
  UserTypeManagementDetailComponent
} from "./pages/administration/user-type-management/user-type-management-detail/user-type-management-detail.component";
import {
  UserRightsManagementComponent
} from "./pages/administration/user-rights-management/user-rights-management.component";
import {KapsSettingsComponent} from "./pages/administration/kaps-settings/kaps-settings.component";

// CUSTOMER

import { CustomerSearchListComponent } from "./pages/customer/customer-search/customer-search-list/customer-search-list.component";
import { CustomerSearchDetailComponent } from "./pages/customer/customer-search/customer-search-detail/customer-search-detail.component";
import { ProspectDetailComponent } from "./pages/customer/prospect/prospect-detail/prospect-detail.component";
import { ProspectListComponent } from "./pages/customer/prospect/prospect-list/prospect-list.component";
import { ClickDriveCustomerComponent } from "./pages/customer/click-drive-customer/click-drive-customer.component";
import { GenerateVoucherComponent } from "./pages/customer/click-drive-customer/generate-voucher/generate-voucher.component";
import { UpdateOrderComponent } from "./pages/customer/click-drive-customer/update-order/update-order.component";
import { PremiumCareMemberListComponent } from "./pages/customer/premium-care-member/premium-care-member-list/premium-care-member-list.component";
import { PremiumCareMemberDetailComponent } from "./pages/customer/premium-care-member/premium-care-member-detail/premium-care-member-detail.component";

//CUSTOMER REPORTS
import { PremiumCareMemberStatusComponent } from "./pages/customer-reports/premium-care-member-status/premium-care-member-status.component";
import { PremiumCareMemberEntitlementService } from "./services/customer-reports/premium-care-member-entitlement.service";

//RESERVATION
import {ReservationComponent} from "./pages/reservation/reservation/reservation.component";
import {ReservationDashboardComponent} from "./pages/reservation/reservation-dashboard/reservation-dashboard.component";
import {
  ReservationPriceListMasterDetailComponent
} from "./pages/reservation/reservation-price-list-master/reservation-price-list-master-detail/reservation-price-list-master-detail.component";
import {
  ReservationPriceListMasterListComponent
} from "./pages/reservation/reservation-price-list-master/reservation-price-list-master-list/reservation-price-list-master-list.component";

// VEHICLE
import {AccessoryDetailComponent} from "./pages/vehicle/accessory/accessory-detail/accessory-detail.component";
import {AccessoryListComponent} from "./pages/vehicle/accessory/accessory-list/accessory-list.component";
import {BookingComponent} from "./pages/vehicle/booking/booking.component";
import {
  ColoursByModelDetailComponent
} from "./pages/vehicle/colours-by-model/colours-by-model-detail/colours-by-model-detail.component";
import {
  ColoursByModelListComponent
} from "./pages/vehicle/colours-by-model/colours-by-model-list/colours-by-model-list.component";
import {DiscountComponent} from "./pages/vehicle/discount/discount.component";
import {
  InteriorColourDetailComponent
} from "./pages/vehicle/interior-colour/interior-colour-detail/interior-colour-detail.component";
import {
  InteriorColourListComponent
} from "./pages/vehicle/interior-colour/interior-colour-list/interior-colour-list.component";
import {
  MasterColourDetailComponent
} from "./pages/vehicle/master-colour/master-colour-detail/master-colour-detail.component";
import {MasterColourListComponent} from "./pages/vehicle/master-colour/master-colour-list/master-colour-list.component";
import {
  ModelAndSpecificationDetailComponent
} from "./pages/vehicle/model-and-specification/model-and-specification-detail/model-and-specification-detail.component";
import {
  ModelAndSpecificationListComponent
} from "./pages/vehicle/model-and-specification/model-and-specification-list/model-and-specification-list.component";
import {PromotionDetailComponent} from "./pages/vehicle/promotion/promotion-detail/promotion-detail.component";
import {PromotionListComponent} from "./pages/vehicle/promotion/promotion-list/promotion-list.component";
import {ReinstateBookingComponent} from "./pages/vehicle/reinstate-booking/reinstate-booking.component";
import {VehicleSelectionComponent} from "./pages/vehicle/vehicle-selection/vehicle-selection.component";
import {
  VehicleStockAvailabilityComponent
} from "./pages/vehicle/vehicle-stock-availability/vehicle-stock-availability.component";

// AUTOPAC
import {AccountAdjustmentComponent} from "./pages/autopac/account-adjustment/account-adjustment.component";
import {AccountCodeCreationComponent} from "./pages/autopac/account-code-creation/account-code-creation.component";
import {AmendChequePurposeComponent} from "./pages/autopac/amend-cheque-purpose/amend-cheque-purpose.component";
import {CarAllocationComponent} from "./pages/autopac/car-allocation/car-allocation.component";
import {CardsDepositListingComponent} from "./pages/autopac/cards-deposit-listing/cards-deposit-listing.component";
import {CarRegistrationComponent} from "./pages/autopac/car-registration/car-registration.component";
import {CashDepositListingComponent} from "./pages/autopac/cash-deposit-listing/cash-deposit-listing.component";
import {ChequeDepositListingComponent} from "./pages/autopac/cheque-deposit-listing/cheque-deposit-listing.component";
import {ChequeEnquiryComponent} from "./pages/autopac/cheque-enquiry/cheque-enquiry.component";
import {ChequeIssueComponent} from "./pages/autopac/cheque-issue/cheque-issue.component";
import {CoeAllocationComponent} from "./pages/autopac/coe-allocation/coe-allocation.component";
import {
  CoeBiddingPreparationComponent
} from "./pages/autopac/coe-bidding-preparation/coe-bidding-preparation.component";
import {CoeBiddingResultsComponent} from "./pages/autopac/coe-bidding-results/coe-bidding-results.component";
import {CoeBidStaffComponent} from "./pages/autopac/coe-bid-staff/coe-bid-staff.component";
import {ContractManagementComponent} from "./pages/autopac/contract-management/contract-management.component";
import {CustomerEnquiryComponent} from "./pages/autopac/customer-enquiry/customer-enquiry.component";
import {DutyPulloutComponent} from "./pages/autopac/duty-pullout/duty-pullout.component";
import {DutyPulloutTaxiComponent} from "./pages/autopac/duty-pullout-taxi/duty-pullout-taxi.component";
import {ExportToHMCComponent} from "./pages/autopac/export-to-hmc/export-to-hmc.component";
import {ExportToHmcNewComponent} from "./pages/autopac/export-to-hmc-new/export-to-hmc-new.component";
import {
  FinanceVehicleReceiptComponent
} from "./pages/autopac/finance-vehicle-receipt/finance-vehicle-receipt.component";
import {GeneralEnquiryComponent} from "./pages/autopac/general-enquiry/general-enquiry.component";
import {GstCalculationComponent} from "./pages/autopac/gst-calculation/gst-calculation.component";
import {InvoiceComponent} from "./pages/autopac/invoice/invoice.component";
import {MonthlyCommissionComponent} from "./pages/autopac/monthly-commission/monthly-commission.component";
import {
  OpenCategoryCoeDepositoryComponent
} from "./pages/autopac/open-category-coe-depository/open-category-coe-depository.component";
import {ParfTransferDateComponent} from "./pages/autopac/parf-transfer-date/parf-transfer-date.component";
import {
  PaymentStatusReadOnlyComponent
} from "./pages/autopac/payment-status-read-only/payment-status-read-only.component";
import {TaxiRegistrationComponent} from "./pages/autopac/taxi-registration/taxi-registration.component";
import {UpdateVehicleRateComponent} from "./pages/autopac/update-vehicle-rate/update-vehicle-rate.component";
import {EtaDateUpdationComponent} from "./pages/autopac/eta-date-updation/eta-date-updation.component";
import {
  OpenCategoryCoeTransferComponent
} from "./pages/autopac/open-category-coe-transfer/open-category-coe-transfer.component";
import {
  LocationMasterListComponent
} from "./pages/autopac/location-master/location-master-list/location-master-list.component";
import {
  LocationMasterDetailComponent
} from "./pages/autopac/location-master/location-master-detail/location-master-detail.component";
import {GiroPaymentComponent} from "./pages/autopac/giro-payment/giro-payment.component";
import {PaymentListComponent} from "./pages/autopac/payment-list/payment-list.component";
import {PaymentStatusComponent} from "./pages/autopac/payment-status/payment-status.component";
import {FobInvoicePriceComponent} from "./pages/autopac/fob-invoice-price/fob-invoice-price.component";
import {SalesmanReceiptComponent} from "./pages/autopac/salesman-receipt/salesman-receipt.component";
import {ServiceEnquiryComponent} from "./pages/autopac/service-enquiry/service-enquiry.component";

// AUTOPAC REPORTS
import {AccessoryReportComponent} from "./pages/autopac-reports/accessory-report/accessory-report.component";
import {
  CoeBidDetailsByDateReportComponent
} from "./pages/autopac-reports/coe-bid-details-by-date-report/coe-bid-details-by-date-report.component";
import {
  CoeBidsDetailReportComponent
} from "./pages/autopac-reports/coe-bids-detail-report/coe-bids-detail-report.component";

// DEBUGGEADORA
import {LaDebuggeadoraComponent} from "./pages/la-debuggeadora/la-debuggeadora.component";
import {AuthGuard} from "./auth/auth.guard";
import { PremiumCareMemberEntitlementComponent } from "./pages/customer-reports/premium-care-member-entitlement/premium-care-member-entitlement.component";
import { ControlParametersComponent } from "./pages/sales/control-parameters/control-parameters.component";
import { InventoryVehicleComponent } from "./pages/sales/inventory-vehicle/inventory-vehicle/inventory-vehicle.component";
import { ShipmentMasterComponent } from "./pages/sales/shipment-master/shipment-master/shipment-master.component";
import { InsuranceComponent } from "./pages/sales/insurance/insurance/insurance.component";
import { HirePurchaseListComponent } from "./pages/sales/hire-purchase/hire-purchase-list/hire-purchase-list.component";
import { HirePurchaseDetailComponent } from "./pages/sales/hire-purchase/hire-purchase-detail/hire-purchase-detail.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "top",
  anchorScrolling: "enabled",
  useHash: false,
};

const routes: Routes = [
  {

    path: "",
    canActivate: [AuthGuard],
    component: HomeComponent,
    children: [
      {
        path: "administration/audit-master",
        component: AuditMasterComponent,
      },
      {
        path: "administration/user-management",
        component: UserManagementListComponent,
        pathMatch: "full",
      },
      {
        path: "administration/user-management/:id",
        component: UserManagementDetailComponent,
      },
      {
        path: "administration/user-type-management",
        component: UserTypeManagementListComponent,
        pathMatch: "full",
      },
      {
        path: "administration/user-type-management/:id",
        component: UserTypeManagementDetailComponent,
      },
      {
        path: "administration/user-rights-management",
        component: UserRightsManagementComponent,
        pathMatch: "full",
      },
      {
        path: "administration/kaps-settings",
        component: KapsSettingsComponent,
      },
      {
        path: "administration/sales-team-shuffle",
        component: SalesTeamShuffleComponent,
      },
      {
        path: "customer/customer-search",
        component: CustomerSearchListComponent,
      },
      {
        path: "customer/customer-search/:id",
        component: CustomerSearchDetailComponent,
      },
      {
        path: "customer/prospect",
        component: ProspectListComponent,
      },
      {
        path: "customer/prospect/:id",
        component: ProspectDetailComponent
      },
      {
        path: "customer/click-drive-customer",
        component: ClickDriveCustomerComponent,
      },
      {
        path: "customer/click-drive-customer/generate-voucher",
        component: GenerateVoucherComponent,
      },
      {
        path: "customer/click-drive-customer/update-order",
        component: UpdateOrderComponent,
      },
      {
        path: "customer/premium-care-member",
        component: PremiumCareMemberListComponent,
      },
      {
        path: "customer/premium-care-member/:id",
        component: PremiumCareMemberDetailComponent,
      },
      {
        path: "customer-reports/premium-care-member-status",
        component: PremiumCareMemberStatusComponent,
      },
      {
        path: "customer-reports/premium-care-member-entitlement",
        component: PremiumCareMemberEntitlementComponent,
      },
      {
        path: "reservation/manageReservationPriceList",
        component: ReservationPriceListMasterListComponent,
        pathMatch: "full",
      },
      {
        path: "reservation/reservation-dashboard",
        component: ReservationDashboardComponent,
        pathMatch: "full",
      },
      {
        path: "reservation/reservation-price-list-master-detail",
        component: ReservationPriceListMasterDetailComponent,
        pathMatch: "full",
      },
      {
        path: "reservation/reservationRequest",
        component: ReservationComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/accessory/:id",
        component: AccessoryDetailComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/accessory",
        component: AccessoryListComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/booking",
        component: BookingComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/colours-by-model/:id",
        component: ColoursByModelDetailComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/colours-by-model",
        component: ColoursByModelListComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/discount",
        component: DiscountComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/interior-colour",
        component: InteriorColourListComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/interior-colour/:id",
        component: InteriorColourDetailComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/master-colour",
        component: MasterColourListComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/master-colour/:id",
        component: MasterColourDetailComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/model-and-specification/model-and-specification-detail",
        component: ModelAndSpecificationDetailComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/model-and-specification/model-and-specification-list",
        component: ModelAndSpecificationListComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/promotion-free-item/:id",
        component: PromotionDetailComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/promotion-free-item",
        component: PromotionListComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/reinstate-booking",
        component: ReinstateBookingComponent,
        pathMatch: "full",
      },
      {
        path: "vehicle/vehicle-selection",
        component: VehicleSelectionComponent,
        pathMatch: "full",
      },
      {
        path: "sales/control-parameters",
        component: ControlParametersComponent,
      },
      {
        path: "sales/hire-purchase",
        component: HirePurchaseListComponent,
      },
      {
        path: "sales/hire-purchase/:id",
        component: HirePurchaseDetailComponent,
      },
      {
        path: "sales/insurance",
        component: InsuranceComponent,
      },
      {
        path: "sales/inventory-vehicle",
        component: InventoryVehicleComponent,
      },
      {
        path: "sales/shipment-master",
        component: ShipmentMasterComponent,
      },
      {
        path: "vehicle/vehicle-stock-availability",
        component: VehicleStockAvailabilityComponent,
      },
      {
        path: "autopac/account-adjustment",
        component: AccountAdjustmentComponent,
      },
      {
        path: "autopac/account-code-creation",
        component: AccountCodeCreationComponent,
      },
      {
        path: "autopac/amend-cheque-purpose",
        component: AmendChequePurposeComponent,
      },
      {
        path: "autopac/car-allocation",
        component: CarAllocationComponent,
      },
      {
        path: "autopac/car-registration",
        component: CarRegistrationComponent,
      },
      {
        path: "autopac/cards-deposit-listing",
        component: CardsDepositListingComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/cash-deposit-listing",
        component: CashDepositListingComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/cheque-deposit-listing",
        component: ChequeDepositListingComponent,
      },
      {
        path: "autopac/cheque-enquiry",
        component: ChequeEnquiryComponent,
      },
      {
        path: "autopac/cheque-issue",
        component: ChequeIssueComponent,
      },
      {
        path: "autopac/coe-allocation",
        component: CoeAllocationComponent,
      },
      {
        path: "autopac/coe-bid-staff",
        component: CoeBidStaffComponent,
      },
      {
        path: "autopac/coe-bidding-preparation",
        component: CoeBiddingPreparationComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/coe-bidding-results",
        component: CoeBiddingResultsComponent,
      },
      {
        path: "autopac/contract-management",
        component: ContractManagementComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/customer-enquiry",
        component: CustomerEnquiryComponent,
      },
      {
        path: "autopac/duty-pullout",
        component: DutyPulloutComponent,
      },
      {
        path: "autopac/duty-pullout-taxi",
        component: DutyPulloutTaxiComponent,
      },
      {
        path: "autopac/export-hmc",
        component: ExportToHMCComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/export-hmc-new",
        component: ExportToHmcNewComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/finance-vehicle-receipt",
        component: FinanceVehicleReceiptComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/general-enquiry",
        component: GeneralEnquiryComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/gst-calculation",
        component: GstCalculationComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/invoice",
        component: InvoiceComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/monthly-commission",
        component: MonthlyCommissionComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/open-category-coe-depository",
        component: OpenCategoryCoeDepositoryComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/parf-transfer-date",
        component: ParfTransferDateComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/payment-status-read-only",
        component: PaymentStatusReadOnlyComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/taxi-registration",
        component: TaxiRegistrationComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/update-vehicle-rate",
        component: UpdateVehicleRateComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/eta-date-updation",
        component: EtaDateUpdationComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/open-categoty-coe-transfer",
        component: OpenCategoryCoeTransferComponent,
        pathMatch: "full",
      },
      {
        path: "autopac/location-master-list",
        component: LocationMasterListComponent,
      },
      {
        path: "autopac/location-master-detail",
        component: LocationMasterDetailComponent,
      },
      {
        path: "autopac/giro-payment",
        component: GiroPaymentComponent,
      },
      {
        path: "autopac/paymemt-list",
        component: PaymentListComponent,
      },
      {
        path: "autopac/payment-status",
        component: PaymentStatusComponent,
      },
      {
        path: "autopac/fob-invoice-price",
        component: FobInvoicePriceComponent,
      },
      {
        path: "autopac/salesman-receipt",
        component: SalesmanReceiptComponent,
      },
      {
        path: "autopac/service-enquiry",
        component: ServiceEnquiryComponent,
      },
      {
        path: "autopac-reports/accessory-report",
        component: AccessoryReportComponent,
        pathMatch: "full",
      },
      {
        path: "autopac-reports/coe-bid-details-by-date",
        component: CoeBidDetailsByDateReportComponent,
        pathMatch: "full",
      },
      {
        path: "autopac-reports/coe-bids-detail",
        component: CoeBidsDetailReportComponent,
        pathMatch: "full",
      },
      {
        path: "la-debuggeadora",
        component: LaDebuggeadoraComponent,
        pathMatch: "full",
      },
    ]
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
