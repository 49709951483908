// src/app/services/auth.service.ts
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable,  BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = "http://localhost:53719"; // Your .NET API URL
  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post<any>(
      `${this.apiUrl}/Services/SecuritySvc.asmx/AuthenticateUser`, // Login endpoint
      { "userid": username, "password": password, "returnUrl": "" },
      { headers: headers, withCredentials: true },
    ).pipe(
      map(response => {
        if (response.d.IsAuthenticated) {
          this.isAuthenticatedSubject.next(true);
          // Store any relevant info like tokens or user details if necessary
        }
        return response;
      })
    );
  }

  logout(): void {
    this.isAuthenticatedSubject.next(false);
    // Perform any additional cleanup, e.g., removing tokens from local storage
  }

  isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.value;
  }
}
