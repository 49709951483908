import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faSearch, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
import { CustomerSearchService } from 'src/app/services/customer/customer-search.service';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search-list.component.html',
  styleUrls: ['./customer-search-list.component.css']
})
export class CustomerSearchListComponent implements OnInit {

  // ICONS VARIABLES
  faSearch = faSearch;
  faDownload = faDownload;

  // OTHER VARIABLES
  fullModelsData: any[] = [];
  modelForm: FormField[] = [];
  searchFields: FormField[] = [];
  formValues: any = {};
  allData: any[] = [];
  tableData: any[] = [];
  selectedModelId: string = ''; 

  tableHeaders = [
    { title: "CONTRACT NO", type: "data", id: "contractNo" },
    { title: "NAME AS IN NRIC/PASSPORT", type: "data", id: "name" },
    { title: "NRIC NO/RCB NO/PASSPORT NO", type: "data", id: "icNo", classes: "max-w-64" },
    { title: "REGISTER NUMBER", type: "data", id: "registerNo" },
    { title: "MODEL", type: "data", id: "model", classes: "max-w-64" },
    { title: "ACTION", type: "editPrint", id: "editPrint" }
  ];

  constructor(
    private customerSearchService: CustomerSearchService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.setModelList();
    this.initializeSearchFields();
  }

  updateModelFields(fields: FormField[]): void {
    const modelField = fields.find(field => field.id === 'model');
    if (modelField) {
      this.selectedModelId = modelField.value;
    }
  }

  onDownloadCustomerList(): void {
    if (!this.selectedModelId) {
      this.notifier.notify('error', 'Please select a model first.');
      return;
    }

    this.customerSearchService.exportCustomer(this.selectedModelId).subscribe(
      (response) => {
        console.log('Customer list downloaded:', response);
        this.notifier.notify('success', 'Customer list downloaded successfully.');
      },
      (error) => {
        console.error('Error downloading customer list:', error);
        this.notifier.notify('error', 'Error downloading customer list.');
      }
    );
  }

  private setModelList(): void {
    this.customerSearchService.getModelDrpList().subscribe(
      (response) => {
        const models = response?.body;
        if (!models || !Array.isArray(models)) {
          console.error("No data found in set model list response:", response);
          return;
        }
        this.fullModelsData = models.map((model: any) => ({
          Type: model.__type,
          Value: model.Value,
          Name: model.Name,
          Description: model.Description,
          Status: model.Status
        }));
        this.fillModelForm();
      },
      (error) => {
        console.error("Error fetching set model list data:", error);
      }
    );
  }

  private fillModelForm(): void {
    this.modelForm = [
      {
        type: "select",
        id: "model",
        label: "Model",
        options: this.fullModelsData.map(user => ({
          display: user.Name,
          value: user.Value
        }))
      }
    ];
  }

  private initializeSearchFields(): void {
    this.searchFields = [
      {
        type: "select",
        id: "searchType",
        label: "Search by",
        required: true,
        options: [
          { display: "NRIC No/RCB No/Passport No", value: "icno" },
          { display: "Name as in NRIC/Passport", value: "name" },
          { display: "Postal Code", value: "postalcode" },
          { display: "Mobile Number", value: "mobileno" },
          { display: "Car Registration Number", value: "regno" }
        ]
      },
      {
        type: "text",
        id: "text",
        label: "Text to search",
        required: true
      }
    ];
  }

  updateSearchFields(fields: FormField[]): void {
    fields.forEach(field => {
      this.formValues[field.id] = field.value;
    });
    console.log("Updated formValues:", this.formValues);
  }

  editEntry(value: any) {
    const selectedContract = this.allData.find(item => item.contractNo === value.contractNo);

    if (selectedContract) {
      this.customerSearchService.setContractData(selectedContract);
      this.router.navigate(
        ["../customer-search", value.contractNo],
        { relativeTo: this.route }
      );
    }
  }

  printEntry(value: any) {
    const contractNo = value.contractNo;
  
    if (!contractNo) {
      this.notifier.notify('error', 'Contract number is missing.');
      return;
    }
  
    this.customerSearchService.print(contractNo).subscribe(
      (response) => {
        console.log('Print response:', response);
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
  
        this.notifier.notify('success', `Print initiated for contract: ${contractNo}`);
      },
      (error) => {
        console.error(`Error printing contract ${contractNo}:`, error);
        this.notifier.notify('error', `Failed to print contract ${contractNo}`);
      }
    );
  }
  

  onSearch(): void {
    const searchType = this.formValues.searchType;
    const searchText = this.formValues.text;

    if (!searchType || !searchText) {
      console.error('Both Search Type and Search Text are required');
      this.notifier.notify('error', 'Both Search Type and Search Text are required');
      return;
    }

    this.customerSearchService.findContract(searchText, searchType).subscribe(
      (response) => {
        console.log('Full response from API:', response);

        this.allData = response.body.map((item: any) => ({
          contractNo: item.ContractNo,
          name: item.Name,
          icNo: item.IcNo,
          registerNo: item.REGISTERNO,
          model: item.Model,
          addressBlock: item.AddressBlock,
          addressRoad: item.AddressRoad,
          addressUnit: item.AddressUnit,
          currentCarModel: item.CurrentCarModel,
          currentRegDate: item.CurrentRegDate,
          dob: item.Dob,
          email: item.Email,
          hp: item.Hp,
          modelInterested: item.ModelInterested,
          modelInterestedOthers: item.ModelInterestedOthers,
          modelShortDesc: item.ModelShortDesc,
          occupation: item.Occupation,
          officeNo: item.OfficeNo,
          postalCode: item.PostalCode,
          registerNumber: item.RegisterNumber,
          registrationDate: item.REGISTRATIONDATE,
          residendeNo: item.ResidenceNo,
          salemansCode: item.SalemansCode,
          salemansID: item.SalemansID,
          title: item.Title,
          userOwner: item.UserOwner
        }));

        this.tableData = this.allData.map((item: any) => ({
          contractNo: item.contractNo,
          name: item.name,
          icNo: item.icNo,
          registerNo: item.registerNo,
          model: item.model,
          editPrint: ""
        }));

        console.log('Mapped table data:', this.tableData);
      },
      (error) => {
        console.error("Error during search:", error);
        this.notifier.notify('error', 'Error during search');
      }
    );
  }
}
