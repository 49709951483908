<section class="page-container">
    <div class="flex items-center justify-between">
      <h1 class="main-heading"><fa-icon [icon]="faDownload" class="mr-1" />Export To HMC (New)</h1>
      <app-navpath />
    </div>
    <div class="content-container">
      <section class="content-container">
        <h2 class="sub-heading w-full border-b">Sales and Registration</h2>
        <div class="mt-4 flex w-fit gap-1">
            <button class="shorter-btn darker-primary-btn">
              <fa-icon [icon]="faPrint" class="mr-1" />Export
            </button>
            <button class="shorter-btn gray-btn">
              <fa-icon [icon]="faEraser" class="mr-1" />Clear
            </button>
        </div>
        <app-data-form
          [dataFields]="salesAndRegistration"
          [dataName]="'salesAndRegistration'"
        />
      </section>
    </div>
    <div class="content-container">
        <section class="content-container">
          <h2 class="sub-heading w-full border-b">Arrival of New Stock</h2>
          <div class="mt-4 flex w-fit gap-1">
              <button class="shorter-btn darker-primary-btn">
                <fa-icon [icon]="faPrint" class="mr-1" />Export
              </button>
              <button class="shorter-btn gray-btn">
                <fa-icon [icon]="faEraser" class="mr-1" />Clear
              </button>
          </div>
          <app-data-form
            [dataFields]="arrivalOfNewStock"
            [dataName]="'arrivalOfNewStock'"
          />
        </section>
      </div>
  </section>

