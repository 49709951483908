import { Component } from '@angular/core';
import {faPrint, faDownload, faEraser} from "@fortawesome/free-solid-svg-icons";
import { FormField } from "src/app/interfaces/form-field.model";
@Component({
  selector: 'app-export-to-hmc-new',
  templateUrl: './export-to-hmc-new.component.html',
  styleUrls: ['./export-to-hmc-new.component.css']
})
export class ExportToHmcNewComponent {

  faPrint = faPrint
  faDownload = faDownload
  faEraser = faEraser

  salesAndRegistration: FormField[] = [
    {
      type: "date",
      id: "salesFromRgeisterDate",
      label: "From Register Date (dd/mm/yyyy)",
      required: true
    },
    {
      type: "date",
      id: "salesFromRgeisterDate",
      label: "From Register Date (dd/mm/yyyy)",
      required: true
    },
    {
      type: "select",
      id: "salesDefaultWhosaleCode",
      label: "Default Wholesale Code",
      options: [
        { display: "001A", value: "001A" },
        { display: "010A", value: "010A" },
        { display: "100A", value: "100A" },
        { display: "Other", value: "Other" },
      ]
    },
  ];

  arrivalOfNewStock: FormField[] = [
    {
      type: "date",
      id: "stockFromRgeisterDate",
      label: "From Register Date (dd/mm/yyyy)",
      required: true
    },
    {
      type: "date",
      id: "stockFromRgeisterDate",
      label: "From Register Date (dd/mm/yyyy)",
      required: true
    },
  ];
}
