<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Inventory Vehicle</h1>
    <app-navpath />
  </div>
  <div class="content-container">
    <div class="grid grid-cols-3 gap-x-4 px-8 py-4 text-sm">
      <label class="flex h-full w-full items-center">
        <p class="w-1/3 whitespace-pre-line pr-2 font-bold">
          File Location
        </p>
        <input
          type="file"
          name=""
          id=""
          class="w-2/3 flex-grow"
          (change)="onFileChange($event)"
          accept=".xlsx, .xls"
        />
      </label>
      <label class="flex h-full items-center">
        <button class="shorter-btn show-btn" (click)="ShowData()">
          <fa-icon [icon]="faEye" class="mr-1" />Show List
        </button>
      </label>
      <label
        *ngIf="showUpload && InventoryVehicles.length > 0"
        class="flex h-full items-center"
      >
        <button class="shorter-btn add-btn" (click)="AddVehicles()">
          <fa-icon [icon]="faUpload" class="mr-1" />Upload
        </button>
      </label>
    </div>
    <div class="overflow-x-auto">
      <app-data-table
        *ngIf="showUpload && InventoryVehicles.length > 0"
        [tableHeaders]="tableHeaders"
        [tableEntries]="filterEntries(InventoryVehicles)"
        [pagination]="false"
      />
    </div>
  </div>
  <div class="content-container">
    <h2 class="sub-heading w-full border-b">
      Vehicle Details Summary
    </h2>
    <div class="flex flex-col gap-y-4 p-8 text-sm font-bold">
      <p>
        Total of
        {{ response?.Total && showUpload ? response?.Total : 0 }}
        unit vehicle loaded
      </p>
      <div *ngIf="showUpload" class="flex flex-col gap-y-4">
        <p *ngFor="let item of response?.PurchaseInvNumbers">
          {{ item.Name }}: {{ item.SubTotal }} units
        </p>
        <p *ngFor="let item of response?.PurchaseInvNumbersDetails">
          {{ item.Name }}: {{ item.SubTotal }} untis
        </p>
        <p
          *ngFor="let item of response?.FileValidationError"
          class="text-red-600"
        >
          {{ item }}
        </p>
      </div>
    </div>
  </div>
</div>
