import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { UserManagementService } from "src/app/services/administration/user-management.service";
import { NotifierService } from "angular-notifier";
import { getEmptyGuid } from "../../../../services/util.service";

@Component({
  selector: "app-user-management-list",
  templateUrl: "./user-management-list.component.html",
  styleUrls: ["./user-management-list.component.css"],
})
export class UserManagementListComponent implements OnInit {
  private readonly notifier: NotifierService;
  faPlus = faPlus;
  users_info: any[] = [];
  filteredUsers: any[] = [];

  constructor(
    private UserManagementService: UserManagementService,
    private router: Router,
    private route: ActivatedRoute,
    notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.UserManagementService.getAll().subscribe(
      (response) => {
        this.users_info = response.body.map((user: any) => {
          const container = {
            "userId": "",
            "fullName": "",
            "userType": "",
            "team": "",
            "editTrash": "",
            "innerId": "",
          };
          container["userId"] = user.LoginId;
          container["fullName"] = user.FirstName + user.LastName;
          container["userType"] = user.RoleName;
          container["team"] = user.Team ? user.Team : "";
          container["editTrash"] = "";
          container["innerId"] = user.UserId ? user.UserId : "";
          return container;
        });
        this.filteredUsers = this.users_info;
      },
      (error) => {
        console.error("Error fetching user management data:", error);
      },
    );
  }

  onSearch(searchValue: string): void {
    this.filteredUsers = this.users_info.filter(
      (item) =>
        item.userId
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        item.fullName
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
    );
  }

  tableHeaders = [
    { title: "USER ID", type: "link", id: "userId" },
    {
      title: "FULL NAME",
      type: "data",
      classes: "max-w-64",
      id: "fullName",
    },
    { title: "USER TYPE", type: "data", id: "userType" },
    { title: "TEAM", type: "data", id: "team" },
    { title: "", type: "editTrash" },
    { title: "INNERID", type: "hidden" },
  ];

  editEntry(value: any) {
    this.router.navigate(["../user-management", value.innerId], {
      relativeTo: this.route,
    });
  }

  removeEntry(value: any) {
    this.UserManagementService.deleteUser(value.innerId).subscribe({
      next: () => {
        this.notifier.notify(
          "success",
          "User " + value.userId + " deleted",
        );
        this.filteredUsers = this.filteredUsers.filter(
          (item) => item.innerId !== value.innerId,
        );
      },
      error: (error) => {
        this.notifier.notify("error", "Error deleting user");
        console.error("Error removing user:", error);
      },
    });
  }

  openEntry(value: any) {
    this.router.navigate(["../user-management", value.innerId], {
      relativeTo: this.route,
    });
  }

  protected readonly getEmptyGuid = getEmptyGuid;
}
