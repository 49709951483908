import { Component, OnInit } from "@angular/core";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  data: any;

  faUser = faUser;
  faLock = faLock;

  constructor(private authService: AuthService,private fb: FormBuilder, private router: Router) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      showPassword: [false]
    });
  }

  ngOnInit() {
  }

  login() {
    const formValues = this.loginForm.value;
    this.authService.login(formValues.email, formValues.password).subscribe(
      (response) => {
        if (response.d.IsAuthenticated) {
          this.router.navigate(['/home']);
        }
      },
      (error) => {
        console.error('Error fetching data in login:', error);
      }
    );
  }
}
