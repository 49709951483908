import { Component, OnInit } from "@angular/core";
import { UserInfoService } from "../../services/user-info.service";
import { STORAGE_KEYS } from "../../constants";
import { LoadingService } from "../../services/loading.service";
import { map } from "rxjs";

@Component({
  selector: "app-loading-page",
  templateUrl: "./loading-page.component.html",
  styleUrls: ["./loading-page.component.css"],
})
export class LoadingPageComponent implements OnInit {
  username: string = "";

  constructor(
    private userInfoService: UserInfoService,
    private loadingService: LoadingService,
  ) {}

  isCompanyLoading$ = this.loadingService.loading$.pipe(
    map((state) => state["company"]),
  );
  isRequestLoading$ = this.loadingService.loading$.pipe(
    map((state) => state["request"]),
  );

  ngOnInit() {
    this.userInfoService.userInfo$.subscribe((info) => {
      this.username =
        info[STORAGE_KEYS.USER_INFO["FULL_NAME"].sessionKey];
    });
  }
}
