<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Customer enquiry</h1>
    <app-navpath />
  </div>
  <section class="content-container">
    <h2 class="sub-heading w-full border-b">Search criteria</h2>
    <app-data-form
      [dataFields]="searchCriteria"
      [dataName]="'searchCriteria'"
    />
    <div class="mt-4 flex w-fit gap-1">
      <button class="shorter-btn primary-btn">
        <fa-icon [icon]="faMagnifyingGlass" class="mr-1" />Search
      </button>
      <button class="shorter-btn gray-btn">
        <fa-icon [icon]="faBroom" class="mr-1" />Clear
      </button>
    </div>
  </section>
</div>
