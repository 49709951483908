import { Component, OnInit } from "@angular/core";
import { faUndo, faWrench } from "@fortawesome/free-solid-svg-icons";
import { ClickDriveCustomerService } from "src/app/services/customer/click-drive-customer.service";

@Component({
  selector: "app-update-order",
  templateUrl: "./update-order.component.html",
  styleUrls: ["./update-order.component.css"],
})
export class UpdateOrderComponent implements OnInit {
  faUndo = faUndo;
  faWrench = faWrench;

  constructor(
    private ClickDriveCustomerService: ClickDriveCustomerService,
  ) {}

  ngOnInit() {}
}
