import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeeklyFocusDashboardService } from 'src/app/services/sales/weekly-focus-dashboard.service';
import { faBan, faSave } from "@fortawesome/free-solid-svg-icons";
import { FormField } from 'src/app/interfaces/form-field.model';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-weekly-focus-dashboard-detail',
  templateUrl: './weekly-focus-dashboard-detail.component.html',
  styleUrls: ['./weekly-focus-dashboard-detail.component.css'],
})
export class WeeklyFocusDashboardDetailComponent implements OnInit {

  faBan = faBan;
  faSave = faSave;

  detail: any = {};
  weeklyFocusId: string = '';
  comment: string = '';
  leadsTableEntries: any[] = [];
  spotters1TableEntries: any[] = [];
  spotters2TableEntries: any[] = [];

  // Showroom Duty
  showroomDutyFormField: FormField[] = [
    { type: 'text', id: 'customerAttendedCatA', label: 'CUSTOMERS ATTENDED (CAT A)', lock: true },
    { type: 'text', id: 'customerAttendedCatB', label: 'CUSTOMERS ATTENDED (CAT B)', lock: true },
    { type: 'text', id: 'testDriveCatA', label: 'TEST DRIVE (CAT A)', lock: true },
    { type: 'text', id: 'testDriveCatB', label: 'TEST DRIVE (CAT B)', lock: true },
    { type: 'text', id: 'closedDealCatA', label: 'CLOSED DEAL (CAT A)', lock: true },
    { type: 'text', id: 'closedDealCatB', label: 'CLOSED DEAL (CAT B)', lock: true },
    { type: 'text', id: 'pendingCatA', label: 'PENDING (CAT A)', lock: true },
    { type: 'text', id: 'pendingCatB', label: 'PENDING (CAT B)', lock: true },
    { type: 'text', id: 'rejectedCatA', label: 'REJECTED (CAT A)', lock: true },
    { type: 'text', id: 'rejectedCatB', label: 'REJECTED (CAT B)', lock: true },
  ];

  // Closing Ratio
  closingRatioFormField: FormField[] = [
    { type: 'text', id: 'closingRatio', label: '# SALES CLOSING RATIO', lock: true },
    { type: 'text', id: 'workingHours', label: 'HOW MANY WORKING HOURS?', lock: true }
  ];

  // Leads Table Headers
  leadsTableHeaders = [
    { title: 'FULL NAME', type: 'data', id: 'FullName' },
    { title: 'CONTACT NO.', type: 'data', id: 'Contact' },
    { title: 'MODEL INTERESTED', type: 'data', id: 'ModelInterested' },
    { title: "WHY DON'T BUY?", type: 'data', id: 'ReasonDontBuy' }
  ];

  // Spotters 1 Table Headers
  spotters1TableHeaders = [
    { title: 'SPOTTER NAME', type: 'data', id: 'SpotterName' },
    { title: 'SPOTTER NRIC', type: 'data', id: 'SpotterNRIC' }
  ];

  // Spotters 2 Table Headers
  spotters2TableHeaders = [
    { title: 'SPOTTER NAME', type: 'data', id: 'SpotterName' }
  ];

  noBusinessSpotterForm: FormField[] = [
    {
      type: 'textarea',
      id: 'noBusinessSpotter',
      label: 'WHY NO BUSINESS FROM SPOTTER?',
      required: true, 
      lock: true,
    }
  ];

  // Prospecting
  standbyProspectingFormField: FormField[] = [
    { type: 'text', id: 'phoneCalls', label: 'PHONE CALLS', lock: true },
    { type: 'text', id: 'internetEmails', label: 'INTERNET: EMAILS', lock: true },
    { type: 'text', id: 'clearDoc', label: 'CLEAR DOCUMENTATION', lock: true },
  ];

  // Research
  standbyResearchFormField: FormField[] = [
    { type: 'text', id: 'comptProducts', label: 'COMPETITORS PRODUCTS & PRICE', lock: true },
    { type: 'text', id: 'usedVehicleVal', label: 'OUR USED VEHICLE VALUE', lock: true },
  ];

  // Commercial Houses
  commercialHousesFormField: FormField[] = [
    { type: 'text', id: 'fleetSales', label: 'FLEET SALES (HOW MANY SOLD?)', lock: true },
    { type: 'text', id: 'companyPurchase', label: 'COMPANY PURCHASES', lock: true },
    { type: 'text', id: 'companyExec', label: 'INDIVIDUAL COMPANY EXECUTIVES', lock: true },
    { type: 'text', id: 'repeatSales', label: 'HOW MANY REPEAT SALES?', lock: true },
    { type: 'text', id: 'others', label: 'OTHERS', lock: true },
  ];

  // Market Assessment
  marketAssessmentFormField: FormField[] = [
    { type: 'textarea', id: 'doBetter', label: 'HOW CAN YOU DO BETTER?', lock: true },
    { type: 'textarea', id: 'need', label: 'WHAT YOU NEED?', lock: true },
    { type: 'textarea', id: 'companyHelp', label: 'HOW CAN THE COMPANY HELP YOU?', lock: true },
    { type: 'textarea', id: 'comptActivity', label: "COMPETITORS' ACTIVITIES & PROMOTIONS?", lock: true },
  ];

  // Why No Cat B Sales
  whyNoCatBFormField: FormField[] = [
    { type: 'textarea', id: 'noCatBCarSales', label: 'WHY NO CAT B CARS SALES', lock: true }
  ];

  // Comments
  commentsSuggestionsFormField: FormField[] = [
    { type: 'textarea', id: 'commentsSuggestions', label: 'ANY OTHER COMMENTS OR SUGGESTIONS', lock: true }
  ];

  // Remarks
  remarksForm: FormField[] = [
    {
      type: 'textarea',
      id: 'remarks',
      label: '',
      required: true, 
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private weeklyFocusService: WeeklyFocusDashboardService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.weeklyFocusId = params['id'];
      if (this.weeklyFocusId) {
        this.loadWeeklyFocusDetail(this.weeklyFocusId);
      }
    });
  }

  private setFormValue(formFields: FormField[], fieldId: string, value: any): void {
    const field = formFields.find(f => f.id === fieldId);
    if (field) {
      field.value = value ?? ''; 
    }
  }

  loadWeeklyFocusDetail(id: string): void {
    this.weeklyFocusService.getWeeklyFocusDetail(id).subscribe(
      (response) => {
       
        if (!response || !response.body) {
          console.warn('Response body is missing.');
          return;
        }
  
        const dataContainer = response.body.d ?? response.body;
        const data = dataContainer?.Data ?? dataContainer;
  
        if (!data) {
          console.warn('No data returned from API.', dataContainer);
          return;
        }
  
        this.detail = { ...data }; 
  
        this.setFormValue(this.showroomDutyFormField, 'customerAttendedCatA', data.CustomerAttendedCatA);
        this.setFormValue(this.showroomDutyFormField, 'customerAttendedCatB', data.CustomerAttendedCatB);
        this.setFormValue(this.showroomDutyFormField, 'testDriveCatA', data.TestDriveCatA);
        this.setFormValue(this.showroomDutyFormField, 'testDriveCatB', data.TestDriveCatB);
        this.setFormValue(this.showroomDutyFormField, 'closedDealCatA', data.ClosedDealCatA);
        this.setFormValue(this.showroomDutyFormField, 'closedDealCatB', data.ClosedDealCatB);
        this.setFormValue(this.showroomDutyFormField, 'pendingCatA', data.PendingCatA);
        this.setFormValue(this.showroomDutyFormField, 'pendingCatB', data.PendingCatB);
        this.setFormValue(this.showroomDutyFormField, 'rejectedCatA', data.RejectedCatA);
        this.setFormValue(this.showroomDutyFormField, 'rejectedCatB', data.RejectedCatB);
  
        this.setFormValue(this.closingRatioFormField, 'closingRatio', data.ClosingRatio);
        this.setFormValue(this.closingRatioFormField, 'workingHours', data.WorkingHours);
  
        this.setFormValue(this.standbyProspectingFormField, 'phoneCalls', data.PhoneCalls);
        this.setFormValue(this.standbyProspectingFormField, 'internetEmails', data.InternetEmails);
        this.setFormValue(this.standbyProspectingFormField, 'clearDoc', data.ClearDoc);
  
        this.setFormValue(this.standbyResearchFormField, 'comptProducts', data.ComptProducts);
        this.setFormValue(this.standbyResearchFormField, 'usedVehicleVal', data.UsedVehicleVal);

        this.setFormValue(this.noBusinessSpotterForm, 'noBusinessSpotter', data.NoBusinessSpotter);
  
        this.setFormValue(this.commercialHousesFormField, 'fleetSales', data.FleetSales);
        this.setFormValue(this.commercialHousesFormField, 'companyPurchase', data.CompanyPurchase);
        this.setFormValue(this.commercialHousesFormField, 'companyExec', data.CompanyExec);
        this.setFormValue(this.commercialHousesFormField, 'repeatSales', data.RepeatSales);
        this.setFormValue(this.commercialHousesFormField, 'others', data.Others);
  
        this.setFormValue(this.marketAssessmentFormField, 'doBetter', data.DoBetter);
        this.setFormValue(this.marketAssessmentFormField, 'need', data.Need);
        this.setFormValue(this.marketAssessmentFormField, 'companyHelp', data.CompanyHelp);
        this.setFormValue(this.marketAssessmentFormField, 'comptActivity', data.ComptActivity);
  
        this.setFormValue(this.whyNoCatBFormField, 'noCatBCarSales', data.NoCatBCarSales);
        this.setFormValue(this.commentsSuggestionsFormField, 'commentsSuggestions', data.CommentsSuggestions);

        this.setFormValue(this.remarksForm, 'remarks', data.UserRemark);
  
        if (Array.isArray(data.Leads)) {
          this.leadsTableEntries = data.Leads.map((lead: any) => ({
            FullName: lead.FullName || '',
            Contact: lead.Contact || '',
            ModelInterested: lead.ModelInterested || '',
            ReasonDontBuy: lead.ReasonDontBuy || '',
          }));
        }
        

        if (Array.isArray(data.Spotters1)) {
          this.spotters1TableEntries = data.Spotters1.map((spotter: any) => ({
            SpotterName: spotter.SpotterName || '',
            SpotterNRIC: spotter.SpotterNRIC || ''
          }));
        }

        if (Array.isArray(data.Spotters2)) {
          this.spotters2TableEntries = data.Spotters2.map((spotter: any) => ({
          SpotterName: spotter.SpotterName || ''
        }));
      }
        
      },
      (error) => {
        console.error('Error loading weekly focus detail:', error);
        this.detail = {};
      }
    );
  }

  onBack(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  onSaveRemark(): void {
    if (!this.weeklyFocusId || !this.detail?.UserRemarkId) {
      console.warn('Weekly ID or UserRemarkId is missing.');
      return;
    }
  
    const updatedRemark = this.remarksForm.find(f => f.id === 'remarks')?.value || '';
  
    const payload = {
      id: this.detail.UserRemarkId, 
      weeklyId: this.weeklyFocusId,
      remarks: updatedRemark
    };
  
    console.log('Saving remark with payload:', payload);
  
    this.weeklyFocusService.saveRemarksWeeklyFocus(payload).subscribe(
      (response) => {
        console.log('Remark saved successfully:', response);
        this.notifier.notify('success', 'Comment saved successfully.');
      },
      (error) => {
        console.error('Error saving remark:', error);
        this.notifier.notify('error', 'Failed to save comment.');
      }
    );
  }
  
}
