<div class="page-container">
  <div class="flex items-center justify-between">
    <h1 class="main-heading">Click & Drive Customer</h1>
    <app-navpath />
  </div>
  <div class="mb-2 flex w-fit gap-1">
    <button class="shorter-btn clear-btn" [routerLink]="'../'">
      <fa-icon [icon]="faUndo" class="mr-1"></fa-icon>Back to option
    </button>
  </div>
  <section class="content-container">
    <div class="mx-auto my-16 max-w-3xl text-center">
      <fa-icon [icon]="faWrench" class="mb-10 block text-6xl" />
      <p>
        <strong>
          Currently, the feature you're looking for is not yet
          available.
        </strong>
        <br />
        If you have any questions or need further assistance, feel
        free to contact our support team.
      </p>
    </div>
  </section>
</div>
